import React from 'react';
const getFollowedEntityName = ({ name, title, game, renderGameFirst }) => {
  if (!game?.abbr) {
    return name;
  }

  if (renderGameFirst) {
    return (
      <>
        {game.abbr}&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;{name || title}
      </>
    );
  }

  return (
    <>
      {name || title}&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;{game.abbr}
    </>
  );
};
export default getFollowedEntityName;
