import classnames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import ItemActionDropdown from '../ItemActionDropdown';
import ItemStyles from '../../ItemStyles';
import ItemParticipants from '../../ItemParticipants';
import ItemPrizepool from '../../ItemPrizepool';
import Countdown from '../../Countdown';
import { withCalendarUrlState } from '../../wrappers';
import { GameTag, TrackLink } from '../../../common';
import TournamentImage from '../../../Images/TournamentImage';
import { ONE_MINUTE, getGameById } from '../../../../store/selectors/gameData';
import { checkSeriesStream } from '../../../../store/actions/calendar';
import getFormattedTime from '../../../../utils/getFormattedTime';
import hasPrizepool from '../../../../utils/hasPrizepool';
import { getSeriesUrl, getTournamentUrl } from '../../../../utils/urls';

const timeClasses = {
  width: '13%',
  fontSize: 20,
  order: -1,
  '.matchView &': {
    width: '15%',
  },
};

const styles = theme => ({
  ...ItemStyles,
  root: {
    ...ItemStyles.root,
    textDecoration: 'none',
    color: theme.palette.text.primary,
    overflow: 'visible',
    background: theme.palette.background.secondary,
  },
  startTimeContainer: {
    ...timeClasses,
    fontWeight: 600,
    lineHeight: '24px',
    '& span': {
      ' & > span': {
        fontSize: 12,
        verticalAlign: 'middle',
        textTransform: 'uppercase',
        marginLeft: 5,
      },
    },
  },
  countdownContainer: {
    ...timeClasses,
    lineHeight: '24px',
    fontWeight: 'bold',
    '.startingNow &': {
      fontSize: 18,
    },
  },
  seriesInfo: {
    ...ItemStyles.seriesInfo,
    width: '42%',
    paddingRight: 10,
    boxSizing: 'border-box',
    '.matchView &': {
      width: '26%',
      marginLeft: 'auto',
    },
  },
  participantsContainer: {
    width: '27%',
    justifyContent: 'center',
    '.matchView &': {
      width: '29%',
    },
  },
  hideLogo: {
    display: 'none',
  },
  startInText: {
    fontSize: 10,
    fontWeight: 500,
    order: -1,
    position: 'absolute',
    bottom: 40,
    letterSpacing: '0.2px',
    lineHeight: '16px',
    marginLeft: -12,
    '@media (max-width:955px) and (min-width:604px)': {
      marginLeft: '55px !important',
    },
  },
  notificationDropdown: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    '.dropdownOpen &': {
      // NOTE: this is needed to prevent rendering the dropdown under the next item notification buttonon.
      zIndex: 1002, // To beat footer when dropdown open.
    },
  },
});

class UpcomingItem extends Component {
  state = { dropdownOpen: false };
  handleClose = () => {
    this.setState({ dropdownOpen: false });
  };

  handleOpen = () => this.setState(state => ({ dropdownOpen: !state.dropdownOpen }));

  render() {
    const {
      classes,
      series: { tournament, participants },
      series,
      game,
      isMatchView,
      checkSeriesStream,
    } = this.props;
    const { dropdownOpen } = this.state;

    const startDate = new Date(Math.floor(series.start.toDate().getTime() / ONE_MINUTE) * ONE_MINUTE); // trim off seconds.
    const isWithinOneHour = startDate.getTime() - new Date().getTime() < 60 * 60 * 1000;
    const nowSeconds = Math.floor(new Date().getTime() / 1000);

    return (
      <div
        className={classnames({
          [classes.root]: true,
          matchView: isMatchView,
          startingNow: series.start && series.start.seconds < nowSeconds,
          dropdownOpen,
        })}
      >
        {isWithinOneHour && (
          <>
            <span className={classes.startInText}> Starts in</span>
            <Countdown
              className={classes.countdownContainer}
              targetTime={startDate}
              onTimeEnd={() => {
                if (!Boolean(series.stream)) {
                  checkSeriesStream();
                }
              }}
            />
          </>
        )}
        {!isWithinOneHour && <div className={classes.startTimeContainer}>{getFormattedTime(startDate)}</div>}
        <GameTag className={classes.gameName} game={game} seriesId={series.id} />
        <div className={classes.seriesInfo}>
          <TournamentImage className={classnames({ [classes.hideLogo]: isMatchView })} tournament={tournament} />
          <div className={classes.seriesInfoText}>
            <div className={classes.tournamentName}>{tournament.title}</div>
            <div className={classes.seriesInfoBottomRow}>
              <span className={classes.seriesTitle}>{series.title}</span>
              {!isMatchView && hasPrizepool(tournament.prizepool) && (
                <>
                  <span className={classes.seriesSeparator}>-</span>
                  <ItemPrizepool prizepool={tournament.prizepool} withTrophyIcon />
                </>
              )}
            </div>
          </div>
        </div>
        {!!participants.length && participants.every(participant => participant.name !== 'TBD') && (
          <ItemParticipants className={classes.participantsContainer} participants={participants} />
        )}
        <div className={classes.notificationDropdown}>
          <ItemActionDropdown
            isMatchView={isMatchView}
            open={dropdownOpen}
            handleClose={this.handleClose}
            handleOpen={this.handleOpen}
            series={series}
          />
        </div>
        <TrackLink
          to={isMatchView ? getSeriesUrl(series) : getTournamentUrl(tournament)}
          className={classes.hoverText}
          track={{
            event: 'Schedule Item Clicked',
            target: isMatchView ? 'series-page' : 'event-page',
            eventProperties: { type: 'upcoming-item' },
            eventOptions: { contextSeriesId: series.id },
          }}
        >
          Click Here to <span>{isMatchView ? 'View Match' : 'View Event'}</span>
        </TrackLink>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withCalendarUrlState,
  connect(
    (state, ownProps) => ({
      game: getGameById(state, ownProps.series.game),
      calendarView: state.calendar.calendarView,
    }),
    { checkSeriesStream }
  )
)(UpcomingItem);
