import React from 'react';

import EntityBadge from './EntityBadge';
import { getSlugFromAbbr } from '../../../store/selectors/gameDataUtils';

export default ({ animated, className, disabled, elevation = 1, game, track }) => {
  const { abbr, id, images } = game;
  const { circle, rectangle, square } = images || {};

  return (
    <EntityBadge
      animated={animated}
      className={className}
      disabled={disabled}
      elevation={elevation}
      src={square || rectangle || circle}
      to={`/${getSlugFromAbbr(abbr)}`}
      track={{
        event: 'Game Badge Clicked',
        eventProperties: {
          game_abbr: abbr,
          game_id: id,
        },
        target: 'game-page',
        ...(track || {}),
      }}
    />
  );
};
