import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  headerContainer: {
    width: '100%',
    display: 'flex',
    maxWidth: 1232,
    margin: 'auto',
    position: 'relative',
    marginBottom: 9,
    '&:before': {
      content: '" "',
      position: 'absolute',
      background: theme.lightTeal,
      width: 70,
      height: 3,
      bottom: 5,
    },
  },
  title: {
    fontWeight: 'bold',
    letterSpacing: '-0.5px',
    lineHeight: '32px',
    zIndex: 2,
    textAlign: 'left',
    paddingTop: 30,
    color: theme.palette.text.primary,
    '.dark &': {
      color: 'white',
    },
  },
  [theme.breakpoints.down('sm')]: {
    headerContainer: {
      justifyContent: 'center',
    },
  },
});

const MainSectionTitle = ({ classes, className, title }) => {
  return (
    <div className={`${classes.headerContainer} ${className}`}>
      <h1 className={classes.title}>{title}</h1>
    </div>
  );
};

export default withStyles(styles)(MainSectionTitle);
