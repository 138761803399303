import React from 'react';
import { withStyles } from '@material-ui/styles';
import { useFirestoreSubscribeDoc } from '../../hooks';

const styles = theme => ({
  container: {
    height: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    transition: 'height 400ms ease-in-out',
    justifyContent: 'space-around',
    '.open &': {
      height: 44,
    },
  },
  label: {
    marginTop: 4,
    marginBottom: 0,
    color: theme.charcoalGrey,
    fontSize: 10,
    letterSpacing: 0.2,
    lineHeight: '16px',
    textAlign: 'center',
  },
  center: {
    textAlign: 'center',
    '& span': {
      verticalAlign: 'middle',
    },
  },
  shadow: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    width: 30,
    borderRadius: '50%',
    marginRight: 8,
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '24px',
    verticalAlign: 'middle',
    // TODO: get actual colors from designer
    '&.D': {
      background: 'orange',
    },
    '&.C': {
      background: 'brown',
    },
    '&.B': {
      background: '#7805CC', // purple
    },
    '&.A': {
      background: 'blue',
    },
    '&.S': {
      background: '#F4A817', // yellow
    },
  },
});

const getClass = percentage => {
  // 95%+ S
  if (percentage > 0.95) {
    return 'S';
  }

  // 0% - 70% D, 70% - 80% C, 80% - 90% B, 90% - 95% A
  const tenth = Math.floor(percentage * 10);
  return ['D', 'D', 'D', 'D', 'D', 'D', 'D', 'C', 'B', 'A'][tenth];
};

const UserStatistics = ({ classes, userId }) => {
  const stats = useFirestoreSubscribeDoc('contestUserStats', userId);
  const gradeClass = getClass(stats.gradePercentage || 0.9);

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.center}>
          <div className={`${classes.shadow} ${gradeClass}`}>{gradeClass}</div>
          <span>Class</span>
        </div>
        <h6 className={classes.label}>Rank</h6>
      </div>
      <div>
        <div className={classes.center}>
          {stats.correctCount || 0}-{stats.incorrectCount || 0}
        </div>
        <h6 className={classes.label}>Win/Loss</h6>
      </div>
      <div>
        <div className={classes.center}>{Math.floor(stats.correctPercentage * 100)}%</div>
        <h6 className={classes.label}>Win Percentage</h6>
      </div>
    </div>
  );
};

export default withStyles(styles)(UserStatistics);
