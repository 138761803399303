import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';

import FollowingEntityLink from '../FollowingEntityLink';
import SliderInput from './SliderInput';
import { BodyText } from '../../common';
import { getFollowedEntityName } from '../../common/follow';
import { useFollowNotifications, useFollowNotificationsAction, useFollowingEntityMultiSubscribe } from '../../../hooks';

import { getGameById } from '../../../store/selectors/gameData';
import { FOLLOW_FIELDS } from '../../../store/actions/user';
import { getTeamUrl, getTournamentUrl } from '../../../utils/urls';

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 12,
    paddingLeft: 16,
    '&:last-child': {
      marginBottom: 16,
    },
  },
});

const ENTITY_URL_MAP = {
  [FOLLOW_FIELDS.TOURNAMENT_IDS]: getTournamentUrl,
  [FOLLOW_FIELDS.TEAM_IDS]: getTeamUrl,
};

const getLinkName = ({ entityField, name, title, game }) => {
  if (entityField === FOLLOW_FIELDS.TEAM_IDS) {
    return getFollowedEntityName(name, game);
  }
  return title;
};

const NotificationFollowingItem = ({ entity, entityField }) => {
  const { game: gameId, slug, name, title } = entity;
  const classes = useStyles();
  const game = useSelector(state => getGameById(state, gameId));
  const entities = useFollowingEntityMultiSubscribe(entityField);

  const isNotifying = useFollowNotifications(entityField, entity);
  const actionHandler = useFollowNotificationsAction(isNotifying, entityField, entity);

  if (!entities?.length) {
    return null;
  }

  return (
    <div className={classes.container}>
      <FollowingEntityLink
        entity={entity}
        entityField={entityField}
        to={slug ? ENTITY_URL_MAP[entityField](entity) : null}
      >
        <BodyText variant="smallSemiBold">{getLinkName({ entityField, name, title, game })}</BodyText>
      </FollowingEntityLink>
      <SliderInput checked={isNotifying} onChange={actionHandler} />
    </div>
  );
};

export default NotificationFollowingItem;
