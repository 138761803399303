class ChannelManager {
  // `emit` is the callback function when queries receive updates.
  constructor(emit) {
    this.emit = emit; // for emmiting to saga channels
    this.queryCounters = [];
  }

  findQuery(query, objType) {
    return this.queryCounters.find(counter => counter.query.isEqual(query) && counter.objType === objType);
  }

  addSubscriber(query, objType) {
    // check we are already subscribing to the query
    const existingQuery = this.findQuery(query, objType);
    // if we are, simply add increment the count.
    if (existingQuery) {
      existingQuery.count++;
    } else {
      this.queryCounters.push(new QueryCounter(query, objType, this.emit));
    }

    // Return an unsubscribe function for the caller to use when done
    return () => {
      this.removeSubscriber(query, objType);
    };
  }

  removeSubscriber(query, objType) {
    // find the query that is being unsubscribed to
    const existingQuery = this.findQuery(query, objType);
    if (existingQuery) {
      // once we find it, decrease the count of subscribers
      existingQuery.count--;
      // reaching 0 means no one cares about this query anymore and we will stop subscribing.
      if (existingQuery.count <= 0) {
        existingQuery.unsubscribe();
        const index = this.queryCounters.indexOf(existingQuery);
        this.queryCounters.splice(index, 1);
      }
    } else {
      console.error('attempting to unsubscribe from non-subscribed query', query, objType);
    }
  }
}

// This class starts a new query and tracks how many subscribers want it alive
class QueryCounter {
  constructor(query, objType, callback) {
    // we know the first snapshot from a query listener will always be batch-add, so we call `gameDataInitialized` instead.
    let initialized = false;

    this.query = query;
    this.objType = objType;
    this.count = 1;

    this.unsubscribe = query.onSnapshot(snapshot => {
      if (!initialized) {
        initialized = true;

        const payload = snapshot.docChanges().map(change => change.doc.data());
        callback({
          changeType: 'initialized',
          objType: this.objType,
          payload,
        });
      } else {
        snapshot.docChanges().forEach(change => {
          callback({
            changeType: change.type,
            objType: this.objType,
            payload: change.doc.data(),
          });
        });
      }
    });
  }
}

export default ChannelManager;
