import theme from '../../theme/oldTheme';

const ANIMATION_SPEED = '240ms';

export default {
  frameContainer: {
    height: 0,
    paddingTop: '56.25%',
    overflow: 'hidden',
    position: 'relative',
    '&:hover': {
      boxShadow: '0 8px 8px 0 rgba(0,0,0,0.2)',
    },
  },
  cardContainer: {
    borderRadius: 7,
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.2)',
    background: 'white',
    overflow: 'hidden',
    zIndex: 301,
  },

  card: {
    padding: '16px',
    color: '#808080',
    fontFamily: 'Source Sans Pro',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
  },

  cardTextRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '&:first-child': {
      paddingBottom: 8,
    },
    '& a': {
      textDecoration: 'underline',
    },
  },

  viewCount: {
    color: theme.lightPink,
    fontSize: '17px',
    fontWeight: '900',
    textTransform: 'lowercase',
  },

  title: {
    color: 'black',
    fontSize: 17,
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'none',
  },

  teamsText: {
    fontStyle: 'oblique',
    fontWeight: '700',
  },

  hoverOverlay: {
    alignItems: 'center',
    background: 'linear-gradient(180deg, hsla(0, 0%, 0%, 1.0) 0%, transparent 100%)',
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: ({ size }) => (size === 'small' ? '30%' : '20%'),
    justifyContent: 'center',
    opacity: 0,
    position: 'absolute',
    textDecoration: 'none',
    top: 0,
    transition: `opacity ${ANIMATION_SPEED} ease-in`,
    width: '100%',
    zIndex: 500,
    '$frameContainer:hover &': {
      opacity: 1,
    },
    '& h2, & h1': {
      fontStyle: 'italic',
      margin: 0,
      userSelect: 'none',
    },
    '& h2': {
      fontWeight: 300,
      fontSize: ({ size }) => (size === 'small' ? '11px' : '18px'),
      marginBottom: ({ size }) => (size === 'small' ? '-2px' : '-6px'),
    },
    '& h1': {
      fontWeight: 'bold',
      fontSize: ({ size }) => (size === 'small' ? '16px' : '24px'),
    },
  },

  placeholderCard: {
    borderRadius: 7,
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.2)',
    width: 800,
    height: 530,
    overflow: 'hidden',
    background: 'white',
    flexShrink: 0,
    zIndex: 1,
    '& > div': {
      width: '100%',
      height: '80%',
    },
    '& > span': {
      display: 'inline-block',
      margin: 10,
      '&:nth-of-type(1)': {
        height: 24,
        width: '75%',
      },
      '&:nth-of-type(2)': {
        height: 24,
        width: '12.5%',
        float: 'right',
      },
      '&:nth-of-type(3)': {
        height: 16,
        width: '18.75%',
        float: 'left',
      },
      '&:nth-of-type(4)': {
        height: 16,
        width: '18.75%',
        float: 'left',
      },
      '&:nth-of-type(5)': {
        height: 16,
        width: '18.75%',
        float: 'right',
      },
    },
  },

  frameTeams: {
    zIndex: 300,
    visibility: 'visible',
    display: 'flex',
    top: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  teamsPlaying: {
    color: 'white',
    fontSize: 18,
    fontStyle: 'oblique',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    marginTop: 10,
  },

  thumbnail: {
    position: 'relative',
    height: 105,
    width: 105,
    fontSize: 48,
    maxWidth: 130,
    filter: 'drop-shadow(0 0 1px white)',
    margin: '0px auto 10px auto',
    '@media (max-width: 600px)': {
      height: 80,
      width: 80,
      fontSize: 32,
    },
  },

  teamContainer: {
    margin: '0 31px',
    fontFamily: "'Arial Black', 'Arial Bold', Gadget, sans-serif",
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 960px)': {
      margin: 'unset',
    },
    '& > span': {
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 135,
    },
  },

  teamsSeparator: {
    position: 'absolute',
    zIndex: -1,
    top: 0,
    left: 6,
    width: '100%',
    opacity: 0.5,
  },

  remainingTeams: {
    textDecoration: 'underline',
    cursor: 'initial',
    fontSize: 11,
    position: 'absolute',
    bottom: 6,
    right: 6,
  },

  timeLabel: {
    position: 'absolute',
    top: 15,
    left: 10,
    width: 'max-content',
    padding: 4,
    paddingRight: 4,
    background: '#000000',
    opacity: 0.6,
    borderRadius: '2px',
    color: 'white',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: '2px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    zIndex: 500,
  },
};
