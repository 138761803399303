import { cancel } from 'redux-saga/effects';

export default function tryCatchWrapper(fn) {
  return function* wrapped(...args) {
    try {
      return yield fn(...args);
    } catch (e) {
      console.error(e);
      yield cancel();
    }
  };
}
