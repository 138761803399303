import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  loadingShimmer: {
    backgroundColor: theme.palette.shimmer.secondary,
    position: 'relative',
    overflow: 'hidden',
    '&:after': {
      content: "' '",
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundImage: `linear-gradient(to right, ${theme.palette.shimmer.secondary} 0%, ${theme.palette.shimmer.tertiary} 20%, ${theme.palette.shimmer.secondary} 40%, ${theme.palette.shimmer.secondary} 100%)`,
      animation: '$placeHolderShimmer 1s linear forwards infinite',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
    },
  },
  '@keyframes placeHolderShimmer': {
    '0%': {
      transform: 'translate(-100%, 0)',
    },
    '100%': {
      transform: 'translate(100%, 0)',
    },
  },
}));

const withShimmerLoading = WrappedComponent => ({ className, ...props }) => {
  const classes = useStyles();
  return <WrappedComponent className={`${classes.loadingShimmer} ${className}`} {...props} />;
};

export default withShimmerLoading;
