import { useCallback, useState } from 'react';

export default (asyncFn, initialVal = {}) => {
  const [result, setResult] = useState(initialVal);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();

  const doAsyncFn = useCallback(
    async (...args) => {
      setLoading(true);
      setError(undefined);
      try {
        setResult(await asyncFn(...args));
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    },
    [asyncFn]
  );

  return [{ result, isLoading, error }, doAsyncFn];
};
