import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';

import CollapsibleSection from '../CollapsibleSection';
import EmailNotificationRow from './EmailNotificationRow';
import { changeUserArrayOptIn } from '../../../store/actions/user';
import { getMailerOptIn } from '../../../store/selectors/user';
import { EMAIL_OPTIONS, getEmailOptionFields } from './constants';

const useStyles = makeStyles({
  collapsible: {
    '& .content': {
      padding: '0 16px 24px',
    },
  },
});

const EmailNotifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const allNotificationsOptIn = useSelector(state => getMailerOptIn(EMAIL_OPTIONS.ALL)(state));
  const newsletterOptIn = useSelector(state => getMailerOptIn(EMAIL_OPTIONS.NEWSLETTER)(state));
  const generalProductOptIn = useSelector(state => getMailerOptIn(EMAIL_OPTIONS.GENERAL)(state));

  const toggleOption = (option, value) => dispatch(changeUserArrayOptIn('mailers', option, value));
  const EMAIL_OPTION_FIELDS = getEmailOptionFields({
    allNotificationsOptIn,
    newsletterOptIn,
    generalProductOptIn,
  });

  return (
    <CollapsibleSection
      subtitle="Notifications that deal with newsletters, product updates and top news"
      title="By Email"
      className={classes.collapsible}
    >
      {Object.keys(EMAIL_OPTION_FIELDS).map(fieldKey => (
        <EmailNotificationRow
          key={fieldKey}
          title={EMAIL_OPTION_FIELDS[fieldKey].title}
          subtext={EMAIL_OPTION_FIELDS[fieldKey].subtext}
          className={classes[EMAIL_OPTION_FIELDS[fieldKey].className]}
          checked={EMAIL_OPTION_FIELDS[fieldKey].checked}
          disabled={!allNotificationsOptIn}
          onChange={e => {
            toggleOption(fieldKey, e.target.checked);
            if (fieldKey !== EMAIL_OPTIONS.ALL && !allNotificationsOptIn && e.target.checked) {
              // If the user activates one of the single email notifications while
              // the main option is deactivated, activate it also.
              toggleOption(EMAIL_OPTIONS.ALL, true);
            }
          }}
        />
      ))}
    </CollapsibleSection>
  );
};

export default EmailNotifications;
