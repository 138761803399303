// NOTE: This code is shared between Frontend and Admin. Please copy from Frontend to Admin when making changes.
import { useEffect, useMemo, useState } from 'react';

import getFirestoreQuery, { normalize } from '../../utils/getFirestoreQuery';

const useFirestoreQueryMemo = ({ collection, whereParams }) =>
  // NOTE: Disable eslint here because whereParams will always be a new object,
  // so we should skip the memo dependency on whereParams, which triggers
  // the exhaustive-deps eslint rule.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => getFirestoreQuery(collection, whereParams), [collection + JSON.stringify(whereParams)]);

const useFirestoreSubscribeEffect = ({ type, query }) => {
  // Determines the correct state setters and initial values depending on desired `type`.
  const [initialized, setInitialized] = useState(false);
  const [results, setResults] = useState({});
  const formattedResults = useMemo(() => (type === 'array' ? Object.values(results) : results), [results, type]);

  // NOTE: Following the rules-of-hooks we must call hooks in the top-level, so
  // instead of a factory function taking a type parameter, we will abstract the useEffect logic.
  useEffect(() => {
    let isInitialized = false;
    const unsub = query.onSnapshot(snapshot => {
      if (!isInitialized) {
        setResults(normalize(snapshot.docs.map(d => d.data())));
        setInitialized(true); // This is too slow to rely upon internally within useEffect.
        isInitialized = true;
      } else {
        snapshot.docChanges().forEach(change => {
          const data = change.doc.data();
          if (change.type === 'removed') {
            setResults(prevResults => {
              const { [data.id]: removed, ...newResults } = prevResults;
              return newResults;
            });
          } else {
            // added or modified
            setResults(prevResults => ({ ...prevResults, [data.id]: data }));
          }
        });
      }
    });
    return unsub;
    // query should unsub & resub if anything pertaining to the query changes.
  }, [query, setInitialized, setResults]);

  return [formattedResults, !initialized /* Loading State */];
};

// TODO: Refactor argument syntax and update all callers.
export const useFirestoreSubscribe = (collection, whereParams) =>
  useFirestoreSubscribeEffect({
    type: 'array',
    query: useFirestoreQueryMemo({ collection, whereParams }),
  });

// TODO: Refactor argument syntax and update all callers.
export const useFirestoreSubscribeMap = (collection, whereParams) =>
  useFirestoreSubscribeEffect({
    type: 'map',
    query: useFirestoreQueryMemo({ collection, whereParams }),
  });

export const useFirestoreSubscribeQuery = ({ query }) =>
  useFirestoreSubscribeEffect({
    type: 'array',
    query,
  });
