import React from 'react';

const OPTIONS = { hour: 'numeric', minute: 'numeric', hour12: true };
// Intentionally unstyled but broken up in separated <span> tags to allow callers to customize styling
export default date => {
  const dateSplit = date.toLocaleString('en-US', OPTIONS).split(' ');

  return (
    <span>
      {dateSplit[0]}
      <span className="formatted-time-ampm">{dateSplit[1].toLowerCase()}</span>
    </span>
  );
};
