import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { MdMoreVert, MdEvent, MdClear, MdReply } from 'react-icons/md';
import { FaHeart } from 'react-icons/fa';

import ItemCalendarAddingDialog from '../../ItemCalendarAddingDialog';
import ItemSharingDialog from '../../ItemSharingDialog';
import { TrackDiv, TrackSpan } from '../../../common';
import FollowDropdownButton from './FollowDropdownButton';
import { isSignedIn } from '../../../../store/selectors/user';
import { openLoginDialog } from '../../../../store/actions/dialogs';
import { LOGIN_DIALOG_VIEWS } from '../../../../store/reducers/dialogs';
import { LOGIN_SOURCES } from '../../../../store/reducers/user';
import { FOLLOW_FIELDS } from '../../../../store/actions/user';
import { addStaticBackgroundContainer, removeStaticBackgroundContainer } from '../../../../store/actions/uiStates';

const useStyles = makeStyles(theme => ({
  root: {
    width: 24,
    color: theme.palette.text.primary,
    background: 'transparent',
    marginRight: -4,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    boxSizing: 'border-box',
  },

  openIcon: {
    margin: 'auto',
    fontSize: 24,
  },

  overlay: {
    display: 'block',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 99999999,
  },

  dialog: {
    display: 'block',
    position: 'fixed',
    zIndex: 100000000,
    background: theme.palette.background.secondary,
    left: 0,
    bottom: 0,
    width: '100%',
    padding: '0 12px',
    height: 0,
    transition: 'all 250ms ease-in-out',
    boxSizing: 'border-box',
    overflow: 'hidden',
    boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.2)',
    '&.open': {
      height: 260,
      paddingTop: 44,
      paddingBottom: 28,
    },
  },
  menuItem: {
    width: '100%',
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: '12px 20px',
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '24px',
    color: theme.palette.text.primary,
    '& span': {
      verticalAlign: 'middle',
    },
    '&:hover': {
      background: 'rgba(0,0,0,0.1)',
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 32,
    top: 16,
    fontSize: 32,
    color: theme.charcoalGrey,
    letterSpacing: 0,
    lineHeight: '32px',
  },
  icon: {
    fontSize: 24,
    verticalAlign: 'middle',
    marginRight: 8,
  },
  shareIcon: {
    transform: 'rotateY(180deg)',
  },
}));

const MobileItemActionDialog = ({ series, open, handleOpen, handleClose, isMatchView }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isCalendarAddingModalOpen, setIsCalendarAddingModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const isUserSignedIn = useSelector(isSignedIn);

  const { images } = series.tournament;

  useEffect(() => {
    // Prevent scrolling when the dialog is open.
    if (open) {
      dispatch(addStaticBackgroundContainer());
    } else {
      dispatch(removeStaticBackgroundContainer());
    }
  }, [dispatch, open]);

  const getTracking = event => ({
    event,
    eventProperties: { is_match_view: isMatchView, is_signed_in: isUserSignedIn },
    eventOptions: { contextSeriesId: series.id },
  });

  return (
    <>
      <TrackSpan className={classes.root} onClick={handleOpen} track={getTracking('Schedule Item Menu Opened')}>
        <MdMoreVert className={classes.openIcon} />
      </TrackSpan>
      {open && <div className={classes.overlay} onClick={handleClose} />}
      <div className={classnames({ [classes.dialog]: true, open })}>
        <MdClear className={classes.closeIcon} onClick={handleClose} />
        <TrackDiv className={classes.menuItem}>
          <FaHeart className={classes.icon} />
          <FollowDropdownButton
            authContext={{ images, name: series.tournament.title }}
            entity={series.tournament}
            entityField={FOLLOW_FIELDS.TOURNAMENT_IDS}
            handleClose={handleClose}
          />
        </TrackDiv>
        <TrackDiv
          className={classes.menuItem}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault(); // Stop from redirecting if parent is a link.

            if (isUserSignedIn) {
              setIsCalendarAddingModalOpen(!isCalendarAddingModalOpen);
            } else {
              dispatch(
                openLoginDialog({
                  view: LOGIN_DIALOG_VIEWS.ADD_TO_CALENDAR,
                  source: LOGIN_SOURCES.DIALOG_NEW_FEATURE,
                  onLoginSuccess: () => {
                    // Leave time for user to be fetched.
                    setTimeout(() => {
                      setIsCalendarAddingModalOpen(!isCalendarAddingModalOpen);
                    }, 1000);
                  },
                })
              );
            }
            handleClose();
          }}
          track={getTracking('Add To Calendar Clicked')}
        >
          <MdEvent className={classes.icon} />
          <span>Add to Calendar</span>
        </TrackDiv>
        <TrackDiv
          className={classes.menuItem}
          onClick={() => {
            setIsShareModalOpen(!isShareModalOpen);
            handleClose();
          }}
          track={getTracking('Share Schedule Item Clicked')}
        >
          <MdReply className={`${classes.icon} ${classes.shareIcon}`} />
          <span>Share {isMatchView ? 'Match' : 'Event'}</span>
        </TrackDiv>
      </div>
      {isCalendarAddingModalOpen && (
        <ItemCalendarAddingDialog series={series} onCloseClick={() => setIsCalendarAddingModalOpen(false)} />
      )}
      {isShareModalOpen && (
        <ItemSharingDialog
          series={series}
          // Passing it as a second prop so it can be shared with EventScreen which only pass tournament.
          event={series.tournament}
          isMatchView={isMatchView}
          isSignedIn={isUserSignedIn}
          onCloseClick={() => setIsShareModalOpen(false)}
        />
      )}
    </>
  );
};

export default MobileItemActionDialog;
