import React from 'react';

import { useFollowing, useFollowNotifications, useFollowNotificationsAction } from '../../../hooks';
import EntityNotificationButton from './EntityNotificationButton';

const HeaderNotificationButton = ({ entity, entityField }) => {
  const following = useFollowing(entityField, entity);
  const isNotifying = useFollowNotifications(entityField, entity);
  const actionHandler = useFollowNotificationsAction(isNotifying, entityField, entity);

  return (
    following && (
      <EntityNotificationButton
        onClick={actionHandler}
        active={isNotifying}
        track={{ eventProperties: { entityId: entity.id, entityField, isNotifying } }}
      />
    )
  );
};

export default HeaderNotificationButton;
