import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  descriptionContainer: {
    maxWidth: 'calc(100vw - 48px)',
    width: '100%',
    margin: 'auto',
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1232,
    },
    '& h1': {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-start',
      color: theme.palette.text.primary,
      [theme.breakpoints.down(1024)]: {
        justifyContent: 'center',
      },
    },
    '& h1:before': {
      content: '" "',
      position: 'absolute',
      background: theme.lightTeal,
      width: 80,
      height: 3,
      bottom: -15,
    },
    '& p': {
      color: theme.palette.text.primary,
      marginTop: 40,
      lineHeight: '2em',
    },
  },
});

// sanitize to avoid XSS, then remove all p-br-p sequences for unnecessary blank spaces.
const getEventDescription = description => DOMPurify.sanitize(description).replace(/<p><br><\/p>/g, '');

const EventDescription = ({ classes, event, label }) => (
  <div className={classes.descriptionContainer}>
    <h1>{label}</h1>
    <p
      dangerouslySetInnerHTML={{
        __html: getEventDescription(event.description),
      }}
    />
  </div>
);

EventDescription.propTypes = {
  event: PropTypes.object.isRequired,
  label: PropTypes.string,
};

export default withStyles(styles)(EventDescription);
