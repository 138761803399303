import React, { useState, useEffect } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
import { makeStyles } from '@material-ui/core/styles';

import TeamListDropdown from './TeamListDropdown';
import StreamsButton from './StreamsButton';
import LgTeamPageLink from './LgTeamPageLink';
import { TrackLink } from '../common';
import { getSeriesUrl } from '../../utils/urls';

const useStyles = makeStyles(theme => ({
  card: {
    height: 80,
    background: theme.palette.background.primary,
    border: `1px solid ${theme.palette.border.soloDenary}`,
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  cardTextRow: {
    padding: '16px 24px 4px 32px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '&:first-child': {
      paddingBottom: 8,
    },
  },
  title: {
    fontFamily: 'Source Sans Pro',
    fontSize: 17,
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'none',
    color: theme.palette.text.primary,
    width: 466,
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      width: 255,
    },
  },
  bestOf: {
    marginLeft: 32,
    fontSize: 13,
    fontWeight: 600,
    color: theme.suvaGray,
  },
  seriesTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    width: 160,
    marginRight: 8,
    marginLeft: 8,
    '& > a:first-child': {
      width: '100%',
      color: theme.palette.text.primary,
      fontSize: 13,
      lineHeight: '18px',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      marginBottom: 4,
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: 130,
    },
  },
  teamContainer: {
    marginLeft: 32,
    paddingRight: 24,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
  },
  teamListContainer: {
    width: 16,
    height: 16,
    borderRadius: 2,
    background: theme.palette.background.ternidenary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fill: theme.palette.text.soloDenary,
      fontSize: 12,
    },
  },
}));

const getTrack = target => ({
  event: 'Single View Card Link Clicked',
  source: 'single-view-card-link',
  target,
});

const VideoFrameCard = ({ series: { participants }, series }) => {
  const classes = useStyles();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileSize, setIsMobileSize] = useState(window.matchMedia('(max-width: 770px)').matches);

  const streamsArr = Object.values(series.streams || {});
  const isParticipantNameTBD = participants.every(participant => participant.name === 'TBD');
  const renderTeamsCount = isMobileSize ? 3 : 4;

  const checkIsMobileSize = () => {
    // Verify that the size is equal to media query
    setIsMobileSize(window.matchMedia('(max-width: 770px)').matches);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    window.addEventListener('resize', checkIsMobileSize);
    return () => {
      window.removeEventListener('resize', checkIsMobileSize);
    };
  }, []);

  return (
    <div className={classes.card}>
      {/* No Teams */}
      {isParticipantNameTBD && (
        <>
          <div className={classes.cardTextRow}>
            <TrackLink className={classes.title} to={getSeriesUrl(series)} track={getTrack('series-page')}>
              {series.title}
            </TrackLink>
            {streamsArr.length >= 2 && <StreamsButton series={series} />}
          </div>
          <span className={classes.bestOf}>{series.bestOf && `Best of ${series.bestOf}`}</span>
        </>
      )}
      {/* Two Teams */}
      {participants.length === 2 && !isParticipantNameTBD && (
        <div className={classes.cardTextRow}>
          <LgTeamPageLink participant={participants[0]} />
          <div className={classes.seriesTitleContainer}>
            <TrackLink to={getSeriesUrl(series)} track={getTrack('series-page')}>
              {series.title}
            </TrackLink>
            {streamsArr.length >= 2 && <StreamsButton series={series} twoTeams />}
          </div>
          <LgTeamPageLink participant={participants[1]} isReversed />
        </div>
      )}
      {/* Multiple Teams */}
      {participants.length > 2 && (
        <>
          <div className={classes.cardTextRow}>
            <TrackLink className={classes.title} to={getSeriesUrl(series)} track={getTrack('series-page')}>
              {series.title}
            </TrackLink>
            {streamsArr.length >= 2 && <StreamsButton series={series} />}
          </div>
          <div className={classes.teamContainer}>
            {participants
              .map((participant, idx) => {
                return (
                  <LgTeamPageLink
                    key={idx}
                    participant={participant}
                    thirdParticipant={isMobileSize && idx === 2}
                    isSmallView
                  />
                );
              })
              .slice(0, renderTeamsCount)}
            {participants?.length > renderTeamsCount && (
              <span className={classes.teamListContainer}>
                {isDropdownOpen ? <IoMdClose onClick={toggleDropdown} /> : <BsThreeDots onClick={toggleDropdown} />}
                {isDropdownOpen && <TeamListDropdown participants={participants} renderTeamsCount={renderTeamsCount} />}
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default VideoFrameCard;
