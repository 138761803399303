import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import RecommendedArticle from './RecommendedArticle';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  article: {
    marginBottom: 8,
  },
}));

export default ({ articles, currentArticle }) => {
  const classes = useStyles();
  const currentId = currentArticle && currentArticle.id;

  return (
    <div className={classes.root}>
      {(articles || []).map(article => (
        <RecommendedArticle
          article={article}
          className={classes.article}
          isActive={article.id === currentId}
          key={article.id}
        />
      ))}
    </div>
  );
};
