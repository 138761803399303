import React from 'react';
import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';
import { FaDiscord } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

import { TrackA } from '../../../common';

const styles = theme => ({
  headerContainer: {
    marginBottom: 34,
    '& h2': {
      color: theme.palette.text.primary,
      margin: 0,
      fontWeight: 400,
    },
  },
  text: {
    fontSize: 42,
    fontWeight: 900,
    lineHeight: '40px',
    textAlign: 'center',
    color: theme.palette.text.primary,
    margin: 0,
  },
  description: {
    color: theme.palette.text.primary,
    fontSize: 28,
    lineHeight: '32px',
    textAlign: 'center',
    marginBottom: 20,
  },
  imgRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 34,
    '& .discord-icon': {
      color: theme.palette.text.primary,
      fontSize: 85,
    },
    '& .separator': {
      color: theme.palette.text.primary,
      fontSize: 30,
      marginLeft: 30,
      marginRight: 30,
    },
  },
  image: {
    width: 85,
  },
  linkContainer: {
    background: theme.darkGrey,
    color: 'white',
    borderRadius: 8,
    fontSize: 22,
    whiteSpace: 'nowrap',
    padding: '10px 16px',
    marginBottom: 10,
    '& a': {
      fontWeight: 700,
      marginLeft: 8,
      color: 'white',
      textDecoration: 'none',
    },
  },
  container: {
    alignItems: 'center',
    background: theme.palette.background.secondary,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '48px 84px 60px',
    maxWidth: 600,
    '&.withoutCheckbox': {
      padding: '48px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    headerContainer: {
      textAlign: 'center',
      marginBottom: 24,

      '& h2': {
        lineHeight: '20px',
        fontSize: 18,
      },
    },
    linkContainer: {
      fontSize: 20,
      whiteSpace: 'inherit',
      textAlign: 'center',
      marginBottom: 22,
    },
    container: {
      padding: '20px 14px 30px',
      '&.withoutCheckbox': {
        padding: '20px 14px',
      },
    },
    text: {
      fontSize: 22,
      lineHeight: '24px',
    },
    description: {
      marginBottom: 16,
      fontSize: 20,
      lineHeight: '22px',
    },
    imgRow: {
      marginBottom: 24,
      '& .discord-icon': {
        fontSize: 70,
      },
      '& .separator': {
        marginLeft: 20,
        marginRight: 20,
      },
    },
    image: {
      width: 70,
    },
  },
});

const DiscordBotView = ({ classes, isSignedIn, view, source }) => {
  return (
    <div className={classnames({ [classes.container]: true })}>
      <div className={classes.headerContainer}>
        <h2>Introducing...</h2>
        <h1 className={classes.text}>The Juked Esports Bot</h1>
      </div>

      <div className={classes.imgRow}>
        <FaDiscord className="discord-icon" />
        <MdClose className="separator" />
        <img className={classes.image} src="/images/juked-logo-192x192.png" alt="jukedLogo" />
      </div>
      <div className={classes.description}>
        Add live match updates from the esports you care about in <b>your Discord server.</b>
      </div>
      <div className={classes.linkContainer}>
        Customize yours now at
        <TrackA
          href="https://jkd.gg/Discord"
          target="_blank"
          rel="noopener noreferrer"
          track={{
            event: 'DiscordBot Link Clicked',
            eventProperties: { feature: 'discord-bot', is_signed_in: isSignedIn, view },
            target: 'discord-bot',
            source,
          }}
        >
          jkd.gg/Discord
        </TrackA>
      </div>
    </div>
  );
};

export default withStyles(styles)(DiscordBotView);
