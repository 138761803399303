import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { useFirestoreGetDoc, useFirestoreSubscribeQuery } from '../hooks';
import {
  Article,
  HelmetArticle,
  MissingArticle,
  LoadingArticle,
  LoadingArticles,
  RecommendedArticles,
} from '../components/Article';
import { BodyText } from '../components/common';
import withThemeProvider from '../components/Wrappers/withThemeProvider';
import { db } from '../store/sagas/firebase';
import { getFeaturedArticles, getNonFeaturedArticles } from '../store/selectors/articles';
import { isAdminUser } from '../store/selectors/user';
import { ABBR_SOURCE_MAP } from '../utils/articles';

const getWeeksAgo = (weeksCount = 3) => {
  const weeksAgo = new Date();
  weeksAgo.setHours(0, 0, 0, 0); // Zero out so we dont resubscribe constantly.
  weeksAgo.setDate(weeksAgo.getDate() - 7 * weeksCount);
  return weeksAgo;
};

const useMemoQuery = () =>
  useMemo(
    () =>
      db
        .collection('articles')
        .where('isPublished', '==', true)
        .where('attributes.date', '>', getWeeksAgo(3))
        .orderBy('attributes.date', 'desc'),
    []
  );

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.secondary,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    minHeight: theme.contentHeight,
    padding: '12px 24px 36px 24px',
  },
  leftColumn: {
    marginRight: 16,
    width: 400,
  },
  rightColumn: {
    flex: '1', // Fill remaining space
    maxWidth: 816,
  },
  columnHeader: {
    height: 20,
    marginBottom: 8,
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    leftColumn: {
      display: 'none',
    },
  },
}));

const ColumnHeader = ({ title }) => {
  const classes = useStyles();
  const titleEl = title ? <BodyText variant="semiBoldLight">{title}</BodyText> : null;
  return <div className={classes.columnHeader}>{titleEl}</div>;
};

const NewsArticleScreen = ({ match }) => {
  const classes = useStyles();
  const {
    params: { sourceAbbr, sourceId },
    path,
  } = match;

  // Will not query unless valid sourceAbbr.
  const isPreview = Boolean(path.match(/^\/news\/preview\/.*/gi));
  const articleId = ABBR_SOURCE_MAP[sourceAbbr] ? `${ABBR_SOURCE_MAP[sourceAbbr]}:${sourceId}` : null;
  const [article, isArticleLoading] = useFirestoreGetDoc('articles', articleId);
  const [isAdmin, isAdminLoading] = useSelector(isAdminUser);
  const [articles, isArticlesLoading] = useFirestoreSubscribeQuery({ query: useMemoQuery() });

  // We must have a preview URL so that page is not cached before publishing in prerender.io.
  const isLoadComplete = isPreview ? !isArticleLoading && !isAdminLoading : !isArticleLoading;
  const isArticleViewable = Boolean(article && article.isPublished) || Boolean(article && isAdmin);
  const is404 = isLoadComplete && !isArticleViewable;

  const featuredArticles = useSelector(state => getFeaturedArticles(state, { articles }));
  const nonFeaturedArticles = useSelector(state => getNonFeaturedArticles(state, { articles })).slice(0, 8);

  // Tell preview bots that data has loaded. Run in useEffect, after DOM has rendered.
  useEffect(() => {
    if (!isPreview && !isArticleLoading && window.prerenderReady !== true) {
      window.prerenderReady = true;
    }
  });

  // Check if article is published, only allow admins if not.
  return (
    <div className={classes.root}>
      <HelmetArticle article={article} />
      <div className={classes.leftColumn}>
        <ColumnHeader title="Recommended Articles" />
        {!isLoadComplete || isArticlesLoading ? (
          <LoadingArticles />
        ) : (
          <RecommendedArticles articles={[...featuredArticles, ...nonFeaturedArticles]} currentArticle={article} />
        )}
      </div>
      <div className={classes.rightColumn}>
        <ColumnHeader />
        {!isLoadComplete ? <LoadingArticle /> : is404 ? <MissingArticle /> : <Article article={article} />}
      </div>
    </div>
  );
};

export default withThemeProvider()(NewsArticleScreen);
