import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import StreamDropdownItem from './StreamDropdownItem';
import { addStaticBackgroundContainer, removeStaticBackgroundContainer } from '../../../store/actions/uiStates';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.darkCharcoal,
    padding: '8px 16px',
    boxSizing: 'border-box',
    margin: '-8px -20px 8px',
    '&.isMinimalView': {
      background: 'none',
      margin: 'unset',
      padding: 'unset',
    },
  },
  dropdown: {
    minWidth: 204,
    height: 44,
    borderRadius: '4px',
    backgroundColor: '#121313',
    '.isMinimalView &': {
      display: 'none !important',
    },
  },
  dropdownItem: {
    cursor: 'pointer',
    color: theme.palette.text.quinary,
    padding: '8px 12px',
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    lineHeight: '20px',
    margin: 'unset',
    '&.disable': {
      pointerEvents: 'none',
      color: 'lightgrey',
      '& svg': {
        fill: 'lightgrey',
      },
    },
    '&:hover': {
      background: theme.palette.background.tertiary,
    },
    '&.defaultStream': {
      fontSize: 15,
      '&:hover': {
        background: 'none',
      },
    },
    '&.header': {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 500,
    },
  },
  dialog: {
    display: 'block',
    position: 'fixed',
    zIndex: 100000000,
    background: theme.palette.background.secondary,
    left: 0,
    bottom: 0,
    width: '100%',
    padding: '0 12px',
    height: 0,
    transition: 'all 250ms ease-in-out',
    boxSizing: 'border-box',
    overflow: 'hidden',
    boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.2)',
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    '.open &': {
      height: 242,
      padding: 32,
    },
  },
  bar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: -12,
    '& > span': {
      width: 64,
      height: 4,
      borderRadius: 2,
      background: theme.black,
    },
  },
}));

const MobileStreamsDropdown = ({
  series,
  toggleDropdown,
  open,
  selectedStream,
  setSelectedStream,
  isEventMatchScreen,
  isMinimalView,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    // Prevent scrolling when the dialog is open.
    if (open) {
      dispatch(addStaticBackgroundContainer());
    } else {
      dispatch(removeStaticBackgroundContainer());
    }
  }, [dispatch, open]);

  return (
    <div
      className={classnames({
        [classes.root]: true,
        isEventMatchScreen,
        open,
        isMinimalView,
      })}
    >
      <div className={classes.dropdown}>
        <StreamDropdownItem
          className={`${classes.dropdownItem} defaultStream`}
          series={series}
          toggleDropdown={toggleDropdown}
          stream={selectedStream}
          open={open}
          closeButton
        />
      </div>
      <div className={classes.dialog}>
        <div className={classes.bar}>
          <span></span>
        </div>
        <p className={`${classes.dropdownItem} header`}>More Streams:</p>

        {Object.values(series?.streams || {})
          .sort((a, b) => b?.viewerCount - a?.viewerCount)
          .map((stream, index) => {
            const { platformId } = stream || {};
            const shouldRender = selectedStream?.platformId !== platformId;
            return shouldRender ? (
              <StreamDropdownItem
                className={classes.dropdownItem}
                key={index}
                series={series}
                stream={stream}
                setSelectedStream={setSelectedStream}
                toggleDropdown={toggleDropdown}
              />
            ) : null;
          })}
      </div>
    </div>
  );
};

export default MobileStreamsDropdown;
