import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { SearchProvider } from '@elastic/react-search-ui';
import { ThemeProvider } from '@material-ui/styles';

import AppV2 from './AppV2';
import oldLightTheme, { darkTheme as oldDarkTheme } from './theme/oldTheme';
import { initializeMixpanel, initializeSaga } from './store';
import { trackEvent, setAdvocateRef, setDarkMode } from './store/actions/uiStates';

import { isUserDialogOpen } from './store/selectors/dialogs';
import { isSignedIn as getIsSignedIn } from './store/selectors/user';
import { getShownGames } from './store/selectors/gameData';
import { messaging } from './store/sagas/firebase';
import { trackPageGa } from './utils/googleAnalytics';
import { SEARCH_CONFIG } from './utils/search';

import BrowseDrawer from './components/Nav/BrowseDrawer';
import TopTicker from './components/TopTicker';
import Nav from './components/Nav';
import Footer from './components/Footer';
import GamePathHandler from './components/GamePathHandler';

import CompetitionScreen from './screens/CompetitionScreen';
import OrganizationScreen from './screens/OrganizationScreen';
import TeamScreen from './screens/TeamScreen';
import EventScreen from './screens/EventScreen';
import EventMatchScreen from './screens/EventMatchScreen';
import EventLiveScreen from './screens/EventLiveScreen';
import NewsArticleScreen from './screens/NewsArticleScreen';
import SeriesNotifications from './components/Notifications/SeriesNotifications';
import CookieConsent from './components/CookieConsent';
import UserTracker from './components/UserTracker';
import NotificationHandler from './components/Notifications/NotificationHandler';
import UserDialog from './components/User/Dialogs';
import ScrollToTopRoute from './components/Wrappers/ScrollToTopRoute';
import HomeScreen from './screens/HomeScreen';
import CalendarScreen from './screens/CalendarScreen';
import ProviderAuthScreen from './screens/ProviderAuthScreen';
import VodsScreen from './screens/VodsScreen';
import VodScreen from './screens/VodScreen';
import NewsScreen from './screens/NewsScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';
import TermsOfUseScreen from './screens/TermsOfUseScreen';
import AboutUsScreen from './screens/AboutUsScreen';
import MaintenanceScreen from './screens/MaintenanceScreen';
import WindowTitle from './components/WindowTitle';
import { getGameSlugsPrefixPath, renderAuthRoutes } from './utils/appHelpers';
import { isFeatureEnabled } from './utils/featureFlags';
import FollowModeSnackbar from './components/FollowModeSnackbar';

class App extends Component {
  constructor(props) {
    super(props);
    initializeSaga();
    initializeMixpanel();
    this.setupRouterListener();
    this.scrollToAsyncRenderedHashLink();
  }

  scrollToAsyncRenderedHashLink = () => {
    // Wait for elements to render after data is received.
    this.timer = setTimeout(() => {
      const { hash } = window.location;
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        element && element.scrollIntoView({ behavior: 'smooth' });
      }
      this.timer = null;
    }, 2500);
  };

  trackLocation = location => {
    const { trackEvent } = this.props;
    const { hash, pathname, search } = location;
    trackEvent('Page View', { pathname, search, hash });
    trackPageGa(pathname + search + hash);
  };

  setupRouterListener() {
    const { history, location, setAdvocateRef } = this.props;
    const { advref } = queryString.parse(location.search);
    if (!!advref) {
      setAdvocateRef(advref);
    }

    this.trackLocation(location); // Track a location on bootup.
    this.unlistenToHistory = history.listen(location => this.trackLocation(location));
  }

  componentWillUnmount() {
    this.unlistenToHistory();
    this.timer && clearTimeout(this.timer);
  }

  render() {
    const {
      gameList,
      isSignedIn,
      isUserDialogOpen,
      location: { pathname },
    } = this.props;
    const gameSlugsPathPrefix = getGameSlugsPrefixPath({ gameList });

    return (
      <>
        {/* Not a visible component, just to handle game-slug related path changes. */}
        <Route path={gameSlugsPathPrefix} component={GamePathHandler} />

        <div className="App">
          <WindowTitle
            title={'Juked.gg - Watch All Live Esports'}
            description={
              'Visit Juked to watch the top esports tournaments from your favorite esports games - live now, upcoming calendar, and VOD.'
            }
          />

          <header className="App-header" />
          <TopTicker />
          <Nav />
          <BrowseDrawer />
          <Switch>
            <ScrollToTopRoute exact path="/c/:competitionSlug" component={CompetitionScreen} />
            {isFeatureEnabled('organizationPage') && (
              <ScrollToTopRoute exact path="/o/:organizationSlug" component={OrganizationScreen} />
            )}
            {isFeatureEnabled('teamPage') && <ScrollToTopRoute exact path="/t/:teamSlug" component={TeamScreen} />}
            <ScrollToTopRoute exact path="/(e|events)/:tournamentSlug" component={EventScreen} />
            <ScrollToTopRoute exact path="/(e|events)/:tournamentSlug/live" component={EventLiveScreen} />
            <ScrollToTopRoute exact path="/(e|events)/:tournamentSlug/:seriesId" component={EventMatchScreen} />
            <ScrollToTopRoute exact path="/vods/watch/:seriesId" component={VodScreen} />
            <ScrollToTopRoute exact path="/vods" component={VodsScreen} />
            {isFeatureEnabled('articlesPage') && <ScrollToTopRoute exact path="/news" component={NewsScreen} />}
            <ScrollToTopRoute exact path="/news/:sourceAbbr/:sourceId" component={NewsArticleScreen} />
            {/* Another route so that we dont cache the public URL in prerender.io until its published */}
            <ScrollToTopRoute exact path="/news/preview/:sourceAbbr/:sourceId" component={NewsArticleScreen} />
            {/* Add other providers after twitter using | as separator. This is necessary because otherwise the path conflicts with /auth/action */}
            <ScrollToTopRoute exact path="/auth/:provider(twitter)" component={ProviderAuthScreen} />
            <ScrollToTopRoute exact path="/privacy-policy" component={PrivacyPolicyScreen} />
            <ScrollToTopRoute exact path="/terms-of-use" component={TermsOfUseScreen} />
            <ScrollToTopRoute exact path="/about-us" component={AboutUsScreen} />
            <ScrollToTopRoute exact path="/calendar" component={CalendarScreen} />
            <ScrollToTopRoute exact path={gameSlugsPathPrefix} component={HomeScreen} />

            {/* Auth-related routes, needs to be last */}
            {renderAuthRoutes({ pathname, isSignedIn })}
          </Switch>
          <SeriesNotifications />
          <CookieConsent />
          <FollowModeSnackbar />
          <Footer />
          {!!isUserDialogOpen && <UserDialog />}
          {!!messaging && <NotificationHandler />}
        </div>
      </>
    );
  }
}

App = compose(
  withRouter,
  connect(
    state => ({
      gameList: getShownGames(state),
      isUserDialogOpen: isUserDialogOpen(state),
      isSignedIn: getIsSignedIn(state),
    }),
    { trackEvent, setAdvocateRef }
  )
)(App);

class MaintenanceApp extends Component {
  //This will assign the theme, depending on the user OS
  componentDidMount() {
    window.matchMedia('(prefers-color-scheme: dark)').addListener(e => this.props.setDarkMode(e.matches));
  }

  render() {
    const { showMaintenancePage, isFetching, isDarkMode } = this.props;

    if (isFetching) return null;
    return (
      <>
        <ThemeProvider theme={isDarkMode ? oldDarkTheme : oldLightTheme}>
          <Switch>
            {/* Switch shows either the maintenance page OR the login page.
             **/}
            <Route exact path="/maintenance" component={MaintenanceScreen} />
            {showMaintenancePage && <Redirect to={'/maintenance'} />}
            {isFeatureEnabled('designsV2') && <Route path="/v2" component={AppV2} />}
            <SearchProvider config={SEARCH_CONFIG}>
              <Route component={App} />
            </SearchProvider>
          </Switch>
          {isFeatureEnabled('userTracker') && <UserTracker />}
        </ThemeProvider>
      </>
    );
  }
}

MaintenanceApp = compose(
  withRouter,
  connect(
    state => ({
      showMaintenancePage: state.maintenance.showMaintenancePage,
      isFetching: state.maintenance.isFetching,
      isDarkMode: state.uiStates.isDarkMode,
    }),
    { setDarkMode }
  )
)(MaintenanceApp);

export default MaintenanceApp;
