import React, { Component } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { MdFilterList } from 'react-icons/md';

import EventTierFilterSelect from './EventTierFilterSelect';
import { TrackDiv } from '../../common';
import GameFilterSelect from '../../Filters/GameFilterSelect';
import SearchFilter from '../../Filters/SearchFilter';
import withTrackClick from '../../Wrappers/withTrackClick';
import { getPreferredGameIds } from '../../../store/selectors/user';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      order: -1,
      width: '100%',
      flexDirection: 'column',
      margin: [[16, 0]],
    },
  },
  searchContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      position: 'relative',
    },
  },
  filterIcon: {
    display: 'none',
    color: '#AEC3C3',
    fontSize: 28,
    lineHeight: '24px',
    margin: 'auto 0 auto 16px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      '.open &': {
        color: theme.magentaPink,
      },
    },
  },
  filterIndicator: {
    display: 'none',
    height: 16,
    width: 16,
    position: 'absolute',
    top: 16,
    right: -2,
    background: theme.magentaPink,
    color: 'white',
    fontSize: 10,
    lineHeight: '16px',
    textAlign: 'center',
    fontWeight: 600,
    borderRadius: '50%',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      visibility: 'visible',
      '.open &': {
        visibility: 'hidden',
      },
    },
  },
  itemFilter: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
      boxSizing: 'border-box',
      height: 0,
      transition: 'height 250ms ease-in-out',
      overflow: 'hidden',
      '.open &': {
        height: 112,
        overflow: 'visible',
        animation: '$showFilters 350ms linear',
      },
    },
  },
  tierFilter: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 16,
    },
  },
  gameFilter: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 16,
      order: -1,
      width: '100%',
      '& .root': {
        width: '100%',
        margin: 0,
      },
      '& .popper': {
        maxHeight: 310,
        '& .dropdown': {
          maxWidth: 'initial',
        },
      },
    },
  },
  isDisabled: {
    opacity: '24%',
    pointerEvents: 'none',
  },
  '@keyframes showFilters': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 0,
    },
    '80%': {
      opacity: 0.65,
    },
    '100%': {
      opacity: 1,
    },
  },
});

const MobileFilterList = withTrackClick(MdFilterList);

class FilterDropdowns extends Component {
  state = { isMobileFiltersDropdownOpen: false };

  toggleDropdownOpen = () =>
    this.setState(state => ({ isMobileFiltersDropdownOpen: !state.isMobileFiltersDropdownOpen }));

  render() {
    const {
      classes,
      onSearchInputChange,
      searchInput,
      selectedGames,
      onGameChange,
      onSortChange,
      isFollowMode,
      preferredGameIds,
    } = this.props;
    const { isMobileFiltersDropdownOpen } = this.state;

    const mobileMenuTrack = {
      event: 'Calendar Mobile Filters Toggled',
      source: 'calendar-page',
      target: !!isMobileFiltersDropdownOpen ? 'collapsed' : 'expanded',
    };

    return (
      <div className={classnames({ [classes.root]: true, open: isMobileFiltersDropdownOpen })}>
        <div className={classes.searchContainer}>
          <SearchFilter
            className={classes.search}
            searchedText={searchInput}
            onClearSearch={() => onSearchInputChange()}
            onSubmit={searchInput => onSearchInputChange(searchInput)}
            placeHolder="Search for events..."
            track={{
              event: 'Calendar Search Clicked',
              eventProperties: { input: searchInput },
              source: 'calendar-page',
              target: 'search-filter',
            }}
          />
          <MobileFilterList onClick={this.toggleDropdownOpen} className={classes.filterIcon} track={mobileMenuTrack} />
          {!!selectedGames.length && (
            <TrackDiv onClick={this.toggleDropdownOpen} className={classes.filterIndicator} track={mobileMenuTrack}>
              {selectedGames.length}
            </TrackDiv>
          )}
        </div>
        <div className={classes.itemFilter}>
          <div className={classes.tierFilter}>
            <span> Show: </span>
            <EventTierFilterSelect onSortChange={onSortChange} className={classes.tierDrop} />
          </div>
          <GameFilterSelect
            className={classnames({
              [classes.gameFilter]: true,
              [classes.isDisabled]: isFollowMode,
            })}
            selectedGames={isFollowMode ? preferredGameIds : selectedGames || []}
            onItemClick={onGameChange}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(state => ({
    selectedGames: state.uiStates.calendarSelectedGames,
    preferredGameIds: getPreferredGameIds(state),
    isFollowMode: state.uiStates.isFollowMode,
  }))
)(FilterDropdowns);
