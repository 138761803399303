import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    filter: 'drop-shadow(0 0 1px white)',
  },
});

const jukedLogo = '/images/Juked_logo.png';

const getSrc = organization => {
  // Image component shared between organizations and teams.
  return (
    (organization && organization.images && (organization.images.icon || organization.images.default)) || jukedLogo
  );
};

const OrganizationImage = ({ organization, classes, className }) => {
  return (
    <div className={`${classes.root} ${className}`}>
      <img className={classes.image} src={getSrc(organization)} alt="Organization Logo" />
    </div>
  );
};

export default withStyles(styles)(OrganizationImage);
