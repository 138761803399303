import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FaUserFriends } from 'react-icons/fa';
import { MdPerson } from 'react-icons/md';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import PlayerControls from './PlayerControls';
import VideoCardStyles from './VideoCardStyles';
import { GameTag, TrackLink, StreamsDropdown, StreamsTooltip } from '../common';
import TeamPageLink from '../common/TeamPageLink';
import MainPagePlayer from '../Video/MainPagePlayer';
import { getGameById } from '../../store/selectors/gameData';
import { getSelectedStreamId } from '../../store/selectors/uiStates';
import { getUnmutedSeriesId } from '../../store/selectors/video';
import { setStreamCardVolume } from '../../store/actions/video.js';
import { setSelectedStreamMap } from '../../store/actions/uiStates';
import getFormattedTeamNames from '../../utils/getFormattedTeamNames';
import { isFeatureEnabled } from '../../utils/featureFlags';
import { getSeriesUrl, getTournamentUrl } from '../../utils/urls';
import ParticipantImage from '../Images/ParticipantImage';

const getSizedCardStyles = size => {
  switch (size) {
    case 'small':
      return {
        fontMain: 13, //Stream Title
        fontPrimary: 13, //Teams Name
        fontSecondary: 10, //Series Title
        fontTertiary: 10, //Game tag
        imageSize: 22, //Teams Image
        svgFontSize: 14, // Teams placeholder
        //LineHeight
        titleLineHeight: '16px',
        gameTagLineHeight: '24px',
        participantLineHeight: '16px',
        seriesLineHeight: '16px',
        //LetterSpacing
        gameTagLetterSpacing: '1.25px',
        //More Series Icons
        moreStreamLineHeight: '17px',
      };
    case 'medium':
      return {
        fontMain: 18, //Stream Title
        fontPrimary: 15, //Teams Name
        fontSecondary: 13, //Series Title
        fontTertiary: 10, //Game tag
        imageSize: 22, //Teams Image
        svgFontSize: 14, // Teams placeholder
        //LineHeight
        titleLineHeight: '24px',
        gameTagLineHeight: '24px',
        participantLineHeight: '20px',
        seriesLineHeight: '20px',
        //LetterSpacing
        gameTagLetterSpacing: '1.25px',
        //More Series Icons
        moreStreamLineHeight: '16px',
      };
    default:
      return {
        fontMain: 20, //Stream Title
        fontPrimary: 15, //Teams Name
        fontSecondary: 13, //Series Title
        fontTertiary: 12, //Game tag
        imageSize: 22, //Teams Image
        svgFontSize: 14, // Teams placeholder
        //LineHeight
        titleLineHeight: '24px',
        gameTagLineHeight: '24px',
        participantLineHeight: '20px',
        seriesLineHeight: '20px',
        //LetterSpacing
        gameTagLetterSpacing: '1.5px',
        //More Series Icons
        moreStreamLineHeight: '16px',
      };
  }
};

const styles = theme => ({
  ...VideoCardStyles,
  item_1_ContainerOf_3: {
    gridColumn: '1 / 2',
    gridRow: '1 / 3',
  },
  item_2_ContainerOf_3: {
    gridColumn: '2 / 3',
    gridRow: '1 / 2',
  },
  item_3_ContainerOf_3: {
    gridColumn: '2 / 3',
    gridRow: '2 / 3',
  },
  item_1_ContainerOf_5: {
    gridColumn: '1 / 3',
    gridRow: '1 / 3',
  },
  item_2_ContainerOf_5: {
    gridColumn: '3 / 4',
    gridRow: '1 / 2',
  },
  item_3_ContainerOf_5: {
    gridColumn: '3 / 4',
    gridRow: '2 / 4',
  },
  item_4_ContainerOf_5: {
    gridColumn: '2 / 3',
    gridRow: '3 / 4',
  },
  item_5_ContainerOf_5: {
    gridColumn: '1 / 2',
    gridRow: '3 / 4',
  },
  frameContainer: {
    ...VideoCardStyles.frameContainer,
    boxShadow: '2px 2px 12px 0 rgba(10,11,11,0.28)',
    '&:hover': {
      boxShadow: '0 8px 8px 0 rgba(0,0,0,0.2)',
    },
  },
  cardContainer: {
    ...VideoCardStyles.cardContainer,
    borderRadius: 12,
    background: theme.palette.background.secondary,
    //Will allow "More Streams" tooltip show over the card.
    zIndex: 'unset',
    '&:hover': {
      boxShadow: '2px 2px 12px 0 rgba(10,11,11,0.28)',
    },
  },
  hoverOverlay: {
    ...VideoCardStyles.hoverOverlay,
    fontFamily: 'Source Sans Pro',
  },
  title: {
    ...VideoCardStyles.title,
    fontSize: ({ size }) => getSizedCardStyles(size).fontMain,
    lineHeight: ({ size }) => getSizedCardStyles(size).titleLineHeight,
    fontFamily: 'Source Sans Pro',
    color: theme.palette.text.primary,
  },
  teamsText: {
    ...VideoCardStyles.teamsText,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: ({ size }) => getSizedCardStyles(size).participantLineHeight,
    width: '100%',
    fontSize: ({ size }) => getSizedCardStyles(size).fontPrimary,
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
    fontWeight: 'unset',
    fontStyle: 'unset',
    fontFamily: 'Source Sans Pro',
  },
  viewCount: {
    ...VideoCardStyles.viewCount,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 1000,
    top: 16,
    right: 16,
    background: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    lineHeight: '16px',
    fontFamily: 'Source Sans Pro',
    fontWeight: 'bold',
    fontSize: 14,
    margin: '0 5px 0 2px',
    padding: 4,
    letterSpacing: '1px',
    borderRadius: '2px',
  },
  UsersIcon: {
    fontSize: 16,
    marginRight: 5,
  },
  titleLink: {
    textDecoration: 'none !important',
  },
  moreStreamContainer: {
    marginLeft: 12,
    verticalAlign: 'middle',
    cursor: 'pointer',
    display: 'inline-block',
    width: 16,
    height: 16,
    textAlign: 'center',
    borderRadius: 4,
    lineHeight: ({ size }) => getSizedCardStyles(size).moreStreamLineHeight,
    background: theme.darkCharcoalGrey,
    '& > svg': {
      fontSize: 10,
      fill: 'white',
    },
  },
  [theme.breakpoints.down('sm')]: {
    title: {
      ...VideoCardStyles.title,
      fontSize: '14px !important',
      color: theme.palette.text.primary,
    },
    viewCount: {
      fontSize: 14,
    },
    UsersIcon: {
      fontSize: 14,
      marginTop: 2,
    },
  },
  participantImage: {
    height: size => getSizedCardStyles(size).imageSize,
    width: size => getSizedCardStyles(size).imageSize,
    fontSize: size => getSizedCardStyles(size).svgFontSize,
    verticalAlign: 'middle',
    marginRight: 4,
    filter: 'drop-shadow(0 0 1px white)',
  },
  vsText: {
    fontStyle: 'italic',
    fontSize: 15,
    fontWeight: 900,
    color: theme.palette.text.primary,
    verticalAlign: 'middle',
    fontFamily: 'Source Sans Pro',
    marginLeft: 10,
    marginRight: 10,
  },
  gameTag: {
    marginLeft: 8,
    '& > a': {
      fontSize: ({ size }) => getSizedCardStyles(size).fontTertiary,
      lineHeight: ({ size }) => getSizedCardStyles(size).gameTagLineHeight,
      letterSpacing: ({ size }) => getSizedCardStyles(size).gameTagLetterSpacing,
    },
  },
  battleRoyal: {
    marginRight: 18,
  },
  seriesTitle: {
    paddingLeft: 12,
    color: theme.palette.text.quinary,
    fontWeight: 600,
    paddingTop: 10,
    paddingBottom: 10,
    verticalAlign: 'middle',
    fontSize: ({ size }) => getSizedCardStyles(size).fontSecondary,
    lineHeight: ({ size }) => getSizedCardStyles(size).seriesLineHeight,
    '&.noBorder': {
      borderLeft: 'unset',
      marginLeft: 'unset',
    },
  },
  participantName: {
    verticalAlign: 'middle',
    '&.border': {
      borderRight: `1px solid ${theme.mediumGrey}`,
      paddingRight: 12,
    },
  },
  streamsTooltipText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 25,
    left: -50,
    zIndex: 999999,
    width: 120,
    height: 32,
    fontSize: 15,
    lineHeight: '16px',
    fontWeight: 'bold',
    background: '#555',
    color: 'white',
    textAlign: 'center',
    borderRadius: 4,
    visibility: 'hidden',
    '$moreStreamContainer:hover &': {
      visibility: 'visible',
    },
  },
});

class StreamCard extends Component {
  state = {
    cardVolume: `${this.props.volume}` ? this.props.volume * 100 : 50,
    isDropdownOpen: false,
    isControlsVisible: false,
  };

  componentDidUpdate(prevProps) {
    const { volume, stream, series, setSelectedStreamMap } = this.props;
    // This helps keep all sliders syncronized.
    if (prevProps.volume !== volume) {
      this.setState({ cardVolume: volume * 100 });
    }

    if (prevProps?.stream?.online && !stream?.online) {
      setSelectedStreamMap({ seriesId: series.id, streamId: series.stream });
    }
  }

  handleVolumeChange = (_, newValue) => {
    const { setStreamCardVolume } = this.props;
    this.setState({ ...this.state, cardVolume: newValue });
    // Twitch player volume range is from 0.0 to 1.0.
    const volume = (newValue / 100).toFixed(1);
    setStreamCardVolume(volume);
  };

  hideControls = () => {
    this.setState({ isControlsVisible: false });
  };

  showControls = () => {
    this.setState({ isControlsVisible: true });
  };

  toggleDropdown = () => {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
  };

  render() {
    const {
      series: { tournament, participants },
      series,
      selectedStreamId,
      classes,
      index,
      pageItems,
      game,
      unmutedSeriesId,
      size,
    } = this.props;
    const { cardVolume, isDropdownOpen, isControlsVisible } = this.state;
    // If EventMatchScreen or VodScreen was playing a video from the same channel, we would have lingering `videoPlayerState`
    // due to how React first mounts new elements before it unmounts old ones.
    // When a StreamCard gets newly mounted, we always wanna make sure the muted/paused states are reset to default.
    // Comparing to series.id so we can maintain the same audio setup after changing to another language stream.
    const muted = series.id !== unmutedSeriesId;
    const source = 'stream-card';

    const stream = series.streams[selectedStreamId];

    const streamsArr = Object.values(series.streams || {});
    const totalViewerCount = streamsArr.map(stream => stream.viewerCount).reduce((accu, viewer) => accu + viewer);

    const isParticipantNameNotTBD = participants.every(participant => participant.name !== 'TBD');
    return (
      <div
        className={classNames({
          [classes[`item_${index}_ContainerOf_${pageItems}`]]: true,
          [classes.cardContainer]: true,
        })}
      >
        <div className={classes.frameContainer} onMouseEnter={this.showControls} onMouseLeave={this.hideControls}>
          <span className={classes.viewCount}>
            <MdPerson className={classes.UsersIcon} />
            {totalViewerCount.toLocaleString(undefined, { maximumFractionDigits: 0 })}
          </span>
          {Boolean(stream) && (
            <MainPagePlayer
              key={stream.id}
              id={stream.id}
              platformType={stream.platformType}
              muted={muted}
              channel={stream.platformId}
              seriesId={series.id}
            />
          )}
          <TrackLink // Send a tracking event with all series context data.
            to={getSeriesUrl(series)}
            className={classes.hoverOverlay}
            track={{
              event: 'Stream Player Clicked',
              source: 'stream-player',
              target: 'series-page',
              eventOptions: { contextSeriesId: series.id },
            }}
          >
            <h2>Click Here to</h2>
            <h1>Watch Live</h1>
          </TrackLink>
          {Boolean(stream) && (
            <PlayerControls
              handleVolumeChange={this.handleVolumeChange}
              muted={muted}
              platformType={stream.platformType}
              seriesId={series.id}
              isVisible={isControlsVisible}
              volume={cardVolume}
            />
          )}
        </div>
        <div className={classes.card}>
          <div className={classes.cardTextRow}>
            <TrackLink
              to={getTournamentUrl(tournament)}
              className={classes.titleLink}
              track={{
                event: 'Tournament Title',
                source: 'tournament-title',
                target: 'event-page',
              }}
            >
              <span className={classes.title} title={tournament.title}>
                {tournament.title}
              </span>
            </TrackLink>
            <GameTag className={classes.gameTag} game={game} seriesId={series.id} />
          </div>
          <div className={classes.cardTextRow}>
            <span className={classes.teamsText}>
              {participants.length === 2 ? (
                isParticipantNameNotTBD && (
                  <>
                    <TeamPageLink
                      slug={participants[0].slug}
                      participantType={participants[0] && participants[0].participantType}
                      track={{ source }}
                    >
                      <ParticipantImage participant={participants[0]} className={classes.participantImage} />
                      <span className={classes.participantName}>
                        {size === 'small'
                          ? participants[0].shortName
                          : participants[0].name || participants[0].shortName}
                      </span>
                    </TeamPageLink>
                    <span className={classes.vsText}>VS</span>
                    <TeamPageLink
                      slug={participants[1].slug}
                      participantType={participants[1] && participants[1].participantType}
                      track={{ source }}
                    >
                      <ParticipantImage participant={participants[1]} className={classes.participantImage} />

                      <span className={`${classes.participantName} border`}>
                        {size === 'small'
                          ? participants[1].shortName
                          : participants[1].name || participants[1].shortName}
                      </span>
                    </TeamPageLink>
                  </>
                )
              ) : (
                <span className={classes.battleRoyal}>
                  {getFormattedTeamNames(
                    participants.map(p => p.shortName || p.name),
                    'VS'
                  )}
                </span>
              )}
              {isFeatureEnabled('moreStreamsDropdown') && streamsArr.length >= 2 && (
                <span className={classes.moreStreamContainer}>
                  <FaUserFriends onClick={this.toggleDropdown} />
                  <StreamsDropdown
                    series={series}
                    toggleDropdown={this.toggleDropdown}
                    open={isDropdownOpen}
                    TooltipComponent={<StreamsTooltip className={classes.streamsTooltipText} open={isDropdownOpen} />}
                    isMinimalView
                  />
                </span>
              )}

              <TrackLink
                to={getSeriesUrl(series)}
                className={classes.titleLink}
                track={{
                  event: 'Series Title',
                  source: 'series-title',
                  target: 'series-page',
                }}
              >
                <span
                  className={classNames({
                    [classes.seriesTitle]: true,
                    noBorder: !isParticipantNameNotTBD,
                  })}
                >
                  {series.title}
                </span>
              </TrackLink>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    (state, ownProps) => ({
      game: getGameById(state, ownProps.series.game),
      volume: state.localData.streamCardVolume,
      unmutedSeriesId: getUnmutedSeriesId(state),
      selectedStreamId: getSelectedStreamId(state, ownProps.series),
    }),
    { setStreamCardVolume, setSelectedStreamMap }
  )
)(StreamCard);
