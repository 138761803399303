import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  h3: {
    fontSize: '18px !important',
    fontWeight: 'bold',
    lineHeight: '24px',
  },
});

const H3 = ({ classes, className, children }) => <h3 className={`${classes.h3} ${className}`}>{children}</h3>;

export default withStyles(styles)(H3);
