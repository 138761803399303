import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  h5: {
    color: theme.palette.text.primary,
    fontSize: '14px !important',
    fontWeight: 'normal',
    lineHeight: '20px',
    letterSpacing: '3px',
    textTransform: 'uppercase',
  },
}));

export default ({ className, children }) => {
  const classes = useStyles();
  return <h5 className={`${classes.h5} ${className}`}>{children}</h5>;
};
