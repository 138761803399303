import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { isDataLoaded } from '../../../store/selectors/gameData';

/**
 * Requires prop isDataLoaded and returns a component injected with ref to pass to Carousel.
 */
const withLoadingCarousel = LoadingCarousel => WrappedComponent => {
  class WrapperComponent extends Component {
    constructor(props) {
      super(props);
      this.vodSlider = React.createRef();
      this.state = { isMobileSize: window.matchMedia('(max-width: 700px)').matches };
    }

    shouldComponentUpdate(nextProps) {
      return !!nextProps.isDataLoaded;
    }

    componentDidUpdate(prevProps, prevState) {
      const prevGameSlug = prevProps.game && prevProps.game.slug;
      const gameSlug = this.props.game && this.props.game.slug;
      if (prevGameSlug !== gameSlug || prevState.isMobileSize !== this.state.isMobileSize) {
        if (this.vodSlider.current) {
          this.vodSlider.current.slickGoTo(0);
        }
      }
    }

    componentDidMount() {
      window.addEventListener('resize', this.checkIsMobileSize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.checkIsMobileSize);
    }

    checkIsMobileSize = () => {
      // Verify that the size is equal to media query
      this.setState({ isMobileSize: window.matchMedia('(max-width: 700px)').matches });
    };

    render() {
      const { isDataLoaded, classes, ...props } = this.props;
      const { isMobileSize } = this.state;

      return !isDataLoaded ? (
        // Skip sending classes to LoadingCarousel.
        <LoadingCarousel isMobileSize={isMobileSize} {...props} />
      ) : (
        <WrappedComponent classes={classes} ref={this.vodSlider} {...props} />
      );
    }
  }

  return compose(
    connect(
      state => ({
        isDataLoaded: isDataLoaded(state),
      }),
      {}
    )
  )(WrapperComponent);
};

export default withLoadingCarousel;
