import { Dict } from 'mixpanel-browser';

import { UserPreferences } from '../types';

export const RESET_UI_STATES = 'RESET_UI_STATES';
export const SET_MIXPANEL_LOADED = 'SET_MIXPANEL_LOADED';
export const TOGGLE_CALENDAR_GAME_FILTER = 'TOGGLE_CALENDAR_GAME_FILTER';
export const SET_CALENDAR_GAME_ID_TO_URL = 'SET_CALENDAR_GAME_ID_TO_URL';
export const SET_CALENDAR_GAME_FILTER = 'SET_CALENDAR_GAME_FILTER';
export const TOGGLE_GAME_FILTERS = 'TOGGLE_GAME_FILTERS';
export const SET_CALENDAR_FILTER = 'SET_CALENDAR_FILTER';
export const SET_CLOSED_BANNER_ID = 'SET_CLOSED_BANNER_ID';
export const TRACK_EVENT = 'TRACK_EVENT';
export const TRACK_USER = 'TRACK_USER';
export const TOGGLE_SCHEDULE_VIEW = 'TOGGLE_SCHEDULE_VIEW';
export const SET_COOKIE_CONSENT = 'SET_COOKIE_CONSENT';
export const SET_RECURRENT_USER = 'SET_RECURRENT_USER';
export const SET_ADVOCATE_REF = 'SET_ADVOCATE_REF';
export const SET_DARK_MODE = ' SET_DARK_MODE';
export const SET_USER_TRACKED_AT = 'SET_USER_TRACKED_AT';
export const SET_VOD_FILTERS = 'SET_VOD_FILTERS';
export const SET_ARTICLE_FILTERS = 'SET_ARTICLE_FILTERS';
export const SET_SINGLE_PLAYER_SERIES_ID = 'SET_SINGLE_PLAYER_SERIES_ID';
export const SET_VISITOR_UUID = 'SET_VISITOR_UUID';
export const SET_SELECTED_STREAM_MAP = 'SET_SELECTED_STREAM_MAP';
export const CLEAN_STREAMS_MAP = 'CLEAN_STREAMS_MAP';
export const TOGGLE_BROWSE_DRAWER = 'TOGGLE_BROWSE_DRAWER';
export const TOGGLE_VIDEO_SWATCH_VIEW = 'TOGGLE_VIDEO_SWATCH_VIEW';
export const TOGGLE_SHOW_TOP_EVENTS_ON_VIDEO_SWATCH = 'TOGGLE_SHOW_TOP_EVENTS_ON_VIDEO_SWATCH';
export const SET_FOLLOW_MODE = 'SET_FOLLOW_MODE';
export const ADD_STATIC_BACKGROUND_CONTAINER = 'ADD_STATIC_BACKGROUND_CONTAINER';
export const REMOVE_STATIC_BACKGROUND_CONTAINER = 'REMOVE_STATIC_BACKGROUND_CONTAINER';

export type TrackEventOptions = {
  contextSeriesId?: string;
};

export type Track = {
  event: string;
  eventProperties?: Dict;
  eventOptions?: TrackEventOptions;
  source?: string;
  target?: string;
};

interface ResetUiStatesAction {
  type: typeof RESET_UI_STATES;
}

export const resetUiStates = (): UiStatesActionTypes => ({
  type: RESET_UI_STATES,
});

interface ToggleCalendarGameFilterAction {
  type: typeof TOGGLE_CALENDAR_GAME_FILTER;
  gameId: string;
}

export const toggleCalendarGameFilter = (gameId: string): UiStatesActionTypes => ({
  type: TOGGLE_CALENDAR_GAME_FILTER,
  gameId,
});

interface SetCalendarGameIdToUrlAction {
  type: typeof SET_CALENDAR_GAME_ID_TO_URL;
  gameIds: string[];
}

export const setCalendarGameIdToUrl = (gameIds: string[]): UiStatesActionTypes => ({
  type: SET_CALENDAR_GAME_ID_TO_URL,
  gameIds,
});

interface SetCalendarGameFilterAction {
  type: typeof SET_CALENDAR_GAME_FILTER;
  gameId: string;
}

export const setCalendarGameFilter = (gameId: string): UiStatesActionTypes => ({
  type: SET_CALENDAR_GAME_FILTER,
  gameId,
});

interface ToggleGameFilterAction {
  type: typeof TOGGLE_GAME_FILTERS;
  id: string;
}

export const toggleGameFilter = (id: string): UiStatesActionTypes => ({
  type: TOGGLE_GAME_FILTERS,
  id,
});

interface SetClosedBannerIdAction {
  type: typeof SET_CLOSED_BANNER_ID;
  bannerId: string;
}

export const setClosedBannerId = (bannerId: string): UiStatesActionTypes => ({
  type: SET_CLOSED_BANNER_ID,
  bannerId,
});

interface SetCalendarFilterAction {
  type: typeof SET_CALENDAR_FILTER;
  filter: string;
  preferredGameIds: UserPreferences['gameIds'];
}

export const setCalendarFilter = (filter: string, preferredGameIds: string[]): UiStatesActionTypes => ({
  type: SET_CALENDAR_FILTER,
  filter,
  preferredGameIds, // Only used when changing calendar filter to favorites.
});

interface SetMixpanelLoadedAction {
  type: typeof SET_MIXPANEL_LOADED;
  trackEvent: Track;
}

export const setMixpanelLoaded = (): UiStatesActionTypes => ({
  type: SET_MIXPANEL_LOADED,
  trackEvent: { event: 'Boot Up' },
});

export interface TrackEventAction {
  type: typeof TRACK_EVENT; // Redux-Saga takes TakeableChannel<unknown>, an action.
  event: string;
  properties: Dict;
  options: TrackEventOptions;
}

/**
 * Action to submit an event to be tracked.  For now this is soley in mixpanel.
 * @param {string} event name to be tracked in tracking backend (mixpanel)
 * @param {object} properties properties to be tracked (mixpanel)
 * @param {object} options
 * @param {string} options.contextSeriesId provide series ID to include series context in event properties
 */
export const trackEvent = (event: string, properties: Dict, options: TrackEventOptions): UiStatesActionTypes => ({
  type: TRACK_EVENT,
  event,
  properties,
  options,
});

interface ToggleScheduleViewAction {
  type: typeof TOGGLE_SCHEDULE_VIEW;
  scheduleView: boolean;
}

export const toggleScheduleView = (scheduleView: boolean): UiStatesActionTypes => ({
  type: TOGGLE_SCHEDULE_VIEW,
  scheduleView,
});

interface SetCookieConsentAction {
  type: typeof SET_COOKIE_CONSENT;
}

export const setCookieConsent = (): UiStatesActionTypes => ({
  type: SET_COOKIE_CONSENT,
});

interface SetRecurrentUserAction {
  type: typeof SET_RECURRENT_USER;
}

// Dialog views are different for first timme visitors.
export const setRecurrentUser = (): UiStatesActionTypes => ({
  type: SET_RECURRENT_USER,
});

interface SetAdvocateRefAction {
  type: typeof SET_ADVOCATE_REF;
  ref: string | null;
}

export const setAdvocateRef = (ref: string | null): UiStatesActionTypes => ({
  type: SET_ADVOCATE_REF,
  ref,
});

interface SetDarkModeAction {
  type: typeof SET_DARK_MODE;
  isDarkMode: boolean;
}

export const setDarkMode = (isDarkMode: boolean): UiStatesActionTypes => ({
  type: SET_DARK_MODE,
  isDarkMode,
});

interface SetSinglePlayerSeriesIdAction {
  type: typeof SET_SINGLE_PLAYER_SERIES_ID;
  seriesId: string;
}

export const setSinglePlayerSeriesId = (seriesId: string): UiStatesActionTypes => ({
  type: SET_SINGLE_PLAYER_SERIES_ID,
  seriesId,
});

interface SetUserTrackedAtAction {
  type: typeof SET_USER_TRACKED_AT;
}

export const setUserTrackedAt = (): UiStatesActionTypes => ({
  type: SET_USER_TRACKED_AT,
});

interface SetVisitorUUIDAction {
  type: typeof SET_VISITOR_UUID;
  visitorUUID: string;
}

export const setVisitorUUID = (visitorUUID: string): UiStatesActionTypes => ({
  type: SET_VISITOR_UUID,
  visitorUUID,
});

export interface TrackUserAction {
  type: typeof TRACK_USER;
  uid: string;
  isAuthenticated: boolean;
  isAdBlocked: boolean;
}

export const trackUser = (uid: string, isAuthenticated: boolean, isAdBlocked: boolean): UiStatesActionTypes => ({
  type: TRACK_USER,
  uid,
  isAuthenticated,
  isAdBlocked,
});

type VodFilters = {
  search: string;
  sort: string;
  toggleGameId: string;
  gameIds: string[];
};

interface SetVodFiltersAction extends VodFilters {
  type: typeof SET_VOD_FILTERS;
}

export const setVodFilters = ({ search, sort, toggleGameId, gameIds }: VodFilters): UiStatesActionTypes => ({
  type: SET_VOD_FILTERS,
  toggleGameId,
  search,
  sort,
  gameIds,
});

type ArticleFilters = {
  search: string;
};

interface SetArticleFiltersAction extends ArticleFilters {
  type: typeof SET_ARTICLE_FILTERS;
}

export const setArticleFilters = ({ search }: ArticleFilters): UiStatesActionTypes => ({
  type: SET_ARTICLE_FILTERS,
  search,
});

type SelectedStreamMap = {
  seriesId: string;
  streamId: string;
};

interface SetSelectedStreamMap extends SelectedStreamMap {
  type: typeof SET_SELECTED_STREAM_MAP;
}

export const setSelectedStreamMap = ({ seriesId, streamId }: SelectedStreamMap): UiStatesActionTypes => ({
  type: SET_SELECTED_STREAM_MAP,
  seriesId,
  streamId,
});

type cleanStreamsMapAction = {
  streamMap: Record<string, string>;
};

export interface CleanStreamsMap extends cleanStreamsMapAction {
  type: typeof CLEAN_STREAMS_MAP;
}

export const cleanStreamsMap = ({ streamMap }: cleanStreamsMapAction): UiStatesActionTypes => ({
  type: CLEAN_STREAMS_MAP,
  streamMap,
});

interface ToggleBrowseDrawerAction {
  type: typeof TOGGLE_BROWSE_DRAWER;
  isBrowseDrawerOpen: boolean | null;
}

export const toggleBrowseDrawer = (isBrowseDrawerOpen: boolean | null): UiStatesActionTypes => ({
  type: TOGGLE_BROWSE_DRAWER,
  isBrowseDrawerOpen,
});

interface ToggleVideoSwatchView {
  type: typeof TOGGLE_VIDEO_SWATCH_VIEW;
  isSinglePlayerView: boolean;
}

export const toggleVideoSwatchView = (isSinglePlayerView: boolean): UiStatesActionTypes => ({
  type: TOGGLE_VIDEO_SWATCH_VIEW,
  isSinglePlayerView,
});

interface ToggleShowTopEventsOnVideoSwatch {
  type: typeof TOGGLE_SHOW_TOP_EVENTS_ON_VIDEO_SWATCH;
  showTopEventsOnVideoSwatch: boolean;
}

export const toggleShowTopEventsOnVideoSwatch = (showTopEventsOnVideoSwatch: boolean): UiStatesActionTypes => ({
  type: TOGGLE_SHOW_TOP_EVENTS_ON_VIDEO_SWATCH,
  showTopEventsOnVideoSwatch,
});

interface SetFollowModeAction {
  type: typeof SET_FOLLOW_MODE;
  isFollowMode: boolean;
}

export const setFollowMode = (isFollowMode: boolean): UiStatesActionTypes => ({
  type: SET_FOLLOW_MODE,
  isFollowMode,
});

interface AddStaticBackgroundContainerAction {
  type: typeof ADD_STATIC_BACKGROUND_CONTAINER;
}

export const addStaticBackgroundContainer = (): UiStatesActionTypes => ({
  type: ADD_STATIC_BACKGROUND_CONTAINER,
});

interface RemoveStaticBackgroundContainerAction {
  type: typeof REMOVE_STATIC_BACKGROUND_CONTAINER;
}

export const removeStaticBackgroundContainer = (): UiStatesActionTypes => ({
  type: REMOVE_STATIC_BACKGROUND_CONTAINER,
});

export type UiStatesActionTypes =
  | ResetUiStatesAction
  | ToggleCalendarGameFilterAction
  | SetCalendarGameIdToUrlAction
  | SetCalendarGameFilterAction
  | ToggleGameFilterAction
  | SetCalendarFilterAction
  | SetClosedBannerIdAction
  | SetMixpanelLoadedAction
  | TrackEventAction
  | ToggleScheduleViewAction
  | SetCookieConsentAction
  | SetRecurrentUserAction
  | SetAdvocateRefAction
  | SetDarkModeAction
  | SetVodFiltersAction
  | SetArticleFiltersAction
  | SetSinglePlayerSeriesIdAction
  | SetUserTrackedAtAction
  | SetVisitorUUIDAction
  | TrackUserAction
  | SetSelectedStreamMap
  | CleanStreamsMap
  | ToggleBrowseDrawerAction
  | ToggleVideoSwatchView
  | ToggleShowTopEventsOnVideoSwatch
  | SetFollowModeAction
  | RemoveStaticBackgroundContainerAction
  | AddStaticBackgroundContainerAction;
