import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

import MatchPageTitle from './MatchPageTitle';
import LiveStreamPlayer from './LiveStreamPlayer';
import Chat from '../Video/Chat';
import TournamentCalendar from '../CalendarTable/TournamentCalendar';
import { EventViewershipInfo, EventStandings, StreamsDropdown, EntityArticleSection } from '../common';

import { getSelectedStreamId } from '../../store/selectors/uiStates';
import { getStandingsById } from '../../store/selectors/gameData';
import { setUnmutedSeriesId, toggleTheaterMode } from '../../store/actions/video';
import { isFeatureEnabled } from '../../utils/featureFlags';

const CHAT_CONTAINER_WIDTH = 360;

const useStyles = makeStyles(theme => ({
  root: {
    '&.isTheaterMode': {
      position: 'fixed',
    },
  },
  mediaContent: {
    width: '100%',
    display: 'flex',
    '.isTheaterMode &': {
      position: 'fixed',
      height: '100%',
      zIndex: 100, // To beat CalendarItems and BracketButton
      background: 'black',
    },
  },
  mainContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chatContainer: {
    position: 'relative',
    height: '100%',
    minWidth: CHAT_CONTAINER_WIDTH, // Keep chat spacing and fit PickEms animations
    '.isTheaterMode &': {
      height: '100vh',
    },
    '.isChatCollapsed &': {
      display: 'none',
    },
  },
  viewerCountContainer: {
    display: 'block',
    backgroundColor: '#1A1A1A',
    width: '100%',
    boxSizing: 'border-box',
    padding: 20,
    position: 'relative',
    '.isTheaterMode &': {
      display: 'none',
    },
  },
  [theme.breakpoints.down('sm')]: {
    chatContainer: {
      display: 'none',
    },
    viewerCountContainer: {
      paddingTop: 8,
    },
  },
}));

// Prevent frameContainer from overflowing when TheaterMode is active.
const getFrameMaxWidth = (isTheaterMode, isChatCollapsed) => {
  if (!isTheaterMode) {
    return 'inherit';
  }
  const playerMaxWidth = window.innerHeight * (16 / 9);

  if (!isChatCollapsed) {
    return playerMaxWidth + CHAT_CONTAINER_WIDTH;
  }

  return playerMaxWidth;
};

const LiveMatchLayout = ({ series }) => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isChatCollapsed, setIsChatCollapsed] = useState(false);
  const [playerMaxWidth, setPlayerMaxWidth] = useState('inherit');

  const isTheaterMode = useSelector(state => state.video.isTheaterMode);
  const selectedStreamId = useSelector(state => getSelectedStreamId(state, series));
  const standings = useSelector(state => getStandingsById(state, series.tournament?.id));

  // Calculates frameContainer max width to prevent overflowing on TheaterMode.
  const handleResize = useCallback(() => {
    const maxWidth = getFrameMaxWidth(isTheaterMode, isChatCollapsed);
    setPlayerMaxWidth(maxWidth);
  }, [isTheaterMode, isChatCollapsed]);

  useEffect(() => {
    const bigScreen = !window.matchMedia('(max-width: 960px)').matches;
    bigScreen && dispatch(setUnmutedSeriesId(series.id, true /* Prevent unmuting players outside first page */));
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch, series, handleResize]);

  useEffect(() => {
    return () => {
      if (isTheaterMode) {
        dispatch(toggleTheaterMode());
      }
    };
    // Disabling eslint so this unmount function only execute once and not after each isTheaterMode change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleTheaterMode = useCallback(() => {
    setPlayerMaxWidth(getFrameMaxWidth(!isTheaterMode, isChatCollapsed));
    dispatch(toggleTheaterMode());
  }, [dispatch, isChatCollapsed, isTheaterMode]);

  const toggleCollapseChat = useCallback(() => {
    setPlayerMaxWidth(getFrameMaxWidth(isTheaterMode, !isChatCollapsed));
    setIsChatCollapsed(!isChatCollapsed);
  }, [isChatCollapsed, isTheaterMode]);

  const toggleDropdownOpen = useCallback(() => setIsDropdownOpen(!isDropdownOpen), [isDropdownOpen]);

  const classes = useStyles();
  const { participants } = series;
  const competition = series.tournament && series.tournament.competition;
  const stream = series.streams && series.streams[selectedStreamId];

  const streamsArr = Object.values(series.streams || {});

  return (
    <div
      className={classNames({
        [classes.root]: true,
        isTheaterMode,
        isChatCollapsed,
      })}
    >
      <MatchPageTitle series={series} />

      <div className={classes.mediaContent}>
        <div className={classes.mainContent}>
          <LiveStreamPlayer
            series={series}
            isTheaterMode={isTheaterMode}
            handleToggleTheaterMode={handleToggleTheaterMode}
            isChatCollapsed={isChatCollapsed}
            toggleCollapseChat={toggleCollapseChat}
            playerMaxWidth={playerMaxWidth}
          />
          <div className={classes.viewerCountContainer}>
            {!isTheaterMode && Boolean(stream) && isFeatureEnabled('moreStreamsDropdown') && streamsArr.length >= 2 && (
              <StreamsDropdown
                series={series}
                toggleDropdown={toggleDropdownOpen}
                open={isDropdownOpen}
                isEventMatchScreen
              />
            )}
            <EventViewershipInfo
              tournament={series.tournament}
              aSeries={series}
              competition={competition}
              streamsArr={streamsArr}
              source={'event-match-screen'}
            />
          </div>
        </div>
        <div>
          {/* Chat -  Renders only when chat is not collapsed */}
          {Boolean(stream) && (
            <Chat
              className={classes.chatContainer}
              isChatCollapsed={isChatCollapsed}
              series={series}
              stream={stream}
              toggleCollapseChat={toggleCollapseChat}
            />
          )}
        </div>
      </div>

      <EventStandings aSeries={series} participants={participants} standings={standings} />
      <TournamentCalendar title="Schedule" tournamentId={series.tournament.id} />
      <EntityArticleSection title={`${series.tournament.title} News`} queryProps={{ eventId: series.tournament.id }} />
    </div>
  );
};

export default LiveMatchLayout;
