import React from 'react';

import FollowButton from './FollowButton';
import { useFollowing, useFollowAction } from '../../../../hooks';

const FollowEntityButton = ({ authContext: context, entity, entityField }) => {
  const following = useFollowing(entityField, entity);
  const actionHandler = useFollowAction(following, entityField, entity);

  return <FollowButton context={context} following={following} actionHandler={actionHandler} />;
};

export default FollowEntityButton;
