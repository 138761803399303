import React from 'react';
import { useSelector } from 'react-redux';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { makeStyles } from '@material-ui/core/styles';
import { getGames } from '../../../store/selectors/gameData';
import { getTeamUrl, getGameUrl } from '../../../utils/urls';
import { TrackLink } from '../../common';

const useStyles = makeStyles(theme => ({
  root: {
    color: 'white',
    display: 'flex',
    marginBottom: 28,
    alignItems: 'center',
    textDecoration: 'none',
  },
  image: {
    width: 32,
    height: 32,
    borderRadius: 4,
    marginRight: 12,
    filter: 'drop-shadow(0 0 1px white)',
    objectFit: 'contain',
  },
  name: {
    flex: 1,
    maxWidth: 228,
    width: '100%',
    fontSize: 18,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  svgContainer: {
    fontSize: 24,
    color: theme.darkGrey,
  },
}));

export const SearchResult = ({ images, name, game, abbr, slug, close, entityType }) => {
  const classes = useStyles();
  const games = useSelector(getGames);

  const { image, displayName, gameAbbr, url } = React.useMemo(() => {
    // NOTE: since this will have all the entities Delan and I
    // decided using the Juked logo would be easier to deal with
    // than having a bunch of different placeholders
    // designs showed square logs, but not all have square, so decided to prioritize square
    const image =
      (images && (images['square'] || Object.values(images).find(img => typeof img === 'string'))) ||
      '/images/Juked_logo.png';

    let url = '';
    switch (entityType) {
      case 'teams':
        if (slug) {
          url = getTeamUrl({ slug });
        }
        break;
      case 'games':
        if (abbr) {
          url = getGameUrl({ abbr });
        }
        break;
      default:
    }

    return {
      displayName: name ?? '',
      image,
      gameAbbr: game && games[game] ? (games[game] && !games[game].hidden ? `(${games[game].abbr})` : '') : '',
      url,
    };
  }, [images, name, game, abbr, slug, entityType, games]);

  return (
    <TrackLink
      className={classes.root}
      onClick={close}
      to={url}
      track={{
        event: 'Global Search Clicked',
        source: 'results-list',
        target: 'result-item',
        eventProperties: { url },
      }}
    >
      <img className={classes.image} src={image} alt={image ? `${name} ${gameAbbr ? gameAbbr : ''}` : undefined} />
      <span className={classes.name} title={`${displayName} ${gameAbbr && `${gameAbbr}`}`}>
        {displayName} {gameAbbr && `${gameAbbr}`}
      </span>
      <span className={classes.svgContainer}>
        <MdKeyboardArrowRight />
      </span>
    </TrackLink>
  );
};
