import React from 'react';
import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  buttonBase: {
    width: '100%',
  },
  itemContainer: {
    alignItems: 'center',
    backgroundColor: theme.palette.favoriteGame.primary,
    borderRadius: 4,
    color: theme.palette.text.primary,
    cursor: 'pointer',
    display: 'flex',
    height: 48,
    minWidth: 168,
    width: '100%',
    overflow: 'hidden',
  },
  imgContainer: {
    backgroundColor: theme.mediumGrey,
    backgroundImage: ({ imgSrc }) => `${imgSrc ? `url(${imgSrc})` : 'none'}`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100%',
    width: 36,
  },
  label: {
    fontSize: 13,
    padding: '8px 10px',
    textAlign: 'left',
  },
  [theme.breakpoints.down('sm')]: {
    label: {
      fontSize: 11,
    },
  },
}));

const PickerItem = ({ disabled, label, onClick, imgSrc = null }) => {
  const classes = useStyles({ imgSrc });

  return (
    <ButtonBase className={classes.buttonBase} onClick={onClick} disabled={!!disabled}>
      <div className={classes.itemContainer}>
        <div className={classes.imgContainer}></div>
        <div className={classes.label}>{label}</div>
      </div>
    </ButtonBase>
  );
};

export default PickerItem;
