import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { TYPE_ICON_MAP } from '../../../store/reducers/video';

const useStyles = makeStyles(theme => ({
    youtube: {
      fill: '#E62217',
    },
    twitch: {
      fill: '#9146FF',
    },
  }));

  const StreamDropdownIcon = ({provider}) => {
    const classes = useStyles();
    const Icon = TYPE_ICON_MAP[provider];
    return (
        <Icon
          className={classnames({
            [classes.youtube]: Number(provider) === 8,
            [classes.twitch]: Number(provider) === 1,
          })}
        />
      );
  }

  export default StreamDropdownIcon