import React from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

import { TrackLink } from './Track';
import { isFeatureEnabled } from '../../utils/featureFlags';

const useStyles = makeStyles(theme => ({
  participantLink: {
    textDecoration: 'none !important',
    color: theme.palette.text.primary,
    cursor: 'default',
    pointerEvents: 'none',
    '&.enableLink': {
      pointerEvents: 'all',
      cursor: 'pointer',
    },
  },
}));

const TeamPageLink = ({ slug, participantType, className, children, track }) => {
  const classes = useStyles();
  return (
    <TrackLink
      to={`/t/${slug}`}
      track={{
        event: 'Team Page Link Clicked',
        target: 'team-page',
        ...(track || {}),
        eventProperties: {
          team_slug: slug,
          ...((track && track.eventProperties) || {}),
        },
      }}
      onClick={e => {
        e.stopPropagation();
      }}
      className={classNames({
        [classes.participantLink]: true,
        [className]: className,
        enableLink: participantType === 'TEAM' && isFeatureEnabled('teamPage'),
      })}
    >
      {children}
    </TrackLink>
  );
};

export default TeamPageLink;
