import { isDataLoaded, createArrEqSelector } from './gameData';
import { getFilteredLiveSeries } from './liveSeries';

const getUnmutedSeiesId = ({ video: { unmutedSeriesId } }) => unmutedSeriesId;
const getIsOnlyFirstPage = ({ video: { isMatchScreenPlayer } }) => isMatchScreenPlayer;

export const getVideoPlayerState = ({ video: { playerStates } }, id) => playerStates[id];

export const getPausedSeriesState = ({ video: { pausedSeriesState } }) => pausedSeriesState;

export const getUnmutedSeriesId = createArrEqSelector(
  isDataLoaded,
  getFilteredLiveSeries,
  getUnmutedSeiesId,
  getIsOnlyFirstPage,
  (loaded, liveSeries, unmutedSeriesId, isMatchScreenPlayer) => {
    if (!loaded) {
      return null;
    }

    const playingSeriesIds = liveSeries.map(curSeries => curSeries.id);

    // If unmutedSeriesId === '' intentionally represents user has muted all the main page cards
    if (unmutedSeriesId === '') {
      return null;
    } else {
      const index = playingSeriesIds.indexOf(unmutedSeriesId);
      // If there's not an unmutedChannel, the player went offline,
      // unmute the player with the highest viewCount.
      if (index === -1 || (index > 4 && isMatchScreenPlayer)) {
        // A false isMatchScreenPlayer flag lets users unmute players from outside the first page.
        return playingSeriesIds.length && playingSeriesIds[0];
      } else {
        return unmutedSeriesId;
      }
    }
  }
);
