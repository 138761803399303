import React from 'react';
import { useSelector } from 'react-redux';

import HeaderMultiNotificationButton from './HeaderMultiNotificationButton';
import HeaderNotificationButton from './HeaderNotificationButton';
import { isSignedIn } from '../../../store/selectors/user';

const NotificationButton = ({ multiple, ...props }) => {
  const isUserSignedIn = useSelector(isSignedIn);

  if (!isUserSignedIn) {
    return null;
  }

  return multiple ? <HeaderMultiNotificationButton {...props} /> : <HeaderNotificationButton {...props} />;
};

export default NotificationButton;
