import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isSignedIn } from '../../../../store/selectors/user';
import { openLoginDialog } from '../../../../store/actions/dialogs';
import { useFollowing, useFollowAction } from '../../../../hooks';
import { LOGIN_DIALOG_VIEWS } from '../../../../store/reducers/dialogs';
import { LOGIN_SOURCES } from '../../../../store/reducers/user';

const FollowDropdownButton = ({ authContext: context, entity, entityField, handleClose }) => {
  const dispatch = useDispatch();

  const following = useFollowing(entityField, entity);
  const actionHandler = useFollowAction(following, entityField, entity);

  const isUserSignedIn = useSelector(state => isSignedIn(state));

  return (
    <span
      onClick={() => {
        if (!isUserSignedIn) {
          dispatch(
            openLoginDialog({
              view: LOGIN_DIALOG_VIEWS.FOLLOW,
              source: LOGIN_SOURCES.DIALOG_NEW_FEATURE,
              onLoginSuccess: () => {
                actionHandler();
              },
              context,
            })
          );
        } else {
          actionHandler();
        }
        handleClose();
      }}
    >{`${following ? 'Unfollow' : 'Follow'} Event`}</span>
  );
};

export default FollowDropdownButton;
