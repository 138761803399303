import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import trackEventMiddleware from './middleware/trackEventMiddleware';
import reducers from './reducers';
import dialogsSaga from './sagas/dialogs.saga';
import gameDataSaga from './sagas/gameData.sagas';
import localDataSaga from './sagas/localData.sagas';
import twitterSaga from './sagas/twitter.sagas';
import videoSaga from './sagas/video.sagas';
import userSaga from './sagas/user.saga';
import maintenanceSaga from './sagas/maintenance.sagas';
import channelManagerSaga from './sagas/channelManager.sagas';
import notificationSaga from './sagas/notification.sagas';
import uiStatesSaga from './sagas/uiStates.saga';
import { initMixpanel } from '../utils/mixpanel';

const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(sagaMiddleware, trackEventMiddleware);

const enhancer =
  process.env.NODE_ENV === 'development'
    ? compose(
        middleware
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() //todo: figure out why this is breaking browsers without redux dev tools
      )
    : compose(middleware);

export const store = createStore(reducers, {}, enhancer);
export const persistor = persistStore(store);

sagaMiddleware.run(maintenanceSaga);

let sagaInitialized = false;
let mixpanelInitialized = false;

export const initializeSaga = (): void => {
  if (sagaInitialized) return;

  sagaInitialized = true;
  sagaMiddleware.run(
    function* rootSaga({ dispatch }) {
      yield all([
        userSaga(),
        dialogsSaga(),
        gameDataSaga(),
        localDataSaga(),
        twitterSaga(),
        videoSaga({ dispatch }),
        channelManagerSaga(),
        notificationSaga(),
        uiStatesSaga(),
      ]);
    },
    { dispatch: store.dispatch }
  );
};

// Initialize mixpanel. Make sure this happens after sagas initialized.
export const initializeMixpanel = (): void => {
  if (mixpanelInitialized) return;

  mixpanelInitialized = true;
  initMixpanel(store.dispatch);
};
