import classnames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import ItemActionDropdown from '../ItemActionDropdown';
import MobileItemCard from '../../MobileItemCard';
import Countdown from '../../Countdown';
import { withCalendarUrlState } from '../../wrappers';
import { GameTag, TrackLink } from '../../../common';
import { ONE_MINUTE, getGameById } from '../../../../store/selectors/gameData';
import getFormattedTime from '../../../../utils/getFormattedTime';
import { getSeriesUrl, getTournamentUrl } from '../../../../utils/urls';

const styles = theme => ({
  root: {
    boxSizing: 'border-box',
    padding: 16,
    flexDirection: 'column',
    margin: '20px 0',
    background: theme.palette.background.secondary,
    boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.2)',
    alignItems: 'center',
    display: 'flex',
    borderRadius: 4,
    position: 'relative',
    textDecoration: 'none',
    overflow: 'visible',
  },
  mainRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'space-between',
  },
  startTimeContainer: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    lineHeight: '24px',
    fontSize: 14,
    '& span': {
      ' & > span': {
        fontSize: 14,
        textTransform: 'uppercase',
        marginLeft: 5,
      },
    },
  },

  countdownContainer: {
    color: theme.palette.text.primary,
    lineHeight: '24px',
    fontWeight: 'bold',
    fontSize: '12px !important',
    width: 120, //  To fit starting now text.
    '.startingNow &': {
      fontSize: 18,
    },
  },
  startInText: {
    fontSize: 9,
    fontWeight: 500,
    position: 'absolute',
    bottom: 18,
    left: -5,
    letterSpacing: '0.2px',
    lineHeight: '16px',
  },
  game: {
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: '1.25px',
    lineHeight: '24px',
    textAlign: 'right',
    left: '50%',
    top: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  notificationDropdown: {
    zIndex: 1,
    '.dropdownOpen &': {
      zIndex: 'initial',
    },
  },
  link: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
});

class MobileUpcomingItem extends Component {
  state = { dropdownOpen: false };

  handleClose = () => {
    this.setState({ dropdownOpen: false });
  };

  handleOpen = () => this.setState(state => ({ dropdownOpen: !state.dropdownOpen }));

  render() {
    const { classes, series, game, isMatchView } = this.props;
    const { dropdownOpen } = this.state;

    const startDate = new Date(Math.floor(series.start.toDate().getTime() / ONE_MINUTE) * ONE_MINUTE); // trim off seconds.
    const isWithinOneHour = startDate.getTime() - new Date().getTime() < 60 * 60 * 1000;
    const nowSeconds = Math.floor(new Date().getTime() / 1000);

    return (
      <div
        className={classnames({
          [classes.root]: true,
          startingNow: series.start && series.start.seconds < nowSeconds,
          dropdownOpen,
        })}
      >
        <div className={classes.mainRow}>
          {isWithinOneHour ? (
            <>
              <span className={classes.startInText}> Starts in</span>
              <Countdown className={classes.countdownContainer} targetTime={startDate} />
            </>
          ) : (
            <div className={classes.startTimeContainer}>{getFormattedTime(startDate)}</div>
          )}
          <GameTag className={classes.game} game={game} seriesId={series.id} />
          <div className={classes.notificationDropdown}>
            <ItemActionDropdown
              isMatchView={isMatchView}
              open={dropdownOpen}
              handleClose={this.handleClose}
              handleOpen={this.handleOpen}
              series={series}
            />
          </div>
        </div>

        <MobileItemCard series={series} game={game} />
        <TrackLink
          to={isMatchView ? getSeriesUrl(series) : getTournamentUrl(series.tournament)}
          className={classes.link}
          track={{
            event: 'Schedule Item Clicked',
            target: isMatchView ? 'series-page' : 'event-page',
            eventProperties: { type: 'upcoming-item' },
            eventOptions: { contextSeriesId: series.id },
          }}
        />
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withCalendarUrlState,
  connect((state, ownProps) => ({
    game: getGameById(state, ownProps.series.game),
  }))
)(MobileUpcomingItem);
