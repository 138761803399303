import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';

import ParticipantImage from '../Images/ParticipantImage';
import TeamPageLink from '../common/TeamPageLink';
import { getSeriesById } from '../../store/selectors/gameData';

const styles = theme => ({
  teamsContainer: {
    maxHeight: 360,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
  },
  twoTeamsContainer: {
    margin: '10px 15px',
  },
  teamContainer: {
    color: 'white',
    fontFamily: 'Arial',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& h2': {
      marginBottom: 1,
      fontSize: 17,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '100%',
    },
    '$twoTeamsContainer &': {
      display: 'flex',
      flexDirection: 'column',
      width: 140,
    },
    '$manyTeamsContainer &': {
      width: 325,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      margin: '10px 5px',
      '& h2': {
        color: theme.lightPink,
      },
      '& img': {
        display: 'none',
      },
    },
  },
  teamMember: {
    margin: 5,
    color: 'white',
    fontSize: 13,
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  firstTeam: {
    textAlign: 'center',
    marginRight: 25,
  },
  secondTeam: {
    marginLeft: 25,
    textAlign: 'center',
  },
  moreThanTwoTeams: {
    textAlign: 'center',
    margin: '10px 5px',
    '& h2': {
      color: theme.lightPink,
    },
  },
  manyTeamsContainer: {
    margin: '10px 7px',
    maxHeight: 310,
    overflowY: 'auto',
    flexWrap: 'wrap',
  },
  teamLogo: {
    filter: 'drop-shadow(0 0 1px white)',
    paddingTop: 2,
    height: 70,
    width: 72,
    fontSize: 24,
  },
  participantLink: {
    color: 'white',
  },
  [theme.breakpoints.down('sm')]: {
    twoTeamsContainer: {
      marginTop: 55,
    },
    firstTeam: {
      marginRight: 15,
    },
    secondTeam: {
      marginLeft: 15,
    },
  },
});

const TeamRoster = props => {
  const { classes, series } = props;
  const source = 'match-team-roster';

  const participants = !!series ? series.participants : [];

  return (
    <div
      className={classNames({
        [classes.teamsContainer]: true,
        [classes.twoTeamsContainer]: participants.length <= 4,
        [classes.manyTeamsContainer]: participants.length > 4,
      })}
    >
      {participants.map((participant, index) => {
        return (
          <div
            key={index}
            className={classNames({
              [classes.teamContainer]: true,
              [classes.firstTeam]: index === 0 && participants.length === 2,
              [classes.secondTeam]: index === 1 && participants.length === 2,
              [classes.moreThanTwoTeams]: participants.length === 4,
            })}
          >
            <TeamPageLink
              slug={participant.slug}
              participantType={participant && participant.participantType}
              className={classes.participantLink}
              track={{ source }}
            >
              <div>
                <ParticipantImage className={classes.teamLogo} participant={participant} />
              </div>
              <h2>{participant.name}</h2>
            </TeamPageLink>
            {!!participant.players && (
              <>
                {participants.length <= 4 && (
                  <>
                    {participant.players.map(name => (
                      <span key={name} className={classes.teamMember}>
                        {name}
                      </span>
                    ))}
                  </>
                )}
                {participants.length > 4 && (
                  <span className={classes.teamMember}>{participant.players.join(', ')}</span>
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default compose(
  withStyles(styles),
  connect((state, ownProps) => ({
    series: getSeriesById(state, ownProps.seriesId),
  }))
)(TeamRoster);
