import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FaGripHorizontal, FaCalendarDay } from 'react-icons/fa';
import { AiFillPlayCircle } from 'react-icons/ai';
import { MdDescription } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { TrackHashLink, TrackLink } from '../common';
import { getTournamentBaseUrl, getTournamentUrl } from '../../utils/urls';
import { getStandingsById } from '../../store/selectors/gameData';
import { fetchStandings } from '../../store/actions/gameData';

const useStyles = makeStyles(theme => ({
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    width: 48,
    height: 48,
    borderRadius: 4,
    background: theme.palette.background.ternidenary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    '& svg': {
      fontSize: 20,
      fill: theme.palette.text.soloDenary,
      margin: '8px 12px 0px 12px',
    },
    '& span': {
      fontSize: 8,
      color: theme.palette.text.soloDenary,
      marginBottom: 4,
      marginTop: 2,
    },
    '&.isDisable': {
      opacity: '24%',
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
  viewButton: {
    height: 48,
    background: theme.winePink,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'white',
    fontSize: 16,
    lineHeight: '16px',
    letterSpacing: '2.5px',
    fontWeight: 'bold',
    borderRadius: 4,
    marginTop: 24,
  },
}));

const StreamCardButtons = ({ aSeries }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const tournamentId = aSeries.tournament?.id;
  const standings = useSelector(state => getStandingsById(state, tournamentId));

  const scoreEmbeds = aSeries.tournament.scoreEmbeds?.map(scoreEmbed => scoreEmbed.title);

  useEffect(() => {
    dispatch(fetchStandings(tournamentId));
  }, [tournamentId, dispatch]);

  const buttons = [
    {
      Icon: FaGripHorizontal,
      text: 'Brackets',
      url: `${getTournamentBaseUrl(aSeries.tournament)}#${Boolean(scoreEmbeds?.length) ? scoreEmbeds[0] : 'standings'}`,
      isDisable: !standings && !Boolean(scoreEmbeds?.length),
    },
    {
      Icon: AiFillPlayCircle,
      text: 'VODS',
      url: `${getTournamentBaseUrl(aSeries.tournament)}#vods`,
      isDisable: !aSeries.tournament.hasVods,
    },
    {
      Icon: FaCalendarDay,
      text: 'Schedule',
      url: `${getTournamentBaseUrl(aSeries.tournament)}#schedule`,
    },
    {
      Icon: MdDescription,
      text: 'News',
      url: `${getTournamentBaseUrl(aSeries.tournament)}#news`,
      isDisable: !aSeries.tournament.hasArticles,
    },
  ];

  return (
    <>
      <div className={classes.buttonRow}>
        {buttons.map(button => {
          const { Icon, text, url, isDisable } = button;
          return (
            <TrackHashLink
              key={text}
              track={{ event: `${text} Button Clicked`, source: 'stream-card-button', target: 'event-page' }}
              to={url}
              className={classNames({
                [classes.button]: true,
                isDisable,
              })}
            >
              <Icon />
              <span>{text}</span>
            </TrackHashLink>
          );
        })}
      </div>
      <TrackLink
        track={{ event: 'StreamCard View Event Clicked', source: 'stream-card', target: 'event-page' }}
        className={classes.viewButton}
        to={getTournamentUrl(aSeries.tournament)}
      >
        VIEW EVENT
      </TrackLink>
    </>
  );
};

export default StreamCardButtons;
