import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { getGameById } from '../../store/selectors/gameData';

const abiosTournamentLogo = 'https://img.abiosgaming.com/events/No-Logo-Abios-Tournament-Square.png';
const jukedLogo = '/images/Juked_logo.png';

const getImageSource = (tournamentImages, gameImages, defaultImage) => {
  if (!tournamentImages && !gameImages) {
    return jukedLogo;
  }

  if (!tournamentImages || tournamentImages.square === abiosTournamentLogo) {
    return gameImages.square;
  }

  if (defaultImage && !!tournamentImages.default) {
    return tournamentImages.default;
  }

  return tournamentImages.square || jukedLogo; // Just in case a manually created event has default but not square image.
};

const styles = () => ({
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

const TournamentImage = ({ tournament, classes, game, className, defaultImage }) => {
  return (
    <div className={className}>
      <img
        className={classes.image}
        src={getImageSource(tournament.images, game.images, defaultImage)}
        alt="Tournament Logo"
      />
    </div>
  );
};

export default compose(
  withStyles(styles),
  connect((state, ownProps) => ({
    game: getGameById(state, ownProps.tournament.game),
  }))
)(TournamentImage);
