import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';

import SliderSwitch from './SliderSwitch';
import FavoriteGamesPickerDialog from '../Preferences/FavoriteGamesPickerDialog';
import { setFollowMode } from '../../store/actions/uiStates';
import { Tooltip } from '../common';
import { TrackSpan } from './Track';

import { LOGIN_DIALOG_VIEWS } from '../../store/reducers/dialogs';
import { LOGIN_SOURCES } from '../../store/reducers/user';
import { openLoginDialog } from '../../store/actions/dialogs';
import { isSignedIn, getPreferredEntityIds } from '../../store/selectors/user';
import { isDataLoaded } from '../../store/selectors/gameData';
import { usePickerToggle } from '../../hooks';

const useStyles = makeStyles(theme => ({
  followButton: {
    background: theme.darkSwampBlack,
    flexShrink: 0,
    height: 28,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    '&.rightSpace': {
      marginRight: 7,
    },
    '&.large': {
      width: '100%',
      justifyContent: 'center',
      background: 'unset',
      '& > span': {
        fontSize: '0.75em',
        fontWeight: 500,
        textTransform: 'uppercase',
        color: theme.palette.text.primary,
        marginLeft: '10px',
        padding: 'unset',
      },
    },
    '& > span': {
      fontSize: 10,
      fontWeight: 'bold',
      lineHeight: '18px',
      marginLeft: 12,
      color: theme.suvaGray,
    },
  },
  switch: {
    width: 28,
    height: 16,
    '& .slider': {
      background: '#1E2424',
      border: 'none',
      '&:before': {
        background: 'white',
        height: 12,
        width: 12,
        left: 1,
        top: 2,
      },
      '.checked&': {
        background: `${theme.lightTeal} !important`,
        '&:before': {
          transform: 'translateX(13px)',
          '-webkit-transform': 'translateX(13px)',
          '-ms-transform': 'translateX(13px)',
        },
      },
    },
  },
  buttonContainer: {
    display: 'flex',
    marginLeft: 10,
  },
  tooltip: {
    padding: 10,
    top: 45,
    right: 80,
    fontSize: 13,
    fontWeight: 600,
    opacity: 'unset',
    background: theme.darkCharcoal,
    '$buttonContainer:hover &': {
      visibility: 'visible',
    },
    '.large &': {
      display: 'none',
    },
  },
}));

const FollowModeSwitch = ({ variant }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const isFollowMode = useSelector(state => state.uiStates.isFollowMode);
  const isUserSignedIn = useSelector(state => isSignedIn(state));
  const isLoaded = useSelector(state => isDataLoaded(state));

  const [isGamesPickerOpen, openGamesPicker, closeGamesPicker] = usePickerToggle('gameIds');

  const favoriteGameIds = useSelector(state => getPreferredEntityIds(state, { entityField: 'gameIds' }));
  const favoriteTeamIds = useSelector(state => getPreferredEntityIds(state, { entityField: 'teamIds' }));
  const favoriteTournamentIds = useSelector(state => getPreferredEntityIds(state, { entityField: 'tournamentIds' }));
  const favoriteCompetitionIds = useSelector(state => getPreferredEntityIds(state, { entityField: 'competitionIds' }));

  const isUserFollowingAnyEntity =
    isLoaded &&
    (favoriteGameIds?.length ||
      favoriteTeamIds?.length ||
      favoriteTournamentIds?.length ||
      favoriteCompetitionIds?.length);

  return (
    <span
      className={classNames({
        [classes.followButton]: true,
        rightSpace: !isUserSignedIn,
        large: variant === 'large',
      })}
    >
      <span>Follow Mode</span>
      <TrackSpan
        track={{
          event: 'Global Filter Switch Clicked',
          source: 'gobal-filter-view-switch',
          target: isFollowMode ? 'default-mode' : 'follow-mode',
        }}
        className={classes.buttonContainer}
      >
        <SliderSwitch
          variant={variant}
          id="followModeSwitch"
          checked={isFollowMode}
          className={classes.switch}
          Icon={variant === 'large' && FaCheck}
          onChange={event => {
            if (!isUserSignedIn) {
              dispatch(
                openLoginDialog({
                  view: LOGIN_DIALOG_VIEWS.FOLLOW_GLOBAL_FILTER,
                  source: LOGIN_SOURCES.DIALOG_NEW_FEATURE,
                  onLoginSuccess: () => {
                    dispatch(setFollowMode(true));
                  },
                })
              );
            } else {
              if (isUserFollowingAnyEntity) {
                dispatch(setFollowMode(event.target.checked));
              } else {
                openGamesPicker();
              }
            }
          }}
        />
        <Tooltip className={classes.tooltip} text="Filter site by what you follow" />
      </TrackSpan>

      {isGamesPickerOpen && (
        <FavoriteGamesPickerDialog
          onCloseClick={closeGamesPicker}
          onGamesFollow={() => {
            dispatch(setFollowMode(true));
            history.push('/');
          }}
        />
      )}
    </span>
  );
};

export default FollowModeSwitch;
