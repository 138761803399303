import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import { DropdownSelect, DropdownItem } from '../common';
import timezones from './TimeZoneNameList';
import { saveUserTimeZone } from '../../store/actions/user';
import { getTimeZone } from '../../store/selectors/user';

const styles = theme => ({
  wrapper: {
    margin: [[8, 16]],
    width: 240,
    '& .popper': {
      width: '100%',
      zIndex: 10001, // To beat footer.
      maxHeight: 500, // Prevents overflowing the page on MD size.
    },
  },
  menuItem: {
    textAlign: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    wrapper: {
      '& .popper': {
        maxHeight: 370, // Prevents overflowing the page on MD size.
      },
    },
  },
});

class TimeZoneSelect extends Component {
  state = { open: false };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, timeZone, saveUserTimeZone } = this.props;
    const { open } = this.state;

    return (
      <DropdownSelect
        withArrowIcon
        className={classes.wrapper}
        open={open}
        active={!!timeZone}
        placeholder={(timeZone && timeZone.replace(/_/g, ' ')) || 'Select Your Timezone'}
        handleOpen={() => this.setState(state => ({ open: !state.open }))}
        handleClose={this.handleClose}
      >
        {timezones.map(name => (
          <DropdownItem
            key={name}
            className={classes.menuItem}
            active={timeZone === name}
            onClick={() => {
              saveUserTimeZone(name);
              this.handleClose();
            }}
          >
            <span>{name.replace(/_/g, ' ')}</span>
          </DropdownItem>
        ))}
      </DropdownSelect>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    state => ({
      timeZone: getTimeZone(state),
    }),
    { saveUserTimeZone }
  )
)(TimeZoneSelect);
