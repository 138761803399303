import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { messaging } from '../../store/sagas/firebase';
import { openSeriesNotification } from '../../store/actions/notification';
import { refreshFCMToken } from '../../store/actions/user';

class NotificationHandler extends Component {
  componentDidMount() {
    this.setupServiceWorker();
    this.setupBroadcastChannel();
  }

  componentWillUnmount() {
    if (!!this.serviceWorkerChannel) {
      this.serviceWorkerChannel.close();
    }
  }

  setupMessaging() {
    const { openSeriesNotification, refreshFCMToken } = this.props;

    // Trigger the notification if the juked app has focus.
    messaging.onMessage(({ data }) => {
      if (data.type === 'series') {
        openSeriesNotification(data.seriesId);
      }
    });

    // When an FCM token is refreshed or invalidated, store the new one.
    messaging.onTokenRefresh(async () => {
      try {
        const token = await messaging.getToken();
        token && refreshFCMToken(token);
      } catch (e) {
        console.error(e);
      }
    });
  }

  setupServiceWorker() {
    const { refreshFCMToken } = this.props;

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        messaging.useServiceWorker(registration);
        this.setupMessaging();

        // To be safe, try to get latest FCM Token.
        if (Notification.permission === 'granted') {
          refreshFCMToken();
        }
      });
    }
  }

  setupBroadcastChannel() {
    const { refreshFCMToken } = this.props;

    if ('BroadcastChannel' in window) {
      // BroadcastChannel communicates with service-worker.
      this.serviceWorkerChannel = new BroadcastChannel('sw-notification');
      this.serviceWorkerChannel.onmessage = ({ data: { cmd } }) => {
        if (cmd === 'refreshFCMToken') {
          // Invoke refreshFCMToken from service-worker when activating.
          if (Notification.permission === 'granted') {
            refreshFCMToken();
          }
        }
      };
    }
  }

  render() {
    return null;
  }
}

export default compose(
  connect(
    null,
    { openSeriesNotification, refreshFCMToken }
  )
)(NotificationHandler);
