import { REHYDRATE } from 'redux-persist';

export const initialState = {
  rehydrated: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        rehydrated: true,
      };
    default:
      return state;
  }
};
