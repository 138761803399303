import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import { H1 } from '../common';
import { Dialog } from '../Dialogs';

const styles = theme => ({
  dialog: {
    alignItems: 'center',
    background: theme.palette.background.secondary,
    borderRadius: 8,
    border: `1px solid ${theme.palette.border.primary}`,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(90% - 64px)',
    justifyContent: 'center',
    padding: '48px 32px 32px 32px',
    position: 'fixed',
    width: 'calc(90% - 96px)',
  },
  embed: {
    height: '100%',
    width: '100%',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  title: {
    marginRight: '16px',
    marginTop: '0',
    padding: '0 3px',
  },
  [theme.breakpoints.down('sm')]: {
    dialog: {
      height: 'calc(90% - 24px)',
      padding: 12,
      width: 'calc(90% - 24px)',
    },
  },
});

const ScoresEmbedDialog = ({ classes, embeds, onCloseClick, selectedEmbededScore }) => {
  const dialogContent = embeds.find(embed => embed.title === decodeURI(selectedEmbededScore));

  return dialogContent ? (
    <Dialog className={classes.dialog} onCloseClick={onCloseClick}>
      <div className={classes.titleRow}>
        <H1 className={classes.title}>{dialogContent.title}</H1>
      </div>
      <iframe className={classes.embed} title="Scores Embed Dialog" src={dialogContent.src} />
    </Dialog>
  ) : null;
};

export default compose(withStyles(styles))(ScoresEmbedDialog);
