import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { SearchResultItem } from './content-items';

const useStyles = makeStyles(theme => ({
  contentItem: {
    color: theme.palette.text.primary,
  },
}));

const SearchResults = ({ className, results }) => {
  const classes = useStyles();

  return (
    <div className={className}>
      {results.map(result => (
        <SearchResultItem
          key={`${result._meta.engine}|${result.id.raw}`}
          className={classes.contentItem}
          result={result}
        />
      ))}
    </div>
  );
};

export default SearchResults;
