import { db } from '../store/sagas/firebase';

export const normalize = arr =>
  arr.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {});

const getFirestoreQuery = (collectionName, where) => {
  // Assuming it's querying processed collections...
  let query = db.collection(collectionName);

  if (!where) {
    return query;
  } else if (typeof where[0] === 'string' && where.length === 3) {
    // if `where` is e.g. ['tournament.id', '==', '123]
    query = query.where(...where);
  } else if (Array.isArray(where) && where.every(w => w.length === 3)) {
    // if `where` is e.g. [['tournament.id', '==', '123], ['start', '<', new Date()]]
    for (let w of where) {
      query = query.where(...w);
    }
  } else {
    console.error('`where` format incorrect');
  }

  return query;
};

export default getFirestoreQuery;
