import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { TrackLink } from '../Track';

const useStyles = makeStyles(theme => ({
  breadcrumb: {
    color: theme.darkGrey,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '16px',
    margin: 0,
    textDecoration: 'none',
  },
}));

const BreadCrumb = ({ children, url, track }) => {
  const classes = useStyles();
  return (
    <TrackLink className={classes.breadcrumb} to={url} track={{ ...track }}>
      {children}
    </TrackLink>
  );
};

export default BreadCrumb;
