import { useState, useEffect } from 'react';

import { db } from '../../store/sagas/firebase';

export default (collectionName, docId) => {
  const [result, setResult] = useState({});

  useEffect(() => {
    const unsub = db
      .collection(collectionName)
      .doc(docId)
      .onSnapshot(doc => {
        setResult(doc.data());
      });
    return unsub;
  }, [collectionName, docId]); // query should unsub & resub if anything pertaining to the query changes.

  return result;
};
