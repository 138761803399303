import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import {
  getGameById,
  getSeriesByTournamentSlug,
  getStandingsById,
  getTournamentMostRecentSingleSeries,
} from '../store/selectors/gameData';
import { getLiveSeriesByTournamentId } from '../store/selectors/liveSeries';
import { getVodSeriesByTournamentId } from '../store/selectors/recentVodSeries';
import { subscribeToQuery, unsubscribeToQuery, fetchStandings } from '../store/actions/gameData';
import { trackEvent } from '../store/actions/uiStates';
import { FOLLOW_FIELDS } from '../store/actions/user';
import EventDescription from '../components/Events/EventDescription';
import FollowHeader from '../components/ContentHeaders';
import HeaderTab, { LABELS, SLUGS } from '../components/ContentHeaders/HeaderTab';
import TournamentImage from '../components/Images/TournamentImage';
import ScoresEmbedDialog from '../components/Events/ScoresEmbedDialog';
import VideoSwatch from '../components/VideoSwatch/VideoSwatch';
import StandingsDialog from '../components/Brackets/StandingsDialog';
import TournamentCalendar from '../components/CalendarTable/TournamentCalendar';
import VodsCarousel from '../components/Vods/VodsCarousel';
import BracketsStandingsButton from '../components/Brackets/EventBracketsStandingsSection';
import ShareButton from '../components/ContentHeaders/HeaderShareButton';
import WindowTitle from '../components/WindowTitle';
import { GameTag, Tooltip, EntityArticleSection } from '../components/common';
import { EventBreadcrumb } from '../components/common/Breadcrumbs';
import HeaderIconButtons from '../components/ContentHeaders/HeaderIconButtons';
import ItemPrizepool from '../components/CalendarTable/ItemPrizepool';
import PickemsBanner from '../components/Images/PickemsBanner';

const styles = theme => ({
  root: {
    minHeight: theme.contentHeight,
    backgroundColor: `${theme.palette.background.primary}`,
  },
  section: {
    borderBottom: `2px solid ${theme.palette.border.primary}`,
    backgroundColor: theme.palette.background.primary,
  },
  videoSwatchContainer: {
    backgroundColor: 'black',
  },
  vodsSection: {
    background: theme.palette.background.soloDenary,
  },
  calendarSection: {
    padding: '0 24px',
  },
  container: {
    maxWidth: 1280,
    width: '100%',
    paddingTop: 5,
    margin: 'auto',
  },
  breadcrumb: {
    marginLeft: 2,
  },
  gameName: {
    marginLeft: 32,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 12,
    },
  },
  banneContainer: {
    paddingBottom: 24,
    background: theme.palette.background.primary,
  },
  prizepool: {
    marginLeft: 24,
    color: 'white',
    fontSize: 15,
    fontWeight: 600,
    lineHeight: '20px',
  },
});

class EventScreen extends Component {
  state = { isStandingsModalOpen: false, isScoresModalOpen: false, hasArticles: false };
  isModalStateLoaded = false;

  componentDidMount() {
    const { fetchStandings, subscribeToQuery, tournament } = this.props;

    subscribeToQuery(...this.getQueryParams());

    if (tournament) {
      // tournament could be null when refreshing or entering this route directly.
      fetchStandings(tournament.id);
      this.handleModalLoadState();
    }
  }

  componentWillUnmount() {
    this.props.unsubscribeToQuery(...this.getQueryParams());
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { tournamentSlug },
      },
      subscribeToQuery,
      unsubscribeToQuery,
      tournament,
      fetchStandings,
    } = this.props;

    const {
      match: {
        params: { tournamentSlug: prevTournSlug },
      },
      tournament: prevTournament,
    } = prevProps;

    if (prevTournSlug !== tournamentSlug) {
      unsubscribeToQuery(...this.getQueryParams(prevTournSlug));
      subscribeToQuery(...this.getQueryParams());
    }

    // NOTE: Use JSON.stringfy as a safety net due to previous fetchStandings infinite loop
    // we've now fixed. Consider removing stringify check if performance is impacted.
    if (!!tournament && JSON.stringify(tournament) !== JSON.stringify(prevTournament)) {
      // If data just loaded, fetch the tournament standings.
      fetchStandings(tournament.id);
      this.handleModalLoadState();
    }
  }

  handleModalLoadState = () => {
    if (!this.isModalStateLoaded) {
      this.isModalStateLoaded = true;
      const {
        location: { hash },
        tournament,
      } = this.props;

      //Use the Scored embed title to compare it to the hash.
      //This will find the selected Score embed
      const embedScore = tournament.scoreEmbeds?.find(embed => embed?.title === decodeURI(hash).replace(/^#/, ''));

      const embedScoreTitle = encodeURI(embedScore?.title);

      if (hash === `#${embedScoreTitle}`) {
        this.handleOpenScoresModal();
      } else if (hash === `#${SLUGS[LABELS.BRACKETS_STANDINGS]}`) {
        this.handleOpenStandingsModal();
      }
    }
  };

  handleOpenScoresModal = () => {
    // Tracked in EventHeaderTab
    this.setState({ isScoresModalOpen: true });
  };

  handleOpenStandingsModal = () => {
    const { trackEvent, tournament } = this.props;
    trackEvent('Standings Opened', { tournamentId: tournament.id });

    if (!!tournament.bracketLink) {
      window.open(tournament.bracketLink, '_blank');
    } else {
      this.setState({ isStandingsModalOpen: true });
    }
  };

  getQueryParams(tournamentSlug) {
    return ['allSeries', ['tournament.slug', '==', tournamentSlug || this.props.match.params.tournamentSlug]];
  }

  //This will hide the News tab when there is no articles
  setHasArticles = hasArticles => {
    this.setState({ hasArticles });
  };

  render() {
    const {
      classes,
      game,
      tournament,
      liveSeries,
      location,
      endedSeries,
      standings,
      mostRecentSingleSeries,
    } = this.props;
    const { isStandingsModalOpen, isScoresModalOpen, hasArticles } = this.state;

    if (!tournament || !game) {
      return <div className={classes.root}></div>; // TODO: render loading state
    }

    const hasVods = Boolean(endedSeries?.length);

    const gameName = game && game.name;
    const tournamentName = tournament.title;

    const { title, description, keywords, robots } = tournament?.metaTags || {};

    const isTFTGame = game.id === 'P2v1TxF2JiAsmUAtznCL';

    if (!location.hash) {
      // If no hash added, redirect to same URL with OVERVIEW hash.
      console.error('[EventScreen] No hash in URL, redirecting...');
      return <Redirect to={{ ...location, hash: `#${SLUGS[LABELS.OVERVIEW]}` }} />;
    }
    const source = 'tournament-page';

    return (
      <div className={`${classes.root} eventScreen`}>
        {/* eventScreen class will hide tournament titles on each calendar item */}
        {(gameName || tournamentName) && (
          <WindowTitle
            title={title || `${tournamentName} | ${gameName} | Watch all streams here`}
            description={
              description ||
              `Follow ${tournamentName} live streams, schedules, brackets, and more on Juked. Watch ${gameName} esports streams and more.`
            }
            keywords={keywords}
            robots={robots}
          />
        )}

        <FollowHeader
          title={tournament.title}
          className={classes.section}
          entity={tournament}
          entityField={FOLLOW_FIELDS.TOURNAMENT_IDS}
          Prizepool={
            <ItemPrizepool
              withDollarSignIcon
              prizepool={tournament.prizepool}
              className={classes.prizepool}
              withTrophyIcon
            />
          }
          ImageComponent={<TournamentImage tournament={tournament} />}
          Tooltip={({ className }) => <Tooltip className={className} text="Tap or click to follow event" />}
          Breadcrumb={
            <EventBreadcrumb className={classes.breadcrumb} game={game} competition={tournament.competition} />
          }
          GameTags={<GameTag className={classes.gameName} game={game} />}
          HeaderIconButtons={
            <HeaderIconButtons
              links={{
                tournamentUrl: tournament.websiteUrl,
                facebook: tournament.links?.facebook,
                instagram: tournament.links?.instagram,
                twitter: tournament.links?.twitter,
                youtube: tournament.links?.youtube,
                discord: tournament.links?.discord,
              }}
              source={source}
            />
          }
          ShareComponent={<ShareButton event={tournament} source={source} />}
          banners={tournament.banners}
        >
          <HeaderTab label={LABELS.OVERVIEW} location={location} />
          {(!!standings || tournament.bracketLink) && (
            <HeaderTab label={LABELS.BRACKETS_STANDINGS} location={location} onClick={this.handleOpenStandingsModal} />
          )}
          {tournament.scoreEmbeds?.map((scoreEmbed, idx) => {
            return (
              <HeaderTab key={idx} label={scoreEmbed.title} location={location} onClick={this.handleOpenScoresModal} />
            );
          })}
          <HeaderTab label={LABELS.SCHEDULE} location={location} />
          {hasArticles && <HeaderTab label={LABELS.NEWS} location={location} />}
          {hasVods && <HeaderTab label={LABELS.VODS} location={location} />}
        </FollowHeader>

        {!liveSeries?.length && tournament.description && (
          <EventDescription label="What is this event?" event={tournament} />
        )}

        {/** Stream section */}
        {Boolean(liveSeries?.length) && (
          <div className={`${classes.section} ${classes.videoSwatchContainer}`}>
            <VideoSwatch liveSeriesArr={liveSeries} />
            {isTFTGame && (
              <div className={classes.banneContainer}>
                <PickemsBanner source="event-page" />
              </div>
            )}
          </div>
        )}

        <span id={SLUGS[LABELS.VODS]} />
        {hasVods && (
          <div className={classes.vodsSection}>
            <VodsCarousel
              className={classes.container}
              title="Recent Matches"
              endedSeries={endedSeries}
              game={tournament.game}
              page="tournament-page"
              tournamentTitle={tournament.title}
            />
          </div>
        )}

        {/** Bracket and Standings section */}
        <span id={SLUGS[LABELS.BRACKETS_STANDINGS]} />
        {(standings || tournament.bracketLink) && (
          <BracketsStandingsButton standings={standings} onClick={this.handleOpenStandingsModal} />
        )}

        {/** Schedule section */}
        <div className={classes.calendarSection}>
          <span id={SLUGS[LABELS.SCHEDULE]} />
          <TournamentCalendar title="Schedule" tournamentId={tournament.id} />
        </div>

        {standings && (
          <StandingsDialog
            standingsId={tournament.id}
            currentSeries={mostRecentSingleSeries?.id}
            open={isStandingsModalOpen}
            onCloseClick={() => this.setState({ isStandingsModalOpen: false })}
          />
        )}

        {/**News Section */}
        <span id={SLUGS[LABELS.NEWS]} />
        <div className={classes.section}>
          <EntityArticleSection
            title={`${tournament.title} News`}
            onFetchArticles={articles => {
              if (Boolean(articles?.length) !== hasArticles) {
                this.setState({ hasArticles: Boolean(articles?.length) });
              }
            }}
            queryProps={{ eventId: tournament?.id }}
          />
        </div>

        {isScoresModalOpen && tournament.scoreEmbeds?.length && (
          <ScoresEmbedDialog
            embeds={tournament.scoreEmbeds}
            onCloseClick={() => this.setState({ isScoresModalOpen: false })}
            selectedEmbededScore={location.hash.replace('#', '')}
          />
        )}
      </div>
    );
  }
}

export default compose(
  connect(
    (state, ownProps) => ({
      aSeries: getSeriesByTournamentSlug(state, ownProps.match.params.tournamentSlug)[0],
      state,
    }),
    { subscribeToQuery, unsubscribeToQuery, fetchStandings, trackEvent },
    (stateProps, dispatchProps, ownProps) =>
      !!stateProps.aSeries
        ? {
            ...stateProps,
            ...dispatchProps,
            ...ownProps,
            // use the tournament/game object nested in the first series item, so we don't have to fetch the tournament separately.
            // TODO: this wouldn't work if the tournament has no series associated with it, but if that's the case, should the tournament page even exist?
            tournament: !!stateProps.aSeries ? stateProps.aSeries.tournament : null,
            game: !!stateProps.aSeries ? getGameById(stateProps.state, stateProps.aSeries.game) : null,
            liveSeries: getLiveSeriesByTournamentId(stateProps.state, stateProps.aSeries.tournament.id), // need a list of all live series to cross-check if the specified series is considerd live.
            standings: getStandingsById(stateProps.state, stateProps.aSeries.tournament.id),
            endedSeries: getVodSeriesByTournamentId(stateProps.state, stateProps.aSeries.tournament.id),
            mostRecentSingleSeries: getTournamentMostRecentSingleSeries(
              stateProps.state,
              stateProps.aSeries.tournament.id
            ),
          }
        : { ...stateProps, ...dispatchProps, ...ownProps }
  ),
  withStyles(styles)
)(EventScreen);
