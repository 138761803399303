import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePrevious } from '../hooks';
import { setSportBuffToken } from '../store/actions/user';
import { TYPE_PLATFORM_MAP } from '../store/reducers/video';
import { functions } from '../store/sagas/firebase';

const SportBuff = ({ elemId, series, stream }) => {
  const { streamTitle, streamId } = series.sportBuff || {};
  const { platformType } = stream || {};
  const pluginType = TYPE_PLATFORM_MAP[platformType];

  // Before a user has authenticated, they will receive an autogenerated
  // visitorUUID stored to cookie.  Once a user authenticates, it is saved
  // to their profile.
  const dispatch = useDispatch();
  const { visitorUUID } = useSelector(({ uiStates }) => uiStates);
  const { fetched, isSignedIn, sportBuffToken: userSportBuffToken } = useSelector(({ user }) => user);
  const [isFetchingToken, setIsFetchingToken] = useState(false);
  const [sportBuffToken, setStateSportBuffToken] = useState(userSportBuffToken);
  const prevVisitorUUID = usePrevious(visitorUUID);

  const fetchToken = useCallback(
    async ({ visitorUUID }) => {
      setIsFetchingToken(true);
      const response = await functions.httpsCallable('authenticateSportBuffUser')({ visitorUUID });
      const token = response && response.data;
      token && dispatch(setSportBuffToken(token));
      // To ensure that we have a token before isFetchingToken is false, we set token to state.
      token && setStateSportBuffToken(token);
      setIsFetchingToken(false);
    },
    [dispatch, setIsFetchingToken, setStateSportBuffToken]
  );

  // This helps us avoid a double call on initialization when a token is fetching but component rerenders.
  useEffect(() => setStateSportBuffToken(userSportBuffToken), [userSportBuffToken, setStateSportBuffToken]);

  // Fetch and set the sportBuffToken when one or a new one is needed.
  useEffect(() => {
    const hasNewUUID = Boolean(visitorUUID && prevVisitorUUID && visitorUUID !== prevVisitorUUID);
    const isLoadingUser = isSignedIn === undefined || (isSignedIn === true && fetched !== true);
    const isFetchingTokenWithSameUUID = isFetchingToken && !hasNewUUID;
    if (isLoadingUser || !visitorUUID || isFetchingTokenWithSameUUID) {
      // A. Skip when sign in check not finished.
      // B. Skip if visitorUUID has not yet loaded. This should not occur.
      // C. Skip if we're already fetching a token and UUID has not changed.
      return;
    } else if (!sportBuffToken || hasNewUUID) {
      // If there is no token or our visitorUUID changes, fetch a new token.
      fetchToken({ visitorUUID });
    }
  }, [fetchToken, isFetchingToken, isSignedIn, fetched, prevVisitorUUID, sportBuffToken, visitorUUID]);

  // Setup the SportBuff whenever sportBuffToken changes.
  useEffect(() => {
    // sportBuffToken will be null on initial load, and set above in fetchToken.
    sportBuffToken &&
      window.BuffVideo({
        debug: process.env.REACT_APP_ENVIRONMENT === 'staging' ? true : false,
        force: true,
        overlayQuerySelector: `#${elemId.replace(/:/g, '\\:')}`, // Selecting by frameContainer ID
        token: sportBuffToken,
        pluginType: 'twitch', // This must remain as twitch, even for youtube...
        streamId: parseInt(streamId),
        customization: {
          hideLeaderBoardPictures: true,
          webController: true,
        },
        ...(streamTitle ? { streamTitle } : {}),
      });
  }, [elemId, pluginType, sportBuffToken, streamId, streamTitle]);

  return <></>;
};

export default SportBuff;
