import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FOLLOW_FIELDS, removePreferredEntity } from '../../../store/actions/user';
import { getGameById } from '../../../store/selectors/gameData';
import { getTeamUrl } from '../../../utils/urls';
import { BodyText } from '../../common';
import FollowingItem from './FollowingItem';
import FollowingEntityLink from '../FollowingEntityLink';

const FollowingItemGame = ({ className, team }) => {
  const { id, game: gameId, name, slug } = team;

  const game = useSelector(state => getGameById(state, gameId));
  const dispatch = useDispatch();

  return (
    <FollowingItem
      className={className}
      key={id}
      handleDelete={() => dispatch(removePreferredEntity(FOLLOW_FIELDS.TEAM_IDS, id))}
    >
      <FollowingEntityLink entity={team} entityField={FOLLOW_FIELDS.TEAM_IDS} to={slug ? getTeamUrl(team) : null}>
        <BodyText variant="smallSemiBold">
          {name}&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;{game?.abbr}
        </BodyText>
      </FollowingEntityLink>
    </FollowingItem>
  );
};

export default FollowingItemGame;
