import {
  CLEAR_ON_LOGIN_SUCCESS,
  CLOSE_LOGIN_DIALOG,
  OPEN_LOGIN_DIALOG,
  SET_LOGIN_DIALOG_VIEW,
  SET_SIGNUP_PROMPTED_AT,
  SWITCH_ROTATING_DIALOG_OFF,
  TOGGLE_SHOULD_REPEAT_DIALOG,
} from '../actions/dialogs';

import { LOGIN_SOURCES } from './user';

export const LOGIN_DIALOG_VIEWS = {
  AUTH: 'AUTH',
  ADD_TO_CALENDAR: 'ADD_TO_CALENDAR',
  DISCORD_BOT: 'DISCORD_BOT',
  PROMPT: 'PROMPT',
  SURVEY: 'SURVEY',
  FOLLOW: 'FOLLOW',
  FOLLOW_GLOBAL_FILTER: 'FOLLOW_GLOBAL_FILTER',
  NEW_FOLLOW_SYSTEM: 'NEW_FOLLOW_REMINDER_SYSTEM',
  REBRAND: 'REBRAND',
  ALPHA_SIGN_UP: 'ALPHA_SIGN_UP',
  // Keep adding views here as needed.
  // ie. DARK_MODE: 'DARK_MODE'
};

// Array order will determine the order in wich dialogs are shown. AUTH and PROMPT are not part of the normal rotation.
// TODO: Add other views when available.
export const ROTATING_DIALOG_VIEWS = [
  LOGIN_DIALOG_VIEWS.ALPHA_SIGN_UP,
  LOGIN_DIALOG_VIEWS.REBRAND,
  // LOGIN_DIALOG_VIEWS.NEW_FOLLOW_SYSTEM,
  // LOGIN_DIALOG_VIEWS.SURVEY,
  // LOGIN_DIALOG_VIEWS.DISCORD_BOT,
];

export const LOGIN_DIALOG_SOURCES_MAP = {
  [LOGIN_DIALOG_VIEWS.PROMPT]: LOGIN_SOURCES.PROMPT,
  [LOGIN_DIALOG_VIEWS.ADD_TO_CALENDAR]: LOGIN_SOURCES.DIALOG_NEW_FEATURE,
  [LOGIN_DIALOG_VIEWS.DISCORD_BOT]: LOGIN_SOURCES.DIALOG_NEW_FEATURE,
  [LOGIN_DIALOG_VIEWS.REBRAND]: LOGIN_SOURCES.DIALOG_NEW_FEATURE,
  [LOGIN_DIALOG_VIEWS.ALPHA_SIGN_UP]: LOGIN_SOURCES.DIALOG_NEW_FEATURE,
  [LOGIN_DIALOG_VIEWS.SURVEY]: LOGIN_SOURCES.DIALOG_NEW_FEATURE,
  [LOGIN_DIALOG_VIEWS.FOLLOW]: LOGIN_SOURCES.DIALOG_NEW_FEATURE,
  [LOGIN_DIALOG_VIEWS.FOLLOW_GLOBAL_FILTER]: LOGIN_SOURCES.DIALOG_NEW_FEATURE,
  [LOGIN_DIALOG_VIEWS.NEW_FOLLOW_SYSTEM]: LOGIN_SOURCES.DIALOG_NEW_FEATURE,
  // TODO: [LOGIN_DIALOG_VIEWS.ANNOUNCEMENT]: LOGIN_SOURCES.DIALOG_ANNOUNCEMENT,
};

export const initialState = {
  isUserDialogOpen: false,
  onLoginSuccess: undefined,
  signupPromptedAt: undefined,
  view: LOGIN_DIALOG_VIEWS.AUTH,
  rotatingDialogsOptOutState: ROTATING_DIALOG_VIEWS.reduce(
    (accu, dialogView) => ({ ...accu, [dialogView]: false }),
    {}
  ),
  shouldRepeatRotatingDialog: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ON_LOGIN_SUCCESS:
      return {
        ...state,
        onLoginSuccess: undefined,
      };
    case CLOSE_LOGIN_DIALOG:
      return {
        ...state,
        isUserDialogOpen: false,
      };
    case OPEN_LOGIN_DIALOG:
      return {
        ...state,
        isUserDialogOpen: true,
        onLoginSuccess: action.onLoginSuccess,
        view: action.view,
        source: action.source,
        context: action.context,
      };
    case SET_LOGIN_DIALOG_VIEW:
      return {
        ...state,
        view: action.view,
      };
    case SET_SIGNUP_PROMPTED_AT:
      return {
        ...state,
        signupPromptedAt: new Date(),
      };
    case SWITCH_ROTATING_DIALOG_OFF: {
      return {
        ...state,
        rotatingDialogsOptOutState: {
          ...state.rotatingDialogsOptOutState,
          [action.key]: true,
        },
      };
    }
    case TOGGLE_SHOULD_REPEAT_DIALOG: {
      return {
        ...state,
        shouldRepeatRotatingDialog: !state.shouldRepeatRotatingDialog,
      };
    }
    default:
      return state;
  }
};
