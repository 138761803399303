import { useState, useEffect } from 'react';

import getFirestoreQuery, { normalize } from '../../utils/getFirestoreQuery';

export default (collectionName, whereParams) => {
  const query = getFirestoreQuery(collectionName, whereParams);
  const [initialized, setInitialized] = useState(false);
  const [results, setResults] = useState({});

  useEffect(() => {
    // TODO: Replace with a Suspense when available in React stable release.
    const fetch = async () => {
      const snapshot = await query.get();
      setResults(normalize(snapshot.docs.map(d => d.data())));
      setInitialized(true);
    };

    collectionName && whereParams ? fetch() : setInitialized(true);
    // NOTE: Disable eslint here because whereParams will always be a new object,
    // so we should skip the memo dependency on whereParams, which triggers
    // the exhaustive-deps eslint rule.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionName + JSON.stringify(whereParams)]); // query should unsub & resub if anything pertaining to the query changes.

  return [results /* Hash Map Structure */, !initialized /* Fetching State */];
};
