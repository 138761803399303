import React from 'react';
import classnames from 'classnames';

import { LABELS, SLUGS } from './HeaderTab';
import BannerFollowHeader from './BannerFollowHeader';

const ContentHeader = ({ className, banners, ...props }) => {
  const withBannerImage = Boolean(banners);

  return (
    <div id={SLUGS[LABELS.OVERVIEW]} className={classnames({ [className]: true, withBannerImage })}>
      <BannerFollowHeader banners={banners} {...props} />
    </div>
  );
};

export default ContentHeader;
