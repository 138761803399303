import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { compose } from 'recompose';

import TournamentCard from './TournamentCard';
import { getSortedTournamentsByCompetitionId } from '../../store/selectors/competitions';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-16px -16px 0 -16px',
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      justifyContent: 'center',
    },
  },
});

const CompetitionTournamentsGrid = ({ classes, tournaments }) => (
  <div className={classes.root}>
    {tournaments.map((tournament, idx) => (
      <TournamentCard tournament={tournament} key={`${tournament.id} ${idx}`} />
    ))}
  </div>
);

export default compose(
  connect(
    (state, ownProps) => ({
      tournaments: getSortedTournamentsByCompetitionId(state, ownProps.competition.id),
    }),
    {}
  ),
  withStyles(styles)
)(CompetitionTournamentsGrid);
