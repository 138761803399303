import { db } from './firebase';
import { eventChannel } from 'redux-saga';
import { fork, put, take } from 'redux-saga/effects';
import { changeMaintenanceFlag } from '../actions/maintenance';

function firestoreEventChannel() {
  return eventChannel(emit => {
    db.collection('system')
      .doc('maintenance')
      .onSnapshot(doc => {
        if (doc && doc.data()) {
          emit(doc.data());
        }
      });
    // Return no-op unsubscribe function, we're staying subscribed as long as app is running.
    return () => {};
  });
}

function* handleMaintenanceChannel() {
  const channel = firestoreEventChannel();

  while (true) {
    const payload = yield take(channel);
    yield put(changeMaintenanceFlag(payload.showMaintenancePage));
  }
}

export default function* maintenanceSaga() {
  yield fork(handleMaintenanceChannel);
}
