import classnames from 'classnames';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';

const getVariant = (variant, theme) => {
  const base = {
    fontSize: 15,
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontPrimary: 'white',
    fontSecondary: 'white',
    letterSpacing: '2.5px',
    minHeight: 36,
    width: 'initial',
    padding: '16px 24px',
    primary: theme.winePink,
    secondary: theme.blackGrey,
    boxShadow: 'unset',
  };

  const iconBase = {
    ...base,
    fontFamily: 'Source Sans Pro',
    letterSpacing: 'normal',
    minHeight: 'unset',
    padding: '6px 16px',
  };

  switch (variant) {
    case 'iconPink':
      return {
        ...iconBase,
        primary: theme.winePink,
        secondary: theme.winePink,
      };
    case 'iconPinkHover':
      return {
        ...iconBase,
        primary: theme.winePink,
        secondary: theme.palette.background.septenary,
      };
    case 'lightTeal':
      return {
        ...base,
        fontPrimary: theme.black,
        fontSecondary: theme.black,
        primary: theme.lightTeal,
        secondary: theme.darkTeal,
      };
    case 'lightPink':
      return {
        ...base,
        primary: theme.lightPink,
        secondary: theme.darkPink,
      };
    case 'winePink':
      return {
        ...base,
        primary: theme.winePink,
        secondary: theme.blackGrey,
      };
    case 'yellow':
      return {
        ...iconBase,
        fontPrimary: '#0D1318',
        fontSecondary: '#0D1318',
        primary: 'linear-gradient(180deg, #EFB400 0%, #E79000 100%)',
        secondary: 'linear-gradient(180deg, #EFB400 0%, #E79000 100%)',
      };
    case 'dialog-action':
      return {
        ...base,
        letterSpacing: 0,
        minHeight: 48,
        fontSize: 18,
        padding: '12px 24px',
        boxShadow: ' 0 2px 4px 0 rgba(10,11,11,0.24)',
        width: '100%',
      };
    default:
      return base;
  }
};

const useStyles = makeStyles(theme => ({
  button: {
    alignItems: 'center',
    background: ({ variant }) => getVariant(variant, theme).primary,
    border: 'none',
    borderRadius: 5,
    color: ({ variant }) => getVariant(variant, theme).fontPrimary,
    cursor: 'pointer',
    minHeight: ({ variant }) => getVariant(variant, theme).minHeight,
    width: ({ variant }) => getVariant(variant, theme).width,
    padding: ({ variant }) => getVariant(variant, theme).padding,
    display: 'flex',
    fontFamily: ({ variant }) => getVariant(variant, theme).fontFamily,
    fontSize: ({ variant }) => getVariant(variant, theme).fontSize,
    fontWeight: 600,
    justifyContent: 'center',
    letterSpacing: ({ variant }) => getVariant(variant, theme).letterSpacing,
    outline: 'none',
    lineHeight: '20px',
    boxShadow: ({ variant }) => getVariant(variant, theme).boxShadow,
    '&:hover': {
      background: ({ variant }) => getVariant(variant, theme).secondary,
      color: ({ variant }) => getVariant(variant, theme).fontSecondary,
    },
    '&.active': {
      background: ({ variant }) => getVariant(variant, theme).secondary,
      color: ({ variant }) => getVariant(variant, theme).fontSecondary,
    },
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.6,
  },
  progress: {
    color: ({ variant }) => getVariant(variant, theme).fontPrimary,
  },
}));

const Button = ({ active, disabled, className, children, loading, onClick, onMouseEnter, onMouseLeave, theme }) => {
  const classes = useStyles({ variant: theme });

  return (
    <button
      className={classnames({ [classes.button]: true, [className]: true, active, [classes.disabled]: !!disabled })}
      disabled={!!disabled}
      onClick={disabled ? null : onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {loading ? <CircularProgress className={classes.progress} size={14} /> : children}
    </button>
  );
};

export default Button;
