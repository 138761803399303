import { makeStyles } from '@material-ui/styles';
import { FaTrash } from 'react-icons/fa';
import React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  trashContainer: {
    '& .trash-icon': {
      color: theme.palette.text.nonary,
    },
  },
}));

const FollowingItem = ({ className, children, handleDelete }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.container} ${className}`}>
      {children}
      <div className={classes.trashContainer} onClick={handleDelete}>
        <FaTrash className="trash-icon" />
      </div>
    </div>
  );
};

export default FollowingItem;
