import { SET_STREAM_CARD_VOLUME, SET_EVENT_PAGE_VOLUME } from '../actions/video';

export const initialState = {
  streamCardVolume: null,
  eventPageVolume: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STREAM_CARD_VOLUME:
      return {
        ...state,
        streamCardVolume: `${action.volume}`,
      };
    case SET_EVENT_PAGE_VOLUME:
      return {
        ...state,
        // Storing this as an string to guard it from 0 value when doing !!playerVolume in HeroTwitchPlayer.
        eventPageVolume: `${action.volume}`,
      };
    default:
      return state;
  }
};
