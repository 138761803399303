import React from 'react';
import { withStyles } from '@material-ui/styles';
import BracketItem from './BracketItem';

const styles = theme => ({
  bracket: {
    display: 'flex',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    color: 'white',
    fontSize: '1rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: 500,
    marginBottom: 8,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flex: 1,
  },
});

const StandardBracket = props => {
  const { classes, bracket, currentSeries, columnNames, spoilerFree, upperBracket } = props;

  const largestCol = Math.max(...Object.keys(bracket));

  const columns = [];
  for (let i = largestCol; i >= 0; i--) {
    // For unspecified column name, we'll just call them Round 1, 2, 3.. starting from left.
    const columnName = columnNames[i] || `Round ${largestCol - i + 1}`;
    columns.push(
      <div className={classes.columnContainer} key={i}>
        <div className={classes.header}>{columnName}</div>
        <div className={classes.column}>
          {bracket[i].map((series, idx) => {
            let previousSeriesIds = bracket[i + 1];
            if (previousSeriesIds) {
              //add formatting
              previousSeriesIds = previousSeriesIds.map(seriesId => {
                return { seriesId, type: 'winner' };
              });
            }
            if (!!bracket[i + 1] && bracket[i].length === bracket[i + 1].length) {
              //there's case in lower bracket where lmast round is the same length of players as the current, if so we should find in upper bracket the last round of same length of games and populate fro that.
              //we should be pulling down an item from the upper bracket, essentially loop through the upper bracket to find that.
              for (const ub of Object.values(upperBracket)) {
                if (ub.length === bracket[i].length) {
                  //now we merge the brackets one by one.
                  previousSeriesIds = ub.reduce((total, item, index) => {
                    return [
                      ...total,
                      { type: 'loser', seriesId: item },
                      { type: 'winner', seriesId: bracket[i + 1][index] },
                    ];
                  }, []);
                }
              }
            }
            if (!previousSeriesIds && !!upperBracket) {
              //getting previous games for when lower breacket has no-one seeded.
              //commented out for now as there are some edge cases this is not supporting (like when seeds are already there);
              // const upperBracketValues = Object.values(upperBracket);
              // const firstUpperBracketColumn = upperBracketValues[upperBracketValues.length - 1];
              // previousSeriesIds = firstUpperBracketColumn.map(seriesId => {
              //   return { type: 'loser', seriesId };
              // });
            }
            if (previousSeriesIds && previousSeriesIds.length > 2) {
              //we need to select 2 previous games from where in the bracket they are at
              //multplying our point will get the appropriate starting point int he array
              const startingPoint = idx * 2;

              previousSeriesIds = previousSeriesIds.slice(startingPoint, startingPoint + 2);
            }
            return (
              <div key={`${i}:${idx}`}>
                <BracketItem
                  previousSeriesIds={previousSeriesIds}
                  seriesId={series}
                  viewingSeriesId={currentSeries}
                  spoilerFree={spoilerFree}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return <div className={classes.bracket}>{columns}</div>;
};

export default withStyles(styles)(StandardBracket);
