export default theme => ({
  root: {
    color: 'white',
    background: 'url(/images/Juked_Background.jpg) no-repeat center center',
    backgroundSize: 'cover',
    zIndex: -40,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: theme.contentHeight,
    width: '100%',
    '& h1': {
      fontSize: 25,
      textAlign: 'center',
    },
  },
  logo: {
    maxWidth: 650,
    minWidth: 200,
  },
  loginStatus: {
    margin: '80px 0',
  },
  socialContainer: {
    margin: 30,
    display: 'flex',
    alignItems: 'center',
  },
  socialMedia: {
    margin: '0px 40px',
    lineHeight: '50px',
    verticalAlign: 'middle',
    fontSize: 50,
    color: 'white',
    textShadow:
      '0 0 45px #000, 0 0 80px #000, 0 0 55px #000, 0 0 40px #000, 0 0 40px #000, 0 0 0px #000, 0 0 65px #000',
  },
  discord: {
    fontSize: 49,
  },
  button: {
    background: theme.lightPink,
    border: 'none',
    borderRadius: 2,
    color: 'white',
    height: 36,
    margin: '25px auto',
    minWidth: 64,
    padding: '0 16px',
    display: 'block',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'uppercase',
    outline: 'none',
    cursor: 'pointer',
  },
  '@media (max-width: 700px)': {
    root: {
      '& h1': {
        fontSize: 20,
      },
    },
    logo: {
      maxWidth: 300,
      minWidth: 200,
    },
    socialMedia: {
      margin: '0px 15px',
      lineHeight: '40px',
      fontSize: 40,
    },
    discord: {
      fontSize: 39,
    },
    loginStatus: {
      margin: '20px 0',
    },
    socialContainer: {
      margin: '5px 30px 0',
    },
  },
});
