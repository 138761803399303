import React, { useMemo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';

import { TrackLink } from '../components/common';
import VideoSwatch from '../components/VideoSwatch/VideoSwatch';
import SingleViewVideoSwatch from '../components/VideoSwatch/SingleViewSwatch';
import TrendingSection from '../components/TrendingSection';
import ArticlesSection from '../components/ArticlesSection';
import VodsCarousel from '../components/Vods/VodsCarousel';
import SortingSwitch from '../components/CalendarTable/SortingSwitch';
import CalendarTable from '../components/CalendarTable/Table';
import GameHeader from '../components/ContentHeaders/GameHeader';
import WindowTitle from '../components/WindowTitle';

import { useFavoriteEntitiesArticles } from '../hooks/articles';
import { getPreferredEntityIds } from '../store/selectors/user';
import { setCalendarGameFilter } from '../store/actions/uiStates';
import { getCurrentGame, isDataLoaded } from '../store/selectors/gameData';
import { getFilteredLiveSeriesSortedByFollow } from '../store/selectors/liveSeries';
import { getRecentVodSeriesSortedByFollow } from '../store/selectors/recentVodSeries';
import { getFilteredUpcomingSeriesByViewMode, getFilteredLiveSeriesByViewMode } from '../store/selectors/calendar';
import { isFeatureEnabled } from '../utils/featureFlags';
import { GAME_MESSAGES } from '../utils/getGameMessages';

const MAX_WIDTH = '(max-width: 770px)';

const useStyles = makeStyles(theme => ({
  videoSwatchContainer: {
    background: 'white',
  },
  topCarousels: {
    overflow: 'hidden',
  },
  vodsCarousel: {
    background: theme.palette.background.soloDenary,
    padding: '0 24px',
  },
  tableRoot: {
    background: theme.palette.background.senary,
    boxSizing: 'border-box',
    padding: '0 24px',
  },
  calendarHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    marginBottom: 40,
    '&:before': {
      content: '" "',
      position: 'absolute',
      background: theme.lightTeal,
      width: 80,
      height: 3,
      bottom: 5,
    },
    '& h1': {
      color: theme.palette.text.primary,
    },
  },
  tableContainer: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    paddingTop: 24,
    paddingBottom: 24,
    maxWidth: 1232,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 15,
    '& a': {
      color: 'white',
      textDecoration: 'none',
      background: theme.darkPink,
      border: 'none',
      borderRadius: 5,
      cursor: 'pointer',
      padding: '15px 30px',
      fontSize: 14,
      fontWeight: 600,
    },
  },
  h3: {
    color: theme.darkGrey,
  },
  [theme.breakpoints.down('sm')]: {
    calendarHeaderContainer: {
      flexDirection: 'column',
      marginBottom: 20,
      marginRight: 0,
    },
  },
}));

const ScheduleButton = ({ game, onClick }) => (
  <TrackLink
    to="/calendar"
    onClick={onClick}
    track={{
      event: 'Full Schedule Button Clicked',
      eventProperties: {
        filter_game_id: game && game.id,
        filter_game_abbr: game && game.abbr,
      },
      source: 'homepage',
      target: 'calendar-page',
    }}
  >
    See Full Schedule
  </TrackLink>
);

const HomeScreen = ({
  match: {
    params: { slug },
  },
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const liveSeries = useSelector(getFilteredLiveSeriesSortedByFollow);
  const upcomingSeries = useSelector(getFilteredUpcomingSeriesByViewMode);
  const endedSeries = useSelector(getRecentVodSeriesSortedByFollow);
  const homeCalendarIsMatchView = useSelector(state => state.uiStates.calendarIsMatchView);
  const game = useSelector(getCurrentGame);
  const isStateDataLoaded = useSelector(isDataLoaded);
  const isSinglePlayerView = useSelector(state => state.uiStates.isSinglePlayerView);
  const showTopEventsOnVideoSwatch = useSelector(state => state.uiStates.showTopEventsOnVideoSwatch);

  const filteredSeries = useMemo(
    () => (showTopEventsOnVideoSwatch ? liveSeries.filter(series => [1, 2].includes(series.tier)) : liveSeries),
    [liveSeries, showTopEventsOnVideoSwatch]
  );
  const gameName = game && game.name;
  const gameId = game && game.id;
  const favoritesGameIds = useSelector(state => getPreferredEntityIds(state, { entityField: 'gameIds' }));
  const favoritesTeamIds = useSelector(state => getPreferredEntityIds(state, { entityField: 'teamIds' }));
  const favoritesTournamentIds = useSelector(state => getPreferredEntityIds(state, { entityField: 'tournamentIds' }));

  const isFollowMode = useSelector(state => state.uiStates.isFollowMode);

  const favoriteGames = useMemo(() => (isFollowMode ? favoritesGameIds : []), [favoritesGameIds, isFollowMode]);
  const favoriteTeams = useMemo(() => (isFollowMode ? favoritesTeamIds : []), [favoritesTeamIds, isFollowMode]);
  const favoriteEvents = useMemo(() => (isFollowMode ? favoritesTournamentIds : []), [
    favoritesTournamentIds,
    isFollowMode,
  ]);

  const [articles, featuredArticles] = useFavoriteEntitiesArticles({
    favoritesGameIds: favoriteGames,
    favoritesTeamIds: favoriteTeams,
    favoritesTournamentIds: favoriteEvents,
    gameId,
  });

  const [isMobileSize, setIsMobileSize] = useState(window.matchMedia(MAX_WIDTH).matches);

  const checkIsMobileSize = () => {
    //Verify that the size is equal to media query
    setIsMobileSize(window.matchMedia(MAX_WIDTH).matches);
  };

  const { title, description, keywords, robots } = game?.metaTags || {};

  const defaultTitle = GAME_MESSAGES[gameId]
    ? GAME_MESSAGES[gameId].title
    : `${gameName} Tournaments, Scores, Streams, and more - Juked`;

  const defaultDescription = GAME_MESSAGES[gameId]
    ? GAME_MESSAGES[gameId].description
    : `Juked’s dedicated home page for ${gameName} esports tournaments, news, scores, teams, brackets, and more.`;

  useEffect(() => {
    //Verify if is mobile size
    window.addEventListener('resize', checkIsMobileSize);
    return () => window.removeEventListener('resize', checkIsMobileSize);
  });

  return (
    <div>
      {gameName ? (
        <WindowTitle
          title={title || defaultTitle}
          description={description || defaultDescription}
          keywords={keywords}
          robots={robots}
        />
      ) : (
        <WindowTitle
          title="Juked: Follow Every Esport - Streams, Scores, News."
          description="Visit Juked to discover the best events and content in esports - tournament coverage for LoL, VALORANT, CS:GO, Overwatch, Rocket League, and more."
          keywords={keywords}
          robots={robots}
        />
      )}

      {game && isFeatureEnabled('gameHeader') && <GameHeader game={game} />}
      <div className={classes.topCarousels}>
        {(!isStateDataLoaded ||
          Boolean(liveSeries?.length) ||
          Boolean(upcomingSeries?.length) ||
          Boolean(filteredSeries?.length)) && (
          <div className={classes.videoSwatchContainer}>
            {isSinglePlayerView && isFeatureEnabled('multiViewButton') && !isMobileSize ? (
              <SingleViewVideoSwatch series={filteredSeries} />
            ) : (
              <VideoSwatch liveSeriesArr={filteredSeries} isMobileSize={isMobileSize} isHomePageView hasFilters />
            )}
          </div>
        )}
        {isFeatureEnabled('featuredEvents') && <TrendingSection />}
        {/* Article Section */}
        {isFeatureEnabled('articles') && (
          <ArticlesSection title="Trending News" game={game} articles={articles} featuredArticles={featuredArticles} />
        )}
      </div>
      <VodsCarousel
        game={game}
        title="Recent VODs & Matches"
        endedSeries={endedSeries}
        className={classes.vodsCarousel}
        maxCount={21}
        page={game ? 'game-page' : 'home-page'}
      />
      <div id="calendar" className={classes.tableRoot}>
        <div className={classes.tableContainer}>
          <div className={classes.calendarHeaderContainer}>
            <h1>What To Watch</h1>
            <SortingSwitch />
          </div>
          {/* the `key` is to force React to re-mount a new CalendarTable so the loading animation works correctly. */}
          <HomeScreenCalendarTable
            key={slug || 'nothing'}
            isMatchView={homeCalendarIsMatchView}
            className={classes.h3}
          />
          <div className={classes.buttonContainer}>
            <ScheduleButton onClick={() => game && dispatch(setCalendarGameFilter(game.id))} game={game} />
          </div>
        </div>
      </div>
    </div>
  );
};

// This is separate out just so we can bind selectors to ownProps.isMatchView
let HomeScreenCalendarTable = ({ className, upcomingSeries, liveSeries, isMatchView }) =>
  (!upcomingSeries || !upcomingSeries.length) && (!liveSeries || !liveSeries.length) ? (
    <h3 className={className}>No events available at the moment.</h3>
  ) : (
    <CalendarTable seriesRange={upcomingSeries} liveSeries={liveSeries} isMatchView={isMatchView} />
  );

HomeScreenCalendarTable = connect((state, ownProps) => ({
  liveSeries: getFilteredLiveSeriesByViewMode(state, ownProps.isMatchView),
  upcomingSeries: getFilteredUpcomingSeriesByViewMode(state, ownProps.isMatchView),
}))(HomeScreenCalendarTable);

export default HomeScreen;
