import React from 'react';
import { FaUsers } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';

import { TrackButton } from '../common';
import { SIGNUP_DIALOG_ACTIONS } from '../User/Dialogs/index';

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '64px 96px',
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '32px',
    textAlign: 'center',
    color: theme.palette.text.primary,
    margin: '0px 0px 12px 4px',
  },
  contentName: {
    display: 'flex',
    marginBottom: 4,
  },
  icon: {
    width: 32,
    height: 32,
    borderRadius: 4,
    marginRight: 4,
  },
  usersSvg: {
    color: 'white',
    fontSize: 22,
  },
  usersIcon: {
    alignItems: 'center',
    backgroundColor: theme.smokedDarkGrey,
    display: 'flex',
    justifyContent: 'center',
    width: 32,
    height: 32,
    borderRadius: 16,
  },
  subTitle: {
    fontSize: 15,
    lineHeight: '20px',
    color: theme.suvaGray,
    textAlign: 'center',
  },
  button: {
    marginTop: 32,
    '&.dark': {
      background: theme.smokedDarkGrey,
      marginTop: 16,
    },
  },
  '@media (max-width: 700px)': {
    container: {
      padding: '20px 14px 30px',
    },
  },
}));

const FollowDialog = ({ context, onClick, isSignedIn, source, view }) => {
  const classes = useStyles();
  const imgSrc = context.images?.square || context.images?.default || context.images?.icon;

  const track = {
    event: SIGNUP_DIALOG_ACTIONS.SIGNUP,
    eventProperties: { view, is_signed_in: isSignedIn },
    source,
    target: 'login',
  };

  return (
    <div className={classes.container}>
      <span className={classes.title}>Follow to stay up to date with</span>
      <div className={classes.contentName}>
        {imgSrc ? (
          <img className={classes.icon} alt="content-icon" src={imgSrc} />
        ) : (
          <div className={classes.usersIcon}>
            <FaUsers className={classes.usersSvg} />
          </div>
        )}
        <span className={classes.title}>{context.name || context.title}</span>
      </div>
      <span className={classes.subTitle}>
        Sign up so you never miss news, matches, <br /> events and more.
      </span>
      <TrackButton className={classes.button} theme={'dialog-action'} onClick={onClick} track={track}>
        Sign Up
      </TrackButton>
      <TrackButton className={`${classes.button} dark`} theme={'dialog-action'} onClick={onClick} track={track}>
        Log In
      </TrackButton>
    </div>
  );
};

export default FollowDialog;
