import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { H1, TrackA } from '../../../common';

const useStyles = makeStyles(theme => ({
  header: {
    textAlign: 'center',
    marginBottom: 34,
    marginTop: 0,
    fontSize: '40px !important',
  },
  video: {
    marginBottom: 34,
    width: '100%',
  },

  frameContainer: {
    height: 0,
    paddingTop: '56.25%',
    overflow: 'hidden',
    position: 'relative',
  },
  frame: {
    border: 0,
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    zIndex: 1,
  },
  container: {
    alignItems: 'center',
    background: theme.palette.background.secondary,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '28px 48px 80px',
    width: 700,
  },
  link: {
    width: 116,
    height: 35,
    background: theme.lightPink,
    textDecoration: 'none',
    borderRadius: 5,
    display: 'flex',
    marginBottom: 24,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 16px',
    color: 'white',
    fontWeight: 650,
    fontSize: 20,
    '&:hover': {
      opacity: 0.8,
    },
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      width: 600,
    },
  },
  [theme.breakpoints.down('xs')]: {
    header: {
      fontSize: '27px !important',
    },
    container: {
      width: '100vw',
      padding: '20px 14px 50px',
    },
    link: {
      width: 92,
      height: 28,
      fontSize: 18,
    },
  },
}));

const FollowSystemDialog = ({ isSignedIn, view, source }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <H1 className={classes.header}>Brand New on Juked</H1>
      <div className={classes.video}>
        <div className={classes.frameContainer}>
          <iframe
            className={classes.frame}
            title="Juked Follow System Video"
            src="https://www.youtube.com/embed/Mze63gppk6s?autoplay=1"
          ></iframe>
        </div>
      </div>

      <TrackA
        className={classes.link}
        href="https://classic.juked.gg/news/c/juked-product-update-february"
        target="_blank"
        rel="noopener noreferrer"
        track={{
          event: 'Updated Follow Reminder System Clicked',
          eventProperties: { feature: 'updated-follow-reminder', is_signed_in: isSignedIn, view },
          target: 'new-follow-system-news',
          source,
        }}
      >
        Learn More
      </TrackA>
    </div>
  );
};

export default FollowSystemDialog;
