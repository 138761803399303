import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import UpcomingItem from './UpcomingItem';
import MobileUpcomingItem from './MobileUpcomingItem';

export default ({ classes, ...props }) => {
  const matchesDesktop = useMediaQuery('(min-width:960px)'); // SM Breakpoint

  return matchesDesktop ? <UpcomingItem {...props} /> : <MobileUpcomingItem {...props} />;
};
