import React from 'react';
import { makeStyles } from '@material-ui/styles';
import ParticipantImage from '../../Images/ParticipantImage';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    marginTop: 4,
  },
  info: {
    display: 'flex',
  },
  participant: {
    height: 16,
    width: 16,
    marginRight: 8,
    color: 'white',
    fontSize: 10,
  },
  text: {
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: '16px',
  },
  score: {
    marginLeft: 16,
  },
}));

const getParticipantScore = (score, live) => {
  if (live) {
    return score || 0;
  }
  return score || '--';
};
const ParticipantRow = ({ participant, live }) => {
  const classes = useStyles();

  const participantScore = getParticipantScore(participant.score, live);
  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <ParticipantImage participant={participant} className={classes.participant} />
        <span className={classes.text}>{participant.name}</span>
      </div>
      <span className={`${classes.text} ${classes.score}`}>{participantScore}</span>
    </div>
  );
};

export default ParticipantRow;
