import {
  ADD_PLAYER,
  REMOVE_PLAYER,
  TOGGLE_PAUSE,
  TOGGLE_PAUSE_ALL,
  SET_PAUSED_STATE,
  SET_UNMUTED_PLAYER_ID,
  TOGGLE_THEATER_MODE,
  TOGGLE_MUTE,
  CLEAN_PAUSED_SERIES_STATE_MAP,
} from '../actions/video';
import { TOGGLE_VIDEO_SWATCH_VIEW } from '../actions/uiStates';
import { FaTwitch, FaYoutube } from 'react-icons/fa';

export const initialState = {
  pauseAll: false,
  isSinglePlayerPaused: false,
  isSinglePlayerMuted: true,
  playerStates: {},
  // Stream cards are muted by receiving unmutedSeriesId: '' in the selector.
  unmutedSeriesId: '',
  pausedSeriesState: {},
  // Doing this separatelly because playerStates are cleared on route change.
  isTheaterMode: false,
};

export const PLATFORMS = { TWITCH: 'twitch', YOUTUBE: 'youtube' };
export const PLATFORM_TYPES = { TWITCH: 1, YOUTUBE: 8 };
export const TYPE_PLATFORM_MAP = {
  [PLATFORM_TYPES.TWITCH]: PLATFORMS.TWITCH,
  [PLATFORM_TYPES.YOUTUBE]: PLATFORMS.YOUTUBE,
};
export const TYPE_ICON_MAP = { [PLATFORM_TYPES.TWITCH]: FaTwitch, [PLATFORM_TYPES.YOUTUBE]: FaYoutube };

const createTwitchPlayer = (id, channel, muted, paused, controls, video, startTime) => {
  const playerType = video ? 'video' : 'channel';
  const player = new window.Twitch.Player(id, {
    width: '100%',
    height: '100%',
    layout: 'video',
    preload: 'auto',
    muted,
    autoplay: !paused,
    parent: [`${window.location.hostname}`],
    [playerType]: channel,
    controls,
    t: startTime,
  });
  player.addEventListener(window.Twitch.Player.PLAYING, () => {
    player.setMuted(muted);
  });

  return player;
};

const createYouTubePlayer = (id, channel, muted, controls, disableKeyboard, autoplay, startTime) => {
  return new window.YT.Player(id, {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 0,
      loop: 1,
      controls,
      showinfo: 0,
      rel: 0, // Related videos from the same channel.
      disablekb: disableKeyboard, // Disables keyboard actions when === 1.
      vq: 'hd1080',
      modestbranding: 1, // Prevents YouTube logo on control bar.
      start: startTime, // Amount of seconds into the video.
    },
    videoId: channel,
    events: {
      onReady: event => {
        // YT does not have a way of initializing muted players.
        muted && event.target.mute();
        autoplay && event.target.playVideo(); //Prevents YouTube videos from playing audio even if they are muted.
      },
    },
  });
};

const getPlayer = (action, paused) => {
  const { streamId, muted, startTime = 0, platformType, controls, channel, video } = action;
  const isMuted = muted === undefined ? true : muted;
  switch (platformType) {
    case PLATFORM_TYPES.TWITCH: {
      const playerControls = Boolean(controls);
      const isVideo = Boolean(video);
      return createTwitchPlayer(streamId, channel, isMuted, paused, playerControls, isVideo, startTime);
    }

    case PLATFORM_TYPES.YOUTUBE: {
      const playerControls = Boolean(controls) ? 1 : 0;
      const disableKeyboard = !action.controls ? 1 : 0;
      const autoplay = paused ? 0 : 1;
      return createYouTubePlayer(streamId, channel, isMuted, playerControls, disableKeyboard, autoplay, startTime);
    }

    default: {
      return null;
    }
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PLAYER:
      const { streamId, platformType, channel, seriesId, controls } = action;
      const isPlayerPausedOnVideoSwatch =
        state.pauseAll || state.pausedSeriesState[seriesId] || state.isSinglePlayerPaused;
      const paused = isPlayerPausedOnVideoSwatch && !controls;
      const player = getPlayer(action, paused);
      return {
        ...state,
        playerStates: {
          ...state.playerStates,
          // We need to index playerStates by seriesId to maintain settings after changing to another language stream.
          [seriesId]: {
            id: seriesId,
            streamId,
            channel,
            platformType,
            paused,
            player,
          },
        },
      };
    case REMOVE_PLAYER:
      const { [action.seriesId]: deletedPlayer, ...others } = state.playerStates;

      // This *shouldn't* happen, but if-check just in case to prevent crash on prod.
      // TODO: confirm that it never happens, then remove if-check
      if (!!deletedPlayer) {
        deletedPlayer.player.destroy();
      }
      return {
        ...state,
        playerStates: others,
      };
    case SET_PAUSED_STATE: {
      return {
        ...state,
        playerStates: {
          ...state.playerStates,
          [action.seriesId]: {
            ...state.playerStates[action.seriesId],
            paused: action.isPaused,
          },
        },
        pausedSeriesState: {
          ...state.pausedSeriesState,
          [action.seriesId]: action.isPaused,
        },
      };
    }
    case TOGGLE_PAUSE:
      return {
        ...state,
        playerStates: {
          ...state.playerStates,
          [action.seriesId]: {
            ...state.playerStates[action.seriesId],
            paused: !state.playerStates[action.seriesId].paused,
          },
        },
        pausedSeriesState: {
          ...state.pausedSeriesState,
          [action.seriesId]: !state.pausedSeriesState[action.seriesId],
        },
        ...(action.isSinglePlayerView ? { isSinglePlayerPaused: !state.isSinglePlayerPaused } : {}),
      };

    case TOGGLE_PAUSE_ALL:
      return {
        ...state,
        pauseAll: !state.pauseAll,
      };

    case CLEAN_PAUSED_SERIES_STATE_MAP: {
      return {
        ...state,
        pausedSeriesState: action.pausedSeriesState,
      };
    }
    case TOGGLE_MUTE:
      // Used only by the single view video swatch. Multy view muting logic is handled through the saga.
      return {
        ...state,
        ...(action.isSinglePlayerView ? { isSinglePlayerMuted: !state.isSinglePlayerMuted } : {}),
      };
    case TOGGLE_VIDEO_SWATCH_VIEW:
      return {
        ...state,
        isSinglePlayerMuted: true,
        isSinglePlayerPaused: false,
        pausedSeriesState: {},
      };
    case SET_UNMUTED_PLAYER_ID:
      return {
        ...state,
        unmutedSeriesId: action.seriesId,
        isMatchScreenPlayer: action.isMatchScreenPlayer,
      };
    case TOGGLE_THEATER_MODE:
      return {
        ...state,
        isTheaterMode: !state.isTheaterMode,
      };
    default:
      return state;
  }
};
