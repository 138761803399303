import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';
import twitterText from 'twitter-text';

import { postTweetRequest } from '../../../../store/actions/twitter';
import TwitterButton from './TwitterButton';

const styles = {
  container: {
    alignItems: 'flex-end',
    display: 'flex',
    padding: '20px',
    position: 'relative',
  },
  progressContainer: {
    bottom: '4px',
    position: 'absolute',
    right: '6px',
  },
  progressBackground: {
    color: '#545454 !important',
  },
  progressMeter: {
    color: '#1b95e0 !important',
    left: 0,
    position: 'absolute',
  },
  limitNumber: {
    color: 'white',
    fontSize: '9px',
    left: 0,
    position: 'absolute',
    right: 0,
    textAlign: 'center',
    top: '3px',
    width: '18px',
  },
  textContainer: {
    display: 'flex',
    marginRight: '6px',
    position: 'relative',
    width: '100%',
  },
  tweetTextArea: {
    background: '#0f0e11',
    border: '1px solid #0e4f77',
    borderRadius: '4px',
    color: '#faf9fa',
    fontSize: '12px',
    fontFamily: 'Arial',
    minHeight: '68px',
    maxHeight: '68px',
    outline: 0,
    padding: '5px 30px 5px 10px',
    resize: 'none',
    transition: 'box-shadow .1s ease-in,border .1s ease-in',
    width: '100%',
    '&:focus': {
      borderColor: '#1b95e0',
      boxShadow: '0 0 6px -2px #1b95e0',
    },
  },
};

class TweetField extends Component {
  state = { text: '', progress: 0, valid: false, weightedLength: 0 };

  setText = e => {
    const text = e.target.value;
    const results = twitterText.parseTweet(text);
    this.setState({
      text,
      weightedLength: results.weightedLength,
      progress: results.permillage / 10,
      valid: results.valid,
    });
  };

  postTweet = () => {
    const { postTweetRequest } = this.props;
    const { text, valid } = this.state;
    this.setState({ text: '', progress: 0, valid: false }, () => {
      text && valid && postTweetRequest(text);
    });
  };

  renderLimitNumber() {
    const { classes } = this.props;
    const { progress, weightedLength } = this.state;
    if (progress >= 75) {
      return <div className={classes.limitNumber}>{280 - weightedLength}</div>;
    }
  }

  render() {
    const { classes } = this.props;
    const { progress, text, valid } = this.state;

    // Color is not working dynamically, maybe because of SVG :(
    // const color = progress < 90 ? '#1b95e0' : progress < 100 ? '#ffad1f' : '#e0245e';

    return (
      <div className={classes.container}>
        <div className={classes.textContainer}>
          <textarea className={classes.tweetTextArea} placeholder="Post a Tweet" value={text} onChange={this.setText} />
          <div className={classes.progressContainer}>
            <CircularProgress
              className={classes.progressBackground}
              variant="static"
              size={18}
              thickness={3}
              value={100}
            />
            <CircularProgress
              className={classes.progressMeter}
              variant="static"
              size={18}
              thickness={3}
              value={Math.min(progress, 100)}
            />
            {this.renderLimitNumber()}
          </div>
        </div>
        <TwitterButton label="Tweet" onClick={this.postTweet} disabled={!(text !== '' || valid)} />
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    state => ({}),
    { postTweetRequest }
  )
)(TweetField);
