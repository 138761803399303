import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';
import { withStyles } from '@material-ui/styles';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';

import { useGetTrackClick } from '../../Wrappers/withTrackClick';

const styles = theme => ({
  carousel: {
    '& .slick-prev, & .slick-next': {
      width: 0,
      display: 'flex !important',
      height: 0,
      zIndex: 6,
      opacity: 1,
      background: theme.palette.background.arrowButton,
      transition: 'opacity 200ms ease-in-out',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      '& > svg': {
        fontSize: 32,
        fill: theme.palette.text.primary,
      },
    },
    '& .slick-prev': {
      height: 48,
      width: 48,
      marginLeft: 5,
      left: 0,
    },
    '& .slick-prev:before, & .slick-next:before': {
      backgroundColor: theme.palette.background.arrowButton,
      content: '"" !important', //To hide react-slick default arrow icon
    },
    '& .slick-prev:hover, & .slick-next:hover': {
      opacity: 0.7,
    },
    '& .slick-next': {
      height: 48,
      width: 48,
      marginRight: 5,
      right: 0,
    },
    '& .slick-disabled': {
      opacity: '0 !important',
      pointerEvents: 'none',
    },
    '& .slick-track': {
      margin: 'unset',
    },
    '& .slick-dots': {
      '& button': {
        '&:before': {
          fontSize: 12,
          top: 3,
          opacity: 1,
          color: theme.lightCementGrey,
        },
      },
      '& .slick-active': {
        '& button': {
          '&:before': {
            color: theme.redPink,
          },
        },
      },
    },
  },
  [theme.breakpoints.between(1023, 1279)]: {
    carouselContainer: {
      width: '80%',
    },
    carousel: {
      '& .slick-prev': {
        left: 0,
      },
      '& .slick-next': {
        right: 0,
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    carousel: {
      '& .slick-prev, & .slick-next': {
        margin: 'unset',
      },
    },
  },
});

const SlickArrowRight = ({ className, onClick }) => (
  <span className={className} onClick={onClick}>
    <MdKeyboardArrowRight />
  </span>
);

const SlickArrowLeft = ({ className, onClick }) => (
  <span className={className} onClick={onClick}>
    <MdKeyboardArrowLeft />
  </span>
);

const getSettings = ({ breakpoints, slides = 3, ...settings }, { getTrackClick, track }) => ({
  beforeChange: (current, next) => {
    // If there is an event, track carousel arrow navigation click events.
    const trackClick = getTrackClick({
      track: { ...track, target: next > current ? 'next-page' : 'previous-page' },
    });
    trackClick();
  },
  dots: false,
  infinite: false,
  initialSlide: 0,
  slidesToShow: slides,
  slidesToScroll: slides,
  nextArrow: <SlickArrowRight />,
  prevArrow: <SlickArrowLeft />,
  responsive: breakpoints || [
    {
      breakpoint: 701,
      settings: {
        slidesToShow: slides,
        slidesToScroll: slides,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  ...settings,
});

// Needs to be a component to receive a ref.
const Carousel = React.forwardRef(({ children, classes, className, settings, track, ...props }, ref) => {
  const getTrackClick = useGetTrackClick();

  return (
    <Slider
      {...getSettings(settings || {}, { getTrackClick, track })}
      className={`${classes.carousel} ${className}`}
      ref={ref}
      {...props}
    >
      {children}
    </Slider>
  );
});

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  beforeChange: PropTypes.func,
};

export default withStyles(styles)(Carousel);
