import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { TrackLink } from '../common';
import { getSlugFromAbbr } from '../../store/selectors/gameDataUtils';

const useStyles = makeStyles(() => ({
  root: {
    '$gameTag:hover &': {
      zIndex: 550,
      cursor: 'pointer',
    },
  },
  gameTag: {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: ({ disableSpacing }) => (disableSpacing ? 'unset' : '24px'),
    whiteSpace: 'nowrap',
    letterSpacing: '1.25px',
    textDecoration: 'none !important',
  },
  disableSpacing: {
    display: 'flex',
  },
}));

const GameTag = ({ className, game, seriesId = null, disableSpacing }) => {
  const classes = useStyles({ disableSpacing });
  return (
    <div
      className={classnames({
        [classes.root]: true,
        [className]: true,
        [classes.disableSpacing]: disableSpacing,
      })}
    >
      <TrackLink
        to={`/${getSlugFromAbbr(game.abbr)}`}
        style={{ color: game.color }}
        onClick={e => {
          e.stopPropagation();
        }}
        className={`${classes.gameTag} tag`}
        track={{
          event: 'Game Tag Clicked',
          source: 'game-tag',
          target: 'game-page',
          eventOptions: { contextSeriesId: seriesId },
        }}
      >
        {game.abbr}
      </TrackLink>
    </div>
  );
};

export default GameTag;
