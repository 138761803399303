import { useEffect, useState } from 'react';

export default query => {
  const [doesMatch, setDoesMatch] = useState(false);

  useEffect(() => {
    const onUpdateMatch = ({ matches }) => {
      setDoesMatch(matches);
    };

    //  https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
    const matcher = window.matchMedia(query);

    const isModern = 'addEventListener' in matcher;
    if (isModern) {
      matcher.addEventListener('change', onUpdateMatch);
    } else {
      matcher.addListener(onUpdateMatch);
    }

    onUpdateMatch(matcher);

    return () => {
      if (isModern) {
        matcher.removeEventListener('change', onUpdateMatch);
      } else {
        matcher.removeListener(onUpdateMatch);
      }
    };
  }, [query, setDoesMatch]);

  return doesMatch;
};
