import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from '@rebass/grid';

type ContainerProps = {
  children: React.ReactNode;
};

const StyledBox = styled(Box)`
  max-width: 1280px;
`;

StyledBox.defaultProps = {
  mx: 'auto',
  px: 0,
};

const Gutterless = styled(Flex)``;

Gutterless.defaultProps = {
  mx: -3,
};

// NOTE: Gutterless component removes gutters along the outside of the
// container, for expected Columns in children.
const Container = ({ children }: ContainerProps): JSX.Element => (
  <StyledBox>
    <Gutterless>{children}</Gutterless>
  </StyledBox>
);

export default Container;
