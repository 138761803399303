import { SagaIterator } from 'redux-saga';
import { call, select, takeEvery, put, delay, fork, take } from 'redux-saga/effects';

import { db } from './firebase';
import {
  setUserTrackedAt,
  TRACK_EVENT,
  TRACK_USER,
  TrackEventAction,
  TrackUserAction,
  cleanStreamsMap,
} from '../actions/uiStates';
import { GAME_DATA_INITIALIZED, GameDataModelsActionTypes } from '../actions/gameData';
import { cleanPausedSeriesStateMap } from '../actions/video';
import { getPausedSeriesState } from '../selectors/video';
import { getSeriesContext, getSelectedStreamMap } from '../selectors/gameData';
import { getLiveSeries } from '../selectors/liveSeries';
import { shouldTrackUser, getVisitorUUID, shouldCleanStreamMap } from '../selectors/uiStates';
import mixpanel, { awaitMixpanelLoaded } from '../../utils/mixpanel';
import { Series, CleanedStreamsPayload } from '../types';

function* handleTrackEvent({ event, properties, options }: TrackEventAction): SagaIterator {
  yield call(awaitMixpanelLoaded);
  const { contextSeriesId } = options || {};

  // Get any additional contexts.
  const seriesContext = (!!contextSeriesId && (yield select(getSeriesContext, contextSeriesId))) || {};

  mixpanel.track(event, {
    ...seriesContext,
    ...properties,
  });
}

function* handleTrackUser({ uid, isAuthenticated, isAdBlocked }: TrackUserAction): SagaIterator {
  if (yield select(shouldTrackUser)) {
    // Users will be tracked at most once per hour.
    yield put(setUserTrackedAt());
    const visitorUUID = yield select(getVisitorUUID);
    yield db.collection('visitorEvents').add({
      type: 'bootup',
      createdAt: new Date(),
      isAdBlocked,
      visitorUUID,
      isAuthenticated,
      ...(isAuthenticated ? { uid } : {}),
    });
  }
}

function* pollStreamsMapCleanup(): SagaIterator {
  // Wait for 'liveSeries' data to be initialized
  yield take(
    (action: GameDataModelsActionTypes) => action.type === GAME_DATA_INITIALIZED && action.objType === 'liveSeries'
  );

  if (yield select(shouldCleanStreamMap)) {
    const selectedStreamMap = yield select(getSelectedStreamMap);
    const pausedSeriesStateMap = yield select(getPausedSeriesState);
    const liveSeries = yield select(getLiveSeries);

    // Cleans the streamMap and pausedSeriesState from state
    // every 12 hours to remove ended series from local storage.
    const { streamMap, pausedSeriesState } = liveSeries.reduce(
      (acc: CleanedStreamsPayload, aSeries: Series) => {
        if (selectedStreamMap[aSeries.id]) {
          acc.streamMap = { ...acc.streamMap, [aSeries.id]: selectedStreamMap[aSeries.id] };
        }

        if (pausedSeriesStateMap[aSeries.id]) {
          acc.pausedSeriesState = { ...acc.pausedSeriesState, [aSeries.id]: pausedSeriesStateMap[aSeries.id] };
        }
        return acc;
      },
      {
        streamMap: {},
        pausedSeriesState: {},
      }
    );

    yield put(cleanStreamsMap({ streamMap }));
    yield put(cleanPausedSeriesStateMap(pausedSeriesState));
  }
  // This is only to prevent making a constant check to clean the selectedStreamMap.
  yield delay(10 * 60 * 1000); //Wait ten minutes
}

export default function* uiStatesSaga(): SagaIterator {
  yield takeEvery(TRACK_EVENT, handleTrackEvent);
  yield takeEvery(TRACK_USER, handleTrackUser);
  yield fork(pollStreamsMapCleanup);
}
