import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import { Button } from '../common';
import { ScrollableDialog } from '../Dialogs';
import { FOLLOW_FIELDS, savePreferredEntities } from '../../store/actions/user';
import FavoriteGamesPicker from './FavoriteGamesPicker';
import { withGamePickerState } from './wrappers';

const styles = theme => ({
  dialog: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.border.denary}`,
  },
  favoriteGamesPicker: {
    borderRadius: 8,
    padding: '40px 96px',
  },
  footer: {
    borderTop: `2px solid ${theme.palette.border.septenary}`,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 32px',
    width: '100%',
  },
  saveButton: {
    marginLeft: 8,
  },
  '@media (max-width: 700px)': {
    favoriteGamesPicker: {
      padding: '12px 24px',
    },
    footer: {
      padding: '12px 24px',
    },
  },
});

const FavoriteGamesPickerDialog = ({
  classes,
  onAddGame,
  onCloseClick,
  onRemoveGame,
  onGamesFollow,
  savePreferredEntities,
  selectedGameIds,
  selectedGames,
}) => {
  const handleSaveClick = e => {
    savePreferredEntities(FOLLOW_FIELDS.GAME_IDS, selectedGameIds);
    if (selectedGameIds.length && onGamesFollow) onGamesFollow();
    onCloseClick();
  };

  return (
    <ScrollableDialog className={classes.dialog} onCloseClick={onCloseClick}>
      <FavoriteGamesPicker
        className={classes.favoriteGamesPicker}
        onAddGame={onAddGame}
        onRemoveGame={onRemoveGame}
        selectedGames={selectedGames}
      />
      <div className={classes.footer}>
        <Button onClick={onCloseClick}>CANCEL</Button>
        <Button className={`${classes.saveButton}`} onClick={handleSaveClick} theme="lightTeal">
          SAVE
        </Button>
      </div>
    </ScrollableDialog>
  );
};

export default compose(
  withStyles(styles),
  withGamePickerState,
  connect(null, { savePreferredEntities })
)(FavoriteGamesPickerDialog);
