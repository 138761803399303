import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import { closeSeriesNotification } from '../../store/actions/notification';
import { getGameById } from '../../store/selectors/gameData';
import { getLiveSeriesById } from '../../store/selectors/liveSeries';
import { getOpenedSeriesNotifications } from '../../store/selectors/notification';
import { getSeriesUrl } from '../../utils/urls';

import { MdNotifications } from 'react-icons/md';
import { Dialog } from '../Dialogs';
import VodThumbnail from '../Vods/VodThumbnail';

const styles = theme => ({
  dialog: {
    color: theme.palette.text.primary,
    position: 'fixed',
    border: 0,
    borderRadius: 4,
    padding: 24,
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    background: theme.palette.background.secondary,

    // I would love to do "top:50%" + "transform: translateY(-50%)" but this causes subpixel rendering
    // and ends up with slightly blurred text on Chrome on Mac. So below is alternative solution.
    minHeight: 250,
    width: 570,
    top: 'calc(50% - 90px)',
    '& > button': {
      border: 0,
      background: theme.darkPink,
      color: 'white',
      outline: 0,
      cursor: 'pointer',
    },
    '& > h2': {
      margin: 0,
    },
  },

  notification: {
    position: 'absolute',
    left: 4,
    top: 4,
    fontSize: 25,
    color: theme.lightPink,
  },

  teamsContainer: {
    background: theme.darkMagenta,
    overflow: 'hidden',
    borderRadius: 7,
    width: 380,
    height: 210,
    margin: 15,
    position: 'relative',
  },

  watchNowButton: {
    fontSize: 20,
    fontWeight: 600,
    borderRadius: 8,
    padding: '5px 35px',
    position: 'relative',
    '&:before': {
      backgroundColor: 'red',
      borderRadius: '50%',
      content: '" "',
      display: 'inline-block',
      height: 10,
      width: 10,
      position: 'absolute',
      bottom: 10,
      left: 16,
    },
    '&:hover': {
      background: theme.lightPink,
    },
  },
  [theme.breakpoints.down('sm')]: {
    dialog: {
      width: 280,
      '& h2': {
        fontSize: 20,
      },
    },
    teamsContainer: {
      width: 290,
      height: 180,
    },
    watchNowButton: {
      fontSize: 18,
    },
  },
  '@media (max-width: 320px)': {
    dialog: {
      width: 250,
      '& h2': {
        fontSize: 18,
      },
    },
    watchNowButton: {
      fontSize: 16,
      '&:before': {
        bottom: 9,
      },
    },
  },
});

const NOTIF_AUDIO = new Audio('/notification.wav');
const FAVICON_ORIGINAL = '/favicon.png';
const FAVICON_NOTIF = '/favicon.png';

class Notification extends Component {
  componentDidMount() {
    NOTIF_AUDIO.play();
    document.getElementById('favicon').href = FAVICON_NOTIF;
  }

  closeNotificationDialog = () => {
    this.props.closeSeriesNotification(this.props.series.id);
    document.getElementById('favicon').href = FAVICON_ORIGINAL;
  };

  render() {
    const { classes, series, game, tournament } = this.props;

    if (!series) return null;

    return (
      <Dialog className={classes.dialog} onCloseClick={this.closeNotificationDialog}>
        <MdNotifications className={classes.notification} />
        <h2>
          {game.name} - {tournament.title}
        </h2>
        <div className={classes.teamsContainer}>
          <VodThumbnail series={series} isShort />
        </div>
        <button
          className={classes.watchNowButton}
          onClick={() => {
            this.closeNotificationDialog();
            this.props.history.push(getSeriesUrl(series));
          }}
        >
          WATCH LIVE NOW
        </button>
      </Dialog>
    );
  }
}

Notification = compose(
  connect(
    (state, ownProps) => ({
      series: getLiveSeriesById(state, ownProps.seriesId),
      state,
    }),
    { closeSeriesNotification },
    (stateProps, dispatchProps, _) =>
      stateProps.series
        ? {
            series: stateProps.series,
            game: getGameById(stateProps.state, stateProps.series.game),
            tournament: stateProps.series.tournament,
            closeSeriesNotification: dispatchProps.closeSeriesNotification,
          }
        : {}
  ),
  withStyles(styles),
  withRouter
)(Notification);

class SeriesNotifications extends Component {
  render() {
    const { seriesIds } = this.props;
    return (
      <Fragment>
        {seriesIds.map(id => (
          <Notification key={id} seriesId={id} />
        ))}
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    seriesIds: getOpenedSeriesNotifications(state),
  }),
  {}
)(SeriesNotifications);
