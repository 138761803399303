import React from 'react';
import { Redirect } from 'react-router-dom';

import ScrollToTopRoute from '../components/Wrappers/ScrollToTopRoute';
import LoginScreen from '../screens/LoginScreen';
import ProfileScreen from '../screens/ProfileScreen';
import AuthEmailHandlerScreen from '../screens/AuthEmailHandlerScreen';

export const getGameSlugsPrefixPath = ({ gameList }) => {
  // `gameSlugsPathPrefix` needs a temporary value while gameList is loading,
  // in order to make the loading homeScreens appear immediately.
  const gameSlugs = gameList.length ? gameList.map(game => game.slug) : [];
  return gameSlugs.length ? `/:slug(${gameSlugs.join('|')})?` : `/:slug?`;
};

export const renderAuthRoutes = ({ pathname, isSignedIn }) => [
  <ScrollToTopRoute key="1" exact path="/auth/action" component={AuthEmailHandlerScreen} />,
  <ScrollToTopRoute key="2" exact path="/login" component={LoginScreen} />,

  /* Place auth-gated routes below this redirect */
  !isSignedIn && <Redirect key="3" to={`/login?r=${pathname}`} />,
  <ScrollToTopRoute key="4" exact path="/profile" component={ProfileScreen} />,
];
