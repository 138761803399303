import classnames from 'classnames';
import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import { QueryLink } from '../common';

const styles = theme => ({
  sideNavItem: {
    color: '#6C6C6C',
    cursor: 'pointer',
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: '-0.5px',
    lineHeight: '24px',
    margin: '12px 0',
    paddingLeft: 10, // 4px active bar, 6px space.
    textDecoration: 'none',
  },
  active: {
    color: theme.palette.text.primary,
    borderLeft: `4px solid ${theme.lightTeal}`,
    paddingLeft: 6,
  },
  [theme.breakpoints.down('sm')]: {},
});

const SideNavItem = ({ active, classes, children, to }) => (
  <QueryLink className={classnames({ [classes.sideNavItem]: true, [classes.active]: active })} to={to}>
    {children}
  </QueryLink>
);

export default compose(withStyles(styles))(SideNavItem);
