// NOTE: This file is shared between Frontend and Admin. Please copy from Frontend to Admin when making changes.
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import LineClamp from '../common/LineClamp';
import CardThumbnail from '../ArticlesSection/CardThumbnail';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    'font-smoothing': 'antialiased !important', // Smooth scaling.
    '-webkit-transform-style': 'preserve-3d', // Smooth scaling.
    '-webkit-backface-visibility': 'hidden', // Smooth scaling.
    '-moz-backface-visibility': 'hidden', // Smooth scaling.
    '-ms-backface-visibility': 'hidden', // Smooth scaling.
    boxShadow: '0px 2px 12px 0 rgba(0,0,0,0.2)',
    display: 'flex',
    width: '100%',
    margin: 'auto',
    marginBottom: 12,
    position: 'relative',
    background: theme.palette.background.secondary,
    height: 104,
    overflow: 'hidden',
    zIndex: 301,
    borderRadius: 7,
    transition: 'transform 0.3s, box-shadow 0.3s ease',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: 285,
      minWidth: 285,
      height: 'unset',
    },
    '&:hover': {
      boxShadow: '0px 4px 12px 0 rgba(0,0,0,0.16)',
      transform: 'translateZ(0) scale(1.02, 1.02)',
    },
  },
  frameContainer: {
    width: 185,
    paddingTop: 'unset',
    height: 'unset',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: 185,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  card: {
    padding: '10px 24px',
    display: 'flex',
    flexDirection: 'column',
    height: 85,
    textTransform: 'unset',
    justifyContent: 'space-between',
    color: '#808080',
    fontFamily: 'Source Sans Pro',
    fontSize: '0.875rem',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'unset',
      height: 105,
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: '20px',
    textAlign: 'left',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      marginBottom: -8,
    },
  },
  lineClamp: {
    [theme.breakpoints.down('sm')]: {
      lineClamp: 2,
    },
  },
  description: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    height: 20,
    maxWidth: 720,
    overflow: 'hidden',
    lineHeight: '18px',
    fontSize: 13,
    marginTop: -10,
    marginBottom: 12,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 10,
      height: 40,
      marginTop: 'unset',
      marginBottom: 'unset',
    },
  },
  cardTextRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    bottom: 0,
    fontSize: '0.875em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      position: 'relative',
      top: 20,
    },
  },
}));

const ArticleCardHorizontal = ({ article, gameTags, articleLink }) => {
  const classes = useStyles();
  const { attributes, redirectUrlMeta } = article;
  const { title, excerpt } = attributes || {};

  // For 'redirect' articles, redirectUrlMeta is parsed on ingest: fallback to meta description.
  const description = excerpt || (redirectUrlMeta && redirectUrlMeta.description) || '';

  return (
    <div className={classes.cardContainer}>
      {/* Card Link */}
      {articleLink}

      <div className={classes.frameContainer}>
        {/* Card Thumbnail */}
        <CardThumbnail article={article} />
      </div>
      <div className={classes.card}>
        <div className={classes.title}>
          <LineClamp className={classes.lineClamp} text={title} />
        </div>
        <div className={classes.description}>
          <LineClamp className={classes.lineClamp} text={description} />
        </div>
        <div className={classes.cardTextRow}>
          {/* Game tag */}
          {gameTags}
        </div>
      </div>
    </div>
  );
};

export default ArticleCardHorizontal;
