import React from 'react';

const SingleViewIcon = ({ fill }) => {
  return (
    <svg
      width="34px"
      height="34px"
      viewBox="0 0 34 34"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Icon - Single Viewer</title>
      <g id="Follow-System" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Homepage---Nav-Bar-Update---Signed-In" transform="translate(-1167.000000, -115.000000)">
          <g id="Top-Bar" transform="translate(80.000000, 115.000000)">
            <g id="Viewer-Buttons" transform="translate(1048.000000, 0.000000)">
              <g id="Single-Viewer-Button" transform="translate(39.000000, 0.000000)">
                <g id="Icon---Single-Viewer" transform="translate(0.333333, 0.333333)">
                  <rect id="Bounds" x="0.666666667" y="0.666666667" width="32" height="32" rx="4"></rect>
                  <g id="Icon" transform="translate(4.000000, 12.000000)" fill={fill} fillRule="nonzero">
                    <rect
                      id="Rectangle"
                      x="2.04281037e-14"
                      y="-6.05737682e-13"
                      width="15.3333333"
                      height="9.33333333"
                      rx="1"
                    ></rect>
                    <rect
                      id="Rectangle-Copy-6"
                      x="16.6666667"
                      y="-6.05737682e-13"
                      width="8.66666667"
                      height="9.33333333"
                      rx="1"
                    ></rect>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SingleViewIcon;
