import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { ShimmerSpan } from '../common/Shimmer';

const useStyles = makeStyles(theme => ({
  placeholderPart: {
    display: 'inline-flex',
    alignItems: 'center',
    width: 112,
    height: '100%',
    borderRight: `1px solid ${theme.mediumCharcoalGrey}`,
    padding: '0 12px',
    verticalAlign: 'middle',
    // live dot
    '&:nth-child(-n+3):before': {
      backgroundColor: 'red',
      borderRadius: '50%',
      content: '" "',
      display: 'inline-block',
      height: 8,
      verticalAlign: 'middle',
      width: 8,
      flexShrink: 0,
      marginRight: 12,
    },
    '& > span': {
      content: '" "',
      display: 'block',
      width: '90%',
      margin: 'auto',
      height: 50,
    },
  },
}));

const LoadingStateTicker = () => {
  const classes = useStyles();

  return [...Array(25).keys()].map(key => {
    return (
      <div className={classes.placeholderPart} key={key}>
        <ShimmerSpan />
      </div>
    );
  });
};

export default LoadingStateTicker;
