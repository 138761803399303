import { Dispatch } from 'redux';
import mixpanel from 'mixpanel-browser';
import { call, take, select, StrictEffect } from 'redux-saga/effects';

import { SET_MIXPANEL_LOADED, setMixpanelLoaded } from '../store/actions/uiStates';
import { UserState } from '../store/reducers/user';
import { getUser } from '../store/selectors/user';
import getIn from './getIn';
import { EMAIL_OPTIONS } from '../components/Profile/Notifications/constants';

interface Tokens {
  [env: string]: string;
}

const tokens: Tokens = {
  production: '209bdfe376ebc5de4c68bd8827ddb76b',
  staging: '4d8e0c46172edcb39989710fe8a88093',
  development: 'bdb7beb35a0daa100602ed714ca7c4b4',
  default: 'bdb7beb35a0daa100602ed714ca7c4b4',
};

export const initMixpanel = (dispatch: Dispatch): void => {
  mixpanel.init(tokens[process.env.REACT_APP_ENVIRONMENT || 'default'], {
    loaded: () => dispatch(setMixpanelLoaded()),
  });
};

const setMixpanelUser = (user: UserState): void =>
  mixpanel.people.set({
    $email: user.email,
    $created: user.joined && user.joined.toDate(),
    displayName: user.displayName,
    joined: user.joined,
    newsletter: user.mailers?.includes(EMAIL_OPTIONS.NEWSLETTER),
    notification: user.mailers?.includes(EMAIL_OPTIONS.ALL),
    uid: user.uid,
    favoriteGameIds: getIn(['preferences', 'gameIds'])(user) || [],
    favoriteTeamIds: getIn(['preferences', 'teamIds'])(user) || [],
    favoriteTournamentIds: getIn(['preferences', 'tournamentIds'])(user) || [],
  });

export const setMixpanelAlias = (user: UserState): void => {
  // NOTE: This should only be set once per account.
  mixpanel.alias(user.uid);
  setMixpanelUser(user);
};

export const setMixpanelIdentity = (user: UserState): void => {
  mixpanel.identify(user.uid);
  setMixpanelUser(user);
};

export function* updateMixpanelUser(): Generator<StrictEffect> {
  yield call(awaitMixpanelLoaded);
  const user = (yield select(getUser)) as UserState;

  if (user) {
    // NOTE: setMixpanelUser requires identify() to be called if some time has passed.
    setMixpanelUser(user);
    mixpanel.identify(); // Flush new people properties.
  }
}

export function* awaitMixpanelLoaded(): Generator {
  const mixpanelLoaded = yield select(({ uiStates: { mixpanelLoaded: loaded } }) => loaded);
  if (!mixpanelLoaded) {
    // If not loaded wait for the action, required for `get_distinct_id`.
    yield take(SET_MIXPANEL_LOADED);
  }
}

export default mixpanel;
