import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';

import { useFirestoreGetMap } from '../hooks';
import { subscribeToQuery, unsubscribeToQuery } from '../store/actions/gameData';
import {
  getLiveSeriesByParticipants,
  getVodSeriesByParticipants,
  getUpcomingSeriesByParticipants,
} from '../store/selectors/organizations';
import { FOLLOW_FIELDS } from '../store/actions/user';
import { MainSectionTitle, SectionBackground, Tooltip, MultiEntityArticleSection } from '../components/common';
import FollowHeader from '../components/ContentHeaders';
import HeaderTab, { LABELS, SLUGS } from '../components/ContentHeaders/HeaderTab';
import { TeamCard } from '../components/Organizations';
import VideoSwatch from '../components/VideoSwatch/VideoSwatch';
import VodsCarousel from '../components/Vods/VodsCarousel';
import EventCalendar from '../components/CalendarTable/EventCalendar';
import OrganizationImage from '../components/Images/OrganizationImage';
import HeaderIconButtons from '../components/ContentHeaders/HeaderIconButtons';
import ShareButton from '../components/ContentHeaders/HeaderShareButton';
import { getGames } from '../store/selectors/gameData';
import WindowTitle from '../components/WindowTitle';
import { OrganizationBreadcrumb } from '../components/common/Breadcrumbs';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: theme.contentHeight,
    backgroundColor: theme.palette.background.primary,
  },
  section: {
    backgroundColor: theme.palette.background.primary,
    borderBottom: `1px solid ${theme.palette.border.primary}`,
    '&.header': {
      backgroundColor: theme.palette.background.duoDenary,
    },
  },
  sectionBackground: {
    borderBottom: `1px solid ${theme.palette.border.primary}`,
    padding: '0 24px 56px',
  },
  videoSwatchContainer: {
    backgroundColor: 'black',
  },
  vodsSection: {
    background: theme.palette.background.soloDenary,
  },
  container: {
    maxWidth: 1232,
    width: '100%',
    paddingTop: 5,
    margin: 'auto',
  },
  header: {
    marginBottom: 24,
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: -16,
    marginTop: -16,
    marginBottom: 9,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: 0,
      marginLeft: 'unset',
      marginTop: 'unset',
    },
  },
  vodsContainer: {
    maxWidth: 1280,
    width: '100%',
    margin: 'auto',
    padding: '5px 0',
  },
  scheduleContainer: {
    padding: '0 10px',
    [theme.breakpoints.up('md')]: {
      padding: '0 24px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },
}));

const getQueryParams = teamId => {
  return ['allSeries', ['teamIds', 'array-contains', teamId]];
};

const OrganizationScreen = ({
  match: {
    params: { organizationSlug },
  },
  location,
}) => {
  const dispatch = useDispatch();

  const slug = organizationSlug.toLowerCase();
  const [organizations, isLoading] = useFirestoreGetMap('processed_organizations', ['slug', '==', slug]);
  const organization = useMemo(() => Object.values(organizations)[0], [organizations]);
  const teamIds = useMemo(() => organization?.teamIds || [], [organization]);
  const [hasArticles, setHasArticles] = useState(false);

  const liveSeries = useSelector(state => getLiveSeriesByParticipants(state, { teamIds }));
  const endedSeries = useSelector(state => getVodSeriesByParticipants(state, { teamIds }));
  const upcomingSeries = useSelector(state => getUpcomingSeriesByParticipants(state, { teamIds }));
  const gameList = useSelector(state => getGames(state));

  const onFetchArticles = useCallback(
    articles => {
      if (Boolean(articles?.length) !== hasArticles) {
        setHasArticles(Boolean(articles?.length));
      }
    },
    [hasArticles]
  );

  useEffect(() => {
    const teamIds = organization?.teamIds || [];
    for (const teamId of teamIds) {
      dispatch(subscribeToQuery(...getQueryParams(teamId)));
    }
    return () => {
      const teamIds = organization?.teamIds || [];
      for (const teamId of teamIds) {
        dispatch(unsubscribeToQuery(...getQueryParams(teamId)));
      }
    };
  }, [organization, dispatch]);

  const classes = useStyles();
  if (isLoading || !organization) return <div className={classes.root}></div>; // TODO: render loading state

  const hasVods = Boolean(endedSeries?.length);
  const hasTeams = Boolean(organization.teams?.length);
  const source = 'organization-page';

  if (!location.hash) {
    // If no hash added, redirect to same URL with OVERVIEW hash.
    console.error('[OrganizationScreen] No hash in URL, redirecting...');
    return <Redirect to={{ ...location, hash: `#${SLUGS[LABELS.OVERVIEW]}` }} />;
  }

  const { title, description, keywords, robots } = organization?.metaTags || {};

  //Will count how many times the teams game is repeated
  const teamsGame = organization?.teams.reduce((accu, team) => {
    accu[team.game] = (accu[team.game] || 0) + 1;
    return accu;
  }, {});

  //Will get the top 3 games played by teams within the Organization
  const topPlayedGames = Object.entries(teamsGame)
    .sort((a, b) => b[1] - a[1])
    .map(game => gameList[game[0]]?.name)
    .slice(0, 3);

  const defaultTitle = `${organization.name} | Recent Scores, Stats, News, Schedules, & VODs`;
  const defaultDescription = `Get up to date ${
    organization.name
  } notifications, streams, recent matches, scores, esports news, VODs, stats, results, and more. ${
    topPlayedGames ? `${topPlayedGames.join(' | ')}` : ''
  }`;

  return (
    <div className={classes.root}>
      <WindowTitle
        title={title ? title : defaultTitle}
        description={description ? description : defaultDescription}
        keywords={keywords}
        robots={robots}
      />
      <FollowHeader
        large
        title={organization.name}
        Tooltip={({ className }) => <Tooltip className={className} text="Tap or click to follow teams" />}
        ImageComponent={<OrganizationImage organization={organization} />}
        HeaderIconButtons={<HeaderIconButtons links={organization.links} source={source} />}
        ShareComponent={<ShareButton event={organization} isOrganizationScreen source={source} />}
        className={`${classes.section} header`}
        banners={organization.banners}
        Breadcrumb={<OrganizationBreadcrumb organization={organization} />}
        entity={organization}
        entityField={FOLLOW_FIELDS.TEAM_IDS}
        followButtonProps={{
          multiple: true,
          relatedEntityName: 'teams',
        }}
      >
        <HeaderTab label={LABELS.OVERVIEW} location={location} />
        {hasTeams && <HeaderTab label={LABELS.TEAMS} location={location} />}
        <HeaderTab label={LABELS.SCHEDULE} location={location} />
        {hasArticles && <HeaderTab label={LABELS.NEWS} location={location} />}
        {hasVods && <HeaderTab label={LABELS.TOP_MATCHES_VODS} location={location} />}
      </FollowHeader>

      {/**Stream Section*/}
      {Boolean(liveSeries?.length) && (
        <div className={`${classes.section} ${classes.videoSwatchContainer}`}>
          <VideoSwatch liveSeriesArr={liveSeries} source={source} />
        </div>
      )}

      {/**Teams Section */}
      <span id={SLUGS[LABELS.TEAMS]} />
      {hasTeams && (
        <SectionBackground className={classes.sectionBackground}>
          <div className={classes.container}>
            <MainSectionTitle className={classes.header} title="Teams" />
            <div className={classes.cardsContainer}>
              {organization.teams.map(team => (
                <TeamCard game={gameList[team.game]} key={team.id} team={team} organization={organization} />
              ))}
            </div>
          </div>
        </SectionBackground>
      )}

      {/**Schedule Section */}
      <span id={SLUGS[LABELS.SCHEDULE]} />
      <div className={classes.scheduleContainer}>
        <EventCalendar
          title="Schedule"
          context="Organization"
          upcomingSeries={upcomingSeries}
          liveSeries={liveSeries}
        />
      </div>

      {/**NEWS Section */}
      <span id={SLUGS[LABELS.NEWS]} />
      {Boolean(teamIds.length) && (
        <div className={classes.section}>
          <MultiEntityArticleSection
            title={`${organization.name} News`}
            onFetchArticles={onFetchArticles}
            queryProps={{
              contextName: 'teamIds',
              contextIds: teamIds,
            }}
          />
        </div>
      )}

      {/**Tops Matches & Vods Section */}
      <span id={SLUGS[LABELS.TOP_MATCHES_VODS]} />
      {hasVods && (
        <div className={classes.vodsSection}>
          <VodsCarousel
            className={classes.vodsContainer}
            filteredGameIds={[
              ...new Set(
                organization.teams.reduce((accu, team) => (Boolean(team.game) ? [...accu, team.game] : accu), [])
              ),
            ]}
            filteredName={organization.name}
            page="organization-page"
            title="Top Matches & Vods"
            endedSeries={endedSeries}
          />
        </div>
      )}
    </div>
  );
};

export default OrganizationScreen;
