import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import classnames from 'classnames';

import { TrackSpan } from '../common';
import isOverlapping from '../../utils/isOverlapping';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

export const ARROW_DIRECTIONS = {
  LEFT: 'left',
  RIGHT: 'right',
};

const useStyles = makeStyles(theme => ({
  root: {
    width: 48,
    height: 60, // needs to be same height as <TickerMatch>
    position: 'absolute',
    top: 0,
    userSelect: 'none',
    zIndex: 0,
    '&.left': {
      left: 0,
    },
    '&.right': {
      right: 0,
    },
    '&.canScroll': {
      zIndex: 501, // To beat TickerMatch hover overlay
    },
  },
  arrow: {
    backgroundColor: theme.palette.background.quartary,
    color: theme.palette.text.quartary,
    position: 'relative',
    opacity: 0,
    cursor: 'initial',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& svg': {
      fontSize: 32,
      color: '#6C6C6C',
    },
    '.canScroll &': {
      zIndex: 502,
      opacity: 1,
      cursor: 'pointer',
    },
    '.left &': {
      borderRight: `1px solid ${theme.mediumCharcoalGrey}`,
    },
    '.right &': {
      borderLeft: `1px solid ${theme.mediumCharcoalGrey}`,
    },
  },
}));

const scrollRight = (containerSelector, scrollControlRef) => {
  const container = document.querySelector(containerSelector);
  // Find the first item that's partially hidden
  const tickerParts = Array.from(container?.childNodes || 0);
  const scrollToItem = tickerParts.find(part => isOverlapping(part, scrollControlRef?.current));

  container.scroll({
    left: scrollToItem?.offsetLeft,
    behavior: 'smooth',
  });
};

const scrollLeft = containerSelector => {
  const container = document.querySelector(containerSelector);
  // Find the first item that's partially hidden
  const tickerParts = Array.from(container?.childNodes || 0);

  let i;
  for (i = tickerParts.length - 1; i >= 0; i--) {
    const part = tickerParts[i];
    // Find the first visible item
    if (part.offsetLeft < container?.scrollLeft) {
      break;
    }
  }

  let curWidth = 0;
  while (i >= 0 && curWidth < window.innerWidth) {
    curWidth += tickerParts[i].offsetWidth + 16 * 2 /* margin */;
    i--;
  }
  const scrollToItem = tickerParts[i + 1];
  container.scroll({
    left: scrollToItem?.offsetLeft,
    behavior: 'smooth',
  });
};

const ScrollingArrow = ({ containerSelector, direction }) => {
  const scrollControlRef = useRef(null);
  const [canScroll, setCanScroll] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      const container = document.querySelector(containerSelector);

      const isScrollable = Boolean(
        direction === ARROW_DIRECTIONS.RIGHT
          ? container && container.scrollWidth > container.scrollLeft + window.innerWidth
          : container && container.scrollLeft
      );

      setCanScroll(isScrollable);
    }, 200);
    return () => clearInterval(timer);
  }, [containerSelector, direction, setCanScroll]);

  const classes = useStyles();

  return (
    <div
      className={classnames({
        [classes.root]: true,
        [direction]: true,
        canScroll,
      })}
      ref={scrollControlRef}
    >
      <TrackSpan
        className={classes.arrow}
        onClick={() => {
          direction === ARROW_DIRECTIONS.RIGHT
            ? scrollRight(containerSelector, scrollControlRef)
            : scrollLeft(containerSelector);
        }}
        track={{
          event: 'Top Ticker Arrow Clicked',
          eventProperties: { type: `${direction}-arrow` },
          source: 'top-ticker',
          target: `${direction === ARROW_DIRECTIONS.RIGHT ? 'next' : 'previous'}-page`,
        }}
      >
        {direction === ARROW_DIRECTIONS.RIGHT ? <MdKeyboardArrowRight /> : <MdKeyboardArrowLeft />}
      </TrackSpan>
    </div>
  );
};

export default ScrollingArrow;
