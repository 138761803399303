import React from 'react';
import { makeStyles } from '@material-ui/styles';

const getBoxShadow = (elevation, hover = false) =>
  hover ? `2px 0 16px ${elevation * 2 + 2}px rgba(10,11,11,0.2)` : `2px 0 10px ${elevation}px rgba(10,11,11,0.1)`;

const useStyles = makeStyles(theme => ({
  elevator: {
    borderRadius: ({ borderRadius }) => borderRadius || 'inherit',
    boxShadow: ({ elevation }) => getBoxShadow(elevation),
    transition: 'box-shadow 0.3s ease-out, transform 0.3s ease-out',
    '&:hover': {
      // Can gain performance by animating on opacity via :after with larger shadow.
      boxShadow: ({ disabled, elevation }) => getBoxShadow(elevation, !disabled),
      transform: ({ disabled }) => (disabled ? 'none' : 'scale(1.01)'),
      transformOrigin: 'center',
    },
  },
}));

const HoverElevator = ({ borderRadius, className, children, disabled = false, elevation = 1 }) => {
  const classes = useStyles({ borderRadius, disabled, elevation });
  return <div className={`${className} ${classes.elevator}`}>{children}</div>;
};

export default HoverElevator;
