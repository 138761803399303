import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { compose } from 'recompose';
import classNames from 'classnames';
import { MdRemoveRedEye } from 'react-icons/md'; //change with material

import { GameTag, TrackLink } from '../common';
import { getGameById } from '../../store/selectors/gameData';
import getDistanceBetweenDates from '../../utils/getDistanceBetweenDates';

import VodThumbnail from './VodThumbnail';
import VideoCardStyles from '../VideoSwatch/VideoCardStyles';

const styles = theme => ({
  ...VideoCardStyles,
  frameContainer: {
    ...VideoCardStyles.frameContainer,
    overflow: 'hidden',
    background: theme.darkMagenta,
    '&.gradientBrackground': {
      background: theme.gradientBackground,
    },
  },
  viewCount: {
    ...VideoCardStyles.viewCount,
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: '500',
    color: '#6C6C6C',
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Source Sans Pro',
    '& > svg': {
      verticalAlign: 'middle',
      fontSize: '16px',
      paddingLeft: 4,
    },
  },
  card: {
    ...VideoCardStyles.card,
    padding: '12px 16px 20px !important',
    textTransform: 'none',
    background: theme.palette.background.secondary,
  },
  cardContainer: {
    ...VideoCardStyles.cardContainer,
    margin: '12px 24px',
    '@media (max-width: 960px)': {
      margin: 10,
    },
    position: 'relative',
    background: theme.palette.background.primary,
  },
  cardTextRow: {
    ...VideoCardStyles.cardTextRow,
    '&:first-child': {
      paddingBottom: 6,
    },
  },
  timeLabel: {
    ...VideoCardStyles.timeLabel,
    color: 'white',
    background: 'rgba(0,0,0,0.7)',
    opacity: 'unset',
    padding: 7,
  },
  title: {
    ...VideoCardStyles.title,
    fontSize: 15,
    fontWeight: 'bold',
    letterSpacing: '-0.42px',
    lineHeight: '20px',
    textTransform: 'none',
    color: theme.palette.text.primary,
    fontFamily: 'Source Sans Pro',
  },
  seriesTitle: {
    fontWeight: 700,
    fontSize: 13,
    fontFamily: 'Source Sans Pro',
    color: theme.palette.text.septenary,
  },
  gameTag: {
    marginLeft: 10,
    fontStyle: 'normal',
    textTransform: 'none',
    display: 'inline',
    fontFamily: 'Source Sans Pro',
    '& .tag': {
      fontSize: '10px',
      lineHeight: '16px',
    },
  },
});

class VodCard extends Component {
  render() {
    const {
      series: { vod, tournament, participants },
      series,
      classes,
      game,
      page,
    } = this.props;

    const noParticipants = participants.every(participant => participant.name === 'TBD');

    return (
      <div
        className={classNames({
          [classes.cardContainer]: true,
        })}
      >
        <div
          className={classNames({
            [classes.frameContainer]: true,
            gradientBrackground: noParticipants,
          })}
        >
          <VodThumbnail series={series} tournament={tournament} isVodCarousel isShort noParticipants={noParticipants} />
          <TrackLink
            className={classes.hoverOverlay}
            to={`/vods/watch/${series.id}`}
            track={{
              event: 'VOD Card Clicked',
              source: 'vod-carousel',
              target: 'vod-page',
              eventProperties: { page, is_vod: true },
              eventOptions: { contextSeriesId: series.id },
            }}
          >
            <h2>Click Here to</h2>
            <h1>Watch</h1>
          </TrackLink>
        </div>
        <div className={classes.card}>
          <div className={classes.cardTextRow}>
            <span className={classes.title} title={tournament.title}>
              {tournament.title}
            </span>
            <span className={classes.viewCount}>
              {/* Since we're only rendering tier-1 VODs with VodsCarousel (which uses this VodCard component), most of the time the vod will be found,
                  so we don't need to block rendering the rest of the card while fetching, except for viewer counts. */}
              {vod.viewerCount.toLocaleString(undefined, { maximumFractionDigits: 0 })} <MdRemoveRedEye />
            </span>
          </div>
          <div className={classes.cardTextRow}>
            <span className={classes.seriesTitle}>{series.title}</span>
            <GameTag className={classes.gameTag} game={game} seriesId={series.id} />
          </div>
        </div>
        <div className={classes.timeLabel}>{getDistanceBetweenDates(series.end.toDate())}</div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect((state, ownProps) => ({
    game: getGameById(state, ownProps.series.game),
  }))
)(VodCard);
