import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { BodyText, H1, LinkButton } from '../common';
import FavoriteGamesPickerDialog from '../Preferences/FavoriteGamesPickerDialog';
import CollapsibleSection from './CollapsibleSection';
import { FollowingItemGame, FollowingItemEvent, FollowingItemTeam } from './FollowingItems';

import { FOLLOW_FIELDS } from '../../store/actions/user';
import { getPreferredGames } from '../../store/selectors/gameData';
import { useFollowingEntityMultiSubscribe, usePickerToggle } from '../../hooks';

const useStyles = makeStyles(theme => ({
  followingView: {
    width: 600,
    display: 'flex',
    flexDirection: 'column',
  },
  h1: {
    margin: '0 0 24px 0',
    fontWeight: 'normal',
  },
  followItem: {
    marginBottom: 12,
  },
  followItemLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  modalLink: {
    marginTop: 16,
  },
  [theme.breakpoints.down('sm')]: {
    followingView: {
      marginTop: 32,
      width: '100%',
    },
  },
}));

const FollowingView = () => {
  const [isGamesPickerOpen, openGamesPicker, closeGamesPicker] = usePickerToggle('gameIds');

  const preferredGames = useSelector(getPreferredGames);
  const preferredTeams = useFollowingEntityMultiSubscribe(FOLLOW_FIELDS.TEAM_IDS);
  const preferredEvents = useFollowingEntityMultiSubscribe(FOLLOW_FIELDS.TOURNAMENT_IDS);
  const classes = useStyles();

  return (
    <div className={classes.followingView}>
      <H1 className={classes.h1}>Your Follows</H1>
      <CollapsibleSection subtitle="Edit the games you follow on Juked" title="Followed Games">
        {preferredGames.map(game => (
          <FollowingItemGame className={classes.followItem} key={game.id} game={game} />
        ))}
        <LinkButton className={`${classes.followItemLink} ${classes.modalLink}`} onClick={openGamesPicker}>
          <BodyText variant="subtext" color="#C40088">
            ADD MORE GAMES +
          </BodyText>
        </LinkButton>
      </CollapsibleSection>

      <CollapsibleSection subtitle="Edit the teams you follow on Juked" title="Followed Teams">
        {preferredTeams.map(team => (
          <FollowingItemTeam className={classes.followItem} key={team.id} team={team} />
        ))}
        {/* Add a link to teams picker here */}
      </CollapsibleSection>

      <CollapsibleSection subtitle="Edit the Events you follow on Juked" title="Followed Events">
        {preferredEvents.map(tournament => (
          <FollowingItemEvent
            className={classes.followItem}
            key={tournament.id}
            entityField={FOLLOW_FIELDS.TOURNAMENT_IDS}
            event={tournament}
          />
        ))}
      </CollapsibleSection>

      {isGamesPickerOpen && <FavoriteGamesPickerDialog onCloseClick={closeGamesPicker} />}
    </div>
  );
};

export default FollowingView;
