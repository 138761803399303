import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import AccountView from '../components/Profile/AccountView';
import FollowingView from '../components/Profile/FollowingView';
import PreferencesView from '../components/Profile/PreferencesView';
import NotificationsView from '../components/Profile/Notifications/NotificationsView';
import { qsParse } from '../components/common/QueryLink';
import { SideNav, SideNavItem } from '../components/SideNav';
import { isFeatureEnabled } from '../utils/featureFlags';

const ACCOUNT = 'account';
const FOLLOWING = 'following';
const PREFERENCES = 'preferences';
const NOTIFICATIONS = 'notifications';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.primary,
    display: 'flex',
    justifyContent: 'center',
    minHeight: theme.contentHeight,
    padding: '40px 0',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  navigationMenuContainer: {
    marginRight: 32,
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: '24px 20px',
    },
    navigationMenuContainer: {
      margin: 0,
      width: '100%',
    },
  },
});

const renderContent = page => {
  switch (page) {
    case FOLLOWING:
      return isFeatureEnabled('gamePreferences') ? <FollowingView /> : <AccountView />;
    case PREFERENCES:
      return isFeatureEnabled('gamePreferences') ? <PreferencesView /> : <AccountView />;
    case NOTIFICATIONS:
      return isFeatureEnabled('notificationsTab') ? <NotificationsView /> : <AccountView />;
    default:
      // Default is Account.
      return <AccountView />;
  }
};

const ProfileScreen = ({ classes, page }) => (
  <div className={classes.root}>
    {isFeatureEnabled('gamePreferences') && (
      <div className={classes.navigationMenuContainer}>
        <SideNav>
          <SideNavItem active={page === ACCOUNT} to={{ pathname: '/profile', query: { page: ACCOUNT } }}>
            Account
          </SideNavItem>
          <SideNavItem active={page === PREFERENCES} to={{ pathname: '/profile', query: { page: PREFERENCES } }}>
            Preferences
          </SideNavItem>
          <SideNavItem active={page === FOLLOWING} to={{ pathname: '/profile', query: { page: FOLLOWING } }}>
            Following
          </SideNavItem>
          {isFeatureEnabled('notificationsTab') && (
            <SideNavItem active={page === NOTIFICATIONS} to={{ pathname: '/profile', query: { page: NOTIFICATIONS } }}>
              Notifications
            </SideNavItem>
          )}
        </SideNav>
      </div>
    )}
    {renderContent(page)}
  </div>
);

const withPageState = WrappedComponent => props => {
  // If null, `page` defaults to ACCOUNT.
  const { page } = qsParse(props.location.search);
  return <WrappedComponent page={page || ACCOUNT} {...props} />;
};

export default compose(withStyles(styles), withPageState)(ProfileScreen);
