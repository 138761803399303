import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ArticleBase from './ArticleBase';
import { ShimmerDiv } from '../common';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    borderRadius: 4,
    height: 42,
    width: '100%',
  },
  subtitle: {
    marginTop: 18,
  },
  content: {
    borderRadius: 4,
    height: 21,
    marginTop: 18,
    width: '80%',
  },
  image: {
    borderRadius: 4,
    height: 320,
    width: '90%',
    marginTop: 18,
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <ArticleBase className={classes.root}>
      <ShimmerDiv className={classes.title} />
      <ShimmerDiv className={classes.content} />
      <br />
      <ShimmerDiv className={classes.image} />
      <br />
      <ShimmerDiv className={classes.content} />
      <ShimmerDiv className={classes.content} />
      <ShimmerDiv className={classes.content} />
      <ShimmerDiv className={classes.content} />
    </ArticleBase>
  );
};
