import React from 'react';
import { withStyles } from '@material-ui/styles';
import StandingsTableRow from './StandingsTableRow';

const styles = theme => ({
  root: {
    borderCollapse: 'collapse',
    minWidth: '100%',
    '& td, & th': {
      padding: '5px 10px',
      textTransform: 'capitalize',
    },
    '& td': {
      fontSize: 20,
      fontWeight: 700,
    },
    '& th': {
      background: 'black',
      color: 'lightgrey',
      textAlign: 'center',
    },
    '& tr': {
      fontSize: 17,
      fontWeight: 600,
      color: 'white',
    },
    '& tr:nth-child(odd)': {
      background: '#1f1f1f',
    },
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      '& td, & th': {
        padding: 'unset',
        paddingLeft: 8,
        fontSize: 12,
      },
      '& td': {
        fontSize: 8,
      },
      '& tr': {
        fontSize: 'unset',
      },
    },
  },
});

// This creates a string hash of all the standing columns we are going to display.
const getScoreHash = (standing, columnsToRender) =>
  columnsToRender.reduce((accum, propKey) => `${accum}:${standing[propKey]}`, '');

const StandingsTable = ({ classes, standings }) => {
  const columnsToRender = ['wins', 'losses', 'draws'];

  // If there's any team's standing that has points, render that too
  if (standings.find(standing => !!standing.points)) {
    columnsToRender.push('points');
  }

  // Figure out numeral ranking. It should already be sorted so we don't have to re-order, but we do want to
  // label each entry, and they should have the same number if the scores are exactly equal.
  let curRank = 0;
  let prevHash = '';
  standings = standings.map((standing, index) => {
    const hash = getScoreHash(standing, columnsToRender);
    if (prevHash !== hash) {
      curRank = index + 1;
    }
    prevHash = hash;
    return { ...standing, rank: curRank };
  });

  return (
    <table className={classes.root}>
      <thead>
        <tr>
          <th>{/* empty header for team name column */}</th>
          {columnsToRender.map(name => (
            <th key={name}>{name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {standings.map((standing, index) => (
          <StandingsTableRow key={index} standing={standing} columnsToRender={columnsToRender} />
        ))}
      </tbody>
    </table>
  );
};

export default withStyles(styles)(StandingsTable);
