import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = () => ({
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

const jukedLogo = '/images/Juked_logo.png';

const getSrc = competition => {
  if (!!competition.images) {
    return competition.images.default || competition.images.square || jukedLogo;
  }
  return jukedLogo;
};

const CompetitionImage = ({ competition, classes, className }) => (
  <div className={className}>
    <img className={classes.image} src={getSrc(competition)} alt="Competition Logo" />
  </div>
);

export default withStyles(styles)(CompetitionImage);
