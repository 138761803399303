import React from 'react';
import { withStyles } from '@material-ui/styles';

import { HoverElevator, TrackLink } from '../common';

const styles = () => ({
  bannerContainer: {
    marginBottom: 24,
  },
  link: {
    display: 'flex', // Some reason it has a white bar without this...
  },
  img: {
    borderRadius: 4,
    width: '100%',
  },
});

const LoginBanner = ({ classes, onCloseClick, source, view }) => (
  <HoverElevator className={classes.bannerContainer} elevation={2} borderRadius={4}>
    <TrackLink
      className={classes.link}
      to="/login"
      onClick={() => onCloseClick({ shouldTrack: false })}
      track={{
        event: 'Signup Dialog Login Banner Clicked',
        eventProperties: { view },
        source,
        target: 'login',
      }}
    >
      <img alt="" className={classes.img} src="https://i.imgur.com/6PiK5wT.png" />
    </TrackLink>
  </HoverElevator>
);

export default withStyles(styles)(LoginBanner);
