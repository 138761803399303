import React from 'react';
import SimpleMarkdown from 'simple-markdown';

import { COMMANDS, getUUID, matchCommand, parseCommand, sanitizeUrl } from './utils';

const e = React.createElement; // Standard documented here: https://reactjs.org/docs/react-without-jsx.html
const LINK_RE = /^\[(.*)\]\((.*)\)$/;
const YOUTUBE_RE = /^(?:https?:\/\/)?(?:www\.)?youtu(?:\.be\/|be.com\/\S*(?:watch|embed)(?:(?:(?=\/[^&\s?]+(?!\S))\/)|(?:\S*v=|v\/)))([^&\s?]+)(?:(\?t|&start)=(\d+))?.*$/i;
const TWITCH_CHANNEL_RE = /^(?:https?:\/\/)?(?:www\.|go\.)?twitch\.tv\/([a-z0-9_]+)($|\?)/i;
const TWITCH_VIDEO_RE = /^(?:https?:\/\/)?(?:www\.|go\.)?twitch\.tv\/videos\/([a-z0-9_]+)($|\?)/i;
const TWITCH_CLIP_RE = /^(?:https?:\/\/)?(?:www\.|go\.)?twitch\.tv\/[a-z0-9_]+\/clip\/([a-z0-9_-]+)($|\?)/i;

const parseEmbedLink = string => {
  const [match, caption, src] = string.match(LINK_RE) || [];
  return { caption, src: match ? src : string };
};

const BASE_TWITCH_PROPS = {
  allowFullScreen: true,
  className: 'twitch-embed',
  frameBorder: '0',
  height: '360px',
  scrolling: 'no',
  width: '640px',
};

const getTwitchEmbed = ({ type, twitchId }) => {
  const src =
    type === 'clip'
      ? `https://clips.twitch.tv/embed?clip=${twitchId}&parent=${window.location.hostname}&autoplay=false`
      : `https://player.twitch.tv/?${type}=${twitchId}&parent=${window.location.hostname}&autoplay=false`;
  return e('iframe', { ...BASE_TWITCH_PROPS, key: `twitch-embed-${twitchId}-${getUUID(5)}`, src }, null);
};

const getWithCaption = ({ caption, embed }) => {
  const captionEl = e('div', { className: 'embed-caption', key: `embed-caption-${getUUID(5)}` }, caption);
  return e('div', { className: 'embed-container', key: `embed-container-${getUUID(5)}` }, embed, captionEl);
};

const getElement = ({ caption, embed }) => (caption ? getWithCaption({ caption, embed }) : embed);

export const embedRule = {
  order: SimpleMarkdown.defaultRules.paragraph.order + 0.5,
  match: matchCommand(COMMANDS.EMBED), // Match commands like: `{{ embed: <arg> }}`
  parse: (capture, parse, state) => {
    // Check if argument contains a URL with caption or is simply a link.
    const [, arg] = parseCommand(capture[0]);
    // Look for either a URL or a caption + URL in format: `[my caption](URL)`
    return parseEmbedLink(arg); // Returns { caption, src }
  },
  react: (node, recurseOutput) => {
    const { caption, src } = node;
    if (!src) return null;

    if (src.match(YOUTUBE_RE)) {
      // Handle YouTube video URL.
      const youtubeMatches = src.match(YOUTUBE_RE) || [];
      const videoId = youtubeMatches[1];
      const videoStartTime = youtubeMatches[3];

      const youtubeURL = `https://www.youtube.com/embed/${videoId}?${
        videoStartTime ? `start=${videoStartTime}&` : ''
      }origin=${window.location.hostname}`;

      const embed = e(
        'iframe',
        {
          className: 'youtube-embed',
          height: '360px',
          key: `youtube-embed-${videoId}-${getUUID(5)}`,
          src: youtubeURL,
          width: '640px',
        },
        null
      );
      return getElement({ caption, embed });
    } else if (src.match(TWITCH_CHANNEL_RE)) {
      // Handle a Twitch channel URL.
      const twitchId = (src.match(TWITCH_CHANNEL_RE) || [])[1];
      const embed = getTwitchEmbed({ twitchId, type: 'channel' });
      return getElement({ caption, embed });
    } else if (src.match(TWITCH_VIDEO_RE)) {
      // Handle a Twitch video URL.
      const twitchId = (src.match(TWITCH_VIDEO_RE) || [])[1];
      const embed = getTwitchEmbed({ twitchId, type: 'video' });
      return getElement({ caption, embed });
    } else if (src.match(TWITCH_CLIP_RE)) {
      // Handle a Twitch clip URL.
      const twitchId = (src.match(TWITCH_CLIP_RE) || [])[1];
      const embed = getTwitchEmbed({ twitchId, type: 'clip' });
      return getElement({ caption, embed });
    } else {
      // Defaults to image.
      const embed = e(
        'img',
        { className: 'image-embed', src: sanitizeUrl(src), key: `image-embed-${getUUID(5)}` },
        null
      );
      return getElement({ caption, embed });
    }
  },
  // html: (node, recurseOutput) => null,
};
