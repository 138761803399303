import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

import {
  isDataLoaded,
  getGameFilters,
  createArrEqSelector,
  getSeriesByTournamentId,
  FEATURED_SERIES_TIERS,
  getPreferredPrioritizedGames,
} from './gameData';
import { getIsFollowMode } from './uiStates';
import { selectorForFilteringGivenSeries, isEnded, isUserFollowingAnyGivenEntity } from './gameDataUtils';
import { getPreferredGameIds } from './user';
import { getLiveSeries, getDuplicationGuardedLiveSeries } from './liveSeries';
import { sortEvents } from './competitionUtils';
import {
  EventTournament,
  EventCompetition,
  isEventCompetition,
  isEventTournament,
  Series,
  PreferredEntitiesMap,
} from '../types';
import { RootState } from '../reducers';
import { getEntitySortedByFollow } from './followMode';
import { getPreferredEntityIdsMap } from './recentVodSeries';

const UPCOMING_SERIES_SIZE = 20;

// ---------- reselect selectors ----------

// TODO: replace getUpcomingSeries after figuring out how to do per-tournament upcoming-series fetching.
const getStateUpcomingSeries = ({ gameData: { upcomingSeries } }: RootState): Series[] =>
  Object.values(upcomingSeries).sort((a, b) => a.start.seconds - b.start.seconds);

export const getUpcomingSeries = createArrEqSelector(
  isDataLoaded,
  getStateUpcomingSeries,
  getLiveSeries,
  getPreferredEntityIdsMap,
  getIsFollowMode,
  (loaded, upcomingSeries, liveSeries, preferredEntityIdsMap, isFollowMode) => {
    if (!loaded) return [];

    //Will filter UpcomingSeries by user favorites Entities (HomePage)
    if (isFollowMode) {
      return upcomingSeries.filter(series =>
        isUserFollowingAnyGivenEntity({
          ...preferredEntityIdsMap,
          game: series.game,
          tournament: series.tournament.id,
          teams: series.teamIds,
          competition: series.tournament.competitionId,
        })
      );
    }
    return upcomingSeries.filter(series => {
      return !liveSeries.some(aLiveSeries => aLiveSeries.id === series.id);
    });
  }
);

export const getFilteredUpcomingSeries = createArrEqSelector(
  isDataLoaded,
  getUpcomingSeries,
  getGameFilters,
  selectorForFilteringGivenSeries()
);

export const getTrimmedUpcomingFeaturedSeries = createArrEqSelector(
  isDataLoaded,
  getUpcomingSeries,
  (loaded, series) => {
    if (!loaded) return [];

    return series.filter(({ tier }) => tier && FEATURED_SERIES_TIERS.includes(tier)).slice(0, UPCOMING_SERIES_SIZE);
  }
);

export const getFilteredUpcomingSingleSeries = createArrEqSelector(getFilteredUpcomingSeries, series =>
  series.length ? series[0] : null
);

export const getUpcomingSeriesByTournamentId = createCachedSelector(getSeriesByTournamentId, series =>
  series.filter(s => !!s.start && !isEnded(s))
)({
  keySelector: (_, id) => `${id}`,
  selectorCreator: createArrEqSelector,
});

const getFeaturedEvents = ({ gameData: { featuredEvents } }: RootState): (EventTournament | EventCompetition)[] =>
  Object.values(featuredEvents);

const filterFollowedEvents = (preferredEntityIdsMap: PreferredEntitiesMap) => (
  event: EventTournament | EventCompetition
) => {
  const commonProps = {
    ...preferredEntityIdsMap,
    teams: event.fromSeries.teamIds,
  };
  if (isEventTournament(event)) {
    return isUserFollowingAnyGivenEntity({
      ...commonProps,
      game: event.game,
      competition: event.competitionId,
    });
  }
  // Competitions will return a different payload.
  return isUserFollowingAnyGivenEntity({
    ...commonProps,
    competition: event.id,
    tournament: event.fromTournament.id,
    gameIds: event.gameIds,
  });
};

export const getTopUpcomingEvents = createArrEqSelector(
  isDataLoaded,
  getFeaturedEvents,
  getGameFilters,
  getPreferredGameIds,
  getPreferredEntityIdsMap,
  getIsFollowMode,
  (isLoaded, featuredEvents, gameFilters, preferredGameIds, preferredEntityIdsMap, isFollowMode) => {
    if (!isLoaded) return null;

    //Will filter Featured Events by user favorites Entities
    if (!gameFilters.length && isFollowMode) {
      return featuredEvents.filter(filterFollowedEvents(preferredEntityIdsMap));
    }

    return featuredEvents
      .filter(featuredEvent => {
        if (!gameFilters || !gameFilters.length) {
          return true;
        }

        if (
          isEventTournament(featuredEvent) &&
          Boolean(featuredEvent.game) &&
          gameFilters.includes(`${featuredEvent.game}`)
        ) {
          return true;
        }

        // Some featuredEvents have a nested gameIds prop, this will take care of them.
        if (
          isEventCompetition(featuredEvent) &&
          Boolean(featuredEvent.gameIds) &&
          gameFilters.find(game => featuredEvent.gameIds.includes(game))
        ) {
          return true;
        }

        return false;
      })
      .sort(sortEvents(preferredGameIds))
      .slice(0, 32); // keep at most 32 events on home page
  }
);

export const getTopUpcomingEventsSortedByFollow = createArrEqSelector(
  getTopUpcomingEvents,
  getPreferredEntityIdsMap,
  getIsFollowMode,
  getEntitySortedByFollow('featured-tournaments')
);

export const getPrioritizedGameSeries = createArrEqSelector(
  getPreferredPrioritizedGames,
  getDuplicationGuardedLiveSeries,
  getUpcomingSeries,
  (games, liveSeries, upcomingSeries) =>
    games.reduce((acc, game) => {
      const liveGameSeries = liveSeries.filter((aSeries: Series) => isGameSeriesParticipantPopulated(aSeries, game.id));
      const upcomingGameSeries = upcomingSeries.filter((aSeries: Series) =>
        isGameSeriesParticipantPopulated(aSeries, game.id)
      );

      if (!liveGameSeries.length && !upcomingGameSeries.length) {
        return acc;
      }

      return [...acc, { game, liveSeries: liveGameSeries, upcomingSeries: upcomingGameSeries }];
    }, [])
);

export const getIsTopTickerVisible = createSelector(
  isDataLoaded,
  getPrioritizedGameSeries,
  (_isDataLoaded, games) => _isDataLoaded && Boolean(games?.length)
);

const isGameSeriesParticipantPopulated = (aSeries: Series, gameId: string) => {
  return (
    aSeries.game === gameId &&
    Boolean(aSeries.start) &&
    aSeries.participants?.length === 2 &&
    aSeries.participants.every(participant => participant.name !== 'TBD')
  );
};
