import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { compose } from 'recompose';
import { Waypoint } from 'react-waypoint';
import classNames from 'classnames';

import HorizontalVodCard from './HorizontalVodCard';
import VodsSortOptions from './VodsSortOptions';
import GameFilterSelect from '../Filters/GameFilterSelect';
import SearchFilter from '../Filters/SearchFilter';
import WindowTitle from '../WindowTitle';
import NoContentMessage from '../common/NoContentMessage';
import { subscribeToQuery, unsubscribeToQuery } from '../../store/actions/gameData';
import { getVodScreenSeries } from '../../store/selectors/recentVodSeries';

const styles = theme => ({
  root: {
    alignItems: 'center',
    background: theme.palette.background.primary,
    textAlign: 'center',
    paddingTop: 24,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    minHeight: '100vh',
    paddingBottom: 10,
  },
  contentContainer: {
    width: 'fit-content',
    margin: '0 auto',
    padding: 12,
  },
  filters: {
    color: theme.darkGrey,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 6,
    width: 800,
  },
  gameFilter: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    textAlign: 'left',
    zIndex: 302,
    margin: '0 0 10px 8px',
  },
  isDisabled: {
    opacity: '24%',
    pointerEvents: 'none',
  },
  loadingMoreVods: {
    fontSize: 25,
    fontStyle: 'oblique',
    fontWeight: 800,
    color: theme.palette.text.primary,
  },
  loading: {
    fontSize: 45,
    fontStyle: 'oblique',
    fontWeight: 980,
    color: theme.palette.text.primary,
  },
  searchFilter: {
    margin: '0 0 10px 8px',
  },
  [theme.breakpoints.down('sm')]: {
    filters: {
      maxWidth: 300,
      justifyContent: 'center',
      margin: 'auto',
    },
    gameFilter: {
      margin: '0 0 8px 0',
    },
    searchFilter: {
      width: '100%',
      margin: '0 0 8px 0',
    },
  },
});

class VodsScreenContent extends Component {
  state = { visibleVods: 5 };
  // TODO: this is a horrible query, way too broad. Should update once we figure out
  //       how we want the filters to work.
  getQueryParams() {
    // we only show up to 14 days ago anyway, so limit query to that.
    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() - 14);

    return [
      'allSeries',
      [
        ['hasVod', '==', true],
        ['end', '>', cutoffDate],
      ], // where() params
    ];
  }

  componentDidMount() {
    this.props.subscribeToQuery(...this.getQueryParams());
  }

  //This will reset the visibleVods to 5 when the user make a search and click the clear button.
  componentDidUpdate(prevProps) {
    const { vods } = this.props;
    if ((vods && vods.length) !== (prevProps.vods && prevProps.vods.length)) {
      this.setState({
        visibleVods: 5,
      });
    }
  }

  componentWillUnmount() {
    this.props.unsubscribeToQuery(...this.getQueryParams());
  }

  handleScroll = () => {
    const { vods } = this.props;
    const { visibleVods } = this.state;

    if (!!vods && vods.length > visibleVods) {
      // Increase vods by another 5.
      this.setState({ visibleVods: visibleVods + 5 });
    }
  };

  render() {
    const {
      classes,
      games,
      onGamesChange,
      onSearchChange,
      onSortChange,
      search,
      sort,
      vods,
      isFollowMode,
    } = this.props;
    const { visibleVods } = this.state;

    return (
      <div className={classes.root}>
        <WindowTitle
          title="Esports VODs & Past Matches | Juked"
          description="Find past matches, tournaments, and VODs from the top esports with Juked’s esports VODs search | LoL, CS:GO, OW, VALORANT, and more."
        />
        <div className={classes.contentContainer}>
          <div className={classes.filters}>
            {/* `search` here is provided just for initial render state */}
            <SearchFilter
              className={classes.searchFilter}
              searchedText={search}
              onClearSearch={() => onSearchChange('')}
              onSubmit={searchTerm => onSearchChange(searchTerm)}
              placeHolder="Search for events..."
            />
            <div className={classes.gameFilter}>
              <span> Show: </span>
              <GameFilterSelect
                className={classNames({
                  [classes.isDisabled]: isFollowMode,
                })}
                onItemClick={onGamesChange}
                selectedGames={games || []}
              />
            </div>
            <VodsSortOptions activeSort={sort} onSortChange={onSortChange} />
          </div>
          {vods === null && <h2 className={classes.loading}>LOADING...</h2>}

          {vods && !vods.length && <NoContentMessage isFavoritesSelected={isFollowMode} favoritesGames={games} />}

          {vods && !!vods.length && (
            <>
              {vods.slice(0, visibleVods).map(series => (
                <HorizontalVodCard key={series.id} series={series} />
              ))}
              {vods && vods.length > visibleVods && (
                <>
                  <Waypoint onEnter={this.handleScroll} />
                  <h2 className={classes.loadingMoreVods}>LOADING...</h2>
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    (state, { games, search, sort }) => ({
      vods: getVodScreenSeries(state, { games, search, sort }),
      isFollowMode: state.uiStates.isFollowMode,
    }),
    { subscribeToQuery, unsubscribeToQuery }
  )
)(VodsScreenContent);
