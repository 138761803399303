import React from 'react';

import EntityBadge from './EntityBadge';
import { getCompetitionUrl, getTournamentUrl } from '../../../utils/urls';

export default ({ animated, className, elevation = 1, event, track }) => {
  const { game, id, images, title, tier, slug } = event;
  const { default: defaultImage, square } = images || {};

  return (
    <EntityBadge
      animated={animated}
      className={className}
      elevation={elevation}
      src={defaultImage || square}
      to={Boolean(event.tournamentIds) ? getCompetitionUrl(event) : getTournamentUrl(event)}
      track={{
        event: 'Event Badge Clicked',
        eventProperties: {
          tournament_id: id,
          tournament_title: title,
          tournament_tier: tier,
          tournament_slug: slug,
          tournament_game_id: game,
        },
        target: 'event-page',
        ...(track || {}),
      }}
    />
  );
};
