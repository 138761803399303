import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  container: {
    background: theme.palette.background.primary,
    textAlign: 'justify',
    padding: '24px 300px 1px 300px',
    '& h1': {
      textAlign: 'center',
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.senary,
    },
    '& h1, & p, & ul, & h3': {
      color: theme.palette.text.primary,
    },
  },
  '@media (max-width: 700px)': {
    container: {
      padding: 'unset',
      paddingTop: 25,
      marginBottom: 0,
      '& p': {
        margin: 25,
        marginBottom: 0,
        paddingBottom: 10,
      },
      '& ul': {
        margin: 20,
      },
      '& h3': {
        marginLeft: 15,
        fontSize: 17,
      },
    },
  },
});

const PrivacyPolicyScreen = ({ classes }) => {
  return (
    <div className={classes.container}>
      <h1>Privacy</h1>
      <p>
        This privacy policy (“<b>Policy</b>”) describes how Buffd Inc. and its related companies (“<b>Juked</b>”)
        collect, use and share personal information of consumer users of this website, classic.juked.gg (the “Site”).
        This Policy also applies to any of our other websites that post this Policy. This Policy does not apply to
        websites that post different statements.
      </p>
      <p>
        Please read the Policy carefully before you start to use the Site.
        <b>
          &nbsp;By clicking to accept or agree to the Policy when this option is made available to you, you accept and
          agree to be bound and abide by this Policy and the Terms of Use found at classic.juked.gg, incorporated herein
          by reference.&nbsp;
        </b>
        If you do not want to agree to the Policy or Terms of Use, you must not access or use the Site.
      </p>
      <p>
        We strive to educate parents and kids about how to appropriately safeguard their privacy when using our Site. We
        are committed to complying with the Children’s Online Privacy Protection Act of 1998 (“COPPA”), which requires
        us to inform parents and legal guardians about our information collection and use practices. We urge kids to
        check with their parents before entering information through our Site, and we recommend that parents discuss
        with their kids restrictions regarding the online release of personal information to anyone they don’t know.
      </p>
      <h3>WHAT WE COLLECT</h3>
      <p>We get information about you in a range of ways. </p>
      <p>
        <b>Information You Give Us.&nbsp;</b>
        We collect your‎ name, postal address, email address, phone number, username, password, demographic information
        (such as your gender and occupation), credit card number, billing information, upload, purchase, view, or
        download certain content or products from the Site; enter contests or sweepstakes; as well as other information
        you give us during your time on our Site. This may include other personal information and content, such as
        locations and activities.
      </p>
      <p>
        <b>Information We Get From Others.&nbsp;</b>
        We may get information about you from other sources such as advertisers and social media networks. This may
        include, for example, information we obtain from social logins when you choose to register with or log in to the
        Site using a social media account. We may add this to information we get from this Site. We will also collect,
        maintain, use, and display your personal data and the geographic location of your mobile device. We derive
        location information from your wireless carrier, certain third-party service providers, or directly from the
        mobile device that you use to access our Site. This location tracking of your mobile device occurs only when our
        Site is actively open and running.
      </p>
      <p>
        <b>Information Automatically Collected.&nbsp;</b>
        We automatically log information about you and your computer. For example, when visiting our Site, we log your
        computer operating system type, IP address, browser type, browser language, the website you visited before
        browsing to our Site, pages you viewed, how long you spent on a page, access times and information about your
        use of and actions on our Site.
      </p>
      <p>
        <b>Cookies.&nbsp;</b>
        We log information using "cookies." Cookies are small data files stored on your hard drive by a website. We may
        use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on
        your computer until you delete them) to provide you with a more personal and interactive experience on our Site.
        This type of information is collected to make the Site more useful to you and to tailor the experience with us
        to meet your special interests and needs.
      </p>
      <h3>USE OF PERSONAL INFORMATION</h3>
      <p>We use your personal information to:</p>
      <ul>
        <li>operate, maintain, and improve our Site, products, and services</li>
        <li>process your subscriptions and orders</li>
        <li>manage your account</li>
        <li>provide you personalized content</li>
        <li>provide you advertising and marketing messages</li>
        <li>process and deliver contest entries and rewards</li>
        <li>respond to comments and questions and provide customer service</li>
        <li>
          send information including confirmations, invoices, technical notices, updates, security alerts, and support
          and administrative messages
        </li>
        <li>
          communicate with you about promotions, upcoming events, and other news about products and services offered by
          us and our selected partners
        </li>
        <li>link or combine user information with other personal information</li>
        <li>protect, investigate, and deter against fraudulent, unauthorized, or illegal activity</li>
        <li>provide and deliver products and services customers request</li>
        <li>conduct market research</li>
        <li>generate user history and data on your participation in activities through the Site</li>
        <li>generate statistics on usage of the Site</li>
        <li> allow other users to participate in interactive features of the Site</li>
      </ul>
      <h3>SHARING OF PERSONAL INFORMATION</h3>
      <p>We may share personal information as follows:</p>

      <ul>
        <li>
          We may share personal information with your consent. For example, you may let us share personal information
          with others for their own marketing uses. Those uses will be subject to their privacy policies.
        </li>
        <li>
          We reserve the right to share personal information when we do a business deal, or negotiate a business deal,
          involving the sale or transfer of all or a part of our business or assets. These deals can include any merger,
          financing, acquisition, or bankruptcy transaction or other change of control event.
        </li>
        <li>We may share personal information for legal, protection, and safety purposes.</li>

        <ul>
          <li>We may share information to comply with laws.</li>
          <li>We may share information to respond to lawful requests and legal processes.</li>
          <li>
            We may share information to protect the rights and property of Juked, our agents, customers, and others.
            This includes enforcing our agreements, policies, and terms of use.
          </li>
          <li>
            We may share information in an emergency. This includes protecting the safety of our employees and agents,
            our customers, or any person.
          </li>
        </ul>

        <li>
          We may share information with those who need it to do work for us such as maintenance, security services,
          fulfilling orders, improving the Juked Site and services.
        </li>
      </ul>

      <p>We may also share aggregated and/or anonymized data with others for their own uses.</p>
      <h3>ANALYTICS</h3>
      <p>
        We may use third-party analytics services in connection with the Site, such as Google Analytics, which uses
        cookies and other technologies to collect and analyze personal information. These service providers use a
        variety of tracking technologies such as cookies to analyze how users use the Site. The information collected
        (described above in “Information Automatically Collected”) will be collected directly by these service providers
        to evaluate your use of the Site.
      </p>
      <p>
        <a href="https://www.google.com/policies/privacy/partners/" rel="noopener noreferrer" target="_blank">
          Click here to learn more about Google’s practices
        </a>
      </p>
      <p>
        <a href="https://tools.google.com/dlpage/gaoptout" rel="noopener noreferrer" target="_blank">
          Click here to learn how to opt-out of Google’s information collection practices
        </a>
      </p>
      <p>
        <b>APIs.&nbsp;</b>
        The Site makes use of various APIs including the YouTube API. The Google Privacy Policy at&nbsp;
        <a href="http://www.google.com/policies/privacy" rel="noopener noreferrer" target="_blank">
          www.google.com/policies/privacy
        </a>
        , applies to your use of the Site and YouTube API. The YouTube API may allow third parties to serve content,
        including advertisements. The YouTube API may store, access or collect (or allows third parties to do so)
        information directly or indirectly on or from your devices, including by placing, accessing or recognizing
        cookies or similar technology on your devices or browsers.
      </p>
      <h3>ADVERTISING</h3>
      <p>
        Juked may allow advertisers, third-party advertising networks and third-party advertising serving companies to
        serve advertisements directly to you on the Site, and other Juked services. By serving these advertisements
        directly to you, these companies can set their own cookies on your computer and trigger their own web beacons
        and other tracking technologies to measure the effectiveness of their advertisements, and to collect and track
        information such as demographic information, inferred interests, aggregated information, and activity to assist
        them in personalizing the advertising content delivered to you.
      </p>
      <p>
        This Privacy Policy does not apply to, and we cannot control the tracking technologies and activities of, other
        advertisers, third-party advertising networks, or third-party advertising servers. You should consult the
        respective privacy policies of these advertisers, third-party advertising networks, or third-party advertising
        servers.
      </p>
      <h3>EUROPEAN ECONOMIC AREA</h3>
      <p>
        Residents of the European Economic Area (“EEA”), can exercise certain data subject rights available to them
        under applicable data protection laws. We will comply with requests to exercise these rights in accordance with
        applicable law. Please note that in some circumstances, we may need to keep processing your information for
        certain legitimate interests or to comply with a legal obligation. If these rights apply to you, they may permit
        you to request that we:
      </p>
      <ul>
        <li>Provide you a copy of certain personal information we hold</li>
        <li>Correct any personal information that is incorrect or complete information that is incomplete</li>
        <li>Erase certain personal information we hold </li>
        <li>Prevent the processing of your personal information for direct marketing purposes</li>
        <li>Restrict the way that we process and disclose specific personal information about you</li>
        <li>Transfer your data to another organization</li>
        <li>Revoke consent that you previously provided for processing</li>
      </ul>
      <p>
        In addition, you may also have the right to lodge a complaint with a supervisory authority, including in your
        country of residence, place of work or where an incident took place.
      </p>
      <p>To make a request, please contact us at email found below.</p>
      <h3>CHILDREN’S PRIVACY</h3>
      <p>IF YOU ARE UNDER 13 YEARS OF AGE, THEN PLEASE DO NOT USE OR ACCESS THE SITE AT ANY TIME OR IN ANY MANNER.</p>
      <p>
        Juked does not knowingly collect or maintain personal information (as defined by COPPA) from persons under 13
        years-of-age. If Juked learns that personal information of persons under 13 has been collected on or through the
        Site, Juked will take appropriate steps to delete this information.
      </p>
      <p>Please contact Juked at the email below if you believe we have collected information on your child.</p>
      <p>
        For residents of the EEA, where processing of personal information is based on consent, Juked will not knowingly
        engage in that processing for users under the age of consent established by applicable data protection law. If
        we learn that we are engaged in that processing with such users, we will halt such processing and will take
        reasonable measures to promptly remove applicable information from our records.
      </p>
      <h3>INFORMATION CHOICES AND CHANGES</h3>
      <p>
        Our marketing emails tell you how to “opt-out.” If you opt out, we may still send you non-marketing emails.
        Non-marketing emails include emails about your accounts and our business dealings with you.
      </p>
      <p>
        You may send requests about personal information to our Contact Information below. You can request to change
        contact choices, opt-out of our sharing with others, and update your personal information.
      </p>
      <p>
        You can typically remove and reject cookies from our Site with your browser settings. Many browsers are set to
        accept cookies until you change your settings. If you remove or reject our cookies, it could affect how our Site
        works for you.{' '}
      </p>
      <p>
        The Site may link to third-party websites or services. The privacy practices of those third parties are not
        governed by this Policy and we are not responsible for the privacy, actions or other practices of any third
        parties, including Twitch and any social networks. We encourage you to review the privacy policies of these
        third-party websites and services to understand their practices.
      </p>
      <h3>TRANSFER, SECURITY AND RETENTION</h3>
      <p>
        We use servers hosted in the United States. We may be transferring your information outside of your region for
        storage and processing in the United States and around the globe. By using the Site and any affiliates services,
        you consent to the transfer of your data to countries outside of your country of residence, including the United
        States, which may have data protection rules that are different from those of your country.
      </p>
      <p>
        We use a variety of security measures to protect your information. However, we cannot guarantee that such
        information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical,
        or managerial safeguards.
      </p>
      <p>
        We keep your personal information for as long as it is required in order to continue to provide services to you,
        to fulfill the relevant purposes described in this Policy, as may be required (or permitted) by law such as for
        tax and accounting purposes, or as otherwise communicated to you.
      </p>
      <h3>CONTACT INFORMATION</h3>
      <p>We welcome your comments or questions about this privacy policy.</p>
      <p>You may also contact us at our address:</p>
      <p>contact@juked.gg</p>
      <p>
        Buffd Inc.
        <br />
        15507 S Normandie Ave Ste #347
        <br />
        Gardena, CA 90247
      </p>
      <h3>CHANGES TO THIS PRIVACY POLICY</h3>
      <p>We may change this privacy policy. If we make any changes, we will change the Last Updated date above.</p>
    </div>
  );
};

export default withStyles(styles)(PrivacyPolicyScreen);
