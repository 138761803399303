import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { MdPerson } from 'react-icons/md';

import MainPagePlayer from '../../Video/MainPagePlayer';
import { TrackLink } from '../../common';
import { setStreamCardVolume } from '../../../store/actions/video.js';
import { getSelectedStreamId } from '../../../store/selectors/uiStates';
import VideoFrameCard from '../../VideoFrameCard';
import PlayerControls from '../PlayerControls';
import VideoCardStyles from '../VideoCardStyles';

const useStyles = makeStyles(theme => ({
  container: {
    width: 736,
    [theme.breakpoints.down('sm')]: {
      width: 350,
    },
  },
  frameContainer: {
    ...VideoCardStyles.frameContainer,
    boxShadow: '2px 2px 12px 0 rgba(10,11,11,0.28)',
    '&:hover': {
      boxShadow: '0 8px 8px 0 rgba(0,0,0,0.2)',
    },
    paddingTop: '56.25%',
    border: `1px solid ${theme.palette.border.soloDenary}`,
    borderBottom: 'none',
    borderRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  viewCount: {
    ...VideoCardStyles.viewCount,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 1000,
    top: 16,
    right: 16,
    background: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    lineHeight: '16px',
    fontFamily: 'Source Sans Pro',
    fontWeight: 'bold',
    fontSize: 14,
    margin: '0 5px 0 2px',
    padding: 4,
    letterSpacing: '1px',
    borderRadius: '2px',
  },
  usersIcon: {
    fontSize: 16,
    marginRight: 5,
  },
  hoverOverlay: {
    ...VideoCardStyles.hoverOverlay,
    fontFamily: 'Source Sans Pro',
  },
}));

const VideoFrame = ({ series, selectedStreamId, isSinglePlayerMuted, setStreamCardVolume, volume, isActive }) => {
  const classes = useStyles();
  const muted = isSinglePlayerMuted;
  const [frameVolume, setFrameVolume] = useState(Boolean(volume) || volume === 0 ? volume * 100 : 50);

  const [isControlsVisible, setIsControlsVisible] = useState(false);
  const handleShowControls = useCallback(() => setIsControlsVisible(true), [setIsControlsVisible]);
  const handleHideControls = useCallback(() => setIsControlsVisible(false), [setIsControlsVisible]);

  const handleVolumeChange = (_, newValue) => {
    setFrameVolume(newValue);
    // Twitch player volume range is from 0.0 to 1.0.
    const volume = (newValue / 100).toFixed(1);
    setStreamCardVolume(volume);
  };

  // This is responsible for maintaining the volume slider in sync with the stored volume.
  useEffect(() => {
    if ((Boolean(volume) || volume === 0) && volume * 100 !== frameVolume) {
      setFrameVolume(volume * 100);
    }
  }, [volume, frameVolume]);

  const stream = series.streams[selectedStreamId];
  const streamsArr = Object.values(series.streams || {});
  const totalViewerCount = streamsArr.map(stream => stream.viewerCount).reduce((accu, viewer) => accu + viewer);

  return (
    <div className={classes.container}>
      <div className={classes.frameContainer} onMouseEnter={handleShowControls} onMouseLeave={handleHideControls}>
        <span className={classes.viewCount}>
          <MdPerson className={classes.usersIcon} />
          {totalViewerCount.toLocaleString(undefined, { maximumFractionDigits: 0 })}
        </span>
        {Boolean(stream) && isActive && (
          <MainPagePlayer
            key={stream.id}
            id={stream.id}
            platformType={stream.platformType}
            muted={muted}
            channel={stream.platformId}
            seriesId={series.id}
          />
        )}
        <TrackLink // Send a tracking event with all series context data.
          to={`/e/${series.tournament.id}/${series.id}`}
          className={classes.hoverOverlay}
          track={{
            event: 'Stream Player Clicked',
            source: 'stream-player',
            target: 'series-page',
            eventOptions: { contextSeriesId: series.id },
          }}
        >
          <h2>Click Here to</h2>
          <h1>Watch Live</h1>
        </TrackLink>
        {
          <PlayerControls
            handleVolumeChange={handleVolumeChange}
            isSinglePlayerView
            muted={isSinglePlayerMuted}
            platformType={stream.platformType}
            seriesId={series.id}
            isVisible={isControlsVisible}
            volume={frameVolume}
          />
        }
      </div>
      <VideoFrameCard series={series} />
    </div>
  );
};

export default connect(
  (state, ownProps) => ({
    volume: state.localData.streamCardVolume,
    selectedStreamId: getSelectedStreamId(state, ownProps.series),
    isSinglePlayerMuted: state.video.isSinglePlayerMuted,
  }),
  { setStreamCardVolume }
)(VideoFrame);
