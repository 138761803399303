import React from 'react';

import { Breadcrumb, BreadcrumbSpacer } from '../Breadcrumbs';
import { getOrganizationUrl } from '../../../utils/urls';

const getTracking = (target, type, event = null) => ({
  event: 'Breadcrumb Link Clicked',
  eventProperties: {
    event_name: event && event.name,
    event_slug: event && event.slug,
    event_id: event && event.id,
    type,
  },
  source: 'breadcrumb',
  target,
});

const OrganizationBreadcrumb = ({ className, organization }) => {
  if (!Boolean(organization)) return null;

  return (
    <div className={className}>
      <Breadcrumb url={`/`} track={getTracking('home-page', 'root-link')}>
        Juked
      </Breadcrumb>

      <BreadcrumbSpacer />

      <Breadcrumb
        url={getOrganizationUrl(organization)}
        track={getTracking('organization-page', 'organization-link', organization)}
      >
        {organization.name}
      </Breadcrumb>
    </div>
  );
};

export default OrganizationBreadcrumb;
