import React, { Component } from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import { fetchTweetsRequest } from '../../../../store/actions/twitter';
import { getTweetIds } from '../../../../store/selectors/twitter';
import TweetField from './TweetField';

const styles = {
  tweetsContainer: {
    display: 'flex',
    height: '100%',
    position: 'relative',
  },
  tweets: {
    borderBottom: 'solid 1px hsla(0,0%,100%,.2)',
    height: '100%',
    maxWidth: '100%',
    minWidth: '100%',
    overflow: 'auto',
    position: 'absolute',
  },
  tweetsFooter: {
    display: 'flex',
    justifyContent: 'center',
    height: '64px', // Prevents flickering when loading starts/ends.
  },
  loading: {
    border: '4px solid darkgrey' /* Grey track */,
    borderTop: '4px solid #01717a' /* Dark teal */,
    height: '20px',
    width: '20px',
    margin: '18px 0',
  },
};

/**
 * Callback currently used to style the embedded Tweet.
 */
const onLoaded = tweetWidgetEl => {
  if (!tweetWidgetEl) {
    return;
  }

  tweetWidgetEl.style['width'] = '100%';
  tweetWidgetEl.style['margin-bottom'] = '16px';

  const tweetEl = tweetWidgetEl.shadowRoot.querySelector('.EmbeddedTweet');
  tweetEl.style['border'] = 'none';
  tweetEl.style['width'] = '100%';
  tweetEl.style['max-width'] = '100%';
  tweetEl.style['background-color'] = 'transparent';
  tweetEl.style['margin'] = '0';

  const cta = tweetEl.querySelector('.EmbeddedTweet-tweetContainer > .CallToAction');
  cta.style['display'] = 'none';

  // Disables time, likes, info button.
  const info = tweetEl.querySelector('.EmbeddedTweet .TweetInfo');
  info.style['display'] = 'none';
};

/**
 * Tweets for authenticated users.
 */
class Tweets extends Component {
  state = { loadedIds: [] };

  componentDidMount() {
    // This is throttled in the twitter saga.
    const { fetchTweetsRequest } = this.props;
    fetchTweetsRequest();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tweetIds.length !== prevProps.tweetIds.length) {
      // In case a user posts a new tweet or we fetch more tweets, rerender.
      this.showMoreFetchedTweets();
    }
  }

  fetchTweets = () => {
    const { fetchTweetsRequest, tweetIds } = this.props;
    const { loadedIds } = this.state;

    if (loadedIds.length < tweetIds.length || tweetIds.length === 0) {
      // No-op if we havent rendered all or any tweets yet.
      return;
    }

    // This is throttled in the twitter saga.
    fetchTweetsRequest(tweetIds[0], tweetIds[tweetIds.length - 1]);
  };

  showMoreFetchedTweets = () => {
    // This is an optimization so that we can store large numbers of ids
    // per api call, but only render a handful at a time.
    const { tweetIds } = this.props;
    const { loadedIds } = this.state;
    if (loadedIds.length < tweetIds.length) {
      // Increase loadedIds by another 10 tweetIds.
      this.setState({ loadedIds: tweetIds.slice(0, loadedIds.length + 10) });
    }
  };

  renderShowWaypoint() {
    const { tweetIds } = this.props;
    const { loadedIds } = this.state;
    if (loadedIds.length < tweetIds.length) {
      // Only render waypoint when there are more tweets to load.
      return <Waypoint onEnter={this.showMoreFetchedTweets} bottomOffset="-200px" />;
    }
  }

  renderFetchWaypoint() {
    const { classes, isFetching } = this.props;
    return (
      <div className={classes.tweetsFooter}>
        {isFetching ? (
          // Render loading indicator instead of waypoint if fetching...
          <div className={`loading-circle ${classes.loading}`} />
        ) : (
          // Render a waypoint to kick off next fetch once reached.
          <Waypoint onEnter={this.fetchTweets} bottomOffset="0" />
        )}
      </div>
    );
  }

  render() {
    const { loadedIds } = this.state;
    const { classes } = this.props;

    return (
      <>
        <div className={classes.tweetsContainer}>
          <div className={classes.tweets}>
            {loadedIds.map(tweetId => {
              return (
                <TwitterTweetEmbed
                  key={tweetId}
                  tweetId={tweetId}
                  options={{ theme: 'dark', align: 'center', cards: 'hidden' }}
                  onLoaded={onLoaded}
                />
              );
            })}
            {this.renderShowWaypoint()}
            {this.renderFetchWaypoint()}
          </div>
        </div>
        <TweetField />
      </>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    state => ({
      tweetIds: getTweetIds(state),
      isFetching: state.twitter.tweets.isFetching,
    }),
    { fetchTweetsRequest }
  )
)(Tweets);
