import { eventChannel } from 'redux-saga';
import { put, take, fork, takeEvery } from 'redux-saga/effects';

import { db } from './firebase';
import ChannelManager from './ChannelManager';
import { SUBSCRIBE_TO_QUERY, UNSUBSCRIBE_TO_QUERY } from '../actions/gameData';

import { changeTypeActionMap } from './gameData.sagas.js';

// declare channelManager singleton
let channelManager;

function* connectChannelManager() {
  const channel = eventChannel(emit => {
    channelManager = new ChannelManager(emit);
    return () => {};
  });

  while (true) {
    const change = yield take(channel);
    yield put(changeTypeActionMap[change.changeType](change.objType, change.payload));
  }
}

/**
 * This constructs a firebase db query. Format of `where` expected to be
 * a single or an array of [field, comparison, value], so either of the following examples
 * 1) ['tournament.id', '==', '123]
 * 2) [['tournament.id', '==', '123], ['start', '<', new Date()]]
 *  */
function constructQuery(objType, where) {
  // Assuming it's querying processed collections...
  let query = db.collection(`processed_${objType}`);

  if (!where) {
    return query;
  } else if (typeof where[0] === 'string' && where.length === 3) {
    // if `where` is e.g. ['tournament.id', '==', '123]
    query = query.where(...where);
  } else if (Array.isArray(where) && where.every(w => w.length === 3)) {
    // if `where` is e.g. [['tournament.id', '==', '123], ['start', '<', new Date()]]
    where.forEach(w => {
      query = query.where(...w);
    });
  } else {
    console.error('[channelManager.sagas] `where` format incorrect');
  }

  return query;
}

function addQuerySubscriber(action) {
  const { objType, where } = action;
  channelManager.addSubscriber(constructQuery(objType, where), objType);
}

function removeQuerySubscriber(action) {
  const { objType, where } = action;
  channelManager.removeSubscriber(constructQuery(objType, where), objType);
}

export default function* gameDataSagas() {
  yield fork(connectChannelManager);
  yield takeEvery(SUBSCRIBE_TO_QUERY, addQuerySubscriber);
  yield takeEvery(UNSUBSCRIBE_TO_QUERY, removeQuerySubscriber);
}
