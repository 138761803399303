import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { TrackA, TrackLink } from '../common';
import { parseArticleId } from '../../utils/articles';

const useStyles = makeStyles(theme => ({
  cardLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

const getTrack = ({ articleId, source, target, type }) => ({
  event: 'Newsfeed Article Clicked',
  eventProperties: { articleId, type },
  source,
  target,
});

const ArticleLink = ({ article, children, className, source }) => {
  const classes = useStyles();
  const {
    attributes: { type, redirect_url: redirectUrl },
    id: articleId,
  } = article;

  if (type === 'redirect') {
    return (
      <TrackA
        className={`${classes.cardLink} ${className}`}
        href={redirectUrl}
        target="_blank"
        rel="noopener noreferrer"
        track={getTrack({ articleId, source, target: 'external-redirect', type })}
      >
        {children}
      </TrackA>
    );
  } else {
    const { sourceAbbr, sourceId } = parseArticleId(articleId);
    return (
      <TrackLink
        className={`${classes.cardLink} ${className}`}
        to={`/news/${sourceAbbr}/${sourceId}`}
        track={getTrack({ articleId, source, target: 'article-page', type })}
      >
        {children}
      </TrackLink>
    );
  }
};

export default ArticleLink;
