import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getVideoPlayerState } from '../../store/selectors/video';
import { loadStreamCardVolume } from '../../store/actions/localData';

import PlayerVolumeHandler from './PlayerVolumeHandler';
import MediaPlayer from './MediaPlayer';

class MainPagePlayer extends Component {
  render() {
    const {
      id,
      channel,
      platformType,
      muted,
      className,
      playerVolume,
      videoPlayerState,
      loadStreamCardVolume,
      seriesId,
    } = this.props;
    return (
      <PlayerVolumeHandler
        loadVolumeFn={loadStreamCardVolume}
        playerVolume={playerVolume}
        videoPlayerState={videoPlayerState}
        platformType={platformType}
      >
        <MediaPlayer
          key={id}
          id={id}
          platformType={platformType}
          muted={muted}
          channel={channel}
          className={className}
          seriesId={seriesId}
        />
      </PlayerVolumeHandler>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    videoPlayerState: getVideoPlayerState(state, ownProps.seriesId),
    playerVolume: state.localData.streamCardVolume,
  }),
  { loadStreamCardVolume }
)(MainPagePlayer);
