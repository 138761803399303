import React from 'react';
import { withStyles } from '@material-ui/styles';

import { H3 } from '.';

const styles = theme => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    padding: 0,
    '& h3': {
      margin: 0,
    },
  },
  icon: {
    fontSize: 24,
    color: theme.lightPink, // Hardcoded for now, can make into theme prop like `Button`
  },
  img: {
    height: 24,
    width: 24,
  },
  imgContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
    height: 24,
    marginRight: 12,
    width: 24,
  },
});

/**
 * Component to render a list item containing an Icon and a label.
 * @prop {class} Icon must be a component class, as imported from react-icons/md.
 * @prop {string} label text to present in header.
 * @prop {string} className (optional) className to tag on outermost element.
 */
const IconListItem = ({ classes, className, Icon, label }) => (
  <ul className={`${classes.container} ${className}`}>
    <div className={classes.imgContainer}>
      <Icon className={classes.icon} />
    </div>
    <H3>{label}</H3>
  </ul>
);

export default withStyles(styles)(IconListItem);
