import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import VodsScreenContent from '../components/Vods/VodsScreenContent';
import withUrlStateSync, { isString } from '../components/Wrappers/withUrlStateSync';
import { setVodFilters } from '../store/actions/uiStates';
import { sortOption } from '../store/selectors/recentVodSeries';
import { getPreferredGameIds, isSignedIn } from '../store/selectors/user';
import { isDataLoaded } from '../store/selectors/gameData';

const DEFAULT_SORT = sortOption.FEATURED;

class VodsScreen extends Component {
  componentDidMount() {
    this.handleSignedInState();
  }

  componentDidUpdate(prevProps) {
    const { isSignedIn, isFollowMode } = this.props;
    const { isSignedIn: prevIsSignedIn, isFollowMode: prevIsFollowMode } = prevProps;
    if (isSignedIn !== prevIsSignedIn) {
      this.handleSignedInState();
    }

    if (isFollowMode !== prevIsFollowMode) {
      this.handleSignedInState();
    }
  }

  handleSignedInState() {
    const { isSignedIn, preferredGameIds, sort, setVodFilters, isFollowMode, isDataLoaded } = this.props;

    if (!isSignedIn && !sort) {
      setVodFilters({ sort: DEFAULT_SORT });
    } else if (isDataLoaded && isSignedIn && isFollowMode) {
      setVodFilters({ sort: DEFAULT_SORT, gameIds: preferredGameIds });
    } else if (isSignedIn && !isFollowMode) {
      setVodFilters({ sort: DEFAULT_SORT, gameIds: [] });
    }
  }

  handleGamesChange = (toggleGameId = null) => {
    const { setVodFilters, sort } = this.props;

    // If a game filter is changed from the FAVORITES view, change it to FEATURED.
    const payload = sort === sortOption.FAVORITES ? { sort: DEFAULT_SORT, toggleGameId } : { toggleGameId, sort };
    setVodFilters(payload);
  };

  handleSearchChange = search => {
    const { setVodFilters } = this.props;
    setVodFilters({ search });
  };

  handleSortChange = sort => {
    const { setVodFilters, sort: previousSort } = this.props;
    if (previousSort === sort) {
      return;
    }
    setVodFilters({ sort });
  };

  render() {
    const { games, search, sort } = this.props;

    return (
      <VodsScreenContent
        games={games}
        onGamesChange={this.handleGamesChange}
        onSearchChange={this.handleSearchChange}
        onSortChange={this.handleSortChange}
        search={search}
        sort={sort}
      />
    );
  }
}

export default compose(
  connect(
    state => ({
      games: state.uiStates.vodGames,
      isSignedIn: isSignedIn(state),
      preferredGameIds: getPreferredGameIds(state),
      search: state.uiStates.vodSearch,
      sort: state.uiStates.vodSort,
      isFollowMode: state.uiStates.isFollowMode,
      isDataLoaded: isDataLoaded(state),
    }),
    { setVodFilters }
  ),
  withUrlStateSync({
    search: { action: search => setVodFilters({ search }), filter: search => isString(search) },
    sort: { action: sort => setVodFilters({ sort }), filter: sort => Object.values(sortOption).includes(sort) },
    games: { action: gameIds => setVodFilters({ gameIds }), filter: games => Array.isArray(games) },
  }) // Must be included after connect in compose.
)(VodsScreen);
