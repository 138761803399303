import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {
    backgroundColor: theme.black,
    height: 320,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  bracketsContainer: {
    position: 'relative',
    paddingRight: 96,
    zIndex: 1,
  },
  rowBrackets: {
    display: 'flex',
    alignItems: 'center',
    margin: 15,
  },
  boxBracket: {
    backgroundColor: '#282828',
    height: 32,
    width: 160,
    margin: 10,
  },
  boxBracketBorder: {
    borderTop: '2px solid #05C7CC',
  },
  buttonContainer: {
    zIndex: 1,
    '& h2': {
      color: 'white',
      fontSize: 64,
      fontWeight: 'bold',
      letterSpacing: '-1.5px',
      marginTop: 'unset',
      marginBottom: 'unset',
    },
    '& span': {
      color: theme.lightTeal,
      fontSize: 24,
      fontWeight: 600,
      letterSpacing: '4px',
      lineHeight: '24px',
    },
  },
  sectionTitle: {
    left: 0,
    position: 'absolute',
    marginTop: 111,
    '& span': {
      fontSize: 120,
      color: 'white',
      fontWeight: 'bold',
      lineHeight: '120px',
      opacity: '0.05',
    },
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      flexDirection: 'column',
      height: 360,
      maxWidth: '100%',
      overflow: 'hidden',
    },
    bracketsContainer: {
      paddingRight: 'unset',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& h2': {
        fontSize: 30,
        textAlign: 'center',
      },
      '& span': {
        fontSize: 20,
        marginTop: 5,
      },
    },
    boxBracket: {
      width: 150,
    },
    sectionTitle: {
      display: 'none',
    },
  },
});

class EventBracketsStandingsSection extends Component {
  render() {
    const { classes, onClick } = this.props;

    return (
      <div onClick={onClick} className={classes.root}>
        <div className={classes.bracketsContainer}>
          <div className={classes.rowBrackets}>
            <div>
              <div className={classes.boxBracket} />
              <div className={`${classes.boxBracket} ${classes.boxBracketBorder}`} />
            </div>
            <div>
              <div className={classes.boxBracket} />
            </div>
          </div>
          <div className={classes.rowBrackets}>
            <div>
              <div className={classes.boxBracket} />
              <div className={`${classes.boxBracket} ${classes.boxBracketBorder}`} />
            </div>
            <div>
              <div className={`${classes.boxBracket} ${classes.boxBracketBorder}`} />
            </div>
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <h2>
            Event Bracket and <br /> Standings
          </h2>
          <span>CLICK HERE</span>
        </div>

        <div className={classes.sectionTitle}>
          <span>Bracket and Standings</span>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(EventBracketsStandingsSection);
