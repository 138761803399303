import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  spacer: {
    color: theme.darkGrey,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '16px',
    margin: 0,
    padding: '0 8px',
  },
}));

const BreadCrumbSpacer = () => {
  const classes = useStyles();
  return <span className={classes.spacer}>{'>'}</span>;
};

export default BreadCrumbSpacer;
