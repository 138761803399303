import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { Checkbox } from '@material-ui/core';
import classnames from 'classnames';

import { ScrollableDialog } from '../../Dialogs';
import {
  clearOnLoginSuccess,
  closeLoginDialog,
  setLoginDialogView,
  setSignupPromptedAt,
  toggleShouldRepeatDialog,
} from '../../../store/actions/dialogs';
import { LOGIN_DIALOG_VIEWS } from '../../../store/reducers/dialogs';
import { getLoginDialogView, getOnLoginSuccess, shouldRepeatRotatingDialog } from '../../../store/selectors/dialogs';
import { isSignedIn, getUser } from '../../../store/selectors/user';
import RebrandDialog from './NewFeatureViews/RebrandDialog';
import AlphaSignUpView from './NewFeatureViews/AlphaSignUpView';
import LoginPromptView from './LoginViews/LoginPromptView';
import CalendarAddingView from './NewFeatureViews/CalendarAddingView';
import DiscordBotView from './NewFeatureViews/DiscordBotView';
import FollowSystemDialog from './NewFeatureViews/FollowSystemDialog';
import LoginView from './LoginViews/LoginView';
import SurveyView from './NewFeatureViews/SurveyView';
import { trackEvent } from '../../../store/actions/uiStates';
import FollowDialog from '../FollowDialog';
import FollowGlobalFilterDialog from '../FollowGlobalFilterDialog';

export const SIGNUP_DIALOG_ACTIONS = {
  REDIRECT: 'Signup Dialog Redirect Clicked',
  SIGNUP: 'Signup Dialog Signup Clicked',
  CLOSE: 'Signup Dialog Closed',
};

const styles = theme => ({
  dialog: {
    alignItems: 'center',
    background: theme.palette.background.secondary,
    borderRadius: 8,
    border: `1px solid ${theme.palette.border.primary}`,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '260px',
    minWidth: '400px',
  },
  checkboxContainer: {
    position: 'absolute',
    bottom: 30,
    display: 'flex',
    alignItems: 'center',
    '&.isSurveyView': {
      display: 'none',
    },
  },
  '@media (max-width: 700px)': {
    dialog: {
      minWidth: 'inherit',
      padding: 14,
    },
    checkboxContainer: {
      bottom: 20,
    },
  },
  '@media (max-width: 320px)': {
    dialog: {
      width: 272,
    },
  },
});

class LoginDialog extends Component {
  handleRenderOnSuccess = () => {
    const { closeLoginDialog } = this.props;

    // NOTE: Does not call `clearOnLoginSuccess` here in case `auth.onAuthStateChanged` has not occurred yet.
    closeLoginDialog();
    return null;
  };

  handleCloseClick = ({ shouldTrack = true } = {}) => {
    const { clearOnLoginSuccess, closeLoginDialog, setSignupPromptedAt, source, trackEvent, view } = this.props;

    // Reset prompt time when closed, in case its open a long time.  Do it here
    // so that if they fail part way through auth flow, they wont get prompted.
    setSignupPromptedAt();

    // If closing the dialog manually, the login flow did not end successfully.
    clearOnLoginSuccess();
    closeLoginDialog();
    shouldTrack &&
      trackEvent(SIGNUP_DIALOG_ACTIONS.CLOSE, {
        source,
        view,
        target: 'close-prompt',
      });
  };

  handlePromptClick = () => {
    const { setLoginDialogView } = this.props;
    setLoginDialogView(LOGIN_DIALOG_VIEWS.AUTH);
  };

  renderDialogContent = () => {
    const { view, source, history, isSignedIn, context } = this.props;
    // NOTE: Pass in view so that close button tracking view matches success action view.
    switch (view) {
      case LOGIN_DIALOG_VIEWS.ALPHA_SIGN_UP:
        return <AlphaSignUpView isSignedIn={isSignedIn} view={view} source={source} />;
      case LOGIN_DIALOG_VIEWS.REBRAND:
        return <RebrandDialog isSignedIn={isSignedIn} view={view} source={source} />;
      case LOGIN_DIALOG_VIEWS.PROMPT:
        return (
          <LoginPromptView
            onCloseClick={this.handleCloseClick}
            onClick={this.handlePromptClick}
            view={view}
            source={source}
          />
        );
      case LOGIN_DIALOG_VIEWS.ADD_TO_CALENDAR:
        return (
          <CalendarAddingView
            onCloseClick={this.handleCloseClick}
            isSignedIn={isSignedIn}
            onClick={this.handlePromptClick}
            onLoginSuccess={() => history.push('/calendar')}
            view={view}
            source={source}
          />
        );
      case LOGIN_DIALOG_VIEWS.DISCORD_BOT:
        return (
          <DiscordBotView onCloseClick={this.handleCloseClick} isSignedIn={isSignedIn} view={view} source={source} />
        );
      case LOGIN_DIALOG_VIEWS.SURVEY:
        return <SurveyView onCloseClick={this.handleCloseClick} isSignedIn={isSignedIn} view={view} source={source} />;
      case LOGIN_DIALOG_VIEWS.FOLLOW:
        return (
          <FollowDialog
            isSignedIn={isSignedIn}
            onClick={this.handlePromptClick}
            view={view}
            source={source}
            context={context}
          />
        );
      case LOGIN_DIALOG_VIEWS.FOLLOW_GLOBAL_FILTER:
        return <FollowGlobalFilterDialog isSignedIn={isSignedIn} view={view} source={source} />;

      case LOGIN_DIALOG_VIEWS.NEW_FOLLOW_SYSTEM:
        return <FollowSystemDialog isSignedIn={isSignedIn} view={view} source={source} />;
      // TODO: Add more rotating dialogs here.
      default:
        return <LoginView source={source} renderOnSuccess={this.handleRenderOnSuccess} />;
    }
  };

  render() {
    const { classes, view, shouldRepeatRotatingDialog, toggleShouldRepeatDialog, onLoginSuccess } = this.props;
    // If onLoginSuccess exist, dialog was called from outside the modal rotation.
    const shouldShowCheckbox =
      view !== LOGIN_DIALOG_VIEWS.AUTH && view !== LOGIN_DIALOG_VIEWS.PROMPT && !onLoginSuccess;

    // Must rerender LoginView on open, otherwise navigating to `/login` will break this dialog
    return (
      <ScrollableDialog
        isLoginPromptDialog={view === LOGIN_DIALOG_VIEWS.PROMPT}
        className={classes.dialog}
        onCloseClick={this.handleCloseClick}
      >
        {this.renderDialogContent()}
        {shouldShowCheckbox && (
          <div
            className={classnames({
              [classes.checkboxContainer]: true,
              isSurveyView: view === LOGIN_DIALOG_VIEWS.SURVEY,
            })}
          >
            <Checkbox id="vehicle3" checked={shouldRepeatRotatingDialog} onChange={toggleShouldRepeatDialog} />
            <label htmlFor="vehicle3"> Show this dialog again. </label>
          </div>
        )}
      </ScrollableDialog>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(
    state => ({
      isSignedIn: isSignedIn(state),
      user: getUser(state),
      onLoginSuccess: getOnLoginSuccess(state),
      view: getLoginDialogView(state),
      source: state.dialogs.source,
      context: state.dialogs.context,
      shouldRepeatRotatingDialog: shouldRepeatRotatingDialog(state),
    }),
    {
      clearOnLoginSuccess,
      closeLoginDialog,
      setLoginDialogView,
      setSignupPromptedAt,
      toggleShouldRepeatDialog,
      trackEvent,
    }
  )
)(LoginDialog);
