import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FaUser } from 'react-icons/fa';

import EventLink from './EventLink';
import totalViewerCountString from '../../utils/totalViewerCountString';
import VideoCardStyles from '../VideoSwatch/VideoCardStyles';
import { EventBreadcrumb } from './Breadcrumbs';

const useStyles = makeStyles(theme => ({
  title: {
    margin: 0,
    textAlign: 'left',
    fontSize: 20,
    color: 'white',
  },
  viewCount: {
    ...VideoCardStyles.viewCount,
    display: 'flex',
    lineHeight: '19px',
    marginTop: 10,
    verticalAlign: 'middle',
  },
  viewCountContainer: {
    display: 'flex',
    cursor: 'pointer',
  },
  usersIcon: {
    fontSize: 16,
    marginLeft: 5,
  },
  viewCountTooltipText: {
    color: 'white',
    width: 128,
    height: '51.59px',
    bottom: -45,
    left: 10,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '16px',
    position: 'absolute',
    fontSize: 13,
    textAlign: 'center',
    textTransform: 'initial',
    fontWeight: 600,
    visibility: 'hidden',
    background: theme.darkCharcoal,
    '$viewCountContainer:hover &': {
      visibility: 'visible',
    },
  },
  [theme.breakpoints.down('sm')]: {
    usersIcon: {
      fontSize: 13,
    },
    viewCount: {
      fontSize: 15,
      lineHeight: '15px',
    },
  },
}));

const EventViewershipInfo = ({ tournament, aSeries, competition, streamsArr, source }) => {
  const classes = useStyles();
  return (
    <>
      <h2 className={classes.title}>
        <EventLink tournament={tournament} aSeries={aSeries} source={source} />
        <EventBreadcrumb competition={competition} tournament={tournament} />
      </h2>
      <span className={classes.viewCount}>
        <span className={classes.viewCountContainer}>
          {totalViewerCountString(streamsArr) || 0}
          <FaUser className={classes.usersIcon} />
          {streamsArr.length >= 2 && (
            <span className={classes.viewCountTooltipText}>Total viewership for this match</span>
          )}
        </span>
      </span>
    </>
  );
};

export default EventViewershipInfo;
