import React from 'react';
import { useSelector } from 'react-redux';

import { GameContentItem } from './content-items';
import { getSortedShownGames } from '../../../store/selectors/gameData';

const GamesContent = () => {
  const games = useSelector(getSortedShownGames);

  return (
    <div>
      {games.map(game => (
        <GameContentItem key={game.id} game={game} />
      ))}
    </div>
  );
};

export default GamesContent;
