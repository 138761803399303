import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import LineClamp from '../../common/LineClamp';
import { TrackA } from '../../common';
import VideoCardStyles from '../VideoCardStyles';

// Most of this is copy-paste from other components to match existing styles.
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    textAlign: 'left',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  // copied from VideoFrame
  videoContainer: {
    width: 736,
    [theme.breakpoints.down('sm')]: {
      width: 350,
    },
  },
  frameContainer: {
    ...VideoCardStyles.frameContainer,
    boxShadow: '2px 2px 12px 0 rgba(10,11,11,0.28)',
    '&:hover': {
      boxShadow: '0 8px 8px 0 rgba(0,0,0,0.2)',
    },
    border: `1px solid ${theme.palette.border.soloDenary}`,
    borderRadius: 8,
    height: 494,
    paddingTop: 0,
  },
  // Copied from StreamInformationCard
  cardContainer: {
    background: theme.palette.background.primary,
    height: 494,
    width: 368,
    marginLeft: 16,
    borderRadius: 8,
    border: `1px solid ${theme.palette.border.soloDenary}`,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: 40,
    '& img': {
      width: 56,
      height: 56,
      marginBotttom: 8,
    },
    '& > a': {
      textDecoration: 'none',
    },
  },
  cardTitle: {
    color: theme.palette.text.primary,
    fontSize: 24,
    lineHeight: '32px',
    marginTop: 8,
    marginBottom: 16,
    lineClamp: 2,
  },
  divider: {
    height: 1,
    background: theme.palette.border.soloDenary,
    marginBottom: 20,
  },
  description: {
    color: theme.palette.text.soloDenary,
    margin: 'unset',
    fontSize: 13,
    lineHeight: '18px',
    textAlign: 'left',
    marginBottom: 46,
  },
  [theme.breakpoints.down('md')]: {
    cardContainer: {
      width: 350,
      marginTop: 20,
      marginLeft: 'unset',
    },
  },
  // copied from StreamCardButtons
  viewButton: {
    textTransform: 'uppercase',
    height: 48,
    background: theme.winePink,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'white',
    fontSize: 16,
    lineHeight: '16px',
    letterSpacing: '2.5px',
    fontWeight: 'bold',
    borderRadius: 4,
    marginTop: 24,
  },
  videoFrame: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    overflow: 'hidden',
  },
}));

// Component mimics the front page carousel content but displays alpha signup info instead.
const SingleViewAlphaSignupPrompt = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.videoContainer}>
        <div className={classes.frameContainer}>
          <iframe
            title="Esports for Everyone"
            src="https://streamable.com/e/dyyxw1?autoplay=1"
            frameBorder="0"
            width="100%"
            height="100%"
            allow="autoplay"
            className={classes.videoFrame}
          ></iframe>
        </div>
      </div>
      <div className={classes.cardContainer}>
        <div className={classes.content}>
          <LineClamp text="The Juked App is here!" className={classes.cardTitle} />
          <div className={classes.divider} />
          <span className={classes.description}>
            We're building a new social network for esports fans, and you're invited. Download now to secure your
            username, follow your favorite teams, meet like minded fans, and join the first social media community built
            for esports fans like you.
          </span>
          <TrackA className={classes.viewButton} href="https://jkd.gg/36lFrtR" target="_blank">
            Get the App
          </TrackA>
        </div>
      </div>
    </div>
  );
};

export default SingleViewAlphaSignupPrompt;
