import classnames from 'classnames';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { FaHeart, FaRegHeart } from 'react-icons/fa';

import { Button } from '../..';
import { isSignedIn } from '../../../../store/selectors/user';
import { openLoginDialog } from '../../../../store/actions/dialogs';
import { LOGIN_DIALOG_VIEWS } from '../../../../store/reducers/dialogs';
import { LOGIN_SOURCES } from '../../../../store/reducers/user';

const useStyles = makeStyles(theme => ({
  followButton: {
    minHeight: 32,
    width: 32,
    background: theme.smokedDarkGrey,
    boxShadow: '0 2px 4px 0 rgba(10,11,11,0.24)',
    padding: 8,
    fontSize: 16,
  },
}));

const FollowIconButton = ({ className, context, actionHandler, following }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isUserSignedIn = useSelector(state => isSignedIn(state));

  return (
    <Button
      className={classnames({ [classes.followButton]: true, [className]: true, active: Boolean(following) })}
      onClick={() => {
        if (!isUserSignedIn) {
          dispatch(
            openLoginDialog({
              view: LOGIN_DIALOG_VIEWS.FOLLOW,
              source: LOGIN_SOURCES.DIALOG_NEW_FEATURE,
              onLoginSuccess: () => {
                actionHandler();
              },
              context,
            })
          );
        } else {
          actionHandler();
        }
      }}
    >
      {following ? <FaHeart /> : <FaRegHeart />}
    </Button>
  );
};

export default FollowIconButton;
