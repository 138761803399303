import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';
import { compose } from 'recompose';

import { renderDateRange } from './AllViewUpcomingItem';
import ItemActionDropdown from '../ItemActionDropdown';
import MobileItemCard from '../../MobileItemCard';
import { withCalendarUrlState } from '../../wrappers';
import { GameTag, TrackLink } from '../../../common';
import { getGameById } from '../../../../store/selectors/gameData';
import { getTournamentUrl } from '../../../../utils/urls';

const styles = theme => ({
  root: {
    boxSizing: 'border-box',
    padding: 16,
    flexDirection: 'column',
    margin: '20px 0',
    background: 'white',
    boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.2)',
    alignItems: 'center',
    display: 'flex',
    borderRadius: 4,
    position: 'relative',
    textDecoration: 'none',
    overflow: 'visible',
  },
  mainRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'space-between',
  },
  dateRange: {
    color: theme.black,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '16px',
  },
  game: {
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: '1.25px',
    lineHeight: '24px',
    textAlign: 'right',
    left: '50%',
    top: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  notificationDropdown: {
    zIndex: 1,
    '.dropdownOpen &': {
      zIndex: 'initial',
    },
  },
  link: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
});

class MobileUpcomingItem extends Component {
  state = { dropdownOpen: false };
  handleClose = () => {
    this.setState({ dropdownOpen: false });
  };

  handleOpen = () => this.setState(state => ({ dropdownOpen: !state.dropdownOpen }));

  render() {
    const { classes, series, game } = this.props;
    const { dropdownOpen } = this.state;

    return (
      <div className={classnames({ [classes.root]: true, dropdownOpen })}>
        <div className={classes.mainRow}>
          <div className={classes.dateRange}>{renderDateRange(series)}</div>
          <GameTag className={classes.game} game={game} seriesId={series.id} />
          <div className={classes.notificationDropdown}>
            <ItemActionDropdown
              open={dropdownOpen}
              handleClose={this.handleClose}
              handleOpen={this.handleOpen}
              series={series}
            />
          </div>
        </div>

        <MobileItemCard series={series} game={game} allView />
        <TrackLink
          to={getTournamentUrl(series.tournament)}
          className={classes.link}
          track={{
            event: 'Schedule Item Clicked',
            target: 'event-page',
            eventProperties: { type: 'all-view-upcoming-item' },
            eventOptions: { contextSeriesId: series.id },
          }}
        />
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withCalendarUrlState,
  connect((state, ownProps) => ({
    game: getGameById(state, ownProps.series.game),
  }))
)(MobileUpcomingItem);
