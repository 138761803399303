import React, { useState } from 'react';

import FollowButton from './FollowButton';
import FollowMultiDialog from '../FollowMultiDialog';
import { useFollowingRelated } from '../../../../hooks';

const FollowMultipleEntitiesButton = ({ authContext: context, entity, relatedEntityName, entityField }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Related Entities Ids (ex: organization.teamIds)
  const following = useFollowingRelated(entityField, entity);

  return (
    <>
      <FollowButton context={context} following={following} actionHandler={() => setIsDialogOpen(!isDialogOpen)} />
      {isDialogOpen && (
        <FollowMultiDialog
          entity={entity}
          entityField={entityField}
          isDialogOpen={isDialogOpen}
          relatedEntityName={relatedEntityName}
          setIsDialogOpen={setIsDialogOpen}
        />
      )}
    </>
  );
};

export default FollowMultipleEntitiesButton;
