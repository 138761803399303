import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useFirestoreMultiSubscribeQuery } from '..';
import { FOLLOW_FIELDS } from '../../store/actions/user';
import { db } from '../../store/sagas/firebase';
import { getPreferredEntityIds } from '../../store/selectors/user';
import chunkArray from '../../utils/chunkArray';

const COLLECTIONS = {
  [FOLLOW_FIELDS.TEAM_IDS]: 'processed_teams',
  [FOLLOW_FIELDS.TOURNAMENT_IDS]: 'processed_allTournaments',
  [FOLLOW_FIELDS.GAME_IDS]: 'games',
};
export default entityField => {
  const entityIds = useSelector(state => getPreferredEntityIds(state, { entityField }));
  const queries = useMemo(
    () =>
      chunkArray(entityIds, 10).map(entityIdsChunk =>
        db.collection(COLLECTIONS[entityField]).where('id', 'in', entityIdsChunk)
      ),
    [entityIds, entityField]
  );

  const [entities] = useFirestoreMultiSubscribeQuery({ queries });
  return entities.flat();
};
