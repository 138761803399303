import React from 'react';
import styled from 'styled-components';

import { Column, Row, ScreenContainer } from '../components-v2/core';

const Text = styled.div`
  color: ${props => props.theme.palette.text.primary};
`;

const HomeScreen = (): JSX.Element => {
  return (
    <ScreenContainer px={24} py={16}>
      <Column>
        <Row>
          <Text>C1 R1 Test</Text>
        </Row>
        <Row>
          <Text>C1 R2 Test</Text>
        </Row>
        <Row>
          <Text>C1 R3 Test</Text>
        </Row>
      </Column>
      <Column>
        <Row>
          <Text>C2 R1 Test</Text>
        </Row>
        <Row>
          <Text>C2 R2 Test</Text>
        </Row>
        <Row>
          <Text>C2 R3 Test</Text>
        </Row>
      </Column>
      <Column>
        <Row>
          <Text>C3 R1 Test</Text>
        </Row>
        <Row>
          <Text>C3 R2 Test</Text>
        </Row>
        <Row>
          <Text>C3 R3 Test</Text>
        </Row>
      </Column>
    </ScreenContainer>
  );
};

export default HomeScreen;
