import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { MdVideogameAsset } from 'react-icons/md';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';

import PickerChip from './PickerChip';
import PickerItem from './PickerItem';
import { H1, IconHeader } from '../common';
import { getPreferencesRemainingGames } from '../../store/selectors/uiStates';

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.background.secondary,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 736,
  },
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    '& h1': {
      marginBottom: 8,
    },
  },
  subheader: {
    color: '#666',
  },
  selectedGames: {
    margin: '24px 0',
  },
  chipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '8px -4px 0 -4px',
  },
  cardsContainer: {
    margin: `8px -${theme.spacing(1.5)}px`,
  },
});

const FavoriteGamesPicker = ({ classes, className, onAddGame, onRemoveGame, remainingGames, selectedGames }) => {
  return (
    <div className={`${classes.container} ${className}`}>
      <div className={classes.headerContainer}>
        <H1>Choose what to Follow</H1>
        <div className={classes.subheader}>
          Take a second to choose what games you want to follow to personalize your Juked experience.
        </div>
      </div>
      <div className={classes.selectedGames}>
        <IconHeader label="Select Games" Icon={MdVideogameAsset} />
        {!!selectedGames.length && (
          <div className={classes.chipsContainer}>
            {selectedGames.map(game => (
              <PickerChip
                className={classes.chip}
                key={game.id}
                onClear={() => onRemoveGame(game.id)}
                label={game.name}
              />
            ))}
          </div>
        )}
      </div>
      <div className={classes.subheader}>Select what game(s) you want to follow below.</div>
      <Grid className={classes.cardsContainer} container spacing={3}>
        {remainingGames.map(game => (
          <Grid item key={game.id} sm={6} md={4}>
            <PickerItem
              label={game.name}
              imgSrc={game.images && game.images.square}
              onClick={() => onAddGame(game.id)}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default compose(
  withStyles(styles),
  connect((state, { selectedGames }) => ({
    remainingGames: getPreferencesRemainingGames(
      state,
      selectedGames.map(game => game.id)
    ),
  }))
)(FavoriteGamesPicker);
