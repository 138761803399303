import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { addHours } from 'date-fns';
import { saveAs } from 'file-saver';

import { TrackButton } from '../common';
import { Dialog } from '../Dialogs';
import { getSeriesUrl } from '../../utils/urls';
import { FaGoogle } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';

const styles = theme => ({
  text: {
    fontSize: 32,
    fontWeight: 400,
    lineHeight: '40px',
    textAlign: 'center',
    color: theme.palette.text.primary,
    marginTop: 0,
    marginBottom: 24,
  },
  button: {
    letterSpacing: 0,
    margin: '0 8px',
    minWidth: 175,
    height: 48,
    padding: '8px 10px',
    fontSize: 14,
    fontWeight: 600,
    borderRadius: 6,
    lineHeight: '20px',
    background: '#E84D2D',
    color: 'white',
    boxShadow: '2px 2px 12px 0 rgba(10,11,11,0.28)',
    '&:hover': {
      background: '#E84D2D',
      opacity: 0.9,
    },
    '&.darkGrey': {
      background: theme.darkGrey,
    },
  },
  dialog: {
    top: '30%',
    left: '50%',
    transform: 'translateX(-50%, -50%)',
    alignItems: 'center',
    background: theme.palette.background.secondary,
    border: 'none',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '448px',
    padding: '48px',
    boxSizing: 'border-box',
    position: 'fixed',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    background: 'rgba(10, 11, 11, 0.16)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
    '& svg': {
      color: 'white',
      fontSize: 18,
    },
  },
  '@media (max-width: 700px)': {
    dialog: {
      maxWidth: '300px',
      padding: 14,
      width: '90%',
    },
    button: {
      padding: '0 5px',
      marginBottom: 10,
    },
    row: {
      flexDirection: 'column',
    },
  },
  '@media (max-width: 320px)': {
    dialog: {
      width: 272,
    },
  },
});

class ItemCalendarAddingDialog extends Component {
  createGoogleCalendarEvent = async () => {
    const { onCloseClick, series } = this.props;

    const calendarLink = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${escape(
      this.getEventTitle()
    )}&dates=${this.getTimeStamp(series.start.toDate())}/${this.getTimeStamp(
      addHours(series.start.toDate(), 1)
    )}&details=<a href=${escape(
      `${window.location.origin}${getSeriesUrl(
        series
      )}?utm_medium=exportcal_google&utm_source=calendar_redirected&utm_campaign=google_calendar`
    )}>Click here to watch the event live on Juked.gg</a>.`;

    window.open(calendarLink, '_blank');
    onCloseClick();
  };

  hasParticipants = () => {
    const { series } = this.props;
    return (
      series.participants &&
      series.participants.length &&
      series.participants.every(participant => !!participant.name && participant.name !== 'TBD')
    );
  };

  getEventTitle = () => {
    const { series } = this.props;
    return `${series.tournament.title} - ${series.title}${
      this.hasParticipants() ? ` - ${series.participants[0].name} vs. ${series.participants[1].name}` : ''
    }`;
  };

  getTimeStamp = date => date.toISOString().replace(/-|:|\.\d\d\d/g, '');

  createIcsFile = () => {
    const { onCloseClick, series } = this.props;

    const event = `BEGIN:VCALENDAR\nVERSION:2.0\nCALSCALE:GREGORIAN\nPRODID:JUKED\nMETHOD:PUBLISH\nX-PUBLISHED-TTL:PT1H\nBEGIN:VEVENT\nUID:${
      series.id
    }\nSUMMARY:${this.getEventTitle()}\nDESCRIPTION: Click the link to watch the event live on Juked.gg  ${
      window.location.origin
    }${getSeriesUrl(
      series
    )}?utm_medium=exportcal_ical&utm_source=calendar_redirected&utm_campaign=ics_file\nDTSTAMP:${this.getTimeStamp(
      new Date()
    )}\nDTSTART:${this.getTimeStamp(series.start.toDate())}\nDTEND:${this.getTimeStamp(
      addHours(series.start.toDate(), 1)
    )}\nBEGIN:VALARM\nACTION:DISPLAY\nDESCRIPTION:Reminder\nTRIGGER:-PT0H30M\nEND:VALARM\nEND:VEVENT\nEND:VCALENDAR\n`;

    const blob = new Blob([event], {
      type: 'data:text/calendar;charset=utf8,',
    });
    saveAs(blob, `calendar${series.id}.ics`);
    onCloseClick();
  };

  render() {
    const { classes, onCloseClick, series } = this.props;

    const getTracking = type => ({
      event: 'Add To Calendar Created',
      eventProperties: { type },
      eventOptions: { contextSeriesId: series.id },
    });

    return (
      <Dialog className={classes.dialog} onCloseClick={onCloseClick}>
        <h1 className={classes.text}>Choose your calendar</h1>
        <div className={classes.row}>
          <TrackButton
            className={classes.button}
            onClick={this.createGoogleCalendarEvent}
            track={getTracking('google-calendar')}
          >
            <div className={classes.icon}>
              <FaGoogle />
            </div>
            <span>Google Calendar</span>
          </TrackButton>
          <TrackButton
            className={`${classes.button} darkGrey`}
            onClick={this.createIcsFile}
            track={getTracking('ical')}
          >
            <div className={classes.icon}>
              <FiDownload />
            </div>
            iCal File
          </TrackButton>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ItemCalendarAddingDialog);
