export const CHANGE_CALENDAR_VIEW = 'CHANGE_CALENDAR_VIEW';
export const INCREASE_WEEK = 'INCREASE_WEEK';
export const DECREASE_WEEK = 'DECREASE_WEEK';
export const CHECK_SERIES_STREAM = 'CHECK_SERIES_STREAM';

export const changeCalendarView = view => ({
  type: CHANGE_CALENDAR_VIEW,
  view,
});

export const increaseWeek = () => ({
  type: INCREASE_WEEK,
});

export const decreaseWeek = () => ({
  type: DECREASE_WEEK,
});

export const checkSeriesStream = () => ({
  type: CHECK_SERIES_STREAM,
});
