import { Bracket, Game, Series, Standings, Stream } from '../types';

export const GAME_DATA_INITIALIZED = 'GAME_DATA_INITIALIZED';
export const GAME_DATA_ADDED = 'GAME_DATA_ADDED';
export const GAME_DATA_CHANGED = 'GAME_DATA_CHANGED';
export const GAME_DATA_REMOVED = 'GAME_DATA_REMOVED';

// TODO: Make a generic pattern for fetching single objects from firestore.
export const FETCH_BRACKET = 'FETCH_BRACKET';
export const FETCH_STANDINGS = 'FETCH_STANDINGS';

// The following action allows components to subscribe to specific db queries
export const SUBSCRIBE_TO_QUERY = 'SUBSCRIBE_TO_QUERY';
export const UNSUBSCRIBE_TO_QUERY = 'UNSUBSCRIBE_TO_QUERY';

export const gameDataTypes = {
  games: 'games',
  brackets: 'brackets',
  standings: 'standings',

  // --- pre-processed collections ---
  liveSeries: 'liveSeries',
  recentVodSeries: 'recentVodSeries',
  upcomingSeries: 'upcomingSeries',
  allSeries: 'allSeries',
  featuredEvents: 'featuredEvents',
};

type GameDataTypes = typeof gameDataTypes;
type GameDataTypeKey = keyof GameDataTypes;

export type GameDataPayload = Game | Stream | Bracket | Standings | Series;
type GameDataPayloadArray = GameDataPayload[];
type GameDataPayloadSimple = { id: string };

interface GameDataInitializedAction {
  type: typeof GAME_DATA_INITIALIZED;
  objType: GameDataTypeKey;
  payload: GameDataPayloadArray;
}

export const gameDataInitialized = (objType: GameDataTypeKey, payload: GameDataPayloadArray): GameDataActionTypes => ({
  type: GAME_DATA_INITIALIZED,
  objType,
  payload,
});

interface GameDataAddedAction {
  type: typeof GAME_DATA_ADDED;
  objType: GameDataTypeKey;
  payload: GameDataPayload;
}

export const gameDataAdded = (objType: GameDataTypeKey, payload: GameDataPayload): GameDataActionTypes => ({
  type: GAME_DATA_ADDED,
  objType,
  payload,
});

interface GameDataChangedAction {
  type: typeof GAME_DATA_CHANGED;
  objType: GameDataTypeKey;
  payload: GameDataPayload;
}

export const gameDataChanged = (objType: GameDataTypeKey, payload: GameDataPayload): GameDataActionTypes => ({
  type: GAME_DATA_CHANGED,
  objType,
  payload,
});

interface GameDataRemovedAction {
  type: typeof GAME_DATA_REMOVED;
  objType: GameDataTypeKey;
  payload: GameDataPayload | GameDataPayloadSimple;
}

export const gameDataRemoved = (
  objType: GameDataTypeKey,
  payload: GameDataPayload | GameDataPayloadSimple
): GameDataActionTypes => ({
  type: GAME_DATA_REMOVED,
  objType,
  payload,
});

interface FetchBracketAction {
  type: typeof FETCH_BRACKET;
  id: string;
}

export const fetchBracket = (id: string): GameDataActionTypes => ({
  type: FETCH_BRACKET,
  id,
});

interface FetchStandingsAction {
  type: typeof FETCH_STANDINGS;
  id: string;
}

export const fetchStandings = (id: string): GameDataActionTypes => ({
  type: FETCH_STANDINGS,
  id,
});

interface SubscribeToQueryAction {
  type: typeof SUBSCRIBE_TO_QUERY;
  objType: GameDataTypeKey;
  where: string[];
}

// The `objType` is also where the results will be stored in redux state.
// e.g. if you pass `allSeries` as objType, the saga will look up the
// `processed_allSeries` collection, and store results in `state.allSeries`
export const subscribeToQuery = (objType: GameDataTypeKey, where: string[]): GameDataActionTypes => ({
  type: SUBSCRIBE_TO_QUERY,
  objType,
  where,
});

interface UnsubscribeToQueryAction {
  type: typeof UNSUBSCRIBE_TO_QUERY;
  objType: GameDataTypeKey;
  where: string[];
}

export const unsubscribeToQuery = (objType: GameDataTypeKey, where: string[]): GameDataActionTypes => ({
  type: UNSUBSCRIBE_TO_QUERY,
  objType,
  where,
});

export type GameDataModelActionTypes = GameDataAddedAction | GameDataChangedAction | GameDataRemovedAction;

export type GameDataModelsActionTypes =
  | GameDataInitializedAction
  | GameDataAddedAction
  | GameDataChangedAction
  | GameDataRemovedAction;

export type GameDataActionTypes =
  | GameDataInitializedAction
  | GameDataAddedAction
  | GameDataChangedAction
  | GameDataRemovedAction
  | FetchBracketAction
  | FetchStandingsAction
  | SubscribeToQueryAction
  | UnsubscribeToQueryAction;
