import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { BodyText, FollowIconButton } from '../common';
import RelationCard, { TopContent } from '../common/RelationCard';
import OrganizationImage from '../Images/OrganizationImage';
import teamNameFormat from '../../utils/getFormattedName';
import LineClamp from '../common/LineClamp';
import { useFollowing, useFollowAction } from '../../hooks';
import { FOLLOW_FIELDS } from '../../store/actions/user';

const useStyles = makeStyles(theme => ({
  teamCard: {
    margin: 16,
    height: 64,
  },
  followButton: {
    position: 'absolute',
    right: 8,
    bottom: 8,
  },
  textRow: {
    marginBottom: 4,
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 145,
    fontSize: 13,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      width: 190,
    },
  },
  playerCount: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.text.primary,
  },
  teamImage: {
    width: 40,
    height: 40,
    marginRight: 8,
  },
  lineClamp: {
    lineClamp: 2,
  },
  content: {
    marginBottom: 'unset',
    marginTop: -5,
  },
}));

const TeamCard = ({ game, organization, team }) => {
  const classes = useStyles();
  const { images, name } = team;
  const following = useFollowing(FOLLOW_FIELDS.TEAM_IDS, team);
  const actionHandler = useFollowAction(following, FOLLOW_FIELDS.TEAM_IDS, team);

  return (
    <RelationCard
      className={classes.teamCard}
      game={game}
      FollowComponent={
        <FollowIconButton
          actionHandler={actionHandler}
          className={classes.followButton}
          context={{ images, name }}
          following={following}
        />
      }
      ImageComponent={({ className }) => (
        <OrganizationImage className={`${className} ${classes.teamImage}`} organization={team} />
      )}
      track={{
        event: 'Team Card Clicked',
        eventProperties: {
          team_id: team.id,
          team_slug: team.slug,
          team_name: team.name,
        },
        source: 'organization-page',
        target: 'team-page',
      }}
      url={`/t/${team.slug}`}
    >
      <TopContent className={classes.content}>
        <BodyText className={`${classes.textRow} ${classes.title}`} variant="largeBold" el="div">
          <LineClamp className={classes.lineClamp} text={teamNameFormat(team.name, organization.name, game)} />
        </BodyText>
        <BodyText variant="smallLight" el="div">
          {team.playerCount > 1 ? `${team.playerCount} Players` : `${team.playerCount} Player`}
        </BodyText>
      </TopContent>
    </RelationCard>
  );
};

export default TeamCard;
