import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import DesktopItem from './LiveItem';
import MobileItem from './MobileLiveItem';

export default props => {
  const matchesDesktop = useMediaQuery('(min-width:960px)'); // SM Breakpoint
  return matchesDesktop ? <DesktopItem {...props} /> : <MobileItem {...props} />;
};
