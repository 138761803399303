import { format, isSameDay } from 'date-fns';
import React from 'react';

export default ({ className, obj }) => {
  // Not supposed to happen because of "processedTournaments", but just to be safe.
  if (!obj.start || !obj.end) {
    return null;
  }

  if (isSameDay(obj.start.toDate(), obj.end.toDate())) {
    return <div className={className}>{format(obj.start.toDate(), 'MMM d, Y')}</div>;
  }

  return (
    <div className={className}>
      {format(obj.start.toDate(), 'MMM') === format(obj.end.toDate(), 'MMM')
        ? `${format(obj.start.toDate(), 'MMM d')} - ${format(obj.end.toDate(), 'd, Y')}`
        : `${format(obj.start.toDate(), 'MMM d')} - ${format(obj.end.toDate(), 'MMM d, Y')}`}
    </div>
  );
};
