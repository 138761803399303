import classnames from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaVolumeUp, FaVolumeMute, FaPause, FaPlay } from 'react-icons/fa';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';

import { storeStreamCardVolume } from '../../store/actions/localData';
import { togglePause, toggleMute } from '../../store/actions/video.js';
import { getVideoPlayerState } from '../../store/selectors/video';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    background: 'linear-gradient(0deg, black 0%, transparent 100%)',
    bottom: 0,
    display: 'flex',
    height: 30,
    opacity: 0,
    padding: '0 10px',
    position: 'absolute',
    transition: 'opacity 240ms ease-in',
    width: '100%',
    zIndex: 600,
    '&.isVisible': {
      // Dont need to hide the bar because anytime you would hover, it will become visible
      opacity: 1,
    },
  },
  controlButtons: {
    background: 'none',
    border: 'none',
    outline: 'none',
    color: 'white',
    cursor: 'pointer',
    fontSize: 20,
    height: 20,
    '&:hover': {
      color: theme.lightPink,
    },
  },
  playPauseButton: {
    height: 16,
    fontSize: 16,
  },
  sliderContainer: {
    width: 130,
    display: 'flex',
    justifyContent: 'center',
    background: 'transparent',
    border: 'none',
    outline: 'none',
  },
  volumeRange: {
    width: 90,
    color: 'white',
    '&:hover': {
      color: theme.lightPink,
    },
  },
}));

const PlayerControls = ({
  handleVolumeChange,
  isSinglePlayerView,
  muted,
  platformType,
  seriesId,
  isVisible,
  volume,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const videoPlayerState = useSelector(state => getVideoPlayerState(state, seriesId));
  const playerStateId = videoPlayerState && videoPlayerState.id;

  const handleChangeCommitted = useCallback(
    (_, value) => dispatch(storeStreamCardVolume(value, seriesId, platformType)),
    [dispatch, seriesId, platformType]
  );
  const handleToggleMute = useCallback(
    e => {
      e.preventDefault();
      dispatch(toggleMute(playerStateId, { isSinglePlayerView }));
    },
    [dispatch, isSinglePlayerView, playerStateId]
  );
  const handleTogglePause = useCallback(
    e => {
      e.preventDefault();
      dispatch(togglePause(playerStateId, { isSinglePlayerView }));
    },
    [dispatch, isSinglePlayerView, playerStateId]
  );
  const preventDefault = useCallback(e => e.preventDefault(), []);
  const stopPropagation = useCallback(e => e.stopPropagation(), []);

  return videoPlayerState ? (
    <div className={classnames({ [classes.root]: true, isVisible })}>
      <button
        className={`${classes.playPauseButton} ${classes.controlButtons}`}
        title={videoPlayerState.paused ? 'play' : 'pause'}
        onClick={handleTogglePause}
      >
        {videoPlayerState.paused ? <FaPlay /> : <FaPause />}
      </button>
      <button className={`${classes.controlButtons}`} title={muted ? 'unmute' : 'mute'} onClick={handleToggleMute}>
        {muted ? <FaVolumeMute /> : <FaVolumeUp />}
      </button>
      {!muted && (
        <div className={classes.sliderContainer} onClick={preventDefault}>
          <Slider
            value={volume}
            className={classes.volumeRange}
            valueLabelDisplay="auto"
            onChange={handleVolumeChange}
            onChangeCommitted={handleChangeCommitted}
            onClick={preventDefault}
            // This prevents the carousel from getting swiped when drgging the card volume slider
            onMouseDown={stopPropagation}
          />
        </div>
      )}
    </div>
  ) : null;
};

export default PlayerControls;
