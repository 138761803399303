import { useMemo } from 'react';

import { useFirestoreMultiSubscribeQuery, useFirestoreSubscribeQuery, useFirestoreMultiGet } from '../../hooks';
import { db } from '../../store/sagas/firebase';
import chunkArray from '../../utils/chunkArray';

export const ARTICLE_COUNT = 7;

const getArticlesQuery = ({ gameId, featured, limit, teamIds, eventIds, favoriteGames }) =>
  ['game', 'featured', 'teamIds', 'eventIds', 'favoriteGames']
    .reduce((accu, property) => {
      // Filter on games if necessary.
      if (property === 'game') return gameId ? accu.where('gameIds', 'array-contains', gameId) : accu;
      // NOTE: Because firestore cant do a `!= null` query, search for any with featuredAt since Epoch.
      if (property === 'featured') return featured ? accu.where('featuredAt', '>', new Date('1970-01-01')) : accu;
      //Show related articles on Organization or Teams page
      if (property === 'teamIds') return teamIds ? accu.where('teamIds', 'array-contains-any', teamIds) : accu;
      //Show related artciles on Event page
      if (property === 'eventIds') return eventIds ? accu.where('eventIds', 'array-contains-any', eventIds) : accu;
      //Will show News base on the user Favorites games. (HomePage)
      if (favoriteGames?.length && !gameId) {
        if (property === 'favoriteGames')
          return favoriteGames ? accu.where('gameIds', 'array-contains-any', favoriteGames) : accu;
      }

      return accu;
    }, db.collection('articles').where('isPublished', '==', true))
    .orderBy(featured ? 'featuredAt' : 'attributes.date', 'desc')
    // Use limit * 3 for homepage because we need to load enough articles to
    // surface older game-favorited ones above non-game-favorited ones.
    .limit(gameId ? limit : limit * 3);

const articlesQuery = (ids, featured, limit, property) => {
  return chunkArray(ids, 10).map(idsChunk => getArticlesQuery({ featured, limit, [property]: idsChunk }));
};

export const useSubscribeArticles = ({ gameId, teamId, eventId, favoritesGameIds }) => {
  const query = useMemo(
    // Use ARTICLE_COUNT + 3 to guarantee we will have 7 more articles that are not featured spots.
    () =>
      getArticlesQuery({
        gameId,
        featured: false,
        limit: ARTICLE_COUNT + 3,
        teamIds: teamId && [teamId],
        eventIds: eventId && [eventId],
        favoritesGameIds,
      }),
    [gameId, teamId, eventId, favoritesGameIds]
  );
  const featuredQuery = useMemo(
    () =>
      getArticlesQuery({
        gameId,
        featured: true,
        limit: 3,
        teamIds: teamId && [teamId],
        eventIds: eventId && [eventId],
        favoritesGameIds,
      }),
    [gameId, teamId, eventId, favoritesGameIds]
  );
  const [articles] = useFirestoreSubscribeQuery({ query });
  const [featuredArticles] = useFirestoreSubscribeQuery({ query: featuredQuery });
  return [articles, featuredArticles];
};

export const useSubscribeArticlesMultiQuery = ({ contextName, contextIds }) => {
  const queries = useMemo(() => {
    // Use ARTICLE_COUNT + 3 to guarantee we will have 7 more articles that are not featured spots.
    return Boolean(contextIds?.length) ? articlesQuery(contextIds, false, ARTICLE_COUNT + 3, contextName) : [];
  }, [contextName, contextIds]);

  const featuredQueries = useMemo(() => {
    return Boolean(contextIds?.length) ? articlesQuery(contextIds, true, 3, contextName) : [];
  }, [contextName, contextIds]);

  const [articles] = useFirestoreMultiSubscribeQuery({ queries });
  const [featuredArticles] = useFirestoreMultiSubscribeQuery({ queries: featuredQueries });
  return [articles.flat(), featuredArticles.flat()];
};

//Will filter News by user favorites Entities (HomePage)
export const useFavoriteEntitiesArticles = ({ favoritesGameIds, favoritesTeamIds, favoritesTournamentIds, gameId }) => {
  const queries = useMemo(() => {
    if (!gameId && Boolean(favoritesGameIds?.length || favoritesTeamIds?.length || favoritesTournamentIds?.length)) {
      return [
        ...(Boolean(favoritesGameIds?.length)
          ? articlesQuery(favoritesGameIds, false, ARTICLE_COUNT + 3, 'favoriteGames')
          : []),
        ...(Boolean(favoritesTeamIds?.length)
          ? articlesQuery(favoritesTeamIds, false, ARTICLE_COUNT + 3, 'teamIds')
          : []),
        ...(Boolean(favoritesTournamentIds?.length)
          ? articlesQuery(favoritesTournamentIds, false, ARTICLE_COUNT + 3, 'eventIds')
          : []),
      ];
    } else {
      return [getArticlesQuery({ featured: false, limit: ARTICLE_COUNT + 3, gameId })];
    }
  }, [favoritesGameIds, favoritesTeamIds, favoritesTournamentIds, gameId]);

  const featuredQueries = useMemo(() => {
    if (!gameId && Boolean(favoritesGameIds?.length || favoritesTeamIds?.length || favoritesTournamentIds?.length)) {
      return [
        ...(Boolean(favoritesGameIds?.length) ? articlesQuery(favoritesGameIds, true, 3, 'favoriteGames') : []),
        ...(Boolean(favoritesTeamIds?.length) ? articlesQuery(favoritesTeamIds, true, 3, 'teamIds') : []),
        ...(Boolean(favoritesTournamentIds?.length) ? articlesQuery(favoritesTournamentIds, true, 3, 'eventIds') : []),
      ];
    } else {
      return [getArticlesQuery({ featured: true, limit: 3, gameId })];
    }
  }, [favoritesGameIds, favoritesTeamIds, favoritesTournamentIds, gameId]);
  const [articles] = useFirestoreMultiGet({ queries });
  const [featuredArticles] = useFirestoreMultiGet({ queries: featuredQueries });
  return [articles.flat(), featuredArticles.flat()];
};
