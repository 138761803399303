import React, { useEffect } from 'react';
import ArticleSection from '../ArticlesSection/index';

import { useSubscribeArticlesMultiQuery } from '../../hooks/articles';

const MultiEntityArticleSection = ({ title, queryProps, onFetchArticles }) => {
  const [articles, featuredArticles] = useSubscribeArticlesMultiQuery(queryProps);

  useEffect(() => {
    onFetchArticles && onFetchArticles(articles);
  }, [articles, onFetchArticles]);

  return <ArticleSection title={title} articles={articles} featuredArticles={featuredArticles} showLink />;
};
export default MultiEntityArticleSection;
