import React, { Fragment } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import UserSection from './UserSection';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    border: 'none',
    bottom: '0',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .45s',
    transitionTimingFunction: 'linear, linear, ease',
    position: 'fixed',
    top: '0',
    height: '100vh',
    maxHeight: '1200px',
    right: '0',
    left: 'auto',
    overflowY: 'auto',
    borderTop: 'none',
    textAlign: 'center',
    paddingRight: '0px',
    paddingLeft: '0',
    backgroundColor: theme.palette.background.primary,
    display: 'block',
    '& hr': {
      margin: 20,
      opacity: 0.2,
    },
  },
  overlay: {
    content: '" "',
    position: 'fixed',
    transition: 'opacity 0.8s ease-in',
    width: '100%',
    height: 0,
    opacity: 0,
    background: 'rgba(0,0,0,0.5)',
    left: 0,
    top: 0,
    display: 'block',
  },
  overlayActive: {
    height: '100%',
    opacity: 1,
  },
  drawerActive: {
    width: '85%',
    paddingTop: 18,
  },
  drawerClose: {
    width: 0,
  },
}));

const Drawer = ({ drawerOpen }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <div
        className={classNames({
          [classes.overlay]: true,
          [classes.overlayActive]: drawerOpen,
        })}
      />
      <div
        anchor="right"
        className={classNames({
          [classes.drawerClose]: !drawerOpen,
          [classes.drawerActive]: drawerOpen,
          [classes.drawerPaper]: true,
        })}
      >
        <UserSection />
      </div>
    </Fragment>
  );
};

export default Drawer;
