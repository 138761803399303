export const ADD_PLAYER = 'ADD_PLAYER';
export const REMOVE_PLAYER = 'REMOVE_PLAYER';
export const TOGGLE_PAUSE = 'TOGGLE_PAUSE';
export const TOGGLE_PAUSE_ALL = 'TOGGLE_PAUSE_ALL';
export const SET_PAUSED_STATE = 'SET_PAUSED_STATE';
export const CLEAN_PAUSED_SERIES_STATE_MAP = 'CLEAN_PAUSED_SERIES_STATE_MAP';
export const SET_STREAM_CARD_VOLUME = 'SET_STREAM_CARD_VOLUME';
export const SET_EVENT_PAGE_VOLUME = 'SET_EVENT_PAGE_VOLUME';
export const TOGGLE_MUTE = 'TOGGLE_MUTE';
export const SET_UNMUTED_PLAYER_ID = 'SET_UNMUTED_PLAYER_ID';
export const TOGGLE_THEATER_MODE = 'TOGGLE_THEATER_MODE';

export const addPlayer = (streamId, channel, type, muted, controls, video, startTime, seriesId) => ({
  type: ADD_PLAYER,
  streamId,
  channel,
  platformType: type,
  muted,
  controls,
  video,
  startTime,
  seriesId,
});

export const removePlayer = seriesId => ({
  type: REMOVE_PLAYER,
  seriesId,
});

export const togglePause = (seriesId, { isSinglePlayerView } = {}) => ({
  type: TOGGLE_PAUSE,
  seriesId,
  isSinglePlayerView,
});

export const setPausedState = (seriesId, { isPaused } = {}) => ({
  type: SET_PAUSED_STATE,
  seriesId,
  isPaused,
});

export const togglePauseAll = () => ({
  type: TOGGLE_PAUSE_ALL,
});

// Paused series are tracked on local storage to be able to load those SPECIFIC player paused.
// This action is dispatched every 12 hours by the uiStates saga to clean the state and local
// storage from all the series that aren't live any more.
export const cleanPausedSeriesStateMap = pausedSeriesState => ({
  type: CLEAN_PAUSED_SERIES_STATE_MAP,
  pausedSeriesState,
});

export const setStreamCardVolume = (volume, onInitialLoad) => ({
  type: SET_STREAM_CARD_VOLUME,
  volume,
  onInitialLoad,
});

export const setEventPageVolume = volume => ({
  type: SET_EVENT_PAGE_VOLUME,
  volume,
});

export const toggleMute = (seriesId, { isSinglePlayerView } = {}) => ({
  type: TOGGLE_MUTE,
  seriesId,
  isSinglePlayerView,
});

export const setUnmutedSeriesId = (seriesId, isMatchScreenPlayer) => ({
  type: SET_UNMUTED_PLAYER_ID,
  seriesId,
  isMatchScreenPlayer,
});

export const toggleTheaterMode = () => ({
  type: TOGGLE_THEATER_MODE,
});
