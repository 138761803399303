import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MdPerson } from 'react-icons/md';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import MobileItemCard from '../../MobileItemCard';
import { GameTag, TrackLink } from '../../../common';
import { getGameById } from '../../../../store/selectors/gameData';
import { getSeriesUrl, getTournamentUrl } from '../../../../utils/urls';
import totalViewerCountString from '../../../../utils/totalViewerCountString';

const styles = theme => ({
  root: {
    boxSizing: 'border-box',
    padding: 16,
    flexDirection: 'column',
    margin: '20px 0',
    background: theme.palette.background.secondary,
    boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.2)',
    alignItems: 'center',
    display: 'flex',
    borderRadius: 4,
    position: 'relative',
    textDecoration: 'none',
  },
  viewersRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  viewersContainer: {
    color: theme.redPink,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  viewers: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '16px',
  },
  viewersIcon: {
    marginLeft: 5,
    fontSize: 16,
    lineHeight: 16,
    verticalAlign: 'middle',
  },
  game: {
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: '1.25px',
    lineHeight: '24px',
    textAlign: 'right',
    left: '50%',
    top: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  link: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
});

class MobileLiveItem extends Component {
  render() {
    const { classes, series, game, isMatchView } = this.props;
    const streamsArr = Object.values(series.streams || {});

    return (
      <div className={classes.root}>
        <div className={classes.viewersRow}>
          <div className={classes.viewersContainer}>
            <span className={classes.viewers}>{totalViewerCountString(streamsArr)}</span>
            <MdPerson className={classes.viewersIcon} />
          </div>
          <GameTag game={game} className={classes.game} seriesId={series.id} />
        </div>
        <MobileItemCard series={series} game={game} />
        {/* Link needs to be absolute possitioned to prevent having nested links because of GameTag */}
        <TrackLink
          to={isMatchView ? getSeriesUrl(series) : getTournamentUrl(series.tournament)}
          className={classes.link}
          track={{
            event: 'Schedule Item Clicked',
            target: isMatchView ? 'series-page' : 'event-page',
            eventProperties: { type: 'live-item' },
            eventOptions: { contextSeriesId: series.id },
          }}
        />
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    (state, ownProps) => ({
      game: getGameById(state, ownProps.series.game),
    }),
    {}
  )
)(MobileLiveItem);
