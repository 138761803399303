import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Variants as found in designs: https://projects.invisionapp.com/d/main/#/console/18346923/418865097/inspect
const useStyles = makeStyles(theme => {
  const VARIANTS = {
    bold: {
      fontWeight: 'bold',
    },
    default: {
      color: theme.palette.text.primary,
      fontSize: 15,
      fontWeight: 'normal',
      lineHeight: '20px',
      letterSpacing: 0,
    },
    tabLabel: {
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: '4px',
      lineHeight: '20px',
    },
    large: {
      fontSize: 18,
      lineHeight: '26px',
    },
    largeBold: {
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: '26px',
    },
    largeLight: {
      color: theme.palette.text.secondary,
      fontSize: 18,
      lineHeight: '26px',
    },
    largeSemiBold: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '26px',
    },
    light: {
      color: theme.palette.text.secondary,
    },
    semiBold: {
      fontWeight: 600,
    },
    semiBoldLight: {
      fontWeight: 600,
      color: theme.palette.text.secondary,
    },
    small: {
      fontSize: 13,
      lineHeight: '18px',
    },
    smallLight: {
      color: theme.palette.text.secondary,
      fontSize: 13,
      lineHeight: '18px',
    },
    smallSemiBold: {
      fontSize: 13,
      fontWeight: 600,
      lineHeight: '18px',
    },
    subtext: {
      fontSize: 10,
      lineHeight: '16px',
      letterSpacing: '0.2px',
    },
  };

  const getCSS = (variant, property) => VARIANTS[variant][property] || VARIANTS['default'][property];

  return {
    text: {
      color: ({ variant, color }) => color || getCSS(variant, 'color'),
      fontSize: ({ variant }) => getCSS(variant, 'fontSize'),
      fontWeight: ({ variant }) => getCSS(variant, 'fontWeight'),
      lineHeight: ({ variant }) => getCSS(variant, 'lineHeight'),
      letterSpacing: ({ variant }) => getCSS(variant, 'letterSpacing'),
    },
  };
});

export default ({ className, children, color, el, onClick, variant = 'default' }) => {
  const classes = useStyles({ color, variant });
  const props = {
    className: `${className} ${classes.text}`,
    onClick,
  };
  return el === 'div' ? <div {...props}>{children}</div> : <span {...props}>{children}</span>;
};
