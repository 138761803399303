import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import GameBadge from './GameBadge';
import { getGames } from '../../../store/selectors/gameData';

const useStyles = makeStyles(() => {
  return {
    badgesContainer: {
      display: 'flex',
      flexDirection: 'row',
      '& .badge': {
        transition: 'margin .3s ease, box-shadow .15s ease-out, transform .15s ease-out',
        margin: ({ spacing }) => `0 ${spacing} 0 0`,
        filter: 'drop-shadow(0 0 1px white)',
        '&:last-child': {
          margin: 0,
        },
      },
    },
  };
});

export default ({ animated, elevation, gameIds, badgeClassName, className, spacing = '8px', track }) => {
  const classes = useStyles({ spacing });
  const games = useSelector(getGames);

  return !gameIds || !gameIds.length ? null : (
    <div className={`${classes.badgesContainer} ${className}`}>
      {gameIds.map(gameId => {
        const game = games[gameId];
        return !game ? null : (
          <GameBadge
            animated={animated}
            elevation={elevation}
            className={`badge ${badgeClassName}`}
            game={game}
            key={gameId}
            track={track}
          />
        );
      })}
    </div>
  );
};
