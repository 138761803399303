import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { getAuthenticated } from '../../../../store/selectors/twitter';
import Tweets from './Tweets';
import TwitterAuth from './TwitterAuth';

const useStyles = makeStyles({
  widget: {
    backgroundColor: '#0f0e11',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  tweetsHeader: {
    alignItems: 'center',
    backgroundColor: '#232127',
    borderBottom: '1px solid hsla(0,0%,100%,.05)',
    color: '#dad8de',
    display: 'flex',
    fontSize: '12px',
    maxHeight: '50px',
    minHeight: '50px',
    padding: '0 10px 0 20px',
    '& h5': {
      fontWeight: 600,
      textAlign: 'center',
      margin: 'auto',
      fontSize: '12px',
    },
  },
  tweetsContainer: {
    display: 'flex',
    height: '100%',
    position: 'relative',
  },
  tweets: {
    borderBottom: 'solid 1px hsla(0,0%,100%,.2)',
    height: '100%',
    overflow: 'auto',
    position: 'absolute',
  },
});

const TwitterFeed = ({ authenticated }) => {
  const classes = useStyles();

  return (
    <div className={classes.widget}>
      <div className={classes.tweetsHeader}>
        <h5>TWITTER FEED</h5>
      </div>
      {authenticated ? <Tweets /> : <TwitterAuth />}
    </div>
  );
};

export default connect(
  state => ({
    authenticated: getAuthenticated(state),
  }),
  {}
)(TwitterFeed);
