import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { compose } from 'recompose';
import classnames from 'classnames';
import { addDays, addMonths, format, addYears, isWithinInterval } from 'date-fns';

import { TrackHashLink } from '../common';
import { locationToString } from '../../components/common/QueryLink';

import { getCalendarSections, TITLES } from '../../store/selectors/calendar';
import { CALENDAR_LINK_FILTERS } from '../../store/reducers/uiStates';

const styles = theme => ({
  jumpLinks: {
    display: 'flex',
    marginLeft: 'auto',
    textDecoration: 'underline',
    '.showAll &': {
      marginRight: 'auto',
    },
    ' & > *:not(:first-child)': {
      marginLeft: 40,
    },
  },
  jumpLink: {
    color: theme.palette.text.default,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '30px',
    '&.disabled': {
      color: 'grey',
      pointerEvents: 'none',
    },
  },
  [theme.breakpoints.down('sm')]: {
    jumpLinks: {
      display: 'none',
    },
  },
});

class JumpLinks extends Component {
  getJumpLink = (index, showAll) => {
    const { startDate, endDate } = this.props;
    const isThisWeek = isWithinInterval(new Date(), { start: startDate, end: endDate });

    if (showAll) {
      return format(addMonths(new Date(), index), 'MMMM');
    } else if (index === 0 && isThisWeek) {
      return TITLES.TODAY;
    } else if (index === 1 && isThisWeek) {
      return TITLES.TOMORROW;
    } else {
      return format(addDays(new Date(), index), 'EEEE');
    }
  };

  getJumpLinkTo = (index, showAll) =>
    locationToString({ ...this.props.location, hash: this.getJumpLink(index, showAll) });

  render() {
    const { classes, calendarView, searchInput, sections } = this.props;
    const showAll = calendarView === CALENDAR_LINK_FILTERS.ALL && !searchInput;
    return (
      <div className={classes.jumpLinks}>
        {[...Array(showAll ? 12 : 7).keys()].map(index => {
          const jumpLink = this.getJumpLink(index, showAll);

          return (
            <TrackHashLink
              key={index}
              smooth
              to={this.getJumpLinkTo(index, showAll)}
              className={classnames({
                [classes.jumpLink]: true,
                disabled: !sections.find(section => section.title === jumpLink),
              })}
              track={{
                event: 'Calendar Date Jump Clicked',
                source: 'calendar-page',
                target: jumpLink,
              }}
            >
              {jumpLink}
            </TrackHashLink>
          );
        })}
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    (state, { seriesRange, searchInput, calendarView }) => ({
      sections: getCalendarSections(state, { seriesRange, searchInput, calendarView }),
      startDate: state.calendar.startDate,
      endDate: !!searchInput ? addYears(new Date(), 999) : state.calendar.endDate,
    }),
    {}
  )
)(JumpLinks);
