import React from 'react';
import { TrackA } from '../common';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  banner: {
    maxWidth: 900,
    margin: 'auto',
    boxShadow: '3px 3px 6px 1px rgba(0,0,0,0.5)',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    '& a': {
      display: 'flex',
    },
  },
  [theme.breakpoints.down('sm')]: {
    banner: {
      maxWidth: 600,
    },
  },
  [theme.breakpoints.down('xs')]: {
    banner: {
      maxWidth: '95%',
    },
  },
}));

const PickemsBanner = ({ className, source }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.banner} ${className}`}>
      <TrackA
        href="https://classic.juked.gg/e/TFTFatesNAQual1/live"
        target="_blank"
        title="Pickems Banner"
        track={{ event: 'Pickems Banner Clicked', source, target: 'event-page' }}
      >
        <img src="/images/Juked_Pickems_Banner.png" alt="pickemsBanner" />
      </TrackA>
    </div>
  );
};

export default PickemsBanner;
