import { delay, fork, take, put, select } from 'redux-saga/effects';
import { SIGN_OUT } from '../actions/user';
import {
  CLOSE_LOGIN_DIALOG,
  openLoginDialog,
  setSignupPromptedAt,
  toggleShouldRepeatDialog,
  switchRotatingDialogOff,
} from '../actions/dialogs';
import { setRecurrentUser } from '../actions/uiStates';
import { LOGIN_DIALOG_VIEWS, LOGIN_DIALOG_SOURCES_MAP } from '../reducers/dialogs';
import { shouldShowSignupPrompt, getNextRotatingDialog, shouldRepeatRotatingDialog } from '../selectors/dialogs';
import { isFirstTimeVisitor } from '../selectors/uiStates';
import { isSignedIn } from '../selectors/user';
import history from '../../history';
import { LOGIN_SOURCES } from '../reducers/user';

const PROMPT_PATHNAME_BLACKLIST = new Set(['/login']);

function* pollForSignupPrompt() {
  // This should never stop running...
  while (true) {
    yield delay(15 * 1000); // Wait 15 seconds.

    const {
      location: { pathname },
    } = history;

    if (yield select(shouldShowSignupPrompt)) {
      // Ensure that prompt does not open on blacklisted pathname.
      if (pathname && !PROMPT_PATHNAME_BLACKLIST.has(pathname)) {
        yield put(setSignupPromptedAt()); // Update signupPromptedAt time.

        const unshownDialog = yield select(getNextRotatingDialog);

        if (yield select(isFirstTimeVisitor)) {
          yield put(setRecurrentUser());
        }

        if (!unshownDialog && !(yield select(isSignedIn))) {
          // Show propt dialog to all first time viaitors or not signed users who saw all the rotating dialogs.

          if (!(yield select(isSignedIn))) {
            // Since this is a new feature, there's a chance that signed in users are treated like first time visitors.
            yield put(openLoginDialog({ view: LOGIN_DIALOG_VIEWS.PROMPT, source: LOGIN_SOURCES.PROMPT }));
          }
        } else if (!!unshownDialog) {
          // If the user has unseen rotating dialogs, show the next in rotation,
          yield put(openLoginDialog({ view: unshownDialog, source: LOGIN_DIALOG_SOURCES_MAP[unshownDialog] }));

          yield take(CLOSE_LOGIN_DIALOG); // Wait for the user to close the dialog.
          if (yield select(shouldRepeatRotatingDialog)) {
            // If the repeat dialog flag was activated
            yield put(toggleShouldRepeatDialog()); // Toggle it OFF.
          } else {
            // If the repeat dialog flag was false, mark rotating dialog as seen. ( Will not show again)
            yield put(switchRotatingDialogOff(unshownDialog));
          }
        } else {
          // If the user is signedIn and has seen all the rotating dialogs, stop this until sign out.
          yield take(SIGN_OUT);
        }
      }
    }
  }
}

export default function* dialogsSaga() {
  yield fork(pollForSignupPrompt);
}
