import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';
import { FaTimes } from 'react-icons/fa';

import { addStaticBackgroundContainer, removeStaticBackgroundContainer } from '../../store/actions/uiStates';

const styles = theme => ({
  dialog: {
    display: 'block',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100000000,
    background: 'white',
    padding: 12,
  },
  overlay: {
    display: 'block',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    background: 'rgba(0, 0, 0, 0.6)',
    top: 0,
    zIndex: 99999999,
  },
  closeButton: {
    width: 20,
    height: 20,
    fontFamily: 'Arial',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '1px',
    position: 'absolute',
    top: -6,
    right: -6,
    padding: 2,
    borderRadius: 2,
    border: 0,
    background: theme.darkPink,
    color: 'white',
    outline: 0,
    cursor: 'pointer',
  },
});

class Dialog extends Component {
  state = { mounted: false };

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    // Disable scrolling on body.
    this.props.addStaticBackgroundContainer();

    // Append new div to the modal-root.  Render children only after the
    // new anchor element has mounted.
    this.modalRoot = document.getElementById('modal-root');
    this.modalRoot.appendChild(this.el);
    this.setState({ mounted: true });

    window.addEventListener('keydown', this.handleEsc);
  }

  componentWillUnmount() {
    this.props.removeStaticBackgroundContainer();
    this.modalRoot.removeChild(this.el);

    window.removeEventListener('keydown', this.handleEsc);
  }

  //This will close the modal if the user clicks the esc button
  handleEsc = event => {
    if (event.keyCode === 27) {
      this.props.onCloseClick();
    }
  };

  render() {
    const { className, classes, children, onCloseClick, withoutCloseButton } = this.props;
    const { mounted } = this.state;
    return mounted
      ? ReactDOM.createPortal(
          <Fragment>
            <div className={classnames({ [classes.overlay]: true })} />
            <div className={classnames({ [className]: true, [classes.dialog]: true })}>
              {children}
              {!withoutCloseButton && (
                <button className={classes.closeButton} onClick={onCloseClick}>
                  <FaTimes />
                </button>
              )}
            </div>
          </Fragment>,
          this.el
        )
      : null;
  }
}

export default compose(
  withStyles(styles),
  connect(null, { addStaticBackgroundContainer, removeStaticBackgroundContainer })
)(Dialog);
