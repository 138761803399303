import React from 'react';
import { withStyles } from '@material-ui/styles';
import { FaCheck } from 'react-icons/fa';

import { TrackSpan } from './Track';
import SliderSwitch from './SliderSwitch';

const styles = theme => ({
  switch: {
    marginLeft: 10,
    '& .slider': {
      '.checked&': {
        background: `${theme.lightTeal} !important`,
      },
    },
  },
});

const DarkModeSwitch = ({ checked, onChange, classes, variant }) => (
  <TrackSpan
    track={{
      event: 'Toggle DarkMode',
      source: 'dark-mode-switch',
      target: checked ? 'unset-dark-mode' : 'set-dark-mode',
    }}
  >
    <SliderSwitch
      id="darkModeSwitch"
      className={classes.switch}
      checked={checked}
      onChange={onChange}
      Icon={FaCheck}
      variant={variant}
    />
  </TrackSpan>
);

export default withStyles(styles)(DarkModeSwitch);
