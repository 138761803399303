import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import MissingArticle from './MissingArticle';
import { parseMd } from '../../utils/simpleMarkdown';

const MEDIA_CSS = {
  display: 'block',
  margin: '0 auto',
  width: '100%',
  maxWidth: '610px', // Like Medium, slightly smaller than full width.
};

const getCaptionCSS = theme => ({
  color: theme.palette.text.secondary,
  display: 'block',
  fontSize: 15,
  fontStyle: 'italic',
  letterSpacing: 0,
  lineHeight: '20px',
  marginTop: 10,
  textAlign: 'center',
});

const getSpacingCSS = theme => ({
  margin: '2em 0 -0.46em 0',
  [theme.breakpoints.down('sm')]: {
    marginTop: '1.56em',
  },
});

const getListCSS = (theme, type) => {
  return {
    listStyle: 'none',
    listStyleImage: 'none',
    margin: 0,
    padding: 0,
    '& li:first-child': {
      marginTop: '2em',
    },
    '& li': {
      color: theme.palette.text.primary,
      listStyleImage: 'none',
      listStylePosition: 'outside',
      listStyleType: `${type === 'ol' ? 'decimal' : 'disc'}`,
      marginBottom: '-0.46em',
      marginLeft: 28,
      marginTop: '1.05em',
    },
    [theme.breakpoints.down('sm')]: {
      '& li:first-child': {
        marginTop: '1.56em',
      },
      '& li': {
        marginTop: '1.34em',
      },
    },
  };
};

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 21,
    lineHeight: '32px',
    '& .paragraph': {
      ...getSpacingCSS(theme),
      color: theme.palette.text.primary,
    },
    '& .youtube-embed, .twitch-embed': {
      border: 'none',
      borderRadius: 4,
      ...MEDIA_CSS,
    },
    '& .embed-container': {
      ...MEDIA_CSS,
    },
    '& .embed-caption': {
      ...getCaptionCSS(theme),
    },
    '& a': {
      color: theme.palette.text.primary,
      borderBottom: `1px solid ${theme.palette.text.primary}`,
      textDecoration: 'none',
      transition: '.2s ease',
    },
    '& a:hover': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    '& blockquote': {
      boxShadow: `inset 3px 0 0 0 ${theme.palette.text.secondary}`,
      marginLeft: '-23px',
      paddingLeft: '23px',
      '& .paragraph': {
        fontStyle: 'italic',
      },
    },
    '& h1': {
      color: theme.palette.text.primary,
      fontSize: 34,
      fontWeight: 600,
      letterSpacing: '-0.015em',
      lineHeight: 1.15,
      margin: '1.95em 0 -0.21em 0',
    },
    '& h2': {
      color: theme.palette.text.primary,
      fontSize: 30,
      fontWeight: 600,
      letterSpacing: '-0.018em',
      lineHeight: 1.15,
      margin: '1.72em 0 -0.21em 0',
    },
    '& h3, h4, h5, h6': {
      ...getSpacingCSS(theme),
      color: theme.palette.text.primary,
      fontSize: 26,
      fontWeight: 600,
      letterSpacing: '-0.022em',
      lineHeight: 1.15,
      margin: '1.72em 0 -0.21em 0',
    },
    '& h1 + .paragraph, h2 + .paragraph, h3 + .paragraph, h4 + .paragraph, h5 + .paragraph, h6 + .paragraph': {
      marginTop: '0.86em',
    },
    '& h1 + .paragraph > .embed-container, h2 + .paragraph > .embed-container, h3 + .paragraph > .embed-container, h4 + .paragraph > .embed-container, h5 + .paragraph > .embed-container, h6 + .paragraph > .embed-container': {
      marginTop: 56, // Images (using Juked syntax) below headers.
    },
    '& h1 + .paragraph > img, h2 + .paragraph > img, h3 + .paragraph > img, h4 + .paragraph > img, h5 + .paragraph > img, h6 + .paragraph > img': {
      marginTop: 56, // Images below headers.
    },
    '& hr': {
      border: 'none',
      fontSize: 28,
      margin: '30px 0 14px 0',
      textAlign: 'center',
      '&:before': {
        color: theme.palette.text.primary,
        content: "'...'",
        letterSpacing: '0.6em',
        lineHeight: 1.4,
        fontStyle: 'italic',
        textIndent: '0.6em',
      },
    },
    '& img': {
      borderRadius: 4,
      ...MEDIA_CSS,
    },
    '& img + em, img + strong': {
      ...getCaptionCSS(theme),
    },
    '& pre': {
      ...getSpacingCSS(theme),
      background: theme.palette.background.secondary,
      padding: 20,
      overFlowX: 'auto',
      '& code': {
        display: 'block',
      },
    },
    '& code': {
      background: theme.palette.background.secondary,
      borderRadius: 6,
      color: theme.palette.text.primary,
      fontFamily: 'Menlo, Monaco, "Courier New", Courier, monospace',
      fontSize: 16,
      fontWeight: 'normal',
      letterSpacing: '-0.022em',
      lineHeight: '1.18',
      margin: '-0.09em 0',
      padding: '.2em .4em',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
    '& ol': {
      ...getListCSS(theme, 'ol'),
    },
    '& ul': {
      ...getListCSS(theme, 'ul'),
    },
    '& table': {
      ...getSpacingCSS(theme),
      borderBottom: `1px solid ${theme.palette.border.primary}`,
      borderCollapse: 'collapse',
      borderColor: theme.palette.border.primary,
      borderSpacing: 0,
      boxSizing: 'border-box',
      width: '100%',
      maxWidth: '610px', // Like Medium img, slightly smaller than full width.
      '& thead': {
        color: theme.palette.text.secondary,
        fontSize: '112.5%',
        lineHeight: 1.5,
        '& tr': {
          border: 'none',
        },
        '& th': {
          borderTop: `1px solid #e3e6ea`,
          fontWeight: 600,
          padding: '0.5em 5px',
        },
      },
      '& tbody': {
        color: theme.palette.text.primary,
        lineHeight: 1.5,
        '& td': {
          borderTop: `1px solid #e3e6ea`,
          fontWeight: 'normal',
          padding: '0.5em 5px',
        },
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      fontSize: 18,
      lineHeight: '26px',
      '& blockquote': {
        marginLeft: '-20px',
        paddingLeft: '20px',
      },
    },
  },
}));

export default ({ article: { body } }) => {
  const classes = useStyles();
  return body ? <div className={classes.root}>{parseMd(body)}</div> : <MissingArticle />;
};
