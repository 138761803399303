import React from 'react';

import ContentItem from './ContentItem';
import { ENTITY_TYPES, FOLLOW_FIELDS } from '../../../../store/actions/user';
import { getTeamUrl } from '../../../../utils/urls';

const TeamContentItem = ({ team, name }) => {
  const { images, name: _name } = team;
  return (
    <ContentItem
      context={{ images, name }}
      entity={team}
      entityField={FOLLOW_FIELDS.TEAM_IDS}
      entityType={ENTITY_TYPES.TEAM}
      name={name || _name}
      to={getTeamUrl(team)}
    />
  );
};

export default TeamContentItem;
