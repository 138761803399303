import { createSelector, OutputSelector } from 'reselect';
import createCachedSelector from 're-reselect';

import { PreferredEntityField, NotificationEntityField } from '../actions/user';
import { RootState } from '../reducers';
import { UserState } from '../reducers/user';
import { UserPreferences, UserNotifications } from '../types';
import getIn from '../../utils/getIn';

export const getUser = ({ user }: RootState): UserState => user;

export const isFetchingUser = createSelector(
  getUser,
  user => user.isSignedIn === undefined || (!!user.isSignedIn && user.fetched === undefined)
);

export const stillNeedsEmailVerification = createSelector(
  getUser,
  user => !!user.providerData && user.providerData[0].providerId === 'password' && !user.emailVerified
);

export const isSignedIn = createSelector(
  getUser,
  stillNeedsEmailVerification,
  (user, needsVerification) => user.isSignedIn && !needsVerification
);

export const getDisplayName = createSelector(
  getUser,
  user => user.displayName || (!!user.email && user.email.match(/(^.*)@/)[1]) // or first part of email before @
);

export const getMailerOptIn = (mailerType: string): OutputSelector<RootState, boolean, (res: UserState) => boolean> =>
  createSelector(getUser, user => user.mailers?.includes(mailerType));

export const getPreferredEntityIds = createCachedSelector(
  getUser,
  (_: RootState, props: { entityField: PreferredEntityField }) => props.entityField,
  (user, entityField): UserPreferences[PreferredEntityField] => getIn(['preferences', entityField])(user) || []
)({ keySelector: (_, props) => `preferences${props.entityField}`, selectorCreator: createSelector });

export const getNotificationEntityIds = createCachedSelector(
  getUser,
  (_: RootState, props: { entityField: PreferredEntityField }) => props.entityField,
  (user, entityField): UserNotifications[NotificationEntityField] => getIn(['notifications', entityField])(user) || []
)({ keySelector: (_, props) => `notifications${props.entityField}`, selectorCreator: createSelector });

export const getPreferredGameIds = createSelector(
  getUser,
  (user): UserPreferences['gameIds'] => getIn(['preferences', 'gameIds'])(user) || []
);

export const getPreferencesAccountStatus = createSelector(getUser, user => user.preferencesAccountStatus);

export const getPreferencesErrors = createSelector(getUser, user => user.preferencesErrors || {});

export const getTimeZone = createSelector(getUser, user => user.timeZone);

export const isAdminUser = createSelector(getUser, isFetchingUser, (user, isFetching) => [
  Boolean(user.isSignedIn && user.admin),
  isFetching,
]);
