import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { DropdownSelect, DropdownItem } from '../common';

import { sortOption } from '../../store/selectors/recentVodSeries';
import { isSignedIn } from '../../store/selectors/user';

const SORT_OPTIONS = [
  {
    value: sortOption.FEATURED,
    label: 'Featured',
  },
  {
    value: sortOption.VIEWS,
    label: 'Views',
  },
  {
    value: sortOption.RECENT,
    label: 'Recent',
  },
];

const styles = () => ({
  optionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 10,
  },
  optionsButton: {
    marginLeft: 16,
    '& .root': {
      textTransform: 'capitalize',
      width: 124,
      '& .dropIcon': {
        margin: '8px 10px 8px auto',
      },
    },
    '& .popper': {
      zIndex: 302, // To beat HorizontalVodCard
      width: 120,
      '& .dropdown': {
        maxWidth: '120px',
      },
    },
  },
});

class VodsSortOptions extends Component {
  state = { open: false };

  render() {
    const { activeSort, classes, onSortChange } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.optionsContainer}>
        <span> Sort By: </span>
        <DropdownSelect
          withArrowIcon
          className={classes.optionsButton}
          open={open}
          active={true}
          placeholder={activeSort}
          handleOpen={() => this.setState(state => ({ open: !state.open }))}
          handleClose={() => this.setState({ open: false })}
        >
          {SORT_OPTIONS.map(option => {
            return (
              <DropdownItem
                key={option.value}
                active={activeSort === option.value}
                onClick={() => {
                  onSortChange(option.value);
                  this.setState({ open: false });
                }}
              >
                {option.label}
              </DropdownItem>
            );
          })}
        </DropdownSelect>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    state => ({
      isSignedIn: isSignedIn(state),
    }),
    {}
  )
)(VodsSortOptions);
