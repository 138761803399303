import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import { storeEventPageVolume, loadEventPageVolume } from '../../store/actions/localData';
import { getVideoPlayerState } from '../../store/selectors/video';
import PlayerVolumeHandler from './PlayerVolumeHandler';
import MediaPlayer from './MediaPlayer';

const INTERVAL_DURATION = 10000;

const styles = {
  sportBuffContainer: {
    height: '100%',
    position: 'absolute',
    width: '100%',
    top: 0,
    zIndex: 1,
  },
};

class HeroMainPlayer extends Component {
  componentDidMount() {
    this.volumePolling = setInterval(() => {
      const { videoPlayerState, playerVolume, storeEventPageVolume } = this.props;
      const { player } = videoPlayerState || {};
      const volume = player && player.getVolume();
      if (!!playerVolume && `${volume}` !== playerVolume) {
        storeEventPageVolume(volume);
      }
    }, INTERVAL_DURATION);
  }

  componentWillUnmount() {
    clearInterval(this.volumePolling);
  }

  render() {
    const {
      classes,
      id,
      sportBuffId,
      channel,
      platformType,
      playerVolume,
      videoPlayerState,
      video,
      seriesId,
      startTime,
      loadEventPageVolume,
    } = this.props;

    return (
      <PlayerVolumeHandler
        loadVolumeFn={loadEventPageVolume}
        playerVolume={playerVolume}
        videoPlayerState={videoPlayerState}
        platformType={platformType}
      >
        <div id={sportBuffId} className={classes.sportBuffContainer}>
          <MediaPlayer
            key={id}
            id={id}
            platformType={platformType}
            muted={false}
            channel={channel}
            controls
            video={video}
            seriesId={seriesId}
            startTime={startTime}
          />
        </div>
      </PlayerVolumeHandler>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    (state, ownProps) => ({
      videoPlayerState: getVideoPlayerState(state, ownProps.seriesId),
      playerVolume: state.localData.eventPageVolume,
    }),
    { storeEventPageVolume, loadEventPageVolume }
  )
)(HeroMainPlayer);
