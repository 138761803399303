import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Breadcrumb, BreadcrumbSpacer } from '../Breadcrumbs';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
}));

const getTracking = (target, type, game = null) => ({
  event: 'Breadcrumb Link Clicked',
  eventProperties: {
    game_id: game.id,
    type,
  },
  source: 'breadcrumb',
  target,
});

const GameBreadcrumb = ({ className, game }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${className}`}>
      <Breadcrumb url={`/`} track={getTracking('home-page', 'root-link', game)}>
        Juked
      </Breadcrumb>

      <BreadcrumbSpacer />
    </div>
  );
};

export default GameBreadcrumb;
