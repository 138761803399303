import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import classnames from 'classnames';

import { getSeriesById } from '../../store/selectors/gameData';

const getBackgroundColor = (color, theme) => {
  switch (color) {
    case 'lightTeal':
      return theme.lightTeal;

    case 'magenta':
      return theme.magentaPink;
    default:
      return color;
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 0,
    boxSizing: 'border-box',
    transition: 'all 400ms ease-in-out',
    '.open &': {
      height: 60,
    },
  },
  growingButton: {
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    color: 'white',
    height: 32,
    width: 120,
    borderRadius: 4,
    background: ({ color }) => getBackgroundColor(color, theme),
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '16px',
    transition: 'transform 180ms ease-in-out',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:disabled': {
      opacity: 0.32,
      pointerEvents: 'none',
    },
    '&:hover': {
      transform: 'scale(1.15)',
      transformOrigin: 'center',
    },
    '& svg': {
      color: 'white',
      fontSize: 16,
      lineHeight: '16px',
    },
    '& span': {
      lineHeight: '20px',
      verticalAlign: 'text-bottom',
    },
  },
}));

const OptionButton = ({ participant, optionValue, optionKey, color, selectedOptionKey, onClick, disabled, type }) => {
  const classes = useStyles({ color });
  const selected = !!selectedOptionKey && selectedOptionKey === optionKey;

  return (
    <div
      className={classnames({
        [classes.root]: true,
        selected,
      })}
    >
      <div className={classes.growingButton}>
        <button
          className={classes.button}
          disabled={disabled && !selected}
          onClick={() => !selectedOptionKey && onClick()}
        >
          <span>{type === 'pickem' ? participant.name : optionValue}</span>
        </button>
      </div>
    </div>
  );
};

export default connect(
  (state, ownProps) => ({
    series: getSeriesById(state, ownProps.seriesId),
  }),
  {},
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    participant:
      stateProps.series.participants && stateProps.series.participants.find(p => p.uuid === ownProps.optionKey),
  })
)(OptionButton);
