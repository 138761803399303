import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import MultipleEntitiesFollowDialog from './MultipleEntitiesFollowDialog';
import { savePreferredEntities } from '../../../store/actions/user';
import { getPreferredEntityIds } from '../../../store/selectors/user';
import { getRelatedStateEntities } from '../../../store/selectors/followMode';

const TOURNAMENTS = 'tournaments';
const TEAMS = 'teams';
const NESTED_RELATION_ENTITIES = [TEAMS];
const STATE_RELATION_ENTITIES = [TOURNAMENTS];

const IDENTIFYING_PROPERTY_MAP = {
  [TEAMS]: 'name',
  [TOURNAMENTS]: 'title',
};

const getRelatedEntities = (relatedEntityName, stateRelatedEntities, nestedEntities) => {
  if (NESTED_RELATION_ENTITIES.includes(relatedEntityName)) {
    return nestedEntities;
  }

  if (STATE_RELATION_ENTITIES.includes(relatedEntityName)) {
    return stateRelatedEntities;
  }
  throw new Error('Related entity name not matching any relation objects', relatedEntityName);
};

const FollowMultiDialog = ({ entity, entityField, relatedEntityName, setIsDialogOpen }) => {
  const dispatch = useDispatch();
  const preferredEntityIds = useSelector(state => getPreferredEntityIds(state, { entityField }));

  // Related Entities Objects (ex: organization.teams)
  const nestedEntities = entity[relatedEntityName];
  const stateRelatedEntities = useSelector(state =>
    getRelatedStateEntities(state, { entityId: entity.id, relatedEntityName })
  );

  const relatedEntities = getRelatedEntities(relatedEntityName, stateRelatedEntities, nestedEntities);

  return (
    <MultipleEntitiesFollowDialog
      title={`Choose which ${
        entity[IDENTIFYING_PROPERTY_MAP[relatedEntityName]]
      } ${relatedEntityName} you want to follow`}
      relatedEntities={relatedEntities}
      subscribedEntityIds={preferredEntityIds}
      renderGameFirst={relatedEntityName === TOURNAMENTS} // Tournament titles are too long. Game names are rendered first.
      onCloseClick={() => setIsDialogOpen(false)}
      onClick={entityIds => {
        dispatch(savePreferredEntities(entityField, entityIds));
        setIsDialogOpen(false);
      }}
    />
  );
};

export default FollowMultiDialog;
