import createCachedSelector from 're-reselect';
import { startOfDay, format, addDays } from 'date-fns';

import { isDataLoaded, createArrEqSelector, getGames, getPreferredGameIdsSet, getSeries } from './gameData';
import { isEnded, filterSeriesByViewMode } from './gameDataUtils';
import { getLiveSeries, getFilteredLiveSeries } from './liveSeries';
import { getFilteredUpcomingSeries } from './upcomingSeries';
import { CALENDAR_FILTERS, CALENDAR_LINK_FILTERS } from '../reducers/uiStates';
import { isAdminUser } from './user';
import { isPartOfName } from './gameDataUtils';
// Home Screen calendar selectors

const seriesSort = (seriesA, seriesB) => {
  const durationGap = seriesA.start.seconds - seriesB.start.seconds;
  if (durationGap === 0 && seriesA.createdAt && seriesB.createdAt) {
    return seriesB.createdAt.seconds - seriesA.createdAt.seconds;
  }
  return durationGap;
};

export const getFilteredUpcomingSeriesByViewMode = createCachedSelector(
  isDataLoaded,
  getFilteredUpcomingSeries,
  (_, isMatchView) => isMatchView,
  (loaded, seriesArr, isMatchView) => {
    if (!loaded) return [];

    return filterSeriesByViewMode(seriesArr, isMatchView, 20);
  }
)({ keySelector: (_, isMatchView) => `${isMatchView}`, selectorCreator: createArrEqSelector });

export const getFilteredLiveSeriesByViewMode = createCachedSelector(
  isDataLoaded,
  getFilteredLiveSeries,
  (_, isMatchView) => isMatchView,
  (loaded, seriesArr, isMatchView) => {
    if (!loaded) return [];

    return filterSeriesByViewMode(seriesArr, isMatchView);
  }
)({ keySelector: (_, isMatchView) => `${isMatchView}`, selectorCreator: createArrEqSelector });

// Calendar Screen calendar selectors
export const getCalendarLiveSeriesByViewMode = createCachedSelector(
  isDataLoaded,
  getLiveSeries,
  ({ uiStates: { calendarSelectedGames } }) => calendarSelectedGames,
  ({ uiStates: { calendarFilter } }) => calendarFilter,
  getGames,
  getPreferredGameIdsSet,
  (_, options) => options,
  (loaded, seriesArr, selectedGames, calendarFilter, allGames, preferredGameIds, { isMatchView, searchInput }) => {
    if (!loaded) return [];

    const liveSeries = filterSeriesByViewMode(seriesArr, isMatchView);
    const filteredLiveSeries = liveSeries.filter(series => {
      if (selectedGames.length && !selectedGames.includes(series.game)) {
        return false;
      }

      if (calendarFilter === CALENDAR_FILTERS.FEATURED && series.tier > 2) {
        return false;
      }

      if (calendarFilter === CALENDAR_FILTERS.FAVORITES && !preferredGameIds.has(series.game)) {
        return false;
      }

      if (!!searchInput && !isSeriesMatchingSearch(series, searchInput, allGames)) {
        return false;
      }
      return true;
    });

    return filteredLiveSeries;
  }
)({ keySelector: (_, isMatchView) => `${isMatchView}`, selectorCreator: createArrEqSelector });

const getCalendarViewParams = ({ calendar: { calendarView, startDate, endDate } }) => ({
  calendarView,
  startDate,
  endDate,
});

export const getCalendarFilteredUpcomingSeries = createArrEqSelector(
  isDataLoaded,
  getSeries,
  getCalendarViewParams,
  ({ uiStates: { calendarSelectedGames } }) => calendarSelectedGames,
  ({ uiStates: { calendarFilter } }) => calendarFilter,
  getGames,
  getPreferredGameIdsSet,
  ({ notification: { seriesIds } }) => seriesIds,
  (_, options) => options,
  (loaded, allSeries, calendarState, selectedGames, calendarFilter, allGames, preferredGameIds, { searchInput }) => {
    if (!loaded) {
      return null;
    }

    let seriesArr = Object.values(allSeries)
      .filter(series => {
        if (!series.start || isEnded(series)) {
          return false;
        }
        if (selectedGames.length && !selectedGames.includes(series.game)) {
          return false;
        }
        if (calendarFilter === CALENDAR_FILTERS.FEATURED && series.tier > 2) {
          return false;
        }
        if (calendarFilter === CALENDAR_FILTERS.FAVORITES && !preferredGameIds.has(series.game)) {
          return false;
        }
        return true;
      })
      .sort(seriesSort);

    // Calendar defaults to by-event view unless is RemindMe view.
    const { calendarView, startDate, endDate } = calendarState;
    seriesArr = filterSeriesByViewMode(seriesArr);

    if (!!searchInput) {
      return seriesArr.filter(
        series =>
          isSeriesMatchingSearch(series, searchInput, allGames) && series.start.toDate() >= startOfDay(new Date())
      );
    }

    if (calendarView === CALENDAR_LINK_FILTERS.DAY) {
      // Show todays series in Today view
      return seriesArr.filter(series => {
        const seriesStartDate = series.start.toDate();

        return seriesStartDate.toDateString() === new Date().toDateString();
      });
    } else if (startDate.toDateString() === new Date().toDateString()) {
      // Show series between start/end dates for Week and All views
      // If date range starts Today, make sure we don't show any past series
      const nowSeconds = Math.floor(new Date().getTime() / 1000);
      return seriesArr.filter(series => series.start.seconds > nowSeconds && series.start.toDate() <= endDate);
    } else {
      return seriesArr.filter(series => series.start.toDate() >= startDate && series.start.toDate() <= endDate);
    }
  }
);

const isSeriesMatchingSearch = (series, input, allGames) => {
  const game = allGames[series.game];

  return (
    series.tournament.title.toLowerCase().includes(input.toLowerCase()) ||
    series.title.toLowerCase().includes(input.toLowerCase()) ||
    game.abbr.toLowerCase().includes(input.toLowerCase()) ||
    game.name.toLowerCase().includes(input.toLowerCase()) ||
    input
      ?.toLowerCase()
      .split(' ')
      .every(word => {
        return series.participants.find(participant => isPartOfName(participant, word));
      })
  );
};

export const TITLES = {
  LIVE: 'Live Now',
  UPCOMING: 'Upcoming',
  TODAY: 'Today',
  TOMORROW: 'Tomorrow',
};

// Calendar Table selector
export const getCalendarSections = createArrEqSelector(
  isDataLoaded,
  isAdminUser,
  getLiveSeries,
  (_, options) => options,
  ({ calendar: { streamLessSeries } }) => streamLessSeries, // Used as a trigger to rerender Table Screen.
  (loaded, [isAdminUser, _], liveSeries, { seriesRange, searchInput, calendarView }, streamLessSeries) => {
    if (!loaded || !seriesRange) {
      return [];
    }

    const filterdSeries = seriesRange
      .filter(series => {
        if (!series.start) {
          console.log(`series ${series.id} doesn't have \`start\`, skipping in CalendarTable`);
          return false;
        }

        // Filter out the series that are also in `props.liveSeries`, since they'll be displayed separately anyway.
        if (liveSeries && liveSeries.some(aLiveSeries => series.id === aLiveSeries.id)) {
          return false;
        }

        const nowSeconds = Math.floor(new Date().getTime() / 1000);
        if (series.start.seconds <= nowSeconds && !series.stream) {
          isAdminUser &&
            console.warn(
              `Skipping "Starting Now..." series ${series.id} in calendar. ${streamLessSeries} series have been removed.`
            );
          return false;
        }

        return true;
      })
      .sort(seriesSort);

    const groups = {};
    const addedTournaments = {};
    const isAllView = calendarView === CALENDAR_LINK_FILTERS.ALL && !searchInput;
    const isDayView = calendarView === CALENDAR_LINK_FILTERS.DAY && !searchInput;
    filterdSeries.forEach(series => {
      let groupIndex;
      const seriesStartDate = series.start.toDate();

      // Group series by month or by day.
      if (isAllView) {
        groupIndex = format(seriesStartDate, 'MMMM');

        const tournamentPerMonthKey = `${series.tournament.id}:${groupIndex}`;

        if (!groups[groupIndex]) {
          groups[groupIndex] = {
            date: groupIndex,
            series: [series],
            title: getSectionTitle(seriesStartDate, groupIndex, isDayView, isAllView),
            subtitle: format(seriesStartDate, 'yyyy'),
          };
          addedTournaments[tournamentPerMonthKey] = series;
        } else if (!addedTournaments[tournamentPerMonthKey]) {
          // Check if we already have a series with the same tournament id.
          groups[groupIndex].series.push(series);
          addedTournaments[tournamentPerMonthKey] = series;
        }
      } else {
        groupIndex = seriesStartDate.toDateString();

        if (!groups[groupIndex]) {
          groups[groupIndex] = {
            date: groupIndex,
            series: [series],
            title: getSectionTitle(seriesStartDate, groupIndex, isDayView, isAllView),
            subtitle: format(seriesStartDate, 'MMMM do'),
          };
        } else {
          groups[groupIndex].series.push(series);
        }
      }
    });

    return Object.values(groups);
  }
);

const getSectionTitle = (sectionDate, date, showUpcomingTitle, isMonthlySection) => {
  const today = new Date();
  const isToday = date === today.toDateString();
  const isTomorrow = date === addDays(today, 1).toDateString();

  if (isMonthlySection) {
    return date;
  }
  if (showUpcomingTitle) {
    return TITLES.UPCOMING;
  }
  if (isToday) {
    return TITLES.TODAY;
  }
  if (isTomorrow) {
    return TITLES.TOMORROW;
  }
  return format(sectionDate, 'EEEE');
};
