import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IoMdClose } from 'react-icons/io';
import { useSelector, useDispatch } from 'react-redux';

import { isSignedIn, getPreferredEntityIds } from '../store/selectors/user';
import { setFollowMode } from '../store/actions/uiStates';
import { isDataLoaded } from '../store/selectors/gameData';
import { usePrevious } from '../hooks';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.senary,
    padding: '20px 0px',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    background: theme.palette.background.quinary,
    color: theme.palette.text.primary,
    height: 60,
    borderRadius: 8,
    border: 'solid 1px #1E2424',
    padding: '0px 40px 0px 30px',
    fontSize: 19,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    bottom: 0,
    zIndex: '999999',
    margin: '24px 90px',

    [theme.breakpoints.down('sm')]: {
      height: 130,
      fontSize: 16,
      margin: '24px 20px',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 20,
    fontSize: 20,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      top: 5,
    },
  },
}));

const FollowModeSnackbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [shouldToggleFollowButtonOff, setShouldToggleFollowButtonOff] = useState(false);

  const isUserSignedIn = useSelector(isSignedIn);

  const isLoaded = useSelector(isDataLoaded);

  const favoritesGameIds = useSelector(state => getPreferredEntityIds(state, { entityField: 'gameIds' }));
  const favoritesTeamIds = useSelector(state => getPreferredEntityIds(state, { entityField: 'teamIds' }));
  const favoritesTournamentIds = useSelector(state => getPreferredEntityIds(state, { entityField: 'tournamentIds' }));
  const favoritesCompetitionIds = useSelector(state => getPreferredEntityIds(state, { entityField: 'competitionIds' }));

  const isFollowMode = useSelector(state => state.uiStates.isFollowMode);

  //This  will prevent to show the snackbar on the profile screen
  const currentPath = window.location.pathname;
  const isProfileScreen = currentPath.includes('profile');

  //Will get all previous value
  const prevFavoritesGameIds = usePrevious(favoritesGameIds);
  const prevFavoritesTeamIds = usePrevious(favoritesTeamIds);
  const prevFavoritesTournamentIds = usePrevious(favoritesTournamentIds);
  const prevFavoritesCompetitionIds = usePrevious(favoritesCompetitionIds);

  const isFollowingAnyEntity =
    favoritesGameIds?.length ||
    favoritesTeamIds?.length ||
    favoritesTournamentIds?.length ||
    favoritesCompetitionIds?.length;

  const shouldDisableFollowMode =
    isLoaded &&
    isUserSignedIn &&
    isFollowMode &&
    !isFollowingAnyEntity &&
    (prevFavoritesGameIds?.length !== favoritesGameIds?.length ||
      prevFavoritesTeamIds?.length !== favoritesTeamIds?.length ||
      prevFavoritesTournamentIds?.length !== favoritesTournamentIds?.length ||
      prevFavoritesCompetitionIds?.length !== favoritesCompetitionIds?.length);

  useEffect(() => {
    if (shouldDisableFollowMode) {
      setShouldToggleFollowButtonOff(true);
    } else if (isFollowingAnyEntity) {
      setShouldToggleFollowButtonOff(false);
    }
  }, [shouldDisableFollowMode, isFollowingAnyEntity]);

  //If the user remove the last entity this will turn of the button and show the snackbar.
  useEffect(() => {
    if (shouldToggleFollowButtonOff && !isProfileScreen) {
      setIsOpen(true);
      dispatch(setFollowMode(false));
      setShouldToggleFollowButtonOff(false);
    }
  }, [dispatch, shouldToggleFollowButtonOff, isProfileScreen]);

  return isOpen ? (
    <div className={classes.root}>
      <div className={classes.container}>
        <IoMdClose onClick={() => setIsOpen(false)} className={classes.closeButton} />
        Since you are no longer following anything, we've turned off Follow Mode. For the most personalized experience,
        be sure to follow your favorite games and teams.
      </div>
    </div>
  ) : null;
};

export default FollowModeSnackbar;
