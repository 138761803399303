import { db } from '../store/sagas/firebase';

let jukedEnabledFeatures;

// Does a one-time fetch to feature flags. We don't want to use a listener here because
// users shouldn't see a feature-switch during a single session.
// NOTE: should block site/process from loading until init is done.
export const initFeatureFlags = async () => {
  const doc = await db
    .collection('system')
    .doc('enabledFeatures')
    .get();

  jukedEnabledFeatures = doc && doc.data();
};

export const isFeatureEnabled = key => {
  if (jukedEnabledFeatures === null) {
    console.error('This env does not have firestore `system.enabledFeatures`, please add.');
    return false;
  }

  if (jukedEnabledFeatures === undefined) {
    console.error('Please make sure initFeatureFlag() finished before calling isFeatureEnabled().');
    return false;
  }

  if (jukedEnabledFeatures.hasOwnProperty(key)) {
    return jukedEnabledFeatures[key];
  } else {
    console.error(`Attempting to retrieve non-existent feature flag: ${key}`);
    return false;
  }
};
