import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { useFirestoreSubscribe, useFilterByParameter } from '../hooks';
import ArticleCard from '../components/ArticlesSection/ArticleCard';
import HorizontalArticleCard from '../components/ArticlesSection/HorizontalArticleCard';
import Filters from '../components/ArticlesSection/Filters/';
import ArticleGameTag from '../components/ArticlesSection/ArticleGameTag';
import NoContentMessage from '../components/common/NoContentMessage';
import ArticleLink from '../components/ArticlesSection/ArticleLink';
import WindowTitle from '../components/WindowTitle';
import { getFeaturedArticles, getSortedArticleList } from '../store/selectors/articles';
import { getPreferredGameIds } from '../store/selectors/user';
import { TrackDiv } from '../components/common';
import { getGames } from '../store/selectors/gameData';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    background: theme.palette.background.senary,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    minHeight: '100vh',
    paddingBottom: 10,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: 1280,
    padding: 15,
    boxSizing: 'border-box',
  },
  featuredNews: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  topNews: {
    width: '100%',
  },
  title: {
    fontSize: 20,
    lineHeight: '16px',
    fontWeight: 'bold',
    paddingTop: 40,
    paddingBottom: 25,
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    letterSpacing: 2,
    width: '100%',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  loading: {
    fontSize: 45,
    fontStyle: 'oblique',
    fontWeight: 980,
    color: theme.palette.text.primary,
  },
  showMoreButton: {
    height: 56,
    width: 183,
    fontSize: 16,
    color: 'white',
    lineHeight: '16px',
    fontWeight: 600,
    fontFamily: 'Source Sans Pro',
    cursor: 'pointer',
    borderRadius: 4,
    background: '#7E1351 !important',
    borderColor: 'transparent',
    margin: '10px 0px',
    padding: 'unset',
    letterSpacing: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '.disabled&': {
      opacity: 0.5,
      cursor: 'unset',
    },
  },
  articleLink: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
}));

const NewsScreen = () => {
  const classes = useStyles();
  // TODO: We should implement pagination here.
  const [articles, isLoading] = useFirestoreSubscribe('processed_articles', ['isPublished', '==', true]);
  const [selectedGames, setSelectedGames] = useFilterByParameter('games');
  const [isFavoritesSelected, setIsFavoritesSelected] = useState(false);
  const [visibleArticlesIndex, setVisibleArticlesIndex] = useState(14);
  const searchTerm = useSelector(state => state.uiStates.articleSearch);

  const filteringProps = { articles, selectedGames, isFavoritesSelected, searchTerm };
  const featuredArticles = useSelector(state => getFeaturedArticles(state, filteringProps));
  const articleList = useSelector(state => getSortedArticleList(state, filteringProps));
  const gameList = useSelector(state => getGames(state));
  const favoritesGames = useSelector(state => getPreferredGameIds(state));

  return (
    <div className={classes.root}>
      <WindowTitle
        title="Esports News - Articles, Highlights, & Top News"
        description="Visit Juked to discover breaking news from across every esport - LoL, Valorant, CS:GO, Dota 2, Overwatch, and more!"
      />
      <Filters
        selectedGames={selectedGames}
        setSelectedGames={setSelectedGames}
        isFavoritesSelected={isFavoritesSelected}
        setIsFavoritesSelected={setIsFavoritesSelected}
        search={searchTerm}
      />

      {isLoading && <h2 className={classes.loading}>LOADING...</h2>}

      {!isLoading && !articleList.length && !featuredArticles.length && (
        <NoContentMessage isFavoritesSelected={isFavoritesSelected} favoritesGames={favoritesGames} />
      )}

      {articles && Boolean(articles.length) && (
        <div className={classes.contentContainer}>
          {Boolean(featuredArticles.length) && (
            <>
              <span className={classes.title}>Featured News</span>
              <div className={classes.featuredNews}>
                {featuredArticles.map(article => (
                  <ArticleCard
                    key={article.id}
                    article={article}
                    gameTags={<ArticleGameTag article={article} games={gameList} />}
                    articleLink={
                      <ArticleLink article={article} className={classes.articleLink} source="article-card" />
                    }
                  />
                ))}
              </div>
            </>
          )}
          {Boolean(articleList.length) && (
            <>
              <span className={classes.title}>Top News</span>
              <div className={classes.topNews}>
                {articleList.slice(0, visibleArticlesIndex).map(article => (
                  <HorizontalArticleCard
                    key={article.id}
                    article={article}
                    gameTags={<ArticleGameTag article={article} games={gameList} />}
                    articleLink={
                      <ArticleLink article={article} className={classes.articleLink} source="horizontal-article-card" />
                    }
                  />
                ))}
              </div>
            </>
          )}
        </div>
      )}
      {articleList && articleList.length > 7 && (
        <TrackDiv
          className={classNames({
            [classes.showMoreButton]: true,
            disabled: visibleArticlesIndex >= articleList.length,
          })}
          onClick={() => setVisibleArticlesIndex(visibleArticlesIndex + 7)}
          track={{
            event: 'Newsfeed Load More Clicked',
            eventProperties: { articlesLoaded: visibleArticlesIndex + 7 },
            source: 'news-page',
          }}
        >
          See More News
        </TrackDiv>
      )}
    </div>
  );
};

export default NewsScreen;
