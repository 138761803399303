import React from 'react';
import styled from 'styled-components';

import { TrackLink, Track } from '../Track';

const JUKED_LOGO = '/images/Juked_logo.png';

export interface RoundBadgeProps {
  animated?: boolean;
  disabled?: boolean;
  className?: string;
  elevation?: number;
  imgSrc?: string;
  size?: string;
  to: string;
  track?: Track;
}

const StyledBadge = styled(TrackLink)<RoundBadgeProps>`
  background-color: transparent;
  background-image: ${({ imgSrc }): string => `url(${imgSrc})`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: ${({ size }): string | undefined => size};
  width: ${({ size }): string | undefined => size};
  transition: 0.2s ease-out;
  &.icon {
    border-radius: ${({ size }): string | undefined => size};
  }
  &:hover {
    box-shadow: ${({ animated, elevation }): string =>
      animated && elevation ? `0px 2px ${elevation * 4}px 0 rgba(0,0,0,0.5)` : 'none'};
    transform: ${({ animated }): string => (animated ? 'scale(1.05)' : 'none')};
  }
`;

const Badge = ({ animated, className, disabled, elevation, imgSrc, size, to, track }: RoundBadgeProps): JSX.Element => (
  <StyledBadge
    animated={animated}
    className={className}
    disabled={disabled}
    elevation={elevation}
    imgSrc={imgSrc || JUKED_LOGO}
    size={size}
    to={to}
    track={track}
  />
);

Badge.defaultProps = {
  animated: false,
  className: null,
  disabled: false,
  elevation: 1,
  imgSrc: JUKED_LOGO,
  size: '32px',
  track: null,
};

export default Badge;
