import { isSignedIn } from './user';
import { ROTATING_DIALOG_VIEWS } from '../reducers/dialogs';
import getSecondsSince from '../../utils/getSecondsSince';
export const DAY_IN_SECONDS = 60 * 60 * 24;

const getSignupPromptedAt = ({ dialogs: { signupPromptedAt } }) => signupPromptedAt;
export const getLoginDialogView = ({ dialogs: { view } }) => view;
export const getOnLoginSuccess = ({ dialogs: { onLoginSuccess } }) => onLoginSuccess;
export const isUserDialogOpen = ({ dialogs: { isUserDialogOpen } }) => isUserDialogOpen;

export const getNextRotatingDialog = ({ dialogs: { rotatingDialogsOptOutState } }) =>
  ROTATING_DIALOG_VIEWS.find(dialogView => !rotatingDialogsOptOutState[dialogView]);

export const shouldRepeatRotatingDialog = ({ dialogs: { shouldRepeatRotatingDialog } }) => shouldRepeatRotatingDialog;

export const shouldShowSignupPrompt = state => {
  const signupPromptedAt = getSignupPromptedAt(state);

  // Check if we still have unshown rotating dialogs.
  const unshownDialog = getNextRotatingDialog(state);

  return !!(
    !isUserDialogOpen(state) &&
    (!signupPromptedAt || getSecondsSince(signupPromptedAt) > DAY_IN_SECONDS) &&
    (!isSignedIn(state) || !!unshownDialog)
  );
};
