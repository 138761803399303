import React from 'react';
import styled from 'styled-components';
import { Box } from '@rebass/grid';
import { BoxProps } from 'rebass__grid';

import Container from './Container';

type ScreenContainerProps = BoxProps & {
  children: React.ReactNode;
};

const Screen = styled(Box)`
  background-color: ${(props): string => props.theme.palette.background.secondary};
  min-height: 100vh;
`;

const ScreenContainer = ({ children, p, px, py }: ScreenContainerProps): JSX.Element => (
  <Screen p={p} px={px} py={py}>
    <Container>{children}</Container>
  </Screen>
);

export default ScreenContainer;
