import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.tertiary,
    borderRadius: 4,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 816,
    padding: '48px 88px 76px 88px',
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    root: {
      padding: '48px 48px 76px 48px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      padding: '48px 24px 76px 24px',
    },
  },
}));

export default ({ children, className }) => {
  const classes = useStyles();
  return <div className={`${classes.root} ${className}`}>{children}</div>;
};
