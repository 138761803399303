import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { format } from 'date-fns';

import CollapsibleSection from './CollapsibleSection';
import LiveItem from './CalendarItems/LiveItems';
import UpcomingItem from './CalendarItems/UpcomingItems';
import AllViewUpcomingItem from './CalendarItems/AllViewUpcomingItems';
import PastItem from './PastItem';

import { TITLES } from '../../store/selectors/calendar';

export const LIVE = 'LIVE';

const styles = theme => ({
  section: {
    marginTop: 5,
  },
  scheduleGroup: {
    position: 'relative',
  },
  nothingLiveMsg: {
    color: theme.palette.text.primary,
    textAlign: 'center',
  },
});

class DateSection extends Component {
  render() {
    const { classes, contentClassName, sectionSeries, date, title, sectionDate, showWeek } = this.props;

    if (!sectionSeries?.length) {
      return null;
    }

    const isLive = date === LIVE;
    const shouldShowDate = !Object.values(TITLES).includes(title) || showWeek;
    const isPastDate = new Date(new Date().toDateString()) > new Date(date);

    return (
      <CollapsibleSection
        title={isLive ? TITLES.LIVE : title}
        date={(shouldShowDate && sectionDate) || (isLive && showWeek && format(new Date(), 'MMMM do'))}
        className={classes.section}
        contentClassName={contentClassName}
        itemsCount={sectionSeries.length}
        collapseByDefault={isPastDate}
      >
        <div className={classes.scheduleGroup}>{this.renderItems(isLive, isPastDate)}</div>
      </CollapsibleSection>
    );
  }

  renderItems = (isLive, isPastDate) => {
    const { classes, sectionSeries, isMatchView, isMonthlySection } = this.props;
    if (!isLive) {
      if (isMonthlySection) {
        return sectionSeries.map(series => <AllViewUpcomingItem key={series.id} series={series} />);
      } else if (isPastDate) {
        return sectionSeries.map(series => <PastItem key={series.id} series={series} isMatchView={isMatchView} />);
      } else {
        // TODO: for matches that are from TODAY but are already over, we should also render PastItem instead.
        return sectionSeries.map(series => <UpcomingItem key={series.id} series={series} isMatchView={isMatchView} />);
      }
    } else if (sectionSeries.length) {
      return sectionSeries.map(series => <LiveItem key={series.id} series={series} isMatchView={isMatchView} />);
    } else {
      return <h3 className={classes.nothingLiveMsg}>Nothing is live right now</h3>;
    }
  };
}

export default withStyles(styles)(DateSection);
