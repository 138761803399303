import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import NotificationButton from './NotificationButton/index';
import { H1, FollowButton } from '../common';
import { trackEvent } from '../../store/actions/uiStates';

const containerXPadding = '24px';

const useStyles = makeStyles(theme => ({
  headerBackground: {
    backgroundColor: theme.palette.background.duoDenary,
  },
  root: {
    backgroundColor: theme.darkSwampBlack,
    minHeight: 160,
    width: '100%',
    position: 'relative',
  },
  gradient: {
    height: '100%',
    width: '100%',
    background: 'linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgb(10, 11, 11) 100%)',
    position: 'absolute',
    zIndex: 1,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 1232,
    boxSizing: 'border-box',
    position: 'relative',
    margin: 'auto',
    height: 56,
    [theme.breakpoints.down('md')]: {
      padding: `0 ${containerXPadding}`,
    },
  },
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '100%',
    margin: '0 auto',
    maxWidth: 1232,
    padding: '24px 0',
    '& > *': {
      zIndex: 100, // To render all items on top of the banner.
    },
    [theme.breakpoints.down('md')]: {
      padding: `24px ${containerXPadding}`,
    },
  },
  icon: {
    borderRadius: 8,
    height: 120,
    marginRight: 16,
    width: 120,
  },
  bannerWrapper: {
    top: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'right',
      // Doesn't show the banner if the img tag doesn't have a source.
      '&:not([src])': {
        display: 'none',
      },
    },
    '& .desktop': {
      display: 'flex',
    },
    '& .tablet': {
      display: 'none',
    },
    '& .mobile': {
      display: 'none',
    },
  },
  nameColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  h1: {
    fontSize: ({ large }) => `${large ? '44' : '32'}px !important`,
    lineHeight: ({ large }) => (large ? '50px' : '40px'),
    color: 'white',
    margin: '0 0 8px 0',
    maxWidth: 550,
  },
  headerRow: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tabsContainer: {
    marginTop: 8,
    display: 'flex',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
  },
  followButtonRow: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipContainer: {
    position: 'relative',
  },
  tooltip: {
    top: 26,
    left: 4,
    width: 112,
    color: 'white',
    fontSize: 13,
    lineHeight: '16px',
    padding: 8,
    background: ' #161717',
    boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.2)',
    '$tooltipContainer:hover &': {
      visibility: 'visible',
      opacity: 1,
    },
  },

  link: {
    marginLeft: 'unset',
    color: theme.darkGrey,
    textDecoration: 'underline',
  },
  [theme.breakpoints.down('sm')]: {
    headerContainer: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '12px !important',
      height: 'unset',
    },
    headerRow: {
      alignItems: 'center',
      flexDirection: 'column',
      fontSize: 20,
    },
    h1: {
      fontSize: ({ large }) => `${large ? '40' : '24'}px !important`,
      lineHeight: ({ large }) => (large ? '44px' : '30px'),
      maxWidth: 300,
    },
    bannerWrapper: {
      '& .desktop': {
        display: 'none',
      },
      '& .tablet': {
        display: 'flex',
      },
      '& .mobile': {
        display: 'none',
      },
    },
    tabsContainer: {
      order: 3,
      marginTop: 12,
      marginBottom: -12,
    },
    container: {
      padding: 15,
    },
    icon: {
      display: 'none',
    },
    buttonsContainer: {
      marginLeft: 0,
      flexWrap: 'wrap',
    },
  },
  [theme.breakpoints.down('xs')]: {
    bannerWrapper: {
      '& .desktop': {
        display: 'none',
      },
      '& .tablet': {
        display: 'none',
      },
      '& .mobile': {
        display: 'flex',
      },
    },
  },
}));

const BannerFollowHeader = ({
  large,
  ImageComponent,
  Breadcrumb,
  Tooltip,
  HeaderIconButtons,
  ShareComponent,
  GameTags,
  Prizepool,
  title,
  children,
  entity,
  entityField,
  banners,
  withGradient,
  followButtonProps = {},
}) => {
  const classes = useStyles({ large });
  const dispatch = useDispatch();

  const { desktop, mobile, tablet } = banners || {};
  const { images, name, title: entityTitle } = entity;

  useEffect(() => {
    const trackBannerViewed = async () =>
      dispatch(trackEvent('Banner Viewed', { entityId: entity.id, banner: desktop }));
    trackBannerViewed();
  }, [dispatch, desktop, entity.id]);

  return (
    <div className={classes.headerBackground}>
      <div className={classes.root}>
        {withGradient && <div className={classes.gradient} />}
        <div className={classes.container}>
          <div className={classes.icon}>{ImageComponent}</div>

          <div className={classes.nameColumn}>
            {Breadcrumb}
            <H1 className={classes.h1}>{title}</H1>
            <div className={classes.followButtonRow}>
              <div className={classes.tooltipContainer}>
                <FollowButton
                  authContext={{ images, name, title: entityTitle }}
                  entity={entity}
                  {...followButtonProps}
                  entityField={entityField}
                />
                {Boolean(Tooltip) && <Tooltip className={classes.tooltip} />}
              </div>
              <NotificationButton
                entity={entity}
                entityField={entityField}
                relatedEntityName={followButtonProps.relatedEntityName}
                multiple={followButtonProps.multiple}
              />
              {GameTags}
              {Prizepool}
            </div>
          </div>
        </div>
        <div className={classes.bannerWrapper}>
          <img src={desktop} alt="" className="desktop" />
          <img src={tablet || desktop} alt="" className="tablet" />
          <img src={mobile || desktop} alt="" className="mobile" />
        </div>
      </div>

      <div className={classes.headerContainer}>
        <div className={classes.tabsContainer}>{children}</div>
        <div className={classes.headerRow}>
          <div className={classes.buttonsContainer}>
            {(Boolean(HeaderIconButtons) && HeaderIconButtons) || null}
            {ShareComponent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerFollowHeader;
