import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  underline: {
    content: '" "',
    position: 'absolute',
    transition: 'all 200ms ease-in-out',
    background: theme.lightTeal,
    width: 54,
    height: 3,
    bottom: 0,
    display: 'none',

    '.showWeek &': {
      display: 'block',
      left: 90,
    },
    '.showDay &': {
      display: 'block',
      left: 0,
    },
    '.showAll &': {
      display: 'block',
      left: 178,
      width: 30,
    },
  },
  [theme.breakpoints.down('sm')]: {
    underline: {
      '.showWeek &': {
        left: ({ left }) => left,
        width: 50,
      },
      '.showAll &': {
        left: ({ left }) => left,
        width: 23,
      },
    },
  },

  // iPhone SE classes
  '@media (max-width: 350px)': {
    underline: {
      width: 42,
      '.showAll &': {
        width: 20,
      },
    },
  },
}));

const Underline = ({ left }) => {
  const classes = useStyles({ left });

  return <div className={classes.underline} />;
};

export default Underline;
