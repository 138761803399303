const totalViewerCount = streams => {
  if (!Boolean(streams)) {
    return 0;
  }
  return streams.reduce((accu, stream) => accu + (stream.viewerCount || 0), 0);
};

export default streams => {
  return totalViewerCount(streams)?.toLocaleString(undefined, { maximumFractionDigits: 0 });
};
