import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { compose } from 'recompose';
import classNames from 'classnames';
import { Swipeable } from 'react-swipeable';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';

import { TrackDiv } from '../common';
import PageIndicator from '../PageIndicator';
import { useGetTrackClick } from '../Wrappers/withTrackClick';
import { isDataLoaded } from '../../store/selectors/gameData';
import { getUnmutedSeriesId } from '../../store/selectors/video';
import StreamCard from './StreamCard';
import TopUpcomingEvent from './TopUpcomingEvent';
import VideoCardStyles from './VideoCardStyles';
import { ShimmerSpan, ShimmerDiv } from '../common/Shimmer';
import MultiViewButtonsRow from './MultiViewButtonsRow';
import { isFeatureEnabled } from '../../utils/featureFlags';
import { ALPHA_SIGNUP_ID } from './SingleViewSwatch/SingleViewCarousel';

const SOURCE = 'video-swatch';

const arrowClasses = {
  zIndex: 1,
  height: 48,
  width: 48,
  background: 'black',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const arrowClassesMobile = {
  marginLeft: 5,
  marginRight: 5,
};

const styles = theme => ({
  root: {
    width: '100%',
    paddingTop: 32,
    paddingBottom: 36,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.background.primary,
    '&.isHomePageView': {
      paddingTop: 8,
    },
  },
  pageContainer: {
    maxWidth: 1232,
    minWidth: 800,
    width: '100%',
  },
  container: {
    display: 'none',
    margin: 'auto',
    gridColumnGap: '10px',
    gridRowGap: '10px',
    '&.isCurrentPage': {
      display: 'grid',
    },
  },
  containerOf1Items: {
    width: 800,
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
  },
  containerOf2Items: {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr',
  },
  containerOf3Items: {
    gridTemplateColumns: '5fr 2fr',
    gridTemplateRows: '1fr 1fr',
  },
  containerOf4Items: {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
  },
  containerOf5Items: {
    gridTemplateColumns: '6fr 6fr 9fr',
  },
  arrow: {
    visibility: 'hidden', // use visibility instead of display so the positions don't shift.
    width: 0,
    height: 0,
    cursor: 'pointer',
    '&.visible': {
      visibility: 'visible',
    },
  },
  arrowIcon: {
    lineHeight: '48px',
    fontSize: 32,
    fill: theme.palette.text.primary,
  },
  arrowNext: {
    ...arrowClasses,
    marginLeft: 24,
    background: theme.palette.background.arrowButton,
  },
  arrowPrevious: {
    ...arrowClasses,
    marginRight: 24,
    background: theme.palette.background.arrowButton,
  },
  placeholderCard: {
    ...VideoCardStyles.placeholderCard,
    background: theme.palette.shimmer.primary,
  },
  upcomingContainer: {
    width: '80%',
  },
  upcomingCard: {
    alignItems: 'center !important',
    display: 'flex !important',
    flexDirection: 'column !important',
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      minHeight: 30,
      marginBottom: 'unset',
      paddingBottom: 22,
    },
    pageContainer: {
      minWidth: 200,
    },
    containerActive: {
      display: 'flex',
      flexDirection: 'column',
    },
    placeholderCard: {
      width: 300,
      height: 214,
      '& > span': {
        '&:nth-of-type(1)': {
          width: '70%',
        },
      },
    },
    arrow: {
      '&.hide': {
        display: 'none',
      },
    },
    arrowNext: {
      ...arrowClassesMobile,
    },
    arrowPrevious: {
      ...arrowClassesMobile,
      '&:before': {
        height: 60,
        paddingTop: 3,
      },
    },
  },
  multiContainer: {
    padding: '8px 24px 0',
    background: theme.palette.background.primary,
  },
  '@media (max-width: 320px)': {
    root: {
      marginBottom: 'unset',
      paddingBottom: 18,
    },
  },
});

const SWIPE_CONFIG = {
  delta: 10, // min distance(px) before a swipe starts
  preventDefaultTouchmoveEvent: false, // preventDefault on touchmove, *See Details*
  trackTouch: true, // track touch input
  trackMouse: false, // track mouse input
  rotationAngle: 0, // set a rotation angle
};

const VideoSwatch = props => {
  const { source = SOURCE } = props;
  const [page, setPage] = useState(1);
  const [mobileSize, setMobileSize] = useState(null);

  const renderLoading = () => {
    const { classes } = props;
    return (
      <div className={classes.placeholderCard}>
        <ShimmerDiv />
        <ShimmerSpan />
        <ShimmerSpan />
        <ShimmerSpan />
        <ShimmerSpan />
        <ShimmerSpan />
      </div>
    );
  };

  const renderUpcoming = () => {
    const { classes, source } = props;
    return (
      <div className={classes.upcomingContainer}>
        <TopUpcomingEvent className={classes.upcomingCard} source={source} />
      </div>
    );
  };

  const renderMobile = pageCount => {
    const { liveSeriesArr, classes, unmutedStreamId, source = SOURCE } = props;
    return (
      <div className={classes.pageContainer}>
        {[...Array(pageCount).keys()].map(i => {
          const series = liveSeriesArr[i];
          const isCurrentPage = i + 1 === page;
          // Don't try to render alpha signup card because it's missing data for the card
          if ((!isCurrentPage && unmutedStreamId !== series.stream) || series.id === ALPHA_SIGNUP_ID) {
            return null;
          }

          return (
            <div
              key={i}
              className={classNames({
                [classes.container]: true,
                isCurrentPage,
              })}
            >
              <StreamCard series={series} />
            </div>
          );
        })}
        {/* This will show the number of pages in dots */}
        <PageIndicator
          currentPage={page}
          pageCount={pageCount}
          clickedDotPage={index => setPage(index + 1)}
          event="Swatch Dot Clicked"
          source={source}
        />
      </div>
    );
  };

  const renderDesktop = (pageCount, seriesCount) => {
    const { liveSeriesArr, classes, unmutedSeriesId, source = SOURCE } = props;
    return (
      <div className={classes.pageContainer}>
        {[...Array(pageCount).keys()].map(i => {
          const isCurrentPage = i + 1 === page;
          const seriesSliceStart = i === 0 ? 0 : (i - 1) * 4 + 5;
          const seriesSliceEnd = i === 0 ? 5 : i * 4 + 5;
          const pageSeries = liveSeriesArr.slice(
            seriesSliceStart,
            seriesSliceEnd <= seriesCount ? seriesSliceEnd : seriesCount
          );
          return (
            <div
              key={i}
              className={classNames({
                [classes.container]: true,
                [classes[`containerOf${pageSeries.length}Items`]]: true,
                isCurrentPage,
              })}
            >
              {pageSeries.map((series, index) => {
                // Don't try to render alpha signup card because it's missing data for the card
                if ((!isCurrentPage && unmutedSeriesId !== series.id) || series.id === ALPHA_SIGNUP_ID) {
                  // The unmutedSeriesId !== series.id check Allow us to keep hearing a series after changing pages.
                  return null;
                }
                return (
                  <StreamCard
                    key={index}
                    index={index + 1}
                    pageItems={pageSeries.length}
                    series={series}
                    size={getCardSize(index, pageSeries)}
                  />
                );
              })}
            </div>
          );
        })}
        {/* This will show the number of pages in dots */}
        <PageIndicator
          currentPage={page}
          pageCount={pageCount}
          clickedDotPage={index => setPage(index + 1)}
          event="Swatch Dot Clicked"
          source={source}
        />
      </div>
    );
  };

  const nextPage = (pageCount, getTrackClick) => {
    if (page !== pageCount) {
      getTrackClick &&
        getTrackClick({
          track: { event: 'Video Swatch Page Changed', type: 'swipe', source, target: 'next-page' },
        })();

      setPage(page + 1);
    }
  };

  const previousPage = getTrackClick => {
    if (page !== 1) {
      getTrackClick &&
        getTrackClick({
          track: { event: 'Video Swatch Page Changed', type: 'swipe', source, target: 'previous-page' },
        })();
      setPage(page - 1);
    }
  };

  //Verify that the size is equal to media query
  const checkMobileSize = () => {
    setMobileSize(window.matchMedia('(max-width: 960px)').matches);
    setPage(1);
  };

  const { liveSeriesArr, classes, isDataLoaded, isHomePageView } = props;
  const getTrackClick = useGetTrackClick();

  useEffect(() => {
    checkMobileSize();
    window.addEventListener('resize', checkMobileSize);
    return () => {
      // This will execute on componentWillUnmount
      window.removeEventListener('resize', checkMobileSize);
    };
  }, []);

  //Will determine the size of the streamcard
  const getCardSize = (index, pageSeries) => {
    if (([3, 4].includes(index) && pageSeries.length === 5) || ([1, 2].includes(index) && pageSeries.length === 3)) {
      return 'small';
    }
    if ([1, 2].includes(index) && pageSeries.length === 5) {
      return 'medium';
    }
    return '';
  };

  const seriesCount = liveSeriesArr.length;
  let pageCount = 1;
  let pageContent;

  //Check if is mobile size
  if (!mobileSize) {
    pageCount = seriesCount === 1 ? 1 : Math.ceil((seriesCount - 1) / 4);
  } else {
    pageCount = seriesCount;
  }

  if (!isDataLoaded) {
    pageContent = renderLoading();
  } else if (isDataLoaded && !seriesCount) {
    pageContent = renderUpcoming();
  } else if (mobileSize) {
    pageContent = renderMobile(pageCount);
  } else if (!mobileSize) {
    pageContent = renderDesktop(pageCount, seriesCount);
  }

  return (
    <Swipeable
      onSwipedLeft={() => nextPage(pageCount, getTrackClick)}
      onSwipedRight={() => previousPage(getTrackClick)}
      {...SWIPE_CONFIG}
    >
      {/* Setting Swipeable here to observe swipes through the hole section. Works on mobile. */}
      <div className={classes.multiContainer}>
        {isFeatureEnabled('multiViewButton') && !mobileSize && (
          <MultiViewButtonsRow isHomePageView={isHomePageView} hasFilters={props.hasFilters} />
        )}
        <div className={classNames({ [classes.root]: true, isHomePageView })}>
          <TrackDiv
            className={classNames({
              [classes.arrowPrevious]: true,
              [classes.arrow]: true,
              visible: !!pageCount && page !== 1,
              hide: !seriesCount,
            })}
            onClick={() => previousPage()}
            track={{
              event: 'Video Swatch Page Changed',
              type: 'click',
              source,
              target: 'previous-page',
            }}
          >
            <MdKeyboardArrowLeft className={classes.arrowIcon} />
          </TrackDiv>
          {pageContent}
          <TrackDiv
            className={classNames({
              [classes.arrowNext]: true,
              [classes.arrow]: true,
              visible: !!pageCount && page !== pageCount,
              hide: !seriesCount,
            })}
            onClick={() => nextPage(pageCount)}
            track={{
              event: 'Video Swatch Page Changed',
              type: 'click',
              source,
              target: 'next-page',
            }}
          >
            <MdKeyboardArrowRight className={classes.arrowIcon} />
          </TrackDiv>
        </div>
      </div>
    </Swipeable>
  );
};

export default compose(
  withStyles(styles),
  connect(
    state => ({
      isDataLoaded: isDataLoaded(state),
      unmutedSeriesId: getUnmutedSeriesId(state),
    }),
    {}
  )
)(VideoSwatch);
