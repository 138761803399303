import React from 'react';
import styled from 'styled-components';

import { SpanText } from '../core';

type TickerSeriesProps = {
  series: { title?: string };
};

const Container = styled.div`
  align-items: center;
  background-color: ${(props): string => props.theme.palette.background.tertiary};
  border-left: 1px solid ${(props): string => props.theme.palette.border.primary};
  display: flex;
  justify-content: center;
  min-width: 88px; /* 104 - 2(8) */
  padding: 0px 8px;
  :last-of-type {
    border-right: 1px solid ${(props): string => props.theme.palette.border.primary};
  }
`;

const TickerGame = ({ series }: TickerSeriesProps): JSX.Element => (
  <Container>
    <SpanText variant="tinyBold">{series.title}</SpanText>
  </Container>
);

export default TickerGame;
