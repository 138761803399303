import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';

import ArticleLink from '../ArticlesSection/ArticleLink';
import { BodyText, GameBadges } from '../common';

const ARTICLE_PADDING = 16;

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  articleLink: {
    borderLeft: ({ isActive }) => (isActive ? `4px solid ${theme.palette.primary.active}` : '4px solid transparent'),
    borderRadius: 4,
    backgroundColor: theme.palette.background.tertiary,
    display: 'flex',
    minHeight: 60,
    padding: `12px 24px ${ARTICLE_PADDING}px ${ARTICLE_PADDING}px`,
    textDecoration: 'initial',
  },
  badges: {
    bottom: 8, // Margin bottom.
    left: 4, // Border left.
    padding: `0 0 ${ARTICLE_PADDING}px ${ARTICLE_PADDING}px`,
    position: 'absolute',
  },
  badge: {
    height: 16,
    width: 16,
  },
  column: {
    display: 'flex',
    flex: '1 0',
    flexDirection: 'column',
  },
  subtext: {
    boxOrient: 'vertical',
    display: '-webkit-box',
    lineClamp: 2,
    marginTop: 4,
    overflow: 'hidden',
  },
  bottomRow: {
    height: 16,
    padding: '6px 0 0 0',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  date: {
    color: theme.palette.text.secondary,
    fontSize: 13,
  },
}));

export default ({ article, className, isActive }) => {
  const classes = useStyles({ isActive });
  const { attributes } = article;
  const { title, excerpt, redirectUrlMeta, date } = attributes || {};

  return (
    <div className={classes.root}>
      <ArticleLink className={`${classes.articleLink} ${className}`} article={article} source="article-page">
        <div className={classes.column}>
          <BodyText variant="bold">{title}</BodyText>
          <BodyText className={classes.subtext} variant="smallLight">
            {excerpt || (redirectUrlMeta && redirectUrlMeta.description)}
          </BodyText>
          <div className={classes.bottomRow}>
            <div className={classes.date}>{format(date.toDate(), 'PP')}</div>
          </div>
        </div>
      </ArticleLink>
      <GameBadges
        animated
        className={classes.badges}
        badgeClassName={classes.badge}
        gameIds={article.gameIds}
        spacing="6px"
      />
    </div>
  );
};
