import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  iconLink: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 15,
    lineHeight: '20px',
    textDecoration: 'none',
  },
  icon: {
    fontSize: 20,
    padding: '0 2px',
    marginLeft: 2,
  },
});

const IconLink = ({ classes, className, Icon, label, url }) => (
  <a className={`${classes.iconLink} ${className}`} href={url} rel="noopener noreferrer" target="_blank">
    {label}
    <Icon className={classes.icon} />
  </a>
);

IconLink.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  label: PropTypes.string,
  url: PropTypes.string,
};

export default withStyles(styles)(IconLink);
