import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { qsParse } from '../common/QueryLink';
import { subscribeToQuery, unsubscribeToQuery } from '../../store/actions/gameData';
import { getFutureSeriesByCompetitionId } from '../../store/selectors/competitions';
import { getUpcomingSeriesByTournamentId } from '../../store/selectors/upcomingSeries';
import { getLiveSeriesByTournamentId, getLiveSeriesByCompetitionId } from '../../store/selectors/liveSeries';
import { setCalendarFilter, setCalendarGameIdToUrl } from '../../store/actions/uiStates';
import { changeCalendarView } from '../../store/actions/calendar';

export const withCalendarUrlState = WrappedComponent =>
  withRouter(props => {
    const { search } = qsParse(props.location.search);
    return <WrappedComponent searchInput={search} {...props} />;
  });

export const withCalendarUrlChange = WrappedComponent =>
  compose(
    withRouter,
    connect(
      state => ({
        calendarView: state.calendar.calendarView,
        calendarFilter: state.uiStates.calendarFilter,
        selectedGames: state.uiStates.calendarSelectedGames,
      }),
      { setCalendarFilter, changeCalendarView, setCalendarGameIdToUrl }
    )
  )(
    class extends Component {
      constructor(props) {
        super(props);
        const {
          selectedGames,
          calendarFilter,
          calendarView,
          setCalendarFilter,
          changeCalendarView,
          setCalendarGameIdToUrl,
        } = props;
        const { games, sort, tab } = qsParse(this.props.location.search);

        //Update the state with the sort that is on the URL
        if (calendarFilter !== sort && sort) {
          setCalendarFilter(sort);
        }

        if (games && games.length) {
          //Compare the arrays game ids
          const shouldUpdateStore = Boolean(
            games.find(id => {
              return !selectedGames.includes(id);
            })
          );

          //Update the selectedGames state with the games that come from the URL
          if (shouldUpdateStore) {
            setCalendarGameIdToUrl(games);
          }
        }

        //Update the state with the calendar view that is on the URL
        if (calendarView !== tab && tab) {
          changeCalendarView(tab);
        }
      }

      render() {
        const { search } = qsParse(this.props.location.search);
        return <WrappedComponent searchInput={search} {...this.props} />;
      }
    }
  );

export const withTournamentSeries = WrappedComponent =>
  connect(
    (state, ownProps) => ({
      liveSeries: getLiveSeriesByTournamentId(state, ownProps.tournamentId),
      upcomingSeries: getUpcomingSeriesByTournamentId(state, ownProps.tournamentId),
    }),
    { subscribeToQuery, unsubscribeToQuery }
  )(
    class extends Component {
      getQueryParams(tournamentId) {
        return ['allSeries', ['tournament.id', '==', tournamentId || this.props.tournamentId]];
      }

      componentDidMount() {
        this.props.subscribeToQuery(...this.getQueryParams());
      }

      componentWillUnmount() {
        this.props.unsubscribeToQuery(...this.getQueryParams());
      }

      componentDidUpdate(prevProps) {
        const { tournamentId, subscribeToQuery, unsubscribeToQuery } = this.props;
        const { tournamentId: prevTournId } = prevProps;

        if (prevTournId !== tournamentId) {
          unsubscribeToQuery(...this.getQueryParams(prevTournId));
          subscribeToQuery(...this.getQueryParams());
        }
      }

      render() {
        const { subscribeToQuery, unsubscribeToQuery, upcomingSeries, ...rest } = this.props;
        return <WrappedComponent upcomingSeries={upcomingSeries} {...rest} />;
      }
    }
  );

export const withCompetitionSeries = WrappedComponent =>
  connect(
    (state, ownProps) => ({
      liveSeries: getLiveSeriesByCompetitionId(state, ownProps.competitionId),
      upcomingSeries: getFutureSeriesByCompetitionId(state, ownProps.competitionId),
    }),
    { subscribeToQuery, unsubscribeToQuery }
  )(
    class extends Component {
      getQueryParams(competitionId) {
        return ['allSeries', ['tournament.competitionId', '==', competitionId || this.props.competitionId]];
      }

      componentDidMount() {
        this.props.subscribeToQuery(...this.getQueryParams());
      }

      componentWillUnmount() {
        this.props.unsubscribeToQuery(...this.getQueryParams());
      }

      componentDidUpdate(prevProps) {
        const { competitionId, subscribeToQuery, unsubscribeToQuery } = this.props;
        const { competitionId: prevCompetitionId } = prevProps;

        if (prevCompetitionId !== competitionId) {
          unsubscribeToQuery(...this.getQueryParams(prevCompetitionId));
          subscribeToQuery(...this.getQueryParams());
        }
      }

      render() {
        const { subscribeToQuery, unsubscribeToQuery, upcomingSeries, ...rest } = this.props;
        return <WrappedComponent upcomingSeries={upcomingSeries} {...rest} />;
      }
    }
  );
