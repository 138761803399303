import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { darkThemeV2, lightThemeV2 } from '../../theme';
import { RootState } from '../../store/reducers';

const withThemeProvider = <P,>(WrappedComponent: React.ComponentType<P>): ((props: P) => JSX.Element) => {
  const ThemedComponent = (props: P): JSX.Element => {
    const isDarkMode = useSelector((state: RootState) => state.uiStates.isDarkMode);
    return (
      <ThemeProvider theme={isDarkMode ? darkThemeV2 : lightThemeV2}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <WrappedComponent {...props} />
      </ThemeProvider>
    );
  };
  return ThemedComponent;
};

export default withThemeProvider;
