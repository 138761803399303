import classnames from 'classnames';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { MdEmail } from 'react-icons/md';

import { H1, IconHeader } from '../common';
import UserPreferences from '../Preferences/UserPreferences';
import EmailNotifications from './Notifications/EmailNotifications';

const styles = theme => ({
  preferencesView: {
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    width: 600,
  },
  signupContainer: {
    minWidth: 544,
  },
  h1: {
    fontWeight: 'normal',
    margin: '0 0 24px 0',
  },
  header: {
    marginBottom: 8,
  },
  subheader: {
    color: '#666',
    marginBottom: 24,
    textAlign: 'center',
  },
  iconHeader: {
    marginBottom: 20,
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      width: '100%',
    },
    signupContainer: {
      minWidth: 240,
    },
    h1: {
      marginTop: 32,
    },
    iconHeader: {
      '& h2': {
        fontSize: '18px !important',
      },
    },
  },
});

class PreferencesView extends Component {
  render() {
    const { classes, className, onSignUp } = this.props;

    return (
      <div
        className={classnames({
          [classes.preferencesView]: true,
          [classes.container]: !onSignUp,
          [classes.signupContainer]: !!onSignUp,
          [className]: true,
        })}
      >
        {onSignUp ? (
          <>
            <H1 className={classes.header}>Setup Preferences</H1>
            <div className={classes.subheader}>
              Take a second to choose what games you want to follow to personalize your Juked experience.
            </div>
          </>
        ) : (
          <H1 className={classes.h1}>Your Preferences</H1>
        )}
        <UserPreferences />

        {onSignUp && (
          <>
            <IconHeader className={classes.iconHeader} label="Customize Your Notifications" Icon={MdEmail} />
            <EmailNotifications />
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(PreferencesView);
