import {
  CLOSE_SERIES_NOTIFICATION,
  OPEN_SERIES_NOTIFICATION,
  RESET_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS,
} from '../actions/notification';
import toggleInArray from '../../utils/toggleInArray';

export const initialState = {
  seriesIds: [],
  openedSeriesNotifications: [], //TODO: persist in local storage?
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SERIES_NOTIFICATION:
    case CLOSE_SERIES_NOTIFICATION:
      return {
        ...state,
        openedSeriesNotifications: toggleInArray(state.openedSeriesNotifications, action.id),
      };
    case RESET_NOTIFICATIONS:
      return initialState;
    case UPDATE_NOTIFICATIONS:
      const seriesIds = action.payload.seriesIds || [];
      return {
        ...state,
        seriesIds,
      };
    default:
      return state;
  }
};
