import React from 'react';
import Helmet from 'react-helmet';

export default ({
  articlePublishedTime,
  description,
  image,
  imageWidth,
  imageHeight,
  title,
  twitterCard,
  type,
  keywords,
  robots,
}) => (
  <Helmet>
    {title && <title>{title}</title>}
    <meta name="description" content={description} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    {imageWidth && <meta property="og:image:width" content={imageWidth} />}
    {imageHeight && <meta property="og:image:height" content={imageHeight} />}
    <meta property="og:title" content={title} />
    {type && <meta property="og:type" content={type} />}
    <meta property="og:url" content={window.location.href} />
    <meta name="twitter:card" content={twitterCard || image ? 'summary_large_image' : 'summary'} />
    <meta name="twitter:creator" content="JukedGG" />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
    <meta name="twitter:site" content="JukedGG" />
    <meta name="twitter:title" content={title} />
    {articlePublishedTime && <meta property="article:published_time" content={articlePublishedTime} />}
    {robots && <meta name="robots" content={robots} />}
    {keywords && <meta name="keywords" content={keywords} />}
  </Helmet>
);
