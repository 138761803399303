import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';

import VideoCardStyles from '../VideoSwatch/VideoCardStyles';
import ParticipantImage from '../Images/ParticipantImage';
import TournamentImage from '../Images/TournamentImage';
import LineClamp from '../common/LineClamp';

import SeparatorTextSrc from './VodSeparator.svg';

const styles = theme => ({
  ...VideoCardStyles,
  teamContainer: {
    ...VideoCardStyles.teamContainer,
    '& > span': {
      whiteSpace: 'normal !important',
    },
    [theme.breakpoints.down('sm')]: {
      '& > span': {
        width: 190,
        fontSize: 20,
      },
      '&:first-child': {
        marginRight: 20,
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& > span': {
        width: 136,
        fontSize: 18,
      },
    },
  },
  teamsPlaying: {
    ...VideoCardStyles.teamsPlaying,
    textTransform: 'capitalize',
  },
  tournamentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0px 20px',
  },
  tournamentImage: {
    width: 120,
  },
  lineClamp: {
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
    whiteSpace: 'unset',
    lineClamp: 2,
    textAlign: 'center',
  },
});

class VodThumbnail extends Component {
  render() {
    const { classes, series, tournament, isVodCarousel, noParticipants } = this.props;
    if (!series) {
      return null;
    }
    const participants = series.participants;
    const participantNames = participants.map(p => p.name || p.shortName);

    return (
      <div className={classes.frameTeams}>
        {noParticipants ? (
          <div className={classes.tournamentContainer}>
            <TournamentImage className={classes.tournamentImage} tournament={tournament} />
            <LineClamp className={classes.lineClamp} text={isVodCarousel ? series.title : tournament.title} />
          </div>
        ) : (
          <span className={classes.teamsPlaying}>
            <div className={classes.teamContainer}>
              <ParticipantImage className={classes.thumbnail} participant={participants[0]} />
              <span> {participantNames[0]}</span>
            </div>
            <img src={SeparatorTextSrc} className={classes.teamsSeparator} alt="" />
            <div className={classes.teamContainer}>
              <ParticipantImage className={classes.thumbnail} participant={participants[1]} />
              <span> {participantNames[1]}</span>
            </div>
            {participants.length > 2 && (
              <span className={classes.remainingTeams} title={participantNames.slice(2).join(' | ')}>
                & {participants.length - 2} others
              </span>
            )}
          </span>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(VodThumbnail);
