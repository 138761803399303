import React, { Component } from 'react';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';

import { TrackLink, TrackSpan } from '../../common';
import { signOut } from '../../../store/actions/user';
import { setDarkMode } from '../../../store/actions/uiStates';
import { isSignedIn } from '../../../store/selectors/user';
import { FaUser, FaMoon } from 'react-icons/fa';
import { MdPersonOutline } from 'react-icons/md';
import { isFeatureEnabled } from '../../../utils/featureFlags';
import DarkModeSwitch from '../../common/DarkModeSwitch';

const styles = theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    marginLeft: 12,
    alignItems: 'center',
  },
  button: {
    color: 'white',
    height: 32,
    width: 88,
    borderRadius: 6,
    fontFamily: 'Source Sans Pro',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
    marginRight: 24,
    '&.teal': {
      color: 'black',
      background: `${theme.lightTeal} !important`,
    },
    '&:hover,&:focus': {
      background: theme.darkCharcoal,
    },
    '&.teal:hover': {
      opacity: 0.8,
    },
  },
  userAvatar: {
    color: 'white',
    position: 'relative',
    height: 32,
    width: 32,
    borderRadius: '50%',
    lineHeight: '20px',
    cursor: 'pointer',
    overflow: 'hidden',
    textDecoration: 'none',
    marginRight: 24,
    display: 'inline-flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    fontSize: 24,
    background: theme.darkCharcoal,
    '&:hover,&:focus': {
      background: 'rgba(200, 200, 200, 0.2)',
    },
  },
  dropdown: {
    position: 'absolute',
    right: 3,
    width: 'max-content',
    minWidth: 150,
    borderRadius: '3px',
    border: '0',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
    top: '100%',
    zIndex: '50',
    margin: '3px 0 0',
    fontSize: '14px',
    listStyle: 'none',
    backgroundColor: theme.palette.background.secondary,
    backgroundClip: 'padding-box',
    display: 'none',
    '&.open': {
      display: 'block',
    },
  },
  dropdownItem: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.text.quinary,
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: theme.palette.text.primary,
    },
    '& > svg': {
      marginRight: 10,
    },
  },
  userIcon: {
    background: 'black',
    alignItems: 'center',
  },
  '@media (max-width: 700px)': {
    root: {
      '&.auth-buttons': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    dropdown: {
      '&.open': {
        position: 'absolute',
        left: 100,
      },
    },
  },
  '@media (max-width: 320px)': {
    dropdown: {
      '&.open': {
        position: 'absolute',
        left: 65,
      },
    },
  },
});

class UserDropdown extends Component {
  state = { open: false };

  componentDidMount() {
    document.body.addEventListener('click', this.handleAnyClick);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleAnyClick);
  }

  //Prevent closing dropdown if clicking avatar or theme switch
  handleAnyClick = event => {
    if (
      (!this.buttonEl.contains(event.target) && !!this.switchEl && !this.switchEl.contains(event.target)) ||
      (!this.switchEl && !this.buttonEl.contains(event.target)) // There's a chance this.switchEl doesn't exist if the feature flag is deactivated.
    ) {
      this.closeDropdown();
    }
  };

  openDropdown = () => {
    this.setState({ open: !this.state.open });
  };

  closeDropdown = () => {
    this.setState({ open: false });
  };

  renderDarkModeSwitch = (classes, setDarkMode, isDarkMode) => {
    return (
      isFeatureEnabled('darkMode') && (
        <span className={classes.dropdownItem}>
          <FaMoon /> Dark Mode
          <span
            ref={node => {
              this.switchEl = node;
            }}
          >
            <DarkModeSwitch checked={isDarkMode} onChange={e => setDarkMode(e.target.checked)} variant="large" />
          </span>
        </span>
      )
    );
  };

  render() {
    const { open } = this.state;
    const { classes, signOut, isSignedIn, setDarkMode, isDarkMode } = this.props;

    if (!isSignedIn) {
      return (
        <div className={`${classes.root} auth-buttons`}>
          <TrackLink
            className={classes.button}
            to="/login"
            track={{
              event: 'Navbar Login Clicked',
              source: 'top-nav',
              target: 'login',
            }}
          >
            Log In
          </TrackLink>
          <TrackLink
            className={`${classes.button} teal`}
            to="/login"
            track={{
              event: 'Navbar Signup Clicked',
              source: 'top-nav',
              target: 'login',
            }}
          >
            Sign Up
          </TrackLink>
          <div
            className={`${classes.userAvatar} ${classes.userIcon}`}
            ref={node => {
              this.buttonEl = node;
            }}
            id="user"
            onClick={this.openDropdown}
          >
            <MdPersonOutline />
          </div>
          <div
            className={classNames({
              [classes.dropdown]: true,
              open,
            })}
          >
            {this.renderDarkModeSwitch(classes, setDarkMode, isDarkMode)}
          </div>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <div
          className={classes.userAvatar}
          ref={node => {
            this.buttonEl = node;
          }}
          id="user"
          onClick={this.openDropdown}
        >
          <FaUser />
        </div>

        <div
          className={classNames({
            [classes.dropdown]: true,
            open,
          })}
        >
          {this.renderDarkModeSwitch(classes, setDarkMode, isDarkMode)}
          <TrackLink
            className={classes.dropdownItem}
            to="/profile"
            track={{
              event: 'Navbar Profile Clicked',
              source: 'top-nav',
              target: 'profile-page',
            }}
          >
            Profile
          </TrackLink>
          <TrackSpan
            className={classes.dropdownItem}
            onClick={signOut}
            track={{
              event: 'Navbar Sign Out Clicked',
              source: 'top-nav',
              target: 'signout',
            }}
          >
            Sign Out
          </TrackSpan>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    state => ({
      isSignedIn: isSignedIn(state),
      isDarkMode: state.uiStates.isDarkMode,
    }),
    { signOut, setDarkMode }
  )
)(UserDropdown);
