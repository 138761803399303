import React, { useState, useCallback, useRef, useMemo } from 'react';
import classname from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { MdSearch } from 'react-icons/md';
import { SearchBox } from '../../common';
import { SearchResult } from './SearchResult';
import { useOnClickOutside } from '../../../hooks/useOnOutsideClick';
import { TrackIconButton } from '../../common';
import { parseSearchResult } from '../../../utils/search';
import useMediaQuery from '../../../hooks/useMediaQuery';

const COLORS = {
  tertiary: '#252929',
};

const useStyles = makeStyles(theme => ({
  root: {
    color: 'white',
    position: 'relative',
    maxWidth: 377,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',

    [theme.breakpoints.down('lg')]: {
      marginLeft: 8,
    },
  },
  searchRow: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
  },
  seperator: {
    width: 1,
    height: 28,
    background: COLORS.tertiary,
    marginRight: 16,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  searchBoxContainer: {
    margin: '6px 0',
    flex: 1,
  },
  searchBox: {
    width: '100%',
    height: '100%',
    '& .sui-search-box__text-input': {
      borderColor: COLORS.tertiary,
      padding: '7px 16px',
      color: 'white',
      caretColor: theme.magentaPink, // this is in the new theme
      '&:focus': {
        backgroundColor: 'unset',
        color: 'white',
      },
    },
    '& > :last-child': {
      padding: '7px 16px',
      bottom: 0,
      height: 'unset',
      color: 'white',
    },
  },
  resultsContainer: {
    boxSizing: 'border-box',
    position: 'absolute',
    background: theme.black,
    padding: 32,
    border: `1px solid ${COLORS.tertiary}`,
    boxShadow: '0 2px 12px 0 rgba(0,0,0,0.08)',
    borderRadius: 8,
    maxWidth: 360,
    top: 46,
    left: 0,
    width: '100%',
    '& > :last-child': {
      marginBottom: 0,
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 320,
      right: 0,
      left: 'unset',
    },
    [theme.breakpoints.down('xs')]: {
      right: -52,
      borderRadius: '8px 0 0 8px',
    },
  },
  searchButton: {
    background: 'transparent',
    padding: 0,
    fontSize: 24,
    marginRight: 12,
    marginTop: 2,
    '& div': {
      color: theme.palette.type === 'light' ? theme.lightCementGrey : 'white',
    },
    '& svg': {
      fontSize: '24px',
    },
  },
  transparent: {
    background: 'transparent',
  },
}));

export const Search = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [rawResults, setRawResults] = useState([]);
  const [isSearchOpen, setisSearchOpen] = useState(false);
  const resetFuncRef = useRef();
  // Assigning the resetFuncRef to the reset function inside of SearchBox
  const resetCb = useCallback(e => (resetFuncRef.current = e), []);

  const handleOutsideClick = useCallback(() => {
    isMobile && resetFuncRef.current && resetFuncRef.current();
    isMobile && setisSearchOpen(false);
    setRawResults([]);
  }, [isMobile]);

  const results = useMemo(() => rawResults.map(result => parseSearchResult({ result })), [rawResults]);

  const ref = useRef();
  useOnClickOutside(ref, handleOutsideClick);

  const handleSearchOpen = useCallback(() => setisSearchOpen(true), []);

  return (
    <div ref={ref} className={classes.root}>
      <div className={classes.searchRow}>
        {isMobile ? (
          <>
            {isSearchOpen ? (
              <div className={classes.searchBoxContainer}>
                <SearchBox
                  className={classes.searchBox}
                  setSearchResults={setRawResults}
                  hasSuggestions={false}
                  searchAsYouType
                  inputProps={{
                    autoFocus: true,
                    value: search,
                    onChange: e => setSearch(e.target?.value || ''),
                  }}
                  reset={() => setSearch('')}
                  track={{ event: 'Global Search Queried', source: 'top-nav' }}
                  exposeResetCb={resetCb}
                />
              </div>
            ) : (
              <TrackIconButton
                variant="square-button"
                className={classes.searchButton}
                onClick={handleSearchOpen}
                Icon={MdSearch}
                track={{ event: 'Global Search Opened', target: 'search-open', source: 'top-nav' }}
              />
            )}
          </>
        ) : (
          <div className={classes.searchBoxContainer}>
            <SearchBox
              className={classes.searchBox}
              setSearchResults={setRawResults}
              inputProps={{
                value: search,
                onChange: e => setSearch(e.target?.value || ''),
              }}
              reset={() => setSearch('')}
              hasSuggestions={false}
              searchAsYouType
              track={{ event: 'Global Search Queried', source: 'top-nav' }}
            />
          </div>
        )}
        <div className={classname(classes.seperator, { [classes.transparent]: isMobile ? isSearchOpen : true })} />
      </div>
      {results.length > 0 && (
        <div className={classes.resultsContainer}>
          {results.map(r => (
            <SearchResult key={`nav-search-${r.id}`} {...r} close={handleOutsideClick} />
          ))}
        </div>
      )}
    </div>
  );
};
