import React from 'react';

import ContentItemContainer from './ContentItemContainer';
import ContentItemLink from './ContentItemLink';
import { FollowIconMinButton } from '../../../common';

const ContentItem = ({ className, context, entity, entityField, entityType, name, onClick, to }) => {
  return (
    <ContentItemContainer className={className}>
      <ContentItemLink entity={entity} entityType={entityType} name={name} onClick={onClick} to={to} />
      <FollowIconMinButton authContext={context} entity={entity} entityField={entityField} />
    </ContentItemContainer>
  );
};

export default ContentItem;
