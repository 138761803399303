import React, { Component } from 'react';
import { MdArrowForward, MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import { Button } from '../common';
import FavoriteGamesPicker from '../Preferences/FavoriteGamesPicker';
import PreferencesView from '../Profile/PreferencesView';
import { withGamePickerState } from '../Preferences/wrappers';
import { FOLLOW_FIELDS, savePreferredEntities, setPreferencesCompletedAt } from '../../store/actions/user';
import { PREFERENCE_TYPES } from '../../store/reducers/user';
import { trackEvent } from '../../store/actions/uiStates';

const GAMES_VIEW = 'GAMES_VIEW';
const PREFERENCES_VIEW = 'PREFERENCES_VIEW';
const EVENT_TYPES = {
  GAMES_VIEW: 'games',
  PREFERENCES_VIEW: 'preferences',
};

const styles = theme => ({
  outerMinHeight: {
    minHeight: 600,
  },
  container: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: 8,
    boxShadow: '0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12)',
    margin: '0 24px 12px 24px',
    overflow: 'hidden',
  },
  childContainer: {
    padding: '40px 96px',
  },
  footer: {
    borderTop: `2px solid ${theme.palette.border.septenary}`,
    background: theme.palette.background.secondary,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 32px',
  },
  footerRight: {
    display: 'flex',
  },
  button: {
    padding: '12px 24px',
  },
  nextButton: {
    marginLeft: 8,
  },
  arrowForwardIcon: {
    fontSize: 24,
    marginLeft: 8,
  },
  arrowBackIcon: {
    fontSize: 24,
    marginRight: 8,
  },
  '@media (max-width: 700px)': {
    childContainer: {
      padding: '12px 32px',
      '&.preferences': {
        padding: '12px 16px',
      },
    },
    button: {
      padding: '12px 16px', // Important to keep content in screen.
    },
    container: {
      margin: '0 12px 12px 12px',
    },
    footer: {
      padding: '12px 16px',
    },
  },
});

class LoginPreferences extends Component {
  state = { screen: 0 };
  screenOrder = [GAMES_VIEW, PREFERENCES_VIEW];

  handleClickNext = () => {
    const { screen } = this.state;
    switch (this.screenOrder[screen]) {
      case GAMES_VIEW: {
        const { savePreferredEntities, selectedGameIds } = this.props;
        savePreferredEntities(FOLLOW_FIELDS.GAME_IDS, selectedGameIds);
        this.setState({ screen: screen + 1 });
        break;
      }
      // TODO: Add other screens here.
      default:
        this.props.setPreferencesCompletedAt(PREFERENCE_TYPES.FAVORITE_GAMES); // This gets called in last screen.
        // TODO: Move the setPreferencesCompletedAt to the correct page once we have a better solution in place.
        return;
    }
  };

  handleClickSkip = e => {
    const { setPreferencesCompletedAt, trackEvent } = this.props;
    const { screen } = this.state;
    const screenName = this.screenOrder[screen];

    // Track skip event, make sure to add an event type for each page.
    if (!EVENT_TYPES[screenName]) {
      console.error(`[LoginPreferences]: missing EVENT_TYPE ${screenName}`);
    } else {
      trackEvent('Favorites Signup Skipped', { type: EVENT_TYPES[screenName] });
    }

    if (screen === this.screenOrder.length - 1) {
      setPreferencesCompletedAt(PREFERENCE_TYPES.FAVORITE_GAMES);
    } else {
      this.setState({ screen: screen + 1 });
    }
  };

  handleClickBack = e => {
    const { screen } = this.state;
    if (screen === 0) return;
    this.setState({ screen: screen - 1 });
  };

  renderContent() {
    const { classes, onAddGame, onRemoveGame, selectedGames } = this.props;
    const { screen } = this.state;
    switch (this.screenOrder[screen]) {
      case PREFERENCES_VIEW: {
        return <PreferencesView onSignUp className={`${classes.childContainer} preferences`} />;
      }
      // TODO: Add other screens here.
      default:
        // Default is GAMES_VIEW.
        return (
          <FavoriteGamesPicker
            className={classes.childContainer}
            onAddGame={onAddGame}
            onRemoveGame={onRemoveGame}
            selectedGames={selectedGames}
          />
        );
    }
  }

  render() {
    const { screen } = this.state;
    const { classes, selectedGames } = this.props;

    return (
      <div className={classes.outerMinHeight}>
        <div className={classes.container}>
          {this.renderContent()}
          <div className={classes.footer}>
            <div>
              {screen === 0 ? null : (
                <Button className={classes.button} onClick={this.handleClickBack}>
                  <MdArrowBack className={classes.arrowBackIcon} /> BACK
                </Button>
              )}
            </div>
            <div className={classes.footerRight}>
              <Button className={classes.button} onClick={this.handleClickSkip}>
                SKIP
              </Button>
              <Button
                className={`${classes.button} ${classes.nextButton}`}
                onClick={this.handleClickNext}
                theme="lightTeal"
                // Disable if we are on favorite games section and don't select any game.
                disabled={screen === 0 && !selectedGames.length}
              >
                NEXT <MdArrowForward className={classes.arrowForwardIcon} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withGamePickerState,
  connect(null, { savePreferredEntities, setPreferencesCompletedAt, trackEvent })
)(LoginPreferences);
