import { useEffect, useMemo, useState } from 'react';

import { normalize } from '../../utils/getFirestoreQuery';

/**
 * Very similar to useFirestoreSusbcribeEffect, except it takes an array of
 * queries and returns results in array matching input queries.
 */
const useFirestoreMultiSubscribeEffect = ({ type, queries }) => {
  // Determines the correct state setters and initial values depending on desired `type`.
  const [initialized, setInitialized] = useState(false);
  const [results, setResults] = useState({});

  const formattedResults = useMemo(
    () =>
      queries.map((_, idx) => {
        if (!results[idx]) return type === 'array' ? [] : {};
        return type === 'array' ? Object.values(results[idx]) : results[idx];
      }),
    [queries, results, type]
  );

  // NOTE: Following the rules-of-hooks we must call hooks in the top-level, so
  // instead of a factory function taking a type parameter, we will abstract the useEffect logic.
  useEffect(() => {
    const initializedMap = queries.reduce((accu, _, idx) => ({ ...accu, [idx]: false }), {});
    const unsubs = queries.map((query, idx) =>
      query.onSnapshot(snapshot => {
        if (!initializedMap[idx]) {
          setResults(prevResults => ({ ...prevResults, [idx]: normalize(snapshot.docs.map(d => d.data())) }));
          initializedMap[idx] = true;
        } else {
          snapshot.docChanges().forEach(change => {
            const data = change.doc.data();
            if (change.type === 'removed') {
              setResults(prevResults => {
                const prevResult = prevResults[idx] || {};
                const { [data.id]: removed, ...newResult } = prevResult;
                return { ...prevResults, [idx]: newResult };
              });
            } else {
              // added or modified
              setResults(prevResults => {
                const prevResult = prevResults[idx] || {};
                return { ...prevResults, [idx]: { ...prevResult, [data.id]: data } };
              });
            }
          });
        }

        // Set initialized if last snapshot
        if (Object.values(initializedMap).every(value => value === true)) {
          setInitialized(true);
        }
      })
    );
    return () => unsubs.forEach(unsub => unsub());

    // query should unsub & resub if anything pertaining to the query changes.
  }, [queries, setInitialized, setResults]);

  return [formattedResults, !initialized /* Loading State */];
};

export const useFirestoreMultiSubscribeQuery = ({ queries }) =>
  useFirestoreMultiSubscribeEffect({
    type: 'array',
    queries,
  });
