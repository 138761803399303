import { createSelector, defaultMemoize, createSelectorCreator } from 'reselect';
import createCachedSelector from 're-reselect';

import { isEqualOrArrayEqual, getSlugFromAbbr, isSubstageStandingsValid } from './gameDataUtils';
import { getPreferredGameIds, getPreferredEntityIds } from './user';
import { FOLLOW_FIELDS } from '../actions/user';
import { RootState } from '../reducers';
import {
  Bracket,
  Game,
  PlayerParticipant,
  Stage,
  Series,
  Standings,
  TeamParticipant,
  isPlayerParticipant,
  isTeamParticipant,
} from '../types';
import getIn from '../../utils/getIn';

export const ONE_MINUTE = 60 * 1000;
export const FEATURED_SERIES_TIERS = [1, 2];

// ---------- Simple getters ----------
const getStandings = ({ gameData: { standings } }: RootState): Record<string, Standings> => standings;
const getBrackets = ({ gameData: { brackets } }: RootState): Record<string, Bracket> => brackets;
export const getGameFilters = ({ uiStates: { gameFilters } }: RootState): string[] => gameFilters;
export const getGames = ({ gameData: { games } }: RootState): Record<string, Game> => games;

export const getSelectedStreamMap = ({ uiStates: { selectedStreamMap } }: RootState): Record<string, string> =>
  selectedStreamMap;

// Get all fetched series, with quicker-processed collections overriding slower-processed collections for freshest data
// All other selectors that needs to get "all the series" should use this selector, instead of accessing `allSeries` directly.
export const getSeries = createSelector(
  ({ gameData: { allSeries } }: RootState) => allSeries,
  ({ gameData: { liveSeries } }: RootState) => liveSeries,
  ({ gameData: { upcomingSeries } }: RootState) => upcomingSeries,
  ({ gameData: { recentVodSeries } }: RootState) => recentVodSeries,
  (allSeries, liveSeries, upcomingSeries, recentVodSeries) => ({
    ...allSeries,
    ...recentVodSeries,
    ...upcomingSeries,
    ...liveSeries,
  })
);

export const isDataLoaded = ({ gameData: { isLoaded } }: RootState): boolean => {
  // Verifies that the 8 data collections have been initialized.
  return isLoaded.all;
};

// ---------- Simple get-by-id getters ----------
export const getBracketById = ({ gameData: { brackets } }: RootState, id: string): Bracket => {
  // TODO: converting legacy data's grandFinal and thirdPlace to array.
  // once confirming all brackets' grandFinal and thirdPlace are migrated to arrays,
  // simply return `brackets[id]`.
  const bracket = brackets[id];

  const { grandFinal, thirdPlace } = bracket;

  if (!!grandFinal && !Array.isArray(grandFinal)) {
    bracket.grandFinal = [grandFinal];
  }

  if (!!thirdPlace && !Array.isArray(thirdPlace)) {
    bracket.thirdPlace = [thirdPlace];
  }

  return bracket;
};

// TODO: eliminate getGameById
export const getGameById = ({ gameData: { games } }: RootState, id: string): Game | null => games[id];

export const getSeriesById = createCachedSelector(
  getSeries,
  (_: RootState, id: string) => id,
  (allSeries, id) => allSeries[id]
)((_, id) => `${id}`);

export const getSeriesByTournamentId = createCachedSelector(
  getSeries,
  (_: RootState, id: string) => id,
  (allSeries, id) => Object.values(allSeries).filter(series => getIn(['tournament', 'id'])(series) === id)
)((_, id) => `${id}`);

export const getSeriesByTournamentSlug = createCachedSelector(
  getSeries,
  (_: RootState, slug: string) => slug,
  (allSeries, slug) => Object.values(allSeries).filter(series => getIn(['tournament', 'slug'])(series) === slug)
)((_, slug) => `${slug}`);

export const getSeriesByCompetitionId = createCachedSelector(
  getSeries,
  (_: RootState, id: string) => id,
  (allSeries, id) => Object.values(allSeries).filter(series => getIn(['tournament', 'competitionId'])(series) === id)
)((_, id) => `${id}`);

export const getSeriesByCompetitionSlug = createCachedSelector(
  getSeries,
  (_: RootState, slug: string) => slug,
  (allSeries, slug) =>
    Object.values(allSeries).filter(series => getIn(['tournament', 'competition', 'slug'])(series) === slug)
)((_, slug) => `${slug}`);

// ---------- reselect selectors ----------

// use `createArrEqSelector` over `createSelector` when one or more inputs are array.
export const createArrEqSelector = createSelectorCreator(defaultMemoize, isEqualOrArrayEqual);

// ------- game related -------

export const getPreferredGameIdsSet = createSelector(
  getPreferredGameIds,
  (gameIds): Set<string> => new Set(gameIds || [])
);

export const getGamesArr = createSelector(getGames, games => (games ? Object.values(games) : []));

const filterShownGames = (games: Game[]) =>
  games.filter(game => !game.hidden).map(game => ({ ...game, slug: getSlugFromAbbr(game.abbr) }));

export const getShownGames = createSelector(getGamesArr, filterShownGames);

const getMaxGamePriority = createSelector(getGames, games =>
  games
    ? Math.max(
        0, // To ensure result is an int.
        ...Object.values(games)
          .filter(game => !game.hidden && typeof game.priority === 'number')
          .map(game => game.priority)
      )
    : 0
);

export const getGamesArrByIds = createSelector(
  getGamesArr,
  (_: RootState, gameIds: string[]) => gameIds,
  (gameList, gameIds) => {
    return gameList.filter(game => {
      if (!gameIds) {
        return false;
      }
      return gameIds.find(id => id === game.id);
    });
  }
);

// Preferred games get a priority deduction of max priority + 1 so they will always be first.
const getPriority = (game: Game, maxPriority: number, preferredGameIds: Set<string>): number =>
  preferredGameIds.has(game.id) ? (game.priority || maxPriority) - (maxPriority + 1) : game.priority || maxPriority + 1;

export const getSortedGames = createSelector(
  getGamesArr,
  getPreferredGameIdsSet,
  getMaxGamePriority,
  (games, preferredGameIds, maxPriority) =>
    [...games].sort(
      (a, b) => getPriority(a, maxPriority, preferredGameIds) - getPriority(b, maxPriority, preferredGameIds)
    )
);

export const getSortedShownGames = createSelector(getSortedGames, filterShownGames);

export const getLiveGamesMap = createSelector(
  getGames,
  ({ gameData: { liveSeries } }: RootState) => liveSeries,
  (games, liveSeries) => {
    const liveSeriesArr = Object.values(liveSeries);
    return liveSeriesArr.reduce(
      (acc: Record<string, Game>, aSeries: Series) =>
        games[aSeries.game] && !acc[aSeries.game] ? { ...acc, [aSeries.game]: games[aSeries.game] } : acc,
      {}
    );
  }
);

export const getPreferredGames = createSelector(
  getGames,
  (state: RootState) => getPreferredEntityIds(state, { entityField: FOLLOW_FIELDS.GAME_IDS }),
  (games, gameIds) => gameIds.map(gameId => games[gameId]).filter(game => Boolean(game))
);

export const getCurrentGame = createSelector(getShownGames, getGameFilters, (gamesArr, gameFilters) => {
  const filteredGame = gamesArr.find(game => game.id === gameFilters[0]);
  return filteredGame;
});

export const getPreferredPrioritizedGames = createSelector(
  getSortedGames,
  getPreferredGameIdsSet,
  getLiveGamesMap,
  (games, preferredGameIdsSet, liveGames) => {
    return [...games].sort((a, b) => {
      if (preferredGameIdsSet.has(a.id) && !preferredGameIdsSet.has(b.id)) {
        return -1;
      }

      if (preferredGameIdsSet.has(b.id) && !preferredGameIdsSet.has(a.id)) {
        return 1;
      }

      if (liveGames[a.id] && !liveGames[b.id]) {
        return -1;
      }

      if (liveGames[b.id] && !liveGames[a.id]) {
        return 1;
      }

      return 0;
    });
  }
);

// ---------- other non-reselect (un-memoized) selectors ----------
// TODO: memoize with createCachedSelector
export const getStandingsById = createCachedSelector(
  getSeries,
  getStandings,
  getBrackets,
  (_: RootState, id: string) => id,
  (series, standings, brackets, id) => {
    if (!standings[id]) {
      return null;
    }
    // Clone to alter properties.
    const resultStandings: Standings = JSON.parse(JSON.stringify(standings[id]));
    const stages = resultStandings.stages
      .map((stage: Stage) => {
        // Go through the stage's substages and validate them
        const substages = stage.substages
          .map(substage => {
            return isSubstageStandingsValid(substage, series, brackets) ? substage : null;
          })
          .filter(substage => substage !== null);
        // If no substage within the stage is valid, the stage itself is also not valid
        return substages.length === 0 ? null : { ...stage, substages };
      })
      .filter((stage: Stage) => stage !== null);

    return {
      ...resultStandings,
      stages,
    };
  }
)((_, id) => `${id}`);

export const getTournamentMostRecentSingleSeries = createSelector(getSeriesByTournamentId, series => {
  const nowSeconds = Math.floor(new Date().getTime() / 1000);

  const mostRecentSingleSeries = series
    .sort((a, b) => a.start && b.start && a.start.seconds - b.start.seconds)
    .find(aSeries => (!aSeries.end || aSeries.end.seconds > nowSeconds) && aSeries.start);

  return mostRecentSingleSeries;
});

// ---------- Mixpanel context selectors ----------
export const getSeriesContext = createCachedSelector(
  getSeriesById,
  getGames,
  getPreferredGameIdsSet,
  (series, games, preferredGameIdsSet) => {
    if (!series) {
      return null;
    }

    const { tournament, participants } = series;
    const { competition } = tournament || {};
    const game = games[series.game];

    const gameContext = game
      ? {
          game_id: game.id,
          game_abbr: game.abbr,
        }
      : {};

    const tournamentContext = tournament
      ? {
          tournament_id: tournament.id,
          tournament_slug: tournament.slug,
          tournament_title: tournament.title,
        }
      : {};

    const competitionContext = competition
      ? {
          competiton_id: competition.id,
          competition_slug: competition.slug,
          competition_title: competition.title,
        }
      : {};

    // Returns a function that takes type to filter UUID on and returns a map
    // function.  Mapping function returns an array of participants or null.
    const getMapParticipants = (filteredParticipants: (PlayerParticipant | TeamParticipant)[]) => (
      mapFn: (p: PlayerParticipant | TeamParticipant) => string | string[]
    ): (string | string[])[] => filteredParticipants.map(mapFn).filter(value => Boolean(value));

    const players = participants ? participants.filter(isPlayerParticipant) : [];
    const teams = participants ? participants.filter(isTeamParticipant) : [];
    const mapPlayers = getMapParticipants(players);
    const mapTeams = getMapParticipants(teams);

    const participantsContext = participants
      ? {
          player_ids: mapPlayers((p: PlayerParticipant) => p.uuid),
          player_names: mapPlayers((p: PlayerParticipant) => p.name),
          player_short_names: mapPlayers((p: PlayerParticipant) => p.shortName),
          team_ids: mapTeams((t: TeamParticipant) => t.uuid),
          team_names: mapTeams((t: TeamParticipant) => t.name),
          team_short_names: mapTeams((t: TeamParticipant) => t.shortName),
          team_player_names: [].concat(...mapTeams((t: TeamParticipant) => t.players)), // Flatten array of player arrays.
        }
      : {};

    return {
      series_id: series.id,
      is_game_favorited: preferredGameIdsSet.has(game.id),
      ...gameContext,
      ...tournamentContext,
      ...competitionContext,
      ...participantsContext,
    };
  }
)((_, seriesId) => `${seriesId}`);
