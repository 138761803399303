import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

import { toggleVideoSwatchView } from '../../store/actions/uiStates';
import { TrackButtonBasic } from '../common';
import MultiViewIcon from './MultiViewIcon';
import SingleViewIcon from './SingleViewSwatch/SingleViewIcon';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginLeft: 'auto',
  },
  button: {
    height: 32,
    width: 32,
    padding: 0,
    borderRadius: 4,
    borderColor: 'transparent',
    marginLeft: 8,
    background: theme.palette.button.primary,
    boxShadow: `2px 2px 8px 0 rgba(10,11,11,0.2)`,
    cursor: 'pointer',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '.selected &': {
      '&:not(:first-child)': {
        background: theme.magentaPink,
      },
    },
    '.notSelected &': {
      '&:first-child': {
        background: theme.magentaPink,
      },
    },
  },
}));

const getTrack = view => ({
  event: 'Video Swatch View Clicked',
  source: 'video-swatch',
  eventProperties: { view },
});

const MultiViewButtons = ({ isHomePageView, isSinglePlayerView }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return isHomePageView ? (
    <div
      className={classnames({
        [classes.container]: true,
        selected: isSinglePlayerView,
        notSelected: !isSinglePlayerView,
      })}
    >
      <TrackButtonBasic
        track={getTrack('multi-player')}
        onClick={() => dispatch(toggleVideoSwatchView(false))}
        className={classes.button}
      >
        <MultiViewIcon fill={isSinglePlayerView ? '#9D9D9D' : 'white'} />
      </TrackButtonBasic>
      <TrackButtonBasic
        track={getTrack('single-player')}
        onClick={() => dispatch(toggleVideoSwatchView(true))}
        className={classes.button}
      >
        <SingleViewIcon fill={isSinglePlayerView ? 'white' : '#9D9D9D'} />
      </TrackButtonBasic>
    </div>
  ) : null;
};

export default MultiViewButtons;
