import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { H1, TrackA } from '../../common';

const useStyles = makeStyles(theme => ({
  header: {
    textAlign: 'center',
    marginBottom: 34,
    marginTop: 0,
    fontSize: '40px !important',
  },
  video: {
    marginBottom: 34,
    width: '100%',
  },

  frameContainer: {
    height: 0,
    paddingTop: '56.25%',
    overflow: 'hidden',
    position: 'relative',
  },
  frame: {
    border: 0,
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    zIndex: 1,
  },
  container: {
    alignItems: 'center',
    background: theme.palette.background.secondary,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '28px 48px 80px',
    width: 700,
  },
  link: {
    width: ({ buttonWidth }) => buttonWidth || 116,
    height: 35,
    background: theme.lightPink,
    textDecoration: 'none',
    borderRadius: 5,
    display: 'flex',
    marginBottom: 24,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 16px',
    color: 'white',
    fontWeight: 650,
    fontSize: 20,
    '&:hover': {
      opacity: 0.8,
    },
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      width: 600,
    },
  },
  [theme.breakpoints.down('xs')]: {
    header: {
      fontSize: '27px !important',
    },
    container: {
      width: '100vw',
      padding: '20px 14px 50px',
    },
    link: {
      width: 92,
      height: 28,
      fontSize: 18,
    },
  },
}));

const FrameView = ({ title, frameSrc, frameTitle, redirectUrl, trackEvent, buttonWidth }) => {
  const classes = useStyles({ buttonWidth });
  return (
    <div className={classes.container}>
      <H1 className={classes.header}>{title}</H1>

      <div className={classes.video}>
        <div className={classes.frameContainer}>
          <iframe className={classes.frame} title={frameTitle} allowFullScreen src={frameSrc}></iframe>
        </div>
      </div>

      <TrackA className={classes.link} href={redirectUrl} target="_blank" rel="noopener noreferrer" track={trackEvent}>
        Get the App
      </TrackA>
    </div>
  );
};

export default FrameView;
