import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { db } from '../../store/sagas/firebase';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { MdCheck, MdClose } from 'react-icons/md';

import Countdown from '../CalendarTable/Countdown';
import { TrackDiv } from '../common';
import withTrackClick from '../Wrappers/withTrackClick';
import { openLoginDialog } from '../../store/actions/dialogs';
import { isSignedIn, getUser } from '../../store/selectors/user';
import { LOGIN_SOURCES } from '../../store/reducers/user';
import { useInterval, useAsync } from '../../hooks';
import OptionButton from './OptionButton';
import UserStatistics from './UserStatistics';
import getOptionsColor from './getOptionsColor';

const getCountdownWidth = end => {
  if (!end) {
    return 'initial';
  }

  const nowSeconds = Math.floor(new Date().getTime() / 1000);
  const secondsDifferential = end.seconds - nowSeconds;

  if (secondsDifferential < 60) {
    return 35;
  }
  if (secondsDifferential < 600) {
    return 60;
  }
  if (secondsDifferential < 3600) {
    return 75;
  }
  return 'initial';
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 'max-content',
    boxSizing: 'border-box',
    width: '100%',
    overflow: 'hidden',
    border: '1px solid #252929',
    background: theme.black,
    boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.2)',
    '& .title': {
      color: theme.charcoalGrey,
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: 2,
      lineHeight: '16px',
    },
  },
  headerContainer: {
    width: 360,
    boxSizing: 'border-box',
    height: 40,
    display: 'flex',
    paddingTop: 12,
    justifyContent: 'space-between',
    padding: '0 24px',
    position: 'relative',
    transition: 'height 380ms ease-in-out',
    '& .caption': {
      color: theme.charcoalGrey,
      fontSize: 14,
      lineHeight: '22px',
      fontWeight: 500,
    },
    '& .promptText': {
      color: 'white',
      fontSize: 18,
      lineHeight: '30px',
    },
    '& .promptText, & .caption': {
      display: 'none',
      '.open &': {
        display: 'block',
      },
    },
    '.open &': {
      height: 'max-content',
      animation: '$openPikcEm 700ms linear',
      display: 'block',
    },
    '.open.countdownEnded &': {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  status: {
    color: 'white',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 3,
    lineHeight: '14px',
    textAlign: 'right',
    '.open &': {
      display: 'none',
    },
  },
  countdown: {
    color: 'white',
    width: ({ end }) => getCountdownWidth(end),
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: '24px',
    display: 'none',
    transition: 'opacity 400ms ease-in-out',
    position: 'absolute',
    top: 8,
    right: 18,
    '& span > span': {
      display: 'none',
    },
    '.open &': {
      display: 'block',
    },
    '.countdownEnded &, .answered &': {
      display: 'none',
    },
    '& :last-child': {
      width: 20,
    },
  },
  hideText: {
    position: 'absolute',
    top: 12,
    right: 23,
    color: 'white',
    cursor: 'pointer',
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '16px',
    display: 'none',
    visibility: 'hidden',
    '.open &': {
      display: 'inline',
    },
    '.countdownEnded &': {
      visibility: 'visible',
    },
  },
  options: {
    fontSize: 24,
    width: 360,
    boxSizing: 'border-box',
    fontWeight: 'bold',
    lineHeight: '24px',
    color: 'white',
    padding: 0,
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridTemplateRows: '1fr',
    overflow: 'hidden',
    transition: 'all 400ms ease-in-out',
    '& > div:nth-child(even)': {
      // each even child render separator
      borderLeft: '1px solid #1E2424',
    },
    '.open &': {
      animation: '$openPikcEm 700ms linear',
      padding: '8px 16px 16px',
      '& > *:nth-child(n+3)': {
        // every element after 2nd
        marginTop: 8,
      },
    },
    '.showStat &': {
      gridTemplateColumns: '1fr',
    },
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 36,
    width: 36,
    borderRadius: 28,
    background: '#ED3B4D', // red
    marginRight: 8,
    display: 'none',
    '.isCorrect &': {
      background: 'green',
    },
    '.open &': {
      display: 'inline-flex',
    },
    '& svg': {
      color: 'white',
      fontSize: 28,
      lineHeight: '28px',
      textAlign: 'center',
    },
  },
  label: {
    marginTop: 4,
    marginBottom: 0,
    color: theme.charcoalGrey,
    fontSize: 10,
    letterSpacing: 0.2,
    lineHeight: '16px',
    textAlign: 'center',
  },
  '@keyframes openPikcEm': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 0,
    },
    '80%': {
      opacity: 0.5,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

const isPickTimeEnded = prompt => !!prompt.answer || (!!prompt.end && prompt.end.toDate() < new Date());

const getExtraLabel = (prompt, entry) => {
  const { answer } = prompt;

  if (!entry && isPickTimeEnded(prompt)) {
    return 'The time to make your pick has passed.';
  }

  if (!entry) {
    return null;
  }

  if (!!entry && !answer) {
    return `Great pick! Results shown after the match ends.`;
  }

  if (entry.choice === answer) {
    return 'What a pick, you’re a genius.';
  }

  return 'You picked wrong this time.';
};

const getStatus = prompt => {
  if (!isPickTimeEnded(prompt)) {
    return 'IN PROGRESS';
  } else {
    if (!prompt.answer) {
      return 'AWAITING RESULT';
    } else {
      return 'ENDED';
    }
  }
};

// This essentially is just checking the value of `isPickTimeEnded` every second and forcing a rerender if the round is now considered to be ended based on the end time.
// This is needed because the round goes from active to end based on the passage of time, and no props actually would change to trigger a rerender.
const useForceUpdateIfEnded = prompt => {
  const [isEnded, setIsEnded] = useState(isPickTimeEnded(prompt));
  const [countdownWidth, setCountdownWidth] = useState(getCountdownWidth(prompt.end));

  useInterval(() => {
    const newIsEnded = isPickTimeEnded(prompt);
    const newCountdownWidth = getCountdownWidth(prompt.end);
    if (isEnded !== newIsEnded) {
      setIsEnded(newIsEnded);
    }
    if (countdownWidth !== newCountdownWidth) {
      setCountdownWidth(newCountdownWidth);
    }
  }, 1000);
};

const createContestUserEntry = ({ prompt, choice, user }) => {
  const id = `${prompt.id}:${user.uid}`;
  return db.collection('contestUserEntries').doc(id).set({ id, prompt, choice, isResultSeen: false, uid: user.uid });
};

const TrackOptionButton = withTrackClick(OptionButton);

const PromptCard = ({ prompt, entry, isSignedIn, user, openLoginDialog, promptCount }) => {
  useForceUpdateIfEnded(prompt);

  // show as open if user 1) hasn't voted on this active prompt yet, 2) hasn't already seen the result
  // if user hasn't voted && voting period ended, there wouldn't be a prompt card for it.
  const [isOpen, setIsOpen] = useState(false);

  const { options, answer, promptText, end, type, seriesId, start } = prompt;

  // computed variables below
  const isCorrect = !!entry && entry.choice === answer;
  const showStat = !!answer && !!entry;
  const countdownEnded = isPickTimeEnded(prompt);

  const [, markEntryAsSeen] = useAsync(entry =>
    db.collection('contestUserEntries').doc(entry.id).update({ isResultSeen: true })
  );

  // This makes the container grow from the top on initial render.
  useEffect(() => {
    if (!entry || !entry.isResultSeen) {
      setIsOpen(true);
    }
  }, [entry]);

  useEffect(() => {
    // By this point if we have more than one prompt available, we're presenting the results for 7 seconds,
    // then we can mark it as seen so the question desappears.
    const shouldSetTimer = showStat && !entry.isResultSeen && promptCount > 1;
    const timer =
      shouldSetTimer &&
      setTimeout(() => {
        markEntryAsSeen(entry);
      }, 7000);
    return () => timer && clearTimeout(timer);

    // promptCount is needed here to force triggering this function if a new prompt is added.
  }, [entry, promptCount, markEntryAsSeen, showStat]);

  const classes = useStyles({ end });
  return (
    <div
      className={classnames({
        [classes.root]: true,
        open: isOpen,
        answered: !!answer,
        isCorrect,
        countdownEnded,
        showStat,
      })}
    >
      <TrackDiv
        className={classes.headerContainer}
        onClick={() => setIsOpen(!isOpen)}
        track={{
          event: 'Contest Window Toggled',
          eventProperties: {
            answered: !!answer,
            is_correct: !!entry ? isCorrect : null,
            countdown_ended: countdownEnded,
            has_entry: !!entry,
          },
          source: 'contest',
          target: !!isOpen ? 'collapsed' : 'expanded',
        }}
      >
        {showStat && <div className={classes.iconContainer}>{isCorrect ? <MdCheck /> : <MdClose />}</div>}
        <div>
          <div className="title">JUKED PICK’EMS</div>
          <div className="promptText">{promptText}</div>
          <div className="caption">{getExtraLabel(prompt, entry)}</div>
        </div>
        {!!end && end.toDate() > new Date() && (
          <Countdown
            key={end.seconds} // force countdown to rerender if `end` changes.
            className={classnames({ [classes.countdown]: true })}
            withoutStartingText
            withColon
            targetTime={end.toDate()}
          />
        )}
        <span className={classes.status}>{getStatus(prompt)}</span>
        {/** TODO: add 'hide' */}
      </TrackDiv>

      <div className={classes.options}>
        {showStat ? (
          <UserStatistics userId={user.uid} />
        ) : (
          Object.keys(options).map((optionKey, index) => {
            const optionValue = options[optionKey];
            const selectedOptionKey = !!entry && entry.choice;

            return (
              <TrackOptionButton
                key={index}
                type={type}
                color={getOptionsColor(index)}
                seriesId={seriesId} // seriesId is needed for ParticipantButton to find participant data
                optionKey={optionKey}
                optionValue={optionValue}
                selectedOptionKey={selectedOptionKey}
                disabled={countdownEnded || (!!selectedOptionKey && selectedOptionKey !== optionKey)}
                onClick={() => {
                  if (!isSignedIn) {
                    openLoginDialog({
                      source: LOGIN_SOURCES.CONTESTS,
                      onLoginSuccess: loggedInUser => {
                        // using `loggedInUser` instead of `user` because when `onLoginSuccess` gets triggered, this component's user prop hasn't necessarily updated yet.
                        createContestUserEntry({ prompt, choice: optionKey, user: loggedInUser });
                      },
                    });
                  } else {
                    createContestUserEntry({ prompt, choice: optionKey, user });
                  }
                }}
                track={{
                  event: 'Contest Selection Made',
                  eventProperties: {
                    is_signed_in: isSignedIn,
                    option_key: optionKey,
                    option_value: optionValue,
                    prompt_end: end.toDate(),
                    prompt_id: prompt.id,
                    prompt_start: start.toDate(),
                    prompt_text: promptText,
                    series_id: seriesId,
                    type,
                  },
                  source: 'contest',
                }}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default connect(
  state => ({
    user: getUser(state),
    isSignedIn: isSignedIn(state),
  }),
  { openLoginDialog }
)(PromptCard);
