import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Checkbox } from '@material-ui/core';

export default withStyles(theme => ({
  root: {
    marginLeft: 6,
    '&$checked': {
      color: theme.lightTeal,
    },
  },
  checked: {},
}))(props => <Checkbox color="default" {...props} />);
