import React, { useState } from 'react';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Button from '../../buttons/Button';
import { isSignedIn } from '../../../../store/selectors/user';
import { openLoginDialog } from '../../../../store/actions/dialogs';
import { LOGIN_DIALOG_VIEWS } from '../../../../store/reducers/dialogs';
import { LOGIN_SOURCES } from '../../../../store/reducers/user';

const useStyles = makeStyles(theme => ({
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    width: ({ following }) => (following ? 120 : 100),
    '&:hover svg': {
      fontSize: ({ following }) => (following ? 16 : 20),
    },
  },
  icon: {
    marginLeft: 8,
    fontSize: 16,
  },
}));

const getLabel = (following, inHover) => {
  if (inHover && following) return 'Unfollow';
  return following ? 'Following' : 'Follow';
};

const getIcon = (following, inHover) => {
  // NOTE: FaHeart is filled and FaRegHeart is outlined.
  if (inHover) {
    return following ? FaRegHeart : FaHeart;
  } else {
    return following ? FaHeart : FaRegHeart;
  }
};

const FollowButton = ({ context, following, actionHandler }) => {
  const dispatch = useDispatch();
  const [inHover, setHover] = useState(false);

  const isUserSignedIn = useSelector(state => isSignedIn(state));
  const classes = useStyles({ following });
  const Icon = getIcon(following, inHover);

  return (
    <Button
      className={classes.button}
      label={getLabel(following, inHover)}
      onClick={() => {
        if (!isUserSignedIn) {
          dispatch(
            openLoginDialog({
              view: LOGIN_DIALOG_VIEWS.FOLLOW,
              source: LOGIN_SOURCES.DIALOG_NEW_FEATURE,
              onLoginSuccess: () => {
                actionHandler();
              },
              context,
            })
          );
        } else {
          actionHandler();
        }
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      theme={following ? 'iconPinkHover' : 'iconPink'}
    >
      <>
        {getLabel(following, inHover)}
        <Icon className={classes.icon} />
      </>
    </Button>
  );
};

export default FollowButton;
