import queryString from 'query-string';

//Will construct the embed URL
export default url => {
  //If the URL arrives embed there is no need to build it because it works perfect in the iframe
  if (url.includes('embed')) return url;

  const providerUrl = queryString.parseUrl(url);

  //Check if URl is from Youtube
  if (url.includes('youtube')) {
    const videoId = providerUrl.query.v;
    return `https://www.youtube.com/embed/${videoId}`;
  }

  // //Check if URL is from Twitch and if is a clip
  if (url.includes('twitch') && providerUrl.query.filter === 'clips') {
    const clipName = providerUrl.url.split('/').pop();
    return `https://clips.twitch.tv/embed?clip=${clipName}&parent=${window.location.hostname}&autoplay=false`;
  }

  // //Check if URL is from Twitch and if is a video
  if (url.includes('twitch') && url.includes('videos')) {
    const videoId = providerUrl.url.split('/').pop();
    return `https://player.twitch.tv/?video=${videoId}&parent=${window.location.hostname}&autoplay=false`;
  }
};
