import React, { Component } from 'react';

// This simple thing is in its own component so we can minimize the rerendering surface.
class Countdown extends Component {
  constructor(props) {
    super(props);
    // Doing this in the constructor so the Countdown can render correctly in the first cycle.
    this.setDelta(props.targetTime);
    this.timer = null;
  }

  setDelta(targetTime) {
    this.delta = (targetTime.getTime() - new Date().getTime()) / 1000;

    // Prevent rendering negatives until interval hits.
    if (this.delta <= 0) {
      this.delta = 0;
    }
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      const { targetTime, onTimeEnd } = this.props;
      this.delta = (targetTime.getTime() - new Date().getTime()) / 1000;
      if (this.delta <= 0) {
        onTimeEnd && onTimeEnd();
        this.delta = 0;
        clearInterval(this.timer);
        return; // Prevent forceUpdate if the component gets unmounted.
      }
      this.forceUpdate();
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    const { targetTime } = this.props;
    if (prevProps.targetTime && prevProps.targetTime !== targetTime) {
      this.setDelta(targetTime);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { className, withoutStartingText, withColon } = this.props;

    const hour = Math.floor(this.delta / 3600);
    const min = Math.floor((this.delta % 3600) / 60);
    const sec = Math.floor(this.delta % 60);

    return (
      <div className={className}>
        {!!hour && (
          <span>
            {hour}
            <span>h</span>{' '}
          </span>
        )}
        {!!min && (
          <span>
            {min}
            <span>m</span>
            {withColon ? ':' : ' '}
          </span>
        )}
        {/* this.delta >= 1 will render the seconds placeholder (:00) only when the countdown is still running */}
        {(withColon || !!sec) && this.delta >= 1 && (
          <span>
            {/* To keep 30:00 format */}
            {!!withColon && sec < 10 ? '0' : ''}
            {sec}
            <span>s</span>
          </span>
        )}

        {!min && !sec && (withoutStartingText ? '00s' : 'starting now...')}
      </div>
    );
  }
}

export default Countdown;
