import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

import {
  isDataLoaded,
  getGameFilters,
  createArrEqSelector,
  getSeriesByCompetitionId,
  getSeriesByTournamentId,
  getSeries,
} from './gameData';
import { selectorForFilteringGivenSeries, isPartOfName } from './gameDataUtils';
import { getPreferredEntityIds } from './user';
import { getIsFollowMode } from './uiStates';
import { isUserFollowingAnyGivenEntity } from './gameDataUtils';
import { getEntitySortedByFollow } from './followMode';

export const sortOption = { FAVORITES: 'followed', FEATURED: 'featured', VIEWS: 'views', RECENT: 'recent' };

export const filterSeriesVods = series =>
  series.filter(s => s.hasVod).sort((a, b) => b.start.seconds - a.start.seconds);

export const getVodSeriesByTournamentId = createCachedSelector(
  getSeriesByTournamentId,
  filterSeriesVods
)({
  keySelector: (_, id) => `${id}`,
  selectorCreator: createArrEqSelector,
});

export const getVodSeriesByCompetitionId = createCachedSelector(
  getSeriesByCompetitionId,
  filterSeriesVods
)({
  keySelector: (_, id) => `${id}`,
  selectorCreator: createArrEqSelector,
});

export const getPreferredEntityIdsMap = createSelector(
  state => getPreferredEntityIds(state, { entityField: 'gameIds' }),
  state => getPreferredEntityIds(state, { entityField: 'teamIds' }),
  state => getPreferredEntityIds(state, { entityField: 'tournamentIds' }),
  state => getPreferredEntityIds(state, { entityField: 'competitionIds' }),
  (preferredGameIds, preferredTeamIds, preferredTournamentIds, preferredCompetitionIds) => ({
    preferredGameIds,
    preferredTeamIds,
    preferredTournamentIds,
    preferredCompetitionIds,
  })
);

const getRecentVodSeries = createSelector(
  getPreferredEntityIdsMap,
  getIsFollowMode,
  getGameFilters,
  ({ gameData: { recentVodSeries } }) => recentVodSeries,
  (preferredEntityIdsMap, isFollowMode, gameFilters, recentVodSeries) => {
    //Will filter VODS by user favorites Entities (HomePage)
    if (!gameFilters.length && !!recentVodSeries && isFollowMode) {
      return Object.values(recentVodSeries).filter(aSeries =>
        isUserFollowingAnyGivenEntity({
          ...preferredEntityIdsMap,
          game: aSeries.game,
          tournament: aSeries.tournament.id,
          teams: aSeries.teamIds,
          competition: aSeries.tournament.competition,
        })
      );
    }
    return !!recentVodSeries ? Object.values(recentVodSeries).sort((a, b) => b - a) : [];
  }
);

export const getFilteredRecentVodSeries = createArrEqSelector(
  isDataLoaded,
  getRecentVodSeries,
  getGameFilters,
  selectorForFilteringGivenSeries()
);

export const getSortedRecentVodSeries = createArrEqSelector(getFilteredRecentVodSeries, vods => {
  return vods.sort((a, b) => b.trendingScore - a.trendingScore);
});

export const getRecentVodSeriesSortedByFollow = createArrEqSelector(
  getSortedRecentVodSeries,
  getPreferredEntityIdsMap,
  getIsFollowMode,
  getEntitySortedByFollow('series')
);

const getSeriesWithVods = createSelector(getSeries, series =>
  Object.values(series)
    .filter(aSeries => aSeries.hasVod)
    .sort((a, b) => b.end.seconds - a.end.seconds)
);

export const getSearchedFilteredVodsSeries = createArrEqSelector(
  isDataLoaded,
  getSeriesWithVods,
  (_, options) => options,
  (loaded, series, { search }) => {
    if (!loaded) return null;
    if (!series.length) return null;
    if (!search) return series;

    return series.filter(curSeries => {
      const searchedValue = search.toLowerCase();

      const seriesInfoString = curSeries.tournament.title + curSeries.title;
      //Finding Teams names to check them agains the searchedValue.
      const matchedParticipant =
        curSeries.participants && curSeries.participants.find(participant => isPartOfName(participant, searchedValue));
      return seriesInfoString.toLowerCase().indexOf(searchedValue) >= 0 || !!matchedParticipant;
    });
  }
);

export const getSortFilteredVodSeries = createArrEqSelector(
  isDataLoaded,
  getSearchedFilteredVodsSeries,
  getIsFollowMode,
  (_, options) => options,
  (loaded, series, isFollowMode, { games, sort }) => {
    if (!loaded || !series) return null;

    const hasGameFilters = games && !!games.length;

    if (isFollowMode && !hasGameFilters) {
      return [];
    }

    if (hasGameFilters || [sortOption.FEATURED, sortOption.FAVORITES].includes(sort)) {
      return series.filter(aSeries => {
        if (hasGameFilters && !games.includes(`${aSeries.game}`)) {
          return false;
        }
        if ([sortOption.FEATURED, sortOption.FAVORITES].includes(sort)) {
          return !!aSeries.trendingScore;
        }
        return true;
      });
    }
    return series;
  }
);

export const getVodScreenSeries = createArrEqSelector(
  isDataLoaded,
  getSortFilteredVodSeries,
  (_, options) => options,
  (loaded, series, { sort }) => {
    if (!loaded || !series) return null;

    switch (sort) {
      case sortOption.FEATURED:
      case sortOption.FAVORITES:
        return [...series].sort((a, b) => b.trendingScore - a.trendingScore);
      case sortOption.VIEWS:
        return [...series].sort((a, b) => (a.vod.viewerCount > b.vod.viewerCount ? -1 : 1));

      case sortOption.RECENT:
      default:
        return series;
    }
  }
);
