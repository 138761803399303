import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { BodyText } from '../common';
import RelationCard, { TopContent } from '../common/RelationCard';
import ParticipantImage from '../Images/ParticipantImage';

const useStyles = makeStyles(theme => ({
  playerCard: {
    margin: 16,
    height: 80,
  },
  textRow: {
    marginBottom: 4,
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 145,
    fontSize: 15,
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {
      width: 190,
    },
  },
  playerName: {
    fontSize: 13,
    lineHeight: '16px',
  },
  bottomContent: {
    height: 0,
  },
  gameTag: {
    fontSize: 10,
    lineHeight: 'inherit',
    marginRight: 11,
  },
  participantImage: {
    filter: 'drop-shadow(0 0 1px white)',
    height: 56,
    width: 56,
    fontSize: 32,
  },
}));

const PlayerCard = ({ player }) => {
  const classes = useStyles();
  return (
    <RelationCard
      className={classes.playerCard}
      disabled
      ImageComponent={({ className }) => (
        <ParticipantImage className={`${className} ${classes.participantImage}`} participant={player} />
      )}
      url="/"
    >
      <TopContent>
        <BodyText className={`${classes.title} ${classes.textRow}`} el="div" variant="largeBold">
          {player.nickName}
        </BodyText>
        <BodyText className={classes.playerName} el="div" variant="semiBold">
          {(player.firstName || player.lastName) && `${player.firstName} ${player.lastName}`}
        </BodyText>
      </TopContent>
    </RelationCard>
  );
};

export default PlayerCard;
