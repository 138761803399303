import {
  RESET_UI_STATES,
  TOGGLE_CALENDAR_GAME_FILTER,
  SET_CALENDAR_GAME_ID_TO_URL,
  SET_CALENDAR_GAME_FILTER,
  TOGGLE_GAME_FILTERS,
  SET_CALENDAR_FILTER,
  SET_CLOSED_BANNER_ID,
  SET_MIXPANEL_LOADED,
  TOGGLE_SCHEDULE_VIEW,
  SET_COOKIE_CONSENT,
  SET_RECURRENT_USER,
  SET_ADVOCATE_REF,
  SET_DARK_MODE,
  SET_USER_TRACKED_AT,
  SET_VOD_FILTERS,
  SET_ARTICLE_FILTERS,
  SET_SINGLE_PLAYER_SERIES_ID,
  SET_VISITOR_UUID,
  SET_SELECTED_STREAM_MAP,
  CLEAN_STREAMS_MAP,
  TOGGLE_BROWSE_DRAWER,
  TOGGLE_VIDEO_SWATCH_VIEW,
  TOGGLE_SHOW_TOP_EVENTS_ON_VIDEO_SWATCH,
  SET_FOLLOW_MODE,
  ADD_STATIC_BACKGROUND_CONTAINER,
  REMOVE_STATIC_BACKGROUND_CONTAINER,
} from '../actions/uiStates';
import toggleInArray from '../../utils/toggleInArray';
import { getNewUUID } from '../../utils/getNewUUID';

export const CALENDAR_FILTERS = {
  ALL: 'ALL',
  FEATURED: 'FEATURED',
  FAVORITES: 'FOLLOWED',
};

export const CALENDAR_LINK_FILTERS = {
  WEEK: 'WEEK',
  DAY: 'DAY',
  ALL: 'ALL',
};

export const initialState = {
  closedBannerIds: {},
  isFirstTimeVisitor: true,
  gameFilters: [],
  calendarIsMatchView: false,
  calendarSelectedGames: [],
  calendarFilter: CALENDAR_FILTERS.FEATURED, // If default changes to or can be set as FAVORITES, make sure calendarSelectedGames also gets set properly on page load.
  mixpanelLoaded: false,
  visitorUUID: getNewUUID(),
  cookieConsent: false,
  advocateRef: null, // Used to track signUp conversions.
  isBrowseDrawerOpen: false,
  isDarkMode: true, // Default new users to DarkMode.
  vodGames: [],
  vodSearch: null,
  vodSort: null,
  articleSearch: null,
  userTrackedAt: null,
  selectedStreamMap: {},
  singlePlayerSeriesId: null,
  streamDropdownChangedAt: new Date(),
  isSinglePlayerView: true,
  showTopEventsOnVideoSwatch: false,
  isFollowMode: false,
  staticBackgroundElements: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_UI_STATES:
      return {
        ...initialState,
        // Set new values on logout.
        visitorUUID: getNewUUID(),
        // Add things that should not be reset on logout here.
        mixpanelLoaded: state.mixpanelLoaded,
        cookieConsent: state.cookieConsent,
        advocateRef: state.advocateRef,
        isDarkMode: state.isDarkMode,
        userTrackedAt: state.userTrackedAt,
      };

    case TOGGLE_CALENDAR_GAME_FILTER: {
      const { gameId } = action;
      let calendarSelectedGames = state.calendarSelectedGames.slice();
      if (!gameId) {
        calendarSelectedGames = [];
      } else if (calendarSelectedGames.includes(gameId)) {
        calendarSelectedGames = calendarSelectedGames.filter(game => game !== gameId);
      } else {
        calendarSelectedGames = [...calendarSelectedGames, gameId];
      }

      if (state.calendarFilter === CALENDAR_FILTERS.FAVORITES) {
        // Change filter to "All Events" when selecting game when favorites filter is selected.
        return { ...state, calendarSelectedGames, calendarFilter: CALENDAR_FILTERS.ALL };
      }

      return { ...state, calendarSelectedGames };
    }

    case SET_CLOSED_BANNER_ID: {
      return {
        ...state,
        closedBannerIds: {
          ...state.closedBannerIds,
          [action.bannerId]: true,
        },
      };
    }

    case SET_CALENDAR_GAME_ID_TO_URL: {
      return { ...state, calendarSelectedGames: action.gameIds };
    }

    case SET_CALENDAR_GAME_FILTER: {
      return { ...state, calendarSelectedGames: [action.gameId] };
    }

    case TOGGLE_GAME_FILTERS: {
      if (!action.id) {
        return { ...state, gameFilters: [] };
      }
      // TODO: allow multi-filter in the future by actually toggling the value in the array
      return { ...state, gameFilters: state.gameFilters.includes(action.id) ? [] : [action.id] };
    }

    case SET_CALENDAR_FILTER: {
      const { filter } = action;

      if (state.isFollowMode) {
        return { ...state, calendarFilter: filter, calendarSelectedGames: action.preferredGameIds };
      }

      return { ...state, calendarFilter: filter, calendarSelectedGames: [] };
    }

    case SET_MIXPANEL_LOADED: {
      return {
        ...state,
        mixpanelLoaded: true,
      };
    }
    case TOGGLE_SCHEDULE_VIEW: {
      return {
        ...state,
        calendarIsMatchView: action.scheduleView,
      };
    }
    case SET_COOKIE_CONSENT: {
      return {
        ...state,
        cookieConsent: true,
      };
    }
    case SET_RECURRENT_USER: {
      return {
        ...state,
        isFirstTimeVisitor: false,
      };
    }
    case SET_ADVOCATE_REF: {
      return {
        ...state,
        advocateRef: action.ref,
      };
    }
    case SET_DARK_MODE: {
      return {
        ...state,
        isDarkMode: action.isDarkMode,
      };
    }
    case SET_USER_TRACKED_AT: {
      return {
        ...state,
        userTrackedAt: new Date(),
      };
    }
    case SET_VISITOR_UUID: {
      return {
        ...state,
        visitorUUID: action.visitorUUID,
      };
    }
    case SET_VOD_FILTERS: {
      // Set gameIds if provided, otherwise toggle gameId, or return default.
      const vodGames =
        action.gameIds !== undefined
          ? action.gameIds || [] // Sets gameIds if provided
          : action.toggleGameId !== undefined
          ? (action.toggleGameId && toggleInArray(state.vodGames, action.toggleGameId)) || [] // Toggle gameId if provided or clear for ALL GAMES if null.
          : state.vodGames; // Otherwise keep it as is if neither gameId or gameIds provided.

      return {
        ...state,
        vodGames,
        ...(action.search !== undefined ? { vodSearch: action.search } : {}),
        ...(action.sort !== undefined ? { vodSort: action.sort } : {}),
      };
    }
    case SET_ARTICLE_FILTERS: {
      return {
        ...state,
        ...(action.search !== undefined ? { articleSearch: action.search } : {}),
      };
    }
    case SET_SINGLE_PLAYER_SERIES_ID: {
      return { ...state, singlePlayerSeriesId: action.seriesId };
    }
    case SET_SELECTED_STREAM_MAP: {
      const { streamId, seriesId } = action;
      if (Boolean(streamId)) {
        return {
          ...state,
          selectedStreamMap: {
            ...state.selectedStreamMap,
            [seriesId]: streamId,
          },
        };
      } else {
        const { [seriesId]: removedStream, ...rest } = state.selectedStreamMap;
        return {
          ...state,
          selectedStreamMap: rest,
        };
      }
    }
    case CLEAN_STREAMS_MAP: {
      return {
        ...state,
        selectedStreamMap: action.streamMap,
      };
    }
    case TOGGLE_BROWSE_DRAWER: {
      const { isBrowseDrawerOpen } = action;
      return {
        ...state,
        isBrowseDrawerOpen: isBrowseDrawerOpen === undefined ? !state.isBrowseDrawerOpen : Boolean(isBrowseDrawerOpen),
      };
    }
    case TOGGLE_VIDEO_SWATCH_VIEW: {
      return {
        ...state,
        isSinglePlayerView: action.isSinglePlayerView,
      };
    }
    case TOGGLE_SHOW_TOP_EVENTS_ON_VIDEO_SWATCH: {
      return {
        ...state,
        showTopEventsOnVideoSwatch: action.showTopEventsOnVideoSwatch,
      };
    }
    case SET_FOLLOW_MODE: {
      return {
        ...state,
        isFollowMode: action.isFollowMode,
      };
    }
    case ADD_STATIC_BACKGROUND_CONTAINER: {
      // If staticBackgroundElements is 0 BEFORE ADDING THE NEW CONTAINER,
      // this is the first element shown, we need to prevent the content from scrolling
      if (!state.staticBackgroundElements) {
        document.body.style.overflow = 'hidden';
      }
      return { ...state, staticBackgroundElements: state.staticBackgroundElements + 1 };
    }
    case REMOVE_STATIC_BACKGROUND_CONTAINER: {
      const staticElements = Boolean(state.staticBackgroundElements) ? state.staticBackgroundElements - 1 : 0;
      // If staticElements is 0 AFTER SUBSTRACTING THE REMOVED CONTAINER,
      // this is the last element shown, we need to allow content scrolling
      if (!staticElements) {
        document.body.style.overflow = 'auto';
      }
      return { ...state, staticBackgroundElements: staticElements };
    }
    default:
      return state;
  }
};
