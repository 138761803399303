import React from 'react';
import { withStyles } from '@material-ui/styles';
import ItemStyles from './ItemStyles';
import ParticipantImage from '../Images/ParticipantImage';

const styles = () => ({
  ...ItemStyles,
});

const ItemParticipants = ({ classes, className, participants }) => {
  const isParticipantNameTBD = !participants[0].shortName || !participants[1].shortName;

  return (
    <div className={`${className} ${classes.participantsContainer}`}>
      {participants.length === 2 &&
        (isParticipantNameTBD ? null : (
          <>
            <div className={classes.participant}>
              <span className={`${classes.team} left`}>{participants[0].shortName}</span>
              <ParticipantImage participant={participants[0]} />
            </div>
            <span className={classes.vsText}>VS</span>
            <div className={classes.participant}>
              <ParticipantImage participant={participants[1]} />
              <span className={`${classes.team} right`}>{participants[1].shortName}</span>
            </div>
          </>
        ))}
      {participants.length > 2 && (
        <span className={classes.moreThanTwoTeams}>
          {participants
            .reduce((acc, participant) => (!participant.shortName ? acc : [...acc, participant.shortName]), [])
            .join(', ')}
        </span>
      )}
    </div>
  );
};

export default withStyles(styles)(ItemParticipants);
