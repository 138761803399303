import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';

import { TrackSpan } from '../common';

const styles = theme => ({
  root: {
    paddingBottom: 20,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
  },
  toggleButton: {
    background: theme.darkPink,
    fontSize: 18,
    fontWeight: 'bold',
    marginRight: 10,
    lineHeight: '18px',
    height: 20,
    width: 20,
    color: 'white',
    textAlign: 'center',
    cursor: 'pointer',
  },
  titleText: {
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    '& span': {
      fontWeight: 400,
    },
  },
  childrenContainer: {
    overflow: 'hidden',
    transition: 'height 180ms ease-in-out',
    height: 'auto',
    '&.collapseByDefault': {
      height: 0,
      paddingBottom: 'unset',
    },
    '&.expanded': {
      overflow: 'visible',
    },
  },
});

// Needs Javascript for animation because it's not possible to CSS-animate with height:auto.
// See: https://css-tricks.com/using-css-transitions-auto-dimensions/
class CollapsibleSection extends Component {
  state = { expanded: !!this.props.collapseByDefault ? false : true };
  childrenContainer = React.createRef();

  animateExpand() {
    const element = this.childrenContainer.current;
    // scrollHeight will give the supposed height of the content, regardless of actual visible height.
    element.style.height = `${element.scrollHeight}px`;
  }

  animateCollapse() {
    const element = this.childrenContainer.current;
    // First set the height to an actual pixel number, so it can be animated to 0
    // scrollHeight will give the supposed height of the content, regardless of actual visible height.
    element.style.height = `${element.scrollHeight}px`;
    // Animate to 0 on next cycle, using the CSS transition rules already specified above.
    setTimeout(() => {
      this.childrenContainer.current.style.height = 0;
    });
  }

  handleToggle = () => {
    const { expanded } = this.state;
    expanded ? this.animateCollapse() : this.animateExpand();
    this.setState({ expanded: !expanded });
  };

  render() {
    const { classes, title, date, children, className, contentClassName, itemsCount } = this.props;
    const { expanded } = this.state;
    const { collapseByDefault } = this.props;

    const track = {
      event: `Schedule Section Toggled`,
      eventProperties: { title, date, items_count: itemsCount, collapsed_by_default: collapseByDefault },
      source: 'collapsible-section',
      target: expanded ? 'collapsed' : 'expanded',
    };

    return (
      <div id={title} className={`${classes.root} ${className}`}>
        <div className={classes.titleContainer}>
          <TrackSpan className={classes.toggleButton} onClick={this.handleToggle} track={track}>
            {expanded ? '-' : '+'}
          </TrackSpan>
          <TrackSpan className={classes.titleText} onClick={this.handleToggle} track={track}>
            {title || '...'}
            {!!date ? (
              <>
                {' '}
                – <span>{date}</span>
              </>
            ) : (
              ''
            )}
            {!expanded && itemsCount > 0 && ` (${itemsCount})`}
          </TrackSpan>
        </div>
        <div
          className={classnames({
            [classes.childrenContainer]: true,
            [contentClassName]: true,
            collapseByDefault,
            expanded,
          })}
          ref={this.childrenContainer}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CollapsibleSection);
