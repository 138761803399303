import { LOGIN_DIALOG_VIEWS } from '../reducers/dialogs';

export const CLEAR_ON_LOGIN_SUCCESS = 'CLEAR_ON_LOGIN_SUCCESS';
export const CLOSE_LOGIN_DIALOG = 'CLOSE_LOGIN_DIALOG';
export const OPEN_LOGIN_DIALOG = 'OPEN_LOGIN_DIALOG';
export const SET_LOGIN_DIALOG_VIEW = 'SET_LOGIN_DIALOG_VIEW';
export const SET_SIGNUP_PROMPTED_AT = 'SET_SIGNUP_PROMPTED_AT';
export const CLOSE_NEW_FEATURE_DIALOG = 'CLOSE_NEW_FEATURE_DIALOG';
export const SWITCH_ROTATING_DIALOG_OFF = 'SWITCH_ROTATING_DIALOG_OFF';
export const TOGGLE_SHOULD_REPEAT_DIALOG = 'TOGGLE_SHOULD_REPEAT_DIALOG';

export const clearOnLoginSuccess = () => ({
  type: CLEAR_ON_LOGIN_SUCCESS,
});

export const closeLoginDialog = () => ({
  type: CLOSE_LOGIN_DIALOG,
});

export const openLoginDialog = ({
  source,
  onLoginSuccess = undefined,
  view = LOGIN_DIALOG_VIEWS.AUTH,
  context = {},
}) => ({
  type: OPEN_LOGIN_DIALOG,
  onLoginSuccess,
  view,
  source,
  context,
  trackEvent: {
    event: 'Sign Up Prompted',
    properties: { source, view },
  },
});

export const setLoginDialogView = view => ({
  type: SET_LOGIN_DIALOG_VIEW,
  view,
});

export const setSignupPromptedAt = () => ({
  type: SET_SIGNUP_PROMPTED_AT,
});

export const switchRotatingDialogOff = key => ({
  type: SWITCH_ROTATING_DIALOG_OFF,
  key,
});

export const toggleShouldRepeatDialog = () => ({
  type: TOGGLE_SHOULD_REPEAT_DIALOG,
});
