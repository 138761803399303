import classnames from 'classnames';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

import { BodyText } from '../common';

const useStyles = makeStyles(theme => ({
  followSection: {
    backgroundColor: theme.palette.background.quinary,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 8,
    padding: '10px 16px',
  },
  clickable: {
    cursor: 'pointer',
  },
  collapsed: {
    display: 'none',
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  collapseButton: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.nonary,
    border: `1px solid ${theme.palette.border.nonary}`,
    borderRadius: 4,
    boxSizing: 'border-box',
    color: theme.palette.text.primary,
    display: 'flex',
    height: 24,
    justifyContent: 'center',
    padding: 4,
    width: 24,
  },
  content: {
    margin: '9px -16px -10px -16px',
    borderTop: `1px solid ${theme.palette.border.septenary}`,
    padding: '16px 32px 24px 32px',
  },
}));

const CollapsibleSection = ({ children, title, subtitle, className }) => {
  const classes = useStyles();
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <div className={`${classes.followSection} ${className}`}>
      <div className={classes.clickable} onClick={() => setIsCollapsed(!isCollapsed)}>
        <div className={classes.row}>
          <BodyText variant="semiBold">{title}</BodyText>
          <div className={classes.collapseButton}>{isCollapsed ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}</div>
        </div>
        <BodyText variant="subtext">{subtitle}</BodyText>
      </div>
      <div
        className={classnames({
          [classes.content]: true,
          [classes.collapsed]: isCollapsed,
          content: true, // Used as target from the parent.
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default CollapsibleSection;
