import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ShimmerDiv } from '../common';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    alignItems: 'flex-end',
    backgroundColor: theme.palette.background.tertiary,
    borderRadius: 4,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 8,
    padding: '16px 24px 24px 22px',
    textDecoration: 'initial',
    width: '100%',
  },
  shimmer: {
    borderRadius: 4,
    height: 21,
    marginBottom: 8,
    width: '100%',
  },
  bottom: {
    height: 14,
    marginTop: 8,
    marginBottom: 0,
  },
}));

const ArticlePlaceholder = ({ classes }) => (
  <div className={classes.card}>
    <ShimmerDiv className={classes.shimmer} />
    <ShimmerDiv className={`${classes.shimmer} ${classes.bottom}`} />
    <ShimmerDiv className={`${classes.shimmer} ${classes.bottom}`} />
  </div>
);

export default () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ArticlePlaceholder classes={classes} />
      <ArticlePlaceholder classes={classes} />
      <ArticlePlaceholder classes={classes} />
      <ArticlePlaceholder classes={classes} />
      <ArticlePlaceholder classes={classes} />
    </div>
  );
};
