import React from 'react';

/**
 * @param {React.ref} ref React ref to container in which you care if click happened outside of.
 * @param {function} handler Function to be called if click outside happened.
 */
export const useOnClickOutside = (ref, handler) => {
  React.useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};
