import classNames from 'classnames';
import React from 'react';
import { withStyles } from '@material-ui/styles';

import { TrackHashLink } from '../common';

export const LABELS = {
  BRACKETS_STANDINGS: 'Brackets / Standings',
  OVERVIEW: 'Overview',
  SCHEDULE: 'Schedule',
  TOURNAMENTS: 'Tournaments',
  VODS: 'VODs',
  TEAMS: 'Teams',
  PLAYERS: 'Players',
  NEWS: 'News',
  TOP_MATCHES_VODS: 'VoDs',
};

export const SLUGS = {
  [LABELS.BRACKETS_STANDINGS]: 'standings',
  [LABELS.OVERVIEW]: 'overview',
  [LABELS.SCHEDULE]: 'schedule',
  [LABELS.TOURNAMENTS]: 'tournaments',
  [LABELS.VODS]: 'vods',
  [LABELS.TEAMS]: 'teams',
  [LABELS.PLAYERS]: 'players',
  [LABELS.NEWS]: 'news',
  [LABELS.TOP_MATCHES_VODS]: 'VoDs',
};

const styles = theme => ({
  // TODO: currently just styling the hardcoded single tab. Restyle after adding real tabs.
  tabs: {
    lineHeight: '24px',
    letterSpacing: '-0.5px',
    fontWeight: 600,
    fontSize: 20,
    padding: 8,
    textDecoration: 'none',
    color: theme.darkGrey,
    '&:not(:last-child)': {
      marginRight: 24,
    },
  },
  selected: {
    color: theme.palette.text.primary,
    borderBottom: `3px solid ${theme.lightTeal}`,
  },
  [theme.breakpoints.down('sm')]: {
    tabs: {
      fontSize: 12,
    },
  },
  // Prevents row overflowing on iPhone SE
  '@media (max-width: 320px)': {
    tabs: {
      '&:not(:last-child)': {
        marginRight: 16,
      },
    },
  },
});

/**
 * Links to an ID that's same as the label, except lower case and _ instead of \s.
 */
const EventHeaderTab = ({ classes, onClick, label, location: { pathname, hash } = {} }) => {
  const selected = `#${!SLUGS[label] ? encodeURI(label) : SLUGS[label]}` === hash;

  // NOTE: NavHashLink breaks when using withTrackClick HOC, so apply styles.
  return (
    <TrackHashLink
      smooth
      to={`${pathname}#${!SLUGS[label] ? label : SLUGS[label]}`}
      onClick={onClick}
      className={classNames({ [classes.tabs]: true, [classes.selected]: selected })}
      track={{
        event: 'Event Header Tab Clicked',
        eventProperties: { label, slug: SLUGS[label] },
        source: 'event-page',
        target: `#${SLUGS[label]}`,
      }}
    >
      {label}
    </TrackHashLink>
  );
};

export default withStyles(styles)(EventHeaderTab);
