import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useInterval, useFirestoreSubscribe } from '../../hooks';
import PromptCard from './PromptCard';
import { isSignedIn, getUser } from '../../store/selectors/user';

// Need to know which ones should be considered as active based on the start time.
// Also filtering out the prompts that were already over before the user even saw it.
// Ended prompts that user voted on still need to be visible so the users can see the results revealed.
const shouldPromptBeVisible = ({ prompt, entries }) => {
  const isPromptStarted = !!prompt.start && prompt.start.toDate() < new Date();
  const isPromptActiveOnMount = !prompt.end || prompt.end.toDate() > new Date();

  const hasUnseenResultEntry = !!entries.find(entry => entry.prompt.id === prompt.id && !entry.isResultSeen);
  return isPromptStarted && (isPromptActiveOnMount || hasUnseenResultEntry);
};

const Contests = ({ series, user, isSignedIn }) => {
  const [visiblePrompts, setVisiblePrompts] = useState([]);
  const [prompts] = useFirestoreSubscribe('contestPrompts', [
    ['seriesId', '==', series.id],
    ['type', 'in', ['freeForm', 'pickem']],
  ]);

  const [entries] = useFirestoreSubscribe('contestUserEntries', [
    ['prompt.seriesId', '==', series.id],
    ['uid', '==', isSignedIn ? user.uid : null], // putting the conditional here instead of at the `entries` assignment level, because react hooks can't be called conditionally
  ]);

  const sortedPrompts = prompts.sort((a, b) => (a.start?.seconds || 0) - (b.start?.seconds || 0));
  useInterval(() => {
    const filteredPrompts = sortedPrompts.filter(prompt =>
      shouldPromptBeVisible({
        prompt,
        entries,
      })
    );

    setVisiblePrompts(filteredPrompts.slice(0, 2)); // Slice makes sure a maximum of two Questions populate the UI.
  }, 1000);

  const promptCount = visiblePrompts.length;

  return (
    <div>
      {visiblePrompts.map(prompt => (
        <PromptCard
          key={prompt.id}
          prompt={prompt}
          promptCount={promptCount}
          entry={entries.find(entry => entry.prompt.id === prompt.id)}
        />
      ))}
    </div>
  );
};

export default connect(state => ({
  user: getUser(state),
  isSignedIn: isSignedIn(state),
}))(Contests);
