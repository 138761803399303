import classnames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import FirebaseAuth from 'react-firebaseui/FirebaseAuth';
import { auth } from '../../store/sagas/firebase';
import { trackEvent } from '../../store/actions/uiStates';
import { signUpComplete, signInComplete } from '../../store/actions/user';
import { isFeatureEnabled } from '../../utils/featureFlags';

// TODO: for some reason FirebaseAuth causes uncaught exception in tests, so
// we're stubbing it for now until we find out why.
let AuthComponent;
if (process.env.NODE_ENV === 'test') {
  AuthComponent = () => <div id="firebaseui_container" />;
} else {
  AuthComponent = FirebaseAuth;
}

const styles = theme => ({
  authWrapper: {
    position: 'relative',
    backgroundColor: theme.palette.background.secondary,
    borderRadius: '4px',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12)',
  },
  boxContainer: {
    color: 'white',
    backgroundSize: 'cover',
    justifyContent: 'center',
    width: '400px !important',
    //Will overwrite browser autofill input fill
    '& input:-webkit-autofill': {
      '-webkit-box-shadow': `0 0 0px 1000px ${theme.palette.signInInput.primary} inset`,
      '-webkit-text-fill-color': theme.palette.text.primary,
    },
    '& .firebaseui-id-secondary-link': {
      // forces the UI to show the login form, and not allow the user to go back to the list-of-buttons UI
      display: 'none',
    },
    '& .firebaseui-container': {
      maxWidth: 400,
      boxShadow: 'none',
      backgroundColor: theme.palette.background.secondary,
    },
    '& .firebaseui-form-actions': {
      display: 'flex',
    },
    //Make "Trouble signing in?" link visible
    '& .firebaseui-form-links': {
      '& > a': {
        display: 'unset',
        color: theme.palette.text.primary,
      },
    },
    '& .firebaseui-button': {
      width: '100%',
    },
    '& .firebaseui-id-submit': {
      background: `${theme.darkPink} !important`,
    },
    '& .mdl-textfield__label': {
      color: theme.palette.text.primary,
    },
    '& .mdl-textfield__label::after': {
      backgroundColor: `${theme.lightTeal} !important`,
    },
    '& .firebaseui-link': {
      color: theme.darkPink,
    },
    '& .firebaseui-error': {
      textAlign: 'center',
    },
    '& .firebaseui-label': {
      textAlign: 'center',
      // force the textfield to say "Display name" since we don't have control over HTML
      '&[for=name]': {
        textIndent: -9999,
        lineHeight: 0,
        visibility: 'hidden !important',
        '&:before': {
          content: '"Display name"',
          textIndent: 0,
          display: 'block',
          lineHeight: 'initial',
          visibility: 'visible',
        },
      },
    },
    '& .firebaseui-input': {
      textAlign: 'center',
      color: theme.palette.text.primary,
      background: theme.palette.signInInput.primary,
    },
    '& .firebaseui-input-invalid': {
      color: theme.palette.text.primary,
    },
    '& .firebaseui-id-page-sign-in.mdl-card': {
      minHeight: 150,
    },
    '& .firebaseui-id-page-sign-in .firebaseui-card-header': {
      display: 'none',
    },
    '& .firebaseui-id-page-sign-in .firebaseui-title': {
      display: 'none',
    },
    '& .firebaseui-title, & .firebaseui-subtitle, & .firebaseui-text': {
      color: theme.palette.text.primary,
    },
  },
  signinTextContainer: {
    alignItems: 'center',
    color: theme.palette.text.primary,
    flexDirection: 'column',
    justifyContent: 'center',
    width: '400px !important',
  },
  signinSubtext: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    margin: 'auto',
    maxWidth: '300px',
    padding: '0 24px 24px 24px',
  },
  signinTitle: {
    fontSize: 25,
    fontWeight: 500,
    lineHeight: '24px',
    margin: 0,
    padding: '24px 24px 8px 24px',
    textAlign: 'center',
  },
  '@media (max-width: 700px)': {
    boxContainer: {
      width: '300px !important',
      '& .firebaseui-container': {
        maxWidth: 300,
      },
      '& .firebaseui-form-actions': {
        display: 'flex',
      },
    },
    signinTextContainer: {
      width: '300px !important',
    },
    signinSubtext: {
      fontSize: 13,
      maxWidth: '220px',
    },
    signinTitle: {
      paddingTop: '16px',
    },
  },
  SSOContainer: {
    padding: 30,
  },
  dividerText: {
    margin: '16px 30px',
    borderTop: '1px solid #AAA',
    '& span': {
      color: '#AAA',
      background: theme.palette.background.secondary,
      width: 'max-content',
      transform: 'translateY(-60%)',
      display: 'block',
      padding: '0 16px',
      margin: 'auto',
    },
  },
  SSOButton: {
    width: '80%',
    fontSize: 16,
    margin: 'auto',
    display: 'block',
    padding: 10,
    borderRadius: 4,
    color: theme.palette.text.octal,
    cursor: 'pointer',
    outline: 0,
    border: `1px solid ${theme.palette.border.octal}`,
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    background: theme.palette.signInButton,
    '&:focus': {
      boxShadow: '0px 0px 5px rgba(0,0,0,0.3)',
    },
    '& span': {
      verticalAlign: 'middle',
    },
    '& img, & svg': {
      width: 24,
      height: 24,
      marginLeft: -24,
      marginRight: 12,
      verticalAlign: 'middle',
    },
    '&:not(:last-child)': {
      marginBottom: 12,
    },
  },
});

class LoginBox extends Component {
  // Initialized in false to work well with the shouldComponentUpdate logic.
  state = { showSigninSubtext: false };

  onSignInSuccess = authResult => {
    const { additionalUserInfo } = authResult;
    const { onSignInSuccess, signInComplete, signUpComplete, source } = this.props;
    const providerId = additionalUserInfo.providerId;
    if (additionalUserInfo.isNewUser) {
      // Execute sign up completion tasks.
      signUpComplete({ source, providerId });
    } else {
      // Execute sign in completion tasks.
      signInComplete({ source, providerId });
    }

    if (onSignInSuccess) {
      onSignInSuccess(authResult);
    }
  };

  // Configure FirebaseUI.
  // Can provide `onSignInSuccess` callback in props.
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.trackedSignup = false;
    this.uiConfig = {
      // Popup signin flow rather than redirect flow.
      signInFlow: 'popup',
      credentialHelper: 'none',
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        { provider: auth.EmailAuthProvider.PROVIDER_ID, requireDisplayName: true },
        { provider: auth.GoogleAuthProvider.PROVIDER_ID },
        { provider: auth.TwitterAuthProvider.PROVIDER_ID },
      ],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: this.onSignInSuccess,
      },
    };
  }

  componentDidMount() {
    const { source, trackEvent } = this.props;

    this.observer = new MutationObserver(() => {
      const { showSigninSubtext } = this.state;
      if (document.querySelector('.firebaseui-id-page-password-sign-up')) {
        if (!this.trackedSignup) {
          this.trackedSignup = true;
          trackEvent('Sign Up Started', { source });
        }
      }

      if (document.querySelector('.firebaseui-id-page-sign-in')) {
        if (!showSigninSubtext) this.setState({ showSigninSubtext: true });
      } else if (showSigninSubtext) {
        this.setState({ showSigninSubtext: false });
      }

      // forces the UI to show the login form
      if (document.querySelector('.firebaseui-idp-password')) {
        document.querySelector('.firebaseui-idp-password').click();
      }
    });

    this.observer.observe(this.wrapperRef.current, {
      childList: true,
      subtree: true,
    });
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { classes, className } = this.props;
    const { showSigninSubtext } = this.state;

    return (
      <div
        className={classnames({
          [className]: true,
          [classes.authWrapper]: true,
        })}
        ref={this.wrapperRef}
      >
        {showSigninSubtext && (
          <div className={classes.signinTextContainer}>
            <h1 className={classes.signinTitle}>Sign in or create an account</h1>
            <div className={classes.signinSubtext}>
              Enter the email you'd like to sign in or create an account with.
            </div>
          </div>
        )}
        <AuthComponent className={classes.boxContainer} uiConfig={this.uiConfig} firebaseAuth={auth()} />
        <div className={classes.SSOContainer}>
          <div className={classes.dividerText}>
            <span>or sign in with</span>
          </div>
          <button
            className={classes.SSOButton}
            onClick={() => {
              trackEvent('SSO Started', { providerId: 'google.com' });
              auth().signInWithPopup(new auth.GoogleAuthProvider()).then(this.onSignInSuccess);
            }}
          >
            <img src="/icons/googleLogo.png" alt="" />
            <span>Google</span>
          </button>
          {isFeatureEnabled('twitterSSO') && (
            <button
              className={classes.SSOButton}
              onClick={() => {
                trackEvent('SSO Started', { providerId: 'twitter.com' });
                auth().signInWithPopup(new auth.TwitterAuthProvider()).then(this.onSignInSuccess);
              }}
            >
              <img src="/icons/twitterLogo_blue.svg" alt="" />
              <span>Twitter</span>
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default compose(withStyles(styles), connect(null, { signInComplete, signUpComplete, trackEvent }))(LoginBox);
