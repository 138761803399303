import React, { useCallback, useState, createRef, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { MdClose } from 'react-icons/md';

import GamesContent from './GamesContent';
import OrganizationsContent from './OrganizationsContent';
import SearchResults from './SearchResults';
import { Drawer, H1, NavTab, SearchBox } from '../../common';
import { TOP_TICKER_HEIGHT, TOP_NAV_HEIGHT } from '../../../theme/oldTheme';
import { vh2px } from '../../../utils/dimensions';
import { useWindowSize, useMediaQuery } from '../../../hooks';
import { toggleBrowseDrawer } from '../../../store/actions/uiStates';
import { getIsTopTickerVisible } from '../../../store/selectors/upcomingSeries';

const TABS = {
  GAMES: 'games',
  TEAMSORGS: 'teamsorgs',
};

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '528px',
  },
  content: {
    padding: '32px 64px',
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.border.soloDenary}`,
    boxSizing: 'border-box',
    height: 41, // 1px shorter than NavTab
    marginBottom: 16,
  },
  tab: {
    '&:not(:last-child)': {
      marginRight: 26,
    },
  },
  searchBar: {
    color: 'white',
    marginBottom: 24,
  },
  searchBox: {
    marginBottom: 24,
    width: '100%',
  },
  mobileCloseButon: {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    drawer: {
      width: '528px',
      height: `calc(100% - ${theme.topNavHeight}px)`,
    },
    content: {
      padding: '24px 48px',
    },
  },
  [theme.breakpoints.down('xs')]: {
    drawer: {
      width: '100%',
    },
    content: {
      padding: '12px 28px',
    },
    h1: {
      fontSize: '30px !important',
      lineHeight: '36px',
    },
    mobileCloseButon: {
      display: 'block',
      position: 'absolute',
      right: 15,
      top: 2,
      fill: theme.palette.text.primary,
      fontSize: '24px',
    },
  },
}));

const Content = ({ activeTab, results }) => {
  if (results?.length) {
    return <SearchResults results={results} />;
  } else if (activeTab === TABS.GAMES) {
    return <GamesContent />;
  } else if (activeTab === TABS.TEAMSORGS) {
    return <OrganizationsContent />;
  }
};

const BrowseDrawer = () => {
  const dispatch = useDispatch();
  const matchesMobile = useMediaQuery('(max-width:600px)'); // XSM Breakpoint
  const isOpen = useSelector(state => state.uiStates.isBrowseDrawerOpen);
  const classes = useStyles({ isOpen });
  const [searchResults, setSearchResults] = useState([]);
  const [activeTab, setActiveTab] = useState(TABS.GAMES);
  const resetFuncRef = useRef();
  useWindowSize(); // This forces rerender when window changes size.

  // Calculate dynamic height based on window and top ticker state.
  const isTopTickerVisible = useSelector(getIsTopTickerVisible);
  const topTickerHeight = isTopTickerVisible ? TOP_TICKER_HEIGHT : 0;
  const height = matchesMobile ? '100%' : vh2px(1) - topTickerHeight - TOP_NAV_HEIGHT; // TopTicker + TopNav height in px

  const handleClickGames = useCallback(() => setActiveTab(TABS.GAMES), [setActiveTab]);
  const handleClickTeamsOrgs = useCallback(() => setActiveTab(TABS.TEAMSORGS), [setActiveTab]);

  const browseEl = createRef();

  // Assigning the resetFuncRef to the reset function inside of SearchBox
  const resetSearchBox = useCallback(e => (resetFuncRef.current = e), []);

  const toggleDrawer = useCallback(() => {
    dispatch(toggleBrowseDrawer());
  }, [dispatch]);

  const handleAnyClick = useCallback(
    e => {
      const { id } = e.target;
      //Check if the Browse link was clicked
      const isBrowseLinkClicked = id === 'browseTab';
      //Check if the OrganizationFollowing Dialog was clicked
      const followDialog = document.querySelector('#multi-follow-dialog');
      const isClickedOutsideBrowseTabAndFollowDialog =
        e.type === 'click' &&
        Boolean(browseEl.current || followDialog) &&
        !browseEl.current.contains(e.target) &&
        !followDialog?.contains(e.target);

      const isEscKeyPress = e.keyCode === 27;

      const shouldCloseDrawer = isOpen && (isClickedOutsideBrowseTabAndFollowDialog || isEscKeyPress);

      if (shouldCloseDrawer || isBrowseLinkClicked) {
        toggleDrawer();
        resetFuncRef.current && resetFuncRef.current();
      }
    },
    [toggleDrawer, isOpen, browseEl]
  );

  useEffect(() => {
    document.body.addEventListener('click', handleAnyClick);
    window.addEventListener('keydown', handleAnyClick);

    return () => {
      document.body.removeEventListener('click', handleAnyClick);
      window.removeEventListener('keydown', handleAnyClick);
    };
  }, [handleAnyClick]);

  return (
    <Drawer ref={browseEl} className={classes.drawer} isOpen={isOpen} height={height}>
      <MdClose className={classes.mobileCloseButon} onClick={toggleDrawer} />
      <div className={classes.content}>
        <H1 className={classes.h1}>Find Your Esports</H1>
        <SearchBox
          className={classes.searchBox}
          setSearchResults={setSearchResults}
          track={{ event: 'Browse Search Queried', source: 'browse-drawer' }}
          exposeResetCb={resetSearchBox}
        />

        {!searchResults?.length && (
          <div className={classes.tabs}>
            <NavTab className={classes.tab} isActive={activeTab === TABS.GAMES} onClick={handleClickGames}>
              GAMES
            </NavTab>
            <NavTab className={classes.tab} isActive={activeTab === TABS.TEAMSORGS} onClick={handleClickTeamsOrgs}>
              TEAMS + ORGS
            </NavTab>
          </div>
        )}
        <Content activeTab={activeTab} results={searchResults} />
      </div>
    </Drawer>
  );
};

export default BrowseDrawer;
