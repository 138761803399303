import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';

import { Dialog } from '../Dialogs';
import { getStandingsById, getSeriesById } from '../../store/selectors/gameData';
import CustomScrollBars from './CustomScrollBars';
import BracketRenderer from './BracketRenderer';
import StandingsTable from './StandingsTable';

const styles = theme => ({
  dialog: {
    background: 'black',
    borderRadius: 8,
    border: '1px solid grey',
    color: 'white',
    position: 'fixed',
  },
  stagesContainer: {
    display: 'flex',
    marginTop: 15,
    marginBottom: 15,
  },
  buttonsContainer: {
    paddingLeft: 25,
    '& h2': {
      fontWeight: 600,
      margin: 0,
      marginBottom: 10,
    },
  },
  selectorButton: {
    background: 'transparent',
    color: 'lightgrey',
    cursor: 'pointer',
    outline: 'none',
    fontSize: 17,
    fontWeight: 600,
    padding: 5,
    borderRadius: 5,
    marginRight: 10,
    '&.active': {
      background: theme.darkTeal,
      color: 'white',
    },
  },
  standingsContentContainer: {
    width: '80vw',
    height: '70vh',
    margin: 'auto',
    '& h2': {
      textAlign: 'center',
    },
  },
  [theme.breakpoints.down('sm')]: {
    dialog: {
      transform: 'scale(0.7)',
      left: 0,
      top: 0,
    },
    stagesContainer: {
      display: 'unset',
    },
    selectorButton: {
      marginBottom: 10,
      fontSize: 16,
    },
  },
  '@media (max-width: 320px)': {
    dialog: {
      transform: 'scale(0.6)',
      left: 0,
      top: 0,
    },
    selectorButton: {
      fontSize: 15,
    },
  },
});

class StandingsDialog extends Component {
  state = { selectedStage: false };
  //Get the selected Stage
  getSelectedStage = () => {
    const {
      series,
      standings: { stages },
    } = this.props;

    if (!stages || !series || !series.substage) {
      return { stageIndex: null, substageIndex: null };
    }

    if (series.substage) {
      let substageIndex;
      const stageIndex = stages.findIndex(stage => {
        substageIndex = stage.substages.findIndex(substage => substage.id === series.substage);
        return substageIndex !== -1;
      });

      return {
        stageIndex: stageIndex > -1 ? `${stageIndex}` : false,
        substageIndex: substageIndex > -1 ? `${substageIndex}` : false,
      };
    }
  };

  render() {
    const { standings, classes, onCloseClick, open } = this.props;

    const currentSeriesStage = this.getSelectedStage();

    // Setting all selected state flags to strings to prevent verifying state against 0.
    const selectedStage = `${this.state.selectedStage ||
      currentSeriesStage.stageIndex ||
      (!!standings.stages && standings.stages.length - 1)}`;
    const substages = standings.stages && standings.stages[selectedStage] && standings.stages[selectedStage].substages;
    const selectedSubstage = `${this.state.selectedSubstage ||
      currentSeriesStage.substageIndex ||
      (substages && substages.length - 1)}`;

    // TODO: figure out how to do spoiler-free standings (is that even a thing?)

    return open ? (
      <Dialog className={classes.dialog} onCloseClick={onCloseClick}>
        {this.renderButtons(selectedStage, selectedSubstage)}
        <CustomScrollBars>
          <div className={classes.standingsContentContainer}>
            {this.renderSelectedStandings(selectedStage, selectedSubstage)}
          </div>
        </CustomScrollBars>
      </Dialog>
    ) : null;
  }

  renderButtons(selectedStage, selectedSubstage) {
    const {
      classes,
      standings: { stages },
    } = this.props;

    return (
      <div className={classes.stagesContainer}>
        {!!stages.length && !!stages[selectedStage].substages.length && (
          <div className={classes.buttonsContainer}>
            <h2>STAGE{stages[selectedStage].substages.length > 1 ? 'S' : ''}</h2>
            {stages[selectedStage].substages.map((substage, idx) => (
              <button
                key={idx}
                className={classnames({
                  [classes.selectorButton]: true,
                  active: selectedSubstage === `${idx}`,
                })}
                // Setting all selected state flags to strings to prevent verifying state against 0.
                onClick={() => this.setState({ selectedSubstage: `${idx}` })}
              >
                {substage.title}
              </button>
            ))}
          </div>
        )}
        {!!stages.length && (
          <div className={classes.buttonsContainer}>
            <h2>PHASE</h2>
            {stages.map((stage, idx) => (
              <button
                key={idx}
                className={classnames({
                  [classes.selectorButton]: true,
                  active: selectedStage === `${idx}`,
                })}
                onClick={() =>
                  // Setting all selected state flags to strings to prevent verifying state against 0.
                  this.setState({ selectedStage: `${idx}`, selectedSubstage: `${stages[idx].substages.length - 1}` })
                }
              >
                {stage.title}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  }

  renderSelectedStandings(selectedStage, selectedSubstage) {
    const {
      currentSeries,
      spoilerFree,
      standings: { stages },
    } = this.props;

    if (!stages.length) {
      return <h2>No results yet</h2>;
    }

    const selectedObj = stages[selectedStage].substages[selectedSubstage];

    if (!selectedObj) {
      return <h2>No results yet</h2>;
    }

    switch (selectedObj.type) {
      case 'bracket':
        return <BracketRenderer bracketId={selectedObj.id} currentSeries={currentSeries} spoilerFree={spoilerFree} />;
      case 'standing':
        return <StandingsTable standings={selectedObj.standings} />;
      default:
        // Should not reach? Maybe if data from Abios is corrupted...
        console.error(
          `standings for series ${currentSeries} ${stages[selectedStage].title}-${stages[selectedStage].substages[selectedSubstage]} has no bracket or standings`
        );

        return null;
    }
  }
}

export default compose(
  withStyles(styles),
  connect((state, ownProps) => ({
    standings: getStandingsById(state, ownProps.standingsId),
    series: getSeriesById(state, ownProps.currentSeries),
  }))
)(StandingsDialog);
