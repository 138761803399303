import React from 'react';
import { makeStyles } from '@material-ui/styles';

import Header from './Header';
import ParticipantRow from './ParticipantRow';
import { TrackLink, BodyText, H2 } from '../../common';
import { getSeriesUrl } from '../../../utils/urls';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: '3px 16px',
    borderRight: `1px solid ${theme.mediumCharcoalGrey}`,
    boxSizing: 'border-box',
    position: 'relative',
  },
  hoverOverlay: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    opacity: 0,
    position: 'absolute',
    textDecoration: 'none',
    top: 0,
    left: 0,
    transition: 'opacity 240ms',
    width: '100%',
    zIndex: 500,
    '$root:hover &': {
      opacity: 1,
    },
    '& h2': {
      color: 'white',
      fontSize: '30px !important',
      fontStyle: 'italic',
      margin: 0,
      userSelect: 'none',
    },
  },
  bodyText: {
    color: 'white',
    lineHeight: '16px',
  },
}));

const TickerMatch = ({ aSeries, live }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header aSeries={aSeries} live={live} />
      {aSeries.participants.map((participant, index) => (
        <ParticipantRow key={index} participant={participant} live={live} />
      ))}
      <TrackLink // Send a tracking event with all series context data.
        to={getSeriesUrl(aSeries)}
        className={classes.hoverOverlay}
        track={{
          event: 'TopTicker Match Clicked',
          source: 'top-ticker',
          target: 'series-page',
          eventOptions: { contextSeriesId: aSeries.id, live },
        }}
      >
        <BodyText el="div" variant="small" className={classes.bodyText}>
          Click to
        </BodyText>
        <H2>View</H2>
      </TrackLink>
    </div>
  );
};

export default TickerMatch;
