import React from 'react';
import { useDispatch } from 'react-redux';

import { FOLLOW_FIELDS, removePreferredEntity } from '../../../store/actions/user';
import { getCompetitionUrl, getTournamentUrl } from '../../../utils/urls';
import { BodyText } from '../../common';
import FollowingItem from './FollowingItem';
import FollowingEntityLink from '../FollowingEntityLink';

const getEventUrl = (event, entityField) => {
  if (!event.slug) return null;

  if (entityField === FOLLOW_FIELDS.TOURNAMENT_IDS) {
    return getTournamentUrl(event);
  }
  return getCompetitionUrl(event);
};

const FollowingItemEvent = ({ className, event, entityField }) => {
  const { id, title } = event;

  const dispatch = useDispatch();

  return (
    <FollowingItem className={className} key={id} handleDelete={() => dispatch(removePreferredEntity(entityField, id))}>
      <FollowingEntityLink entity={event} entityField={entityField} to={getEventUrl(event, entityField)}>
        <BodyText variant="smallSemiBold">{title}</BodyText>
      </FollowingEntityLink>
    </FollowingItem>
  );
};

export default FollowingItemEvent;
