type Banners = {
  desktop?: string;
  mobile?: string;
  tablet?: string;
};

type BracketPart = {
  [key: number]: (string | null)[];
};

type FirestoreTimestamp = {
  seconds: number;
  nanoseconds: number;
  toDate: () => Date;
};

type GameImages = {
  square?: string;
  circle?: string;
  rectangle?: string;
};

type Images = {
  banner?: string;
  banner_fallback?: boolean;
  default?: string;
  fallback?: string;
  square?: string;
  square_fallback?: boolean;
  thumbnail?: string;
};

type MetaTags = {
  description: string;
  keywords: string;
  robots: string;
  title: string;
};

type Modified = {
  [attr: string]: string;
};

type Prizepool = {
  first?: string | null;
  second?: string | null;
  third?: string | null;
  total: string | null;
};

type ScoreEmbed = {
  src: string;
  title: string;
};

export type Stage = {
  substages: Substage[];
  title: string;
};

type Substage = {
  id: string;
  standings?: SubstageStandings[];
  title: string;
  type: string;
};

type SubstageStandings = {
  draws: number;
  losses: number;
  participant: SubstageTeamParticipant | SubstagePlayerParticipant;
  points: number | null;
  wins: number;
};

type SubstageTeamParticipant = {
  id: string;
  players: string[];
  type: 'TEAM';
};

type SubstagePlayerParticipant = {
  id: string;
  type: 'PLAYER';
};

type Preferences = {
  teamIds: string[];
  tournamentIds: string[];
  competitionIds: string[];
};

export type UserPreferences = Preferences & {
  favoriteGamesCompletedAt: FirestoreTimestamp;
  gameIds: string[];
};

export type UserNotifications = Preferences;

type UserProviderData = {
  providerId: string;
};

export interface Bracket {
  createdAt?: FirestoreTimestamp;
  grandFinal?: string[] | null;
  id: string;
  lower?: BracketPart;
  thirdPlace?: string[] | null;
  updatedAt?: FirestoreTimestamp;
  upper?: BracketPart;
}

export interface Competition {
  banners?: Banners;
  description?: string;
  end?: FirestoreTimestamp;
  gameIds: string[];
  id: string;
  location?: string;
  metaTags?: MetaTags;
  modified?: Modified;
  prizepool?: Prizepool;
  slug: string;
  start?: FirestoreTimestamp;
  tier: number;
  title?: string;
  tournamentIds?: string[];
  updatedAt?: FirestoreTimestamp;
  websiteUrl?: string;
}

export interface EventCompetition extends Competition {
  fromSeries: SeriesRaw;
  fromTournament: TournamentRaw;
  isCompetition: true;
}

export interface EventTournament extends TournamentRaw {
  fromSeries: SeriesRaw;
}

export interface Game {
  abbr: string;
  color: string;
  createdAt?: FirestoreTimestamp;
  createdByJuked?: boolean;
  defaultMatchType?: string;
  hidden?: boolean;
  hideParticipants?: boolean;
  id: string;
  images: GameImages;
  metaTags?: MetaTags;
  modified?: Modified;
  name?: string;
  priority: number;
  updatedAt?: FirestoreTimestamp;
}

export interface TeamParticipant {
  image?: string;
  name: string;
  players?: string[];
  type?: string;
  score?: number;
  shortName?: string;
  uuid?: string;
  winner?: boolean;
}

export interface PlayerParticipant {
  firstName: string | null;
  image?: string;
  lastName: string | null;
  name: string;
  score?: number | null;
  shortName?: string;
  uuid: string;
  winner?: boolean;
}

export interface Series extends SeriesRaw {
  tournament: Tournament;
}

interface SeriesRaw {
  adminUid?: string;
  bestOf?: number;
  createdAt?: FirestoreTimestamp;
  createdByJuked?: string;
  end?: FirestoreTimestamp | null;
  game: string;
  hasVod: boolean;
  id: string;
  isLongerThan24Hours?: boolean;
  live?: boolean;
  metaTags?: MetaTags;
  participants?: (TeamParticipant | PlayerParticipant)[];
  start?: FirestoreTimestamp | null;
  stream?: string | null;
  streams?: Record<string, Stream>;
  substage?: string;
  tier: number;
  title: string;
  teamIds?: string[];
  updatedAt?: FirestoreTimestamp;
  vod?: Vod | null;
  winner?: string | null;
}

export interface Standings {
  createdAt?: FirestoreTimestamp;
  finalized: FirestoreTimestamp | null;
  id: string;
  stages: Stage[];
  updatedAt?: FirestoreTimestamp;
}

export interface StreamMetadata {
  language: string;
  channelId?: string;
}

export interface Stream {
  id: string;
  online: boolean;
  platformId: string;
  platformType: number;
  type?: number;
  updatedAt?: FirestoreTimestamp;
  viewerCount: number;
  metadata?: StreamMetadata;
}

export interface CleanedStreamsPayload {
  streamMap?: Record<string, string>;
  pausedSeriesState?: Record<string, boolean>;
}

export interface Tournament extends TournamentRaw {
  competition?: Competition | null;
}

interface TournamentRaw {
  adminUid?: string;
  banners?: Banners;
  bracketLink?: string;
  competitionId?: string;
  createdByJuked?: string;
  description?: string;
  end?: FirestoreTimestamp | null;
  game: string;
  id: string;
  images?: Images;
  isFeatured?: boolean;
  location?: string;
  metaTags?: MetaTags;
  prizepool?: Prizepool;
  scoreEmbeds?: ScoreEmbed[];
  short_description?: string;
  slug: string;
  start: FirestoreTimestamp | null;
  tier?: number;
  title: string;
  updatedAt?: FirestoreTimestamp;
  websiteUrl?: string;
}

export interface User {
  admin?: boolean;
  beta?: boolean;
  displayName?: string; // From auth user
  email: string;
  emailVerified?: boolean; // From auth user
  fcmToken?: string;
  joined: FirestoreTimestamp;
  mailers?: string[];
  notifications?: UserNotifications;
  providerData?: UserProviderData[]; // from auth user
  preferences?: UserPreferences;
  role?: string;
  requiresMixpanelAlias?: boolean;
  sportBuffUUID?: string;
  timeZone?: string | null;
  uid: string;
}

export interface Vod {
  createdAt?: FirestoreTimestamp;
  id: string;
  platformType: number;
  secondsIntoVideo: number;
  thumbnail: string;
  updatedAt?: FirestoreTimestamp;
  videoId: string;
  viewerCount: number;
}

export interface PreferredEntitiesMap {
  preferredGameIds: string[];
  preferredTeamIds: string[];
  preferredTournamentIds: string[];
  preferredCompetitionIds: string[];
}

export const isEventCompetition = (obj: EventCompetition | EventTournament): obj is EventCompetition => {
  return (obj as EventCompetition).isCompetition === true;
};

export const isEventTournament = (obj: EventCompetition | EventTournament): obj is EventTournament => {
  return !('isCompetition' in obj);
};

export const isTeamParticipant = (obj: TeamParticipant | PlayerParticipant): obj is TeamParticipant => {
  return Boolean(obj.uuid) && obj.uuid.startsWith('TEAM');
};

export const isPlayerParticipant = (obj: TeamParticipant | PlayerParticipant): obj is PlayerParticipant => {
  return Boolean(obj.uuid) && obj.uuid.startsWith('PLAYER');
};
