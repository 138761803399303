import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 8,
    justifyContent: 'space-between',
    padding: 8,
    margin: '0 -8px 12px -8px', // Matches padding in x direction.
    '&:hover': {
      backgroundColor: theme.palette.background.quinary,
    },
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ContentItemContainer = ({ className, children }) => {
  const classes = useStyles();
  return <div className={`${classes.root} ${classes.flex} ${className}`}>{children}</div>;
};

export default ContentItemContainer;
