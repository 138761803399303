import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import { getSeriesById, isDataLoaded, getStandingsById } from '../store/selectors/gameData';
import HeroMainPlayer from '../components/Video/HeroMainPlayer';
import TournamentCalendar from '../components/CalendarTable/TournamentCalendar';
import { subscribeToQuery, unsubscribeToQuery, fetchStandings } from '../store/actions/gameData';
import { EventViewershipInfo, EventStandings } from '../components/common';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    marginTop: 10,
    maxWidth: 1280,
    minWidth: 800,
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 'unset',
      minWidth: 'unset',
    },
  },
  frameContainer: {
    height: 0,
    paddingTop: '56.25%',
    overflow: 'hidden',
    position: 'relative',
  },
  tournamentTitle: {
    color: 'white',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 5,
    [theme.breakpoints.down('sm')]: {
      margin: '10px 5px',
    },
  },
  loadingContainer: {
    minHeight: '100vh', // so footer doesn't show on top
    '& .loading-circle': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  viewerCountContainer: {
    display: 'block',
    backgroundColor: '#1A1A1A',
    width: '100%',
    boxSizing: 'border-box',
    padding: 20,
    position: 'relative',
  },
});

class VodScreen extends Component {
  getQueryParams(seriesId) {
    return ['allSeries', ['id', '==', seriesId || this.props.match.params.seriesId]];
  }

  componentDidMount() {
    const { subscribeToQuery, series, fetchStandings } = this.props;
    subscribeToQuery(...this.getQueryParams());

    if (!series) return;
    fetchStandings(series.tournament.id);
  }

  componentWillUnmount() {
    this.props.unsubscribeToQuery(...this.getQueryParams());
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { seriesId },
      },
      subscribeToQuery,
      unsubscribeToQuery,
      series,
      fetchStandings,
    } = this.props;

    const {
      series: prevSeries,
      match: {
        params: { seriesId: prevSeriesId },
      },
    } = prevProps;

    if (prevSeriesId !== seriesId) {
      unsubscribeToQuery(...this.getQueryParams(prevSeriesId));
      subscribeToQuery(...this.getQueryParams());
    }
    if (prevSeries !== series && !!series) {
      fetchStandings(series.tournament.id);
    }
  }

  render() {
    const { classes, vod, series, tournament, isDataLoaded, standings } = this.props;

    if (!isDataLoaded || !series) {
      return (
        <div className={classes.loadingContainer}>
          <div className="loading-circle" />
        </div>
      );
    }

    if (vod && vod.videoId) {
      return (
        <div className={classes.root}>
          <div className={classes.container}>
            <div className={classes.frameContainer}>
              <HeroMainPlayer
                key={vod.videoId}
                muted={false}
                id={vod.id}
                channel={vod.videoId}
                platformType={vod.platformType}
                video
                startTime={vod.secondsIntoVideo}
                seriesId={series.id}
              />
            </div>
          </div>
          <div className={classes.viewerCountContainer}>
            <EventViewershipInfo
              tournament={tournament}
              aSeries={series}
              competition={tournament.competition}
              streamsArr={[vod]}
              source={'vod-screen'}
            />
          </div>
          <EventStandings aSeries={series} participants={series.participants} standings={standings} />
          <TournamentCalendar tournamentId={tournament.id} />
        </div>
      );
    }

    return <div>Video not found</div>;
  }
}

export default compose(
  connect(
    (state, ownProps) => ({
      series: getSeriesById(state, ownProps.match.params.seriesId),
      isDataLoaded: isDataLoaded(state),
      state,
    }),
    { subscribeToQuery, unsubscribeToQuery, fetchStandings },
    (stateProps, dispatchProps, ownProps) =>
      !!stateProps.series
        ? {
            ...stateProps,
            ...dispatchProps,
            ...ownProps,
            tournament: stateProps.series.tournament,
            vod: stateProps.series.vod,
            standings: getStandingsById(stateProps.state, stateProps.series.tournament.id),
          }
        : { ...stateProps, ...dispatchProps, ...ownProps }
  ),
  withStyles(styles)
)(VodScreen);
