import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';

import DesktopStreamsDropdown from './DesktopStreamsDropdown';
import MobileStreamsDropdown from './MobileStreamsDropdown';
import { setSelectedStreamMap } from '../../../store/actions/uiStates';
import { getSelectedStreamId } from '../../../store/selectors/uiStates';

export default ({ classes, series, ...props }) => {
  const dispatch = useDispatch();
  const matchesDesktop = useMediaQuery('(min-width:960px)'); // SM Breakpoint
  const selectedStreamId = useSelector(state => getSelectedStreamId(state, series));
  const selectedStream = series.streams[selectedStreamId];

  const setSelectedStream = ({ seriesId, streamId }) => {
    dispatch(setSelectedStreamMap({ seriesId, streamId }));
  };

  const Dropdown = matchesDesktop ? DesktopStreamsDropdown : MobileStreamsDropdown;
  return <Dropdown {...props} series={series} selectedStream={selectedStream} setSelectedStream={setSelectedStream} />;
};
