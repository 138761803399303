import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { compose } from 'recompose';
import { getSeriesById } from '../../store/selectors/gameData';
import classnames from 'classnames';

import { useFirestoreSubscribe } from '../../hooks';
import { isSignedIn, getUser } from '../../store/selectors/user';
import BracketPickButton from '../Contests/BracketPickButton';
import { isFeatureEnabled } from '../../utils/featureFlags';
import TeamPageLink from '../common/TeamPageLink';

const styles = theme => ({
  label: {
    width: 194,
    boxSizing: 'border-box',
    fontSize: '0.6875em',
    color: 'white',
    textAlign: 'center',
  },
  root: {
    width: 194,
    boxSizing: 'border-box',
    background: 'white',
    color: 'black',
    margin: 6,
    lineHeight: '1.5em',
    fontSize: '0.875em',
    padding: '8px 20px',
    borderRadius: 4,
    position: 'relative',
    border: '2px solid transparent',
    '&.active': {
      borderColor: theme.lightTeal,
    },
    '&.hideSpoiler': {
      backgroundColor: 'grey',
    },
    '&.spacer': {
      // using visibility instead of just not rendering anything, because we want the spacer to have the same size as other stuff
      visibility: 'hidden',
    },
  },
  contentContainer: {
    '.hideSpoiler &': {
      filter: 'blur(7px) brightness(0.3)',
    },
  },
  showResult: {
    cursor: 'pointer',
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  team: {
    display: 'flex',
    position: 'relative',
    '&:not(:last-child)': {
      borderBottom: '1px solid black',
    },
    '& > span:first-child': {
      textOverflow: 'ellipsis',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      flex: 1,
    },
    '&.winner': {
      fontWeight: 'bold',
      '&:after': {
        content: '" "',
        position: 'absolute',
        top: 6,
        right: -14,
        width: 0,
        height: 0,
        borderTop: '5px solid transparent',
        borderBottom: '5px solid transparent',
        borderLeft: '8px solid red',
      },
    },
  },
  won: {
    background: 'green',
  },
  lost: {
    background: 'red',
  },
  participantText: {
    color: 'black',
  },
});

const BracketItem = ({
  seriesId,
  series,
  viewingSeries,
  classes,
  spoilerFree,
  showTitle,
  titleIndex,
  user,
  previousSeries,
  isSignedIn,
}) => {
  const [userShowResult, setUserShowResult] = useState(false);
  const source = 'bracket-item';

  let active, hideSpoiler, hideScores;
  if (!!series && !!viewingSeries) {
    active = series.id === viewingSeries.id;
    // we want to hide results from all the series that ends after "this" VOD's series, unless user chose to show result.
    hideSpoiler =
      !active && !userShowResult && spoilerFree && (!series.end || series.end.seconds > viewingSeries.start.seconds);
    // we want to hide scores for the series that we're watching VOD for.
    hideScores = spoilerFree && active;
  }

  // Javascript literal equivalent to &nbsp;
  const NBSP = '\u00A0';

  const participants = !!series ? series.participants : [];

  if (!participants.length) {
    participants.concat({ name: NBSP }, { name: NBSP });
  }

  const [prompts] = useFirestoreSubscribe('contestPrompts', [
    ['seriesId', '==', seriesId],
    ['type', '==', 'bracketology'],
  ]);
  const [entries] = useFirestoreSubscribe('contestUserEntries', [
    ['prompt.seriesId', '==', seriesId],
    ['uid', '==', isSignedIn ? user.uid : null],
    ['prompt.type', '==', 'bracketology'],
    // putting the conditional here instead of at the `entries` assignment level, because react hooks can't be called conditionally
  ]);

  const entry = entries && entries[0];
  const prompt = prompts && prompts[0];

  return (
    <>
      {isFeatureEnabled('debug') && (
        <div>
          Prompts: {prompts && prompts.length}
          <br />
          Series: <strong>{seriesId}</strong>
          <br />
          Previous:{' '}
          <strong>{!!previousSeries && previousSeries.map(ps => `[${ps.type} ${ps.seriesId}]`).join(', ')}</strong>
        </div>
      )}
      {showTitle && (
        <div className={classes.label}>
          {series && series.title} - Series #{titleIndex}
        </div>
      )}
      <div
        className={classnames({
          [classes.root]: true,
          spacer: seriesId === null,
          active,
          hideSpoiler,
          [classes.won]: entry && prompt && prompt.answer && entry.choice === prompt.answer,
          [classes.lost]: entry && prompt && prompt.answer && entry.choice !== prompt.answer,
        })}
      >
        <div className={classes.contentContainer}>
          {participants.map(({ name, score, winner, uuid, organization, slug, participantType }, idx) => {
            return (
              <div
                key={idx}
                className={classnames({
                  [classes.team]: true,
                  winner: !hideScores && !!winner,
                })}
              >
                {!!prompt && isFeatureEnabled('bracketology') ? (
                  <span>
                    <BracketPickButton
                      name={name}
                      prompt={prompt}
                      uuid={uuid}
                      entry={entry}
                      index={idx}
                      previous={previousSeries && previousSeries[idx]}
                      otherPrevious={previousSeries && previousSeries[1 - idx]}
                      participants={participants}
                      slug={slug}
                      participantType={participantType}
                    />
                  </span>
                ) : (
                  <span>
                    {name === 'TBD' ? (
                      NBSP
                    ) : (
                      <TeamPageLink
                        className={classes.participantText}
                        slug={slug}
                        participantType={participantType}
                        track={{ source }}
                      >
                        {name}
                      </TeamPageLink>
                    )}
                  </span>
                )}
                {!hideScores && <span>{score}</span>}
              </div>
            );
          })}
        </div>
        {hideSpoiler && (
          <span className={classes.showResult} onClick={() => setUserShowResult(true)}>
            Show Result
          </span>
        )}
      </div>
    </>
  );
};

export default compose(
  withStyles(styles),
  connect((state, ownProps) => ({
    user: getUser(state),
    isSignedIn: isSignedIn(state),
    previousSeries: ownProps.previousSeriesIds,
    series: getSeriesById(state, ownProps.seriesId),
    viewingSeries: getSeriesById(state, ownProps.viewingSeriesId), // The series being watched on Event or VOD screen
  }))
)(BracketItem);
