import { cancel, delay, fork, put, takeEvery, select } from 'redux-saga/effects';
import { trackEvent } from '../actions/uiStates';
import {
  ADD_PLAYER,
  REMOVE_PLAYER,
  TOGGLE_PAUSE,
  TOGGLE_PAUSE_ALL,
  SET_STREAM_CARD_VOLUME,
  TOGGLE_MUTE,
  togglePause,
  setPausedState,
  setUnmutedSeriesId,
} from '../actions/video';
import { gameDataRemoved } from '../actions/gameData';
import { getUnmutedSeriesId } from '../selectors/video';
import { PLATFORM_TYPES, TYPE_PLATFORM_MAP } from '../reducers/video';

const getPlayerProperties = (player, platformType) => {
  switch (platformType) {
    case PLATFORM_TYPES.TWITCH:
      // https://dev.twitch.tv/docs/embed/video-and-clips
      return {
        isPlaying: !player.isPaused(),
        isMuted: player.getMuted(),
        volume: player.getVolume(),
      };
    case PLATFORM_TYPES.YOUTUBE:
      // https://developers.google.com/youtube/iframe_api_reference
      return {
        isPlaying: !!player.getPlayerState && player.getPlayerState() === 1,
        isMuted: player.isMuted(),
        volume: player.getVolume(),
      };
    default:
      console.error(`[getPlayerProperties] unsupported platformType: ${platformType}`);
      return;
  }
};

function* doTogglePause(action) {
  const {
    video: { playerStates },
  } = yield select();
  const { seriesId } = action;
  const player = playerStates[seriesId];
  const isTwitchPlayer = player?.platformType === PLATFORM_TYPES.TWITCH;

  // This *shouldn't* happen, but if-check just in case to prevent crash on prod.
  // TODO: confirm that it never happens, then remove if-check
  if (!player) return;

  if (isTwitchPlayer) {
    player.paused ? player.player.pause() : player.player.play();
  } else {
    player.paused ? player.player.pauseVideo() : player.player.playVideo();
  }
}

function* doTogglePauseAll() {
  const {
    video: { pauseAll, playerStates },
  } = yield select();

  // doing for-loops instead of forEach because can't use `yield` in anonymous function.
  const keys = Object.keys(playerStates);
  for (let i = 0; i < keys.length; i++) {
    const playerState = playerStates[keys[i]];
    if ((pauseAll && !playerState.paused) || (!pauseAll && playerState.paused)) {
      yield put(togglePause(keys[i]));
    }
  }
}

function* runPlayerHeartbeat(action) {
  yield delay(10 * 1000); // Wait 10 seconds for player setup.

  // This should never stop running while the player is alive...
  while (true) {
    const {
      video: { playerStates },
    } = yield select();

    const { streamId, platformType, seriesId, video } = action;
    const { player } = playerStates[seriesId] || {};

    if (!player) {
      // If the player is destroyed but we do not capture the REMOVE_PLAYER
      // action, the heartbeat should self-terminate.
      return;
    } else {
      try {
        const { isPlaying, isMuted, volume } = getPlayerProperties(player, platformType) || {};
        if (!!isPlaying && !document.hidden) {
          // Only send heartbeat when player is active.
          yield put(
            trackEvent(
              'Heartbeat',
              {
                is_muted: isMuted,
                is_playing: isPlaying,
                platform_id: streamId,
                platform: TYPE_PLATFORM_MAP[platformType],
                is_vod: video,
                volume,
              },
              { contextSeriesId: seriesId } // Include series context.
            )
          );
        }
      } catch (e) {
        console.error(e);
      }
    }

    yield delay(60 * 1000); // Wait 60 seconds.
  }
}

function* playerHeartbeatManager() {
  // Manager ensures only one heartbeat per stream is spawned.
  const tasks = {};

  yield takeEvery(ADD_PLAYER, function* addPlayerHeartbeat(action) {
    const { seriesId } = action;
    if (!tasks[seriesId]) {
      // We must track the heartbeat task in case changing pages tries to
      // create a new heartbeat for the same video id.
      const heartbeatTask = yield fork(runPlayerHeartbeat, action);
      tasks[seriesId] = heartbeatTask;
    }
  });

  yield takeEvery(REMOVE_PLAYER, function* removePlayerHeartbeat(action) {
    const { seriesId } = action;
    if (!!tasks[seriesId]) {
      yield cancel(tasks[seriesId]);
      delete tasks[seriesId];
    }
  });
}

function* setStreamCardVolume(action) {
  const {
    video: { playerStates },
  } = yield select();

  const unmutedSeriesId = yield select(getUnmutedSeriesId);

  // At this stage, there will allways be only one unmutedStream.
  const playerState = Boolean(unmutedSeriesId) && playerStates[unmutedSeriesId];
  const isTwitchPlayer = playerState?.platformType === PLATFORM_TYPES.TWITCH;

  const { player } = playerState || {};
  if (player) {
    if (isTwitchPlayer || !action.onInitialLoad) {
      player.setVolume(isTwitchPlayer ? parseFloat(action.volume) : action.volume * 100);
    } else {
      // YouTube player will error if setting the volume before player its ready
      player.addEventListener('onReady', () => setYouTubeEventVolume(player, action.volume));
    }
  }
}

const setYouTubeEventVolume = (player, volume) => {
  player.setVolume(volume * 100);
  player.removeEventListener('onReady', () => setYouTubeEventVolume(player, volume));
};

function* doToggleMute(action) {
  const {
    video: { playerStates, isSinglePlayerMuted },
    localData: { streamCardVolume },
  } = yield select();

  const { seriesId, isSinglePlayerView } = action;
  const playerState = playerStates[seriesId];
  const { player } = playerState || {};
  const unmutedSeriesId = yield select(getUnmutedSeriesId);
  const muted = isSinglePlayerView ? isSinglePlayerMuted : unmutedSeriesId === seriesId;
  const isTwitchPlayer = playerState?.platformType === PLATFORM_TYPES.TWITCH;

  if (isTwitchPlayer) {
    player.setMuted(muted);
  } else {
    muted ? player.mute() : player.unMute();
  }

  if (!muted) {
    // Verify if we had another player unmuted
    const previouslyUnmutedPlayerState = Boolean(unmutedSeriesId) && playerStates[unmutedSeriesId];
    if (Boolean(previouslyUnmutedPlayerState) && !isSinglePlayerView) {
      // Mute the other previously unmuted stream.
      const platformType = previouslyUnmutedPlayerState.platformType;
      const previouslyUnmutedPlayer = previouslyUnmutedPlayerState.player;
      platformType === PLATFORM_TYPES.TWITCH ? previouslyUnmutedPlayer.setMuted(true) : previouslyUnmutedPlayer.mute();
    }

    if (streamCardVolume) {
      // Player.setVolume() will not work on muted streams.
      player.setVolume(isTwitchPlayer ? parseFloat(streamCardVolume) : streamCardVolume * 100);
    }
    yield put(setUnmutedSeriesId(seriesId));
  } else {
    yield put(setUnmutedSeriesId(''));
  }
}

function* watchPlayerForOffline({ dispatch }, action) {
  const {
    gameData: { liveSeries },
    video: { playerStates },
  } = yield select();
  const { platformType, video, seriesId } = action;
  const { player } = playerStates[seriesId];
  const series = liveSeries[seriesId];

  if (platformType === PLATFORM_TYPES.TWITCH) {
    // TODO: Track and remove event listeners on players removal
    player.addEventListener(window.Twitch.Player.OFFLINE, () => {
      removeLiveSeriesIfStreamGoesOffline(dispatch, series);
    });
  } else {
    // If not a VOD
    if (!video) {
      player &&
        // TODO: Track and remove event listeners on players removal
        player.addEventListener('onStateChange', event => {
          if (event.data === 0) {
            removeLiveSeriesIfStreamGoesOffline(dispatch, series);
          }
        });
    }
  }
  // We don't need to remove this listeners because once the gameDataRemoved action is dispatched, the player will be destroyed completely.
}

// Check existence just in case liveSeries got updated while this saga is processing.
const removeLiveSeriesIfStreamGoesOffline = (dispatch, series) => {
  if (!!series) {
    // Using `dispatch` instead of `yield put` here, because it's in a callback and we'd have
    // to establish a channel if we want to use generator function, which is an overkill.
    dispatch(gameDataRemoved('liveSeries', { id: series.id }));
  }
};

// Observe changes on the player paused state, so we can keep our state in
// sync if the user changes the paused state from the player controls.
function* watchPlayerForPaused({ dispatch }, action) {
  const {
    video: { playerStates },
  } = yield select();

  const { platformType, video, seriesId, controls } = action;
  const { player } = playerStates[seriesId];

  if (Boolean(player) && !controls && !video) {
    // Event listener is only set for streams on the HomeScreen

    if (platformType === PLATFORM_TYPES.TWITCH) {
      // TODO: Track and remove event listeners on players removal
      player.addEventListener(window.Twitch.Player.PLAY, () => {
        dispatch(setPausedState(seriesId, { isPaused: false }));
      });
    } else {
      player.addEventListener('onStateChange', event => {
        if (event.data === 1) {
          // Playing
          dispatch(setPausedState(seriesId, { isPaused: false }));
        }

        if (event.data === 2) {
          // Paused
          dispatch(setPausedState(seriesId, { isPaused: true }));
        }
      });
    }
  }
}

export default function* videoSagas({ dispatch }) {
  yield fork(playerHeartbeatManager);
  yield takeEvery(TOGGLE_PAUSE, doTogglePause);
  yield takeEvery(TOGGLE_PAUSE_ALL, doTogglePauseAll);
  yield takeEvery(SET_STREAM_CARD_VOLUME, setStreamCardVolume);
  yield takeEvery(TOGGLE_MUTE, doToggleMute);
  yield takeEvery(ADD_PLAYER, watchPlayerForOffline, { dispatch });
  yield takeEvery(ADD_PLAYER, watchPlayerForPaused, { dispatch });
}
