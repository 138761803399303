import React from 'react';
//import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const getVariant = (variant, theme) => {
  const dafault = {
    width: 56,
    height: 32,
    sliderBackground: theme.palette.background.nonary,
    sliderBorder: `1px solid ${theme.palette.border.nonary}`,
    sliderBackgroundBefore: theme.darkGrey,
    sliderBeforeHeight: `24px`,
    sliderBeforeWidth: '24px',
    sliderBeforeLeft: '4px',
    sliderBeforeTop: 'unset',
    transform: 'translateX(23px)',
    webKitTransform: `'-webkit-transform': 'translateX(23px)'`,
    msTransform: `'-ms-transform': 'translateX(23px)'`,
  };

  switch (variant) {
    case 'large':
      return {
        width: `50px !important`,
        height: `25px !important`,
        sliderBackground: `${theme.palette.background.nonary} !important`,
        sliderBorder: `1px solid ${theme.palette.border.nonary} !important`,
        sliderBackgroundBefore: `${theme.mediumGrey} !important`,
        sliderBeforeHeight: `20px !important`,
        sliderBeforeWidth: '20px !important',
        sliderBeforeLeft: '1px !important',
        sliderBeforeTop: '1px !important',
        transform: 'translateX(26px) !important',
        webKitTransform: `'-webkit-transform': 'translateX(23px)'`,
        msTransform: `'-ms-transform': 'translateX(26px)'`,
      };
    default:
      return dafault;
  }
};

const useStyles = makeStyles(theme => ({
  switch: {
    position: 'relative',
    display: 'inline-block',
    width: ({ variant }) => getVariant(variant, theme).width,
    height: ({ variant }) => getVariant(variant, theme).height,
    '& input': {
      opacity: 0,
      width: 0,
      height: 0,
    },
  },
  slider: {
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: ({ variant }) => getVariant(variant, theme).sliderBackground,
    border: ({ variant }) => getVariant(variant, theme).sliderBorder,
    transition: '0.4s',
    borderRadius: 19,
    boxSizing: 'border-box',
    '&:before': {
      position: 'absolute',
      content: '""',
      height: ({ variant }) => getVariant(variant, theme).sliderBeforeHeight,
      width: ({ variant }) => getVariant(variant, theme).sliderBeforeWidth,
      left: ({ variant }) => getVariant(variant, theme).sliderBeforeLeft,
      top: ({ variant }) => getVariant(variant, theme).sliderBeforeTop,
      bottom: 3,
      background: ({ variant }) => getVariant(variant, theme).sliderBackgroundBefore,
      borderRadius: '50%',
      transition: '0.4s',
    },
    '.checked &': {
      '&:before': {
        background: theme.lightTeal,
        transform: ({ variant }) => getVariant(variant, theme).transform,
        '-webkit-transform': ({ variant }) => getVariant(variant, theme).webKitTransform,
        '-ms-transform': ({ variant }) => getVariant(variant, theme).msTransform,
      },
    },
  },
  iconStyle: {
    height: '100%',
    paddingLeft: 10,
    fontSize: 9,
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 'unset',
      paddingRight: 15,
    },
  },
}));

const SliderSwitch = ({ className, checked, onChange, Icon, id, variant }) => {
  const classes = useStyles({ variant });

  return (
    <label className={classnames({ [classes.switch]: true, [className]: true, checked })}>
      <input
        id={id}
        type="checkbox"
        checked={!!checked}
        onChange={onChange}
        onClick={e => e.stopPropagation()} // Stop event propogation so that we dont get two onClick events from having input nested under label.
      />
      <span id="slider" className={`${classes.slider} slider`}>
        {Icon && <Icon id="sliderIcon" className={classes.iconStyle} />}
      </span>
    </label>
  );
};

export default SliderSwitch;
