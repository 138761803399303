import React, { useState } from 'react';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FaUsers } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { ScrollableDialog } from '../../Dialogs';
import Button from '../buttons/Button';
import { BodyText, H2 } from '../text';
import { getGames } from '../../../store/selectors/gameData';
import getFollowedEntityName from './getFollowedEntityName';

const useStyles = makeStyles(theme => ({
  dialog: {
    alignItems: 'center',
    background: theme.palette.background.secondary,
    borderRadius: 8,
    border: `1px solid ${theme.palette.border.primary}`,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '260px',
    minWidth: '400px',
    maxWidth: '480px',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: 64,
  },
  title: {
    textAlign: 'center',
    margin: '0 0 24px 0', // Needs to use the entire margin sintax to overwrite H2 default margins
  },
  column: {
    width: '100%',
    padding: '0 42px',
    boxSizing: 'border-box',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
  },
  icon: {
    width: 24,
    height: 24,
    borderRadius: 4,
    marginRight: 12,
  },
  names: {
    maxWidth: 190,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  checkbox: {
    marginLeft: 'auto',
    padding: 0,
  },
  button: {
    marginTop: 32,
  },
  '@media (max-width: 700px)': {
    container: {
      padding: '32px 16px 24px',
    },
    dialog: {
      minWidth: 'inherit',
      padding: 14,
    },
  },

  '@media (max-width: 320px)': {
    dialog: {
      width: 272,
    },
  },
}));

const MultipleEntitiesFollowDialog = ({
  title,
  relatedEntities,
  subscribedEntityIds,
  renderGameFirst,
  onCloseClick,
  onClick,
}) => {
  const classes = useStyles();
  const gamesMap = useSelector(getGames);
  const [selectedEntityIds, setSelectedEntityIds] = useState(subscribedEntityIds);

  const toggleSelectedEntity = entityId => {
    const entityIds = selectedEntityIds.includes(entityId)
      ? selectedEntityIds.filter(id => id !== entityId)
      : [...selectedEntityIds, entityId];
    setSelectedEntityIds(entityIds);
  };

  return (
    <ScrollableDialog id="multi-follow-dialog" className={classes.dialog} onCloseClick={onCloseClick}>
      <div className={classes.container}>
        <H2 variant="semiBold" className={classes.title}>
          {title}
        </H2>
        <div className={classes.column}>
          {relatedEntities.map(({ id, name, title, game: gameId }) => {
            const game = gamesMap[gameId];
            return (
              <div key={id} className={classes.row}>
                {game ? (
                  <img className={classes.icon} alt="content-icon" src={game?.images?.square} />
                ) : (
                  <FaUsers className={classes.icon} />
                )}
                <BodyText variant="smallSemiBold" className={classes.names}>
                  {getFollowedEntityName({ name, title, game, renderGameFirst })}
                </BodyText>
                <Checkbox
                  color="default"
                  className={classes.checkbox}
                  checked={selectedEntityIds.includes(id)}
                  onChange={() => toggleSelectedEntity(id)}
                />
              </div>
            );
          })}
          <Button className={classes.button} theme={'dialog-action'} onClick={() => onClick(selectedEntityIds)}>
            Save
          </Button>
        </div>
      </div>
    </ScrollableDialog>
  );
};

export default MultipleEntitiesFollowDialog;
