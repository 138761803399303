import { createSelector } from 'reselect';

import { getSortedGames } from './gameData';
import getSecondsSince from '../../utils/getSecondsSince';

const HOUR_IN_SECONDS = 60 * 60;
const TWELVE_HOURS_IN_SECONDS = 12 * 60 * 60;

export const getPreferencesRemainingGames = createSelector(
  getSortedGames,
  (_, selectedGameIds) => selectedGameIds,
  (games, selectedGameIds) => games.filter(game => !game.hidden && !selectedGameIds.includes(game.id))
);

export const isFirstTimeVisitor = ({ uiStates: { isFirstTimeVisitor } }) => isFirstTimeVisitor;
export const getAdvocateReference = ({ uiStates: { advocateRef } }) => advocateRef;
export const getVisitorUUID = ({ uiStates: { visitorUUID } }) => visitorUUID;
export const getIsFollowMode = ({ uiStates: { isFollowMode } }) => isFollowMode;

const getUserTrackedAt = ({ uiStates: { userTrackedAt } }) => userTrackedAt;
const getStreamDropdownChangedAt = ({ uiStates: { streamDropdownChangedAt } }) => streamDropdownChangedAt;

export const shouldTrackUser = state => {
  const userTrackedAt = getUserTrackedAt(state);

  return Boolean(!userTrackedAt || getSecondsSince(userTrackedAt) > HOUR_IN_SECONDS);
};

export const getSelectedStreamId = ({ uiStates: { selectedStreamMap } }, series) => {
  const { id } = series;
  return selectedStreamMap[id] || series.stream;
};

export const shouldCleanStreamMap = state => {
  const streamDropdownChangedAt = getStreamDropdownChangedAt(state);
  return getSecondsSince(streamDropdownChangedAt) > TWELVE_HOURS_IN_SECONDS;
};
