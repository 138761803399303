import React from 'react';
import { withStyles } from '@material-ui/styles';
import { FaTrophy } from 'react-icons/fa';

const styles = theme => ({
  tournamentPrizepool: {
    fontSize: 14,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    '& .trophyIcon': {
      fontSize: 15,
      marginRight: 5,
    },
  },
  [theme.breakpoints.down('sm')]: {
    tournamentPrizepool: {
      fontSize: 11,
      '& .trophyIcon': {
        fontSize: 12,
      },
    },
  },
});

const getPrizepoolText = prizepool => {
  if (!prizepool || !prizepool.total) {
    return null;
  }

  return prizepool.total.replace(/ /g, '');
};

const ItemPrizepool = ({ classes, prizepool, withTrophyIcon, className }) => {
  const prizepoolText = getPrizepoolText(prizepool);

  if (!prizepoolText || ['tba', 'tbd', 'n/a'].includes(prizepoolText.toLowerCase())) {
    return null;
  }

  return (
    <div className={`${classes.tournamentPrizepool} ${className}`}>
      {withTrophyIcon && <FaTrophy className="trophyIcon" />}
      <span>{prizepoolText}</span>
    </div>
  );
};

export default withStyles(styles)(ItemPrizepool);
