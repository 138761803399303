import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { GameTag, HoverElevator, TrackLink } from '../common';

const CARD_PADDING = 12;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.tertiary,
    position: 'relative',
  },
  relationCard: {
    borderRadius: 6,
    boxSizing: 'border-box',
    color: 'inherit', // Override Link styles
    cursor: ({ disabled }) => (disabled ? 'auto' : 'pointer'),
    display: 'flex',
    padding: CARD_PADDING,
    pointerEvents: ({ disabled }) => (disabled ? 'none' : 'auto'),
    position: 'relative',
    textDecoration: 'none', // Override Link styles
    width: 268,
  },
  imageComponent: {
    height: 80,
    marginRight: 16,
    width: 80,
    '& img': {
      borderRadius: 4,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '2px 0',
  },
  topContent: {
    marginBottom: 8,
  },
  bottomContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  prizepool: {
    fontSize: 14,
    fontWeight: 600,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  textRow: {
    marginBottom: 4,
  },
  gameTag: {
    fontSize: 10,
    lineHeight: '18px',
    bottom: 0, // Margin bottom.
    right: 0, // Border left.
    padding: `0 ${CARD_PADDING + 11}px ${CARD_PADDING}px 0`,
    position: 'absolute',
    zIndex: '10000',
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      flex: 1,
    },
    relationCard: {
      width: 'unset',
    },
    content: {
      width: '100%',
    },
    title: {
      width: 'unset',
    },
  },
}));

export const TopContent = ({ className, children }) => {
  const classes = useStyles();
  return <div className={`${classes.topContent} ${className}`}>{children}</div>;
};

export const BottomContent = ({ className, children }) => {
  const classes = useStyles();
  return <div className={`${classes.bottomContent} ${className}`}>{children}</div>;
};

const RelationCard = ({
  children,
  className,
  disabled,
  game,
  hasGameTag,
  ImageComponent,
  FollowComponent,
  track = {},
  url,
}) => {
  const classes = useStyles({ disabled });
  return (
    <HoverElevator className={`${className} ${classes.root}`} borderRadius={6} disabled={disabled}>
      <TrackLink
        className={classes.relationCard}
        disabled={disabled}
        to={url}
        track={{
          ...track,
          event: track.event || 'Relation Card Clicked',
          eventProperties: {
            ...(track.eventProperties || {}),
            game_id: game && game.id,
            game_abbr: game && game.abbr,
          },
        }}
      >
        {ImageComponent && <ImageComponent className={classes.imageComponent} />}
        <div className={classes.content}>{children}</div>
      </TrackLink>
      {hasGameTag && game && <GameTag className={classes.gameTag} game={game} />}
      {FollowComponent}
    </HoverElevator>
  );
};

export default RelationCard;
