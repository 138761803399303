import {
  FETCH_TWEETS_FAILURE,
  FETCH_TWEETS_REQUEST,
  FETCH_TWEETS_SKIPPED,
  FETCH_TWEETS_SUCCESS,
  POST_TWEET_FAILURE,
  POST_TWEET_REQUEST,
  POST_TWEET_SUCCESS,
  SET_CHAT_VIEW,
  TWITTER_AUTH_FAILURE,
  TWITTER_AUTH_SUCCESS,
} from '../actions/twitter';

export const TWITTER = 'TWITTER';

export const initialState = {
  authenticated: false,
  displayTweets: false,
  tweets: {
    tweetIds: [],
    fetchedAt: null,
    isFetching: false,
    error: null,
  },
  postTweet: {
    isFetching: false,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CHAT_VIEW:
      return {
        ...state,
        displayTweets: action.view === TWITTER,
      };
    case FETCH_TWEETS_REQUEST:
      return {
        ...state,
        tweets: {
          ...state.tweets,
          isFetching: true,
          error: null,
        },
      };
    case FETCH_TWEETS_SKIPPED:
      return {
        ...state,
        tweets: {
          ...state.tweets,
          isFetching: false,
        },
      };
    case FETCH_TWEETS_SUCCESS:
      const tweetIds = action.merge ? [...state.tweets.tweetIds, ...action.tweetIds] : action.tweetIds;
      return {
        ...state,
        tweets: {
          ...state.tweets,
          isFetching: false,
          error: null,
          fetchedAt: Date.now(),
          tweetIds,
        },
      };
    case FETCH_TWEETS_FAILURE:
      // TODO: Handle rate limit error... or let backend handle it.
      const authenticated = action.error.response && action.error.response.status === 401 ? false : state.authenticated;
      return {
        ...state,
        authenticated,
        tweets: {
          ...state.tweets,
          tweetIds: [],
          isFetching: false,
          error: action.error,
        },
      };
    case POST_TWEET_REQUEST:
      return {
        ...state,
        postTweet: {
          ...state.postTweet,
          isFetching: true,
          error: null,
        },
      };
    case POST_TWEET_FAILURE:
      return {
        ...state,
        postTweet: {
          ...state.postTweet,
          isFetching: false,
          error: action.error,
        },
      };
    case POST_TWEET_SUCCESS:
      // Set new tweet at the head of the feed.
      return {
        ...state,
        tweets: {
          ...state.tweets,
          tweetIds: [action.tweetId, ...state.tweets.tweetIds],
        },
        postTweet: {
          ...state.postTweet,
          isFetching: false,
          error: null,
        },
      };
    case TWITTER_AUTH_SUCCESS:
      return {
        ...state,
        authenticated: true,
        tweets: { ...initialState.tweets },
      };
    case TWITTER_AUTH_FAILURE:
      return {
        ...state,
        authenticated: false,
        tweets: { ...initialState.tweets },
      };
    default:
      return state;
  }
};
