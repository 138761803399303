import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MultipleEntitiesFollowDialog } from '../../common';
import EntityNotificationButton from './EntityNotificationButton';
import { savePreferredEntitiesNotifications } from '../../../store/actions/user';
import { getNotificationEntityIds, getPreferredEntityIds } from '../../../store/selectors/user';

const HeaderMultiNotificationButton = ({ entity, entityField, relatedEntityName }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const preferredEntityIds = useSelector(state => getPreferredEntityIds(state, { entityField }));

  // Related Entities Objects (ex: The organization.teams that the user has selected as preffered)
  const relatedEntities = entity[relatedEntityName]?.filter(relatedEntity =>
    preferredEntityIds.includes(relatedEntity.id)
  );
  // Related Entities Ids (ex: The organization.teamIds that the user has selected as preffered)
  const relatedEntitiesIds = entity[entityField]?.filter(relatedEntityId =>
    preferredEntityIds.includes(relatedEntityId)
  );

  const notifyingEntityIds = useSelector(state => getNotificationEntityIds(state, { entityField }));

  const isNotifying = Boolean(relatedEntitiesIds.find(id => notifyingEntityIds.includes(id)));

  if (!relatedEntities?.length || !relatedEntitiesIds?.length) {
    // If this values are 0, is because the user isn't following any of the related entities.
    // i.e. The user isn't following any of the Organization related teams.
    return null;
  }

  return (
    <>
      <EntityNotificationButton
        onClick={() => setIsDialogOpen(true)}
        active={isNotifying}
        track={{ eventProperties: { entityId: entity.id, entityField, isNotifying } }}
      />
      {isDialogOpen && (
        <MultipleEntitiesFollowDialog
          title={`Choose which ${entity.name} followed ${relatedEntityName} you want to get notified`}
          relatedEntities={relatedEntities}
          subscribedEntityIds={notifyingEntityIds}
          onCloseClick={() => setIsDialogOpen(false)}
          onClick={entityIds => {
            dispatch(savePreferredEntitiesNotifications(entityField, entityIds));
            setIsDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default HeaderMultiNotificationButton;
