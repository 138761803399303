import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  lineClamp: {
    display: '-webkit-box',
    lineClamp: 1,
    boxOrient: 'vertical',
    overflow: 'hidden',
    margin: 0,
    textOverflow: 'ellipsis',
  },
});

const LineClamp = ({ text, className, dangerouslySetInnerHTML }) => {
  const classes = useStyles();
  return (
    <p className={`${classes.lineClamp} ${className}`} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
      {text}
    </p>
  );
};

export default LineClamp;
