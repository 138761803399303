import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import dialogs from './dialogs';
import gameData from './gameData';
import hydration from './hydration';
import localData from './localData';
import twitter from './twitter';
import video from './video';
import user from './user';
import notification from './notification';
import maintenance from './maintenance';
import calendar from './calendar';
import uiStates from './uiStates';

const reducers = combineReducers({
  dialogs: persistReducer(
    {
      key: 'dialogs',
      storage,
      whitelist: ['signupPromptedAt', 'rotatingDialogsOptOutState'],
    },
    dialogs
  ),
  gameData,
  hydration,
  localData,
  maintenance,
  notification,
  calendar,
  uiStates: persistReducer(
    {
      key: 'uiStates',
      storage,
      whitelist: [
        'calendarIsMatchView',
        'calendarSelectedGames',
        'calendarFilter',
        'closedBannerIds',
        'cookieConsent',
        'isFirstTimeVisitor',
        'advocateRef',
        'isDarkMode',
        'articleSearch',
        'userTrackedAt',
        'visitorUUID',
        'selectedStreamMap',
        'singlePlayerSeriesId',
        'streamDropdownChangedAt',
        'isSinglePlayerView',
        'showTopEventsOnVideoSwatch',
        'isFollowMode',
      ],
    },
    uiStates
  ),
  twitter: persistReducer(
    { key: 'twitter', storage, whitelist: ['authenticated', 'displayTweets', 'tweets'] },
    twitter
  ),
  user,
  video: persistReducer({ key: 'video', storage, whitelist: ['pauseAll', 'pausedSeriesState'] }, video),
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
