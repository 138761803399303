import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { TrackDiv, TrackLink } from '../../common';
import UserDropdown from './UserDropdown';

const useStyles = makeStyles(theme => ({
  navLink: {
    alignItems: 'center',
    borderBottom: `1px solid transparent`,
    boxSizing: 'border-box',
    color: 'white',
    position: 'relative',
    height: '100%',
    lineHeight: '20px',
    cursor: 'pointer',
    textDecoration: 'none',
    margin: '0px 16px',
    display: 'inline-flex',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
    transition: 'border-bottom 350ms ease, color 350ms ease',
    '&.active': {
      borderBottom: `2px solid ${theme.magentaPink}`,
      color: theme.magentaPink,
    },
    '&.activeText': {
      color: theme.magentaPink,
    },
  },
}));

const TopNavItems = ({ drawerView }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const user = useSelector(state => state.user);
  const isBrowseOpen = useSelector(state => state.uiStates.isBrowseDrawerOpen);

  return (
    <>
      <TrackDiv
        id="browseTab"
        className={classNames({ [classes.navLink]: true, activeText: Boolean(isBrowseOpen) })}
        track={{ event: 'Browse Tab Clicked', source: 'top-nav', target: 'browse-drawer' }}
      >
        Browse
      </TrackDiv>
      {/* This Links the user to the News Page */}
      <TrackLink
        to="/news"
        className={classNames({ [classes.navLink]: true, active: pathname === '/news' && !isBrowseOpen })}
        track={{
          event: 'Navbar Calendar Clicked',
          source: 'top-nav',
          target: 'news-page',
        }}
      >
        News
      </TrackLink>
      {/* This Links the user to the ScheduleTable */}
      <TrackLink
        to="/calendar"
        className={classNames({ [classes.navLink]: true, active: pathname === '/calendar' && !isBrowseOpen })}
        track={{
          event: 'Navbar Calendar Clicked',
          source: 'top-nav',
          target: 'calendar-page',
        }}
      >
        Calendar
      </TrackLink>
      {/* This Links the user to the vods page */}
      <TrackLink
        to="/vods"
        className={classNames({ [classes.navLink]: true, active: pathname === '/vods' && !isBrowseOpen })}
        track={{
          event: 'Navbar VODS Clicked',
          source: 'top-nav',
          target: 'vods-page',
        }}
      >
        VODs
      </TrackLink>
      {Boolean(drawerView && user) && <UserDropdown />}
    </>
  );
};

export default TopNavItems;
