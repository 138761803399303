import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MdArrowDropDown } from 'react-icons/md';

import { TrackSpan } from '../common';

const styles = theme => ({
  wrapper: {
    position: 'relative',
    '& .root': {
      display: 'flex',
      width: 'inherit',
      alignItems: 'center',
      cursor: 'pointer',
      height: 40,
      boxSizing: 'border-box',
      border: `2px solid ${theme.palette.border.quinary}`,
      borderRadius: 4,
      background: theme.palette.background.octal,
      '& span': {
        marginLeft: 16,
        fontSize: 15,
        fontWeight: 600,
        lineHeight: '20px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '&.active': {
        '& span': {
          color: theme.palette.text.primary,
          fontWeight: 700,
        },
      },
    },
    '& .dropIcon': {
      fontSize: 24,
      margin: '8px 16px 8px auto',
      color: theme.palette.text.quinary,
    },
    '& .popper': {
      maxHeight: 410,
      overflowY: 'auto',
      borderBottom: `2px solid ${theme.palette.border.primary}`,
      position: 'absolute',
      top: 40,
      willChange: 'transform',
      transition: 'opacity 260ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 170ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      width: 'inherit',
      backgroundColor: theme.palette.background.primary,
      '& .dropdown': {
        borderRadius: 4,
        border: `2px solid ${theme.palette.border.quinary}`,
        borderBottom: 'none',
        borderTop: 'none',
        boxSizing: 'border-box',
        fontSize: '14px',
        listStyle: 'none',
        backgroundColor: theme.palette.background.primary,
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      '& .open': {
        height: 'auto',
        opacity: 1,
        transform: 'scale(1, 1) translateZ(0px)',
      },
      '&:not(.open)': {
        pointerEvents: 'none',
        opacity: 0,
        height: 0,
      },
    },
  },
});

class DropdownSelect extends Component {
  menuEl = React.createRef();
  parentEl = React.createRef();

  componentDidMount() {
    document.body.addEventListener('click', this.handleAnyClick);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleAnyClick);
  }

  handleAnyClick = ({ target }) => {
    if (!this.parentEl.current.contains(target)) {
      this.props.handleClose();
    }
  };

  render() {
    const {
      classes,
      className,
      children,
      active,
      placeholder,
      open,
      handleOpen,
      withArrowIcon,
      icon,
      track,
    } = this.props;

    return (
      <div className={`${classes.wrapper} ${className}`} ref={this.parentEl}>
        <TrackSpan
          className={classnames({ root: true, active })}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault(); // Stop from redirecting if parent is a link.

            this.menuEl.current.scrollTo(0, 0); // Show dropdown from the beginning.
            handleOpen();
          }}
          track={track}
        >
          {!!icon && icon}
          {placeholder && <span>{placeholder}</span>}
          {withArrowIcon && <MdArrowDropDown className="dropIcon" />}
        </TrackSpan>
        <div
          ref={this.menuEl}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault(); // Stop from redirecting if parent is a link.
          }}
          className={classnames({
            popper: true,
            open,
          })}
        >
          <div
            className={classnames({
              dropdown: true,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
}

DropdownSelect.propTypes = {
  children: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  active: PropTypes.bool,
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default withStyles(styles)(DropdownSelect);
