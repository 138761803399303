import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { trackEvent } from '../../store/actions/uiStates';

export default type => {
  const dispatch = useDispatch();
  const [isPickerOpen, setPickerOpen] = useState(false);

  const handleOnClick = useCallback(
    (toState = true) => e => {
      // Toggle picker and track if opening.
      setPickerOpen(toState);
      if (toState) {
        dispatch(trackEvent('Favorites Set Start', { type }));
      }
    },
    [dispatch, setPickerOpen, type]
  );
  const openGamesPicker = useCallback(handleOnClick(true), [handleOnClick]);
  const closeGamesPicker = useCallback(handleOnClick(false), [handleOnClick]);

  return [isPickerOpen, openGamesPicker, closeGamesPicker];
};
