import GoogleAnalytics from 'react-ga';

const ENV = process.env.REACT_APP_ENVIRONMENT;

const tokens = {
  production: 'UA-140960889-1',
  staging: 'UA-141300431-1',
};

if (tokens[ENV]) {
  GoogleAnalytics.initialize(tokens[ENV]);
}

export const trackPageGa = page => {
  if (!tokens[ENV]) {
    return;
  }
  GoogleAnalytics.set({ page });
  GoogleAnalytics.pageview(page);
};

export const setUserGA = ({ userId }) => {
  GoogleAnalytics.set({ userId });
};

export const clearUserGA = () => {
  GoogleAnalytics.set({ userId: null });
};

export const trackEventGa = options => GoogleAnalytics.event(options);

export default GoogleAnalytics;
