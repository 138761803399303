import React, { Component } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import { setCookieConsent } from '../store/actions/uiStates';
import { TrackButtonBasic, TrackLink } from './common';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  cookieConsentContainer: {
    display: 'flex',
    position: 'fixed',
    bottom: 80,
    backgroundColor: '#0A0B0B',
    color: '#000',
    margin: '1em 2px 1em 0',
    alignItems: 'center',
    borderRadius: '5px',
    zIndex: 999999,
    border: 'solid 1px #1E2424',
    height: 64,
  },
  cookieConsentMessage: {
    color: 'white',
    marginLeft: 24,
    maxWidth: '100%',
    display: 'inline-flex',
    fontSize: 15,
    lineHeight: '20px',
    textAlign: 'center',
  },
  cookieLearn: {
    color: 'white',
    opacity: '0.85',
    textDecoration: 'underline',
    marginLeft: 5,
    marginRight: 5,
  },
  buttonContainer: {
    border: 0,
    font: 'inherit',
    margin: 0,
    padding: 0,
    color: '#000',
  },
  button: {
    height: 32,
    width: 96,
    margin: '16px 32px 16px 24px',
    display: 'block',
    backgroundColor: '#B70B6C',
    color: '#fff',
    fontWeight: '700',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    fontSize: 15,
    cursor: 'pointer',
    borderRadius: 4,
    border: 'none',
  },
  '@media (max-width: 700px)': {
    cookieConsentContainer: {
      height: 80,
    },
    cookieConsentMessage: {
      display: 'unset',
      textAlign: 'left',
      fontSize: 10,
    },
    button: {
      height: 30,
      width: 80,
      fontSize: 14,
    },
  },
};

class CookieConsent extends Component {
  grantConsent = () => {
    this.props.setCookieConsent();
  };

  render() {
    const { classes, cookieConsent } = this.props;

    if (!!cookieConsent) {
      return null;
    }

    return (
      <div className={classes.container}>
        <div className={classes.cookieConsentContainer}>
          <span className={classes.cookieConsentMessage}>
            This website uses cookies to ensure you get the best and most relevant experience. To find out more, read
            our
            <TrackLink
              className={classes.cookieLearn}
              to="/terms-of-use"
              track={{
                event: 'Cookie Consent Link Clicked',
                source: 'cookie-consent',
                target: 'terms-page',
              }}
            >
              Terms of Use
            </TrackLink>
            and
            <TrackLink
              className={classes.cookieLearn}
              to="/privacy-policy"
              track={{
                event: 'Cookie Consent Link Clicked',
                source: 'cookie-consent',
                target: 'privacy-policy-page',
              }}
            >
              Privacy Policy
            </TrackLink>
          </span>

          <TrackButtonBasic
            className={classes.button}
            onClick={this.grantConsent}
            track={{
              event: 'Cookie Consent Granted',
              source: 'cookie-consent',
            }}
          >
            I Agree
          </TrackButtonBasic>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    state => ({
      cookieConsent: state.uiStates.cookieConsent,
    }),
    { setCookieConsent }
  )
)(CookieConsent);
