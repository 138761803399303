import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { TrackLink } from '../../common';

const JUKED_LOGO = '/images/Juked_logo.png';

const useStyles = makeStyles(theme => ({
  badge: {
    backgroundColor: 'transparent',
    backgroundImage: ({ imgSrc }) => `url(${imgSrc})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '50%',
    height: 32,
    width: 32,
    transition: '.2s ease-out',
    '&.icon': { borderRadius: 32 },
    '&:hover': {
      boxShadow: ({ animated, elevation }) => (animated ? `0px 2px ${elevation * 4}px 0 rgba(0,0,0,0.5)` : 'none'),
      transform: ({ animated }) => (animated ? 'scale(1.05)' : 'none'),
    },
  },
}));

export default ({ animated: _animated, className, disabled, elevation = 1, src, to, track }) => {
  const imgSrc = src || JUKED_LOGO;
  const animated = disabled ? false : Boolean(_animated);
  const classes = useStyles({ animated, elevation, imgSrc });
  const Badge = disabled ? props => <div {...props} /> : TrackLink; // Only use TrackLink if `to` is present.
  return (
    <Badge
      className={classnames({
        [classes.badge]: true,
        icon: !src,
        [className]: true,
      })}
      to={to}
      track={{
        event: 'Entity Badge Clicked',
        ...(track || {}),
      }}
    />
  );
};
