import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import { FaTwitter, FaInstagram, FaFacebookSquare, FaDiscord } from 'react-icons/fa';
import classNames from 'classnames';

import { TrackA, TrackLink } from './common';

const styles = theme => ({
  footer: {
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    height: 170,
    maxWidth: '100%',
    zIndex: 1000,
    '&.isVodsScreen': {
      display: 'none',
    },
    '&.isTheaterMode': {
      display: 'none',
    },
  },
  container: {
    maxWidth: 860,
    margin: '0 auto',
    paddingTop: 25,
    '& hr': {
      width: '100%',
      marginTop: 15,
    },
  },
  informationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    '& > a': {
      color: theme.palette.text.tertiary,
      fontSize: 17,
      fontWeight: 700,
      textDecoration: 'none',
    },
  },
  contactContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 25,
    color: 'white',
  },
  copyRightText: {
    fontSize: 12,
    paddingTop: 15,
    marginLeft: 0,
    color: theme.palette.text.tertiary,
  },
  socialMedia: {
    fontSize: 30,
    '& a': {
      color: theme.palette.text.tertiary,
      marginLeft: 19,
    },
  },
  // This will make the footer mobile friendly
  '@media (max-width: 860px)': {
    footer: {
      height: '100%',
      '& hr': {
        width: 200,
      },
    },
    informationContainer: {
      flexDirection: 'column',
      textAlign: 'center',
      '& a': {
        marginBottom: 10,
      },
    },
    contactContainer: {
      flexDirection: 'column',
      textAlign: 'center',
    },
    copyRightText: {
      marginBottom: 20,
      paddingTop: 0,
      marginLeft: 0,
      marginRight: 0,
    },
    socialMedia: {
      fontSize: 35,
      '& a': {
        margin: 10,
        marginBottom: 20,
      },
    },
  },
});

const getTrackLink = target => ({
  event: 'Footer Link Clicked',
  eventProperties: { type: 'link' },
  source: 'footer',
  target,
});

const getTrackSocial = target => ({
  event: 'Footer Link Clicked',
  eventProperties: { type: 'social' },
  source: 'footer',
  target,
});

const Footer = ({ classes, isTheaterMode }) => {
  //To know if we are in vods page. This will apply the display:fixed to the footer
  const currentPath = window.location.pathname;
  const isVodsScreen = currentPath.includes('vods') && !currentPath.includes('vods/watch');

  return (
    <div
      className={classNames({
        [classes.footer]: true,
        isVodsScreen,
        isTheaterMode,
      })}
    >
      <div className={classes.container}>
        <div className={classes.informationContainer}>
          <TrackLink to="/about-us" track={getTrackLink('about-page')}>
            About
          </TrackLink>
          <TrackLink to="/privacy-policy" track={getTrackLink('privacy-policy-page')}>
            Privacy Policy
          </TrackLink>
          <TrackLink to="/terms-of-use" track={getTrackLink('terms-page')}>
            Terms
          </TrackLink>
          <TrackA
            href="https://juked.kampsite.co/"
            target="_blank"
            rel="noopener noreferrer"
            track={getTrackLink('feature-request')}
          >
            Feature Request
          </TrackA>
          <TrackA
            href="https://form.asana.com/?k=K_SncbzOKT1a8SRjSJIQLg&d=720668988008733"
            target="_blank"
            rel="noopener noreferrer"
            track={getTrackLink('submit-event')}
          >
            Submit an Event
          </TrackA>
          <TrackA
            href="https://medium.com/jukedgg/announcing-the-juked-discord-bot-3bd9f2ea931e"
            target="_blank"
            rel="noopener noreferrer"
            track={getTrackLink('discord-bot')}
          >
            Try Our Discord Bot
          </TrackA>
          <TrackA href="mailto:contact@juked.gg?subject=Juked%20support%20team" track={getTrackLink('contact-us')}>
            Contact Us
          </TrackA>
        </div>
        <hr size="1" />
        <div className={classes.contactContainer}>
          <span className={classes.copyRightText}>Copyright &copy;{new Date().getFullYear()}. All rights Reserved</span>
          <span className={classes.socialMedia}>
            <TrackA
              href="https://twitter.com/JukedGG"
              rel="noopener noreferrer"
              target="_blank"
              track={getTrackSocial('twitter')}
            >
              <FaTwitter />
            </TrackA>
            <TrackA
              href="https://www.facebook.com/JukedGG/"
              rel="noopener noreferrer"
              target="_blank"
              track={getTrackSocial('facebook')}
            >
              <FaFacebookSquare />
            </TrackA>
            <TrackA
              href="https://www.instagram.com/JukedEsports/"
              rel="noopener noreferrer"
              target="_blank"
              track={getTrackSocial('instagram')}
            >
              <FaInstagram />
            </TrackA>
            <TrackA
              href="https://discord.gg/UwzFC4S"
              rel="noopener noreferrer"
              target="_blank"
              track={getTrackSocial('discord')}
            >
              <FaDiscord />
            </TrackA>
          </span>
        </div>
      </div>
    </div>
  );
};

export default compose(
  withStyles(styles),
  connect(state => ({
    isTheaterMode: state.video.isTheaterMode,
  }))
)(Footer);
