import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import GameFilterSelect from '../../Filters/GameFilterSelect';
import SearchFilter from '../../Filters/SearchFilter';
import { getPreferredGameIds } from '../../../store/selectors/user';
import { setArticleFilters } from '../../../store/actions/uiStates';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.secondary,
    width: '100%',
    height: 64,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  filtersContainer: {
    width: '100%',
    maxWidth: 1260,
    display: 'flex',
    margin: 'auto',
    position: 'relative',
    color: theme.palette.text.primary,
  },
  dropdowns: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  gameFilter: {
    textAlign: 'initial',
    zIndex: 400, // To beat card container
  },
  isDisabled: {
    opacity: '24%',
    pointerEvents: 'none',
  },
}));

const Filters = ({ selectedGames, setSelectedGames, isFavoritesSelected, setIsFavoritesSelected, search }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const preferredGamesIds = useSelector(getPreferredGameIds);
  const isFollowMode = useSelector(state => state.uiStates.isFollowMode);

  const onGameChange = gameId => {
    setIsFavoritesSelected(false);
    if (!gameId) {
      setSelectedGames([]);
    } else {
      const gamesSelected = selectedGames.includes(gameId)
        ? selectedGames.filter(game => game !== gameId)
        : [...selectedGames, gameId];
      setSelectedGames(gamesSelected);
    }
  };

  const handleSearchChange = search => {
    dispatch(setArticleFilters({ search }));
  };

  useEffect(() => {
    if (isFollowMode) {
      setSelectedGames(preferredGamesIds);
      return setIsFavoritesSelected(true);
    }
    setSelectedGames([]);
    setIsFavoritesSelected(false);
  }, [isFollowMode, preferredGamesIds, setIsFavoritesSelected, setSelectedGames]);

  return (
    <div className={classes.root}>
      <div className={classes.filtersContainer}>
        <SearchFilter
          searchedText={search}
          placeHolder="Search content and news"
          onClearSearch={() => handleSearchChange()}
          onSubmit={searchTerm => handleSearchChange(searchTerm)}
        />
        <div className={classes.dropdowns}>
          <GameFilterSelect
            className={classnames({
              [classes.gameFilter]: true,
              [classes.isDisabled]: isFollowMode,
            })}
            selectedGames={isFavoritesSelected ? preferredGamesIds : selectedGames}
            onItemClick={onGameChange}
            source="news-page"
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
