import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { StylesProvider, createGenerateClassName } from '@material-ui/styles';

import App from './App';
import history from './history';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from './store';
import { disableReactDevTools } from './utils/disableReactDevTools';
import { initFeatureFlags } from './utils/featureFlags';

import 'typeface-source-sans-pro';

if (!['development', 'staging', 'test'].includes(process.env.REACT_APP_ENVIRONMENT)) {
  disableReactDevTools();
}

const generateClassName = createGenerateClassName({
  productionPrefix: 'juked',
});

initFeatureFlags().then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <StylesProvider generateClassName={generateClassName}>
          <Router history={history}>
            <App />
          </Router>
        </StylesProvider>
      </PersistGate>
    </Provider>,

    document.getElementById('root')
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
