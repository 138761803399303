import { createMuiTheme } from '@material-ui/core/styles';

// Named according to: https://www.htmlcsscolor.com/hex
const colors = {
  teal: '#05C7CC',
  lightTeal: '#40D4D8',
  lighterTeal: '#A4EAEC',
  darkFuscia: '#9D0C5E',
  fuscia: '#B70B6C',
  lightFuscia: '#C8458F',
  lighterFuscia: '#E5A6C9',
  trueBlack: '#000000',
  black: '#0A0B0B',
  darkSwampBlack: '#131414',
  swampBlack: '#161717',
  lightSwampBlack: '#1E2424',
  lighterSwampBlack: '#252929',
  dimGray: '#6C6C6C',
  suvaGray: '#8E8E8E',
  nobelGray: '#9B9A9A',
  veryLightGray: '#CECECE',
  whisperGray: '#EBEBEB',
  darkWhiteSmokeGray: '#EFEFEF',
  whiteSmokeGray: '#F5F5F5',
  white: '#FFFFFF',
};

const baseTheme = createMuiTheme({
  colors,
  contentHeight: 'calc(100vh - 170px - 48px - 60px)', // footer 170, top nav 48, top ticker 60.
  palette: {
    primary: {
      main: colors.teal,
      active: colors.teal,
      hover: colors.lightTeal,
      disabled: colors.lighterTeal,
    },
    secondary: {
      main: colors.darkFuscia,
      active: colors.fuscia,
      hover: colors.lightFuscia,
      disabled: colors.lighterFuscia,
    },
  },
});

export const lightTheme = createMuiTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    background: {
      primary: colors.whiteSmokeGray,
      secondary: colors.whisperGray,
      tertiary: colors.white,
      quartary: colors.swampBlack,
    },
    border: {
      primary: colors.darkWhiteSmokeGray,
      secondary: colors.veryLightGray,
    },
    shimmer: {
      primary: colors.white,
      secondary: '#F6F7F9', // aliceBlueGray
      tertiary: '#E9EBEE', // solitudeGray
    },
    text: {
      primary: colors.black,
      secondary: colors.dimGray,
      tertiary: colors.white,
      disabled: colors.nobelGray,
    },
  },
});

export const darkTheme = createMuiTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    type: 'dark',
    background: {
      primary: colors.black,
      secondary: colors.darkSwampBlack,
      tertiary: colors.swampBlack,
      quartary: colors.trueBlack,
    },
    border: {
      primary: colors.lightSwampBlack,
      secondary: colors.lighterSwampBlack,
    },
    shimmer: {
      primary: colors.dimGray,
      secondary: '#3B3B3B', // eclipseBlack
      tertiary: colors.dimGray,
    },
    text: {
      primary: colors.white,
      secondary: colors.suvaGray,
      tertiary: colors.black,
      disabled: colors.dimGray,
    },
  },
});
