import { createMuiTheme } from '@material-ui/core/styles';

export const TOP_NAV_HEIGHT = 48;
export const TOP_TICKER_HEIGHT = 60;

const commonColors = createMuiTheme({
  black: '#0A0B0B',
  lightGrey: ' #F5F5F5',
  darkCharcoal: '#161717',
  mediumGrey: '#E2E2E2',
  whisperGray: '#EBEBEB',
  lightCementGrey: '#CECECE',
  darkGrey: '#6C6C6C',
  blackGrey: '#3B3B3B',
  smokedLightGrey: '#808080',
  smokedDarkGrey: '#1E2424',
  swampBlack: ' #161717',
  darkSwampBlack: '#131414',
  suvaGray: '#8E8E8E',
  lightCharcoalGrey: '#18181B',
  mediumCharcoalGrey: '#2E2F2F',
  darkCharcoalGrey: '#282828',
  charcoalGrey: '#9D9D9D',
  darkTeal: '#01717a',
  lightTeal: '#05c7cc',
  brandOrange: '#eba200',
  darkPink: '#6b0847',
  lightPink: '#b71d80',
  redPink: '#ed3b4d',
  magentaPink: '#B70B6C',
  winePink: '#9D0C5E',
  darkMagenta: '#93055F',
  magenta: '#F0039A',
  fuchsia: '#FF00FF',
  contentHeight: `calc(100vh - 170px - ${TOP_NAV_HEIGHT}px - ${TOP_TICKER_HEIGHT}px)`, // footer 170, top nav 48, top ticker 60.
  topTickerHeight: TOP_TICKER_HEIGHT,
  topNavHeight: TOP_NAV_HEIGHT,
  gradientBackground: 'radial-gradient(ellipse at bottom right,#93055F 10%,transparent 90%), #01717a ',
  palette: {
    primary: {
      main: '#05c7cc', // lightTeal
      dark: '#01717a', // darkTeal
    },
    secondary: {
      main: '#b71d80', // lightPink
      dark: '#6b0847', // darkPink
    },
  },
});

export const darkTheme = createMuiTheme({
  ...commonColors,
  //For reference: https://material-ui.com/customization/palette/
  palette: {
    type: 'dark',
    background: {
      primary: commonColors.black,
      secondary: commonColors.darkCharcoal,
      tertiary: '#252929',
      quartary: commonColors.lightCharcoalGrey,
      quinary: commonColors.darkCharcoal,
      senary: commonColors.black,
      septenary: commonColors.smokedDarkGrey,
      octal: commonColors.mediumCharcoalGrey,
      nonary: commonColors.mediumCharcoalGrey,
      denary: commonColors.black,
      soloDenary: commonColors.black,
      duoDenary: commonColors.darkSwampBlack,
      ternidenary: commonColors.darkCharcoal,
      arrowButton: commonColors.darkCharcoal,
      default: 'black',
    },
    text: {
      secondary: commonColors.lightGrey,
      tertiary: commonColors.charcoalGrey,
      quartary: commonColors.lightGrey,
      quinary: 'white',
      senary: commonColors.fuchsia,
      septenary: 'white',
      octal: 'white',
      nonary: commonColors.darkGrey,
      denary: commonColors.fuchsia,
      soloDenary: 'white',
      duoDenary: commonColors.suvaGray,
      default: 'white',
    },
    border: {
      primary: commonColors.darkCharcoal,
      secondary: commonColors.lightCharcoalGrey,
      tertiary: commonColors.darkGrey,
      quartary: commonColors.darkCharcoal,
      quinary: 'none',
      senary: 'none',
      septenary: commonColors.smokedDarkGrey,
      octal: 'transparent',
      nonary: commonColors.blackGrey,
      denary: 'none',
      soloDenary: '#252929',
      default: 'none',
    },
    shimmer: {
      primary: commonColors.darkGrey,
      secondary: commonColors.blackGrey,
      tertiary: commonColors.darkGrey,
    },
    signInButton: commonColors.darkCharcoalGrey,
    signInInput: {
      primary: commonColors.darkCharcoalGrey,
    },
    inputIcon: {
      primary: 'white',
    },
    favoriteGame: {
      primary: commonColors.smokedDarkGrey,
    },
    featuredSection: {
      primary: commonColors.black,
    },
    button: {
      primary: commonColors.smokedDarkGrey,
    },
    participantDefaultIcon: commonColors.smokedDarkGrey,
  },
});

export default createMuiTheme({
  ...commonColors,
  //For reference: https://material-ui.com/customization/palette/
  palette: {
    type: 'light',
    background: {
      primary: 'white',
      secondary: 'white',
      tertiary: commonColors.mediumGrey,
      quartary: 'white',
      quinary: commonColors.lightGrey,
      senary: commonColors.lightGrey,
      septenary: commonColors.mediumCharcoalGrey,
      octal: 'white',
      nonary: commonColors.lightGrey,
      denary: '#171717',
      soloDenary: commonColors.whisperGray,
      duoDenary: 'white',
      ternidenary: commonColors.lightCementGrey,
      arrowButton: commonColors.lightCementGrey,
      default: commonColors.darkPink,
    },
    text: {
      primary: 'black',
      secondary: commonColors.darkGrey,
      tertiary: 'white',
      quartary: commonColors.blackGrey,
      quinary: commonColors.darkGrey,
      senary: 'blue',
      septenary: commonColors.smokedLightGrey,
      octal: commonColors.lightTeal,
      nonary: 'black',
      denary: commonColors.darkGrey,
      soloDenary: commonColors.black,
      duoDenary: commonColors.black,
      default: commonColors.darkPink,
    },
    border: {
      primary: commonColors.mediumGrey,
      secondary: commonColors.lightGrey,
      tertiary: commonColors.smokedLightGrey,
      quartary: 'transparent',
      quinary: commonColors.mediumGrey,
      senary: '#444',
      septenary: '#D3D3D3',
      octal: commonColors.lightTeal,
      nonary: commonColors.mediumGrey,
      denary: commonColors.smokedLightGrey,
      soloDenary: commonColors.lightCementGrey,
      default: commonColors.whisperGray,
    },
    shimmer: {
      primary: 'white',
      secondary: '#f6f7f9',
      tertiary: '#e9ebee',
    },
    signInButton: 'white',
    signInInput: {
      primary: 'white',
    },
    inputIcon: {
      primary: commonColors.charcoalGrey,
    },
    favoriteGame: {
      primary: commonColors.lightGrey,
    },
    featuredSection: {
      primary: '#EFEFEF',
    },
    button: {
      primary: 'white',
    },
    participantDefaultIcon: commonColors.smokedLightGrey,
  },
});
