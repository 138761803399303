import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import { TrackLink, BodyText, GameBadge, OrganizationBadge, TeamBadge } from '../../../common';
import { useMediaQuery } from '../../../../hooks';
import { ENTITY_TYPES } from '../../../../store/actions/user';
import { toggleBrowseDrawer } from '../../../../store/actions/uiStates';

const SOURCES = {
  [ENTITY_TYPES.GAME]: 'game-item',
  [ENTITY_TYPES.ORGANIZATION]: 'organization-item',
  [ENTITY_TYPES.TEAM]: 'team-item',
};

const TARGETS = {
  [ENTITY_TYPES.GAME]: 'game-page',
  [ENTITY_TYPES.ORGANIZATION]: 'organization-page',
  [ENTITY_TYPES.TEAM]: 'team-page',
};

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    textDecoration: 'none',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    borderRadius: 4,
    marginRight: 12,
  },
}));

const Badge = ({ className, entity, entityType }) => {
  switch (entityType) {
    case ENTITY_TYPES.GAME:
      return <GameBadge className={className} disabled game={entity} />;
    case ENTITY_TYPES.ORGANIZATION:
      return <OrganizationBadge className={className} disabled organization={entity} />;
    case ENTITY_TYPES.TEAM:
      return <TeamBadge className={className} disabled team={entity} />;
    default:
      throw new Error(`entityField not supported: ${entityType}`);
  }
};

const ContentItemLink = ({ entity, entityType, name, onClick, to }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)'); // XSM Breakpoint

  return (
    <TrackLink
      className={`${classes.root} ${classes.flex}`}
      onClick={e => {
        onClick && onClick(e);
        if (isMobile) {
          dispatch(toggleBrowseDrawer());
        }
      }}
      to={to}
      track={{
        event: 'Browse Drawer Item Clicked',
        eventProperties: {
          entity_id: entity.id,
          entity_name: entity.name,
          entity_type: entityType,
        },
        source: SOURCES[entityType],
        target: TARGETS[entityType],
      }}
    >
      <Badge className={classes.badge} entity={entity} entityType={entityType} />
      <BodyText variant="largeSemiBold">{name}</BodyText>
    </TrackLink>
  );
};

export default ContentItemLink;
