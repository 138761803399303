import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getCalendarSections } from '../../store/selectors/calendar';
import { getPreferredGameIds, isSignedIn } from '../../store/selectors/user';
import { CALENDAR_FILTERS, CALENDAR_LINK_FILTERS } from '../../store/reducers/uiStates';

import CollapsibleSection from './CollapsibleSection';
import DateSection, { LIVE } from './DateSection';
import { addDays } from 'date-fns';
import { ShimmerDiv } from '../common/Shimmer';

const styles = theme => ({
  root: {
    margin: 'auto',
    maxWidth: 1232,
    width: '100%',
  },
  sectionContent: {},
  placeholderRow: {
    height: 62,
    borderRadius: 8,
    margin: '20px 0',
    boxShadow: '1px 1px 6px 0px rgba(0,0,0,0.3)',
  },
  nothingMsg: {
    color: theme.palette.text.primary,
  },
  [theme.breakpoints.down('sm')]: {
    sectionContent: {
      margin: 'unset',
      padding: '0',
    },
    root: {
      margin: 0,
    },
  },
});

// Some of the table's contents are loaded dynamically using date-range queries, so
// it's hard to definitively say if it's done loading or not found.
const LOAD_STATUS = {
  LOADING: 'LOADING',
  NOT_FOUND: 'NOT_FOUND',
};

class Table extends Component {
  state = {
    loadStatus: LOAD_STATUS.LOADING,
  };

  componentDidMount() {
    this.startTimingLoading();
  }

  componentWillUnmount() {
    if (this.loadingTimeout) {
      clearTimeout(this.loadingTimeout);
    }
  }

  componentDidUpdate(prevProps) {
    this.startTimingLoading(prevProps);
  }

  // If there's no series now, check again in a couple seconds and if still nothing then display not-found message
  startTimingLoading = props => {
    const { calendarView } = this.props;

    if (!props || calendarView !== props.calendarView) {
      if (this.loadingTimeout) {
        clearTimeout(this.loadingTimeout);
      }

      // first set animation to loading
      this.setState({ loadStatus: LOAD_STATUS.LOADING });
      this.loadingTimeout = setTimeout(() => {
        if (!props || calendarView !== (props && props.calendarView)) {
          this.setState({ loadStatus: LOAD_STATUS.NOT_FOUND });
        }
      }, 1000);
    }
  };

  renderNotFound = () => {
    const {
      calendarFilter,
      calendarView,
      classes,
      isSignedIn,
      preferredGameIds,
      searchInput,
      seriesRange,
    } = this.props;

    if (!seriesRange || !seriesRange.length) {
      if (!!searchInput) {
        return <h3 className={classes.nothingMsg}>No search results.</h3>;
      }
      let emptyTableText;

      if (!!isSignedIn && calendarFilter === CALENDAR_FILTERS.FAVORITES && preferredGameIds.length === 0) {
        emptyTableText = (
          <>
            You didn't set any followed games in your Profile yet, nerd! Head over to your{' '}
            <Link to="/profile?page=following">Profile</Link> to set them up.
          </>
        );
      } else if (calendarView === CALENDAR_LINK_FILTERS.WEEK) {
        emptyTableText = 'No events this week. Try the following week.';
      } else if (calendarView === CALENDAR_LINK_FILTERS.DAY) {
        emptyTableText = 'No events today. Check the Week view.';
      } else {
        emptyTableText = 'Nothing to show.';
      }

      return <h3 className={classes.nothingMsg}>{emptyTableText}</h3>;
    } else {
      return null;
    }
  };

  getSectionSeries = (section, showAll) => {
    if (showAll) {
      return section.series.sort((a, b) => {
        // This is avery specifyc guard when merging to new environments in wich the processor hasn't triggered.
        if (!a.tournament.start || !b.tournament.start) {
          return 0;
        }
        return a.tournament.start.seconds - b.tournament.start.seconds;
      });
    }
    return section.series;
  };

  render() {
    const { classes } = this.props;

    return <div className={classes.root}>{this.renderContent()}</div>;
  }

  renderContent = () => {
    const { classes, liveSeries, sections, isMatchView, hideLive, calendarView, searchInput } = this.props;
    const { loadStatus } = this.state;
    const showAll = calendarView === CALENDAR_LINK_FILTERS.ALL && !searchInput;
    const showWeek = calendarView === CALENDAR_LINK_FILTERS.WEEK && !searchInput;

    if (loadStatus === LOAD_STATUS.LOADING) {
      return this.renderShimmerPlaceholders();
    }

    // If there's a live section but no upcoming section, return that directly.
    if (!hideLive && sections.length === 0 && liveSeries.length > 0) {
      return (
        <DateSection
          key={LIVE}
          date={LIVE}
          isMatchView={isMatchView}
          sectionSeries={liveSeries}
          contentClassName={classes.sectionContent}
          showWeek={showWeek}
        />
      );
    }

    let isLiveSectionCreated = false;

    return (
      <>
        {this.renderNotFound()}

        {sections.map(section => {
          const today = new Date().toDateString();
          const todaysDate = new Date(today);
          const groupDate = new Date(section.date);

          const calendarSections = [];

          // If we're about to print section for today or past today, prepend the live section first.
          // Live section will not show on HomePage if next upcoming section is over a week from today.
          if (!hideLive && !isLiveSectionCreated && groupDate >= todaysDate && groupDate <= addDays(todaysDate, 6)) {
            calendarSections.push(
              <DateSection
                key={LIVE}
                date={LIVE}
                isMatchView={isMatchView}
                sectionSeries={liveSeries}
                contentClassName={classes.sectionContent}
                showWeek={showWeek}
              />
            );
            isLiveSectionCreated = true;
          }
          const sectionSeries = this.getSectionSeries(section, showAll);

          calendarSections.push(
            <DateSection
              key={section.date}
              date={section.date}
              title={section.title}
              sectionDate={section.subtitle}
              isMatchView={isMatchView}
              sectionSeries={sectionSeries}
              contentClassName={classes.sectionContent}
              isMonthlySection={showAll}
              showWeek={showWeek}
            />
          );

          return calendarSections;
        })}
      </>
    );
  };

  renderShimmerPlaceholders = () => {
    const { classes } = this.props;
    return (
      <CollapsibleSection className={classes.section} contentClassName={classes.sectionContent}>
        {Array.from(Array(3)).map((_, index) => (
          <ShimmerDiv key={index} className={classes.placeholderRow} />
        ))}
      </CollapsibleSection>
    );
  };
}
export default compose(
  withStyles(styles),
  connect((state, { seriesRange, searchInput, calendarView }) => ({
    calendarFilter: state.uiStates.calendarFilter,
    isSignedIn: isSignedIn(state),
    preferredGameIds: getPreferredGameIds(state),
    sections: getCalendarSections(state, { seriesRange, searchInput, calendarView }),
  }))
)(Table);
