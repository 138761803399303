import { call, cancel, delay, put, select, takeLeading } from 'redux-saga/effects';

import {
  FETCH_TWEETS_REQUEST,
  fetchTweetsSkipped,
  fetchTweetsSuccess,
  fetchTweetsFailure,
  POST_TWEET_REQUEST,
  postTweetSuccess,
  postTweetFailure,
} from '../actions/twitter';
import { axiosParse } from '../../utils/api';

function* fetchTweets({ firstId, lastId }) {
  const {
    twitter: {
      tweets: { fetchedAt },
    },
  } = yield select();

  // Only fetch if not fetched, or 1 min has passed since last fetch.
  if (!fetchedAt || Date.now() - fetchedAt >= 1 * 60 * 1000) {
    try {
      // Only merge data if we're fetching additional tweets.
      const hasIds = !!(firstId && lastId);
      const params = hasIds ? { firstId, lastId } : null;
      const { data } = yield call(() => axiosParse.get('/twitter/tweets', { params }));
      yield put(fetchTweetsSuccess(data, hasIds));
    } catch (e) {
      yield put(fetchTweetsFailure(e));
      yield cancel();
    }
  } else {
    // Give the loading animation a few seconds to spin.
    yield delay(5000);
    yield put(fetchTweetsSkipped());
  }
}

function* postTweet({ text }) {
  try {
    const { data } = yield call(() => axiosParse.post('/twitter/tweets', { text }));
    yield put(postTweetSuccess(data));
  } catch (e) {
    yield put(postTweetFailure(e));
    yield cancel();
  }
}

export default function* twitterSagas() {
  yield takeLeading(POST_TWEET_REQUEST, postTweet);
  yield takeLeading(FETCH_TWEETS_REQUEST, fetchTweets);
}
