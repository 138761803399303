import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { trackEvent } from '../../store/actions/uiStates';

/**
 * A hook that returns a onClick handler function factory.  The factory takes
 * track and onClick properties and returns an onClick handler. This is so that
 * you can call the hook in a function body and pass the factory around.
 */
export const useGetTrackClick = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const match = useRouteMatch();

  const getTrackClick = ({ track, onClick = null }) => e => {
    const { pathname, search, hash } = location;
    const { event, eventProperties, eventOptions, source, target } = track || {};

    !!onClick && onClick(e);

    if (!!event) {
      dispatch(
        trackEvent(
          event,
          {
            source,
            target,
            url: pathname + search + hash,
            url_path: match.path,
            url_base: pathname,
            ...(eventProperties || {}),
          },
          eventOptions
        )
      );
    }
  };

  // Because we are returning a callback, we should memoize with useCallback
  // to prevent child from rendering unecessarily due to changed reference.
  return useCallback(getTrackClick, [dispatch, location, match]);
};

/**
 * Convenience function when you only need the onClick callback handler.
 */
export const useTrackClick = args => useGetTrackClick()(args);

/**
 * A HOC that defines some standard tracking parameters and applies them to our trackEvent action.
 * @param {Component} WrappedComponent component to be wrapped
 * @prop {object} track
 * @prop {string} track.event event name, REQUIRED to track an event
 * @prop {object} track.eventProperties custom data passed through to trackEvent and sent to tracking backend.
 * @prop {object} track.eventOptions tracking config options like: `contextSeriesID`. Check trackEvent comments.
 * @prop {string} track.source standard property to denote origin of event; folded into eventProperties.
 * @prop {string} track.target standard property to denote destination or result of event; folded into eventProperties.
 */
const withTrackClick = WrappedComponent => ({ onClick, track, ...props }) => {
  const { disabled } = props; // Pass these through.
  const trackClick = disabled ? onClick : useTrackClick({ track, onClick });
  return <WrappedComponent onClick={trackClick} {...props} />;
};

export default withTrackClick;
