import React from 'react';
import { useSelector } from 'react-redux';

import GameContentItem from './GameContentItem';
import TeamContentItem from './TeamContentItem';
import { getGames } from '../../../../store/selectors/gameData';
import { parseSearchResult } from '../../../../utils/search';

const SearchResultItem = ({ className, result }) => {
  const entity = parseSearchResult({ result });
  const games = useSelector(getGames);

  switch (entity.entityType) {
    case 'teams':
      const game = entity.game && games[entity.game];
      const suffix = game && !game.hidden ? ` (${game.abbr})` : '';
      return <TeamContentItem className={className} team={entity} name={`${entity.name}${suffix}`} />;
    case 'games':
      return <GameContentItem className={className} game={entity} />;
    default:
      console.error(`[SearchResultItem] unsupported engine: ${entity.entityType}`);
      return null;
  }
};

export default SearchResultItem;
