import {
  SET_PREFERENCES_ACCOUNT_STATUS,
  SET_PREFERENCES_ERROR,
  SET_SPORT_BUFF_TOKEN,
  SET_USER,
  SIGN_OUT,
  UPDATE_FCM_TOKEN,
  UPDATE_USER,
  PreferencesErrors,
  UserActionTypes,
} from '../actions/user';
import { User as UserModel } from '../types';

interface UserStateBase {
  fcmToken: string | null;
  fetched?: boolean;
  preferencesAccountStatus: string | null;
  preferencesErrors: PreferencesErrors;
  isSignedIn?: boolean;
  sportBuffToken?: string;
}

export type UserState = UserModel & UserStateBase;

export const PREFERENCE_TYPES = {
  FAVORITE_GAMES: 'favoriteGames',
};

export const LOGIN_SOURCES = {
  CONTESTS: 'contests',
  PROMPT: 'prompt',
  LOGIN_GATE: 'login-gate',
  SIGNUP_PAGE: 'signup-page',
  DIALOG_NEW_FEATURE: 'new-feature',
  // DIALOG_ANNOUNCEMENT: 'dialog-announcement',
};

// `isSignedIn` is intentionally undefined, to indicate that auth provider
// is still loading user data and we don't quite know yet if they're signed in or not.
export const initialState: UserState = {
  email: null, // Required by User type
  fcmToken: null,
  joined: null, // Required by User type
  preferencesAccountStatus: null,
  preferencesErrors: {},
  uid: null, // Required by User type
};

export default (state = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case SET_PREFERENCES_ACCOUNT_STATUS:
      return {
        ...state,
        preferencesAccountStatus: action.status,
      };
    case SET_PREFERENCES_ERROR: {
      const { errors } = action;
      return {
        ...state,
        preferencesErrors: {
          ...state.preferencesErrors,
          ...errors, // Merge in errors.
        },
        preferencesAccountStatus: null,
      };
    }
    case SET_USER: {
      const { user } = action;
      if (!user) {
        return {
          ...state,
          isSignedIn: false,
        };
      }
      return {
        ...state,
        ...user,
        isSignedIn: true,
      };
    }
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case SIGN_OUT:
      return initialState;
    case UPDATE_FCM_TOKEN:
      return {
        ...state,
        fcmToken: action.fcmToken,
      };
    case SET_SPORT_BUFF_TOKEN:
      return {
        ...state,
        sportBuffToken: action.token,
      };
    default:
      return state;
  }
};
