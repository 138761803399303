import DOMPurify from 'dompurify';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { getGames } from '../../../store/selectors/gameData';
import { GameTag, TrackLink } from '../../common';
import StreamCardButtons from '../StreamCardButtons';
import LineClamp from '../../common/LineClamp';
import { getTournamentUrl } from '../../../utils/urls';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    background: theme.palette.background.primary,
    height: 494,
    width: 368,
    marginLeft: 16,
    borderRadius: 8,
    border: `1px solid ${theme.palette.border.soloDenary}`,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: 40,
    '& img': {
      width: 56,
      height: 56,
      marginBotttom: 8,
    },
    '& > a': {
      textDecoration: 'none',
    },
  },
  tournamentTitle: {
    color: theme.palette.text.primary,
    fontSize: 24,
    lineHeight: '32px',
    marginTop: 8,
    marginBottom: 16,
    lineClamp: 2,
  },
  tournamentGame: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  divider: {
    height: 1,
    background: theme.palette.border.soloDenary,
    width: 251,
    marginLeft: 16,
  },
  description: {
    color: theme.palette.text.soloDenary,
    margin: 'unset',
    fontSize: 13,
    lineHeight: '18px',
    textAlign: 'left',
    marginBottom: 46,
    height: 72,
    lineClamp: 4,
    '& p': {
      margin: 'unset',
    },
  },
  [theme.breakpoints.down('md')]: {
    cardContainer: {
      width: 350,
      marginTop: 20,
      marginLeft: 'unset',
    },
  },
}));

const StreamInformationCard = ({ aSeries }) => {
  const classes = useStyles();

  const gameList = useSelector(state => getGames(state));
  const tournamentGame = gameList[aSeries.tournament.game];

  // sanitize to avoid XSS, then remove all p-br-p sequences for unnecessary blank spaces.
  const getEventDescription = description => DOMPurify.sanitize(description).replace(/<p><br><\/p>/g, '');

  if (!aSeries.tournament) {
    return null;
  }

  return (
    <div className={classes.cardContainer}>
      <div className={classes.content}>
        <TrackLink
          to={getTournamentUrl(aSeries.tournament)}
          track={{
            event: 'Single View Card Link Clicked',
            source: 'single-view-card-link',
            target: 'tournament-page',
          }}
        >
          {aSeries.tournament.images?.square && <img src={aSeries.tournament.images.square} alt="tournament" />}
          <LineClamp text={aSeries.tournament.title} className={classes.tournamentTitle} />
        </TrackLink>
        <span className={classes.tournamentGame}>
          <GameTag game={tournamentGame} seriesId={aSeries.id} />
          <span className={classes.divider}></span>
        </span>

        <LineClamp
          dangerouslySetInnerHTML={{
            __html: getEventDescription(aSeries.tournament.description),
          }}
          className={classes.description}
        />

        <StreamCardButtons aSeries={aSeries} />
      </div>
    </div>
  );
};

export default StreamInformationCard;
