// up to 16 colors for each Contest option buttons.

export default index => {
  return [
    'lightTeal',
    'magenta',
    'tomato',
    'orange',
    'green',
    'purple',
    'pink',
    'blue',
    'firebrick',
    'fuchsia',
    'goldenrod',
    'orangered',
    'royalblue',
    'seagreen',
    'sienna',
    'turquoise',
    'salmon',
  ][index];
};
