import React from 'react';
import { compose } from 'recompose';
import { MdClear } from 'react-icons/md';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  container: {
    alignItems: 'center',
    backgroundColor: theme.lightPink,
    borderRadius: 4,
    color: 'white',
    display: 'flex',
    margin: 4,
    padding: 8,
    pointerEvents: 'none',
    marginRight: 10,
    position: 'relative',
  },
  icon: {
    cursor: 'pointer',
    fontSize: 16,
    marginRight: 8,
    pointerEvents: 'auto',
  },
  label: {
    fontSize: '13px',
    marginRight: 5,
  },
});

const PickerChip = ({ classes, className, label, onClear }) => (
  <div className={`${classes.container} ${className}`}>
    <MdClear className={classes.icon} onClick={onClear} />
    <div className={classes.label}>{label}</div>
  </div>
);

export default compose(withStyles(styles))(PickerChip);
