import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';

import { useFirestoreGetMap } from '../hooks';
import { subscribeToQuery, unsubscribeToQuery } from '../store/actions/gameData';
import {
  getLiveSeriesByParticipants,
  getVodSeriesByParticipants,
  getUpcomingSeriesByParticipants,
} from '../store/selectors/organizations';
import { FOLLOW_FIELDS } from '../store/actions/user';
import { getGames } from '../store/selectors/gameData';
import { TeamBreadcrumb } from '../components/common/Breadcrumbs';
import { MainSectionTitle, SectionBackground, GameTag, Tooltip, EntityArticleSection } from '../components/common';
import FollowHeader from '../components/ContentHeaders';
import HeaderTab, { LABELS, SLUGS } from '../components/ContentHeaders/HeaderTab';
import { PlayerCard } from '../components/Teams';
import VideoSwatch from '../components/VideoSwatch/VideoSwatch';
import VodsCarousel from '../components/Vods/VodsCarousel';
import EventCalendar from '../components/CalendarTable/EventCalendar';
import OrganizationImage from '../components/Images/OrganizationImage';
import HeaderIconButtons from '../components/ContentHeaders/HeaderIconButtons';
import ShareButton from '../components/ContentHeaders/HeaderShareButton';
import WindowTitle from '../components/WindowTitle';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: theme.contentHeight,
    backgroundColor: theme.palette.background.primary,
  },
  section: {
    borderBottom: `2px solid ${theme.palette.border.primary}`,
    backgroundColor: theme.palette.background.primary,
  },
  sectionBackground: {
    borderBottom: `1px solid ${theme.palette.border.primary}`,
    paddingBottom: 56,
  },
  videoSwatchContainer: {
    backgroundColor: 'black',
  },
  vodsSection: {
    background: theme.palette.background.soloDenary,
    padding: '0 24px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  container: {
    maxWidth: 1232,
    width: '100%',
    paddingTop: 5,
    margin: 'auto',
    padding: '0 24px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  vodsContainer: {
    maxWidth: 1280,
    width: '100%',
    margin: 'auto',
    padding: '5px 0',
  },
  header: {
    marginBottom: 24,
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: -16,
    marginTop: -16,
    marginBottom: 9,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: 0,
      marginLeft: 'unset',
      marginTop: 'unset',
    },
  },
  gameName: {
    marginLeft: 32,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 12,
    },
  },
  scheduleContainer: {
    padding: '0 10px',
    [theme.breakpoints.up('md')]: {
      padding: '0 24px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },
}));

const getQueryParams = teamId => {
  return ['allSeries', ['teamIds', 'array-contains', teamId]];
};

const TeamScreen = ({
  match: {
    params: { teamSlug },
  },
  location,
}) => {
  const dispatch = useDispatch();
  const [teams, isLoading] = useFirestoreGetMap('processed_teams', ['slug', '==', teamSlug.toLowerCase()]);
  const team = useMemo(() => Object.values(teams)[0], [teams]);
  const teamIds = useMemo(() => (team ? [team.id] : []), [team]);
  const [hasArticles, setHasArticles] = useState(false);

  // Passing id as an array to be able to share selectors with organizations.
  const liveSeries = useSelector(state => getLiveSeriesByParticipants(state, { teamIds }));
  const endedSeries = useSelector(state => getVodSeriesByParticipants(state, { teamIds }));
  const upcomingSeries = useSelector(state => getUpcomingSeriesByParticipants(state, { teamIds }));
  const gameList = useSelector(state => getGames(state));

  const onFetchArticles = useCallback(
    articles => {
      if (Boolean(articles?.length) !== hasArticles) {
        setHasArticles(Boolean(articles?.length));
      }
    },
    [hasArticles]
  );

  useEffect(() => {
    if (team) {
      dispatch(subscribeToQuery(...getQueryParams(team.id)));
    }
    return () => {
      if (team) {
        dispatch(unsubscribeToQuery(...getQueryParams(team.id)));
      }
    };
  }, [team, dispatch]);

  const classes = useStyles();
  if (isLoading || !team) return <div className={classes.root}></div>; // TODO: render loading state

  const hasVods = Boolean(endedSeries?.length);
  const hasPlayers = Boolean(team.players?.length);
  const source = 'team-page';

  if (!location.hash) {
    // If no hash added, redirect to same URL with OVERVIEW hash.
    console.error('[TeamScreen] No hash in URL, redirecting...');
    return <Redirect to={{ ...location, hash: `#${SLUGS[LABELS.OVERVIEW]}` }} />;
  }

  const teamLinks = team.links || {};
  const orgLinks = team.organization?.links || {};

  const { title, description, keywords, robots } = team?.metaTags || {};
  const game = team.game && gameList[team.game];
  const gameName = game?.name;

  const defaultTitle = `${team.name} | ${gameName ? `${gameName} |` : ''} Scores, Stats, News, Schedules, & VODs`;
  const defaultDescription = `Get up to date ${team.name} notifications, streams, recent matches, scores, esports news, VODs, stats, results, and more. ${gameName}`;

  return (
    <div className={classes.root}>
      <WindowTitle
        title={title ? title : defaultTitle}
        description={description ? description : defaultDescription}
        keywords={keywords}
        robots={robots}
      />
      <FollowHeader
        large
        title={team.name}
        Tooltip={({ className }) => <Tooltip className={className} text="Tap or click to follow team" />}
        ImageComponent={<OrganizationImage organization={team.images ? team : team.organization} />}
        Breadcrumb={<TeamBreadcrumb team={team} game={game} />}
        GameTags={(game && <GameTag className={classes.gameName} game={game} />) || null}
        HeaderIconButtons={
          <HeaderIconButtons
            links={{
              facebook: teamLinks.facebook || orgLinks.facebook,
              instagram: teamLinks.instagram || orgLinks.instagram,
              twitter: teamLinks.twitter || orgLinks.twitter,
              youtube: teamLinks.youtube || orgLinks.youtube,
              discord: teamLinks.discord || orgLinks.discord,
              tournamentUrl: teamLinks.tournamentUrl || orgLinks.tournamentUrl,
              teamStore: teamLinks.teamStore || orgLinks.teamStore,
            }}
            source={source}
          />
        }
        ShareComponent={<ShareButton event={team} isTeamScreen source={source} />}
        className={classes.section}
        entity={team}
        entityField={FOLLOW_FIELDS.TEAM_IDS}
        banners={team.banners || team.organization?.banners}
      >
        <HeaderTab label={LABELS.OVERVIEW} location={location} />
        <HeaderTab label={LABELS.PLAYERS} location={location} />
        <HeaderTab label={LABELS.SCHEDULE} location={location} />
        {hasArticles && <HeaderTab label={LABELS.NEWS} location={location} />}
        {hasVods && <HeaderTab label={LABELS.TOP_MATCHES_VODS} location={location} />}
      </FollowHeader>

      {/**Stream Section*/}
      {Boolean(liveSeries?.length) && (
        <div className={`${classes.section} ${classes.videoSwatchContainer}`}>
          <VideoSwatch liveSeriesArr={liveSeries} source={source} />
        </div>
      )}

      {/**Players Section */}
      <span id={SLUGS[LABELS.PLAYERS]} />
      {hasPlayers && (
        <SectionBackground className={classes.sectionBackground}>
          <div className={classes.container}>
            <MainSectionTitle className={classes.header} title="Players" />
            <div className={classes.cardsContainer}>
              {team.players.map(player => {
                if (Boolean(player.deletedAt)) {
                  return null;
                }
                return <PlayerCard key={player.id} player={player} />;
              })}
            </div>
          </div>
        </SectionBackground>
      )}

      {/**Schedule Section */}
      <span id={SLUGS[LABELS.SCHEDULE]} />
      <div className={classes.scheduleContainer}>
        <EventCalendar title="Schedule" context="Team" upcomingSeries={upcomingSeries} liveSeries={liveSeries} />
      </div>

      {/**NEWS Section */}
      <span id={SLUGS[LABELS.NEWS]} />
      <div className={classes.section}>
        <EntityArticleSection
          title={`${team.name} News`}
          onFetchArticles={onFetchArticles}
          queryProps={{ teamId: team?.id }}
        />
      </div>

      {/**Tops Matches & Vods Section */}
      <span id={SLUGS[LABELS.TOP_MATCHES_VODS]} />
      {hasVods && (
        <div className={classes.vodsSection}>
          <VodsCarousel
            className={classes.vodsContainer}
            game={game}
            filteredName={team.name}
            page="team-page"
            title="Top Matches & Vods"
            endedSeries={endedSeries}
          />
        </div>
      )}
    </div>
  );
};

export default TeamScreen;
