import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';
import { IoMdClose } from 'react-icons/io';

import { addStaticBackgroundContainer, removeStaticBackgroundContainer } from '../../store/actions/uiStates';

const styles = theme => ({
  dialog: {
    background: 'transparent',
    height: '100%',
    left: 0,
    overflow: 'auto',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 100000000,
  },
  overlay: {
    display: 'block',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    background: 'rgba(0, 0, 0, 0.6)',
    top: 0,
    zIndex: 99999999,
  },
  contentScrollContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 100000000,
    width: '100%',
  },
  content: {
    background: theme.palette.background.secondary,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'center',
    margin: '80px 0',
  },
  closeButton: {
    width: 30,
    height: 30,
    fontFamily: 'Arial',
    fontSize: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '1px',
    position: 'absolute',
    top: 16,
    right: 16,
    padding: 2,
    borderRadius: 2,
    border: 0,
    background: 'transparent',
    color: theme.palette.text.primary,
    outline: 0,
    cursor: 'pointer',
  },
  [theme.breakpoints.down('sm')]: {
    content: {
      margin: '24px 12px',
    },
    closeButton: {
      '&.isLoginPromptDialog': {
        top: 0,
        right: 0,
      },
    },
  },
});

class ScrollableDialog extends Component {
  state = { mounted: false };

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    // Disable scrolling on body.
    this.props.addStaticBackgroundContainer();

    // Append new div to the modal-root.  Render children only after the
    // new anchor element has mounted.
    this.modalRoot = document.getElementById('modal-root');
    this.modalRoot.appendChild(this.el);
    this.setState({ mounted: true });
    window.addEventListener('keydown', this.handleEsc);
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el);
    this.props.removeStaticBackgroundContainer();

    window.removeEventListener('keydown', this.handleEsc);
  }

  //This will close the modal if the user clicks the esc button
  handleEsc = event => {
    if (event.keyCode === 27) {
      this.props.onCloseClick();
    }
  };

  render() {
    const { className, classes, id, children, onCloseClick, isLoginPromptDialog } = this.props;
    const { mounted } = this.state;

    return mounted
      ? ReactDOM.createPortal(
          <div id={id} className={classes.dialog}>
            <div className={classnames({ [classes.overlay]: true })} />
            {/* This container allows scroll anywhere across page. */}
            <div className={classes.contentScrollContainer}>
              <div className={classnames({ [className]: true, [classes.content]: true })}>
                {children}
                <button
                  className={classnames({
                    [classes.closeButton]: true,
                    isLoginPromptDialog,
                  })}
                  onClick={onCloseClick}
                >
                  <IoMdClose />
                </button>
              </div>
            </div>
          </div>,
          this.el
        )
      : null;
  }
}

export default compose(
  withStyles(styles),
  connect(null, { addStaticBackgroundContainer, removeStaticBackgroundContainer })
)(ScrollableDialog);
