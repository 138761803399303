import classnames from 'classnames';
import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { MdSearch, MdClear } from 'react-icons/md';

import { TrackButtonBasic } from '../common';

const styles = theme => ({
  button: {
    background: theme.palette.background.nonary,
    padding: '8px 16px',
    cursor: 'pointer',
    marginLeft: 'auto',
    border: 'none',
    outline: 'none',
    boxSizing: 'border-box',
    '&:hover': {
      background: 'rgba(0,0,0,0.1)',
    },
  },
  clear: {
    padding: [[10, 8]],
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(0,0,0,0.1)',
    },
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    marginLeft: 8,
    marginRight: 16,
    width: 265,
    height: 40,
    background: theme.palette.background.nonary,
    boxSizing: 'border-box',
    border: `2px solid ${theme.palette.border.quinary}`,
    borderRadius: 4,
    '& input': {
      width: 170,
      marginLeft: 16,
      fontSize: 15,
      background: theme.palette.background.nonary,
      fontWeight: 600,
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      border: 'none',
      outline: 'none',
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      '& input': {
        width: '67%',
        fontWeight: 'initial',
      },
    },
  },
  searchIcon: {
    color: '#AEC3C3',
    fontSize: 24,
  },
});

class SearchFilter extends Component {
  state = { searchInput: !!this.props.searchedText ? this.props.searchedText : '' };

  componentDidUpdate(prevProps) {
    const { searchedText } = this.props;
    if (searchedText !== prevProps.searchedText && searchedText !== this.state.searchInput) {
      // Catches change in searchedText, like on back/forward navigation after component mount.
      this.setState({ searchInput: searchedText });
    }
  }

  handleSubmit = e => {
    const { onSubmit } = this.props;
    const { searchInput } = this.state;

    e.preventDefault();
    if (onSubmit) {
      onSubmit(searchInput);
    }
  };

  clearSearch = e => {
    const { onClearSearch } = this.props;

    e.preventDefault();
    this.setState({ searchInput: '' });
    if (onClearSearch) {
      onClearSearch();
    }
  };

  render() {
    const { classes, className, searchedText, track, placeHolder } = this.props;
    const { searchInput } = this.state;

    return (
      <form className={classnames({ [classes.search]: true, [className]: true })} onSubmit={this.handleSubmit}>
        <input
          type="text"
          value={searchInput || ''} // Clears out input on navigation when searchInput changes to null.
          placeholder={placeHolder}
          onChange={e => this.setState({ searchInput: e.target.value })}
        />
        {(searchInput || searchedText) && <MdClear className={classes.clear} onClick={this.clearSearch} />}
        <TrackButtonBasic className={classes.button} type="submit" track={track}>
          <MdSearch className={classes.searchIcon} />
        </TrackButtonBasic>
      </form>
    );
  }
}

export default compose(withStyles(styles))(SearchFilter);
