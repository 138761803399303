import { CHANGE_CALENDAR_VIEW, INCREASE_WEEK, DECREASE_WEEK, CHECK_SERIES_STREAM } from '../actions/calendar';
import { CALENDAR_LINK_FILTERS } from '../reducers/uiStates';

import { addDays, addYears, subDays, startOfDay, endOfDay, endOfMonth } from 'date-fns';

export const initialState = {
  calendarView: CALENDAR_LINK_FILTERS.WEEK,
  startDate: startOfDay(new Date()),
  endDate: endOfDay(addDays(new Date(), 6)),
  streamLessSeries: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_CALENDAR_VIEW: {
      let endDate;
      if (action.view === CALENDAR_LINK_FILTERS.DAY) {
        endDate = endOfDay(new Date());
      } else if (action.view === CALENDAR_LINK_FILTERS.WEEK) {
        endDate = endOfDay(addDays(new Date(), 6));
      } else {
        endDate = endOfMonth(addYears(new Date(), 1));
      }
      return {
        ...state,
        calendarView: action.view,
        startDate: startOfDay(new Date()),
        endDate,
      };
    }
    case INCREASE_WEEK: {
      return {
        ...state,
        startDate: addDays(state.startDate, 7),
        endDate: addDays(state.endDate, 7),
      };
    }
    case DECREASE_WEEK: {
      // If we're at today's week, we don't want to let users to decrease week anymore
      if (state.startDate.toDateString() === new Date().toDateString()) {
        return state;
      }
      return {
        ...state,
        startDate: subDays(state.startDate, 7),
        endDate: subDays(state.endDate, 7),
      };
    }

    case CHECK_SERIES_STREAM: {
      return {
        ...state,
        streamLessSeries: state.streamLessSeries + 1,
      };
    }

    default:
      return state;
  }
};
