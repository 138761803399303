import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { compose } from 'recompose';

import { DateRange, GameTag, TrackLink } from '../common';
import { getGameById } from '../../store/selectors/gameData';
import CompetitionImage from '../Images/CompetitionImage';
import TournamentImage from '../Images/TournamentImage';
import ItemPrizepool from '../CalendarTable/ItemPrizepool';
import VideoCardStyles from '../VideoSwatch/VideoCardStyles';

const styles = theme => ({
  ...VideoCardStyles,
  frameContainer: {
    overflow: 'hidden',
    position: 'relative',
  },
  card: {
    textTransform: 'none',
    padding: '16px 16px 24px 16px',
  },
  cardContainer: {
    width: 250,
    borderRadius: 6,
    background: theme.palette.background.secondary,
    color: theme.palette.text.primary,
    boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.2)',
    overflow: 'hidden',
    position: 'relative',
    '&:hover': {
      boxShadow: '0 8px 8px 0 rgba(0,0,0,0.2)',
      background: theme.black,
    },
    '&:not(:first-child)': {
      marginLeft: 32,
    },
  },
  logo: {
    height: 176,
    width: 250,
    borderRadius: '4px 4px 0 0',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 48,
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: '24px',
    textAlign: 'center',
    marginBottom: 12,
    overflow: 'hidden',
    '$cardContainer:hover &': {
      color: 'white',
    },
  },
  lineClamp: {
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    overflow: 'hidden',
    margin: 0,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  middleRow: {
    justifyContent: 'space-evenly',
    marginBottom: 8,
  },
  bottomRow: {
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
  },
  timeLabel: {
    position: 'absolute',
    width: 'max-content',
    padding: 4,
    background: '#000000',
    opacity: 0.9,
    borderRadius: '2px',
    color: 'white',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: '2px',
    lineHeight: '16px',
    zIndex: 2, // To beat tournament image
    left: 16,
    top: 16,
    maxWidth: 215,
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  games: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    cursor: 'pointer',
    zIndex: 15, // To beat over cardLink
  },
  gameTag: {
    marginRight: 11,
    fontSize: 10,
    lineHeight: '16px',
  },
  prizepool: {
    fontSize: '13px !important',
    lineHeight: '20px !important',
    marginRight: 8,
    '$cardContainer:hover &': {
      color: 'white !important',
    },
    '& .trophyIcon': {
      fontSize: 14,
      '$cardContainer:hover &': {
        color: 'white',
      },
    },
    '& .dollarSignIcon': {
      fontSize: 13,
      lineHeight: '24px',
      '$cardContainer:hover &': {
        color: 'white',
      },
    },
  },
  stage: {
    color: theme.palette.text.quinary,
    fontSize: 13,
    lineHeight: '20px',
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '$cardContainer:hover &': {
      color: 'white',
    },
  },
  textOverlay: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    opacity: 0,
    position: 'absolute',
    textDecoration: 'none',
    top: 0,
    transition: 'opacity 240ms',
    width: '100%',
    zIndex: 10, // To beat over image text
    '$cardContainer:hover &': {
      opacity: 1,
    },
    '& h2, & h1': {
      margin: 0,
      userSelect: 'none',
    },
    '& h2': {
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 300,
    },
    '& h1': {
      fontStyle: 'italic',
      fontWeight: 'bold',
      letterSpacing: '-2px',
      lineHeight: '64px',
      fontSize: 64,
    },
  },
  cardLink: {
    color: 'inherit',
    textDecoration: 'none',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 12, // To beat over textOverlay
  },
});

class TrendingCard extends Component {
  renderImage() {
    const { classes, event } = this.props;
    return event.isCompetition ? (
      <CompetitionImage className={classes.logo} competition={event} />
    ) : (
      <TournamentImage className={classes.logo} defaultImage tournament={event} />
    );
  }

  render() {
    const { event, classes, className, games } = this.props;
    const url = `/${event.isCompetition ? 'c' : 'e'}/${event.slug}`;

    return (
      <div className={`${className} ${classes.cardContainer}`}>
        <TrackLink
          className={classes.cardLink}
          to={url}
          track={{
            event: 'Featured Event Card Clicked',
            source: 'featured-event-carousel',
            target: 'event-page',
            eventOptions: { contextSeriesId: event.fromSeries.id },
          }}
        />
        <div className={classes.frameContainer}>
          {this.renderImage()}
          <div className={classes.textOverlay}>
            <h2>Click Here to view</h2>
            <h1>Event</h1>
          </div>
        </div>
        <div className={classes.card}>
          <div className={classes.title}>
            <p className={classes.lineClamp}>{event.title}</p>
          </div>
          <div className={`${classes.row} ${classes.middleRow}`}>
            <ItemPrizepool className={classes.prizepool} prizepool={event.prizepool} withTrophyIcon />
            <div className={classes.stage}>{event.fromSeries.title}</div>
          </div>
          <div className={`${classes.row} ${classes.bottomRow}`}>
            <div className={classes.games}>
              {games.map(game => (
                <GameTag className={classes.gameTag} game={game} key={game.id} seriesId={event.fromSeries.id} />
              ))}
            </div>
          </div>
        </div>
        <DateRange className={classes.timeLabel} obj={event} />
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect((state, { event }) => ({
    games: (event.isCompetition ? event.gameIds : [event.game]).map(id => getGameById(state, id)),
  }))
)(TrendingCard);
