import { createSelector } from 'reselect';

// use `createArrEqSelector` over `createSelector` when one or more inputs are array.
import { createArrEqSelector } from './gameData';
import { getPreferredGameIds } from '../selectors/user';
import { getGames } from '../selectors/gameData';
import { isPartOfName } from './gameDataUtils';

const sortArticlesByDate = (a, b) => b.attributes.date.seconds - a.attributes.date.seconds;

const getGameFilteredArticles = createArrEqSelector(
  (_, { articles }) => articles,
  (_, { game }) => game,
  (_, { isFavoritesSelected }) => isFavoritesSelected,
  (_, { selectedGames }) => selectedGames,
  (_, { searchTerm }) => searchTerm,
  getPreferredGameIds,
  getGames,
  (articles, game, isFavoritesSelected, selectedGames = [], searchTerm, preferredGameIds, gameList) => {
    // Remove duplicates.
    const seen = new Set();
    const uniqueArticles = articles.filter(article => (seen.has(article.id) ? false : seen.add(article.id)));

    if (!game && !selectedGames.length && !isFavoritesSelected && !searchTerm) return uniqueArticles;

    return uniqueArticles.filter(article => {
      if (game && (!article.gameIds || !article.gameIds.find(id => id === game.id))) {
        return false;
      }

      if (selectedGames.length && (!article.gameIds || !article.gameIds.find(id => selectedGames.includes(id)))) {
        return false;
      }

      if (isFavoritesSelected && (!article.gameIds || !article.gameIds.find(id => preferredGameIds.includes(id)))) {
        return false;
      }

      if (searchTerm && !isArticlesMatchingSearch(article, searchTerm, gameList)) {
        return false;
      }

      return true;
    });
  }
);

const isArticlesMatchingSearch = (article, input, gameList) => {
  const inputTerm = input.toLowerCase();
  const gamesString =
    article.gameIds &&
    article.gameIds.reduce((acc, gameId) => {
      const game = gameList[gameId];
      return game ? `${acc} ${game.abbr.toLowerCase()} ${game.name.toLowerCase()} ` : acc;
    }, '');

  return (
    article.attributes?.title.toLowerCase().includes(inputTerm) ||
    article.attributes?.excerpt.toLowerCase().includes(inputTerm) ||
    gamesString?.includes(inputTerm) ||
    inputTerm?.split(' ').every(word => {
      return article.teams.find(team => isPartOfName(team, word));
    })
  );
};

export const getFeaturedArticles = createSelector(getGameFilteredArticles, articles =>
  articles
    .filter(article => Boolean(article.featuredAt))
    .sort((a, b) => b.featuredAt.seconds - a.featuredAt.seconds)
    .slice(0, 3)
);

export const getNonFeaturedArticles = createSelector(
  getGameFilteredArticles,
  getFeaturedArticles,
  (articles, featuredArticles) =>
    articles.filter(article =>
      featuredArticles.find(featuredArticle => featuredArticle.id === article.id) ? false : true
    )
);

export const getSortedArticleList = createSelector(
  getGameFilteredArticles,
  getFeaturedArticles,
  (articles, featuredArticles) =>
    articles
      .filter(article => (featuredArticles.find(featuredArticle => featuredArticle.id === article.id) ? false : true))
      .sort(sortArticlesByDate)
);
