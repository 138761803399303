import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';

import { MdNotificationsOff, MdNotificationsActive } from 'react-icons/md';

import { TrackIconButton } from '../../common';

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: 8,
    '&.active': {
      background: theme.lightTeal,
    },
  },
}));

const EntityNotificationButton = ({ onClick, active, track = {} }) => {
  const classes = useStyles();

  return (
    <TrackIconButton
      className={classnames({ [classes.button]: true, active })}
      variant="square-button"
      track={{
        event: 'Follow Notification Clicked',
        source: 'follow-header',
        ...track,
      }}
      onClick={onClick}
      Icon={active ? MdNotificationsActive : MdNotificationsOff}
    />
  );
};

export default EntityNotificationButton;
