import React from 'react';

import FrameView from '../FrameView';

const AlphaSignUpView = ({ isSignedIn, view, source }) => {
  return (
    <FrameView
      title="The Juked App is here!"
      frameSrc="https://streamable.com/e/dyyxw1?autoplay=1"
      frameTitle="The Juked App"
      buttonWidth={132}
      buttonText="Get the App"
      redirectUrl="https://jkd.gg/36lFrtR"
      track={{
        event: 'Beta Download Modal Clicked',
        eventProperties: { feature: 'beta-download-announcement', is_signed_in: isSignedIn, view },
        target: 'beta-download-page',
        source,
      }}
    />
  );
};

export default AlphaSignUpView;
