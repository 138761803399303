import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import TwitterFeed from './TwitterFeed';
import StreamChat from './StreamChat';
import ChatHeader from './ChatHeader';
import Contests from '../../Contests';
import { isFeatureEnabled } from '../../../utils/featureFlags';

const useStyles = makeStyles(theme => ({
  chatContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    chatContainer: {
      display: 'none',
    },
  },
}));

const Chat = ({ stream, className, displayTweets, isChatCollapsed, series, toggleCollapseChat }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.chatContainer} ${className}`}>
      <div className={classes.contentWrapper}>
        <ChatHeader stream={stream} isChatCollapsed={isChatCollapsed} toggleCollapseChat={toggleCollapseChat} />
        {isFeatureEnabled('contests') && <Contests series={series} />}
        {renderChat(stream.platformId, stream.platformType, displayTweets, series.id)}
      </div>
    </div>
  );
};

const renderChat = (channel, platformType, displayTweets) => {
  if (displayTweets) {
    return <TwitterFeed />;
  }

  return <StreamChat channel={channel} platformType={platformType} />;
};

export default connect(state => ({
  displayTweets: state.twitter.displayTweets,
}))(Chat);
