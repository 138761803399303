import React from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { FaPause, FaPlay } from 'react-icons/fa';

import { TrackButtonBasic } from '../common';
import { togglePauseAll } from '../../store/actions/video';
import { getLiveSeries } from '../../store/selectors/liveSeries';

const useStyles = makeStyles(theme => ({
  playPauseButton: {
    width: 32,
    height: 32,
    marginRight: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    borderRadius: 2,
    color: theme.palette.text.quinary,
    background: theme.palette.background.secondary,
    border: `1px solid ${theme.palette.background.ternidenary}`,
    tapHighlightColor: 'transparent',
    userSelect: 'none',
    cursor: 'pointer',
    '&:hover': {
      background: 'lightgrey',
    },
    '& svg': {
      verticalAlign: 'middle',
    },
    '&.play svg': {
      marginLeft: 1,
    },
  },
}));

const PauseAllButton = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const pauseAll = useSelector(state => state.video.pauseAll);
  const liveSeries = useSelector(getLiveSeries);

  // Show the PauseAllButton only if we have active streams
  if (!liveSeries?.length) {
    return null;
  }

  return (
    <TrackButtonBasic
      title={`${pauseAll ? 'Play' : 'Pause'}" All Streams"`}
      className={classNames({
        [classes.playPauseButton]: true,
        play: pauseAll,
        pause: !pauseAll,
      })}
      onClick={() => dispatch(togglePauseAll())}
      track={{
        event: 'Pause All Streams Toggled',
        source: 'pause-all button',
        target: pauseAll ? 'play' : 'pause',
      }}
    >
      {pauseAll ? <FaPlay /> : <FaPause />}
    </TrackButtonBasic>
  );
};

export default PauseAllButton;
