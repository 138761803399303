import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import MultiViewButtons from './MultiViewButtons';
import PauseAllButton from './PauseAllButton';
import TopEventsToggleSelect from './TopEventsToggleSelect';

const useStyles = makeStyles({
  topBar: {
    height: 32,
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 1232,
    width: '100%',
    paddingTop: 8,
    margin: '0 auto 8px',
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

const MultiViewButtonsRow = ({ isHomePageView, hasFilters, className }) => {
  const classes = useStyles();
  const isSinglePlayerView = useSelector(state => state.uiStates.isSinglePlayerView);

  return (
    <div className={`${classes.topBar} ${className}`}>
      <div className={classes.leftContainer}>
        {!isSinglePlayerView && <PauseAllButton />}
        {hasFilters && <TopEventsToggleSelect />}
      </div>
      <MultiViewButtons isHomePageView={isHomePageView} isSinglePlayerView={isSinglePlayerView} />
    </div>
  );
};

export default MultiViewButtonsRow;
