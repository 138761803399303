import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';

const getCredentials = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return {
      searchKey: 'search-8ty2wjg81u4j1b9zg24wbi8f',
      endpointBase: 'https://juked-search-production.ent.us-central1.gcp.cloud.es.io',
    };
  } else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    return {
      searchKey: 'search-gpufne6bp2wt5vzisanr92mj',
      endpointBase: 'https://1b6d829d3f67423db938c964ee2d8700.ent-search.us-west1.gcp.cloud.es.io',
    };
  } else {
    return {
      searchKey: 'search-7n3fmk4msin3nxw8e1jaoura',
      endpointBase: 'https://432bf20c9852430ca41053e45e68bf4b.ent-search.us-west1.gcp.cloud.es.io',
    };
  }
};

const connector = new AppSearchAPIConnector({
  ...getCredentials(),
  engineName: 'juked-search',
  cacheResponses: false,
});

export const SEARCH_CONFIG = {
  apiConnector: connector,
  searchQuery: {
    search_fields: {
      // Shared
      name: {},
      // Teams
      short_name: {},
      search_term: {},
      game_name: {},
      game_abbr: {},
      // Games
      abbr: {},
    },
  },
  autocompleteQuery: {
    suggestions: {
      types: {
        documents: { fields: ['abbr', 'game_name', 'game_abbr', 'name', 'search_term', 'short_name'] },
      },
    },
  },
  trackUrlState: false,
};

const jsonParse = value => {
  try {
    return JSON.parse(value);
  } catch (err) {
    if (typeof value == 'string') return value;
    console.error(err);
  }
};

export const parseSearchResult = ({ result }) =>
  Object.keys(result).reduce((accu, key) => {
    if (key === 'id') {
      return { ...accu, id: result._meta.id, entityType: getSearchResultEntityType(result) };
    }
    return { ...accu, [key]: result[key].raw ? jsonParse(result[key].raw) : result[key] };
  }, {});

/**
 * @param {result} Object Single ElasticSearch result item
 * @returns {string} Entity type
 */
export const getSearchResultEntityType = result => {
  return result?._meta?.engine;
};
