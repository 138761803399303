// NOTE: this file is copied from frontend to the follow locations.  Please copy over when changes are made:
// > admin/src/utils/articles.js
// > backend/firebase/functions/articles/parseArticleId.js

const SOURCES = {
  CMS: 'juked-cms',
};

const ARTICLE_ID_RE = new RegExp(`^(${Object.values(SOURCES).join('|')}):(.*)$`, 'i');

export const ABBR_SOURCE_MAP = {
  c: SOURCES.CMS,
};

export const SOURCE_ABBR_MAP = {
  [SOURCES.CMS]: 'c',
};

export const parseArticleId = articleId => {
  if (!articleId) return null;
  const [, source, sourceId] = articleId.match(ARTICLE_ID_RE);
  const sourceAbbr = SOURCE_ABBR_MAP[source];
  return { sourceAbbr, source, sourceId };
};
