// NOTE: This file is shared between Frontend and Admin. Please copy from Frontend to Admin when making changes.
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import getDistanceBetweenDates from '../../utils/getDistanceBetweenDates';
import GameTag from '../common/GameTag';

const DOMAIN_REGEX = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/gi;

const useStyles = makeStyles(theme => ({
  gameTagContainer: {
    display: 'flex',
    '& div:last-of-type': {
      marginRight: 20,
    },
  },
  gameTag: {
    marginRight: 8,
    '& > a': {
      fontSize: 10,
    },
  },
  time: {
    fontSize: 10,
    textTransform: 'uppercase',
    lineHeight: '12px',
    letterSpacing: '1.5px',
    color: '#777777',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  },
  sourceText: {
    position: 'absolute',
    right: 16,
    fontSize: 10,
    color: '#9B9A9A',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const getDomain = url => {
  const match = url.match(DOMAIN_REGEX);
  return match && match[0].replace(/^(www\.)/i, '').toLowerCase();
};

const ArticleGameTag = ({ article, games, className }) => {
  const classes = useStyles();

  const {
    attributes: { type, redirect_url: redirectUrl },
  } = article;

  const source = type !== 'redirect' ? null : getDomain(redirectUrl);

  if (!games) {
    return null;
  }

  return (
    <span className={`${classes.gameTagContainer} ${className}`}>
      {article.gameIds?.reduce((acc, gameId) => {
        const game = games[gameId];
        return !game ? acc : [...acc, <GameTag className={classes.gameTag} game={game} key={game.id} disableSpacing />];
      }, [])}
      <span className={classes.time}>{getDistanceBetweenDates(article.attributes.date.toDate())}</span>
      <span className={classes.sourceText}>{!source ? 'classic.juked.gg' : source}</span>
    </span>
  );
};

export default ArticleGameTag;
