import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import TeamPageLink from '../common/TeamPageLink';
import LineClamp from '../common/LineClamp';
import ParticipantImage from '../Images/ParticipantImage';

const reversedImgClasses = {
  marginLeft: 16,
  marginRight: 'unset',
};

const smallViewImgClasses = {
  width: 16,
  height: 16,
  marginRight: 8,
};

const useStyles = makeStyles(theme => ({
  participantContainer: {
    display: 'flex',
    alignItems: 'center',
    '&.isReversed, &.isSmallView': {
      flexDirection: 'row-reverse',
    },
  },
  participantName: {
    fontSize: 18,
    lineHeight: '21px',
    color: theme.palette.text.primary,
    width: 155,
    textAlign: 'right',
    marginRight: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    '.isReversed &': {
      textAlign: 'left',
      marginLeft: 8,
      marginRight: 'unset',
    },
    '.isSmallView &': {
      width: 'unset',
      fontSize: 13,
      lineHeight: '16px',
      marginRight: 12,
      [theme.breakpoints.down('sm')]: {
        display: 'unset',
      },
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  lineClamp: {
    lineClamp: 2,
    whiteSpace: 'normal',
  },
  participantImage: {
    width: 40,
    height: 40,
    filter: 'drop-shadow(0 0 1px white)',
    marginRight: 16,
    '.isReversed &': {
      ...reversedImgClasses,
    },
    '.isSmallView &': {
      ...smallViewImgClasses,
    },
    [theme.breakpoints.down('sm')]: {
      width: 30,
      height: 30,
    },
  },
  usersIcon: {
    alignItems: 'center',
    backgroundColor: theme.palette.participantDefaultIcon,
    display: 'flex',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: '50%',
    filter: 'drop-shadow(0 0 1px white)',
    marginRight: 16,
    '.isReversed &': {
      ...reversedImgClasses,
    },
    '.isSmallView &': {
      ...smallViewImgClasses,
    },
    [theme.breakpoints.down('sm')]: {
      width: 30,
      height: 30,
    },
  },
  usersSvg: {
    color: 'white',
    fontSize: 22,
  },
  lastParticipant: {
    '.isSmallView &': {
      width: 70,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',
    },
  },
  score: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    background: theme.palette.background.ternidenary,
    fontSize: 28,
    fontWeight: 'bold',
    lineHeight: '32px',
    color: theme.palette.text.soloDenary,
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      width: 30,
      height: 30,
      fontSize: 20,
    },
  },
}));

const LgTeamPageLink = ({ participant, isReversed, isSmallView, thirdParticipant }) => {
  const classes = useStyles();

  return (
    <TeamPageLink
      className={classnames({
        [classes.participantContainer]: true,
        isReversed,
        isSmallView,
      })}
      slug={participant.slug}
      participantType={participant && participant.participantType}
    >
      <span
        className={classnames({
          [classes.participantName]: true,
          [classes.lastParticipant]: thirdParticipant,
        })}
      >
        <LineClamp className={classes.lineClamp} text={participant.name || participant.shortName} />
      </span>
      <ParticipantImage participant={participant} className={classes.participantImage} />
      {!isSmallView && <span className={classes.score}>{participant.score || 0}</span>}
    </TeamPageLink>
  );
};

export default LgTeamPageLink;
