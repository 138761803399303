import React from 'react';

import EntityBadge from './EntityBadge';
import { getTeamUrl } from '../../../utils/urls';

export default ({ animated, className, disabled, elevation = 1, team, track }) => {
  const { game, id, images, name, shortName, slug } = team;
  const { default: defaultImage, thumbnail } = images || {};

  return (
    <EntityBadge
      animated={animated}
      className={className}
      disabled={disabled}
      elevation={elevation}
      src={defaultImage || thumbnail}
      to={getTeamUrl(team)}
      track={{
        event: 'Team Badge Clicked',
        eventProperties: {
          team_id: id,
          team_name: name,
          team_shortname: shortName,
          team_slug: slug,
          team_game_id: game,
        },
        target: 'team-page',
        ...(track || {}),
      }}
    />
  );
};
