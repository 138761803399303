import React from 'react';
import { MdEmail, MdNotifications } from 'react-icons/md';
import { IoLogoGameControllerB } from 'react-icons/io';
import { withStyles } from '@material-ui/styles';

import { SIGNUP_DIALOG_ACTIONS } from '../index';
import LoginBanner from '../../LoginBanner';
import { H1, IconListItem, LinkButton, TrackButton } from '../../../common';
import { isFeatureEnabled } from '../../../../utils/featureFlags';

const styles = theme => ({
  button: {
    margin: '0 0 24px 0',
    width: '100%',
  },
  loginPromptView: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '36px 84px 52px 84px', // Subtract 12px from each side due to modal padding.
    justifyContent: 'center',
  },
  container: {
    maxWidth: 352,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  h1: {
    margin: '0 0 24px 0',
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  list: {
    listStyleType: 'none',
    margin: '0 0 24px 0',
    color: theme.palette.text.primary,
  },
  link: {
    color: theme.lightPink,
  },
  [theme.breakpoints.down('sm')]: {
    loginPromptView: {
      padding: '0 12px',
      width: 300,
    },
  },
  '@media (max-width: 320px)': {
    loginPromptView: {
      width: 'inherit',
    },
  },
});

const LoginPromptView = ({ classes, onClick, onCloseClick, view, source }) => (
  <div className={classes.loginPromptView}>
    <div className={classes.container}>
      <H1 className={classes.h1}>Sign up and access all Juked features</H1>
      <li className={classes.list}>
        <IconListItem label="Event and Match Notifications" Icon={MdNotifications} />
        <IconListItem label="Set Followed Games" Icon={IoLogoGameControllerB} />
        <IconListItem label="Juked Weekly Esports Newsletter" Icon={MdEmail} />
      </li>
      {isFeatureEnabled('loginBanner') && <LoginBanner onCloseClick={onCloseClick} source={source} view={view} />}
      <TrackButton
        className={classes.button}
        theme={'lightTeal'}
        onClick={onClick}
        track={{ event: SIGNUP_DIALOG_ACTIONS.SIGNUP, eventProperties: { view }, source, target: 'login' }}
      >
        JOIN NOW
      </TrackButton>
      {/* onCloseClick is already tracked */}
      <LinkButton bold className={classes.link} fontSize={16} onClick={() => onCloseClick()}>
        I don't want to join now
      </LinkButton>
    </div>
  </div>
);

export default withStyles(styles)(LoginPromptView);
