import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ItemActionDropdown from './ItemActionDropdown';
import MobileItemActionDialog from './MobileItemActionDialog';

export default ({ classes, ...props }) => {
  const matchesDesktop = useMediaQuery('(min-width:960px)'); // SM Breakpoint

  return matchesDesktop ? <ItemActionDropdown {...props} /> : <MobileItemActionDialog {...props} />;
};
