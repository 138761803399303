import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MdReply } from 'react-icons/md';

import { TrackIconButton } from '../common';
import ItemSharingDialog from '../CalendarTable/ItemSharingDialog';
import { isSignedIn as getIsSignedIn } from '../../store/selectors/user';

export default ({ article, track }) => {
  const { type } = article.attributes || {};
  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  const isSignedIn = useSelector(getIsSignedIn);

  return (
    <>
      <TrackIconButton
        Icon={MdReply}
        label="Share"
        onClick={() => setShareDialogOpen(!isShareDialogOpen)}
        reverseIcon
        track={{
          event: 'Article Share Clicked',
          eventProperties: { article_type: type, article_id: article.id },
          ...(track || { source: 'article-share-button' }),
        }}
      />
      {isShareDialogOpen && (
        <ItemSharingDialog article={article} isSignedIn={isSignedIn} onCloseClick={() => setShareDialogOpen(false)} />
      )}
    </>
  );
};
