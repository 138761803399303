import React, { Component } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import { auth } from '../store/sagas/firebase';
import AuthScreenStyles from './AuthScreenStyles';

const styles = theme => ({
  ...AuthScreenStyles(theme),
  authStatus: {
    background: theme.palette.background.primary,
    color: theme.palette.text.primary,
    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.5)',
    marginTop: 40,
    padding: 20,
    borderRadius: 4,
    width: 400,
    textAlign: 'center',
    '& input': {
      padding: 10,
      width: 200,
      color: theme.palette.text.primary,
      background: theme.palette.signInInput.primary,
      border: `1px solid ${theme.palette.border.primary}`,
    },
  },
  error: {
    fontSize: '0.8125rem',
    color: 'red',
  },
  button: {
    ...AuthScreenStyles(theme).button,
    'input ~ &': {
      display: 'inline-block',
      marginLeft: 12,
    },
  },
});

const queryStrings = new URLSearchParams(window.location.search);
const mode = queryStrings.get('mode');
const oobCode = queryStrings.get('oobCode');

const authStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  RESET_PASSWORD: 'RESET_PASSWORD',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

class AuthEmailHandlerScreen extends Component {
  state = {
    status: authStatus.IN_PROGRESS,
    email: null,
    newPassword: '',
    errorMessage: '',
  };

  async componentDidMount() {
    try {
      switch (mode) {
        case 'resetPassword':
          // Verify oobCode, then get the user's email for display.
          const email = await auth().verifyPasswordResetCode(oobCode);
          this.setState({
            status: authStatus.RESET_PASSWORD,
            email,
          });
          break;
        case 'verifyEmail':
          // Verify oobCode, which also confirms the email verification directly.
          await auth().applyActionCode(oobCode);
          this.setState({ status: authStatus.SUCCESS });
          break;
        case 'recoverEmail':
          // We currently don't support this feature so users shouldn't be able to get here.
          // Just in case there's still some condition that causes Firebase auth to send users here,
          // pretend it's successful to show a generic support message.
          this.setState({ status: authStatus.SUCCESS });
          break;
        default:
          break;
      }
    } catch (err) {
      this.setState({
        status: authStatus.ERROR,
      });
    }
  }

  handleResetPassword = async () => {
    const { email, newPassword } = this.state;
    try {
      await auth().confirmPasswordReset(oobCode, newPassword);
      // done changing password, attempting to sign user in directly
      await auth().signInWithEmailAndPassword(email, newPassword);

      // make the component render success & redirecting message, then redirect to landing after a delay
      this.setState({ status: authStatus.SUCCESS });
      setTimeout(() => (window.location.href = window.location.origin), 3000);
    } catch (err) {
      this.setState({ errorMessage: err.message });
    }
  };

  render() {
    const { classes } = this.props;

    return <div className={classes.root}>{this.renderContent()}</div>;
  }

  renderContent() {
    const { classes } = this.props;

    if (this.state.status === authStatus.IN_PROGRESS) {
      return <div className="loading-circle" />;
    }

    if (this.state.status === authStatus.ERROR) {
      return (
        <div className={classes.authStatus}>
          <h1>Oops! Something is wrong...</h1>
          <p>Your request expired or the link has already been used.</p>
        </div>
      );
    }

    // It's not loading or errored, so call the render function for each specific mode.
    return <div className={classes.authStatus}>{this[mode]()}</div>;
  }

  resetPassword() {
    const { classes } = this.props;
    const { status, email, newPassword, errorMessage } = this.state;

    switch (status) {
      case authStatus.RESET_PASSWORD:
        return (
          <>
            <h1>Reset Password</h1>
            <p>
              for <b>{email}</b>
            </p>
            <input
              placeholder="new password"
              type="password"
              value={newPassword}
              onChange={event => this.setState({ newPassword: event.target.value })}
            />
            <button className={classes.button} onClick={this.handleResetPassword}>
              Submit
            </button>
            {!!errorMessage && <p className={classes.error}>{errorMessage}</p>}
          </>
        );
      case authStatus.SUCCESS:
        return <h5>Password reset successful, logging into Juked shortly...</h5>;
      default:
        // should be unreachable
        return null;
    }
  }

  verifyEmail() {
    const { classes } = this.props;

    // SUCCESS
    return (
      <>
        <h1>Your email has been verified</h1>
        <p>You can now sign in with your new account</p>
        <button
          className={classes.button}
          onClick={() => {
            window.location.href = window.location.origin;
          }}
        >
          Continue
        </button>
      </>
    );
  }

  // We currently don't support this functionality. Not sure if it still gets triggered by
  // firestore under certain situations, so safer to have it defined anyway.
  recoverEmail() {
    return <div>Please contact Juked customer support.</div>;
  }
}

export default compose(withStyles(styles))(AuthEmailHandlerScreen);
