import React from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { MdKeyboardTab } from 'react-icons/md';

const styles = theme => ({
  collapseButtonContainer: {
    display: 'flex',
    position: 'relative',
    height: 48,
    width: 48,
    justifyContent: 'center',
    background: theme.black,
    zIndex: 1,
    '.isChatCollapsed &': {
      position: 'absolute',
      right: 0,
      top: 0,
    },
    '.isTheaterMode &': {
      top: 0,
    },
  },
  collapseTooltipText: {
    position: 'absolute',
    zIndex: 1,
    right: 50,
    top: 15,
    width: 55,
    fontSize: 13,
    fontWeight: 600,
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center',
    padding: '2px 0',
    visibility: 'hidden',
    opacity: 0.8,
    '$collapseButtonContainer:hover &': {
      visibility: 'visible',
    },
  },
  collapseButton: {
    color: 'white',
    fontSize: 24,
    height: 48,
    zIndex: 1,
    cursor: 'pointer',
    '.isChatCollapsed &': {
      transform: 'rotate(180deg)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    collapseButtonContainer: {
      display: 'none',
    },
  },
});

const ChatCollapsedButton = ({ classes, className, isChatCollapsed, toggleCollapseChat }) => {
  return (
    <div className={`${classes.collapseButtonContainer}  ${className}`}>
      <span className={classes.collapseTooltipText}>{isChatCollapsed ? 'Expand' : 'Collapse'}</span>
      <MdKeyboardTab className={classes.collapseButton} onClick={() => toggleCollapseChat()} />
    </div>
  );
};

export default compose(withStyles(styles))(ChatCollapsedButton);
