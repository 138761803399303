import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import TopNavItems from './TopNavItems';
import UserDropdown from './UserDropdown';
import { TrackLink } from '../../common';
import FollowModeSwitch from '../../common/FollowModeSwitch';
import { isFeatureEnabled } from '../../../utils/featureFlags';
import { Search } from '../Search';

const useStyles = makeStyles(theme => ({
  appBar: {
    display: 'flex',
    zIndex: '1001', // Higher than footer.
    height: theme.topNavHeight,
    boxSizing: 'border-box',
    borderBottom: `1px solid ${theme.smokedDarkGrey}`,
    backgroundColor: 'black',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'space-between', // This makes appBarLeft and appBarRight stay left and right
    '&.isTheaterMode': {
      display: 'none',
    },
  },
  appBarLeft: {
    flex: 1, // This works with the appBar's display:flex to make it stretch, instead of appBarRight
    display: 'flex',
    alignItems: 'center',
  },
  appBarRight: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
  },
  logo: {
    fontSize: 25,
    fontWeight: 400,
    margin: 0,
    lineHeight: '25px',
    color: 'white',
    padding: 9,
    display: 'flex',
    textDecoration: 'none',
    height: '30px',
    background: theme.brandOrange,
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  linksContainer: {
    display: 'flex',
    flex: 1,
    height: theme.topNavHeight,
    paddingLeft: 16,
  },
}));

const TopNav = () => {
  const classes = useStyles();
  const isTheaterMode = useSelector(state => state.video.isTheaterMode);

  return (
    <div
      className={classNames({
        [classes.appBar]: true,
        isTheaterMode,
      })}
    >
      <div className={classes.appBarLeft}>
        <TrackLink
          to="/"
          className={classes.logo}
          title="JUKED"
          track={{ event: 'Navbar Juked Logo Clicked', source: 'top-nav', target: 'homepage' }}
        >
          <img src="/images/WebLogo.png" alt="Juked" />
        </TrackLink>
        {/* This container only renders on big screens */}
        <div className={classes.linksContainer}>
          <TopNavItems />
        </div>
      </div>

      <div className={classes.appBarRight}>
        <Search />
        {/*Follow button filter*/}
        {isFeatureEnabled('followModeSwitch') && <FollowModeSwitch />}

        {/* This is User feature's button & dropdown menu */}
        <UserDropdown />
      </div>
    </div>
  );
};

export default TopNav;
