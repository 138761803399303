import React from 'react';

import RoundBadge, { RoundBadgeProps } from './RoundBadge';
import { getSlugFromAbbr } from '../../../store/selectors/gameDataUtils';
import { Game } from '../../../store/types';

interface GameBadgeProps extends Omit<RoundBadgeProps, 'to'> {
  game: Game;
}

const GameBadge = ({
  animated,
  className,
  disabled,
  elevation,
  game,
  imgSrc,
  size,
  track,
}: GameBadgeProps): JSX.Element => {
  const { id, abbr } = game;
  return (
    <RoundBadge
      animated={animated}
      className={className}
      disabled={disabled}
      elevation={elevation}
      imgSrc={imgSrc}
      size={size}
      to={getSlugFromAbbr(abbr)}
      track={{
        event: 'Game Badge Clicked',
        eventProperties: {
          game_abbr: abbr,
          game_id: id,
        },
        target: 'game-page',
        ...(track || {}),
      }}
    />
  );
};

export default GameBadge;
