import createCachedSelector from 're-reselect';
import { createArrEqSelector } from './gameData';
import { sortAndFilterStartedSeries } from './gameDataUtils';
import { getLiveSeriesByTournamentId } from './liveSeries';
import { getUpcomingSeriesByTournamentId } from './upcomingSeries';

export const getOpenedSeriesNotifications = ({ notification: { openedSeriesNotifications } }) => {
  return openedSeriesNotifications.slice(); // slice to force update when array changes
};

// Used by the EventScreen notification RemindMe button
export const getNextUpcomingSeriesByTournamentId = createCachedSelector(
  getUpcomingSeriesByTournamentId,
  getLiveSeriesByTournamentId,
  sortAndFilterStartedSeries
)({
  keySelector: (_, id) => `${id}`,
  selectorCreator: createArrEqSelector,
});
