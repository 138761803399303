import React, { Component } from 'react';
import { PLATFORM_TYPES } from '../../store/reducers/video';

class PlayerVolumeHandler extends Component {
  componentDidMount() {
    this.props.loadVolumeFn();
  }

  componentDidUpdate() {
    const { videoPlayerState, playerVolume, platformType } = this.props;

    if (!videoPlayerState) return;

    const { player } = videoPlayerState;

    const isTwitchPlayer = platformType === PLATFORM_TYPES.TWITCH;

    if (player && (Boolean(playerVolume) || playerVolume === 0)) {
      if (isTwitchPlayer) {
        player.addEventListener(window.Twitch.Player.PLAYING, () =>
          this.handleTwitchVolumeChnage(player, playerVolume)
        );
      } else {
        player.addEventListener('onReady', () => this.handleYoutubeVolumeChnage(player, playerVolume));
      }
    }
  }

  handleTwitchVolumeChnage = (player, volume) => {
    player.setVolume(parseFloat(volume));
    player.removeEventListener(window.Twitch.Player.PLAYING, () => this.handleTwitchVolumeChnage(player, volume));
  };

  handleYoutubeVolumeChnage = (player, volume) => {
    player.setVolume(volume * 100);
    player.removeEventListener('onReady', () => this.handleYoutubeVolumeChnage(player, volume));
  };

  render() {
    return <>{this.props.children} </>;
  }
}

export default PlayerVolumeHandler;
