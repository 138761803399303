import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { TWITTER } from '../../../store/reducers/twitter';
import { PLATFORMS } from '../../../store/reducers/video';

const getThemeColors = provider => {
  switch (provider) {
    case PLATFORMS.TWITCH:
      return {
        primary: '#7d5bbe',
        secondary: '#9a7fcc',
        active: '#6441a4',
      };
    case PLATFORMS.YOUTUBE:
      return {
        primary: '#ff0000',
        secondary: '#ff4040',
        active: '#c4302b',
      };
    default:
      return {
        primary: '#1b95e0',
        secondary: '#45aae9',
        active: '#1da1f2',
      };
  }
};

const useStyles = makeStyles({
  chatButton: {
    height: 48,
    width: 'calc((100% - 48px)/2)', // Half of the entire width less the "CollapseChatButton width"
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#1E2424',
    border: 'none',
    borderLeft: ({ provider }) => (provider === TWITTER ? '2px solid #3B3B3B' : 'none'),
    borderBottom: '1px solid #3B3B3B',
    display: 'flex',
    transition: '.1s ease-in',
    '&:hover:enabled': {
      cursor: 'pointer',
    },
    '&:focus:enabled': {
      outline: 'none',
    },
    '& svg': {
      color: ({ provider, active }) => (!!active ? getThemeColors(provider).primary : '#fff'),
      fontSize: 22,
    },
  },
});

export default ({ className, icon, onClick, active, provider }) => {
  const classes = useStyles({ provider, active });
  return (
    <button className={`${classes.chatButton} ${className}`} onClick={onClick}>
      {icon}
    </button>
  );
};
