import classNames from 'classnames';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { BodyText } from '../';

const useStyles = makeStyles(theme => ({
  tab: {
    borderBottom: `2px solid transparent`,
    cursor: 'pointer',
    display: 'inline-block',
    lineHeight: 'normal',
    paddingBottom: 18,
    transition: 'border-bottom 350ms ease',
    '& .navtab-text': {
      color: theme.darkGrey,
      lineHeight: 'normal',
      transition: 'color 350ms ease',
    },
  },
  isActive: {
    borderBottom: `2px solid ${theme.magentaPink}`,
    '& .navtab-text': {
      color: theme.palette.text.primary,
    },
  },
}));

const NavTab = ({ isActive, children, className, onClick }) => {
  const classes = useStyles({ isActive });

  return (
    <BodyText
      className={classNames({ [className]: true, [classes.tab]: true, [classes.isActive]: Boolean(isActive) })}
      onClick={onClick}
      variant="tabLabel"
    >
      <span className="navtab-text">{children}</span>
    </BodyText>
  );
};

export default NavTab;
