import axios from 'axios';

const { REACT_APP_PARSE_API_BASE } = process.env;

const PARSE_API_BASE = REACT_APP_PARSE_API_BASE || 'http://localhost:1337';
const ensureSlashes = url => `/${url}/`.replace(/\/\//g, '/');
export const getParseUrl = endpoint => PARSE_API_BASE + ensureSlashes(endpoint);

// Will need withCredentials if backend server is hosted on a different domain.
export const axiosParse = axios.create({
  baseURL: PARSE_API_BASE,
  withCredentials: true,
});
