import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import withTrackClick from '../Wrappers/withTrackClick';
import { Button, IconButton } from './buttons';
import IconLink from './IconLink';
import QueryLink from './QueryLink';

export const TrackA = withTrackClick(({ children, ...props }) => <a {...props}>{children}</a>);

export const TrackButton = withTrackClick(Button);

export const TrackButtonBasic = withTrackClick(props => <button {...props} />);

export const TrackDiv = withTrackClick(props => <div {...props} />);

export const TrackHashLink = withTrackClick(HashLink);

export const TrackIconButton = withTrackClick(IconButton);

export const TrackIconLink = withTrackClick(IconLink);

export const TrackLink = withTrackClick(Link);

export const TrackQueryLink = withTrackClick(QueryLink);

export const TrackSpan = withTrackClick(props => <span {...props} />);
