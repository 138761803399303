import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';

import GamePathHandler from './components/GamePathHandler';
import WindowTitle from './components/WindowTitle';
import ScrollToTopRoute from './components/Wrappers/ScrollToTopRoute';
import NavBar from './components-v2/NavBar';
import TopTicker from './components-v2/TopTicker';
import { withThemeProvider } from './components-v2/wrappers';
import { HomeScreen } from './screens-v2';
import { initializeMixpanel, initializeSaga } from './store';
import { getShownGames } from './store/selectors/gameData';
import { isSignedIn as getIsSignedIn } from './store/selectors/user';
import { getGameSlugsPrefixPath, renderAuthRoutes } from './utils/appHelpers';

const AppV2 = (): JSX.Element => {
  const { pathname } = useLocation();
  const gameList = useSelector(getShownGames);
  const isSignedIn = useSelector(getIsSignedIn);

  // Constructor logic
  useEffect((): void => {
    initializeSaga();
    initializeMixpanel();
  }, []);

  // TODO: Track location logic...

  const gameSlugsPathPrefix = getGameSlugsPrefixPath({ gameList });

  return (
    <>
      {/* Not a visible component, just to handle game-slug related path changes. */}
      <Route path={gameSlugsPathPrefix} component={GamePathHandler} />
      <div>
        <WindowTitle
          title="Juked.gg - Watch All Live Esports"
          description="Visit Juked to watch the top esports tournaments from your favorite esports games - live now, upcoming calendar, and VOD."
        />
        <TopTicker />
        <NavBar />
        <Switch>
          <ScrollToTopRoute exact path="/v2" component={HomeScreen} />
          <ScrollToTopRoute exact path={`/v2/${gameSlugsPathPrefix}`} component={HomeScreen} />

          {/* Auth-related routes, needs to be last */}
          {renderAuthRoutes({ pathname, isSignedIn })}
        </Switch>
      </div>
    </>
  );
};

export default withThemeProvider(AppV2);
