import { eventChannel } from 'redux-saga';
import { call, put, take, fork, takeEvery, takeLeading, select } from 'redux-saga/effects';

import firebase, { db, auth } from './firebase';
import { clearOnLoginSuccess } from '../actions/dialogs';
import { resetNotifications, updateNotifications } from '../actions/notification';
import {
  FOLLOW_FIELD_VALUES,
  SET_USER,
  UPDATE_USER,
  setUser,
  signOut,
  updateUser,
  SIGN_OUT,
  SIGN_IN_COMPLETE,
  SIGN_UP_COMPLETE,
  CHANGE_USER_ARRAY_OPT_IN,
  REMOVE_PREFERRED_ENTITY,
  REMOVE_PREFERRED_ENTITY_NOTIFICATIONS,
  SAVE_PREFERENCES_ACCOUNT,
  SAVE_PREFERRED_ENTITIES,
  SAVE_PREFERRED_ENTITIES_NOTIFICATIONS,
  SAVE_PREFERRED_ENTITY,
  SAVE_PREFERRED_ENTITY_NOTIFICATIONS,
  SAVE_USER_TIME_ZONE,
  setPreferencesAccountStatus,
  setPreferencesError,
  SET_PREFERENCES_COMPLETED_AT,
} from '../actions/user';
import { resetUiStates, setAdvocateRef, setVisitorUUID } from '../actions/uiStates';
import { setSportBuffToken } from '../actions/user';
import { getAdvocateReference, getVisitorUUID } from '../selectors/uiStates';
import { PREFERENCE_TYPES } from '../reducers/user';
import { getUser, isSignedIn } from '../selectors/user';
import { clearUserGA, setUserGA, trackEventGa } from '../../utils/googleAnalytics';
import { awaitMixpanelLoaded, setMixpanelAlias, setMixpanelIdentity, updateMixpanelUser } from '../../utils/mixpanel';
import SnapshotObserver from '../../utils/SnapshotObserver';
import tryCatchWrapper from '../../utils/tryCatchWrapper';

const OBSERVER_KEYS = {
  USERS: 'users-doc',
  USER_NOTIFICATIONS: 'user-notifications-doc',
};
const snapshotObserver = new SnapshotObserver();

function authChangeChannel() {
  return eventChannel(emit => {
    return auth().onAuthStateChanged(user => {
      emit({ user });
    });
  });
}

function* subscribeToAuthChange() {
  const channel = authChangeChannel();

  while (true) {
    const { user } = yield take(channel);
    yield put(setUser(user));
  }
}

function* userProcessor({ data, exists }) {
  if (exists) {
    const { uid } = data;

    // First message loads with only `fcmToken` property, not sure why...
    if (!uid) return;

    // When user is added (page refresh, login, sign up), check for timeZone after we receive the entire user object.
    if (!data.timeZone) {
      yield call(saveUserTimeZone, {});
    }

    if (!data.visitorUUID) {
      yield call(saveVisitorUUID);
    } else {
      const coockiedVisitorUUID = yield select(getVisitorUUID);
      if (data.visitorUUID !== coockiedVisitorUUID) {
        // Sync user visitorUUID into state if for some reason they are different.
        yield put(setVisitorUUID(data.visitorUUID));
      }
    }

    setUserGA({ userId: uid });
    return yield put(updateUser({ ...data, fetched: true }));
  } else if (yield select(isSignedIn)) {
    return yield put(signOut());
  }
}

function* notificationsProcessor(data) {
  return yield put(updateNotifications(data));
}

function* onSetUser(action) {
  if (action.user) {
    // NOTE: Remember to unsubscribe `snapshotObservers` in onSignOut().
    yield snapshotObserver.subscribe({
      query: db.collection('users').doc(action.user.uid),
      processorFn: userProcessor,
      snapshotHandlerFn: (emit, doc) => {
        emit({ data: doc.data(), exists: doc.exists });
      },
      key: OBSERVER_KEYS.USERS,
    });

    yield snapshotObserver.subscribe({
      query: db.collection('userNotifications').doc(action.user.uid),
      processorFn: notificationsProcessor,
      snapshotHandlerFn: (emit, doc) => {
        emit(doc.data() || {});
      },
      key: OBSERVER_KEYS.USER_NOTIFICATIONS,
    });
  }
}

function* onSignOut() {
  // NOTE: Must unsubscribe from each user-dependent observer here.
  snapshotObserver.unsubscribe(OBSERVER_KEYS.USERS);
  snapshotObserver.unsubscribe(OBSERVER_KEYS.USER_NOTIFICATIONS);

  clearUserGA();

  yield put(resetNotifications());
  yield put(resetUiStates());
  yield put(clearOnLoginSuccess());
  yield auth().signOut();
}

function* awaitFetchedUser() {
  yield take(UPDATE_USER);
  return yield select(getUser);
}

function* setMixpanelAliasOrIdentity(user) {
  yield call(awaitMixpanelLoaded);

  if (user.requiresMixpanelAlias) {
    // If requiresMixpanelAlias has been set, we need to add an alias.
    // This will happen for accounts created prior to mixpanel integration.
    setMixpanelAlias(user);
    yield db
      .collection('users')
      .doc(user.uid)
      .update({ requiresMixpanelAlias: firebase.firestore.FieldValue.delete() });
  } else {
    setMixpanelIdentity(user);
  }
}

function* onSignUpComplete({ providerId }) {
  yield onLoginSuccess();
  const authUser = auth().currentUser;

  // send email verification if not using SSO
  if (authUser.providerData[0].providerId === 'password') {
    authUser.sendEmailVerification({
      // Set redirect URL after verification to be the origin of the site.
      url: window.location.origin,
    });
  }

  // Wait for new user profile to be received.
  const user = yield call(awaitFetchedUser);
  const { visitorUUID } = yield select(({ uiStates }) => uiStates);

  // Execute new signup actions.
  yield db.collection('users').doc(user.uid).update({
    visitorUUID, // Saves anonymous visitor activity UUID to user.
  });

  if (!user.timeZone) {
    yield call(saveUserTimeZone, {});
  }

  // Set mixpanel alias to merge anonymous user data with mixpanel profile.
  yield call(awaitMixpanelLoaded);
  setMixpanelAlias(user);

  yield call(() => registerReferral(user.uid));

  // Track conversion in Google Analytics
  setUserGA({ userId: user.uid });
  trackEventGa({
    category: 'auth',
    action: 'signup success',
    label: providerId,
  });
}

function* registerReferral(userId) {
  // If we have an advocete reference post the signUp conversion call.
  const advocateRef = yield select(getAdvocateReference);
  if (!!advocateRef) {
    try {
      yield call(() =>
        fetch(new URL('https://api.adv.gg/v1/register-conversion/'), {
          method: 'POST',
          headers: { 'Content-Type': 'application/json;charset=UTF-8' },
          body: JSON.stringify({ source: advocateRef }),
        })
      );

      yield db
        .collection('users')
        .doc(userId)
        .update({ referral: { source: 'advocate', trackingId: advocateRef } });
    } catch (e) {
      console.error(e);
    }
    // Clear advocateRef flag in case of multiple signUps from the same machine.
    yield put(setAdvocateRef(null));
  }
}

function* onLoginSuccess() {
  const onLoginSuccess = yield select(({ dialogs: { onLoginSuccess } }) => onLoginSuccess);
  if (onLoginSuccess) {
    onLoginSuccess();
    yield put(clearOnLoginSuccess());
  }
}

function* onSignInComplete() {
  yield onLoginSuccess();

  // Wait for user profile to be received.
  const user = yield call(awaitFetchedUser);

  if (!user.timeZone) {
    yield call(saveUserTimeZone, {});
  }

  if (!user.visitorUUID) {
    yield call(saveVisitorUUID);
  } else {
    // Sync user visitorUUID into state.
    yield put(setVisitorUUID(user.visitorUUID));
  }

  // Execute new signup actions.
  setUserGA({ userId: user.uid });
  yield call(setMixpanelAliasOrIdentity, user);
  yield put(setSportBuffToken(null));
}

// Preferences

function* authenticate(currentPassword) {
  const user = auth().currentUser;
  const cred = auth.EmailAuthProvider.credential(user.email, currentPassword);
  return yield user.reauthenticateWithCredential(cred);
}

function* onSavePreferencesAccount(action) {
  const { currentPassword, displayName, email, newPassword, isSSOProvider } = action.data;

  yield put(setPreferencesAccountStatus('saving'));

  //If the user is using Google or Twitter as their provider, they don't have to authenticate to change the displayName
  if (!isSSOProvider) {
    try {
      yield call(authenticate, currentPassword);
      yield put(setPreferencesError({ currentPassword: null }));
    } catch (e) {
      yield put(setPreferencesError({ currentPassword: e.message }));
      return;
    }
  }

  if (displayName) {
    const nameSuccess = yield call(changeUserDisplayName, displayName);
    if (!nameSuccess) return;
  }

  if (newPassword) {
    const pwSuccess = yield call(changeUserPassword, newPassword);
    if (!pwSuccess) return;
  }

  if (email) {
    const emailSuccess = yield call(changeUserEmail, email);
    if (!emailSuccess) return;
    window.location.reload(); // sign out to force email re-validation.
  }

  // Set completion status.
  yield put(setPreferencesAccountStatus('saved'));
  yield call(updateMixpanelUser); // Update mixpanel.
}

function* changeUserDisplayName(displayName) {
  // TODO: validate action.name
  // TODO: check displayName uniqueness
  if (displayName.length < 5) {
    yield put(setPreferencesError({ displayName: 'Username must be 5 or more characters.' }));
    return false;
  }

  try {
    yield auth().currentUser.updateProfile({ displayName });
    yield put(updateUser({ displayName }));
    yield put(setPreferencesError({ displayName: null }));
    return true;
  } catch (e) {
    yield put(setPreferencesError({ displayName: e.message }));
    return false;
  }
}

function* changeUserEmail(email) {
  const user = auth().currentUser;

  try {
    yield user.updateEmail(email);
    yield user.sendEmailVerification({ url: window.location.origin });

    // Also update record in firebase
    yield db.collection('users').doc(user.uid).set({ email }, { merge: true });

    // TODO: maybe do something less aggressive
    yield put(setPreferencesError({ email: null }));
    return true;
  } catch (e) {
    yield put(setPreferencesError({ email: e.message }));
    return false;
  }
}

function* changeUserPassword(newPassword) {
  try {
    yield auth().currentUser.updatePassword(newPassword);
    yield put(setPreferencesError({ newPassword: null }));
    return true;
  } catch (e) {
    yield put(setPreferencesError({ newPassword: e.message }));
    return false;
  }
}

function* onChangeUserArrayOptIn({ optionName, value, isOptIn }) {
  const user = yield select(getUser);
  yield db
    .collection('users')
    .doc(user.uid)
    .set(
      {
        [optionName]: isOptIn
          ? firebase.firestore.FieldValue.arrayUnion(value)
          : firebase.firestore.FieldValue.arrayRemove(value),
      },
      { merge: true }
    );
}

function* onSavePreferredEntities({ entityField, selectedIds }) {
  if (!FOLLOW_FIELD_VALUES.includes(entityField)) {
    console.error(`[onSavePreferredEntities] Invalid type: ${entityField}`);
    return;
  }

  if (!auth().currentUser) return;
  const user = yield select(getUser);
  const notifyingIds = selectedIds.filter(id => {
    // To prevent loosing previous notification states we need to make sure we are subscribing to the just added
    // entities (not already included in the user preferences), or the already notification subscribed entities.
    const isNewPreference = !(user.preferences?.[entityField] || []).includes(id);
    const isExistingNotification = (user.notifications?.[entityField] || []).includes(id);
    return isNewPreference || isExistingNotification;
  });

  // Update preferences in DB.
  yield db
    .collection('users')
    .doc(auth().currentUser.uid)
    // When a user follows an entity, we also enable notifications automatically.
    .update({ [`preferences.${entityField}`]: selectedIds, [`notifications.${entityField}`]: notifyingIds });

  // Update mixpanel
  yield call(updateMixpanelUser);
}

function* onSavePreferredEntity({ entityField, selectedId }) {
  if (!FOLLOW_FIELD_VALUES.includes(entityField)) {
    console.error(`[onSavePreferredEntity] Invalid type: ${entityField}`);
    return;
  }

  if (!auth().currentUser) return;

  // Update preferences in DB.
  yield db
    .collection('users')
    .doc(auth().currentUser.uid)
    .update({
      [`preferences.${entityField}`]: firebase.firestore.FieldValue.arrayUnion(selectedId),
      // When a user follows an entity, we also enable notifications automatically.
      [`notifications.${entityField}`]: firebase.firestore.FieldValue.arrayUnion(selectedId),
    });

  // Update mixpanel
  yield call(updateMixpanelUser);
}

function* onRemovePreferredEntity({ entityField, selectedId }) {
  if (!FOLLOW_FIELD_VALUES.includes(entityField)) {
    console.error(`[onRemovePreferredEntity] Invalid type: ${entityField}`);
    return;
  }

  if (!auth().currentUser) return;

  // Update preferences in DB.
  yield db
    .collection('users')
    .doc(auth().currentUser.uid)
    .update({
      [`preferences.${entityField}`]: firebase.firestore.FieldValue.arrayRemove(selectedId),
      // When a user follows an entity, we also enable notifications automatically.
      [`notifications.${entityField}`]: firebase.firestore.FieldValue.arrayRemove(selectedId),
    });

  // Update mixpanel
  yield call(updateMixpanelUser);
}

function* onSavePreferredEntitiesNotifications({ entityField, selectedIds }) {
  if (!FOLLOW_FIELD_VALUES.includes(entityField)) {
    console.error(`[onSavePreferredEntitiesNotifications] Invalid type: ${entityField}`);
    return;
  }

  if (!auth().currentUser) return;

  // Update notifications in DB.
  yield db
    .collection('users')
    .doc(auth().currentUser.uid)
    .update({ [`notifications.${entityField}`]: selectedIds });

  // Update mixpanel
  yield call(updateMixpanelUser);
}

function* onSavePreferredEntityNotifications({ entityField, selectedId }) {
  if (!FOLLOW_FIELD_VALUES.includes(entityField)) {
    console.error(`[onSavePreferredEntityNotifications] Invalid type: ${entityField}`);
    return;
  }

  if (!auth().currentUser) return;

  // Update notifications in DB.
  yield db
    .collection('users')
    .doc(auth().currentUser.uid)
    .update({ [`notifications.${entityField}`]: firebase.firestore.FieldValue.arrayUnion(selectedId) });

  // Update mixpanel
  yield call(updateMixpanelUser);
}

function* onRemovePreferredEntityNotifications({ entityField, selectedId }) {
  if (!FOLLOW_FIELD_VALUES.includes(entityField)) {
    console.error(`[onRemovePreferredEntityNotifications] Invalid type: ${entityField}`);
    return;
  }

  if (!auth().currentUser) return;

  // Update notifications in DB.
  yield db
    .collection('users')
    .doc(auth().currentUser.uid)
    .update({ [`notifications.${entityField}`]: firebase.firestore.FieldValue.arrayRemove(selectedId) });

  // Update mixpanel
  yield call(updateMixpanelUser);
}

function* saveUserTimeZone({ selectedTimeZone }) {
  // Update user time zone in DB.
  yield db
    .collection('users')
    .doc(auth().currentUser.uid)
    // If no time zone selected "Intl.DateTimeFormat().resolvedOptions().timeZone" will return clients
    // time zone. Example: 'America/Los_Angeles'
    .update({ timeZone: selectedTimeZone || Intl.DateTimeFormat().resolvedOptions().timeZone });
}

function* saveVisitorUUID() {
  // For existing users that have no visitorUUID, add it to profile.
  const user = yield select(getUser);
  if (user && !user.visitorUUID) {
    const { visitorUUID } = yield select(({ uiStates }) => uiStates);
    yield db.collection('users').doc(auth().currentUser.uid).update({ visitorUUID });
  }
}

function* onSetPreferencesCompletedAt({ preferencesType }) {
  if (!Object.values(PREFERENCE_TYPES).includes(preferencesType)) {
    console.error(`[onSetPreferencesCompletedAt] Invalid type: ${preferencesType}`);
  } else {
    yield db
      .collection('users')
      .doc(auth().currentUser.uid)
      .update({ [`preferences.${preferencesType}CompletedAt`]: new Date() });
  }
}

export default function* userSaga() {
  yield fork(subscribeToAuthChange);
  yield takeEvery(CHANGE_USER_ARRAY_OPT_IN, onChangeUserArrayOptIn);
  yield takeEvery(REMOVE_PREFERRED_ENTITY, onRemovePreferredEntity);
  yield takeEvery(SAVE_PREFERRED_ENTITIES, onSavePreferredEntities);
  yield takeEvery(SAVE_PREFERRED_ENTITY, onSavePreferredEntity);
  yield takeEvery(REMOVE_PREFERRED_ENTITY_NOTIFICATIONS, onRemovePreferredEntityNotifications);
  yield takeEvery(SAVE_PREFERRED_ENTITIES_NOTIFICATIONS, onSavePreferredEntitiesNotifications);
  yield takeEvery(SAVE_PREFERRED_ENTITY_NOTIFICATIONS, onSavePreferredEntityNotifications);
  yield takeEvery(SAVE_USER_TIME_ZONE, saveUserTimeZone);
  yield takeEvery(SET_PREFERENCES_COMPLETED_AT, onSetPreferencesCompletedAt);
  yield takeEvery(SET_USER, onSetUser);
  yield takeEvery(SIGN_IN_COMPLETE, tryCatchWrapper(onSignInComplete));
  yield takeEvery(SIGN_OUT, onSignOut);
  yield takeEvery(SIGN_UP_COMPLETE, tryCatchWrapper(onSignUpComplete));
  yield takeLeading(SAVE_PREFERENCES_ACCOUNT, onSavePreferencesAccount);
}
