import React, { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { MdMenu } from 'react-icons/md';

import Drawer from './Drawer';
import { TrackButtonBasic, TrackLink, TrackQueryLink, TrackDiv } from '../../common';
import { isFeatureEnabled } from '../../../utils/featureFlags';
import { addStaticBackgroundContainer, removeStaticBackgroundContainer } from '../../../store/actions/uiStates';
import { Search } from '../Search';

const useStyles = makeStyles(theme => ({
  appBar: {
    display: 'flex',
    zIndex: '1001', // Higher than footer.
    height: '3rem',
    boxSizing: 'border-box',
    borderBottom: '1px solid rgba(0,0,0,0.0975)',
    backgroundColor: 'black',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'space-between', // This makes appBarLeft and appBarRight stay left and right
    '&.isTheaterMode': {
      display: 'none',
    },
  },
  appBarLeft: {
    flex: 1, // This works with the appBar's display:flex to make it stretch, instead of appBarRight
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flex: 'unset',
    },
  },
  appBarRight: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  logo: {
    fontSize: 25,
    fontWeight: 400,
    margin: 0,
    lineHeight: '25px',
    color: 'white',
    padding: 9,
    display: 'flex',
    textDecoration: 'none',
    height: '30px',
    background: theme.brandOrange,
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  linksContainer: {
    marginLeft: 24,
  },
  link: {
    color: 'white',
    fontSize: 15,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '20px',
    textDecoration: 'none',
    marginRight: 22,
    display: 'inline',
    '&.hideOnMobile': {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  },
  menuButton: {
    fontSize: 28,
    color: 'white',
    lineHeight: '25px',
    backgroundColor: 'transparent',
    border: 0,
    outline: 0,
    display: 'block',
    verticalAlign: 'middle',
    margin: '0 12px',
    padding: 0,
  },
}));

const SideNav = () => {
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isTheaterMode = useSelector(state => state.video.isTheaterMode);

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
    dispatch(removeStaticBackgroundContainer());
  }, [dispatch]);

  const handleAnyClick = useCallback(
    e => {
      const { id } = e.target;
      if (drawerOpen && !['darkModeSwitch', 'slider', 'sliderIcon', 'followModeSwitch'].find(elId => id === elId)) {
        //onClick event can be emitted by any element inside the sliderSwitch
        closeDrawer();
      }
    },
    [drawerOpen, closeDrawer]
  );

  useEffect(() => {
    window.addEventListener('resize', closeDrawer);
    document.body.addEventListener('click', handleAnyClick);

    return () => {
      window.removeEventListener('resize', closeDrawer);
      document.body.removeEventListener('click', handleAnyClick);
    };
  }, [handleAnyClick, closeDrawer]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
    dispatch(addStaticBackgroundContainer());
  };

  const classes = useStyles();
  return (
    <div
      className={classNames({
        [classes.appBar]: true,
        isTheaterMode,
      })}
    >
      <div className={classes.appBarLeft}>
        <TrackLink
          to="/"
          className={classes.logo}
          title="JUKED"
          track={{ event: 'Navbar Juked Logo Clicked', source: 'side-nav', target: 'homepage' }}
        >
          <img src="/images/WebLogo.png" alt="Juked" />
        </TrackLink>
        <div className={classes.linksContainer}>
          <TrackDiv
            id="browseTab"
            className={classes.link}
            track={{ event: 'Browse Tab Clicked', source: 'side-nav', target: 'browse-drawer' }}
          >
            Browse
          </TrackDiv>
          <TrackLink
            to="/calendar"
            className={`${classes.link} hideOnMobile`}
            title="JUKED"
            track={{
              event: 'Navbar Calendar Clicked',
              source: 'top-nav',
              target: 'calendar-page',
            }}
          >
            Calendar
          </TrackLink>
          {/* This Links the user to the news page */}
          {isFeatureEnabled('articlesPage') && (
            <TrackLink
              to={{
                pathname: '/news',
              }}
              className={`${classes.link} hideOnMobile`}
              track={{
                event: 'Navbar News Clicked',
                source: 'side-nav',
                target: 'News-page',
              }}
            >
              News
            </TrackLink>
          )}
          <TrackQueryLink
            to="/vods"
            className={`${classes.link} hideOnMobile`}
            track={{
              event: 'Navbar VODS Clicked',
              source: 'side-nav',
              target: 'vods-page',
            }}
          >
            VODs
          </TrackQueryLink>
        </div>
      </div>
      <div className={classes.appBarRight}>
        <Search />
        <TrackButtonBasic
          title="Open Game List"
          className={classes.menuButton}
          onClick={handleDrawerToggle}
          track={{
            event: 'Navbar Menu Toggled',
            source: 'side-nav',
            target: !!drawerOpen ? 'collapsed' : 'expanded',
          }}
        >
          <MdMenu />
        </TrackButtonBasic>
      </div>

      <Drawer drawerOpen={drawerOpen} />
    </div>
  );
};

export default SideNav;
