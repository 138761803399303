import React, { useCallback, useEffect } from 'react';
import classname from 'classnames';
import { MdClose, MdSearch } from 'react-icons/md';
import { makeStyles } from '@material-ui/core/styles';
import { SearchBox, withSearch } from '@elastic/react-search-ui';
import { useDispatch } from 'react-redux';

import { trackEvent } from '../../../store/actions/uiStates';
import { usePrevious } from '../../../hooks';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  searchBox: {
    '& .sui-search-box__wrapper': {
      width: '100%',
    },
    '& .sui-search-box__text-input': {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.border.soloDenary}`,
      borderRadius: 4,
      boxSizing: 'border-box',
      color: theme.palette.text.primary,
      fontSize: 15,
      fontWeight: 600,
      outline: 'none',
      padding: '10px 16px',
      position: 'relative',
      transition: 'background-color 75ms ease-in, box-shadow 75ms ease-in',
      width: '100%',
      '&:focus': {
        backgroundColor: theme.palette.background.duoDenary,
        boxShadow: '0 2px 4px rgba(59,69,79,.3)',
      },
    },
    '& .sui-search-box__autocomplete-container': {
      backgroundColor: theme.palette.background.primary,
      border: `1px solid ${theme.palette.border.soloDenary}`,
      borderRadius: 4,
      boxShadow: '0 2px 4px rgba(59,69,79,.3)',
      boxSizing: 'content-box',
      display: 'flex',
      flexDirection: 'column',
      left: 0,
      lineHeight: 1.5,
      margin: 0,
      padding: '24px 0 12px',
      position: 'absolute',
      right: 0,
      top: '110%',
      zIndex: 1,
    },
    '& .sui-search-box__autocomplete-container ul': {
      background: 'transparent',
      borderRadius: 3,
      listStyle: 'none',
      margin: 0,
      padding: '0 0 24px',
      '&:last-child': {
        padding: 0,
      },
    },
    '& .sui-search-box__autocomplete-container li': {
      borderRadius: 4,
      color: theme.palette.text.primary,
      cursor: 'default',
      display: 'list-item',
      fontSize: '.9em',
      margin: '0 12px',
      padding: '4px 12px',
      '&[aria-selected="true"]': {
        background: theme.palette.background.quinary,
      },
    },
  },
  searchIcon: {
    color: theme.charcoalGrey,
    fontSize: 24,
    height: 42,
    padding: '9px 16px',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  closeIcon: {
    cursor: 'pointer',
  },
}));

const mapContextToProps = ({ searchTerm, reset, results }) => ({
  searchTerm,
  reset,
  results,
});

const InputView = ({ getAutocomplete, getInputProps }) => {
  return (
    <div className="sui-search-box__wrapper">
      <input
        {...getInputProps({
          placeholder: 'Search...',
        })}
      />
      {getAutocomplete()}
    </div>
  );
};

// Use wrapped search box to limit the number of rerenders occuring when you need access to results.
const WrappedSearchBox = withSearch(mapContextToProps)(
  ({
    className,
    searchTerm,
    setSearchResults,
    reset,
    results,
    hasSuggestions = true,
    searchAsYouType = false,
    inputProps,
    track,
    exposeResetCb,
  }) => {
    // NOTE: results, searchTerm, setSearchTerm are provided by withSearch HOC.
    const classes = useStyles();
    const prevResults = usePrevious(results);
    const prevSearchTerm = usePrevious(searchTerm);
    const resetSearch = useCallback(() => reset(), [reset]);
    const dispatch = useDispatch();

    const { value: parentCustomSearchTerm } = inputProps || {};

    // reset the input from a parent component, a bit hacky but works
    // the issue was state getting shared between browse and nav search
    useEffect(() => {
      exposeResetCb && exposeResetCb(reset);
    }, [exposeResetCb, reset]);

    useEffect(() => {
      // Use setSearchResults if provided when results change.
      if (setSearchResults && results !== prevResults) {
        searchTerm && setSearchResults(results);
        //This will track the search input to mixpanel
        if (searchTerm) {
          const { event, ...trackingRest } = track;
          dispatch(
            trackEvent(event, {
              target: 'search-input',
              ...trackingRest,
              eventProperties: { ...trackingRest.eventProperties, searchTerm },
            })
          );
        }
      }
    }, [prevResults, setSearchResults, results, dispatch, searchTerm, track]);

    useEffect(() => {
      // Use setSearchResults if provided to clear results if user deletes searchTerm.
      if (setSearchResults && searchTerm !== prevSearchTerm && !searchTerm) setSearchResults([]);
    }, [prevSearchTerm, searchTerm, setSearchResults]);

    return (
      <div className={`${classes.root} ${className}`}>
        <SearchBox
          searchAsYouType={searchAsYouType}
          autocompleteSuggestions={hasSuggestions}
          className={classes.searchBox}
          inputView={InputView}
          inputProps={inputProps}
        />
        <div className={classname(classes.searchIcon, { [classes.closeIcon]: !!searchTerm })}>
          {parentCustomSearchTerm || (searchTerm && parentCustomSearchTerm === undefined) ? (
            <MdClose onClick={resetSearch} />
          ) : (
            <MdSearch />
          )}
        </div>
      </div>
    );
  }
);

export default WrappedSearchBox;
