import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { TrackButton, H2 } from '../common';
import { SIGNUP_DIALOG_ACTIONS } from '../User/Dialogs/index';
import { closeLoginDialog } from '../../store/actions/dialogs';

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '64px 96px',
    '& > h2': {
      textAlign: 'center',
    },
  },
  subTitle: {
    fontSize: 15,
    color: theme.suvaGray,
    textAlign: 'center',
  },
  button: {
    marginTop: 32,
    '&.dark': {
      background: theme.smokedDarkGrey,
      marginTop: 16,
      '&:hover': {
        background: theme.blackGrey,
      },
    },
  },
  '@media (max-width: 700px)': {
    container: {
      padding: '20px 14px 30px',
    },
  },
}));

const FollowGlobalFilterDialog = ({ isSignedIn, source, view }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const track = {
    event: SIGNUP_DIALOG_ACTIONS.SIGNUP,
    eventProperties: { view, is_signed_in: isSignedIn },
    source,
    target: 'login',
  };

  const handleClick = useCallback(() => {
    dispatch(closeLoginDialog());
    history.push('/login');
  }, [history, dispatch]);

  return (
    <div className={classes.container}>
      <H2 variant="semiBold">You need to be signed in to use Follow mode</H2>
      <span className={classes.subTitle}>
        Sign up or sign in to use Follow Mode, for the most personalized Juked experience.
      </span>
      <TrackButton className={classes.button} theme={'dialog-action'} onClick={handleClick} track={track}>
        Sign Up
      </TrackButton>
      <TrackButton className={`${classes.button} dark`} theme={'dialog-action'} onClick={handleClick} track={track}>
        Log In
      </TrackButton>
    </div>
  );
};

export default FollowGlobalFilterDialog;
