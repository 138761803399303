import React, { Fragment } from 'react';

// Intentionally unstyled but broken up in separated <span> tags to allow callers to customize styling
export default (names, separator) => {
  if (names.every(name => name === 'TBD')) {
    return '';
  }
  if (names.length <= 4) {
    return names.join(separator || ' | ');
  } else {
    return (
      <Fragment>
        {names[0]}, {names[1]}, &amp;&nbsp;
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} title={names.slice(2).join(' | ')}>
          {names.length - 2} others
        </span>
      </Fragment>
    );
  }
};
