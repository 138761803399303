import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import { getTopUpcomingEventsSortedByFollow } from '../../store/selectors/upcomingSeries';
import { Carousel, CarouselContainer, MainSectionTitle, withLoadingCarousel } from '../common';
import LoadingCarousel from './LoadingCarousel';
import TrendingCard from './TrendingCard';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.featuredSection.primary,
    borderTop: `2px solid ${theme.palette.border.quartary}`,
    borderBottom: `2px solid ${theme.palette.border.quartary}`,
    padding: '0 24px',
  },
  carouselContainer: {
    marginTop: 24,
    marginBottom: 48,
  },
  card: {
    margin: '12px 24px', // Centered in carousel with space for shadows.
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      padding: '0 58px',
    },
    card: {
      margin: '12px auto', // Centered for mobile.
    },
    carouselContainer: {
      maxWidth: 720,
    },
  },
});

const breakpoints = [
  {
    breakpoint: 1279,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
    },
  },
  {
    breakpoint: 960,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 760,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

const TrendingSection = React.forwardRef(({ classes, dark, events }, ref) =>
  !events || !events.length ? null : (
    <div
      className={classnames({
        [classes.root]: true,
        dark,
      })}
    >
      <MainSectionTitle title="Featured Events" />
      <CarouselContainer className={classes.carouselContainer}>
        <Carousel
          settings={{ breakpoints, slides: 4 }}
          ref={ref}
          track={{ source: 'featured-event-carousel', event: 'Featured Event Carousel Arrow Clicked' }}
        >
          {events.map(event => (
            <TrendingCard className={classes.card} key={event.id} event={event} />
          ))}
        </Carousel>
      </CarouselContainer>
    </div>
  )
);

export default compose(
  withStyles(styles),
  connect(
    state => ({
      events: getTopUpcomingEventsSortedByFollow(state),
    }),
    {},
    null,
    { forwardRef: true }
  ),
  withLoadingCarousel(LoadingCarousel)
)(TrendingSection);
