import React, { useEffect, useMemo, useState } from 'react';
import handleViewport from 'react-in-viewport';
import { usePagination } from 'use-pagination-firestore';

const getBlock = ({ fetch }) => ({ inViewport, forwardedRef }) => {
  const [hasTriggered, setHasTriggered] = useState(false);

  useEffect(() => {
    if (inViewport === true && hasTriggered === false) {
      setHasTriggered(true);
      fetch();
    }
  }, [hasTriggered, inViewport, setHasTriggered]);

  return <div ref={forwardedRef} />;
};

/**
 * Hook that returns a fetched set of query results and a component that will fetch more when rendered.
 */
const useInfiniteScroll = ({ query, limit = 10 }) => {
  const { items, getNext } = usePagination(query, {
    limit,
  });

  // Only return a new LoadingBlock when items change, getNext was triggering re-renders.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const LoadNextBlock = useMemo(() => handleViewport(getBlock({ fetch: getNext }), null, { disconnectOnLeave: true }), [
    items,
  ]);

  return { items, LoadNextBlock };
};

export default useInfiniteScroll;
