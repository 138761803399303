import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import FollowIconButton from './FollowIconButton';
import FollowMultiDialog from '../FollowMultiDialog';
import { useFollowAction, useFollowing, useFollowingRelated } from '../../../../hooks';

const useStyles = makeStyles(theme => ({
  followButton: {
    background: 'transparent',
    color: theme.palette.border.tertiary,
    fontSize: '20px', // Eyeballed to match design size
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
    '&.active': {
      color: theme.magentaPink,
      background: 'transparent',
    },
  },
}));

const ButtonBase = ({ className, ...props }) => {
  const classes = useStyles();
  return <FollowIconButton className={`${classes.followButton} ${className}`} {...props} />;
};

const FollowingButton = ({ entity, entityField, setIsDialogOpen, ...props }) => {
  const following = useFollowing(entityField, entity);
  const actionHandler = useFollowAction(following, entityField, entity);
  return <ButtonBase actionHandler={actionHandler} following={following} {...props} />;
};

const FollowingMultiButton = ({ entity, entityField, setIsDialogOpen, ...props }) => {
  const following = useFollowingRelated(entityField, entity);
  const actionHandler = useCallback(() => setIsDialogOpen(true), [setIsDialogOpen]);
  return <ButtonBase actionHandler={actionHandler} following={following} {...props} />;
};

const FollowIconMinButton = ({ className, entityField, entity, multiple, relatedEntityName, ...props }) => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const Button = multiple ? FollowingMultiButton : FollowingButton;

  return (
    <>
      <Button
        className={`${classes.followButton} ${className}`}
        entity={entity}
        entityField={entityField}
        setIsDialogOpen={setIsDialogOpen}
        {...props}
      />
      {multiple && isDialogOpen && (
        <FollowMultiDialog
          entity={entity}
          relatedEntityName={relatedEntityName}
          entityField={entityField}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
        />
      )}
    </>
  );
};

export default FollowIconMinButton;
