import React from 'react';
import { connect } from 'react-redux';
import { db } from '../../store/sagas/firebase';
import { trackEvent } from '../../store/actions/uiStates';
import { openLoginDialog } from '../../store/actions/dialogs';
import { isSignedIn, getUser } from '../../store/selectors/user';
import { LOGIN_SOURCES } from '../../store/reducers/user';
import { useFirestoreSubscribe } from '../../hooks';
import { makeStyles } from '@material-ui/core/styles';

import TeamPageLink from '../common/TeamPageLink';

// const isPickTimeEnded = prompt => !!prompt.answer || (!!prompt.end && prompt.end.toDate() < new Date());

const useStyles = makeStyles(theme => ({
  participantText: {
    color: 'black',
  },
}));

const createContestUserEntry = ({ prompt, choice, choiceObject, otherObject, user }) => {
  const id = `${prompt.id}:${user.uid}`;
  return db
    .collection('contestUserEntries')
    .doc(id)
    .set({ id, prompt, choice, choiceObject, otherObject, isResultSeen: false, uid: user.uid });
};

const getWinnerOrLoser = (reference, object) => {
  if (object) {
    if (reference.type === 'winner') {
      return object.choiceObject;
    }
    if (reference.type === 'loser') {
      return object.otherObject;
    }
  }
  return {};
};

const BracketPickButton = ({
  prompt,
  isSignedIn,
  user,
  openLoginDialog,
  entry,
  name,
  uuid,
  index,
  previous,
  participants,
  otherPrevious,
  slug,
  participantType,
}) => {
  const classes = useStyles();
  const { answer } = prompt;
  const NBSP = '\u00A0';
  const source = 'bracket-pick-button';

  const [previousEntries] = useFirestoreSubscribe('contestUserEntries', [
    ['prompt.seriesId', '==', (previous && previous.seriesId) || 0],
    ['uid', '==', isSignedIn ? user.uid : null],
    ['prompt.type', '==', 'bracketology'],
    // putting the conditional here instead of at the `entries` assignment level, because react hooks can't be called conditionally
  ]);

  const [otherPreviousEntries] = useFirestoreSubscribe('contestUserEntries', [
    ['prompt.seriesId', '==', (otherPrevious && otherPrevious.seriesId) || 0],
    ['uid', '==', isSignedIn ? user.uid : null],
    ['prompt.type', '==', 'bracketology'],
    // putting the conditional here instead of at the `entries` assignment level, because react hooks can't be called conditionally
  ]);

  let usingPreviousPick = false;
  let previousEntry = previousEntries[0];
  let otherPreviousEntry = otherPreviousEntries[0];

  let choiceObject = { choice: uuid, name };

  let otherObject = {};
  const otherTeam = participants.find(p => p.uuid !== uuid);
  if (otherTeam) {
    otherObject = { choice: otherTeam.uuid, name: otherTeam.name };
  }

  //Get Previous Team Objects if the game hasn't started yet.
  if (name === 'TBD') {
    //todo: this is where we will start the logic for displaying the previous pick so we can continue picking
    if (!!previous) {
      usingPreviousPick = true;
      choiceObject = getWinnerOrLoser(previous, previousEntry);
      otherObject = getWinnerOrLoser(otherPrevious, otherPreviousEntry);
    } else {
      choiceObject.name = NBSP;
    }
  }

  if (!!answer || !!entry) {
    //|| isPickTimeEnded(prompt)) {
    const score = entry && entry.scoring ? ` [+${entry.scoring.score}]` : '';
    if (!!entry && (entry.choice === answer || !answer) && entry.choice === uuid) {
      return (
        <strong>
          <TeamPageLink
            className={classes.participantText}
            slug={slug}
            participantType={participantType}
            track={{ source }}
          >
            {choiceObject.name}
          </TeamPageLink>
          {score}
        </strong>
      );
    } else if (!!entry && entry.choice !== answer && entry.choice === uuid) {
      return (
        <strike>
          <TeamPageLink
            className={classes.participantText}
            slug={slug}
            participantType={participantType}
            track={{ source }}
          >
            {choiceObject.name}
          </TeamPageLink>
          {score}
        </strike>
      );
    } else {
      return (
        <span>
          <TeamPageLink
            className={classes.participantText}
            slug={slug}
            participantType={participantType}
            track={{ source }}
          >
            {choiceObject.name}
          </TeamPageLink>
        </span>
      );
    }
  }

  if (usingPreviousPick && !choiceObject.name) {
    //don't allow picks before either picked game
    return <></>;
  }

  const getUserEntryData = user => ({
    prompt,
    choice: choiceObject.choice,
    choiceObject,
    otherObject,
    user,
  });

  return (
    <span>
      <button
        onClick={() => {
          if (!isSignedIn) {
            openLoginDialog({
              source: LOGIN_SOURCES.CONTESTS,
              onLoginSuccess: loggedInUser => {
                // using `loggedInUser` instead of `user` because when `onLoginSuccess` gets triggered, this component's user prop hasn't necessarily updated yet.
                createContestUserEntry(getUserEntryData(loggedInUser));
              },
            });
          } else {
            createContestUserEntry(getUserEntryData(user));
          }

          trackEvent('Contest Selection Made Bracket', {
            isSignedIn,
            promptId: prompt.id,
            selectedOption: choiceObject.choice,
          });
        }}
      >
        Pick
      </button>
      <TeamPageLink
        className={classes.participantText}
        slug={slug}
        participantType={participantType}
        track={{ source }}
      >
        {choiceObject.name}
      </TeamPageLink>
    </span>
  );
};

export default connect(
  state => ({
    user: getUser(state),
    isSignedIn: isSignedIn(state),
  }),
  { openLoginDialog }
)(BracketPickButton);
