import React from 'react';
import queryString from 'query-string';

import { TrackLink } from './Track';

const ensureToObj = to => (typeof to === 'string' || to instanceof String ? { pathname: to } : to);

// NOTE: use arrayFormat here to enforce array type for single item arrays.
export const qsParse = string => queryString.parse(string, { arrayFormat: 'bracket' });
export const qsStringify = obj => queryString.stringify(obj, { arrayFormat: 'bracket' });
export const locationToString = location =>
  `${location.pathname}${location.search}${location.hash ? `#${location.hash}` : ''}`;

/**
 * Takes a query object and merges it into existing location `search` field.
 * @param {*} to location-like `to` object.
 * @param {*} query object containing `search` query url parameters.
 */
export const mergeQueryToLocation = (to, query) => ({
  ...ensureToObj(to),
  search: qsStringify({ ...qsParse(ensureToObj(to).search), ...(query || {}) }),
});

/**
 * HOC around Link to stringify the `to.query` object.
 */
export const QueryLink = ({ to, ...props }) => (
  <TrackLink {...props} to={mergeQueryToLocation(to, ensureToObj(to).query)} />
);

export default QueryLink;
