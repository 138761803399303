import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { useSelector } from 'react-redux';
import { MdShare } from 'react-icons/md';

import ItemSharingDialog from '../CalendarTable/ItemSharingDialog';
import { TrackIconButton } from '../common';
import { isSignedIn } from '../../store/selectors/user';

const useStyles = makeStyles(theme => ({
  button: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 16,
    },
  },
}));

const ShareButton = ({ event, isTeamScreen, isOrganizationScreen, source }) => {
  const isUserSignedIn = useSelector(isSignedIn);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <TrackIconButton
        className={classes.button}
        variant="square-button"
        track={{
          event: 'Share Event Clicked',
          eventProperties: { isSignedIn: isUserSignedIn, eventId: event.id },
          source,
        }}
        onClick={() => {
          setIsShareDialogOpen(!isShareDialogOpen);
        }}
        Icon={MdShare}
      />

      {isShareDialogOpen && (
        <ItemSharingDialog
          event={event}
          isEventScreen
          isOrganizationScreen={isOrganizationScreen}
          isTeamScreen={isTeamScreen}
          isSignedIn={isUserSignedIn}
          onCloseClick={() => setIsShareDialogOpen(false)}
        />
      )}
    </>
  );
};

export default ShareButton;
