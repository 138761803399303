import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { TrackLink, TrackSpan } from '../../common';
import DarkmodeSwitchTab from './DarkModeSwitchTab';
import { signOut } from '../../../store/actions/user';
import { getDisplayName, isSignedIn } from '../../../store/selectors/user';
import FollowModeSwitch from '../../common/FollowModeSwitch';
import { isFeatureEnabled } from '../../../utils/featureFlags';

const useStyles = makeStyles(theme => ({
  navHeader: {
    padding: 12,
    color: 'grey',
    fontSize: '0.85em',
    fontWeight: 500,
  },
  authButtons: {
    display: 'flex',
  },
  button: {
    border: '1px solid lightgrey',
    width: '100%',
    margin: 12,
    whiteSpace: 'nowrap',
    padding: '7px 17px',
    borderRadius: '4px',
    fontSize: '0.8125rem',
    fontWeight: 'bold',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&.pink': {
      borderColor: theme.magentaPink,
      background: theme.magentaPink,
      color: 'white',
    },
  },
  navItem: {
    position: 'relative',
    display: 'block',
    fontSize: '0.75em',
    padding: 12,
    fontWeight: 500,
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: theme.palette.text.primary,
    '&.hideOnTablet': {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  },
  darkModeButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      marginRight: 10,
    },
  },
}));

const UserSection = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const displayName = useSelector(getDisplayName);
  const isUserSignedIn = useSelector(isSignedIn);

  if (!isUserSignedIn) {
    return (
      <>
        <div className={classes.authButtons}>
          <TrackLink
            className={classes.button}
            to="/login"
            track={{
              event: 'Navbar Login Clicked',
              source: 'side-nav',
              target: 'login',
            }}
          >
            LOG IN
          </TrackLink>
          <TrackLink
            className={`${classes.button} pink`}
            to="/login"
            track={{
              event: 'Navbar Signup Clicked',
              source: 'side-nav',
              target: 'login',
            }}
          >
            SIGN UP
          </TrackLink>
        </div>
        <TrackLink
          to="/calendar"
          className={`${classes.navItem} hideOnTablet`}
          title="JUKED"
          track={{
            event: 'Navbar Calendar Clicked',
            source: 'side-nav',
            target: 'calendar-page',
          }}
        >
          Calendar
        </TrackLink>
        {/* This Links the user to the news page */}
        {isFeatureEnabled('articlesPage') && (
          <TrackLink
            to={{
              pathname: '/news',
            }}
            className={`${classes.navItem} hideOnTablet`}
            track={{
              event: 'Navbar News Clicked',
              source: 'side-nav',
              target: 'News-page',
            }}
          >
            News
          </TrackLink>
        )}

        <TrackLink
          to="/vods"
          className={`${classes.navItem} hideOnTablet`}
          track={{
            event: 'Navbar VODS Clicked',
            source: 'side-nav',
            target: 'vods-page',
          }}
        >
          VODs
        </TrackLink>
        <DarkmodeSwitchTab className={`${classes.navItem} ${classes.darkModeButton}`} />
        <FollowModeSwitch variant="large" />
      </>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.navHeader}>{displayName}</div>
      <TrackLink
        to="/calendar"
        className={`${classes.navItem} hideOnTablet`}
        title="JUKED"
        track={{
          event: 'Navbar Calendar Clicked',
          source: 'side-nav',
          target: 'calendar-page',
        }}
      >
        Calendar
      </TrackLink>
      {/* This Links the user to the news page */}
      {isFeatureEnabled('articlesPage') && (
        <TrackLink
          to={{
            pathname: '/news',
          }}
          className={`${classes.navItem} hideOnTablet`}
          track={{
            event: 'Navbar News Clicked',
            source: 'side-nav',
            target: 'News-page',
          }}
        >
          News
        </TrackLink>
      )}
      <TrackLink
        to="/vods"
        className={`${classes.navItem} hideOnTablet`}
        track={{
          event: 'Navbar VODS Clicked',
          source: 'side-nav',
          target: 'vods-page',
        }}
      >
        VODs
      </TrackLink>
      <TrackLink
        className={classes.navItem}
        to="/profile"
        track={{
          event: 'Navbar Profile Clicked',
          source: 'side-nav',
          target: 'profile-page',
        }}
      >
        Profile
      </TrackLink>
      <TrackSpan
        className={classes.navItem}
        onClick={() => dispatch(signOut())}
        track={{
          event: 'Navbar Sign Out Clicked',
          source: 'side-nav',
          target: 'signout',
        }}
      >
        Sign Out
      </TrackSpan>
      <DarkmodeSwitchTab className={`${classes.navItem} ${classes.darkModeButton}`} />
      <FollowModeSwitch variant="large" />
    </div>
  );
};

export default UserSection;
