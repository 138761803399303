import { Bracket, EventTournament, EventCompetition, Game, Series, Standings, Stream } from '../types';
import {
  GAME_DATA_ADDED,
  GAME_DATA_CHANGED,
  GAME_DATA_REMOVED,
  GAME_DATA_INITIALIZED,
  GameDataPayload,
  GameDataActionTypes,
  GameDataModelActionTypes,
  GameDataModelsActionTypes,
  gameDataTypes,
} from '../actions/gameData';

export interface GameDataState {
  games: Record<string, Game>;
  streams: Record<string, Stream>;
  brackets: Record<string, Bracket>;
  standings: Record<string, Standings>;
  allSeries: Record<string, Series>;
  liveSeries: Record<string, Series>;
  recentVodSeries: Record<string, Series>;
  upcomingSeries: Record<string, Series>;
  featuredEvents: Record<string, EventTournament | EventCompetition>;
  isLoaded: { [state: string]: boolean };
}

export const initialState: GameDataState = {
  games: {},
  streams: {},
  brackets: {},
  standings: {},
  allSeries: {},
  liveSeries: {},
  recentVodSeries: {},
  upcomingSeries: {},
  featuredEvents: {},
  isLoaded: {
    allSeries: true,
    brackets: true, // loading deferred to later.
    standings: true, // loading deferred to later.
    all: false,
  },
};

const checkObjType = (action: GameDataModelsActionTypes): boolean => {
  if (!action.objType || !Object.keys(gameDataTypes).includes(action.objType)) {
    console.error(`Error: action ${action.type} should contain {objType: [${Object.keys(gameDataTypes).join('|')}]}`);
    return false;
  }
  return true;
};

const checkId = (action: GameDataModelActionTypes): boolean => {
  if (!action.payload.id) {
    console.error(`Error: The |payload| object for action ${action.type} should contain |id|`);
    return false;
  }
  return true;
};

export default (state = initialState, action: GameDataActionTypes): GameDataState => {
  switch (action.type) {
    case GAME_DATA_INITIALIZED: {
      if (!checkObjType(action)) return state;

      const isLoaded = {
        ...state.isLoaded,
        [action.objType]: true,
      };

      // Verifies if all collections have loaded
      if (Object.keys(isLoaded).length > Object.keys(gameDataTypes).length) {
        isLoaded.all = true;
      }

      // Normalize initial batch of data
      const normalized = action.payload.reduce((acc: Record<string, GameDataPayload>, obj: GameDataPayload) => {
        acc[obj.id] = obj;
        return acc;
      }, {});
      return {
        ...state,
        [action.objType]: {
          ...state[action.objType],
          ...normalized,
        },
        isLoaded,
      };
    }
    case GAME_DATA_ADDED:
      if (!checkObjType(action) || !checkId(action)) return state;

      return {
        ...state,
        [action.objType]: {
          ...state[action.objType],
          [action.payload.id]: action.payload,
        },
      };
    case GAME_DATA_CHANGED:
      if (!checkObjType(action) || !checkId(action)) return state;

      return {
        ...state,
        [action.objType]: {
          ...state[action.objType],
          [action.payload.id]: {
            ...state[action.objType][action.payload.id],
            ...action.payload,
          },
        },
      };
    case GAME_DATA_REMOVED: {
      if (!checkObjType(action) || !checkId(action)) return state;

      const data = { ...state[action.objType] };
      delete data[action.payload.id];

      return {
        ...state,
        [action.objType]: data,
      };
    }
    default:
      return state;
  }
};
