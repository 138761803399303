import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaMoon } from 'react-icons/fa';

import DarkModeSwitch from '../../common/DarkModeSwitch';

import { isFeatureEnabled } from '../../../utils/featureFlags';
import { setDarkMode } from '../../../store/actions/uiStates';

const DarkModeSwitchTab = ({ className }) => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector(state => state.uiStates.isDarkMode);

  return (
    isFeatureEnabled('darkMode') && (
      <span className={className}>
        <FaMoon /> Dark Mode
        <span>
          <DarkModeSwitch
            checked={isDarkMode}
            onChange={e => dispatch(setDarkMode(e.target.checked))}
            variant="large"
          />
        </span>
      </span>
    )
  );
};

export default DarkModeSwitchTab;
