import { put, takeEvery, select } from 'redux-saga/effects';

import { setStreamCardVolume, setEventPageVolume } from '../actions/video';
import {
  STORE_STREAM_CARD_VOLUME,
  STORE_EVENT_PAGE_VOLUME,
  LOAD_STREAM_CARD_VOLUME,
  LOAD_EVENT_PAGE_VOLUME,
} from '../actions/localData';
import { PLATFORM_TYPES } from '../reducers/video';

const STREAM_CARD_VOLUME_KEY = 'streamCardVolume';
const EVENT_PAGE_VOLUME_KEY = 'eventPageVolume';

function* storeStreamCardPreferedVolume(action) {
  const {
    video: { playerStates },
  } = yield select();
  const { id, platformType } = action;
  const volume = parseFloat((action.volume / 100).toFixed(1));
  const { player } = playerStates[id];

  player.setVolume(platformType === PLATFORM_TYPES.TWITCH ? volume : action.volume);
  window.localStorage.setItem(STREAM_CARD_VOLUME_KEY, volume);
}

function* storeEventPagePreferedVolume(action) {
  const volume = action.volume;
  window.localStorage.setItem(EVENT_PAGE_VOLUME_KEY, volume);
  yield put(setEventPageVolume(volume));
}

function* loadStreamCardPreferedVolume() {
  const storedStreamCardVolume = window.localStorage.getItem(STREAM_CARD_VOLUME_KEY);
  if (storedStreamCardVolume) {
    yield put(setStreamCardVolume(storedStreamCardVolume, true /* onInitialLoad */));
  }
}

function* loadEventPagePreferedVolume() {
  const storedEventPageVolume = window.localStorage.getItem(EVENT_PAGE_VOLUME_KEY);
  if (storedEventPageVolume) {
    yield put(setEventPageVolume(storedEventPageVolume));
  } else {
    // This is used to override persisted volume from streamCard to EventPage.
    yield put(setEventPageVolume(0.5));
  }
}

export default function* localDataSagas() {
  yield takeEvery(STORE_STREAM_CARD_VOLUME, storeStreamCardPreferedVolume);
  yield takeEvery(STORE_EVENT_PAGE_VOLUME, storeEventPagePreferedVolume);
  yield takeEvery(LOAD_STREAM_CARD_VOLUME, loadStreamCardPreferedVolume);
  yield takeEvery(LOAD_EVENT_PAGE_VOLUME, loadEventPagePreferedVolume);
}
