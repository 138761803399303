/**
 * Returns an array of chunks by given `chunkSize`.
 * @param {array} array - the array to split into chunks
 * @param {chunkSize} int - the number of array items per chunk.
 */
export default (array, chunkSize) =>
  array.reduce(
    (accu, _, index, arr) => (!(index % chunkSize) ? accu.concat([arr.slice(index, index + chunkSize)]) : accu),
    []
  );
