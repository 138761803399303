import React from 'react';
import Helmet from 'react-helmet';

const WindowTitle = ({ title, description, robots = null, keywords = null }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {robots && <meta name="robots" content={robots} />}
      {keywords && <meta name="keywords" content={keywords} />}
    </Helmet>
  );
};

export default WindowTitle;
