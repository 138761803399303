import { eventChannel } from 'redux-saga';
import { call, put, take, fork, select, takeEvery } from 'redux-saga/effects';

import { db } from './firebase';
import { getSeries } from '../selectors/gameData';

import {
  gameDataAdded,
  gameDataChanged,
  gameDataRemoved,
  gameDataInitialized,
  FETCH_BRACKET,
  FETCH_STANDINGS,
  fetchBracket,
} from '../actions/gameData';

// We want to track which collection had been initialized, meaning they've received their first batch
// of snapshot. We do this because the first batch of snapshot contains a LOT of data, so we want to
// batch-store them into redux instead of one-by-one.
const isInitialized = new Set();

function firestoreEventChannel(collection) {
  let query = db.collection(collection);

  // For homepage upcoming calendar, different user timezone yields different results.
  if (collection === 'processed_upcomingSeries') {
    query = query.where('timezone', 'array-contains', new Date().getTimezoneOffset() / -60);
  }

  return eventChannel(emit => {
    query.onSnapshot(snapshot => {
      const objType = collection.replace('processed_', '');

      if (!isInitialized.has(collection)) {
        isInitialized.add(collection);

        const payload = snapshot.docChanges().map(change => change.doc.data());

        emit({
          changeType: 'initialized',
          objType,
          payload,
        });
      } else {
        snapshot.docChanges().forEach(change => {
          emit({
            changeType: change.type,
            objType,
            payload: change.doc.data(),
          });
        });
      }
    });

    // Return no-op unsubscribe function, we're staying subscribed as long as app is running.
    return () => {};
  });
}

export const changeTypeActionMap = {
  initialized: gameDataInitialized,
  added: gameDataAdded,
  modified: gameDataChanged,
  removed: gameDataRemoved,
};

function* handleCollectionChannel(collection) {
  const channel = firestoreEventChannel(collection);

  while (true) {
    const change = yield take(channel);
    yield put(changeTypeActionMap[change.changeType](change.objType, change.payload));
  }
}

function* doFetchBracket(action) {
  try {
    const snapshot = yield call(() => db.collection('brackets').doc(`${action.id}`).get());

    if (snapshot.exists) {
      const bracket = snapshot.data();
      yield put(gameDataAdded('brackets', bracket));

      // Also fetch and save all series that has this bracket id as its substage
      let seriesSnapshot = yield call(() =>
        db.collection('processed_allSeries').where('substage', '==', bracket.id).get()
      );

      // NOTE: Without the filter check, this runs an infinite loop in many Event pages
      // TODO: using gameDataInitialized to batch-add. seems to be ok though, since `allSeries` is lazy-loaded anyway?
      const allSeries = yield select(getSeries);
      const data = seriesSnapshot.docs.map(s => s.data()).filter(data => !allSeries[data.id]);
      data.length && (yield put(gameDataInitialized('allSeries', data)));
    } else {
      console.error(`bracket #${action.id} does not exist in db`);
    }
  } catch (err) {
    //TODO: show "try later" error message somewhere
    console.error(err);
  }
}

function* doFetchStandings(action) {
  try {
    const snapshot = yield call(() => db.collection('processed_allStandings').doc(`${action.id}`).get());

    if (snapshot.exists) {
      const standings = snapshot.data();
      yield put(gameDataAdded('standings', standings));

      // Also pull all the brackets within the standing.
      // TODO: This adds a lot of component tree re-renders if there happen to
      //       be a lot of stages/substages. Perhaps we should collect all data
      //       and batch insert into `brackets`.
      for (const stage of standings.stages) {
        for (const substage of stage.substages) {
          if (substage.id && substage.type === 'bracket') {
            yield put(fetchBracket(substage.id));
          }
        }
      }
    }
  } catch (err) {
    //TODO: show "try later" error message somewhere
    console.error(err);
  }
}

export default function* gameDataSagas() {
  const collectionTypes = [
    'games',
    'processed_liveSeries',
    'processed_recentVodSeries',
    'processed_upcomingSeries',
    'processed_featuredEvents',
    // TODO: shouldn't do this. should make each tournament/series specific page spawn a new onSnapshot request with a more specific query.
    // 'processed_allSeries',
  ];

  for (const collection of collectionTypes) {
    yield fork(handleCollectionChannel, collection);
  }

  yield takeEvery(FETCH_BRACKET, doFetchBracket);
  yield takeEvery(FETCH_STANDINGS, doFetchStandings);
}
