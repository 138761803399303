import React from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';

const styles = theme => ({
  container: {
    background: theme.palette.background.primary,
    textAlign: 'justify',
    padding: '24px 300px 15px 300px',
    fontSize: 20,
    color: theme.palette.text.primary,
    '& img': {
      width: '100%',
      maxWidth: 700,
      display: 'block',
      margin: '15px auto 0px auto',
    },
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      padding: 'unset',
      paddingTop: 24,
      paddingBottom: 14,
      '& img': {
        width: 300,
      },
      '& p': {
        margin: 20,
      },
    },
  },
});

const AboutUsScreen = ({ classes, isDarkMode }) => {
  return (
    <div className={classes.container}>
      <img src={`/images/${isDarkMode ? 'Light_logo.png' : 'Dark_logo.png'}`} alt="Juked" />
      <p>
        Esports is bigger than ever, and has grown exponentially every year for the past decade. In fact, esports has
        grown so much that it is now nearly impossible for anyone, including even the most diehard esports fan, to
        follow all of the trending news, events, and broadcasts happening across the industry. Bluntly, staying informed
        and up to date in esports is a constant chore.
      </p>
      <p>This doesn't have to be the case.</p>
      <p>
        Juked's goal is to create a better way to discover, watch, and engage with the best events and content from
        across the global esports community. With Juked, esports fans will never miss a moment from the esports games,
        teams, and players they love. We are building support for every esports game that fans are playing and watching
        today. Our plan is to support 25+ esports titles on Juked, so you should feel at home regardless of which games
        are your favorite, or which scene you hail from.
      </p>
      <p>So, esports fan - welcome to Juked. We do hope you enjoy your stay.</p>
      <p>&mdash; Ben and Chris, Juked Co-Founders</p>
    </div>
  );
};

export default compose(
  withStyles(styles),
  connect(
    state => ({
      isDarkMode: state.uiStates.isDarkMode,
    }),
    {}
  )
)(AboutUsScreen);
