import React, { Component } from 'react';
import OverlayScrollbars from 'overlayscrollbars';
import './customScrollBars.css';

class CustomScrollBars extends Component {
  constructor(props) {
    super(props);
    this.osTargetRef = React.createRef();
  }

  componentDidMount() {
    this.osInstance = OverlayScrollbars(this.osTargetRef.current, this.props.options || {});
  }

  componentWillUnmount() {
    if (this.osInstance && this.osInstance.destroy) this.osInstance.destroy();
  }

  render() {
    return (
      <div {...this.props} ref={this.osTargetRef} className={`${this.props.className} os-custom-theme `}>
        <div>{this.props.children}</div>
      </div>
    );
  }
}

export default CustomScrollBars;
