import { createSelector } from 'reselect';
import { createArrEqSelector, getGameFilters, isDataLoaded, FEATURED_SERIES_TIERS } from './gameData';
import {
  selectorForFilteringGivenSeries,
  groupSeriesByTournaments,
  isUserFollowingAnyGivenEntity,
} from './gameDataUtils';
import { RootState } from '../reducers';
import { Series, Stream } from '../types';
import { getIsFollowMode } from './uiStates';
import { getPreferredEntityIdsMap } from './recentVodSeries';
import { getEntitySortedByFollow } from './followMode';

const END_GRACE_PERIOD_MINUTES = 15;

export const getLiveSeriesRaw = ({ gameData: { liveSeries } }: RootState): Record<string, Series> => liveSeries;

export const getLiveSeriesByTournamentId = ({ gameData: { liveSeries } }: RootState, id: string): Series[] =>
  Object.values(liveSeries)
    .filter(series => series.tournament.id === id)
    .sort((a, b) => b.streams[b.stream].viewerCount - a.streams[a.stream].viewerCount);

export const getLiveSeriesByTournamentSlug = ({ gameData: { liveSeries } }: RootState, slug: string): Series[] =>
  Object.values(liveSeries)
    .filter(series => series.tournament.slug === slug)
    .sort((a, b) => b.streams[b.stream].viewerCount - a.streams[a.stream].viewerCount);

export const getLiveSeriesById = ({ gameData: { liveSeries } }: RootState, id: string): Series | null => liveSeries[id];

export const getLiveSeriesByCompetitionId = ({ gameData: { liveSeries } }: RootState, id: string): Series[] =>
  Object.values(liveSeries)
    .filter(series => series.tournament.competitionId === id)
    .sort((a, b) => {
      const firstSeriesStream: Stream = a.streams[a.stream];
      const secondSeriesStream: Stream = b.streams[b.stream];

      return secondSeriesStream.viewerCount - firstSeriesStream.viewerCount;
    }); // b - a

export const getDuplicationGuardedLiveSeries = createSelector(
  getLiveSeriesRaw,
  getPreferredEntityIdsMap,
  getIsFollowMode,
  (series, preferredEntityIdsMap, isFollowMode) => {
    if (!series) {
      return [];
    }

    const nowSeconds = Math.floor(new Date().getTime() / 1000);
    const streamMap = new Set();

    const liveSeries = Object.values(series).filter(aSeries => {
      if (isFollowMode) {
        return isUserFollowingAnyGivenEntity({
          ...preferredEntityIdsMap,
          game: aSeries.game,
          tournament: aSeries.tournament.id,
          teams: aSeries.teamIds,
          competition: aSeries.tournament.competition,
        });
      } else {
        if (aSeries.end?.seconds + END_GRACE_PERIOD_MINUTES * 60 < nowSeconds) {
          return false;
        }
        // We choose not to prioritize in case of duplicate streams since it is a rare situation for the added processing weight.
        if (streamMap.has(aSeries.stream)) return false;
        streamMap.add(aSeries.stream);
        return true;
      }
    });

    return liveSeries.sort((a, b) => (b.streams[b.stream]?.viewerCount || 0) - (a.streams[a.stream]?.viewerCount || 0));
  }
);

export const getLiveSeries = createArrEqSelector(getDuplicationGuardedLiveSeries, liveSeries => {
  if (!liveSeries) {
    return [];
  }

  return liveSeries.sort((a, b) => {
    // Moves featured event live series to the first positions.
    if (a.tournament?.isFeatured !== b.tournament?.isFeatured) {
      if (a.tournament?.isFeatured) {
        return -1;
      }
      return 1;
    }
    return 0;
  });
});

export const getFeaturedLiveSeriesGroupedbyTournament = createArrEqSelector(
  isDataLoaded,
  getLiveSeries,
  (loaded, series) => {
    if (!loaded) return [];
    const featuredLiveSeries = series.filter(({ tier }) => tier && FEATURED_SERIES_TIERS.includes(tier));
    return groupSeriesByTournaments(featuredLiveSeries);
  }
);

export const getFilteredLiveSeries = createArrEqSelector(
  isDataLoaded,
  getLiveSeries,
  getGameFilters,
  selectorForFilteringGivenSeries()
);

export const getFilteredLiveSeriesSortedByFollow = createArrEqSelector(
  getFilteredLiveSeries,
  getPreferredEntityIdsMap,
  getIsFollowMode,
  getEntitySortedByFollow('series')
);
