import styled, { CSSObject, DefaultTheme } from 'styled-components';
import { color, typography } from 'styled-system';

interface TextProps {
  readonly color?: string;
  readonly variant?: string;
  readonly theme: DefaultTheme;
}

type Variants = {
  [variant: string]: CSSObject;
};

const getVariants = (props: TextProps): Variants => ({
  bold: {
    fontWeight: 'bold',
  },
  large: {
    fontSize: '18px',
    lineHeight: '26px',
  },
  largeBold: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '26px',
  },
  largeLight: {
    color: props.theme.palette.text.secondary,
    fontSize: '18px',
    lineHeight: '26px',
  },
  largeSemiBold: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '26px',
  },
  light: {
    color: props.theme.palette.text.secondary,
  },
  semiBold: {
    fontWeight: 600,
  },
  semiBoldLight: {
    fontWeight: 600,
    color: props.theme.palette.text.secondary,
  },
  small: {
    fontSize: '13px',
    lineHeight: '18px',
  },
  smallLight: {
    color: props.theme.palette.text.secondary,
    fontSize: '13px',
    lineHeight: '18px',
  },
  tinyBold: {
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: '1.25px',
    lineHeight: '12px',
  },
});

const textVariants = (props: TextProps): CSSObject => {
  // const variants = (props.variant && getVariants(props)) || {};
  const variant = (props.variant && getVariants(props)[props.variant]) || {};
  return {
    color: props.color || props.theme.palette.text.primary,
    fontSize: 15,
    fontWeight: 'normal',
    lineHeight: '20px',
    letterSpacing: 0,
    ...variant,
  };
};

export const SpanText = styled.span<TextProps>`
  ${color}
  ${typography}
  ${textVariants}
`;

export const PText = styled.p<TextProps>`
  ${color}
  ${typography}
  ${textVariants}
`;
