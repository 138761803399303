import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import ChatTabButton from './ChatTabButton';
import { FaTwitter } from 'react-icons/fa';
import ChatCollapsedButton from './ChatCollapsedButton';
import { setChatView } from '../../../store/actions/twitter';
import { TWITTER } from '../../../store/reducers/twitter';
import { TYPE_PLATFORM_MAP, TYPE_ICON_MAP } from '../../../store/reducers/video';

const styles = () => ({
  chatButton: {
    zIndex: '5',
  },
  buttonRow: {
    display: 'flex',
    height: 48,
  },
});

class ChatHeader extends Component {
  render() {
    const { stream, classes, displayTweets, isChatCollapsed, setChatView, toggleCollapseChat } = this.props;
    const Icon = TYPE_ICON_MAP[stream.platformType];
    return (
      <div className={classes.buttonRow}>
        <ChatCollapsedButton isChatCollapsed={isChatCollapsed} toggleCollapseChat={toggleCollapseChat} />
        <ChatTabButton
          className={classes.chatButton}
          icon={<Icon />}
          onClick={() => setChatView(TYPE_PLATFORM_MAP[stream.platformType])}
          provider={TYPE_PLATFORM_MAP[stream.platformType]}
          active={!displayTweets}
        />
        <ChatTabButton
          className={classes.chatButton}
          icon={<FaTwitter />}
          onClick={() => setChatView(TWITTER)}
          provider={TWITTER}
          active={displayTweets}
        />
      </div>
    );
  }
}
export default compose(
  withStyles(styles),
  connect(
    state => ({
      displayTweets: state.twitter.displayTweets,
    }),
    { setChatView }
  )
)(ChatHeader);
