import React from 'react';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';

import withTrackClick from './Wrappers/withTrackClick';

const styles = theme => ({
  dotContainer: {
    textAlign: 'center',
    marginTop: 5,
  },
  dot: {
    height: 10,
    width: 10,
    backgroundColor: '#9C9C9C',
    borderRadius: '50%',
    display: 'inline-block',
    margin: 5,
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: '#DFDFDF',
  },
});

const TrackSpan = withTrackClick(props => <span {...props} />);

const PageIndicator = ({ classes, clickedDotPage, currentPage, event, pageCount, source }) => (
  <div className={classes.dotContainer}>
    {pageCount > 1 &&
      Array(pageCount)
        .fill()
        .map((_, index) => {
          return (
            <TrackSpan
              className={classNames({
                [classes.dot]: true,
                [classes.selected]: currentPage === index + 1,
              })}
              key={index}
              onClick={() => clickedDotPage(index)}
              event={event || 'Dot Nav Clicked'}
              source={source}
              target={`page-${index + 1}`}
            />
          );
        })}
  </div>
);

export default withStyles(styles)(PageIndicator);
