import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import AuthScreenStyles from '../../../../screens/AuthScreenStyles';
import { signOut } from '../../../../store/actions/user';
import { LOGIN_SOURCES } from '../../../../store/reducers/user';
import { auth } from '../../../../store/sagas/firebase';
import { getUser, isFetchingUser, stillNeedsEmailVerification } from '../../../../store/selectors/user';
import { isFeatureEnabled } from '../../../../utils/featureFlags';
import LoginBox from '../../LoginBox';
import LoginPreferences from '../../LoginPreferences';

const styles = theme => ({
  button: {
    ...AuthScreenStyles(theme).button,
  },
  modalVerification: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& button': {
      margin: '24px 0 0 0',
    },
    '& h1': {
      fontSize: 20,
      textAlign: 'center',
    },
  },
  modalLoginBox: {
    backgroundColor: 'transparent',
    borderRadius: 'unset',
    boxShadow: 'none',
    '& h1': {
      fontSize: 25,
      textAlign: 'center',
    },
  },
});

class LoginView extends Component {
  state = { isResended: false };

  handleResendVerication = () => {
    auth().currentUser.sendEmailVerification({
      // Set redirect URL after verification to be the origin of the site.
      url: window.location.origin,
    });

    this.setState({ isResended: true });
  };

  // TODO: needs follow-up implementation.
  renderEmailVerificationRequired() {
    const { classes, isModalSource, signOut } = this.props;
    const { isResended } = this.state;

    return (
      <div className={classnames({ [classes.modalVerification]: isModalSource })}>
        <h1>You need to verify your email before you can sign in.</h1>
        <button className={classes.button} onClick={signOut}>
          SIGN IN WITH ANOTHER ACCOUNT
        </button>
        <button className={classes.button} onClick={this.handleResendVerication}>
          RESEND VERIFICATION
        </button>
        {isResended && <h1>Verification email has been sent to your address</h1>}
      </div>
    );
  }

  render() {
    const { classes, isFetchingUser, isModalSource, user, renderOnSuccess, source, needsVerification } = this.props;

    if (isFetchingUser) {
      return <div className="loading-circle" />;
    } else if (!user.isSignedIn) {
      return <LoginBox className={classnames({ [classes.modalLoginBox]: isModalSource })} source={source} />;
    } else if (
      isFeatureEnabled('gamePreferences') &&
      (!user.preferences || !user.preferences.favoriteGamesCompletedAt) &&
      !isModalSource
    ) {
      return <LoginPreferences />;
    } else if (needsVerification) {
      return this.renderEmailVerificationRequired();
    } else {
      if (renderOnSuccess) {
        return renderOnSuccess();
      } else {
        return null;
      }
    }
  }
}

export default compose(
  withStyles(styles),
  connect(
    (state, ownProps) => ({
      isFetchingUser: isFetchingUser(state),
      isModalSource: ownProps.source !== LOGIN_SOURCES.SIGNUP_PAGE,
      user: getUser(state),
      needsVerification: stillNeedsEmailVerification(state),
    }),
    { signOut }
  )
)(LoginView);
