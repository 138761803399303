import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FaUsers, FaUser } from 'react-icons/fa';

const abiosPlaceHolder = 'https://img.abiosgaming.com/other/New-Abios-Place-Holder.png';

const useStyles = makeStyles(theme => ({
  teamPlaceHolder: {
    background: '#9FA0A5!important',
    borderRadius: '50%!important',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    color: 'white!important',
  },
}));

const ParticipantImage = ({ participant, className }) => {
  const classes = useStyles();
  const { alt, image, isPlaceHolder, component } = React.useMemo(() => {
    if (!participant.image || participant.image === abiosPlaceHolder) {
      if (participant.participantType === 'TEAM') {
        return {
          isPlaceHolder: true,
          component: <FaUsers arial-label="Default team image" />,
          alt: '',
          image: '',
        };
      } else {
        return {
          isPlaceHolder: true,
          component: <FaUser aria-label="Default player image" />,
          alt: '',
          image: '',
        };
      }
    }
    return { isPlaceHolder: false, component: null, alt: 'Particpant', image: participant.image };
  }, [participant]);

  return isPlaceHolder ? (
    <div className={`${classes.teamPlaceHolder} ${className}`}>{component}</div>
  ) : (
    <img className={className} src={image} alt={alt} />
  );
};

export default ParticipantImage;
