import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { getStandingsById } from '../../store/selectors/gameData';

import Countdown from '../CalendarTable/Countdown';
import TournamentCalendar from '../CalendarTable/TournamentCalendar';
import { EventLink, EventStandings, EventBreadcrumb, EntityArticleSection } from '../common';
import MatchPageTitle from './MatchPageTitle';

const useStyles = makeStyles(theme => ({
  upcomingContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 40,
    alignItems: 'center',
    color: 'white',
  },
  upcomingBreadcrumb: {
    fontSize: 13,
    marginTop: 4,
    textAlign: 'center',
  },
  countdown: {
    margin: 15,
    fontSize: '2rem',
    color: theme.redPink,
    fontStyle: 'italic',
    fontWeight: 'bold',
  },

  [theme.breakpoints.down('sm')]: {
    upcomingContainer: {
      '& h1': {
        fontSize: 25,
        textAlign: 'center',
      },
    },
  },
}));

const UpcomingMatchLayout = ({ series }) => {
  const classes = useStyles();

  const standings = useSelector(state => getStandingsById(state, series.tournament?.id));
  const competition = series.tournament?.competition;

  return (
    <div className={classes.upcomingContainer}>
      {/* This will set the page title */}
      <MatchPageTitle series={series} />
      <h1>
        <EventLink tournament={series.tournament} aSeries={series} source={'event-match-screen'} />
        {!!competition && (
          <EventBreadcrumb
            className={classes.upcomingBreadcrumb}
            competition={competition}
            tournament={series.tournament}
          />
        )}
      </h1>
      <span>starts in</span>
      <Countdown className={classes.countdown} targetTime={series.start.toDate()} />
      <EventStandings aSeries={series} participants={series.participants} standings={standings} />
      <TournamentCalendar title="Schedule" tournamentId={series.tournament.id} />
      <EntityArticleSection
        className={classes.articleSection}
        title={`${series.tournament.title} News`}
        queryProps={{ eventId: series.tournament.id }}
      />
    </div>
  );
};

export default UpcomingMatchLayout;
