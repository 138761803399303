import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

import { seriesToCompetition } from './competitionUtils';
import { createArrEqSelector, getSeriesByCompetitionId, getSeriesByCompetitionSlug } from './gameData';
import { sortAndFilterStartedSeries, isEnded } from './gameDataUtils';
import { getLiveSeriesByCompetitionId } from './liveSeries';

export const getFutureSeriesByCompetitionId = createCachedSelector(getSeriesByCompetitionId, series =>
  series.filter(s => !!s.start && !isEnded(s))
)({
  keySelector: (_, id) => `${id}`,
  selectorCreator: createArrEqSelector,
});

export const getNextUpcomingSeriesByCompetitionId = createCachedSelector(
  getFutureSeriesByCompetitionId,
  getLiveSeriesByCompetitionId,
  sortAndFilterStartedSeries
)({
  keySelector: (_, id) => `${id}`,
  selectorCreator: createArrEqSelector,
});

export const getCompetitionBySlug = createSelector(getSeriesByCompetitionSlug, series => {
  if (!series || !series.length) {
    return null;
  }

  return seriesToCompetition(series.find(aSeries => !!aSeries.tournament.competition));
});

/**
 * Returns a list of unique tournaments given a competitionId
 */
export const getTournamentsByCompetitionId = createArrEqSelector(getSeriesByCompetitionId, series =>
  Object.values(
    series.reduce((accu, aSeries) => {
      if (!!aSeries.tournament && !accu[aSeries.tournament.id]) {
        accu[aSeries.tournament.id] = aSeries.tournament;
      }
      return accu;
    }, {})
  )
);

export const getSortedTournamentsByCompetitionId = createArrEqSelector(getTournamentsByCompetitionId, tournaments =>
  tournaments.sort((a, b) => a.start.seconds - b.start.seconds)
);
