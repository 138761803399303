import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import ItemPrizepool from '../CalendarTable/ItemPrizepool';
import { BodyText, DateRange } from '../common';
import RelationCard, { TopContent, BottomContent } from '../common/RelationCard';
import TournamentImage from '../Images/TournamentImage';
import { getGameById } from '../../store/selectors/gameData';
import { getTournamentUrl } from '../../utils/urls';

const useStyles = makeStyles(theme => ({
  tournamentCard: {
    margin: 16,
    '& > a': {
      width: 384,
      padding: 16,
    },
  },
  textRow: {
    marginBottom: 4,
  },
  text: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: '20px',
    textTransform: 'uppercase',
    color: theme.palette.text.primary,
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 256,
  },
}));

const TournamentCard = ({ tournament }) => {
  const classes = useStyles();
  const game = useSelector(state => (tournament ? getGameById(state, tournament.game) : {}));

  return (
    <RelationCard
      className={classes.tournamentCard}
      game={game}
      hasGameTag
      ImageComponent={({ className }) => <TournamentImage className={className} tournament={tournament} />}
      track={{
        event: 'Event Tournament Card Clicked',
        eventProperties: {
          event_id: tournament.id,
          event_slug: tournament.slug,
          event_name: tournament.name,
        },
        source: 'event-page',
        target: 'tournament-page',
      }}
      url={getTournamentUrl(tournament)}
    >
      <TopContent>
        <BodyText className={`${classes.title} ${classes.textRow}`} el="div" variant="largeSemiBold">
          {tournament.title}
        </BodyText>
        <DateRange className={classes.text} obj={tournament} />
      </TopContent>
      <BottomContent className={classes.bottomContent}>
        <ItemPrizepool prizepool={tournament.prizepool} />
      </BottomContent>
    </RelationCard>
  );
};

export default TournamentCard;
