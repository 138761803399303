import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import { MdEmail } from 'react-icons/md';

import { TrackButton, TrackA } from '../common';
import { Dialog } from '../Dialogs';
import { parseArticleId } from '../../utils/articles';
import { getCompetitionUrl, getSeriesUrl, getTournamentUrl } from '../../utils/urls';

const styles = theme => ({
  dialog: {
    top: '30%',
    left: '50%',
    transform: 'translateX(-50%, -50%)',
    alignItems: 'center',
    background: theme.palette.background.secondary,
    border: 'none',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '448px',
    padding: '48px',
    boxSizing: 'border-box',
    position: 'fixed',
  },
  text: {
    fontSize: 32,
    fontWeight: 400,
    lineHeight: '40px',
    textAlign: 'center',
    color: theme.palette.text.primary,
    marginTop: 0,
    marginBottom: 24,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  iconColumn: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 8px',
  },
  shareButton: {
    height: 56,
    width: 56,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 6,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  twitter: {
    background: '#31B7E8',
    '& img': {
      width: 42,
    },
  },
  email: {
    background: '#05C7CC',
    '& svg': {
      color: 'white',
      fontSize: 32,
      lineHeight: ' 32px',
      textAlign: 'center',
    },
  },
  buttonText: {
    color: theme.palette.text.primary,
    fontSize: 15,
    letterSpacing: 0,
    lineHeight: '20px',
    textAlign: 'center',
    marginBottom: 24,
  },
  input: {
    boxSizing: 'border-box',
    paddingLeft: 16,
    height: 48,
    width: 248,
    border: `2px solid ${theme.palette.border.primary}`,
    borderRadius: 4,
    color: theme.palette.text.primary,
    fontSize: 15,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    outline: 'none',
    textOverflow: 'ellipsis',
    background: theme.palette.background.octal || theme.palette.background.tertiary,
  },
  button: {
    letterSpacing: 2.5,
    margin: '0 8px',
    minWidth: 96,
    height: 48,
    padding: '8px 10px',
    fontSize: 16,
    fontWeight: 600,
    borderRadius: 4,
    lineHeight: '17px',
    background: theme.magentaPink,
    color: 'white',
    '&:hover': {
      opacity: 0.9,
    },
  },
  '@media (max-width: 700px)': {
    dialog: {
      top: '50%',
      maxWidth: '300px',
      padding: 14,
      width: '90%',
    },
    button: {
      margin: 0,
    },
    input: {
      marginBottom: 10,
    },
    row: {
      '&.bottom': {
        flexDirection: 'column',
      },
    },
  },
  '@media (max-width: 320px)': {
    dialog: {
      width: 272,
    },
  },
});

class ItemSharingDialog extends Component {
  copyInputText = () => {
    const inputRef = document.getElementById('shareInput');
    inputRef.select();
    inputRef.setSelectionRange(0, 99999);
    document.execCommand('copy');
  };

  getOrganizationShareLink = () => {
    const { isOrganizationScreen, event } = this.props;

    let shareLink = `${window.location.origin}`;
    if (isOrganizationScreen) {
      shareLink += '/o/';
    }
    shareLink += `${event.slug}#overview`;

    return shareLink;
  };

  getTeamScreenShareLink = () => {
    const { isTeamScreen, event } = this.props;

    let shareLink = `${window.location.origin}`;
    if (isTeamScreen) {
      shareLink += '/t/';
    }
    shareLink += `${event.slug}#overview`;

    return shareLink;
  };

  getEventShareLink = () => {
    const { series, isEventScreen, event, isMatchView } = this.props;
    const domain = `${window.location.origin}`;

    if (isMatchView) return `${domain}${getSeriesUrl(series)}`;
    if (isEventScreen && event.isCompetition) return `${domain}${getCompetitionUrl(event)}`; // Does isEventScreen matter?
    return `${domain}${getTournamentUrl(event)}`;
  };

  getArticleShareLink = () => {
    const { article } = this.props;
    const { sourceAbbr, sourceId } = parseArticleId(article.id);
    return `${window.location.origin}/news/${sourceAbbr}/${sourceId}`;
  };

  getShareLink = () => {
    const { article, isEventScreen, event, isOrganizationScreen, isTeamScreen } = this.props;
    if (article) {
      return this.getArticleShareLink();
    } else if (!isOrganizationScreen && !isTeamScreen && (isEventScreen || event)) {
      return this.getEventShareLink();
    } else if (isOrganizationScreen) {
      return this.getOrganizationShareLink();
    } else if (isTeamScreen) {
      return this.getTeamScreenShareLink();
    } else {
      throw new Error('ItemSharingDialog currently supports event or article...');
    }
  };

  getTwitterShareLink = () => {
    const { article, series, event, isEventScreen, isOrganizationScreen, isTeamScreen } = this.props;
    const shareLink = `${this.getShareLink()}?utm_medium=tweet&utm_source=shared_link&utm_campaign=twitter_share`;

    if (article) {
      return `https://twitter.com/intent/tweet?text=${escape(
        `Check out ${article.attributes && article.attributes.title} on @JukedGG!`
      )}&url=${escape(shareLink)}`;
    } else if (isEventScreen && !isOrganizationScreen && !isTeamScreen) {
      return `https://twitter.com/intent/tweet?text=${escape(
        `I'm watching ${event.title} on @JukedGG! Join me!`
      )}&url=${escape(shareLink)}`;
    } else if (isOrganizationScreen || isTeamScreen) {
      return `https://twitter.com/intent/tweet?text=${escape(`Check out ${event.name} on @JukedGG! `)}&url=${escape(
        shareLink
      )}`;
    }

    return `https://twitter.com/intent/tweet?text=${escape(
      `I'm going to watch ${event.title} on @JukedGG! Don't miss it on ${format(
        series.start.toDate(),
        'LL/d/yyyy hh:mm a'
      )}!\n`
    )}&url=${escape(shareLink)}`;
  };

  getMailSharingLink = () => {
    const { article, series, isEventScreen, event, isOrganizationScreen, isTeamScreen } = this.props;
    const shareLink = `${this.getShareLink()}?utm_medium=email&utm_source=shared_link&utm_campaign=email_share`;

    if (article) {
      return `mailto:?subject=👀 Check out this article on Juked!&body=I wanted to share an article from Juked.gg (${
        article.attributes && article.attributes.title
      }): ${escape(shareLink)}`;
    } else if (isEventScreen && !isOrganizationScreen && !isTeamScreen) {
      return `mailto:?subject=I'm watching on Juked right now. 👀 Join me!&body=I'm watchings some hype matches from ${
        event.title
      } right now on Juked.gg! 📺 You don't want to miss this! ${escape(shareLink)}`;
    } else if (isOrganizationScreen || isTeamScreen) {
      return `mailto:?subject= Check out ${event.name} on Juked.gg!&body=Check out the ${event.name} ${
        isOrganizationScreen ? 'organization' : 'team'
      } page on Juked.gg for schedules, scores, streams, and more! ${escape(shareLink)}`;
    }

    return `mailto:?subject=I saw this esports event for you. 👀 &body=I’m adding ${event.title} on ${format(
      series.start.toDate(),
      'LL/d/yyyy hh:mm a'
    )} to my reminders and calendar on Juked.gg and thought you might want to as well. ${escape(shareLink)}`;
  };

  getTrackingFn = () => {
    const { article, event, isSignedIn, isMatchView, series } = this.props;
    const itemView = article ? 'Article' : isMatchView ? 'Match' : 'Event';

    const articleTracking = article
      ? {
          article_id: article.id,
          article_type: article.attributes && article.attributes.type,
        }
      : {};

    const eventTracking = event
      ? {
          is_match_view: isMatchView,
          is_competition: event.isCompetition,
          event_id: event.id,
        }
      : {};

    return medium => ({
      event: `${itemView} Shared`,
      eventProperties: {
        is_signed_in: isSignedIn,
        ...eventTracking,
        ...articleTracking,
        medium,
      },
      eventOptions: { contextSeriesId: series && series.id },
    });
  };

  render() {
    const { classes, onCloseClick } = this.props;
    const getTracking = this.getTrackingFn();

    return (
      <Dialog className={classes.dialog} onCloseClick={onCloseClick}>
        <h1 className={classes.text}>Choose how to share</h1>
        <div className={classes.row}>
          <div className={classes.iconColumn}>
            <TrackA
              href={this.getTwitterShareLink()}
              title="Share by Twitter"
              className="twitter-share-button"
              target="_blank"
              rel="noopener noreferrer"
              data-show-count="false"
              track={getTracking('twitter')}
            >
              <div className={`${classes.shareButton} ${classes.twitter}`}>
                <img src={'/icons/twitterLogo.svg'} alt="" />
              </div>
            </TrackA>

            <span className={classes.buttonText}>Twitter</span>
          </div>
          <div className={classes.iconColumn}>
            <TrackA href={this.getMailSharingLink()} title="Share by Email" track={getTracking('email')}>
              <div className={`${classes.shareButton} ${classes.email}`}>
                <MdEmail />
              </div>
            </TrackA>
            <span className={classes.buttonText}>Email</span>
          </div>
        </div>
        <div className={`${classes.row} bottom`}>
          <input readOnly id="shareInput" className={classes.input} type="text" value={this.getShareLink()} />
          <TrackButton className={classes.button} onClick={this.copyInputText} track={getTracking('link')}>
            COPY
          </TrackButton>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ItemSharingDialog);
