import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { MdMoreVert, MdEvent, MdReply } from 'react-icons/md';
import { FaHeart } from 'react-icons/fa';

import ItemCalendarAddingDialog from '../../ItemCalendarAddingDialog';
import ItemSharingDialog from '../../ItemSharingDialog';
import { DropdownSelect, DropdownItem } from '../../../common';
import FollowDropdownButton from './FollowDropdownButton';
import { isSignedIn } from '../../../../store/selectors/user';
import { openLoginDialog } from '../../../../store/actions/dialogs';
import { LOGIN_DIALOG_VIEWS } from '../../../../store/reducers/dialogs';
import { LOGIN_SOURCES } from '../../../../store/reducers/user';
import { FOLLOW_FIELDS } from '../../../../store/actions/user';

const styles = theme => ({
  wrapper: {
    height: '100%',
    '& .root': {
      width: 32,
      height: '100%',
      color: 'white',
      background: theme.palette.background.septenary,
      border: 'none',
      borderRadius: '0 4px 4px 0',
      boxSizing: 'border-box',
    },
    '& .popper': {
      top: 17,
      right: 24,
      width: 172,
      border: `2px solid ${theme.palette.border.primary}`,
      borderRadius: 4,
      boxSizing: 'border-box',
      background: theme.palette.background.primary,
      padding: [[16, 8]],
      transition: 'none',
      overflow: 'hidden',
    },
    '& .dropdown': {
      border: 'none !important',
    },
  },
  openIcon: {
    margin: 'auto',
    fontSize: 32,
  },
  menuItem: {
    padding: '4px 0',
    fontSize: 15,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.text.primary,
    '& span': {
      verticalAlign: 'middle',
    },
  },
  icon: {
    fontSize: 16,
    verticalAlign: 'middle',
    marginRight: 6,
  },
  shareIcon: {
    transform: 'rotateY(180deg)',
  },
});

class ItemActionDropdown extends Component {
  state = { isCalendarAddingModalOpen: false, isShareModalOpen: false };

  render() {
    const { classes, series, open, handleOpen, handleClose, isSignedIn, isMatchView, openLoginDialog } = this.props;
    const { isCalendarAddingModalOpen, isShareModalOpen } = this.state;
    const { images } = series.tournament;

    const getTracking = event => ({
      event,
      eventProperties: { is_match_view: isMatchView, is_signed_in: isSignedIn },
      eventOptions: { contextSeriesId: series.id },
    });

    return (
      <>
        <DropdownSelect
          icon={<MdMoreVert className={classes.openIcon} />}
          className={classes.wrapper}
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          track={getTracking('Schedule Item Menu Opened')}
        >
          <DropdownItem className={classes.menuItem}>
            <FaHeart className={classes.icon} />
            <FollowDropdownButton
              authContext={{ images, name: series.tournament.title }}
              entity={series.tournament}
              entityField={FOLLOW_FIELDS.TOURNAMENT_IDS}
              handleClose={handleClose}
            />
          </DropdownItem>
          <DropdownItem
            className={classes.menuItem}
            onClick={() => {
              if (isSignedIn) {
                this.setState(state => ({ isCalendarAddingModalOpen: !state.modalOpen }));
                handleClose();
              } else {
                openLoginDialog({
                  view: LOGIN_DIALOG_VIEWS.ADD_TO_CALENDAR,
                  source: LOGIN_SOURCES.DIALOG_NEW_FEATURE,
                  onLoginSuccess: () => {
                    // Leave time for user to be fetched.
                    setTimeout(() => {
                      // Opens the CalendarAddingModal after successfully signing in.
                      this.setState({ isCalendarAddingModalOpen: true });
                    }, 1000);
                  },
                });
              }
            }}
            track={getTracking('Add To Calendar Clicked')}
          >
            <MdEvent className={classes.icon} />
            <span>Add to Calendar</span>
          </DropdownItem>
          <DropdownItem
            className={classes.menuItem}
            onClick={() => {
              this.setState(state => ({ isShareModalOpen: !state.modalOpen }));
              handleClose();
            }}
            track={getTracking('Share Schedule Item Clicked')}
          >
            <MdReply className={`${classes.icon} ${classes.shareIcon}`} />
            <span>Share {isMatchView ? 'Match' : 'Event'}</span>
          </DropdownItem>
        </DropdownSelect>
        {isCalendarAddingModalOpen && (
          <ItemCalendarAddingDialog
            series={series}
            onCloseClick={() => this.setState({ isCalendarAddingModalOpen: false })}
          />
        )}
        {isShareModalOpen && (
          <ItemSharingDialog
            series={series}
            // Passing it as a second prop so it can be shared with EventScreen which only pass tournament.
            event={series.tournament}
            isMatchView={isMatchView}
            isSignedIn={isSignedIn}
            onCloseClick={() => this.setState({ isShareModalOpen: false })}
          />
        )}
      </>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    state => ({
      isSignedIn: isSignedIn(state),
    }),
    { openLoginDialog }
  )
)(ItemActionDropdown);
