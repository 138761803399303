import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { addPlayer, removePlayer } from '../../store/actions/video';

const styles = {
  frame: {
    border: 0,
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    zIndex: 1,
  },
};

class MediaPlayer extends Component {
  componentDidMount() {
    // NOTE: seriesId used for heartbeat.
    const { id, channel, platformType, muted, addPlayer, controls, video, startTime, seriesId } = this.props;
    addPlayer(id, channel, platformType, muted, controls, video, startTime, seriesId);
  }

  componentWillUnmount() {
    const { seriesId, removePlayer } = this.props;
    removePlayer(seriesId);
  }

  render() {
    const { classes, id, className /* from parent */ } = this.props;

    return <div id={id} className={`${classes.frame} ${className}`} />;
  }
}

export default compose(withStyles(styles), connect(null, { addPlayer, removePlayer }))(MediaPlayer);
