import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import MultiViewButtonsRow from '../MultiViewButtonsRow';
import { isDataLoaded } from '../../../store/selectors/gameData';
import SingleViewCarousel from './SingleViewCarousel';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingTop: 8,
    paddingBottom: 36,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.background.primary,
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  topRow: {
    maxWidth: 1120,
    width: '100%',
    margin: '0 auto 12px',
  },
  pageContainer: {
    position: 'relative',
    width: '100%',
  },
  noLiveSeries: {
    width: '80%',
  },
  [theme.breakpoints.down('md')]: {
    topRow: {
      maxWidth: 976,
    },
  },
}));

const SingleViewVideoSwatch = ({ series }) => {
  const classes = useStyles();

  const isLoaded = useSelector(state => isDataLoaded(state));

  return (
    <div className={classes.root}>
      <div
        className={classNames({
          [classes.pageContainer]: true,
          [classes.noLiveSeries]: isLoaded && !Boolean(series.length),
        })}
      >
        <MultiViewButtonsRow isHomePageView hasFilters className={classes.topRow} />
        <div className={classes.contentContainer}>
          <SingleViewCarousel series={series} isLoaded={isLoaded} />
        </div>
      </div>
    </div>
  );
};

export default SingleViewVideoSwatch;
