import createCachedSelector from 're-reselect';

import { getSeries, createArrEqSelector } from './gameData';
import { getLiveSeries } from './liveSeries';
import { isEnded } from './gameDataUtils';
import { filterSeriesVods } from './recentVodSeries';

const getParticipantRelatedSeries = (series, teamIds) =>
  series.filter(aSeries => {
    return aSeries.participants.find(participant => teamIds.some(teamId => participant.uuid === `TEAM:${teamId}`));
  });

export const getSeriesByParticipants = createCachedSelector(
  getSeries,
  (_, { teamIds }) => teamIds,
  (allSeries, teamIds = []) => {
    return (allSeries && getParticipantRelatedSeries(Object.values(allSeries), teamIds)) || [];
  }
)((_, teamIds) => `${teamIds}`);

export const getLiveSeriesByParticipants = createCachedSelector(
  getLiveSeries,
  (_, { teamIds }) => teamIds,
  (liveSeries, teamIds = []) => getParticipantRelatedSeries(liveSeries, teamIds)
)((_, teamIds) => `${teamIds}`);

export const getUpcomingSeriesByParticipants = createCachedSelector(getSeriesByParticipants, series =>
  series.filter(s => !!s.start && !isEnded(s))
)({
  keySelector: (_, id) => `${id}`,
  selectorCreator: createArrEqSelector,
});

export const getVodSeriesByParticipants = createCachedSelector(
  getSeriesByParticipants,
  filterSeriesVods
)({
  keySelector: (_, id) => `${id}`,
  selectorCreator: createArrEqSelector,
});
