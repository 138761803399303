export const FETCH_TWEETS_REQUEST = 'FETCH_TWEETS_REQUEST';
export const FETCH_TWEETS_SKIPPED = 'FETCH_TWEETS_SKIPPED';
export const FETCH_TWEETS_SUCCESS = 'FETCH_TWEETS_SUCCESS';
export const FETCH_TWEETS_FAILURE = 'FETCH_TWEETS_FAILURE';
export const POST_TWEET_REQUEST = 'POST_TWEET_REQUEST';
export const POST_TWEET_SUCCESS = 'POST_TWEET_SUCCESS';
export const POST_TWEET_FAILURE = 'POST_TWEET_FAILURE';
export const SET_CHAT_VIEW = 'SET_CHAT_VIEW';
export const TWITTER_AUTH_SUCCESS = 'TWITTER_AUTH_SUCCESS';
export const TWITTER_AUTH_FAILURE = 'TWITTER_AUTH_FAILURE';

export const fetchTweetsRequest = (firstId, lastId) => ({
  type: FETCH_TWEETS_REQUEST,
  firstId,
  lastId,
});

export const fetchTweetsSkipped = () => ({
  type: FETCH_TWEETS_SKIPPED,
});

export const fetchTweetsSuccess = (tweetIds, merge) => ({
  type: FETCH_TWEETS_SUCCESS,
  tweetIds,
  merge,
});

export const fetchTweetsFailure = error => ({
  type: FETCH_TWEETS_FAILURE,
  error,
});

export const postTweetRequest = text => ({
  type: POST_TWEET_REQUEST,
  text,
});

export const postTweetSuccess = tweetId => ({
  type: POST_TWEET_SUCCESS,
  tweetId,
});

export const postTweetFailure = error => ({
  type: POST_TWEET_FAILURE,
  error,
});

export const setChatView = view => ({
  type: SET_CHAT_VIEW,
  view,
});

export const twitterAuthSuccess = () => ({
  type: TWITTER_AUTH_SUCCESS,
});

export const twitterAuthFailure = error => ({
  type: TWITTER_AUTH_FAILURE,
  error,
});
