import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IoMdThumbsUp } from 'react-icons/io';

import { TrackA, LinkButton, TrackButton } from '../../../common';

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'center',
    background: theme.palette.background.secondary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '64px 96px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: 32,
      height: 32,
      marginRight: 8,
      background: theme.lightTeal,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > svg': {
        fill: 'white',
      },
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '32px',
    textAlign: 'center',
    color: theme.palette.text.primary,
    marginBottom: 16,
  },
  subTitle: {
    fontSize: 15,
    lineHeight: '20px',
    color: theme.suvaGray,
    marginBottom: 32,
  },
  button: {
    letterSpacing: 0,
    width: '100%',
    height: 48,
    fontSize: 18,
    fontWeight: 500,
    textDecoration: 'none',
  },
  link: {
    fontSize: 18,
    lineHeight: '24px',
    marginTop: 28,
    color: theme.magentaPink,
  },
  '@media (max-width: 700px)': {
    container: {
      padding: '20px 14px 30px',
    },
  },
}));

const SurveyView = ({ onCloseClick, isSignedIn, view, source }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <div>
          <IoMdThumbsUp />
        </div>
        <h1 className={classes.title}>Thanks for being a Juked user!</h1>
      </div>
      <span className={classes.subTitle}>Want to improve the product? Send us your feedback now.</span>

      <TrackA
        href="https://airtable.com/shrPSpUEs7g3k6doR"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.button}
        track={{
          event: 'Feedback Link Clicked',
          eventProperties: { feature: 'airtable-website', is_signed_in: isSignedIn, view },
          target: 'airtable-website',
          source,
        }}
      >
        <TrackButton className={classes.button} theme={'winePink'}>
          Leave feedback
        </TrackButton>
      </TrackA>
      <LinkButton className={classes.link} onClick={() => onCloseClick()}>
        No, thanks
      </LinkButton>
    </div>
  );
};

export default SurveyView;
