import React, { Component } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';

import getNotificationPermission from '../../utils/getNotificationPermission';
import { Button, IconHeader, SliderSwitch } from '../common';
import TimeZoneSelect from './TimeZoneSelect';
import { MdNotifications } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';

const styles = theme => ({
  container: {
    width: 600,
  },
  section: {
    marginBottom: 24,
    maxWidth: 600,
  },
  subtitle: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: ' 24px',
    marginBottom: 8,
  },
  infoRow: {
    display: 'flex',
    margin: '8px 16px',
    height: 48,
    background: theme.palette.background.quinary,
    borderRadius: 4,
    alignItems: 'center',
  },
  infoValue: {
    fontSize: 18,
    lineHeight: '26px',
    marginLeft: 16,
  },
  infoAction: {
    width: 'max-content',
    flexShrink: 0,
    marginLeft: 'auto',
    marginRight: 16,
    '& > span': {
      cursor: 'pointer',
      textDecoration: 'underline',
      padding: 4,
      fontSize: '0.8125em',
    },
  },
  button: {
    width: 160,
    borderRadius: 4,
    marginTop: 16,
    marginLeft: 16,
    padding: '12px 24px',
    background: theme.magentaPink,
    '&.granted': {
      background: '#08BF3F',
      opacity: 1,
    },
    '& svg': {
      fontSize: 24,
      lineHeight: 24,
      marginLeft: 4,
    },
  },
  errorText: {
    color: 'red',
    marginLeft: 16,
    marginTop: 16,
    textAlign: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      width: '100%',
    },
    iconHeader: {
      '& h2': {
        fontSize: '18px !important',
      },
    },
    infoValue: {
      fontSize: 14,
    },
    section: {
      width: '100%',
    },
  },
});

class UserPreferences extends Component {
  state = { notificationState: getNotificationPermission() };

  renderSliderRow(onChange, checked, label) {
    const { classes } = this.props;

    return (
      <div className={classes.infoRow}>
        <span className={classes.infoValue}>{label}</span>
        <div className={classes.infoAction}>
          <SliderSwitch checked={checked} onChange={onChange} />
        </div>
      </div>
    );
  }

  renderButtonText = () => {
    const { notificationState } = this.state;
    if (!notificationState) {
      return 'UNAVAILABLE';
    }
    if (notificationState === 'granted') {
      return 'SUCCESS';
    }
    if (notificationState === 'default') {
      return 'TURN ON';
    }
    return 'BLOCKED';
  };

  render() {
    const { className, classes } = this.props;
    const { notificationState } = this.state;
    const granted = notificationState === 'granted';

    // Button will request permission if the browser is notification capable and users hasn't answered.
    const disabledNotificationButton = !(notificationState === 'default');

    return (
      <div className={`${classes.container} ${className}`}>
        <IconHeader className={classes.iconHeader} label="Customize Your Notifications" Icon={MdNotifications} />
        <div className={classes.section}>
          <p className={classes.subtitle}>Time Zone</p>
          <TimeZoneSelect />
        </div>
        <div className={classes.section}>
          <p className={classes.subtitle}>Web Browser</p>
          <div className={classes.infoValue}>Click below to turn on browser notifications for events and matches.</div>
          <Button
            className={classnames({ [classes.button]: true, granted })}
            theme="lightPink"
            disabled={!('serviceWorker' in navigator && 'PushManager' in window) || disabledNotificationButton}
            onClick={async () => {
              const result = await Notification.requestPermission();
              this.setState({ notificationState: result });
            }}
          >
            {this.renderButtonText()} {granted ? <FaCheck /> : <MdNotifications />}
          </Button>
          {notificationState === 'denied' && (
            <p className={classes.errorText}>
              Notifications blocked! Please reset notifications permission in the browser.
            </p>
          )}
          {!notificationState && <p className={classes.errorText}>This browser does not support notifications.</p>}
        </div>
      </div>
    );
  }
}

export default compose(withStyles(styles))(UserPreferences);
