import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import CompetitionCalendar from '../components/CalendarTable/CompetitionCalendar';
import { MainSectionTitle, SectionBackground, GameTag, MultiEntityArticleSection } from '../components/common';
import CompetitionTournamentsGrid from '../components/Competitions/CompetitionTournamentsGrid';
import EventDescription from '../components/Events/EventDescription';
import FollowHeader from '../components/ContentHeaders';
import HeaderTab, { LABELS, SLUGS } from '../components/ContentHeaders/HeaderTab';
import VideoSwatch from '../components/VideoSwatch/VideoSwatch';
import VodsCarousel from '../components/Vods/VodsCarousel';
import CompetitionImage from '../components/Images/CompetitionImage';
import WindowTitle from '../components/WindowTitle';
import HeaderIconButtons from '../components/ContentHeaders/HeaderIconButtons';
import ItemPrizepool from '../components/CalendarTable/ItemPrizepool';
import { EventBreadcrumb } from '../components/common/Breadcrumbs';
import ShareButton from '../components/ContentHeaders/HeaderShareButton';

import { FOLLOW_FIELDS } from '../store/actions/user';
import { subscribeToQuery, unsubscribeToQuery } from '../store/actions/gameData';
import { getCompetitionBySlug } from '../store/selectors/competitions';
import { getGamesArrByIds } from '../store/selectors/gameData';
import { getLiveSeriesByCompetitionId } from '../store/selectors/liveSeries';
import { getVodSeriesByCompetitionId } from '../store/selectors/recentVodSeries';

const styles = theme => ({
  root: {
    minHeight: theme.contentHeight,
    backgroundColor: theme.palette.background.primary,
  },
  section: {
    borderBottom: `1px solid ${theme.palette.border.septenary}`,
  },
  sectionPadding: {
    padding: '0 24px',
  },
  header: {
    marginBottom: 24,
  },
  tournaments: {
    paddingBottom: 56,
  },
  videoSwatchContainer: {
    backgroundColor: 'black',
  },
  vodsSection: {
    background: theme.palette.background.soloDenary,
    padding: '0 24px',
  },
  container: {
    maxWidth: 'calc(100vw - 48px)',
    width: '100%',
    paddingTop: 5,
    margin: 'auto',
    '@media (min-width: 1024px)': {
      maxWidth: 1232,
    },
  },
  vodsContainer: {
    maxWidth: 1280,
    width: '100%',
    margin: 'auto',
    padding: '5px 0',
  },
  gameName: {
    marginLeft: 32,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  prizepool: {
    marginLeft: 24,
    color: 'white',
    fontSize: 15,
    fontWeight: 600,
    lineHeight: '20px',
  },
});

class CompetitionScreen extends Component {
  state = { hasArticles: false };
  getQueryParams(competitionSlug) {
    return [
      'allSeries',
      ['tournament.competition.slug', '==', competitionSlug || this.props.match.params.competitionSlug],
    ];
  }

  componentDidMount() {
    const { subscribeToQuery } = this.props;
    subscribeToQuery(...this.getQueryParams());
  }

  componentWillUnmount() {
    this.props.unsubscribeToQuery(...this.getQueryParams());
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { competitionId },
      },
      subscribeToQuery,
      unsubscribeToQuery,
    } = this.props;

    const {
      match: {
        params: { competitionId: prevCompetitionId },
      },
    } = prevProps;

    if (prevCompetitionId !== competitionId) {
      unsubscribeToQuery(...this.getQueryParams(prevCompetitionId));
      subscribeToQuery(...this.getQueryParams());
    }
  }

  render() {
    const { classes, competition, endedSeries, liveSeries, location, games } = this.props;
    const { hasArticles } = this.state;

    const competitionName = competition?.title;

    const { title, description, keywords, robots } = competition?.metaTags || {};

    if (!competition) {
      // Note some components require competition below.
      return <div className={classes.root}></div>; // TODO: render loading state
    }

    const hasVods = Boolean(endedSeries?.length);

    if (!location.hash) {
      // If no hash added, redirect to same URL with OVERVIEW hash.
      console.error('[CompetitionScreen] No hash in URL, redirecting... ');
      return <Redirect to={{ ...location, hash: `#${SLUGS[LABELS.OVERVIEW]}` }} />;
    }

    const gameNames = games.map(obj => obj.name).join(' | ');
    const source = 'competition-page';

    return (
      <div className={classes.root}>
        {gameNames && (
          <WindowTitle
            title={title || `${competitionName} | Watch all streams here | Juked`}
            description={
              description ||
              `Follow the ${competitionName} live streams, schedules, brackets, and more on Juked | ${gameNames}`
            }
            keywords={keywords}
            robots={robots}
          />
        )}
        <FollowHeader
          title={competition.title}
          className={classes.section}
          entity={competition}
          entityField={FOLLOW_FIELDS.TOURNAMENT_IDS}
          followButtonProps={{
            multiple: true,
            relatedEntityName: 'tournaments',
          }}
          ImageComponent={<CompetitionImage competition={competition} />}
          HeaderIconButtons={<HeaderIconButtons links={{ tournamentUrl: competition.websiteUrl }} source={source} />}
          ShareComponent={<ShareButton event={competition} source={source} />}
          Prizepool={
            <ItemPrizepool
              withDollarSignIcon
              prizepool={competition.prizepool}
              className={classes.prizepool}
              withTrophyIcon
            />
          }
          GameTags={games.map(game => (
            <GameTag key={game.id} className={classes.gameName} game={game} />
          ))}
          Breadcrumb={<EventBreadcrumb className={classes.breadcrumb} tournament={competition.fromTournament} />}
          banners={competition.banners}
        >
          <HeaderTab label={LABELS.OVERVIEW} location={location} />
          <HeaderTab label={LABELS.TOURNAMENTS} location={location} />
          <HeaderTab label={LABELS.SCHEDULE} location={location} />
          {hasArticles && <HeaderTab label={LABELS.NEWS} location={location} />}
          {hasVods && <HeaderTab label={LABELS.VODS} location={location} />}
        </FollowHeader>
        {(!liveSeries || !liveSeries.length) && competition.description && (
          <EventDescription label="What is this event?" event={competition} />
        )}

        {/** Stream section */}
        {!!liveSeries && !!liveSeries.length && (
          <div className={`${classes.section} ${classes.sectionPadding} ${classes.videoSwatchContainer}`}>
            <VideoSwatch liveSeriesArr={liveSeries} />
          </div>
        )}

        {/** Tournaments section */}
        <span id={SLUGS[LABELS.TOURNAMENTS]} />
        <SectionBackground className={`${classes.section} ${classes.sectionPadding}`}>
          <div className={`${classes.container} ${classes.tournaments}`}>
            <MainSectionTitle className={classes.header} title="Tournaments" />
            <CompetitionTournamentsGrid competition={competition} />
          </div>
        </SectionBackground>

        {/** VODs section */}
        <span id={SLUGS[LABELS.VODS]} />
        {hasVods && (
          <div className={classes.vodsSection}>
            <VodsCarousel
              className={classes.vodsContainer}
              title="Recent Top Matches"
              endedSeries={endedSeries}
              page="competition-page"
              tournamentTitle={competition.title}
            />
          </div>
        )}

        {/** Schedule section */}
        <div className={classes.sectionPadding}>
          <span id={SLUGS[LABELS.SCHEDULE]} />
          <CompetitionCalendar competitionId={competition.id} />
        </div>

        {/**News Section */}
        <span id={SLUGS[LABELS.NEWS]} />
        {Boolean(competition.tournamentIds?.length) && (
          <div className={`${classes.section} ${classes.sectionPadding}`}>
            <MultiEntityArticleSection
              title={`${competition.title} News`}
              onFetchArticles={articles => {
                if (Boolean(articles?.length) !== hasArticles) {
                  this.setState({ hasArticles: Boolean(articles?.length) });
                }
              }}
              queryProps={{
                contextName: 'eventIds',
                contextIds: competition.tournamentIds,
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  connect(
    (state, ownProps) => ({
      // Uses the competition object nested in the first series item, so we don't have to fetch the tournament separately.
      // TODO: this wouldn't work if the competition has no series associated with it, but if that's the case, should the competition page even exist?
      competition: getCompetitionBySlug(state, ownProps.match.params.competitionSlug),
      state,
    }),
    { subscribeToQuery, unsubscribeToQuery },
    (stateProps, dispatchProps, ownProps) =>
      !!stateProps.competition
        ? {
            ...stateProps,
            ...dispatchProps,
            ...ownProps,
            endedSeries: getVodSeriesByCompetitionId(stateProps.state, stateProps.competition.id),
            // Need a list of all live series to cross-check if the specified series is considerd live.
            liveSeries: getLiveSeriesByCompetitionId(stateProps.state, stateProps.competition.id),
            //This will bring the game names
            games: getGamesArrByIds(stateProps.state, stateProps.competition.gameIds),
          }
        : { ...stateProps, ...dispatchProps, ...ownProps }
  ),
  withStyles(styles)
)(CompetitionScreen);
