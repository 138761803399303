import { format } from 'date-fns';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ArticleBase from './ArticleBase';
import ArticleHeroImage from './ArticleHeroImage';
import ArticleMarkdown from './ArticleMarkdown';
import ArticleShareButton from './ArticleShareButton';
import { GameBadges, H1, H5 } from '../common';

const useStyles = makeStyles(theme => ({
  h1: {
    color: theme.palette.text.primary,
    margin: 0,
  },
  subTitleRow: {
    display: 'flex',
    margin: '8px 0 0 0',
  },
  h5: {
    margin: 0,
  },
  spacer: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    margin: '0 16px',
  },
  date: {
    color: theme.palette.text.secondary,
  },
  iconRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '16px 0 0 -6px',
  },
  heroImg: {
    margin: '24px -23px 0 -23px',
  },
  [theme.breakpoints.down('sm')]: {
    heroImg: {
      margin: '24px 0 0 0',
    },
  },
}));

export default ({ article }) => {
  const classes = useStyles();
  const { author, title, date } = article.attributes || {};
  const source = 'article';

  return (
    <ArticleBase>
      <H1 className={classes.h1}>{title}</H1>
      <div className={classes.subTitleRow}>
        <H5 className={classes.h5}>{`By ${author || 'Juked'}`}</H5>
        <div className={classes.spacer}>·</div>
        <div className={classes.date}>{format(date.toDate(), 'PP')}</div>
      </div>
      <div className={classes.iconRow}>
        <ArticleShareButton article={article} track={{ source }} />
        <GameBadges animated elevation={2} gameIds={article.gameIds} track={{ source }} />
      </div>
      <ArticleHeroImage article={article} className={classes.heroImg} />
      <ArticleMarkdown article={article} />
    </ArticleBase>
  );
};
