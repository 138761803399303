import React from 'react';
import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';

import TournamentImage from '../Images/TournamentImage';
import ParticipantImage from '../Images/ParticipantImage';

const row = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
};

const styles = theme => ({
  tournamentRow: {
    ...row,
    marginBottom: 8,
    '.withoutParticipants &': {
      marginBottom: 0,
    },
  },
  tournamentImage: {
    width: 16,
    height: 16,
    '.withoutParticipants &': {
      width: 24,
      height: 24,
    },
  },
  tournamentName: {
    marginLeft: 8,
    color: theme.darkGrey,
    fontSize: 12,
    lineHeight: '16px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '.withoutParticipants &': {
      fontSize: 14,
      fontWeight: 600,
    },
  },
  itemCard: {
    ...row,
    marginTop: 12,
    '& .participantImage': {
      width: 20,
      height: 20,
      fontSize: 12,
    },
    '& .participant': {
      marginLeft: 8,
      color: theme.palette.text.primary,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '16px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  leftWideContainer: {
    width: '68%',
    '& :last-child': {
      marginBottom: 0,
    },
    '.allView &': {
      width: '90%',
    },
  },
  participantRow: {
    ...row,
    marginBottom: 6,
    '.withoutParticipants &': {
      display: 'none',
    },
  },
  seriesTitle: {
    width: '32%',
    color: theme.palette.text.primary,
    fontSize: 10,
    fontWeight: 500,
    letterSpacing: '0.2px',
    lineHeight: '16px',
    textAlign: 'right',
    '.allView &': {
      display: 'none',
    },
  },
});

const MobileItemCard = ({ classes, series: { tournament, participants }, series, game, allView }) => {
  const withoutParticipants =
    allView ||
    !participants.length ||
    !participants.every(participant => !!participant.name && participant.name !== 'TBD');

  return (
    <>
      <div className={classnames({ [classes.itemCard]: true, withoutParticipants, allView })}>
        <div className={classes.leftWideContainer}>
          <div className={classes.tournamentRow}>
            <TournamentImage className={classes.tournamentImage} tournament={tournament} />
            <div className={classes.tournamentName}>{tournament.title}</div>
          </div>
          {participants.map((participant, idx) => (
            <div key={idx} className={classes.participantRow}>
              <ParticipantImage className="participantImage" participant={participant} />
              <div className="participant">{participant.name}</div>
            </div>
          ))}
        </div>

        <div className={classes.seriesTitle}>{series.title}</div>
      </div>
    </>
  );
};

export default withStyles(styles)(MobileItemCard);
