import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { BodyText } from '../../common';
import SliderInput from './SliderInput';

const useStyles = makeStyles(theme => ({
  notificationRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 12,
    paddingLeft: 16,
  },
}));

const EmailNotificationRow = ({ disabled, className, title, subtext, checked, onChange }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.notificationRow} ${className}`}>
      <div>
        <BodyText el="div" variant="smallSemiBold" color={disabled ? 'grey' : null}>
          {title}
        </BodyText>
        {subtext && (
          <BodyText variant="subtext" color={disabled ? 'grey' : null}>
            {subtext}
          </BodyText>
        )}
      </div>
      <SliderInput checked={checked} onChange={onChange} disabled={disabled} />
    </div>
  );
};

export default EmailNotificationRow;
