// Toggle In Array
export default (array, newItem) => {
  array = array.slice();
  const index = array.indexOf(newItem);

  if (index === -1) {
    array.push(newItem);
  } else {
    array.splice(index, 1);
  }

  return array;
};
