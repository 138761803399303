import React, { Component } from 'react';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft, MdPlayArrow } from 'react-icons/md';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { compose } from 'recompose';
import classnames from 'classnames';
import { addYears, format, isWithinInterval } from 'date-fns';

import CalendarTable from '../components/CalendarTable/Table';
import JumpLinks from '../components/CalendarTable/JumpLinks';
import CalendarFilters from '../components/CalendarTable/Filters';
import { withCalendarUrlChange } from '../components/CalendarTable/wrappers';
import WindowTitle from '../components/WindowTitle';
import withTrackClick from '../components/Wrappers/withTrackClick';
import { getCalendarLiveSeriesByViewMode, getCalendarFilteredUpcomingSeries } from '../store/selectors/calendar';
import { increaseWeek, decreaseWeek } from '../store/actions/calendar';
import { subscribeToQuery, unsubscribeToQuery } from '../store/actions/gameData';
import { CALENDAR_LINK_FILTERS } from '../store/reducers/uiStates';
import { mergeQueryToLocation } from '../components/common/QueryLink';
import { getPreferredGameIds } from '../store/selectors/user';

const styles = theme => ({
  root: {
    background: theme.palette.background.senary,
    boxSizing: 'border-box',
    minHeight: theme.contentHeight,
  },
  container: {
    color: 'black',
    margin: 'auto',
    maxWidth: 1280,
    minWidth: 960, // theme `sm` breakpoint
  },
  jumpSection: {
    height: 60,
    display: 'none',
    borderBottom: '2px solid #e6e6e6',
    margin: '0 auto 30px',
    maxWidth: 1232,
    '.showWeek &': {
      display: 'flex',
    },
    '.showAll &': {
      display: 'flex',
    },
  },
  weekChange: {
    display: 'none',
    lineHeight: '25px',
    color: theme.palette.text.primary,
    fontSize: 34,
    '.showWeek &': {
      display: 'flex',
    },
  },
  arrowIcon: {
    cursor: 'pointer',
    color: theme.palette.text.primary,
    fontSize: 26,
    marginLeft: 8,
    '&.left': {
      '.disabled &': {
        color: 'lightgray',
        pointerEvents: 'none',
      },
      marginLeft: 0,
      marginRight: 8,
    },
  },
  mobileArrowIcon: {
    display: 'none',
  },
  month: {
    fontWeight: 700,
    paddingRight: 10,
  },
  emptyText: {
    margin: '0 20px',
    color: theme.palette.text.primary,
  },
  [theme.breakpoints.down('md')]: {
    jumpSection: {
      padding: '0 24px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      minWidth: 'unset',
    },
    weekChange: {
      fontSize: 26,
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginBottom: 8,
    },
    arrowIcon: {
      display: 'none',
    },
    mobileArrowIcon: {
      display: 'block',
      lineHeight: '40px',
      fontSize: 40,
      cursor: 'pointer',
      color: '#181818',
      marginLeft: 8,
      '&.left': {
        '.disabled &': {
          color: 'lightgray',
          pointerEvents: 'none',
        },
        transform: 'rotate(180deg)',
        marginLeft: 0,
        marginRight: 8,
      },
    },
    jumpSection: {
      margin: '0 20px 30px',
      '.showAll &': {
        display: 'none',
      },
    },
    emptyText: {
      textAlign: 'center',
    },
  },
  // iPhone SE classes
  '@media (max-width: 350px)': {
    mobileArrowIcon: {
      fontSize: 32,
    },
    weekChange: {
      fontSize: 22,
    },
  },

  scheduleContainer: {
    padding: '0 24px',
    [theme.breakpoints.between(768, 1024)]: {
      padding: 0,
    },
  },
});

const LeftMobileArrow = withTrackClick(MdPlayArrow);
const RightMobileArrow = withTrackClick(MdPlayArrow);
const LeftArrow = withTrackClick(MdKeyboardArrowLeft);
const RightArrow = withTrackClick(MdKeyboardArrowRight);

class CalendarScreen extends Component {
  // Pass props as args because caller might pass current props or prev props.
  getQueryParams(props) {
    return [
      'allSeries',
      [
        ['start', '>=', props.startDate],
        ['start', '<=', props.endDate],
      ],
    ];
  }

  componentDidMount() {
    const {
      subscribeToQuery,
      history,
      location,
      selectedGames,
      calendarFilter,
      calendarView,
      preferredGameIds,
      isFollowMode,
    } = this.props;
    subscribeToQuery(...this.getQueryParams(this.props));

    const filters = ['ALL', 'FEATURED'];

    history.push(
      mergeQueryToLocation(location, {
        games:
          isFollowMode && Boolean(preferredGameIds.length) // TODO: Use hooks/useFilterByParameter when we make this functional component
            ? preferredGameIds
            : Boolean(selectedGames.length)
            ? selectedGames
            : null,
        sort: filters.includes(calendarFilter) ? calendarFilter : 'ALL',
        tab: !!calendarView ? calendarView : null,
      })
    );
  }

  componentWillUnmount() {
    const { unsubscribeToQuery } = this.props;
    unsubscribeToQuery(...this.getQueryParams(this.props));
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate, calendarView, subscribeToQuery, unsubscribeToQuery } = this.props;
    const { startDate: prevStartDate, endDate: prevEndDate, calendarView: prevCalendarView } = prevProps;

    if (
      startDate.toDateString() !== prevStartDate.toDateString() ||
      endDate.toDateString() !== prevEndDate.toDateString() ||
      calendarView !== prevCalendarView
    ) {
      unsubscribeToQuery(...this.getQueryParams(prevProps));
      subscribeToQuery(...this.getQueryParams(this.props));
    }
  }

  render() {
    const {
      classes,
      liveSeries,
      seriesRange,
      startDate,
      endDate,
      increaseWeek,
      decreaseWeek,
      calendarView,
      searchInput,
      location,
    } = this.props;
    const startMonth = format(startDate, 'MMM');
    const endMonth = format(endDate, 'MMM');

    const showDay = calendarView === CALENDAR_LINK_FILTERS.DAY && !searchInput;
    const showWeek = calendarView === CALENDAR_LINK_FILTERS.WEEK && !searchInput;
    const showAll = calendarView === CALENDAR_LINK_FILTERS.ALL && !searchInput;

    const getWeekTracking = right => ({
      event: `Calendar ${right ? 'Next' : 'Previous'} Week Clicked`,
      source: 'calendar-page',
      target: right ? 'next-week' : 'previous-week',
    });

    return (
      <div
        className={classnames({
          [classes.root]: true,
          showWeek,
          showDay,
          showAll,
        })}
      >
        <WindowTitle
          title="Esports Calendar - Esports Schedules | Juked"
          description="Find every league, tournament, schedule, and match from the top esports on the Juked esports events calendar | LoL, CS:GO, OW, VALORANT, and more."
        />
        <CalendarFilters />
        <div
          className={classnames({
            [classes.container]: true,
            disabled: startDate <= new Date(),
          })}
        >
          <div className={classes.jumpSection}>
            <div className={classes.weekChange}>
              <LeftMobileArrow
                className={`${classes.mobileArrowIcon} left `}
                onClick={decreaseWeek}
                track={getWeekTracking(false)}
              />
              <LeftArrow
                className={`${classes.arrowIcon} left `}
                onClick={decreaseWeek}
                track={getWeekTracking(false)}
              />
              <span className={classes.month}>{startMonth}</span>
              <span>
                {format(startDate, 'do')} - {startMonth !== endMonth ? `  ${endMonth} ` : ` `} {format(endDate, 'do')}
              </span>
              <RightArrow className={classes.arrowIcon} onClick={increaseWeek} track={getWeekTracking(true)} />
              <RightMobileArrow
                className={classes.mobileArrowIcon}
                onClick={increaseWeek}
                track={getWeekTracking(true)}
              />
            </div>
            <JumpLinks
              location={location}
              seriesRange={seriesRange}
              calendarView={calendarView}
              searchInput={searchInput}
            />
          </div>
          {/* the `key` is to force React to re-mount a new CalendarTable so the loading animation works correctly. */}
          <div className={classes.scheduleContainer}>
            <CalendarTable
              key={startDate.toDateString()}
              liveSeries={liveSeries}
              seriesRange={seriesRange}
              isMatchView={false}
              calendarView={calendarView}
              searchInput={searchInput}
              hideLive={showAll || !isWithinInterval(new Date(), { start: startDate, end: endDate })}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withCalendarUrlChange,
  connect(
    (state, { searchInput }) => ({
      liveSeries: getCalendarLiveSeriesByViewMode(state, { isMatchView: false, searchInput }),
      seriesRange: getCalendarFilteredUpcomingSeries(state, { searchInput }),
      startDate: state.calendar.startDate,
      endDate: !!searchInput ? addYears(new Date(), 999) : state.calendar.endDate,
      calendarView: state.calendar.calendarView,
      selectedGames: state.uiStates.calendarSelectedGames,
      calendarFilter: state.uiStates.calendarFilter,
      preferredGameIds: getPreferredGameIds(state),
      isFollowMode: state.uiStates.isFollowMode,
    }),
    { increaseWeek, decreaseWeek, subscribeToQuery, unsubscribeToQuery }
  )
)(CalendarScreen);
