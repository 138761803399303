import React from 'react';
import styled from 'styled-components';

import { GameBadge } from '../core';
import { Game } from '../../store/types';

interface TickerGameProps {
  game: Game;
}

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 48px;
`;

const TickerGame = ({ game }: TickerGameProps): JSX.Element => {
  const { circle, rectangle, square } = game?.images || {};
  return (
    <Container>
      <GameBadge animated game={game} imgSrc={circle || square || rectangle} size="20px" />
    </Container>
  );
};

export default TickerGame;
