import React, { useState } from 'react';
import { MdOndemandVideo } from 'react-icons/md';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { StreamsDropdown } from '../common';

const useStyles = makeStyles(theme => ({
  moreStreamContainer: {
    width: 24,
    height: 24,
    borderRadius: 2,
    background: theme.palette.background.ternidenary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& > svg': {
      fill: theme.palette.text.soloDenary,
      fontSize: 12,
    },
    '&.twoTeams': {
      marginTop: 4,
    },
  },
}));

const StreamsButton = ({ series, twoTeams }) => {
  const classes = useStyles();
  const [isStreamDropdownOpen, setIsStreamDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsStreamDropdownOpen(!isStreamDropdownOpen);
  };

  return (
    <span
      onClick={toggleDropdown}
      className={classnames({
        [classes.moreStreamContainer]: true,
        twoTeams,
      })}
    >
      <MdOndemandVideo />
      <StreamsDropdown series={series} toggleDropdown={toggleDropdown} open={isStreamDropdownOpen} isMinimalView />
    </span>
  );
};

export default StreamsButton;
