import React from 'react';
import { useDispatch } from 'react-redux';

import { FOLLOW_FIELDS, removePreferredEntity } from '../../../store/actions/user';
import { getSlugFromAbbr } from '../../../store/selectors/gameDataUtils';
import { BodyText } from '../../common';
import FollowingItem from './FollowingItem';
import FollowingEntityLink from '../FollowingEntityLink';

const FollowingItemGame = ({ className, game }) => {
  const { abbr, id, name } = game;

  const dispatch = useDispatch();

  return (
    <FollowingItem
      className={className}
      key={id}
      handleDelete={() => dispatch(removePreferredEntity(FOLLOW_FIELDS.GAME_IDS, id))}
    >
      <FollowingEntityLink
        entity={game}
        entityField={FOLLOW_FIELDS.GAME_IDS}
        to={abbr ? `/${getSlugFromAbbr(abbr)}` : null}
      >
        <BodyText variant="smallSemiBold">{name}</BodyText>
      </FollowingEntityLink>
    </FollowingItem>
  );
};

export default FollowingItemGame;
