import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';

import { darkTheme, lightTheme } from '../../theme';

const withThemeProvider = theme => WrappedComponent => props => {
  const isDarkMode = useSelector(state => state.uiStates.isDarkMode);
  return (
    <ThemeProvider theme={theme || isDarkMode ? darkTheme : lightTheme}>
      <WrappedComponent {...props} />
    </ThemeProvider>
  );
};

export default withThemeProvider;
