import React from 'react';

import { HelmetSocials } from '../common';

export default ({ article }) => {
  const { date, excerpt, hero_media_url: heroMediaUrl, title } = article?.attributes || {};
  const { title: customTitle, description: customDescription, keywords, robots } = article?.metaTags || {};

  return (
    <HelmetSocials
      articlePublishedTime={date && date.toDate().toISOString()}
      description={customDescription || excerpt}
      image={heroMediaUrl}
      imageWidth="686"
      imageHeight="457"
      title={customTitle || title}
      twitterCard="summary_large_image"
      type="article"
      keywords={keywords}
      robots={robots}
    />
  );
};
