import classNames from 'classnames';
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import { addStaticBackgroundContainer, removeStaticBackgroundContainer } from '../../store/actions/uiStates';

const useStyles = makeStyles(theme => ({
  overlay: {
    background: 'rgba(0,0,0,0.6)',
    height: 0,
    opacity: 0,
    position: 'absolute',
    '-webkit-transition': 'opacity 0.8s ease-in-out',
    '-moz-transition': 'opacity 0.8s ease-in-out',
    transition: 'opacity 0.8s ease-in-out',
    width: 0,
    zIndex: 1000,
  },
  overlayActive: {
    height: ({ height }) => (height ? `${height}px` : '100%'),
    opacity: 1,
    width: '100%',
  },
  drawer: {
    backgroundColor: theme.palette.background.primary,
    height: ({ height }) => (height ? `${height}px` : '100%'),
    overflow: 'auto',
    position: 'absolute',
    transform: 'translateX(-110%)',
    '-webkit-transition': 'transform 0.8s ease-in-out',
    '-moz-transition': 'transform 0.8s ease-in-out',
    transition: 'transform 0.8s ease-in-out',
    zIndex: 1001,
  },
  drawerOpen: {
    transform: 'translateX(0%)',
  },
}));

export default React.forwardRef(({ children, className, height, isOpen }, ref) => {
  const classes = useStyles({ height });
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      // Disable scrolling on body.
      dispatch(addStaticBackgroundContainer());
      return () => {
        // Reenable scrolling on body.
        dispatch(removeStaticBackgroundContainer());
      };
    }
  }, [isOpen, dispatch]);

  return (
    <>
      <div
        id="overlay"
        className={classNames({
          [classes.overlay]: true,
          [classes.overlayActive]: isOpen,
        })}
      />
      <div
        ref={ref}
        className={classNames({
          [className]: true,
          [classes.drawer]: true,
          [classes.drawerOpen]: isOpen,
        })}
      >
        {children}
      </div>
    </>
  );
});
