import React from 'react';

import ContentItemContainer from './ContentItemContainer';
import ContentItemLink from './ContentItemLink';
import { FollowIconMinButton } from '../../../common';
import { FOLLOW_FIELDS, ENTITY_TYPES } from '../../../../store/actions/user';
import { getOrganizationUrl } from '../../../../utils/urls';

const OrganizationContentItem = ({ className, organization }) => {
  const { images, name } = organization;
  return (
    <ContentItemContainer className={className}>
      <ContentItemLink
        entity={organization}
        entityType={ENTITY_TYPES.ORGANIZATION}
        name={name}
        to={getOrganizationUrl(organization)}
      />
      {organization.teamIds?.length && (
        <FollowIconMinButton
          authContext={{ images, name }}
          entity={organization}
          entityField={FOLLOW_FIELDS.TEAM_IDS}
          relatedEntityName="teams"
          multiple
        />
      )}
    </ContentItemContainer>
  );
};

export default OrganizationContentItem;
