import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import LineClamp from '../common/LineClamp';
import CardThumbnail from '../ArticlesSection/CardThumbnail';

const useStyles = makeStyles(theme => ({
  frameContainer: {
    overflow: 'hidden',
    position: 'relative',
    height: 206,
    '& > img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  },
  overlay: {
    background: 'linear-gradient(to bottom, transparent 0%, rgba(10,11,11,0.88) 68.46%, rgba(10,11,11,0.88) 100%)',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
  },
  card: {
    textTransform: 'none',
    padding: '16px 16px 16px 16px',
  },
  cardContainer: {
    'font-smoothing': 'antialiased !important', // Smooth scaling.
    '-webkit-transform-style': 'preserve-3d', // Smooth scaling.
    '-webkit-backface-visibility': 'hidden', // Smooth scaling.
    '-moz-backface-visibility': 'hidden', // Smooth scaling.
    '-ms-backface-visibility': 'hidden', // Smooth scaling.
    width: 415,
    height: 295,
    borderRadius: 4,
    background: theme.palette.background.secondary,
    color: theme.palette.text.primary,
    boxShadow: '0px 2px 12px 0 rgba(0,0,0,0.2)',
    overflow: 'hidden',
    position: 'relative',
    transition: 'transform 0.3s, box-shadow 0.3s ease',
    '&:not(:first-child)': {
      marginLeft: 20,
    },
    '&:hover': {
      boxShadow: '0px 4px 12px 0 rgba(0,0,0,0.16)',
      transform: 'translateZ(0) scale(1.06, 1.06)', // Even number works best.
    },
    [theme.breakpoints.down('sm')]: {
      width: 320,
      marginBottom: 15,
      '&:not(:first-child)': {
        marginLeft: 'unset',
      },
    },
  },
  title: {
    position: 'absolute',
    bottom: 0,
    color: 'white',
    lineHeight: '32px',
    fontSize: 25,
    fontWeight: 700,
    padding: 10,
    textAlign: 'left',
  },
  description: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    height: 36,
    overflow: 'hidden',
    lineHeight: '18px',
    fontSize: 13,
    textAlign: 'left',
    marginBottom: 8,
  },
  lineClamp: {
    lineClamp: 2,
  },
  cardTextRow: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
}));

const ArticleCard = ({ article, gameTags, articleLink }) => {
  const classes = useStyles();
  const { attributes, redirectUrlMeta } = article;
  const { title, excerpt } = attributes || {};

  // For 'redirect' articles, redirectUrlMeta is parsed on ingest: fallback to meta description.
  const description = excerpt || (redirectUrlMeta && redirectUrlMeta.description) || '';

  return (
    <div className={classes.cardContainer}>
      {/* Card Link */}
      {articleLink}

      <div className={classes.frameContainer}>
        {/* Card Thumbnail */}
        <CardThumbnail article={article} />

        {/* Thumbnail gradient overlay */}
        <div className={classes.overlay}></div>

        <div className={classes.title}>
          <LineClamp className={classes.lineClamp} text={title} />
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.description}>
          <LineClamp className={classes.lineClamp} text={description} />
        </div>
        <div className={classes.cardTextRow}>
          {/* Game tag */}
          {gameTags}
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
