import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { OrganizationContentItem } from './content-items';
import { usePrevious, useInfiniteScroll, useFirestoreMultiSubscribeQuery } from '../../../hooks';
import chunkArray from '../../../utils/chunkArray';
import { db } from '../../../store/sagas/firebase';
import { getPreferredEntityIds } from '../../../store/selectors/user';

const query = db.collection('processed_organizations').orderBy('name');

const getFilteredOrganizations = (organizations, followedTeamIds, options = {}) => {
  const followedTeamIdsSet = new Set(followedTeamIds);
  const filteredOrgs = organizations.filter(
    organization => !organization.teamIds?.find(teamId => followedTeamIdsSet.has(teamId))
  );
  if (!options.sortByName) {
    return filteredOrgs;
  }
  return filteredOrgs.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};

const getFollowedOrganizationIndex = (organization, followedTeamIds) =>
  followedTeamIds.findIndex(teamId => organization.teamIds.includes(teamId));

const OrganizationsContent = ({ className }) => {
  const followedTeamIds = useSelector(state => getPreferredEntityIds(state, { entityField: 'teamIds' }));
  const queries = useMemo(
    () =>
      chunkArray(followedTeamIds, 10).map(teamIdsChunk =>
        db.collection('processed_organizations').where('teamIds', 'array-contains-any', teamIdsChunk)
      ),
    [followedTeamIds]
  );

  const [entities] = useFirestoreMultiSubscribeQuery({ queries });
  const followedOrganizations = useMemo(
    () =>
      entities
        .flat()
        .sort(
          (a, b) => getFollowedOrganizationIndex(a, followedTeamIds) - getFollowedOrganizationIndex(b, followedTeamIds)
        ),
    [entities, followedTeamIds]
  );

  const [organizations, setOrganizations] = useState([]);

  const { items, LoadNextBlock } = useInfiniteScroll({ limit: 40, query });
  const prevItems = usePrevious(items);
  const prevFollowedOrgs = usePrevious(followedOrganizations);

  useEffect(() => {
    if (items !== prevItems) {
      // When we fetch more items, store them in state.

      if (followedOrganizations.length) {
        // Prevent doing the extra effort if the user isn't following any organization

        const filteredItems = getFilteredOrganizations(items, followedTeamIds);

        setOrganizations(state => [...(!prevItems?.length ? followedOrganizations : state), ...filteredItems]);
      } else {
        setOrganizations(state => [...state, ...items]);
      }
    } else if (followedOrganizations.length !== prevFollowedOrgs.length) {
      setOrganizations(state => [
        ...followedOrganizations,
        ...getFilteredOrganizations(state, followedTeamIds, { sortByName: true }),
      ]);
    }
  }, [items, organizations, prevItems, setOrganizations, followedTeamIds, followedOrganizations, prevFollowedOrgs]);

  const content = organizations.map(organization => (
    <OrganizationContentItem key={organization.id} organization={organization} />
  ));
  const loadingBlockIndex = items.length ? content.length - Math.round(items.length / 2) : 0; // Insert loadingBlock halfway through new items.

  return (
    <div className={className}>
      {content.slice(0, loadingBlockIndex)}
      {loadingBlockIndex > 0 && <LoadNextBlock />}
      {content.slice(loadingBlockIndex)}
    </div>
  );
};

export default OrganizationsContent;
