import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ShimmerSpan, ShimmerDiv } from '../../common/Shimmer';
import VideoCardStyles from '../VideoCardStyles';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  placeholderCard: {
    ...VideoCardStyles.placeholderCard,
    background: theme.palette.shimmer.primary,
  },
  streamCardLoading: {
    marginLeft: 16,
    '& > div': {
      width: 368,
      height: 530,
      borderRadius: 8,
      boxShadow: '0 2px 2px 0 rgba(0,0,0,0.2)',
    },
  },
}));

const LoadingState = () => {
  const classes = useStyles();
  return (
    <div className={classes.loadingContainer}>
      <div className={classes.placeholderCard}>
        <ShimmerDiv />
        <ShimmerSpan />
        <ShimmerSpan />
        <ShimmerSpan />
        <ShimmerSpan />
        <ShimmerSpan />
      </div>
      <div className={classes.streamCardLoading}>
        <ShimmerDiv />
      </div>
    </div>
  );
};

export default LoadingState;
