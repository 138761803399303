import React from 'react';

import FrameView from '../FrameView';

const RebrandDialog = ({ isSignedIn, view, source }) => {
  return (
    <FrameView
      title="WHAT? Juked is evolving."
      frameSrc="https://www.youtube.com/embed/BPSxDfPlrUY?autoplay=1"
      frameTitle="The New Juked"
      buttonText="Learn More"
      redirectUrl="https://juked.gg/"
      track={{
        event: 'Rebrand Announcement Modal Clicked',
        eventProperties: { feature: 'rebrand-announcement', is_signed_in: isSignedIn, view },
        target: 'rebrand-news',
        source,
      }}
    />
  );
};

export default RebrandDialog;
