import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  h1: {
    color: theme.palette.text.primary,
    fontSize: '32px !important',
    fontWeight: '600',
    lineHeight: '40px',
  },
});

const H1 = ({ classes, className, children }) => <h1 className={`${classes.h1} ${className}`}>{children}</h1>;

export default withStyles(styles)(H1);
