import React from 'react';

import EntityBadge from './EntityBadge';
import { getOrganizationUrl } from '../../../utils/urls';

export default ({ animated, className, disabled, elevation = 1, organization, track }) => {
  const { id, images, name, slug } = organization;
  const { icon } = images || {};

  return (
    <EntityBadge
      animated={animated}
      className={className}
      disabled={disabled}
      elevation={elevation}
      src={icon}
      to={getOrganizationUrl(organization)}
      track={{
        event: 'Organization Badge Clicked',
        eventProperties: {
          organization_id: id,
          organization_name: name,
          organization_slug: slug,
        },
        target: 'organization-page',
        ...(track || {}),
      }}
    />
  );
};
