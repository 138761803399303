import React from 'react';

import ContentItem from './ContentItem';
import { ENTITY_TYPES, FOLLOW_FIELDS } from '../../../../store/actions/user';
import { getSlugFromAbbr } from '../../../../store/selectors/gameDataUtils';

const GameContentItem = ({ game }) => {
  const { abbr, images, name } = game;
  return (
    <ContentItem
      context={{ images, name }}
      entity={game}
      entityField={FOLLOW_FIELDS.GAME_IDS}
      entityType={ENTITY_TYPES.GAME}
      name={name}
      to={`/${getSlugFromAbbr(abbr)}`}
    />
  );
};

export default GameContentItem;
