import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import { TrackDiv } from '../common';
import { toggleScheduleView } from '../../store/actions/uiStates';

const absoluteAndFull = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const styles = theme => ({
  switch: {
    position: 'relative',
    width: 220,
    height: 50,
    overflow: 'hidden',
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  checkbox: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0,
    opacity: 0,
    cursor: 'pointer',
    zIndex: 3,

    '&:checked + .knobs': {
      '& span': {
        color: '#718096',
      },
      '&:before': {
        left: 110,
        background: theme.darkPink,
      },
      '&:after': {
        color: 'white',
      },
    },
  },
  knobs: {
    ...absoluteAndFull,
    zIndex: 2,
    '&:before, &:after, & span': {
      position: 'absolute',
      width: 100,
      height: 30,
      fontSize: 16,
      fontWeight: 600,
      textAlign: 'center',
      lineHeight: '30px',
      padding: [[9, 4]],
      transition: '0.3s ease all',
    },
    '&:before': {
      content: '""',
      left: 0,
      backgroundColor: theme.darkPink,
    },
    '&:after': {
      content: '"By Match"',
      right: 4,
      color: '#4e4e4e',
    },
    '& span': {
      display: 'inline-block',
      left: 4,
      color: 'white',
      zIndex: 1,
    },
  },

  layer: {
    ...absoluteAndFull,
    width: '100%',
    backgroundColor: theme.palette.background.primary,
    transition: '0.3s ease all',
    zIndex: 1,
    border: '3px solid #6b0847',
    boxSizing: 'border-box',
  },
});

class SortingSwitch extends Component {
  render() {
    const { classes, value, toggleScheduleView } = this.props;

    return (
      <TrackDiv
        className={classes.switch}
        track={{
          event: 'Schedule View Toggled',
          source: 'calendar-table-switch',
          target: !!value ? 'match-view' : 'event-view',
        }}
      >
        <input
          checked={!!value}
          type="checkbox"
          className={`${classes.checkbox} checkbox`}
          onChange={() => toggleScheduleView(!value)}
        />
        <div className={`${classes.knobs} knobs`}>
          <span>By Event</span>
        </div>
        <div className={classes.layer}></div>
      </TrackDiv>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    state => ({
      value: state.uiStates.calendarIsMatchView,
    }),
    { toggleScheduleView }
  )
)(SortingSwitch);
