import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/styles';
import { Redirect, useParams } from 'react-router-dom';

import { getSeriesById } from '../store/selectors/gameData';
import { getLiveSeriesByTournamentId } from '../store/selectors/liveSeries';
import { subscribeToQuery, unsubscribeToQuery, fetchStandings } from '../store/actions/gameData';

import UpcomingMatchLayout from '../components/Matches/UpcomingMatchLayout';

import { isDataLoaded } from '../store/selectors/gameData';
import { getSeriesUrl, getTournamentUrlFromSlug, getVodBaseUrl } from '../utils/urls';
import LiveMatchLayout from '../components/Matches/LiveMatchLayout';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    minHeight: '100vh', // so footer doesn't show on top
    '& .loading-circle': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    loadingContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& .loading-circle': {
        left: 'unset',
      },
    },
  },
}));

const getQueryParams = tournamentSlug => {
  return ['allSeries', ['tournament.slug', '==', tournamentSlug]];
};

const EventMatchScreen = ({ series, liveSeries, isDataLoaded }) => {
  const dispatch = useDispatch();
  const { tournamentSlug } = useParams();

  useEffect(() => {
    dispatch(subscribeToQuery(...getQueryParams(tournamentSlug)));
    return () => dispatch(unsubscribeToQuery(...getQueryParams(tournamentSlug)));
  }, [tournamentSlug, dispatch]);

  useEffect(() => {
    if (series) {
      dispatch(fetchStandings(series.tournament.id));
    }
  }, [series, dispatch]);

  const classes = useStyles();

  if (!isDataLoaded) {
    return (
      <div className={classes.loadingContainer}>
        <div className="loading-circle" />
      </div>
    );
  }

  // If the data is loaded and there's no series, we will redirect the user to the tournament.
  if (!series) {
    return <Redirect to={getTournamentUrlFromSlug(tournamentSlug)} />;
  }

  const isSeriesLive = Boolean(liveSeries) && liveSeries.some(s => s.id === series.id);

  // If the series starts in the future and it is not a liveSeries, show a countdown.
  const nowSeconds = Math.floor(new Date().getTime() / 1000);
  const startSeconds = series.start ? series.start.seconds : -1;
  if (startSeconds > nowSeconds && !isSeriesLive) {
    return <UpcomingMatchLayout series={series} />;
  }

  if (isSeriesLive) {
    // If the series is live, show it.
    return <LiveMatchLayout series={series} />;
  }

  // If the current series is no longer live,
  // find one that belongs to the same tournament and casted on same stream.
  const nextSeries =
    Boolean(liveSeries) &&
    liveSeries.find(aSeries => aSeries.stream === series.stream && aSeries.tournament.id === series.tournament.id);
  if (nextSeries) {
    // TODO: make this not reload the entire page, we wanna keep the stream playing without flicker.
    return <Redirect to={getSeriesUrl(nextSeries)} />;
  }

  // check if a VOD was already created for it.
  if (Boolean(series.vod)) {
    // If the current series is no longer live,
    // and no other series is live for the same tournament,
    // show the created VOD
    return <Redirect to={getVodBaseUrl(series)} />;
  }
  // Fallback to rendering stats
  // TODO: do we need a better fall-back than rendering stats from a game that's over?
  return <LiveMatchLayout series={series} />;
};

export default compose(
  connect(
    (state, ownProps) => ({
      series: getSeriesById(state, ownProps.match.params.seriesId),
      isDataLoaded: isDataLoaded(state),
      state,
    }),
    {},
    (stateProps, dispatchProps, ownProps) =>
      !!stateProps.series
        ? {
            ...stateProps,
            ...dispatchProps,
            ...ownProps,
            // TODO: We should reconcile the series and the tournament, because they're independently pulled from the URL.
            // What if we get a URL in which the tournament ID refers to a different tournament than the one the series ID is in?

            // need a list of all live series to cross-check if the specified series is considerd live.
            liveSeries: getLiveSeriesByTournamentId(stateProps.state, stateProps.series.tournament.id),
          }
        : { ...stateProps, ...dispatchProps, ...ownProps }
  )
)(EventMatchScreen);
