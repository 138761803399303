import classnames from 'classnames';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const getVariant = (variant, theme) => {
  const base = {
    fontSize: 20,
    color: theme.palette.text.secondary,
    height: 24,
    width: 24,
    border: 'solid 1px transparent',
    borderRadius: 5,
    background: 'initial',
    padding: '3px 5px', // Plus 1px border
    boxShadow: 'unset',
    hoveredOpacity: 1,
    hoveredBorder: `solid 1px ${theme.palette.border.secondary}`,
    labelDisplay: 'initial',
    iconMargin: '0 4px 0 0',
  };

  switch (variant) {
    case 'square-button':
      return {
        height: 32,
        width: 32,
        border: 'none',
        borderRadius: 4,
        background: theme.smokedDarkGrey,
        padding: 0,
        boxShadow: '0 2px 4px 0 rgba(10,11,11,0.24)',
        fontSize: 16,
        color: 'white',
        labelDisplay: 'none',
        iconMargin: 0,
        hoveredBorder: 'none',
        hoveredOpacity: 0.8,
      };
    default:
      return base;
  }
};

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'center',
    border: ({ variant }) => getVariant(variant, theme).border,
    borderRadius: ({ variant }) => getVariant(variant, theme).borderRadius,
    cursor: 'pointer',
    display: 'flex',
    padding: ({ variant }) => getVariant(variant, theme).padding,
    transition: 'border .2s ease',
    boxShadow: ({ variant }) => getVariant(variant, theme).boxShadow,
    background: ({ variant }) => getVariant(variant, theme).background,
    '&:hover': {
      opacity: ({ variant }) => getVariant(variant, theme).hoveredOpacity,
      border: ({ variant }) => getVariant(variant, theme).hoveredBorder,
    },
  },
  icon: {
    fontSize: ({ variant }) => getVariant(variant, theme).fontSize,
  },
  imgContainer: {
    alignItems: 'center',
    color: ({ variant }) => getVariant(variant, theme).color,
    display: 'flex',
    height: ({ variant }) => getVariant(variant, theme).height,
    justifyContent: 'center',
    marginRight: ({ variant }) => getVariant(variant, theme).iconMargin,
    width: ({ variant }) => getVariant(variant, theme).width,
    '&.reverseIcon': {
      transform: 'scaleX(-1)',
    },
  },
  label: {
    display: ({ variant }) => getVariant(variant, theme).labelDisplay,
    color: theme.palette.text.secondary,
    fontFamily: 'Roboto Mono,Roboto,Helvetica,Arial,sans-serif',
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: '2px',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
}));

/**
 * Component to render a minimal button containing an Icon and a label.
 * @prop {class} Icon must be a component class, as imported from react-icons/md.
 * @prop {string} label text to present in header.
 * @prop {string} className (optional) className to tag on outermost element.
 */
export default ({ className, Icon, label, onClick, reverseIcon = false, variant }) => {
  const classes = useStyles({ variant });
  return (
    <div role="button" tabIndex="1" className={`${classes.container} ${className}`} onClick={onClick}>
      <div className={classnames({ [classes.imgContainer]: true, reverseIcon })}>
        <Icon className={classes.icon} />
      </div>
      <div className={classes.label}>{label}</div>
    </div>
  );
};
