import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import VideoFrame from './VideoFrame';
import StreamInformationCard from './StreamInformationCard';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    textAlign: 'left',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}));

const SingleViewVideoContent = ({ series, isActive }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <VideoFrame series={series} isActive={isActive} />
      <StreamInformationCard aSeries={series} />
    </div>
  );
};

export default SingleViewVideoContent;
