import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  h2: {
    fontSize: '24px !important',
    fontWeight: ({ variant }) => (variant === 'semiBold' ? 600 : 'bold'),
    lineHeight: '32px',
    color: theme.palette.text.primary,
  },
}));

const H2 = ({ variant, className, children }) => {
  const classes = useStyles({ variant });

  return <h2 className={`${classes.h2} ${className}`}>{children}</h2>;
};

export default H2;
