import { Component } from 'react';
import { compose } from 'recompose';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { twitterAuthFailure } from '../store/actions/twitter';

/**
 * The callback redirect in a provider OAuth popup -- will close itself and return to main window.
 */
class ProviderAuthScreen extends Component {
  componentDidMount() {
    const { location, provider, twitterAuthFailure } = this.props;

    // Set error or authenticated state.
    const params = queryString.parse(location.search);
    if (params.failed) {
      twitterAuthFailure(new Error(`${provider} authentication failed.`));
    } else if (provider === 'twitter') {
      // Call function set on window in TwitterAuth component.
      window.opener.twitterAuthSuccess();
    }

    // Focus back on parent window and close.
    window.opener.focus();
    window.close();
  }

  render() {
    return null;
  }
}

export default compose(
  connect(
    (state, ownProps) => ({
      provider: ownProps.match.params.provider,
    }),
    { twitterAuthFailure }
  )
)(ProviderAuthScreen);
