import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ArticleBase from './ArticleBase';
import { H1, BodyText, HelmetPrerenderIO } from '../common';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  h1: {
    color: theme.palette.text.primary,
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <ArticleBase className={classes.root}>
      <HelmetPrerenderIO statusCode="404" />
      <H1 className={classes.h1}>Sorry, this amazing article does not yet exist...</H1>
      <BodyText variant="largeLight">Check out one of our recommended articles on the left!</BodyText>
    </ArticleBase>
  );
};
