import React from 'react';

import { Breadcrumb, BreadcrumbSpacer } from '../Breadcrumbs';
import { getOrganizationUrl, getTeamUrl, getGameUrl } from '../../../utils/urls';
import teamNameFormat, { cleanName } from '../../../utils/getFormattedName';

const getTracking = (target, type, team = null) => ({
  event: 'Breadcrumb Link Clicked',
  eventProperties: {
    event_name: team && team.name,
    event_slug: team && team.slug,
    event_id: team && team.id,
    type,
  },
  source: 'breadcrumb',
  target,
});

const TeamBreadcrumb = ({ className, team, game }) => {
  const equalNames = cleanName(team.name) === cleanName(team.organization?.name);
  return (
    <div className={className}>
      <Breadcrumb url={`/`} track={getTracking('home-page', 'root-link')}>
        Juked
      </Breadcrumb>

      <BreadcrumbSpacer />

      {Boolean(team.organization) ? (
        <>
          <Breadcrumb
            url={getOrganizationUrl(team.organization)}
            track={getTracking('team-page', 'organization-link', team)}
          >
            {team.organization.name}
          </Breadcrumb>
          <BreadcrumbSpacer />
          <Breadcrumb
            url={equalNames ? getGameUrl(game) : getTeamUrl(team)}
            track={getTracking('team-page', 'team-link', team)}
          >
            {teamNameFormat(team.name, team.organization.name, game)}
          </Breadcrumb>
        </>
      ) : (
        game && (
          <Breadcrumb url={getGameUrl(game)} track={getTracking('team-page', 'game-link', team)}>
            {game.name}
          </Breadcrumb>
        )
      )}
    </div>
  );
};

export default TeamBreadcrumb;
