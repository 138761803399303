import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';

import TickerGame from './TickerGame';
import TickerSeries from './TickerSeries';
import { getGames, isDataLoaded as getIsDataLoaded } from '../../store/selectors/gameData';
import { getFeaturedLiveSeriesGroupedbyTournament } from '../../store/selectors/liveSeries';
import { getTrimmedUpcomingFeaturedSeries } from '../../store/selectors/upcomingSeries';
import { Series } from '../../store/types';

type SeriesByGame = {
  [gameId: string]: Series[];
};

type SeriesByGameMap = {
  gameMap: SeriesByGame;
  games: string[];
};

const seriesByGameMap = (series: Series[]): SeriesByGameMap =>
  series.reduce(
    ({ games, gameMap }: SeriesByGameMap, aSeries) => {
      const seriesGame = aSeries.game;
      if (seriesGame && !gameMap[seriesGame]) {
        // Game not yet seen, add to games array and initialize gameMap[game].
        return { games: [...games, seriesGame], gameMap: { ...gameMap, [seriesGame]: [aSeries] } };
      }
      // Game already seen, simply add series to gameMap.
      return { games, gameMap: { ...gameMap, [seriesGame]: [...gameMap[seriesGame], aSeries] } };
    },
    { games: [], gameMap: {} }
  );

const Container = styled.div`
  display: flex;
  background-color: ${(props): string => props.theme.palette.background.secondary};
  border-bottom: solid 1px ${(props): string => props.theme.palette.border.secondary};
  height: 60px;
  width: 100%;
  overflow-x: hidden;
`;

const TopTicker = (): JSX.Element => {
  const isDataLoaded = useSelector(getIsDataLoaded);
  const allGames = useSelector(getGames);
  const liveSeries = useSelector(getFeaturedLiveSeriesGroupedbyTournament);
  const upcomingSeries = useSelector(getTrimmedUpcomingFeaturedSeries);

  const { games, gameMap } = useMemo(() => seriesByGameMap([...liveSeries, ...upcomingSeries]), [
    liveSeries,
    upcomingSeries,
  ]);

  return isDataLoaded ? (
    <Container>
      {games.map(gameId => {
        const game = allGames[gameId];
        return (
          <Flex key={gameId}>
            <TickerGame game={game} />
            {gameMap[gameId].map(series => (
              <TickerSeries key={series.id} series={series} />
            ))}
          </Flex>
        );
      })}
    </Container>
  ) : (
    <Container>LOADING</Container>
  );
};

export default TopTicker;
