import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { FollowButton, H1 } from '../common';
import { GameBreadcrumb } from '../common/Breadcrumbs';
import { FOLLOW_FIELDS } from '../../store/actions/user';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.black,
    backgroundImage: ({ bannerUrl }) => `url(${bannerUrl})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: 160,
    width: '100%',
  },
  gradient: {
    height: '100%',
    width: '100%',
    background: 'linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgb(10, 11, 11) 100%)',
  },
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 1280,
    padding: '24px',
  },
  icon: {
    borderRadius: 8,
    height: 112,
    marginRight: 16,
    width: 112,
  },
  nameColumn: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0',
  },
  h1: {
    color: 'white',
    margin: '0 0 8px 0',
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      padding: 15,
    },
    icon: {
      display: 'none',
    },
  },
}));

const GameHeader = ({ game }) => {
  const bannerUrl = game.banners?.desktop;
  const classes = useStyles({ bannerUrl });
  const { images, name } = game;

  return (
    <div className={classes.root}>
      <div className={classes.gradient}>
        <div className={classes.container}>
          <img alt="game-icon" className={classes.icon} src={game.images?.square} />
          <div className={classes.nameColumn}>
            <GameBreadcrumb game={game} />
            <H1 className={classes.h1}>{game.name}</H1>
            <FollowButton authContext={{ images, name }} entity={game} entityField={FOLLOW_FIELDS.GAME_IDS} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameHeader;
