import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TeamPageLink from '../common/TeamPageLink';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    display: 'flex',
    '&.isEventMatchScreen': {
      position: 'unset',
    },
  },
  dropdown: {
    position: 'absolute',
    minWidth: 196,
    borderRadius: '4px',
    border: '0',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
    left: -5,
    top: 10,
    zIndex: '999999',
    margin: '3px 0 0',
    backgroundColor: theme.palette.background.primary,
    [theme.breakpoints.down('sm')]: {
      left: -155,
    },
  },
  dropdownItem: {
    color: theme.palette.text.quinary,
    padding: '8px 12px',
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    lineHeight: '16px',
    margin: 'unset',
    borderRadius: '4px',
    '& > img': {
      width: 16,
      height: 16,
      marginRight: 12,
      filter: 'drop-shadow(0 0 1px white)',
    },
    '&:hover': {
      background: theme.palette.background.tertiary,
    },
  },
}));

const TeamListDropdown = ({ participants, renderTeamsCount }) => {
  const classes = useStyles();
  const jukedLogo = '/images/Juked_logo.png';

  return (
    <div className={classes.root}>
      <div className={classes.dropdown}>
        {participants
          .map((participant, idx) => {
            return (
              <TeamPageLink
                className={classes.dropdownItem}
                key={idx}
                slug={participant.slug}
                participantType={participant && participant.participantType}
              >
                <img src={participant.image || jukedLogo} alt="Team logo" />
                <span key={idx}>{participant.name}</span>
              </TeamPageLink>
            );
          })
          .slice(renderTeamsCount)}
      </div>
    </div>
  );
};

export default TeamListDropdown;
