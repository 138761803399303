import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import { setCalendarFilter } from '../../../store/actions/uiStates';
import { CALENDAR_FILTERS } from '../../../store/reducers/uiStates';
import { isSignedIn } from '../../../store/selectors/user';
import { getPreferredGameIds } from '../../../store/selectors/user';
import { DropdownSelect, DropdownItem } from '../../common';

const styles = theme => ({
  wrapper: {
    marginLeft: 8,
    '& .root': {
      width: 140,
    },
    '& .popper': {
      width: 140,
      zIndex: 2, // To beat notifications button.
      '& .dropdown': {
        maxWidth: '140px',
      },
    },
  },
  menuItem: {
    textAlign: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    wrapper: {
      width: '100%',
      '& .root': {
        width: '100%',
      },
      '& .popper': {
        overflow: 'auto',
        width: '100%',
        '& .dropdown': {
          maxWidth: 'initial',
        },
      },
    },
  },
});

const FILTER_LABELS = {
  [CALENDAR_FILTERS.ALL]: 'All Events',
  [CALENDAR_FILTERS.FAVORITES]: 'Followed',
  [CALENDAR_FILTERS.FEATURED]: 'Featured',
};

class EventTierFilterSelect extends Component {
  state = { open: false };

  componentDidMount() {
    const { setCalendarFilter, isFollowMode, preferredGameIds, onSortChange } = this.props;
    if (isFollowMode) {
      setCalendarFilter(CALENDAR_FILTERS.FEATURED, preferredGameIds);
      onSortChange(CALENDAR_FILTERS.FEATURED);
    }
  }

  componentDidUpdate(prevProps) {
    const { setCalendarFilter, isFollowMode, preferredGameIds, onSortChange } = this.props;

    if (isFollowMode !== prevProps.isFollowMode) {
      setCalendarFilter(CALENDAR_FILTERS.FEATURED, preferredGameIds);
      onSortChange(CALENDAR_FILTERS.FEATURED);
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      classes,
      isSignedIn,
      setCalendarFilter,
      calendarFilter,
      onSortChange,
      isFollowMode,
      preferredGameIds,
    } = this.props;
    const { open } = this.state;

    const getTracking = (event, target = null) => ({
      event,
      source: 'calendar-page',
      eventProperties: { is_signed_in: isSignedIn, from_value: calendarFilter },
      target,
    });

    return (
      <DropdownSelect
        withArrowIcon
        className={classes.wrapper}
        open={open}
        active={calendarFilter !== CALENDAR_FILTERS.ALL}
        placeholder={FILTER_LABELS[calendarFilter]}
        handleOpen={() => this.setState(state => ({ open: !state.open }))}
        handleClose={this.handleClose}
        track={getTracking('Calendar Type Filter Opened')}
      >
        <DropdownItem
          className={classes.menuItem}
          active={calendarFilter === CALENDAR_FILTERS.ALL}
          onClick={() => {
            setCalendarFilter(CALENDAR_FILTERS.ALL, isFollowMode ? preferredGameIds : null);
            onSortChange(CALENDAR_FILTERS.ALL);
            this.handleClose();
          }}
          track={getTracking('Calendar Type Filter Clicked', CALENDAR_FILTERS.ALL)}
        >
          <span>All Events</span>
        </DropdownItem>
        <DropdownItem
          className={classes.menuItem}
          active={calendarFilter === CALENDAR_FILTERS.FEATURED}
          onClick={() => {
            setCalendarFilter(CALENDAR_FILTERS.FEATURED, isFollowMode ? preferredGameIds : null);
            onSortChange(CALENDAR_FILTERS.FEATURED);
            this.handleClose();
          }}
          track={getTracking('Calendar Type Filter Clicked', CALENDAR_FILTERS.FEATURED)}
        >
          <span>Featured</span>
        </DropdownItem>
      </DropdownSelect>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    state => ({
      calendarFilter: state.uiStates.calendarFilter,
      isSignedIn: isSignedIn(state),
      preferredGameIds: getPreferredGameIds(state),
      isFollowMode: state.uiStates.isFollowMode,
    }),
    { setCalendarFilter }
  )
)(EventTierFilterSelect);
