import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';

import { QueryLink } from '../common';
import { Carousel, CarouselContainer, MainSectionTitle, withLoadingCarousel } from '../common';
import LoadingCarousel from './LoadingCarousel';
import VodCard from './VodCard';
import { sortOption } from '../../store/selectors/recentVodSeries';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: `2px solid ${theme.palette.border.primary}`,
  },
  link: {
    color: theme.magenta,
    textDecoration: 'none',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '2.5px',
    marginRight: 20,
    '&:hover,&:focus': {
      textDecoration: 'underline',
    },
  },
});

const VodsCarousel = React.forwardRef(
  (
    {
      endedSeries,
      classes,
      className,
      game,
      maxCount,
      page,
      dark,
      title,
      tournamentTitle,
      filteredName,
      filteredGameIds,
    },
    ref
  ) => {
    if (!endedSeries || !endedSeries.length) {
      return (
        <div
          className={classnames({
            [className]: true,
            [classes.root]: true,
            dark,
          })}
        />
      );
    }

    const totalVodsCount = maxCount ? Math.min(endedSeries.length, maxCount) : endedSeries.length;

    return (
      <div
        className={classnames({
          [className]: true,
          [classes.root]: true,
          dark,
        })}
      >
        <MainSectionTitle title={title} />
        <CarouselContainer>
          <QueryLink
            className={classes.link}
            to={{
              pathname: '/vods',
              query: {
                games: game ? [game.id] : filteredGameIds ? filteredGameIds : [null],
                search: tournamentTitle || filteredName,
                sort: sortOption.FEATURED,
              },
            }}
            track={{
              event: 'VOD View All Clicked',
              eventProperties: {
                page,
                filter_game_id: game && game.id,
                filter_game_abbr: game && game.abbr,
                search: tournamentTitle,
                sort: sortOption.FEATURED,
                carousel_count: totalVodsCount,
              },
              source: 'vods-carousel',
              target: 'vods-page',
            }}
          >
            VIEW ALL VODS
          </QueryLink>
          <Carousel ref={ref} track={{ source: 'vod-carousel', event: 'VOD Carousel Arrow Clicked' }}>
            {endedSeries.slice(0, totalVodsCount).map(series => (
              <VodCard key={series.id} page series={series} />
            ))}
          </Carousel>
        </CarouselContainer>
      </div>
    );
  }
);

export default compose(
  withStyles(styles),
  // This must be inside the connect() HOC to pass the ref correctly.
  withLoadingCarousel(LoadingCarousel)
)(VodsCarousel);
