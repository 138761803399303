import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { MdPerson, MdEmail } from 'react-icons/md';
import { withStyles } from '@material-ui/styles';

import { Button, H1, H2, IconOutlinedInput, LinkButton, PasswordOutlinedInput } from '../common';
import { savePreferencesAccount, setPreferencesError } from '../../store/actions/user';
import { getDisplayName, getPreferencesAccountStatus, getPreferencesErrors, getUser } from '../../store/selectors/user';
import firebase from '../../store/sagas/firebase';

const styles = theme => ({
  accountView: {
    width: 600,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 24,
    maxWidth: 600,
    width: 352,
  },
  h1: {
    fontWeight: 'normal',
  },
  header: {
    margin: '0 0 24px 0',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    flexStart: 'end',
    justifyContent: 'space-between',
  },
  button: {
    minWidth: 96,
  },
  field: {
    marginBottom: 24,
    width: 352,
    background: theme.palette.background.secondary,
    '& .MuiInputBase-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.border.nonary,
      },
    },
  },
  note: {
    color: '#777',
    fontSize: 11,
    marginBottom: 24,
    marginTop: '-12',
  },
  [theme.breakpoints.down('sm')]: {
    accountView: {
      width: '100%',
    },
    section: {
      width: '100%',
    },
    h1: {
      marginTop: 32,
    },
    field: {
      width: '100%',
    },
  },
});

const getInitialState = props => ({
  confirmPassword: '',
  currentPassword: '',
  displayName: props.displayName,
  editPassword: false,
  email: props.user.email,
  newPassword: '',
  passwordMismatch: '',
});

class AccountView extends Component {
  state = getInitialState(this.props);

  //Check if the user provider is Google or Twitter
  isSSOProvider = firebase.auth().currentUser.providerData.every(prov => prov.providerId !== 'password');

  // Used for IconOutlinedInput label and labelWidth.
  passwordLabels = {
    currentPassword: ['Current Password', 140],
    newPassword: ['New Password', 111],
    confirmPassword: ['Confirm Password', 134],
  };

  componentDidUpdate(prevProps) {
    const { status } = this.props;
    if (prevProps.status !== 'saved' && status === 'saved') {
      // If save is successful, reset component state.
      this.setState(getInitialState(this.props));
      this.resetErrors();
    }
  }

  componentWillUnmount() {
    // When component unmounts, reset all errors used in this component.
    this.resetErrors();
  }

  resetErrors() {
    const { setPreferencesError } = this.props;
    setPreferencesError({
      currentPassword: null,
      displayName: null,
      email: null,
      newPassword: null,
    });
  }

  buildSaveData = () => {
    const { displayName, email, newPassword, currentPassword } = this.state;
    const data = { currentPassword };
    if (displayName && displayName !== this.props.displayName) {
      data.displayName = displayName;
    }
    if (email && email !== this.props.user.email) {
      data.email = email;
    }
    if (newPassword) {
      data.newPassword = newPassword;
    }
    if (this.isSSOProvider) {
      data.isSSOProvider = this.isSSOProvider;
    }
    return data;
  };

  handleConfirmPasswordCheck = () => {
    const { confirmPassword, newPassword } = this.state;
    this.setState({
      passwordMismatch: !!(newPassword && newPassword !== confirmPassword) ? 'Passwords must match' : '',
    });
  };

  isSaveDisabled() {
    const { confirmPassword, currentPassword, displayName, email, newPassword } = this.state;

    if (this.isSSOProvider && displayName !== this.props.displayName) return false;

    if (
      !currentPassword || // Must have confirmPassword to make changes.
      (newPassword && newPassword !== confirmPassword) || // When changing passwords, must confirm newPassword.
      (!newPassword && displayName === this.props.displayName && email === this.props.user.email) // No changes to make.
    ) {
      return true;
    }
    return false;
  }

  renderDisplayName() {
    const { classes, errors } = this.props;
    const { displayName } = this.state;

    return (
      <IconOutlinedInput
        className={classes.field}
        errorMsg={errors.displayName}
        icon={<MdPerson />}
        label="Display Name"
        labelWidth={103}
        onChange={e => this.setState({ displayName: e.target.value })}
        value={displayName}
      />
    );
  }

  renderEmail() {
    const { classes, errors } = this.props;
    const { email } = this.state;

    return (
      <IconOutlinedInput
        className={classes.field}
        errorMsg={errors.email}
        icon={<MdEmail />}
        label="Email"
        labelWidth={43}
        onChange={e => this.setState({ email: e.target.value })}
        value={email}
        disabled={this.isSSOProvider}
      />
    );
  }

  renderPasswordField({ field, required, error }) {
    const { classes, errors } = this.props;
    const value = this.state[field];

    return (
      <PasswordOutlinedInput
        className={classes.field}
        errorMsg={errors[field] || (error && error)}
        label={this.passwordLabels[field][0]}
        labelWidth={this.passwordLabels[field][1]}
        onBlur={field === 'confirmPassword' ? this.handleConfirmPasswordCheck : null}
        onChange={e => this.setState({ [field]: e.target.value })}
        required={!!required}
        value={value}
      />
    );
  }

  renderChangePassword() {
    const { editPassword, passwordMismatch } = this.state;

    return (
      editPassword && (
        <>
          {this.renderPasswordField({ field: 'newPassword' })}
          {this.renderPasswordField({ field: 'confirmPassword', error: passwordMismatch })}
        </>
      )
    );
  }

  renderButtons() {
    const { classes, savePreferencesAccount, status } = this.props;
    const { editPassword } = this.state;

    // This is in row-reverse.
    return (
      <div className={classes.buttons}>
        <Button
          disabled={this.isSaveDisabled() || status === 'saving'}
          className={classes.button}
          loading={status === 'saving'}
          onClick={() => savePreferencesAccount(this.buildSaveData())}
          theme={'lightTeal'}
        >
          SAVE
        </Button>
        {!editPassword && !this.isSSOProvider && (
          <LinkButton onClick={() => this.setState({ editPassword: true })}>Change Password?</LinkButton>
        )}
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.accountView}>
        <H1 className={`${classes.header} ${classes.h1}`}>Your Account</H1>
        <div className={classes.section}>
          <H2 className={classes.header}>Account Details</H2>
          {this.renderDisplayName()}
          {this.renderEmail()}
          {!this.isSSOProvider && this.renderPasswordField({ field: 'currentPassword', required: true })}
          {this.renderChangePassword()}
          {this.state.email !== this.props.user.email && (
            <div className={classes.note}>
              Since your email is also your login, you will be required to re-validate the new email before you can sign
              in again.
            </div>
          )}
          {this.renderButtons()}
        </div>
        <div className={classes.section}></div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    state => ({
      displayName: getDisplayName(state),
      errors: getPreferencesErrors(state),
      status: getPreferencesAccountStatus(state),
      user: getUser(state),
    }),
    { savePreferencesAccount, setPreferencesError }
  )
)(AccountView);
