export const CLOSE_SERIES_NOTIFICATION = 'CLOSE_SERIES_NOTIFICATION';
export const OPEN_SERIES_NOTIFICATION = 'OPEN_SERIES_NOTIFICATION';
export const SET_SERIES_NOTIFICATION = 'SET_SERIES_NOTIFICATION';
export const TOGGLE_SERIES_NOTIFICATION = 'TOGGLE_SERIES_NOTIFICATION';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';

export const openSeriesNotification = id => ({
  type: OPEN_SERIES_NOTIFICATION,
  id,
});

export const closeSeriesNotification = id => ({
  type: CLOSE_SERIES_NOTIFICATION,
  id,
});

export const setSeriesNotification = series => ({
  type: SET_SERIES_NOTIFICATION,
  series,
});

export const toggleSeriesNotification = series => ({
  type: TOGGLE_SERIES_NOTIFICATION,
  series,
});

export const updateNotifications = payload => ({
  type: UPDATE_NOTIFICATIONS,
  payload,
});

export const resetNotifications = () => ({
  type: RESET_NOTIFICATIONS,
});
