import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { TrackDiv } from '../Track';

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
    fontSize: ({ fontSize }) => fontSize || 13,
    fontWeight: ({ bold }) => (bold ? 'bold' : 'normal'),
    color: theme.palette.text.nonary,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const LinkButton = ({ bold, className, children, fontSize, onClick, track }) => {
  const classes = useStyles({ fontSize, bold });
  return (
    <TrackDiv className={`${classes.link} ${className}`} onClick={onClick} track={track}>
      {children}
    </TrackDiv>
  );
};

export default LinkButton;
