import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  sideNav: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.quinary,
    borderRadius: 8,
    padding: '20px 22px', // Items have 12px margin top and bottom.
    width: '256px',
  },
  [theme.breakpoints.down('sm')]: {
    sideNav: {
      boxSizing: 'border-box',
      width: '100%', // Matches width padding.
    },
  },
});

const SideNav = ({ classes, children }) => <div className={classes.sideNav}>{children}</div>;

export default withStyles(styles)(SideNav);
