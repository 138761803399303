import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { compose } from 'recompose';
import classnames from 'classnames';
import { format } from 'date-fns';

import Countdown from '../CalendarTable/Countdown';
import ItemParticipants from '../CalendarTable/ItemParticipants';
import ItemActionDropdown from '../CalendarTable/CalendarItems/ItemActionDropdown';
import TournamentImage from '../Images/TournamentImage';
import { H1, H2, TrackLink } from '../common';
import { ONE_MINUTE } from '../../store/selectors/gameData';
import { getFilteredUpcomingSingleSeries } from '../../store/selectors/upcomingSeries';
import { getSeriesUrl } from '../../utils/urls';

const styles = theme => ({
  root: {
    position: 'relative',
  },
  startDateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 12,
    color: theme.palette.text.primary,
    '& .H1': {
      fontWeight: 600,
      letterSpacing: 0,
      marginLeft: 12,
      margin: 0,
    },
    '& .H2': {
      fontWeight: 600,
      letterSpacing: 0,
      margin: 0,
    },
  },
  noSeriesLive: {
    position: 'relative',
    height: 88,
    width: 552,
    borderRadius: 4,
    background: theme.palette.background.secondary,
    boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.2)',
    alignItems: 'center',
    display: 'flex',
    boxSizing: 'border-box',
    border: `2px solid ${theme.palette.border.default}`,
  },

  startTimeContainer: {
    color: theme.palette.text.primary,
    letterSpacing: 0,
    fontSize: 32,
    width: 125,
    fontWeight: 600,
    lineHeight: '40px',
    textAlign: 'center',
    marginLeft: 12,
    whiteSpace: 'nowrap',
  },
  tournamentName: {
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '24px',
    width: '100%',
    letterSpacing: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
  },
  seriesInfoBottomRow: {
    display: 'flex',
    maxWidth: '100%',
  },
  seriesInfo: {
    width: '44%',
    color: theme.palette.text.primary,
    marginLeft: 32,
    display: 'flex',
    alignItems: 'center',
    '& span': {
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: 13,
      letterSpacing: 0,
      lineHeight: '16px',
      textAlign: 'left',
    },
    '& img': {
      width: 40,
      height: 40,
      marginRight: 12,
    },
    '&.isParticipantNameTBD': {
      width: '80%',
    },
  },
  seriesInfoText: {
    minWidth: 0,
  },
  participantsContainer: {
    width: '40%',
    marginLeft: 12,
    color: theme.palette.text.primary,
  },
  notificationDropdown: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    '.dropdownOpen &': {
      // NOTE: Needed to render the dialog overl all content on mobile.
      zIndex: 1002, // To beat footer when dropdown open.
    },
  },
  link: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  [theme.breakpoints.down('sm')]: {
    noSeriesLive: {
      width: 315,
    },
    seriesInfo: {
      width: '60%',
      marginLeft: 16,
    },
    participantsContainer: {
      marginLeft: 8,
      width: '25%',
    },
    notificationDropdown: {
      top: 28,
    },
  },
});

class TopUpcomingEvent extends Component {
  state = { dropdownOpen: false };

  handleClose = () => {
    this.setState({ dropdownOpen: false });
  };

  handleOpen = () => this.setState(state => ({ dropdownOpen: !state.dropdownOpen }));

  render() {
    const { classes, topUpcomingSeries, source, className } = this.props;
    const { dropdownOpen } = this.state;

    //Prevent site crashing if there aren't any upcoming events
    if (!topUpcomingSeries) return null;

    const { participants, tournament } = topUpcomingSeries;

    const startDate = new Date(Math.floor(topUpcomingSeries.start.toDate().getTime() / ONE_MINUTE) * ONE_MINUTE); // trim off seconds.
    const isWithinOneHour = startDate.getTime() - new Date().getTime() < 60 * 60 * 1000;

    const isParticipantNameTBD = !participants[0].shortName || !participants[1].shortName;

    return (
      <div
        className={classnames({
          [classes.root]: true,
          [className]: true,
          dropdownOpen,
        })}
      >
        <div className={classes.startDateContainer}>
          <H2 className="H2">Next Up</H2>
          {isWithinOneHour ? (
            <Countdown withoutStartingText className={classes.startTimeContainer} targetTime={startDate} />
          ) : (
            <H1 className="H1">{format(topUpcomingSeries.start.toDate(), 'MMM dd, h:mmaaaaa')}m</H1>
          )}
        </div>
        <div className={`${classes.noSeriesLive} topUpcoming`}>
          <div
            className={classnames({
              [classes.seriesInfo]: true,
              isParticipantNameTBD: isParticipantNameTBD && !Boolean(participants.length > 2),
            })}
          >
            <TournamentImage tournament={tournament} />
            <div className={classes.seriesInfoText}>
              <div className={classes.tournamentName}>{tournament.title}</div>
              <span>{topUpcomingSeries.title}</span>
            </div>
          </div>
          {!!participants.length && (
            <ItemParticipants className={classes.participantsContainer} participants={participants} />
          )}
          <div className={classes.notificationDropdown}>
            <ItemActionDropdown
              isMatchView
              open={dropdownOpen}
              handleClose={this.handleClose}
              handleOpen={this.handleOpen}
              series={topUpcomingSeries}
            />
          </div>
        </div>
        <TrackLink
          to={getSeriesUrl(topUpcomingSeries)}
          className={classes.link}
          track={{
            event: 'Upcoming Event Clicked',
            source,
            target: 'series-page',
            eventOptions: { contextSeriesId: topUpcomingSeries.id },
          }}
        />
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    state => ({
      topUpcomingSeries: getFilteredUpcomingSingleSeries(state),
    }),
    {}
  )
)(TopUpcomingEvent);
