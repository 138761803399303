import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { FaColumns } from 'react-icons/fa';

import { getSelectedStreamId } from '../../store/selectors/uiStates';
import { isFeatureEnabled } from '../../utils/featureFlags';

import HeroMainPlayer from '../Video/HeroMainPlayer';
import SportBuff from '../SportBuff';
import ChatCollapsedButton from '../Video/Chat/ChatCollapsedButton';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  mediaWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '.isChatCollapsed &': {
      display: 'block',
      margin: '0 48px',
      width: 'calc(100% - 96px)', // To fit the ChatCollapsButton on the right of the container.
    },
    '.isTheaterMode &': {
      margin: 'auto !important',
      width: '100% !important',
    },
  },
  frameContainer: {
    height: 0,
    paddingTop: '56.25%',
    overflow: 'hidden',
    position: 'relative',
  },
  frameContainerFlexContainer: {
    flex: 1,
  },
  theaterButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  theaterModeButton: {
    color: 'white',
    bottom: 12,
    zIndex: 1,
    position: 'absolute',
    cursor: 'pointer',
    visibility: 'hidden',
    '$frameContainer:hover &': {
      visibility: 'visible',
    },
    '&:hover': {
      color: '#9146ff',
    },
  },
  theaterTooltipText: {
    position: 'absolute',
    bottom: 30,
    zIndex: 1,
    width: 110,
    fontSize: 13,
    fontWeight: 600,
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center',
    padding: '2px 0',
    visibility: 'hidden',
    opacity: 0.8,
    '$theaterButtonContainer:hover &': {
      visibility: 'visible',
    },
  },
  frameCollapseButton: {
    opacity: 0,
    display: 'none',
    '.isTheaterMode.isChatCollapsed &': {
      display: 'block',
    },
    '$frameContainer:hover &': {
      opacity: 1,
    },
  },
  notTheaterCollapseButton: {
    display: 'none',
    '.isChatCollapsed &': {
      display: 'flex',
    },
    '.isTheaterMode.isChatCollapsed &': {
      display: 'none',
    },
  },
  offlineLabel: {
    color: 'dimgrey',
    fontStyle: 'italic',
    letterSpacing: 1,
    textAlign: 'center',
    margin: 80,
    gridColumn: '1 / 7', // span whole page
  },

  [theme.breakpoints.down('sm')]: {
    theaterButtonContainer: {
      display: 'none',
    },
  },
}));

const LiveStreamPlayer = ({
  series,
  isTheaterMode,
  handleToggleTheaterMode,
  isChatCollapsed,
  toggleCollapseChat,
  playerMaxWidth,
}) => {
  const selectedStreamId = useSelector(state => getSelectedStreamId(state, series));

  const shouldRenderSportBuff = stream =>
    Boolean(series?.sportBuff?.streamId && stream) && isFeatureEnabled('sportBuffVideo');

  const classes = useStyles();
  const stream = series.streams && series.streams[selectedStreamId];
  const sportBuffId = `sportBuff-${stream?.id}`;

  return (
    <div className={classes.container}>
      {/* `channel` could be null if the user somehow navigates to a /events/ url that is way over.
              TODO: maybe `renderLive` is not the best fallback? */}
      {Boolean(stream) ? (
        <div style={{ maxWidth: playerMaxWidth }} className={classes.mediaWrapper}>
          <div className={classes.frameContainerFlexContainer}>
            <div className={classes.frameContainer}>
              <HeroMainPlayer
                key={stream.id}
                id={stream.id}
                sportBuffId={sportBuffId}
                platformType={stream.platformType}
                muted={false}
                channel={stream.platformId}
                seriesId={series.id}
                controls
              />

              <div className={classes.theaterButtonContainer}>
                <span className={classes.theaterTooltipText}>
                  {isTheaterMode ? 'Exit Theater Mode' : 'Theater Mode'}
                </span>
                <FaColumns className={classes.theaterModeButton} onClick={handleToggleTheaterMode} />
              </div>

              {/* frameCollapseButton - Renders only when chat is collapsed, and TheaterMode is activated */}
              <div className={classes.frameCollapseButton}>
                <ChatCollapsedButton
                  isChatCollapsed={isChatCollapsed}
                  isTheaterMode={isTheaterMode}
                  toggleCollapseChat={toggleCollapseChat}
                />
              </div>
            </div>
          </div>

          {/* ChatCollapsedButton - Renders only when chat is collapsed, and not in TheaterMode */}
          <ChatCollapsedButton
            className={classes.notTheaterCollapseButton}
            isChatCollapsed={isChatCollapsed}
            isTheaterMode={isTheaterMode}
            toggleCollapseChat={toggleCollapseChat}
          />

          {shouldRenderSportBuff(stream) && <SportBuff elemId={sportBuffId} series={series} stream={stream} />}
        </div>
      ) : (
        <h1 className={classes.offlineLabel}>Stream is offline.</h1>
      )}
    </div>
  );
};

export default LiveStreamPlayer;
