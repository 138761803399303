import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import VodThumbnail from './VodThumbnail';
import { GameTag } from '../common';
import { getGameById } from '../../store/selectors/gameData';
import getFormattedTeamNames from '../../utils/getFormattedTeamNames';
import getDistanceBetweenDates from '../../utils/getDistanceBetweenDates';
import VideoCardStyles from '../VideoSwatch/VideoCardStyles';

const styles = theme => ({
  ...VideoCardStyles,
  cardContainer: {
    ...VideoCardStyles.cardContainer,
    border: `1px solid ${theme.palette.border.senary}`,
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.2)',
    textDecoration: 'none',
    display: 'grid',
    gridTemplateColumns: '7fr 8fr',
    width: 800,
    margin: 'auto',
    marginBottom: 12,
    position: 'relative',
    background: theme.palette.background.secondary,
    '@media (max-width: 850px)': {
      display: 'block',
      width: 500,
    },
    '@media (max-width: 600px)': {
      display: 'block',
      width: 'auto',
      minWidth: 300,
    },
    '&:hover': {
      transform: 'translate(-2px, -2px)',
    },
  },

  frameContainer: {
    ...VideoCardStyles.frameContainer,
    overflow: 'hidden',
    background: theme.darkMagenta,
    '&.gradientBrackground': {
      background: theme.gradientBackground,
    },
  },

  card: {
    ...VideoCardStyles.card,
    padding: '24px 24px 0px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  gameTag: {
    position: 'absolute',
    right: 8,
    top: 8,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      color: 'grey !important',
    },
  },

  title: {
    ...VideoCardStyles.title,
    fontSize: '20px',
    margin: '6px 0',
    whiteSpace: 'unset',
    color: theme.palette.text.primary,
  },

  teamsText: {
    fontWeight: 600,
    fontSize: '17px',
    margin: '6px 0',
  },

  cardTextRow: {
    ...VideoCardStyles.cardTextRow,
    width: '49%',
    position: 'absolute',
    bottom: 0,
    alignItems: 'flex-end',
    margin: '6px 0',
    fontSize: '0.875em',
    '@media (max-width: 850px)': {
      width: '100%',
      position: 'relative',
    },
  },

  viewCount: {
    ...VideoCardStyles.viewCount,
    color: theme.palette.text.primary,
  },

  timeLabel: {
    ...VideoCardStyles.viewCount,
    color: theme.palette.text.primary,
  },
  cardLink: {
    color: 'inherit',
    textDecoration: 'none',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
});

class HorizontalVodCard extends Component {
  render() {
    const {
      series: { tournament, vod, participants },
      series,
      classes,
      game,
    } = this.props;

    // Block rendering anything at all, until vod is found. This will help avoid layout jumping if some vods aren't found,
    // which is common on VodsScreen (which uses this HorizontalVodCard component) due to lower-tiered series.
    if (!vod) return null;

    const noParticipants = participants.every(participant => participant.name === 'TBD');

    return (
      <div className={classes.cardContainer}>
        <div
          className={classnames({
            [classes.frameContainer]: true,
            gradientBrackground: noParticipants,
          })}
        >
          <VodThumbnail series={series} tournament={tournament} isShort noParticipants={noParticipants} />
          <Link to={`/vods/watch/${series.id}`} className={classes.hoverOverlay}>
            <h2>Click Here to</h2>
            <h1>Watch</h1>
          </Link>
        </div>
        <div className={classes.card}>
          <Link to={`/vods/watch/${series.id}`} className={classes.cardLink} />
          <GameTag className={classes.gameTag} game={game} />
          <div className={classes.title} title={tournament.title}>
            {noParticipants ? series.title : `${tournament.title} - ${series.title}`}
          </div>
          <div className={classes.teamsText}>{getFormattedTeamNames(participants.map(p => p.name))}</div>
          <div className={classes.cardTextRow}>
            <span className={classes.timeLabel}>{getDistanceBetweenDates(series.end.toDate())}</span>
            <span className={classes.viewCount}>
              {vod.viewerCount.toLocaleString(undefined, { maximumFractionDigits: 0 })} viewers
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect((state, ownProps) => ({
    game: getGameById(state, ownProps.series.game),
  }))
)(HorizontalVodCard);
