import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import { DropdownSelect, DropdownItem } from '../common';
import { getSortedGames, isDataLoaded } from '../../store/selectors/gameData';

const styles = () => ({
  wrapper: {
    '& .root': {
      marginLeft: 16,
      width: 200,
    },
    '& .popper': {
      right: 0,
      zIndex: 2, // To beat notifications button.
      '& .dropdown': {
        maxWidth: '200px',
      },
    },
  },
});

class GameFilterSelect extends Component {
  state = { open: false };

  renderGamesDropdownLabel = () => {
    const { gameMap, selectedGames, isDataLoaded, onItemClick } = this.props;

    if (!isDataLoaded) {
      return '';
    } else {
      if (!!selectedGames.length) {
        return selectedGames
          .filter(gameId => {
            if (!gameMap[gameId]) {
              // If the game no longer exists this will remove from local storage.
              onItemClick(gameId);
            }
            return !!gameMap[gameId];
          })
          .map(gameId => gameMap[gameId].abbr || gameMap[gameId].name)
          .join(', ');
      }
    }

    if (this.state.open) {
      return 'Choose Games';
    }

    return 'All Games';
  };

  render() {
    const { classes, disabled, games, onItemClick, selectedGames, className, source, isFollowMode } = this.props;
    const { open } = this.state;

    const getTracking = (event, target = null, game = null) => ({
      event,
      eventProperties: {
        selectedGameIds: selectedGames,
        disabled,
        game_abbr: game && game.abbr,
        game_id: game && game.id,
      },
      source: source || 'game-filter',
      target,
    });

    return (
      <DropdownSelect
        withArrowIcon
        className={`${classes.wrapper} ${className}`}
        open={open}
        active={isFollowMode || !!selectedGames.length}
        placeholder={this.renderGamesDropdownLabel()}
        handleOpen={() => this.setState(state => ({ open: !state.open }))}
        handleClose={() => this.setState({ open: false })}
        track={getTracking('Game Filter Opened')}
      >
        <DropdownItem
          disabled={disabled}
          active={!selectedGames.length}
          /* Must have empty arguments to clear filters */
          onClick={() => onItemClick()}
          hasCheckbox
          track={getTracking('Game Filter Clicked', 'all-games')}
        >
          <span>All Games</span>
        </DropdownItem>
        {games.map(game => {
          return (
            <DropdownItem
              key={game.id}
              disabled={disabled}
              active={selectedGames.includes(game.id)}
              onClick={() => onItemClick(game.id)}
              hasCheckbox
              track={getTracking('Game Filter Clicked', game.abbr, game)}
            >
              <span>{game.name}</span>
            </DropdownItem>
          );
        })}
      </DropdownSelect>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(state => ({
    games: getSortedGames(state),
    gameMap: state.gameData.games, // used for quickly getting game names by IDs
    isDataLoaded: isDataLoaded(state),
    isFollowMode: state.uiStates.isFollowMode,
  }))
)(GameFilterSelect);
