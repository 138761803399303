import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { Redirect } from 'react-router-dom';

import { getSeriesByTournamentSlug } from '../store/selectors/gameData';
import { getLiveSeriesByTournamentSlug } from '../store/selectors/liveSeries';
import { subscribeToQuery } from '../store/actions/gameData';
import { isDataLoaded } from '../store/selectors/gameData';
import { getSeriesUrl, getTournamentUrl, getTournamentUrlFromSlug } from '../utils/urls';

const styles = theme => ({
  loadingContainer: {
    minHeight: '100vh', // so footer doesn't show on top
    '& .loading-circle': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    loadingContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& .loading-circle': {
        left: 'unset',
      },
    },
  },
});

class EventLiveScreen extends Component {
  getQueryParams(tournamentSlug) {
    return ['allSeries', ['tournament.slug', '==', tournamentSlug || this.props.match.params.tournamentSlug]];
  }

  async componentDidMount() {
    const { subscribeToQuery } = this.props;
    subscribeToQuery(...this.getQueryParams());
  }

  render() {
    const {
      classes,
      tournamentSeries,
      liveSeries,
      match: {
        params: { tournamentSlug },
      },
      isDataLoaded,
    } = this.props;

    if (!isDataLoaded) {
      return (
        <div className={classes.loadingContainer}>
          <div className="loading-circle" />
        </div>
      );
    }

    // Redirect to the live Series with more viewers.
    const mostViewedLiveSeries = liveSeries && liveSeries[0];
    if (mostViewedLiveSeries) {
      return <Redirect to={getSeriesUrl(mostViewedLiveSeries)} />;
    }

    const nowSeconds = Math.floor(new Date().getTime() / 1000);
    const upComingSeries = tournamentSeries?.reduce((accu, aSeries) => {
      if (aSeries.start?.seconds && aSeries.start.seconds > nowSeconds) {
        if (!accu?.start || accu.start.seconds > aSeries.start.seconds) {
          return aSeries;
        }
      }
      return accu;
    }, undefined);

    //Redirect to the upcoming series.
    if (upComingSeries) {
      return <Redirect to={getSeriesUrl(upComingSeries)} />;
    }

    // If the data is loaded and there's no series, we will redirect the user to the tournament.
    if (!tournamentSeries?.length) {
      return <Redirect to={getTournamentUrlFromSlug(tournamentSlug)} />;
    }

    // If there are no upcoming series, redirect to the event page.
    const tournament = tournamentSeries[0].tournament;
    return <Redirect to={getTournamentUrl(tournament)} />;
  }
}

export default compose(
  connect(
    (state, ownProps) => ({
      tournamentSeries: getSeriesByTournamentSlug(state, ownProps.match.params.tournamentSlug),
      liveSeries: getLiveSeriesByTournamentSlug(state, ownProps.match.params.tournamentSlug),
      isDataLoaded: isDataLoaded(state),
      state,
    }),
    { subscribeToQuery },
    (stateProps, dispatchProps, ownProps) =>
      Boolean(stateProps.tournamentSeries)
        ? {
            ...stateProps,
            ...dispatchProps,
            ...ownProps,
          }
        : { ...stateProps, ...dispatchProps, ...ownProps }
  ),
  withStyles(styles)
)(EventLiveScreen);
