import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles(() => ({
  hideTooltip: {
    visibility: 'hidden !important',
  },
}));

const StreamsTooltip = ({ className, open }) => {
  const classes = useStyles();
  return (
    <span
      className={classnames({
        [className]: className,
        [classes.hideTooltip]: open,
      })}
    >
      More Streams
    </span>
  );
};

export default StreamsTooltip;
