import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import ParticipantImage from '../Images/ParticipantImage';

import { getSeriesById } from '../../store/selectors/gameData';
import { isFeatureEnabled } from '../../utils/featureFlags';
import TeamPageLink from '../common/TeamPageLink';

const TEAM = 'TEAM';

const styles = theme => ({
  teamContainer: {
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginLeft: 7,
    marginRight: 20,
    position: 'relative',
    maxHeight: 360,
    '&.small': {
      minWidth: 300,
      marginTop: 10,
    },
    '&.large': {
      minWidth: 500,
      marginTop: 10,
    },
  },
  teamLogo: {
    filter: 'drop-shadow(0 0 1px white)',
    paddingTop: 2,
    fontSize: 32,
    // targets placeholder div
    '& > div': {
      width: 70,
      height: 72,
    },
    '&.enableLink': {
      pointerEvents: 'all',
    },
  },
  teamScores: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: 590,
    maxHeight: 275,
    overflowY: 'auto',
  },
  teamScore: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    '& > div': {
      // Score Box shared styles
      color: 'black',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'white',
      borderRadius: 5,
    },
    '& > span': {
      // Team name shared styles
      fontSize: 15,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
  },
  twoTeams: {
    minWidth: 85,
    flexDirection: 'column',
    textAlign: 'center',
    margin: 5,
    '& img': {
      height: 70,
    },
    '& h1': {
      fontSize: '3rem',
      margin: 5,
    },
    // Score Box styles for two teams
    '& > div': {
      width: 66,
      height: 66,
    },
    // Team name styles for two teams
    '& > span': {
      margin: 18,
    },
  },
  moreThanTwoTeams: {
    minWidth: 160,
    margin: '10px 30px',
    justifyContent: 'space-between',
    '& img': {
      width: 45,
    },
    '& h1': {
      fontSize: '2rem',
      margin: 5,
    },
    // Score Box styles for more than two teams
    '& > div': {
      width: 45,
      height: 45,
    },
    // Team name styles for more than two teams
    '& > span': {
      margin: 5,
      width: 90,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginLeft: 4,
    },
  },
  seriesTitle: {
    color: 'white',
    fontSize: 20,
    fontWeight: 700,
    marginBottom: 8,
    textTransform: 'uppercase',
  },
  hideText: {
    display: 'none',
  },
  versusOverlay: {
    position: 'absolute',
    top: 18,
    width: '100%',
    height: '100%',
    fontSize: '13.2rem',
    fontStyle: 'oblique',
    fontWeight: 900,
    color: '#282828',
    zIndex: -1,
  },
  seriesInformationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 5,
  },
  bestOf: {
    fontSize: 18,
    fontWeight: 800,
    color: theme.redPink,
    marginTop: 5,
    '&.top': {
      fontSize: 16,
      marginLeft: 10,
      verticalAlign: 'sub',
    },
  },
  participantLink: {
    pointerEvents: 'none !important',
  },
  participantName: {
    '&.enableLink': {
      pointerEvents: 'all !important',
    },
  },
});

const TeamsScore = props => {
  const { classes, series } = props;
  const source = 'match-team-score';

  const participants = !!series ? series.participants : [];
  const moreThanFour = participants.length > 4;

  const bestOfText = (
    // Check if game is overwatch
    //TODO: remove cast once IDs are converted to strings in Firestore
    <>
      {`${series.game}` === `${12}` ? (
        <span className={classNames({ [classes.bestOf]: true, top: moreThanFour })}>{series.bestOf} MAP SET</span>
      ) : (
        <span className={classNames({ [classes.bestOf]: true, top: moreThanFour })}>Best of {series.bestOf}</span>
      )}
    </>
  );

  return (
    <div className={`${classes.teamContainer} ${participants.length <= 4 ? 'small' : 'large'}`}>
      <div className={classes.versusOverlay}>VS</div>
      <div className={classes.seriesInformationContainer}>
        <span className={classes.seriesTitle}>{series.title}</span>
        <div className={classNames({ [classes.hideText]: !moreThanFour || !series.bestOf })}>{bestOfText}</div>
      </div>
      <div className={classes.teamScores}>
        {participants.length &&
          participants.map((participant, index) => {
            const participantType = participant && participant.participantType;
            const enableLink = participantType === TEAM && isFeatureEnabled('teamPage');
            return (
              <TeamPageLink
                className={classes.participantLink}
                slug={participant.slug}
                participantType={participantType}
                key={index}
                track={{ source }}
              >
                <div
                  className={classNames({
                    [classes.teamScore]: true,
                    [classes.twoTeams]: participants.length <= 4,
                    [classes.moreThanTwoTeams]: participants.length > 4,
                  })}
                >
                  <ParticipantImage
                    className={classNames({
                      [classes.teamLogo]: true,
                      enableLink,
                    })}
                    participant={participant}
                  />
                  <span
                    className={classNames({
                      [classes.participantName]: true,
                      enableLink,
                    })}
                  >
                    {participant.shortName}
                  </span>

                  <div>
                    <h1>{participant.score || 0}</h1>
                  </div>
                </div>
              </TeamPageLink>
            );
          })}
      </div>

      <div
        className={classNames({
          [classes.hideText]: moreThanFour || !series.bestOf,
        })}
      >
        {bestOfText}
      </div>
    </div>
  );
};

export default compose(
  withStyles(styles),
  connect((state, ownProps) => ({
    series: getSeriesById(state, ownProps.seriesId),
  }))
)(TeamsScore);
