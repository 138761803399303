import React from 'react';
import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';

import { SIGNUP_DIALOG_ACTIONS } from '../index';
import { Button, TrackButton } from '../../../common';

const styles = theme => ({
  title: {
    width: 150,
    height: 32,
    background: theme.lightTeal,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 3,
    color: theme.palette.text.primary,
    lineHeight: '20px',
    marginBottom: 24,
  },
  text: {
    fontSize: 32,
    fontWeight: 400,
    lineHeight: '40px',
    textAlign: 'center',
    color: theme.palette.text.primary,
    marginTop: 0,
    marginBottom: 16,
  },
  subtitle: {
    height: 40,
    color: theme.palette.text.primary,
    fontSize: 15,
    lineHeight: '20px',
    textAlign: 'center',
    marginBottom: 24,
  },
  image: {
    width: 352,
    marginBottom: 32,
  },
  button: {
    letterSpacing: 0,
    width: '100%',
    height: 48,
    padding: '8px 10px',
    fontSize: 16,
    fontWeight: 600,
    borderRadius: 6,
    lineHeight: '20px',
    background: theme.magentaPink,
    color: 'white',
    '&:hover': {
      opacity: 0.9,
    },
    '&.transparent': {
      background: 'transparent',
      color: theme.magentaPink,
      marginTop: 16,
      '&:hover': {
        background: theme.lightGrey,
      },
    },
  },
  gate: {
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(0,0,0,0.1)',
    },
  },
  container: {
    alignItems: 'center',
    background: theme.palette.background.secondary,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '48px 84px 60px',
    '&.withoutCheckbox': {
      padding: '48px 84px',
    },
  },
  '@media (max-width: 700px)': {
    container: {
      padding: '20px 14px 30px',
      '&.withoutCheckbox': {
        padding: '20px 14px',
      },
    },
    button: {
      padding: '0 5px',
    },
    image: {
      width: 260,
    },
    text: {
      fontSize: 26,
    },
  },
  '@media (max-width: 320px)': {
    image: {
      width: 240,
    },
  },
});

const CalendarAddingView = ({
  classes,
  onClick,
  onCloseClick,
  isSignedIn,
  onLoginSuccess,
  withoutCheckbox,
  source,
  view,
}) => {
  const feature = 'add-to-calendar';
  return (
    <div className={classnames({ [classes.container]: true, withoutCheckbox })}>
      <div className={classes.title}>NEW FEATURE</div>
      <h1 className={classes.text}>Add To Your Calendar</h1>
      <div className={classes.subtitle}>You can now export matches from Juked to your personal calendars.</div>
      <img className={classes.image} src="/images/Add_to_Calendar.png" alt="addToCalendar" />
      {isSignedIn ? (
        <TrackButton
          className={classes.button}
          onClick={() => {
            onCloseClick({ shouldTrack: false }); // Track a redirect instead.
            onLoginSuccess(); // Redirect users to Calendar screen.
          }}
          track={{
            event: SIGNUP_DIALOG_ACTIONS.REDIRECT,
            eventProperties: { view, feature, is_signed_in: isSignedIn },
            source,
            target: 'calendar-page',
          }}
        >
          Try it now
        </TrackButton>
      ) : (
        <TrackButton
          className={classes.button}
          onClick={onClick}
          track={{
            event: SIGNUP_DIALOG_ACTIONS.SIGNUP,
            eventProperties: { view, feature, is_signed_in: isSignedIn },
            source,
            target: 'login',
          }}
        >
          Sign up and try it today
        </TrackButton>
      )}
      {/* onCloseClick is already tracked */}
      <Button className={`${classes.button} transparent`} onClick={() => onCloseClick()}>
        No Thanks
      </Button>
    </div>
  );
};

export default withStyles(styles)(CalendarAddingView);
