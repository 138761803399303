import React from 'react';
import { makeStyles } from '@material-ui/styles';
import classnames from 'classnames';

import { SliderSwitch } from '../../common';

const useStyles = makeStyles(theme => ({
  switch: {
    width: 28,
    height: 16,
    marginRight: 32,
    '& .slider': {
      '&:before': {
        height: 12,
        width: 12,
        left: 1,
        bottom: 1,
      },
    },
    '&.checked': {
      '& .slider': {
        '&:before': {
          transform: 'translateX(12px)',
          '-webkit-transform': 'translateX(12px)',
          '-ms-transform': 'translateX(12px)',
        },
      },
    },
    '&.disabled': {
      '& .slider': {
        opacity: 0.5,
        '&:before': {
          opacity: 0.6,
          background: theme.black,
        },
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    switch: {
      marginRight: 0,
      marginLeft: 8,
    },
  },
}));

const SliderInput = ({ checked, onChange, disabled }) => {
  const classes = useStyles();

  return (
    <SliderSwitch
      className={classnames({ [classes.switch]: true, checked, disabled })}
      checked={checked}
      onChange={onChange}
    />
  );
};

export default SliderInput;
