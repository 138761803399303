import classnames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MdPerson } from 'react-icons/md';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import ItemStyles from '../../ItemStyles';
import ItemParticipants from '../../ItemParticipants';
import { GameTag, TrackLink } from '../../../common';
import ItemPrizepool from '../../ItemPrizepool';
import TournamentImage from '../../../Images/TournamentImage';
import { getGameById } from '../../../../store/selectors/gameData';
import hasPrizepool from '../../../../utils/hasPrizepool';
import { getSeriesUrl, getTournamentUrl } from '../../../../utils/urls';
import totalViewerCountString from '../../../../utils/totalViewerCountString';

const styles = theme => ({
  ...ItemStyles,
  root: {
    ...ItemStyles.root,
    textDecoration: 'none',
    color: theme.palette.text.primary,
    background: theme.palette.background.secondary,
  },
  viewersContainer: {
    width: '13%',
    color: theme.redPink,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'initial',
    order: -1,
    '.matchView &': {
      width: '15%',
    },
  },
  viewersIcon: {
    marginLeft: 5,
    fontSize: 16,
    lineHeight: 16,
    verticalAlign: 'middle',
  },
  viewers: {
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 600,
    '&:before': {
      backgroundColor: 'red',
      borderRadius: 12,
      content: '" "',
      display: 'inline-block',
      height: 12,
      marginBottom: 4,
      marginRight: 6,
      verticalAlign: 'middle',
      width: 12,
    },
  },
  nowPlaying: {
    width: '8%',
    fontSize: 10,
    fontWeight: 500,
    marginRight: 5,
    textAlign: 'right',
    lineHeight: '16px',
    letterSpacing: '0.2px',
  },
  hideLogo: {
    display: 'none',
  },
  noParticipants: {
    marginLeft: 'auto',
  },
});

class LiveItem extends Component {
  render() {
    const {
      classes,
      series: { tournament, participants },
      series,
      game,
      isMatchView,
    } = this.props;

    const streamsArr = Object.values(series.streams || {});
    return (
      <div
        className={classnames({
          [classes.root]: true,
          matchView: isMatchView,
        })}
      >
        <div className={classes.viewersContainer}>
          <span className={classes.viewers}>{totalViewerCountString(streamsArr)}</span>
          <MdPerson className={classes.viewersIcon} />
        </div>
        <GameTag game={game} className={classes.gameName} seriesId={series.id} />
        <div
          className={classnames({
            [classes.seriesInfo]: true,
            [classes.noParticipants]:
              isMatchView && (!participants.length || participants.some(participant => participant.name === 'TBD')),
          })}
        >
          <TournamentImage className={classnames({ [classes.hideLogo]: isMatchView })} tournament={tournament} />
          <div className={classes.seriesInfoText}>
            <div className={classes.tournamentName}>{tournament.title}</div>
            <div className={classes.seriesInfoBottomRow}>
              <span className={classes.seriesTitle}>{series.title}</span>
              {!isMatchView && hasPrizepool(tournament.prizepool) && (
                <>
                  <span className={classes.seriesSeparator}>-</span>
                  <ItemPrizepool prizepool={tournament.prizepool} withTrophyIcon />
                </>
              )}
            </div>
          </div>
        </div>
        {!!participants.length && participants.every(participant => participant.name !== 'TBD') && (
          <>
            {!isMatchView && <div className={classes.nowPlaying}>Now Playing</div>}

            <ItemParticipants participants={participants} />
          </>
        )}
        <TrackLink
          to={isMatchView ? getSeriesUrl(series) : getTournamentUrl(tournament)}
          className={classes.hoverText}
          track={{
            event: 'Schedule Item Clicked',
            target: isMatchView ? 'series-page' : 'event-page',
            eventProperties: { type: 'live-item' },
            eventOptions: { contextSeriesId: series.id },
          }}
        >
          Click Here to <span>{isMatchView ? 'View Match' : 'View Event'}</span>
        </TrackLink>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    (state, ownProps) => ({
      game: getGameById(state, ownProps.series.game),
    }),
    {}
  )
)(LiveItem);
