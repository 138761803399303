import React, { useCallback, MouseEvent, MouseEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { Track, trackEvent } from '../../store/actions/uiStates';

interface TrackClickProps {
  disabled?: boolean;
  onClick?: MouseEventHandler;
  track?: Track;
}

interface WithTrackClickProps extends TrackClickProps {
  disabled?: boolean;
}

/**
 * A hook that returns a onClick handler function factory.  The factory takes
 * track and onClick properties and returns an onClick handler. This is so that
 * you can call the hook in a function body and pass the factory around.
 */
export const useGetTrackClick = (): ((props: TrackClickProps) => (e: MouseEvent) => void) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const match = useRouteMatch();

  const getTrackClick = ({ disabled, track, onClick = null }: TrackClickProps) => (e: MouseEvent) => {
    const { pathname, search, hash } = location;
    const { event, eventProperties, eventOptions, source, target } = track || {};

    if (onClick) onClick(e);

    if (event && !disabled) {
      dispatch(
        trackEvent(
          event,
          {
            source,
            target,
            url: pathname + search + hash,
            url_path: match.path,
            url_base: pathname,
            ...(eventProperties || {}),
          },
          eventOptions
        )
      );
    }
  };

  // Because we are returning a callback, we should memoize with useCallback
  // to prevent child from rendering unecessarily due to changed reference.
  return useCallback(getTrackClick, [dispatch, location, match]);
};

/**
 * Convenience function when you only need the onClick callback handler.
 */
export const useTrackClick = <P extends TrackClickProps>(args: P): ((e: MouseEvent) => void) =>
  useGetTrackClick()(args);

/**
 * A HOC that defines some standard tracking parameters and applies them to our trackEvent action.
 * @param {Component} WrappedComponent component to be wrapped
 * @prop {object} track
 * @prop {string} track.event event name, REQUIRED to track an event
 * @prop {object} track.eventProperties custom data passed through to trackEvent and sent to tracking backend.
 * @prop {object} track.eventOptions tracking config options like: `contextSeriesID`. Check trackEvent comments.
 * @prop {string} track.source standard property to denote origin of event; folded into eventProperties.
 * @prop {string} track.target standard property to denote destination or result of event; folded into eventProperties.
 */
export const withTrackClick = <P extends WithTrackClickProps>(
  WrappedComponent: React.ComponentType<P>
): ((props: P) => JSX.Element) => {
  const TrackClick = ({ disabled, onClick, track, ...props }: P): JSX.Element => {
    const handleClick = useTrackClick({ disabled, track, onClick });
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    return <WrappedComponent disabled={disabled} onClick={handleClick} {...(props as P)} />;
  };
  return TrackClick;
};
