import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import StandardBracket from './StandardBracket';
import BracketItem from './BracketItem';
import classnames from 'classnames';

import { getBracketById } from '../../store/selectors/gameData';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '100%',
    boxSizing: 'border-box',
    padding: 10,
  },
  centeredCircle: {
    margin: 'auto',
  },
  title: {
    color: 'white',
    width: '100%',
    textAlign: 'left',
  },
  singleSeriesColumn: {
    marginLeft: 40,
    '& h1': {
      textAlign: 'center',
    },
    '&.upperOnly': {},
  },
  upperOnly: {
    alignSelf: 'stretch',
    '& $singleSeriesColumn': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    '& $thirdPlaceItemContainer': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    '& h1': {
      color: 'white',
      fontSize: '1rem',
      textAlign: 'center',
      textTransform: 'uppercase',
      letterSpacing: 1,
      fontWeight: 500,
      marginTop: 0,
      marginBottom: 8,
    },
  },
  thirdPlaceItemContainer: {}, // Need this placeholder so the $thirdPlaceItemContainer above works.
  standardBracket: {
    margin: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      transform: 'scale(0.9)',
      left: 0,
      top: 0,
    },
  },
});

const upperBracketColumnNames = {
  0: 'Final',
  1: 'Semifinals',
  2: 'Quarterfinals',
  3: 'Round of 16',
  4: 'Round of 32',
  5: 'Round of 64',
};

const lowerBracketColumnNames = {
  0: 'Cons. Final',
  1: 'LB Final',
  2: 'LB Semifinal',
};

class BracketRenderer extends Component {
  render() {
    const { classes, bracket, currentSeries, spoilerFree } = this.props;

    if (!bracket) {
      return (
        <div className={classes.root}>
          <div className={`loading-circle ${classes.centeredCircle}`} />;
        </div>
      );
    }

    const standardBrackets = [];
    const endBrackets = [];

    const upperOnly = !!bracket.upper && !bracket.lower;

    if (bracket.upper) {
      standardBrackets.push(
        <Fragment key="upper">
          {!upperOnly && <h1 className={classes.title}>Upper Bracket</h1>}
          <StandardBracket
            bracket={bracket.upper}
            columnNames={upperBracketColumnNames}
            currentSeries={currentSeries}
            spoilerFree={spoilerFree}
          />
        </Fragment>
      );
    }

    if (bracket.lower) {
      standardBrackets.push(
        <Fragment key="lower">
          <h1 className={classes.title}>Lower Bracket</h1>
          <StandardBracket
            bracket={bracket.lower}
            columnNames={lowerBracketColumnNames}
            currentSeries={currentSeries}
            spoilerFree={spoilerFree}
            upperBracket={bracket.upper}
          />
        </Fragment>
      );
    }

    let previousSeriesIds;

    if (bracket.lower) {
      previousSeriesIds = [
        { type: 'winner', seriesId: bracket.upper[0][0] },
        { type: 'winner', seriesId: bracket.lower[0][0] },
      ];
    } else if (bracket.upper && !!bracket.upper[1]) {
      previousSeriesIds = [
        { type: 'winner', seriesId: bracket.upper[1][0] },
        { type: 'winner', seriesId: bracket.upper[1][1] },
      ];
    }

    if (bracket.grandFinal) {
      endBrackets.push(
        <div key="gf" className={classes.singleSeriesColumn}>
          <h1 className={classes.title}>Grand Final</h1>
          {bracket.grandFinal.map((series, index) => (
            <BracketItem
              key={series}
              seriesId={series}
              previousSeriesIds={previousSeriesIds}
              viewingSeriesId={currentSeries}
              showTitle={bracket.grandFinal.length > 1}
              titleIndex={index + 1}
              spoilerFree={spoilerFree}
            />
          ))}
        </div>
      );
    }

    if (bracket.thirdPlace) {
      endBrackets.push(
        <div key="third" className={classes.singleSeriesColumn}>
          <h1 className={classes.title}>Third Place</h1>
          {/* thirdPlaceItemContainer necessary to align with upper in case there's only upper + thirdplace.*/}
          <div className={classes.thirdPlaceItemContainer}>
            {bracket.thirdPlace.map((series, index) => (
              <BracketItem
                key={series}
                seriesId={series}
                previousSeriesIds={previousSeriesIds}
                viewingSeriesId={currentSeries}
                showTitle={bracket.thirdPlace.length > 1}
                titleIndex={index + 1}
                spoilerFree={spoilerFree}
              />
            ))}
          </div>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        {!!standardBrackets.length && <div className={classes.standardBracket}>{standardBrackets}</div>}
        {!!endBrackets.length && <div className={classnames({ [classes.upperOnly]: upperOnly })}>{endBrackets}</div>}
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    (state, ownProps) => ({
      bracket: getBracketById(state, ownProps.bracketId),
    }),
    {}
  )
)(BracketRenderer);
