import getIn from '../../utils/getIn';

/**
 * Takes a series and returns the inverse, a tournament or competition with a nested series.
 */
export const seriesToEvent = ({ tournament: { competition, ...fromTournament }, ...fromSeries }) =>
  !!competition
    ? // Returns a competition type object
      getModifiedCompetition({
        ...competition,
        fromTournament,
        fromSeries,
      })
    : // Returns a tournament type object
      {
        ...fromTournament,
        fromSeries,
      };

export const seriesToCompetition = aSeries =>
  !!getIn(['tournament', 'competition', 'id'])(aSeries) && seriesToEvent(aSeries);

const sortBySeriesTime = (a, b) => a.fromSeries.start.seconds - b.fromSeries.start.seconds;

/**
 * Sorting logic will have manually set isFeatured items first, then sorted by favorite games,
 *  then sorted by tier, and finally sorted by event start date.
 */
export const sortEvents = preferredGameIds => (a, b) => {
  if (a.isFeatured && !b.isFeatured) return -1; // `a` comes first
  if (b.isFeatured && !a.isFeatured) return 1; // `b` comes first

  const aPreferredIndex = preferredGameIds.indexOf(a.game);
  const bPreferredIndex = preferredGameIds.indexOf(b.game);

  if (aPreferredIndex >= 0 && bPreferredIndex === -1) {
    return -1;
  }

  if (aPreferredIndex === -1 && bPreferredIndex >= 0) {
    return 1;
  }

  // If the game is the same, skip index comparison.
  if (aPreferredIndex >= 0 && bPreferredIndex >= 0 && aPreferredIndex !== bPreferredIndex) {
    return aPreferredIndex - bPreferredIndex;
  }

  if (a.tier !== b.tier) {
    return a.tier - b.tier;
  }

  return sortBySeriesTime(a, b);
};

/**
 * Takes a raw firebase competition and adds necessary fields for frontend.
 */
export const getModifiedCompetition = competition => ({
  ...competition,
  isCompetition: true,
});
