import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { GameBadge, EventBadge, TeamBadge, TrackLink } from '../common';
import { FOLLOW_FIELDS } from '../../store/actions/user';

const useStyles = makeStyles({
  followItemContent: {
    display: 'flex',
    alignItems: 'center',
  },
  followItemLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  entityBadge: {
    borderRadius: 4,
    height: 24,
    marginRight: 6,
    width: 24,
  },
});

const TRACKING_BASE = {
  event: 'Followed Game Link Clicked',
  source: 'preferences-page',
};

const getTrackingProperties = ({ entityField, entity }) => {
  switch (entityField) {
    case FOLLOW_FIELDS.GAME_IDS: {
      const { abbr, id } = entity;

      return {
        ...TRACKING_BASE,
        eventProperties: {
          game_abbr: abbr,
          game_id: id,
        },
        target: 'game-page',
      };
    }
    case FOLLOW_FIELDS.TEAM_IDS: {
      const { id, game: gameId, name, shortName, slug } = entity;

      return {
        ...TRACKING_BASE,
        eventProperties: {
          team_id: id,
          team_name: name,
          team_shortname: shortName,
          team_slug: slug,
          team_game_id: gameId,
        },
        target: 'team-page',
      };
    }
    case FOLLOW_FIELDS.TOURNAMENT_IDS: {
      const { id, game: gameId, title, tier, slug } = entity;

      return {
        ...TRACKING_BASE,
        eventProperties: {
          event_id: id,
          event_title: title,
          event_tier: tier,
          event_slug: slug,
          event_game_id: gameId,
        },
        target: 'event-page',
      };
    }
    default:
      throw new Error(`entityField ${entityField} not yet implemented`);
  }
};

const getEntityBadge = ({ entityField, entity, track, className }) => {
  switch (entityField) {
    case FOLLOW_FIELDS.GAME_IDS: {
      return <GameBadge elevation={0} game={entity} className={className} track={track} />;
    }
    case FOLLOW_FIELDS.TEAM_IDS: {
      return <TeamBadge elevation={0} team={entity} className={className} track={track} />;
    }
    case FOLLOW_FIELDS.TOURNAMENT_IDS: {
      return <EventBadge elevation={0} event={entity} className={className} track={track} />;
    }
    default:
      return null;
  }
};

const FollowingEntityLink = ({ className, entity, entityField, children, to }) => {
  const classes = useStyles();

  const track = getTrackingProperties({ entityField, entity });
  const EntityBadge = getEntityBadge({ entityField, entity, track, className: classes.entityBadge });
  return (
    <div className={`${classes.followItemContent} ${className}`}>
      {EntityBadge}
      <TrackLink className={classes.followItemLink} to={to} track={track}>
        {children}
      </TrackLink>
    </div>
  );
};

export default FollowingEntityLink;
