import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { BodyText } from '../../common';
import NotificationFollowingItem from './NotificationFollowingItem';
import { useFollowingEntityMultiSubscribe } from '../../../hooks';

import { FOLLOW_FIELDS } from '../../../store/actions/user';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: 8,
  },
  [theme.breakpoints.down('sm')]: {},
}));

const HEADERS = {
  [FOLLOW_FIELDS.TOURNAMENT_IDS]: 'Events',
  [FOLLOW_FIELDS.TEAM_IDS]: 'Teams',
};

const EntityFollowingNotifications = ({ entityField }) => {
  const classes = useStyles();
  const entities = useFollowingEntityMultiSubscribe(entityField);

  if (!entities?.length) {
    return null;
  }

  return (
    <div>
      <BodyText el="div" className={classes.header}>
        {HEADERS[entityField]}
      </BodyText>
      {entities.map(entity => (
        <NotificationFollowingItem key={entity.id} entity={entity} entityField={entityField} />
      ))}
    </div>
  );
};

export default EntityFollowingNotifications;
