import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const style = () => ({
  twitterButton: {
    alignItems: 'center',
    height: 30,
    padding: '0 10px',
    backgroundColor: '#1da1f2',
    border: '1px solid',
    borderColor: 'transparent',
    borderRadius: '4px',
    color: '#fff',
    display: 'flex',
    fontSize: '12px',
    lineHeight: '1',
    transition: '.1s ease-in',
    '&:hover:enabled': {
      backgroundColor: '#1b95e0',
      borderColor: '#1b95e0',
      cursor: 'pointer',
    },
    '&:focus:enabled': {
      backgroundColor: '#1b95e0',
      borderColor: '#45aae9',
      boxShadow: '#1b95e0',
      outline: 'none',
    },
    '&:disabled': {
      backgroundColor: 'hsla(0,0%,100%,.05)',
      borderColor: 'transparent',
      boxShadow: 'none',
      color: 'hsla(0,0%,100%,.2)',
      cursor: 'not-allowed',
    },
  },
  buttonIcon: {
    height: '30px',
    marginRight: '4px',
  },
});

export default withStyles(style)(({ classes, onClick, withIcon, label, disabled }) => {
  return (
    <button className={classes.twitterButton} onClick={onClick} disabled={disabled}>
      {withIcon && <img className={classes.buttonIcon} src={'/icons/twitterLogo.svg'} alt="" />}
      {label}
    </button>
  );
});
