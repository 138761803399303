import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import TwitterButton from './TwitterButton';
import { twitterAuthSuccess } from '../../../../store/actions/twitter';
import { getParseUrl } from '../../../../utils/api';

const styles = {
  centeredContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
};

/**
 * Manage unauthenticated state, provide auth button.
 */
class Auth extends Component {
  componentDidMount() {
    const { twitterAuthSuccess } = this.props;

    // Set callback function for popup to invoke in ProviderAuthScreen.
    window.twitterAuthSuccess = () => twitterAuthSuccess();
  }

  authenticate = () => {
    // Open auth pop-up.
    window.open(getParseUrl('connect/twitter'), 'twitter_login', 'width=700,height=700');
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.centeredContainer}>
        <TwitterButton withIcon label="Login with Twitter" onClick={this.authenticate} />
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    state => ({}),
    { twitterAuthSuccess }
  )
)(Auth);
