import classnames from 'classnames';
import React from 'react';
import { withStyles } from '@material-ui/styles';

import { CarouselContainer } from '../common';
import VideoCardStyles from '../VideoSwatch/VideoCardStyles';
import { ShimmerSpan, ShimmerDiv } from '../common/Shimmer';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.background.primary,
  },
  placeholderCard: {
    ...VideoCardStyles.placeholderCard,
    margin: 12,
    width: '256px !important',
    height: 304,
    background: theme.palette.shimmer.primary,
  },
  [theme.breakpoints.down('sm')]: {
    placeholderCard: {
      width: '300px !important',
      height: 214,
      '& > span': {
        '&:nth-of-type(1)': {
          width: '70%',
        },
      },
    },
  },
});

const LoadingCarousel = ({ classes, className, dark, isMobileSize }) => (
  <div
    className={classnames({
      [className]: true,
      [classes.root]: true,
      dark,
    })}
  >
    <CarouselContainer notLoaded>
      {Array.from(Array(isMobileSize ? 1 : 3)).map((_, index) => (
        <div key={index} className={classes.placeholderCard}>
          <ShimmerDiv />
          <ShimmerSpan />
          <ShimmerSpan />
        </div>
      ))}
    </CarouselContainer>
  </div>
);

export default withStyles(styles)(LoadingCarousel);
