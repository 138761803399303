export const STORE_STREAM_CARD_VOLUME = 'STORE_STREAM_CARD_VOLUME';
export const LOAD_STREAM_CARD_VOLUME = 'LOAD_STREAM_CARD_VOLUME';
export const LOAD_EVENT_PAGE_VOLUME = 'LOAD_EVENT_PAGE_VOLUME';
export const STORE_EVENT_PAGE_VOLUME = 'STORE_EVENT_PAGE_VOLUME';

export const storeStreamCardVolume = (volume, id, platformType) => ({
  type: STORE_STREAM_CARD_VOLUME,
  volume,
  id,
  platformType,
});

export const storeEventPageVolume = volume => ({
  type: STORE_EVENT_PAGE_VOLUME,
  volume,
});

export const loadStreamCardVolume = () => ({
  type: LOAD_STREAM_CARD_VOLUME,
});

export const loadEventPageVolume = () => ({
  type: LOAD_EVENT_PAGE_VOLUME,
});
