import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import TeamRosters from '../MatchInfo/TeamRosters';
import TeamsScore from '../MatchInfo/TeamsScore';
import StandingsDialogButton from '../Brackets/StandingsDialogButton';

const useStyles = makeStyles(theme => ({
  infoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    minHeight: 290,
    overflowX: 'auto',
    overflowY: 'hidden',
    overflow: 'hidden !important',
    background: '#070707',
    paddingTop: 15,
    paddingBottom: 30,
    width: '100%',
    ' & > *': {
      alignSelf: 'stretch', // fills the height of container so all siblings have the same height.
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 50,
      paddingRight: 50,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    ' & > *:not(:last-child)': {
      borderRight: '3px solid #282828',
    },
    '&:empty': {
      display: 'none',
    },
  },
  moreThanFourTeamsContainer: {
    justifyContent: 'normal',
    ' & > *': {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  [theme.breakpoints.down('sm')]: {
    infoContainer: {
      flexDirection: 'column',
      margin: 'unset',
      ' & > *': {
        flexDirection: 'column',
        alignItems: 'center',
        borderRight: 'none !important',
      },
    },
    teamsScoreContainer: {
      order: -1, //This will set the teamScore to the first position in the container
      marginTop: 20,
    },
  },
}));

const EventStandings = ({ aSeries, participants, standings }) => {
  const classes = useStyles();
  const shouldRender = participants.length || Boolean(standings) || aSeries.tournament.bracketLink;

  if (!shouldRender) return null;

  return (
    <div
      className={classNames({
        [classes.infoContainer]: true,
        [classes.moreThanFourTeamsContainer]: participants.length > 4,
      })}
    >
      {participants.length && participants.every(participant => participant.players && participant.players.length) && (
        <div>
          <TeamRosters seriesId={aSeries.id} />
        </div>
      )}
      {participants.length && participants.every(participant => participant.name && participant.name !== 'TBD') && (
        <div className={classes.teamsScoreContainer}>
          <TeamsScore seriesId={aSeries.id} />
        </div>
      )}
      {(standings || aSeries.tournament.bracketLink) && (
        <div>
          <StandingsDialogButton
            standingsId={aSeries.tournament.id}
            bracketLink={aSeries.tournament.bracketLink}
            currentSeries={aSeries.id}
          />
        </div>
      )}
    </div>
  );
};

export default EventStandings;
