// These values are the key in Juked markdown syntax with form `{{ <command>: <arg> }}`
export const COMMANDS = {
  EMBED: 'embed',
};

// Build commands regex for better performance in matchCommand fn calls.
const getCommandRe = command => new RegExp(`^{{ *(${command} *:.*) *}}`, 'i');

// Generates a mapping of COMMANDS keys to regex for commands like: `{{ embed: <arg> }}`
// ex: { ..., embed: '{{ embed: <arg> }}' }
const COMMANDS_RE = Object.keys(COMMANDS).reduce(
  (accu, key) => ({ ...accu, [COMMANDS[key]]: getCommandRe(COMMANDS[key]) }),
  {}
);

const PARSE_RE = new RegExp(`^{{ *(${Object.values(COMMANDS).join('|')}) *: *(.*) *}}$`);

/**
 * Matches commands like: `{{ embed: <arg> }}` for use in markdown `rule.parse`.
 * @param {str} command
 */
export const matchCommand = command => str => {
  return COMMANDS_RE[command].exec(str);
};

/**
 * Parses commands like: `{{ embed: <arg> }}` and returns [<command>, <arg>]
 * @param {str} command
 */
export const parseCommand = str => {
  const [, command, arg] = str.match(PARSE_RE) || [];
  return [command && command.trim(), arg && arg.trim()];
};

export const sanitizeUrl = url => {
  if (url == null) {
    return null;
  }
  try {
    var prot = decodeURIComponent(url)
      .replace(/[^A-Za-z0-9/:]/g, '')
      .toLowerCase();

    // eslint-disable-next-line
    if (prot.indexOf('javascript:') === 0 || prot.indexOf('vbscript:') === 0 || prot.indexOf('data:') === 0) {
      return null;
    }
  } catch (e) {
    // decodeURIComponent sometimes throws a URIError
    // See `decodeURIComponent('a%AFc');`
    // http://stackoverflow.com/questions/9064536/javascript-decodeuricomponent-malformed-uri-exception
    return null;
  }
  return url;
};

export const getUUID = count =>
  [...Array(count)].map(_ => ((Math.random() * 36) | 0).toString(36)).join``.toUpperCase();
