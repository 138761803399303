import { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { mergeQueryToLocation } from '../components/common/QueryLink';

export default parameterName => {
  const location = useLocation();
  const historyRef = useRef(useHistory());
  const locationRef = useRef(location);
  // We are using location instead of locationRef.current here because using locationRef.current does
  // not allow us to retrieve updated parameter.
  const initialSelectedParameter = queryString.parse(location.search, { arrayFormat: 'bracket' })[parameterName];
  const [selectedParameter, setSelectedParameter] = useState(initialSelectedParameter || []);

  useEffect(() => {
    historyRef.current.push(
      mergeQueryToLocation(locationRef.current, {
        [parameterName]: Boolean(selectedParameter.length) ? selectedParameter : null,
      })
    );
    // historyRef and locationRef are passed so that eslint does not show a warning
    // and change in history and location does not cause additional re-renders.
  }, [historyRef, locationRef, parameterName, selectedParameter]);
  return [selectedParameter, setSelectedParameter];
};
