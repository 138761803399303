export const EMAIL_OPTIONS = {
  ALL: 'all-notifications',
  NEWSLETTER: 'newsletter',
  GENERAL: 'general-product',
};

export const getEmailOptionFields = ({ allNotificationsOptIn, newsletterOptIn, generalProductOptIn }) => ({
  [EMAIL_OPTIONS.ALL]: {
    title: 'All Email Notifications',
    className: 'allNotifications',
    checked: allNotificationsOptIn,
  },
  [EMAIL_OPTIONS.NEWSLETTER]: {
    title: 'The Download',
    subtext: 'Our weekly newsletter covering the top esports to watch',
    checked: newsletterOptIn,
  },
  [EMAIL_OPTIONS.GENERAL]: {
    title: 'General product updates',
    subtext: 'Announcements about new features on Juked',
    checked: generalProductOptIn,
  },
});
