import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { IoMdClose } from 'react-icons/io';

import { TYPE_PLATFORM_MAP } from '../../../store/reducers/video';

import StreamDropdownIcon from './StreamDropdownIcon';
import { TrackSpan } from '../Track';

const useStyles = makeStyles(theme => ({
  rowContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    width: 56,
    height: 28,
    borderRadius: 4,
    fontSize: 10,
    lineHeight: '16px',
    backgroundColor: theme.darkCharcoal,
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    marginLeft: 8,
    outline: 'none',
    '&.open': {
      display: 'flex',
      alignItems: 'center',
      width: 28,
    },
    '& > svg': {
      fontSize: 16,
    },
  },
  providerIcon: {
    display: 'flex',
    marginRight: 8,
  },
  firstItem: {
    width: '38%',
    '&.viewerCount': {
      textAlign: 'end',
    },
  },
  secondItem: {
    width: '45%',
    textAlign: 'end',
  },
  thirdItem: {
    width: 20,
  },
}));

const StreamsDropdownItem = ({ className, series, toggleDropdown, open, setSelectedStream, stream, closeButton }) => {
  const classes = useStyles();

  const { platformType, platformId, metadata, online, viewerCount } = stream || {};
  const streamId = `${TYPE_PLATFORM_MAP[platformType]}:${platformId}`;

  const track = {
    event: 'Multi-Stream Selected',
    eventProperties: {
      series_id: series.id,
      stream_id: streamId,
      stream_language: metadata.language,
      channel_id: metadata.channelId,
    },
    source: 'streams-dropdown',
    target: 'stream',
  };

  return (
    <TrackSpan
      className={classnames({
        [className]: true,
        disable: !online,
      })}
      key={platformId}
      onClick={() => {
        if (!closeButton) {
          setSelectedStream({
            streamId,
            seriesId: series.id,
          });
        }

        toggleDropdown();
      }}
      track={track}
    >
      <span className={classes.providerIcon}>
        <StreamDropdownIcon className={classes.providerIcon} provider={platformType} />
      </span>
      <span className={classes.rowContainer}>
        <span className={classes.firstItem}>{platformId}</span>
        {open && (
          <span className={classes.secondItem}>
            {viewerCount?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || 0}
          </span>
        )}
        {closeButton ? (
          <button
            className={classnames({
              [classes.closeButton]: true,
              open,
            })}
            onClick={() => toggleDropdown()}
          >
            {open ? <IoMdClose /> : 'SWITCH'}
          </button>
        ) : (
          <>
            <span className={`${classes.firstItem} viewerCount`}>
              {viewerCount?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || 0}
            </span>
            <span className={classes.thirdItem}>{metadata.language}</span>
          </>
        )}
      </span>
    </TrackSpan>
  );
};

export default StreamsDropdownItem;
