import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { compose } from 'recompose';

import { ONE_MINUTE, getGameById } from '../../store/selectors/gameData';

import ItemStyles from './ItemStyles';
import ItemParticipants from './ItemParticipants';
import getFormattedTime from '../../utils/getFormattedTime';
import TournamentImage from '../Images/TournamentImage';

const timeClasses = {
  width: '13%',
  order: -1,
  '.matchView &': {
    width: '15%',
  },
};

const styles = theme => ({
  ...ItemStyles,
  root: {
    ...ItemStyles.root,
    textDecoration: 'none',
    color: theme.palette.text.primary,
    background: theme.palette.background.secondary,
    overflow: 'visible',
  },
  startTimeContainer: {
    ...timeClasses,
    fontSize: '1.5em',
    fontWeight: 600,
    lineHeight: '25px',
    '& span': {
      ' & > span': {
        fontSize: '0.7em',
        verticalAlign: 'top',
        textTransform: 'uppercase',
        marginLeft: 5,
      },
    },
    position: 'relative',
  },
  seriesInfo: {
    ...ItemStyles.seriesInfo,
    width: '33%',
    paddingRight: 10,
    boSizing: 'border-box',
    '.matchView &': {
      width: '31%',
      marginLeft: 'auto',
    },
  },
  participantsContainer: {
    width: '29%',
  },
  endedLabel: {
    width: '15%',
    textAlign: 'center',
  },
});

class PastItem extends Component {
  render() {
    const {
      classes,
      series: { tournament, participants },
      series,
      game,
    } = this.props;

    const startDate = new Date(Math.floor(series.start.toDate().getTime() / ONE_MINUTE) * ONE_MINUTE); // trim off seconds.

    return (
      <div className={classes.root}>
        <div className={classes.startTimeContainer}>{getFormattedTime(startDate)}</div>
        <div className={classes.gameName}>{game && game.abbr}</div>
        <div className={classes.seriesInfo}>
          <TournamentImage tournament={tournament} />
          <div className={classes.seriesInfoText}>
            <div className={classes.tournamentName}>{tournament.title}</div>
            <span className={classes.seriesTitle}>{series.title}</span>
          </div>
        </div>
        {!!participants.length && participants.every(participant => participant.name !== 'TBD') && (
          <ItemParticipants className={classes.participantsContainer} participants={participants} />
        )}
        <div className={classes.endedLabel}>Ended</div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect((state, ownProps) => ({
    game: getGameById(state, ownProps.series.game),
  }))
)(PastItem);
