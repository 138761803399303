/** Any helper functions used by selectors but not directly by components should be here  */

export const groupSeriesByTournaments = series => {
  // move series under the same tournament to be next to each other in an object, starting from the most-viewed
  const tournamentOrder = [];
  series = series.reduce((result, item) => {
    const tourn = item.tournament.id;
    if (!tournamentOrder.includes(tourn)) {
      tournamentOrder.push(tourn);
    }

    return {
      ...result,
      [tourn]: [...(result[tourn] || []), item],
    };
  }, {});
  // then re-combine into array
  series = tournamentOrder.reduce((arr, key) => {
    return arr.concat(series[key]);
  }, []);
  return series;
};

// Returns a generated selector handler function based on slice length.
export const selectorForFilteringGivenSeries = sliceLength => (loaded, series, gameFilters) => {
  if (!loaded) return [];

  if (!Array.isArray(series)) {
    series = Object.values(series);
  }
  // TODO: remove cast once IDs are converted to strings in Firestore
  series = !!gameFilters.length ? series.filter(aSeries => gameFilters.includes(`${aSeries.game}`)) : series;
  return sliceLength ? series.slice(0, sliceLength) : series;
};

export const isEqualOrArrayEqual = (a, b) => {
  if (a === b) {
    return true;
  } else {
    if (Array.isArray(a) && Array.isArray(b) && a.length === b.length) {
      for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
          return false;
        }
      }
      return true;
    }
  }

  return false;
};

export const isPartOfName = (teamOrPlayer, searchedValue) => {
  const { name, firstName, lastName, nickName, realName, shortName } = teamOrPlayer;

  // check if any of these names include the search term, if it is defined.
  return [name, firstName, lastName, nickName, realName, shortName].some(
    aName => aName && aName.toLowerCase().indexOf(searchedValue) >= 0
  );
};

export const getSeriesByBracket = (series, bracket) => {
  //Make an array of all the id of the upperSeries
  const upperSeries = !!bracket.upper ? Object.values(bracket.upper).flat() : [];
  //Make an array of all the id of the lowerSeries
  const lowerSeries = !!bracket.lower ? Object.values(bracket.lower).flat() : [];
  //Make an array of all the id of grandFinalSeries
  const grandFinalSeries = !!bracket.grandFinal ? Object.values(bracket.grandFinal).flat() : [];
  //Make an array of all the id of thirdPlaceSeries
  const thirdPlaceSeries = !!bracket.thirdPlace ? Object.values(bracket.thirdPlace).flat() : [];
  //Concatenate all series
  const allSeriesIds = [].concat(upperSeries, lowerSeries, grandFinalSeries, thirdPlaceSeries);

  return allSeriesIds.reduce((acc, id) => (!!series[id] ? [...acc, series[id]] : acc), []);
};

export const getSlugFromAbbr = abbr => abbr.replace(/[\W_]+/g, '').toLowerCase();

export const isSubstageStandingsValid = (substage, series, brackets) => {
  if (substage.type === 'standing') {
    // If it's a standings-type substage but standings is empty
    if (substage.standings.length === 0) {
      return false;
    }
  } else if (substage.type === 'bracket') {
    // If it's a bracket-type substage but bracket is not found
    const bracket = brackets[substage.id];
    if (!bracket) {
      return false;
    }
    const allSeries = getSeriesByBracket(series, bracket);
    return allSeries.some(series => !!series.participants && !!series.participants.length);
  }

  // Add other validations here if future issues are spotted..

  return true;
};

// --- upcoming-series related ---
const START_CUTOFF_MINUTES = 24 * 60 * 60;

const isStartTimeTooOld = series => {
  if (series.isLongerThan24Hours && !!series.end) {
    // Require end date for longer than a day series.
    return false;
  }
  if (!series.start) return false;
  const nowSeconds = Math.floor(new Date().getTime() / 1000);
  return series.start.seconds < nowSeconds - START_CUTOFF_MINUTES;
};

export const isEnded = series => {
  if (!series.start) return false;
  const nowSeconds = Math.floor(new Date().getTime() / 1000);
  // make sure end is set in the past, and start is not more than 24 hours ago
  return (!!series.end && series.end.seconds < nowSeconds) || isStartTimeTooOld(series);
};

export const isStarted = series => {
  if (!series.start) return false;
  return series.start.toDate() < new Date();
};

export const filterSeriesByViewMode = (seriesArr, isMatchView, count, singleAppearance) => {
  if (isMatchView) {
    if (count) {
      return seriesArr.slice(0, count);
    } else {
      return seriesArr;
    }
  } else {
    // Find 20 series, 1 per tournament per day.
    const eventViewSeries = [];
    const addedTournaments = new Set();
    for (let i = 0; i < seriesArr.length; i++) {
      const aSeries = seriesArr[i];
      const tournamentKey = aSeries.tournament.id;
      const tournamentPerDayKey = `${aSeries.tournament.id}:${aSeries.start.toDate().toDateString()}`;
      const key = singleAppearance ? tournamentKey : tournamentPerDayKey;
      // If we haven't added a series from this tournament on this day
      if (!addedTournaments.has(key)) {
        eventViewSeries.push(aSeries);
        // Add to the list of already-taken-care-of tournament-per-day keys
        addedTournaments.add(key);
        if (count && addedTournaments.size >= count) {
          break;
        }
      }
    }
    return eventViewSeries;
  }
};

export const sortAndFilterStartedSeries = (upcomingSeries, liveSeries) => {
  // Sort series by start time and then return the first one that is not currently LIVE.
  // NOTE: Do the `isEnded` check, because they won't be cleaned from the userNotifications collection.
  const liveSeriesIds = (liveSeries || []).map(series => series.id);
  return upcomingSeries
    .sort((a, b) => a.start.seconds - b.start.seconds)
    .find(series => !liveSeriesIds.includes(series.id) && !isEnded(series) && !isStarted(series));
};

export const isUserFollowingAnyEntity = preferredEntityIdsMap => {
  const { preferredGameIds, preferredTeamIds, preferredTournamentIds, preferredCompetitionIds } = preferredEntityIdsMap;
  return (
    preferredGameIds?.length ||
    preferredTeamIds?.length ||
    preferredTournamentIds?.length ||
    preferredCompetitionIds?.length
  );
};

export const isUserFollowingAnyGivenEntity = props => {
  const {
    preferredGameIds,
    preferredTeamIds,
    preferredTournamentIds,
    preferredCompetitionIds,
    game,
    gameIds,
    tournament,
    teams,
    competition,
  } = props;

  return (
    (preferredGameIds || []).includes(game) ||
    (preferredGameIds || []).some(id => gameIds?.includes(id)) ||
    (preferredTournamentIds || []).includes(tournament) ||
    (preferredTeamIds || []).some(id => teams?.includes(id)) ||
    (preferredCompetitionIds || []).includes(competition)
  );
};
