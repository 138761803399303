import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/messaging';
// To minimize size, import other firebase modules as necessary.
//
// e.x.:
//  import 'firebase/auth';

// This config is intended for client-side, so it's okay to be directly in the repo.

let config;
let vapidKey;

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  config = {
    apiKey: 'AIzaSyAE5n6WT0Q9tfCHYf7gc5Y95mhieD2EERo',
    authDomain: 'juked-d5e67.firebaseapp.com',
    databaseURL: 'https://juked-d5e67.firebaseio.com',
    projectId: 'juked-d5e67',
    storageBucket: 'juked-d5e67.appspot.com',
    messagingSenderId: '216114315876',
    appId: '1:216114315876:web:1830da8e6e11e335a51571',
  };
  vapidKey = 'BG1Nz0hK2BFJ-UaOPvyfnElfAUdu-N-D8Kcjvhd5UU4NkmiI6CDwJDcVz2MS-P7fGJ-SbXGqTR_aOT8VJvLhAe8';
} else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
  config = {
    apiKey: 'AIzaSyBrIzdzJ0Gn4S6EWuZKOtG_7j3orTKlSGM',
    authDomain: 'juked-staging.firebaseapp.com',
    databaseURL: 'https://juked-staging.firebaseio.com',
    projectId: 'juked-staging',
    storageBucket: 'juked-staging.appspot.com',
    messagingSenderId: '41938468481',
    appId: '1:41938468481:web:7d19ce8653cfed6aea1780',
  };
  vapidKey = 'BJO9jrHe9dxSyKobOiK0fw2D0UTLttf5Vgv6dnKXnefvhtLTMibD2I0rGt6ntNTLAhZuXTYxqCbmSSyNMrDvep8';
} else {
  config = {
    apiKey: 'AIzaSyBBHO-FMhlLOlnqrFmmwgC1hZQ4U1-dxbQ',
    authDomain: 'juked-f6669.firebaseapp.com',
    databaseURL: 'https://juked-f6669.firebaseio.com',
    projectId: 'juked-f6669',
    storageBucket: 'juked-f6669.appspot.com',
    messagingSenderId: '850560165334',
    appId: '1:850560165334:web:3dc135932a4407544ff077',
  };
  vapidKey = 'BJbeSB9s7VY5s4jkGOC1jy4uS58qyQQSso70S308x4ST8_dDD7KHj3P2VC0IXsW1SouJBBunaptPEldIOZ53I7Q';
}

firebase.initializeApp(config);

export default firebase;

export const auth = firebase.auth;
export const functions = firebase.functions();
export const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;
if (messaging) {
  messaging.usePublicVapidKey(vapidKey);
}

let dbOrProxy;
if (process.env.NODE_ENV !== 'test') {
  dbOrProxy = firebase.firestore();
} else {
  // TODO: make a mock firestore that can actually return stuff in tests.
  const mockFirestore = new Proxy(
    {},
    {
      get(_, propKey) {
        // db...get() expected to return a promise
        if (propKey === 'get') {
          return () => Promise.resolve({ exists: false });
        }

        // db...onSnapshot() expected to return a function
        if (propKey === 'onSnapshot') {
          return () => () => {};
        }

        return () => mockFirestore;
      },
    }
  );
  dbOrProxy = mockFirestore;
}

export const db = dbOrProxy;
