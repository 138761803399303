import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { PLATFORM_TYPES } from '../../../store/reducers/video';

const useStyles = makeStyles({
  chat: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
});

const StreamChat = ({ channel, platformType }) => {
  const classes = useStyles();
  return (
    <iframe
      className={classes.chat}
      title={channel}
      frameBorder="0"
      scrolling="no"
      id="chat_embed"
      src={
        platformType === PLATFORM_TYPES.TWITCH
          ? `https://www.twitch.tv/embed/${channel}/chat?darkpopout&parent=${window.location.hostname}`
          : `https://gaming.youtube.com/live_chat?v=${channel}&embed_domain=${window.location.hostname}`
        // Use gaming.youtube.com for darkmode.
      }
    />
  );
};

export default StreamChat;
