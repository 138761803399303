import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import AllViewUpcomingItem from './AllViewUpcomingItem';
import MobileAllViewItem from './MobileAllViewItem';

export default ({ classes, ...props }) => {
  const matchesDesktop = useMediaQuery('(min-width:960px)'); // SM Breakpoint

  return matchesDesktop ? <AllViewUpcomingItem {...props} /> : <MobileAllViewItem {...props} />;
};
