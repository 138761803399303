import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackUser } from '../store/actions/uiStates';

const UserTracker = () => {
  const adRef = useRef(null);
  const dispatch = useDispatch();
  const { uid, isSignedIn, fetched } = useSelector(({ user }) => user);

  useEffect(() => {
    const isLoadingUser = isSignedIn === undefined || (isSignedIn === true && fetched !== true);
    if (!isLoadingUser) {
      const isAdBlocked = adRef && adRef.current.offsetHeight === 0;
      dispatch(trackUser(uid, isSignedIn, isAdBlocked));
    }
  }, [dispatch, uid, isSignedIn, fetched]);

  return (
    <div
      ref={adRef}
      className="adBanner"
      style={{ height: '1px', width: '1px', visiblity: 'none', pointerEvents: 'none' }}
    />
  );
};

export default UserTracker;
