import { Component } from 'react';
import { connect } from 'react-redux';

import { toggleGameFilter } from '../store/actions/uiStates';
import { getShownGames } from '../store/selectors/gameData';

// Any screens that *could* start with either / or /:slug should be in here instead of in <App>.
class GameFilteredScreens extends Component {
  componentDidMount() {
    this.updateGameFilter();
  }

  componentDidUpdate(prev) {
    const {
      match: {
        params: { slug },
      },
      gameList,
    } = this.props;

    if (slug !== prev.match.params.slug || gameList.length !== prev.gameList.length) {
      this.updateGameFilter();
    }
  }

  updateGameFilter() {
    const {
      match: {
        params: { slug },
      },
      toggleGameFilter,
      gameList,
    } = this.props;

    if (!gameList.length) return;

    // if root path
    if (!slug) {
      toggleGameFilter();
    } else {
      // At this point since the slug is checked by regex in <App> already, it should guarantee a match with one of the existing games in gameList.
      const gameId = gameList.find(game => game.slug === slug).id;
      toggleGameFilter(gameId);
    }
  }

  render() {
    return null;
  }
}

export default connect(
  state => ({
    gameList: getShownGames(state),
  }),
  { toggleGameFilter }
)(GameFilteredScreens);
