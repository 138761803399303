import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import StreamDropdownItem from './StreamDropdownItem';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    display: 'flex',
    '&.isEventMatchScreen': {
      position: 'unset',
    },
  },
  dropdown: {
    position: 'absolute',
    minWidth: 260,
    borderRadius: '4px',
    border: '0',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
    left: -80,
    top: 20,
    zIndex: '999999',
    margin: '3px 0 0',
    backgroundColor: theme.palette.background.primary,
    display: 'none',
    '.open &': {
      display: 'block',
    },
    '.isEventMatchScreen &': {
      right: 20,
      left: 'unset',
      top: 18,
      display: 'block',
      backgroundColor: '#0B0C0C',
    },
  },
  dropdownItem: {
    cursor: 'pointer',
    color: theme.palette.text.quinary,
    padding: '8px 12px',
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    lineHeight: '16px',
    margin: 'unset',
    '&.disable': {
      pointerEvents: 'none',
      color: 'lightgrey',
      '& svg': {
        fill: 'lightgrey',
      },
    },
    '&:hover': {
      background: theme.palette.background.tertiary,
    },
    '&.defaultStream': {
      '&:hover': {
        background: 'none',
      },
      '.isEventMatchScreen &': {
        borderBottom: 'none',
      },
      '.open &': {
        borderBottom: `1px solid ${theme.smokedDarkGrey} !important`,
      },
    },
    '&:not(:first-child)': {
      '.isEventMatchScreen &': {
        display: 'none',
      },
      '.isEventMatchScreen.open &': {
        display: 'flex',
      },
    },
  },
  rowContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    width: 56,
    height: 28,
    borderRadius: 4,
    fontSize: 10,
    lineHeight: '16px',
    backgroundColor: '#131414',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
  },
}));

const StreamsDropdown = ({
  series,
  toggleDropdown,
  open,
  TooltipComponent,
  isEventMatchScreen,
  selectedStream,
  setSelectedStream,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classnames({
        [classes.root]: true,
        isEventMatchScreen,
        open,
      })}
    >
      <div className={classes.dropdown}>
        <StreamDropdownItem
          className={classnames({
            [classes.dropdownItem]: true,
            defaultStream: true,
          })}
          series={series}
          toggleDropdown={toggleDropdown}
          stream={selectedStream}
          open={open}
          closeButton
        />

        {Object.values(series?.streams || {})
          .sort((a, b) => b?.viewerCount - a?.viewerCount)
          .map((stream, index) => {
            const { platformId } = stream || {};
            const shouldRender = selectedStream?.platformId !== platformId;
            return shouldRender ? (
              <StreamDropdownItem
                className={classes.dropdownItem}
                key={index}
                series={series}
                stream={stream}
                setSelectedStream={setSelectedStream}
                toggleDropdown={toggleDropdown}
              />
            ) : null;
          })}
      </div>
      {TooltipComponent}
    </div>
  );
};

export default StreamsDropdown;
