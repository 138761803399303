import React from 'react';
import { FaTwitter, FaYoutube, FaInstagram, FaFacebook, FaDiscord, FaTag } from 'react-icons/fa';
import { MdHome } from 'react-icons/md';
import { makeStyles } from '@material-ui/styles';

import { TrackA } from '../common';

const useStyles = makeStyles(theme => ({
  icons: {
    textDecoration: 'none',
    margin: 8,
    display: 'flex',
    height: 32,
    width: 32,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.smokedDarkGrey,
    '& svg': {
      fill: 'white',
    },
    [theme.breakpoints.down('sm')]: {
      height: 30,
      width: 30,
    },
  },
  twitter: {
    fontSize: 20,
    background: '#00AAEE',
  },
  youtube: {
    fontSize: 22,
    background: '#E62217',
  },
  instagram: {
    fontSize: 24,
    background: '#B70C6C',
  },
  facebook: {
    fontSize: 22,
    background: '#3C5898',
  },
  discord: {
    fontSize: 22,
    background: '#7389DA',
  },
  teamStore: {
    fontSize: 14,
  },
  linksContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    marginRight: 16,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginRight: 0,
      marginLeft: 'unset',
      '& > *:last-child': {
        marginRight: 0,
      },
    },
  },
}));

const SOCIALNETWORKS = {
  twitter: 'twitter',
  youtube: 'youtube',
  instagram: 'instagram',
  facebook: 'facebook',
  discord: 'discord',
};

const OTHER_LINKS = {
  teamStore: 'teamStore',
  tournamentUrl: 'tournamentUrl',
};

const HEADER_LINKS = {
  ...SOCIALNETWORKS,
  // Property order will determine the icon rendering order.
  ...OTHER_LINKS,
};

const ComponentSVGs = {
  [SOCIALNETWORKS.twitter]: FaTwitter,
  [SOCIALNETWORKS.youtube]: FaYoutube,
  [SOCIALNETWORKS.instagram]: FaInstagram,
  [SOCIALNETWORKS.facebook]: FaFacebook,
  [SOCIALNETWORKS.discord]: FaDiscord,
  [OTHER_LINKS.tournamentUrl]: MdHome,
  [OTHER_LINKS.teamStore]: FaTag,
};

const HeaderIconButtons = ({ links, source }) => {
  const classes = useStyles();
  return (
    <div className={classes.linksContainer}>
      {links &&
        Object.keys(HEADER_LINKS).map(link => {
          if (!links[link]) return null;
          const FaIcon = ComponentSVGs[link];

          return (
            <TrackA
              className={`${classes.icons} ${classes[link]}`}
              href={links[link].includes('https://') ? links[link] : `https://${link}.com/${links[link]}`}
              key={link}
              rel="noopener noreferrer"
              target="_blank"
              track={{
                event: 'Social Link Clicked',
                source,
                target: `${link}-website`,
              }}
            >
              <FaIcon />
            </TrackA>
          );
        })}
    </div>
  );
};

export default HeaderIconButtons;
