import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import StandingsDialog from './StandingsDialog';
import { trackEvent } from '../../store/actions/uiStates';

const styles = () => ({
  bracketsContainer: {
    paddingRight: 30,
    marginTop: 55,
    marginLeft: 20,
    cursor: 'pointer',
    width: 280,
    position: 'relative',
  },
  bracketsContainerText: {
    color: 'white',
    top: 40,
    left: 30,
    textAlign: 'center',
    position: 'absolute',
    fontSize: 30,
    fontWeight: 900,
  },
  rowBrackets: {
    display: 'flex',
    alignItems: 'center',
  },
  boxBracket: {
    backgroundColor: '#282828',
    height: 30,
    width: 120,
    margin: 10,
  },
});

class StandingsDialogButton extends Component {
  state = {
    isDialogOpen: false,
    // TODO: figure out how to auto-pick the section that current series is in.
  };

  handleDialogOpen = () => {
    const { standingsId, trackEvent, bracketLink } = this.props;
    trackEvent('Standings Opened', { tournamentId: standingsId });
    if (!!bracketLink) {
      window.open(bracketLink, '_blank');
    } else {
      this.setState({ isDialogOpen: true });
    }
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false });
  };

  render() {
    const { classes, standingsId, currentSeries, bracketLink } = this.props;
    return (
      <Fragment>
        <div className={classes.bracketsContainer} onClick={this.handleDialogOpen}>
          <div className={classes.bracketsContainerText}>
            View Brackets <br /> & <br /> Standings
          </div>
          <div className={classes.rowBrackets}>
            <div>
              <div className={classes.boxBracket} />
              <div className={classes.boxBracket} />
            </div>
            <div>
              <div className={classes.boxBracket} />
            </div>
          </div>
          <div className={classes.rowBrackets}>
            <div>
              <div className={classes.boxBracket} />
              <div className={classes.boxBracket} />
            </div>
            <div>
              <div className={classes.boxBracket} />
            </div>
          </div>
        </div>
        {/* StandingsDialog will only show if a bracketLink is not provided for the tournament*/}
        {!bracketLink && (
          <StandingsDialog
            standingsId={standingsId}
            currentSeries={currentSeries}
            open={this.state.isDialogOpen}
            onCloseClick={this.handleDialogClose}
          />
        )}
      </Fragment>
    );
  }
}

export default compose(connect(null, { trackEvent }), withStyles(styles))(StandingsDialogButton);
