import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getGames } from '../../store/selectors/gameData';
import { getPreferredGameIds } from '../../store/selectors/user';

export const withGamePickerState = WrappedComponent => {
  class GamePickerState extends Component {
    state = {
      selectedGameIds: this.props.preferredGameIds || [],
    };

    getSelectedGames = () => this.state.selectedGameIds.map(id => this.props.games[id]).filter(game => Boolean(game));

    handleAddGame = gameId => {
      const { selectedGameIds } = this.state;
      if (!selectedGameIds.includes(gameId)) {
        this.setState({
          selectedGameIds: [...selectedGameIds, gameId],
        });
      }
    };

    handleRemoveGame = gameId => {
      const { selectedGameIds } = this.state;
      this.setState({
        selectedGameIds: selectedGameIds.filter(id => id !== gameId),
      });
    };

    render() {
      const { selectedGameIds } = this.state;

      return (
        <WrappedComponent
          onAddGame={this.handleAddGame}
          onRemoveGame={this.handleRemoveGame}
          selectedGames={this.getSelectedGames()}
          selectedGameIds={selectedGameIds}
          {...this.props}
        />
      );
    }
  }

  return connect(state => ({
    games: getGames(state),
    preferredGameIds: getPreferredGameIds(state), // For initial-state
  }))(GamePickerState);
};
