import theme from '../../theme/oldTheme';
export default {
  root: {
    minHeight: 64,
    boxSizing: 'border-box',
    margin: '20px 0',
    background: 'white',
    boxShadow: '1px 1px 6px 0px rgba(0,0,0,0.3)',
    alignItems: 'center',
    display: 'flex',
    padding: '10px 10px 10px 32px',
    borderRadius: 5,
    position: 'relative',
  },
  hoverText: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    justifyContent: 'center',
    fontSize: 28,
    fontStyle: 'italic',
    opacity: 0,
    transition: 'opacity 100ms ease-in-out',
    textDecoration: 'none',
    '& span': {
      marginLeft: 12,
      fontSize: '1.75em',
      fontWeight: 'bold',
    },
    '$root:hover &': {
      opacity: 1,
    },
  },
  gameName: {
    width: '10%',
    fontWeight: 'bold',
    fontSize: 12,
    letterSpacing: '1.25px',
    lineHeight: '24px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textDecoration: 'none',
    order: -1,
    zIndex: 1,
    cursor: 'pointer',
  },
  tournamentName: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '26px',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '.eventScreen &': {
      display: 'none',
    },
    '.matchView &': {
      fontSize: 15,
      lineHeight: '22px',
    },
  },
  teamNames: {
    fontSize: '0.8125em',
    '& a': {
      textDecoration: 'underline',
    },
  },
  seriesInfoBottomRow: {
    display: 'flex',
    maxWidth: '100%',
  },
  seriesInfo: {
    maxWidth: '42%',
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      display: 'block',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '.eventScreen &': {
        fontWeight: 700,
        fontSize: '15px !important',
        lineHeight: '20px',
      },
      '.matchView &': {
        fontSize: 15,
        lineHeight: '20px',
      },
    },
    '& img': {
      width: 40,
      height: 40,
      marginRight: 16,
    },
  },
  seriesInfoText: {
    minWidth: 0,
  },
  seriesSeparator: {
    margin: [[0, 5]],
  },
  participantsContainer: {
    display: 'flex',
    alignItems: 'center',
    '.matchView &': {
      order: -1,
      width: '33%',
    },
  },
  participant: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 700,
    '.topUpcoming &': {
      fontSize: 18,
      lineHeight: '22px',
      display: 'flex',
    },
    '& img': {
      height: 32,
      margin: '0 7px',
      filter: 'drop-shadow(0 0 1px white)',
      verticalAlign: 'middle',
    },
    '& .left': {
      textAlign: 'right',
    },
    '& .right': {
      textAlign: 'left',
    },
    '& > div': {
      fontSize: 12,
      width: 20,
      height: 20,
      margin: '0 7px',
    },
  },
  team: {
    width: 85,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '.topUpcoming &': {
      width: 50,
    },
  },
  moreThanTwoTeams: {
    width: '100%',
    fontSize: 16,
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '.matchView &': {
      paddingRight: 25,
    },
  },
  vsText: {
    fontSize: 12,
    fontStyle: 'italic',
    fontWeight: 600,
    margin: 7,
    '.topUpcoming &': {
      margin: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      padding: '15px 10px 15px 10px',
    },
    gameName: {
      fontSize: 9,
      transform: 'translateX(-50%) rotate(-90deg)',
      transformOrigin: 'center',
      textAlign: 'center',
      order: -2,
      overflow: 'unset',
      textOverflow: 'unset',
    },
    tournamentName: {
      fontSize: 13,
      paddingBottom: 'unset',
    },
    seriesInfo: {
      '& span': {
        '.eventScreen &': {
          fontWeight: 700,
          fontSize: 13,
        },
      },
    },
    participantsContainer: {
      flexDirection: 'column',
    },
    participant: {
      fontSize: 11,
      display: 'unset',
      lineHeight: 'unset',
      '& img': {
        display: 'none',
      },
    },
    hoverText: {
      fontSize: 20,
    },
  },
};
