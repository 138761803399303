import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import HorizontalArticleCard from './HorizontalArticleCard';
import ArticleCard from '../ArticlesSection/ArticleCard';
import MainSectionTitle from '../common/MainSectionTitle';
import ArticleGameTag from './ArticleGameTag';
import ArticleLink from './ArticleLink';
import { TrackLink } from '../common';
import { ARTICLE_COUNT } from '../../hooks/articles'; // Should be 7
import { getFeaturedArticles, getNonFeaturedArticles } from '../../store/selectors/articles';
import { getGames } from '../../store/selectors/gameData';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.background.senary,
    borderBottom: `2px solid ${theme.palette.border.quartary}`,
    boxSizing: 'border-box',
    padding: '15px 24px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    width: '100%',
    maxWidth: 1232,
    marginTop: 24,
    '&.showLink': {
      marginTop: 'unset',
    },
  },
  showMoreButton: {
    height: 56,
    width: 183,
    fontSize: 16,
    color: 'white',
    lineHeight: '16px',
    fontWeight: 600,
    fontFamily: 'Source Sans Pro',
    cursor: 'pointer',
    borderRadius: 4,
    background: '#7E1351 !important',
    borderColor: 'transparent',
    marginBottom: 30,
    padding: 'unset',
    letterSpacing: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
  },
  viewAllButton: {
    textDecoration: 'none',
    color: '#F0039A',
    letterSpacing: '2.5px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 600,
    textAlign: 'right',
    '&:hover,&:focus': {
      textDecoration: 'underline',
    },
  },
  featuredCards: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('md')]: {
      ' & > *:not(:last-child)': {
        marginRight: 15,
      },
    },
  },
  articleLink: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
}));

const ArticlesSection = ({ articles, featuredArticles, game, title, showLink, className }) => {
  const classes = useStyles();
  const combinedArticles = useMemo(() => [...articles, ...featuredArticles], [articles, featuredArticles]);
  const featuredArticleList = useSelector(state => getFeaturedArticles(state, { articles: combinedArticles, game }));
  const articleList = useSelector(state => getNonFeaturedArticles(state, { articles: combinedArticles, game }));
  const gameList = useSelector(state => getGames(state));

  // Will not show the section if there are no articles.
  if (!combinedArticles || !combinedArticles.length) {
    return null;
  }

  return (
    <div
      className={classnames({
        [classes.root]: true,
        [className]: true,
      })}
    >
      <MainSectionTitle className={classes.header} title={title} />
      <div
        className={classnames({
          [classes.container]: true,
          showLink,
        })}
      >
        {showLink && (
          <TrackLink
            to="/news"
            className={classes.viewAllButton}
            track={{
              event: 'More News Button Clicked',
              source: 'articles-section',
              target: 'news-page',
            }}
          >
            VIEW ALL NEWS
          </TrackLink>
        )}
        <div className={classes.featuredCards}>
          {featuredArticleList &&
            featuredArticleList.map(article => (
              <ArticleCard
                key={article.id}
                article={article}
                gameTags={<ArticleGameTag article={article} games={gameList} />}
                articleLink={<ArticleLink article={article} className={classes.articleLink} source="article-card" />}
              />
            ))}
        </div>
        {articleList.slice(0, ARTICLE_COUNT).map(article => (
          <HorizontalArticleCard
            key={article.id}
            article={article}
            gameTags={<ArticleGameTag article={article} games={gameList} />}
            articleLink={
              <ArticleLink article={article} className={classes.articleLink} source="horizontal-article-card" />
            }
          />
        ))}
      </div>
      <TrackLink
        to="/news"
        className={classes.showMoreButton}
        track={{
          event: 'More News Button Clicked',
          source: 'articles-section',
          target: 'news-page',
        }}
      >
        See More News
      </TrackLink>
    </div>
  );
};

export default ArticlesSection;
