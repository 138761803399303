import SimpleMarkdown from 'simple-markdown';

import { embedRule } from './embedRule';

const rules = {
  ...SimpleMarkdown.defaultRules,
  embedRule,
};

const parser = SimpleMarkdown.parserFor(rules);
const reactOutput = SimpleMarkdown.reactFor(SimpleMarkdown.ruleOutput(rules, 'react'));

export const parseMd = source => {
  // Many rules require content to end in \n\n to be interpreted as a block.
  const blockSource = source + '\n\n';
  const parseTree = parser(blockSource, { inline: false });
  return reactOutput(parseTree);
};
