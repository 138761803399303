import React from 'react';
import { withStyles } from '@material-ui/styles';

import { H2 } from '.';

const styles = theme => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    '& h2': {
      margin: 0,
    },
  },
  icon: {
    fontSize: 20,
  },
  img: {
    height: 32,
    width: 32,
  },
  imgContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.lightTeal,
    borderRadius: '50%',
    color: 'white',
    height: 32,
    marginRight: 8,
    width: 32,
  },
});

/**
 * Component to render a header containing an Icon and a label.
 * @prop {class} Icon must be a component class, as imported from react-icons/md.
 * @prop {string} label text to present in header.
 * @prop {string} className (optional) className to tag on outermost element.
 */
const IconHeader = ({ classes, className, Icon, label }) => (
  <div className={`${classes.container} ${className}`}>
    <div className={classes.imgContainer}>
      <Icon className={classes.icon} />
    </div>
    <H2>{label}</H2>
  </div>
);

export default withStyles(styles)(IconHeader);
