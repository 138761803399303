import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import renderEventTitle from '../../utils/renderEventTitle';
import { getTournamentUrl } from '../../utils/urls';
import { TrackLink } from './Track';

const useStyles = makeStyles(theme => ({
  eventTitleLink: {
    textDecoration: 'none',
    color: 'white',
  },
}));

const EventLink = ({ tournament, aSeries, source }) => {
  const classes = useStyles();
  return (
    <TrackLink
      className={classes.eventTitleLink}
      to={getTournamentUrl(tournament)}
      track={{ event: 'Event Link Clicked', target: 'tournament-page', source: source || 'event-link' }}
    >
      {renderEventTitle(aSeries)}
    </TrackLink>
  );
};

export default EventLink;
