import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import withThemeProvider from '../../Wrappers/withThemeProvider';

const useStyles = makeStyles(theme => {
  const VARIANTS = {
    default: {
      backgroundColor: theme.palette.background.primary,
    },
    primary: {
      backgroundColor: theme.palette.background.primary,
    },
    secondary: {
      backgroundColor: theme.palette.background.secondary,
    },
    tertiary: {
      backgroundColor: theme.palette.background.tertiary,
    },
  };

  const getCSS = (variant, property) => VARIANTS[variant][property] || VARIANTS['default'][property];

  return {
    background: {
      backgroundColor: ({ variant }) => getCSS(variant || 'default', 'backgroundColor'),
    },
  };
});

const SectionBackground = ({ children, className, variant }) => {
  const classes = useStyles({ variant });
  return <div className={`${classes.background} ${className}`}>{children}</div>;
};

export default withThemeProvider()(SectionBackground);
