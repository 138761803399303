import formatDistanceStrict from 'date-fns/formatDistanceStrict';

const TIME_UNITS = { minute: 'min', minutes: 'mins', hour: 'hr', hours: 'hrs' };

const getDistanceBetweenDates = (firstDate, secondDate = new Date(), options = { addSuffix: true }) => {
  const distanceString = formatDistanceStrict(firstDate, secondDate, options);
  let timeBetween = '';

  Object.keys(TIME_UNITS).forEach(key => {
    if (distanceString.indexOf(key) >= 0) {
      timeBetween = distanceString.replace(key, TIME_UNITS[key]);
    }
  });
  if (!timeBetween) {
    return distanceString;
  }
  return timeBetween;
};

export default getDistanceBetweenDates;
