import { useState, useMemo, useEffect } from 'react';
import { normalize } from '../../utils/getFirestoreQuery';

const useFirestoreMultiGet = ({ queries }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState({});

  const formattedResults = useMemo(
    () =>
      queries.map((_, idx) => {
        if (!results[idx]) return [];
        return Object.values(results[idx]);
      }),
    [queries, results]
  );

  useEffect(() => {
    const initializedMap = queries.reduce((accu, _, idx) => ({ ...accu, [idx]: false }), {});
    const payload = {};

    const fetchQueries = async () => {
      await Promise.all(
        queries.map(async (query, idx) => {
          const snapshot = await query.get();
          payload[idx] = normalize(snapshot.docs.map(d => d.data()));
          initializedMap[idx] = true;

          if (Object.values(initializedMap).every(initialized => initialized)) {
            setIsLoading(false);
          }
        })
      );
      setResults(payload);
    };
    fetchQueries();
  }, [queries, setIsLoading, setResults]);

  return [formattedResults, !isLoading];
};

export default useFirestoreMultiGet;
