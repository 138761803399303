import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

import { isUserFollowingAnyEntity } from './gameDataUtils';
import { getSortedTournamentsByCompetitionId } from './competitions';

const getPreferredEntityIds = preferredEntityIdsMap => [
  preferredEntityIdsMap.preferredTeamIds || [],
  preferredEntityIdsMap.preferredTournamentIds || [],
  preferredEntityIdsMap.preferredGameIds || [],
  preferredEntityIdsMap.preferredCompetitionIds || [],
];

const getEntityOrderByIndexedFollow = ({ entityAId, entityBId, preferredEntityIds }) => {
  const isEntityAFollowed = preferredEntityIds.includes(entityAId);
  const isEntityBFollowed = preferredEntityIds.includes(entityBId);
  if (isEntityAFollowed && isEntityBFollowed) {
    const aPreferredIndex = preferredEntityIds.indexOf(entityAId);
    const bPreferredIndex = preferredEntityIds.indexOf(entityBId);
    return aPreferredIndex - bPreferredIndex;
  }
  if (isEntityAFollowed) return -1;
  if (isEntityBFollowed) return 1;

  return null;
};

const getEntityOrderByTeam = ({ entityATeamIds, entityBTeamIds, preferredTeamIds }) => {
  const entityAFollowedTeamIds = entityATeamIds.filter(tid => preferredTeamIds.includes(tid)); // intersection of both
  const entityBFollowedTeamIds = entityBTeamIds.filter(tid => preferredTeamIds.includes(tid));
  if (entityAFollowedTeamIds.length && entityBFollowedTeamIds.length) return 0;
  if (entityAFollowedTeamIds.length) return -1;
  if (entityBFollowedTeamIds.length) return 1;

  return null;
};

const getEntityOrderByTournament = ({ tournamentAId, tournamentBId, preferredTournamentIds }) =>
  getEntityOrderByIndexedFollow({
    entityAId: tournamentAId,
    entityBId: tournamentBId,
    preferredEntityIds: preferredTournamentIds,
  });

const getEntityOrderByCompetition = ({ competitionAId, competitionBId, preferredCompetitionIds }) =>
  getEntityOrderByIndexedFollow({
    entityAId: competitionAId,
    entityBId: competitionBId,
    preferredEntityIds: preferredCompetitionIds,
  });

const getEntityOrderByGame = ({ gameAId, gameBId, preferredGameIds }) =>
  getEntityOrderByIndexedFollow({
    entityAId: gameAId,
    entityBId: gameBId,
    preferredEntityIds: preferredGameIds,
  });

const getSeriesSortedByFollow = ({ series, preferredEntityIdsMap }) => {
  const [preferredTeamIds, preferredTournamentIds, preferredGameIds, preferredCompetitionIds] = getPreferredEntityIds(
    preferredEntityIdsMap
  );

  return series.sort((seriesA, seriesB) => {
    // Sort by Team follows (Organization follows are handled in team follows)
    const seriesOrderByTeam = getEntityOrderByTeam({
      entityATeamIds: seriesA.teamIds || [],
      entityBTeamIds: seriesB.teamIds || [],
      preferredTeamIds,
    });
    if (seriesOrderByTeam !== null) return seriesOrderByTeam;

    // Sort by Tournament follows
    const seriesOrderByTournament = getEntityOrderByTournament({
      tournamentAId: seriesA.tournament?.id,
      tournamentBId: seriesB.tournament?.id,
      preferredTournamentIds,
    });
    if (seriesOrderByTournament !== null) return seriesOrderByTournament;

    // Sort by Competition follows
    const seriesOrderByCompetition = getEntityOrderByCompetition({
      competitionAId: seriesA.tournament?.competition,
      competitionBId: seriesB.tournament?.competition,
      preferredCompetitionIds,
    });
    if (seriesOrderByCompetition !== null) return seriesOrderByCompetition;

    // Sort by Game follows
    const seriesOrderByGame = getEntityOrderByGame({
      gameAId: seriesA.game,
      gameBId: seriesB.game,
      preferredGameIds,
    });
    if (seriesOrderByGame !== null) return seriesOrderByGame;

    return 0;
  });
};

const getFeaturedTournamentsSortedByFollow = ({ tournaments, preferredEntityIdsMap }) => {
  const [preferredTeamIds, preferredTournamentIds, preferredGameIds, preferredCompetitionIds] = getPreferredEntityIds(
    preferredEntityIdsMap
  );

  return tournaments.sort((tA, tB) => {
    // Sort by Team follows (Organization follows are handled in team follows)
    const tournamentOrderByTeam = getEntityOrderByTeam({
      entityATeamIds: tA.fromSeries.teamIds || [],
      entityBTeamIds: tB.fromSeries.teamIds || [],
      preferredTeamIds,
    });
    if (tournamentOrderByTeam !== null) return tournamentOrderByTeam;
    // Sorting based on followed tournament ids makes sure that explicitly
    // followed tournaments come on top of tournaments that appear because of
    // Competition or Game follows.
    const tournamentOrderByTournament = getEntityOrderByTournament({
      tournamentAId: tA.id,
      tournamentBId: tB.id,
      preferredTournamentIds,
    });
    if (tournamentOrderByTournament !== null) return tournamentOrderByTournament;

    // Sort by Competition follows
    const tournamentOrderByCompetition = getEntityOrderByCompetition({
      competitionAId: tA.competitionId,
      competitionBId: tB.competitionId,
      preferredCompetitionIds,
    });
    if (tournamentOrderByCompetition !== null) return tournamentOrderByCompetition;

    // Sort by Game follows
    const tournamentOrderByGame = getEntityOrderByGame({
      gameAId: tA.game,
      gameBId: tB.game,
      preferredGameIds,
    });
    if (tournamentOrderByGame !== null) return tournamentOrderByGame;

    return 0;
  });
};

export const getEntitySortedByFollow = entityName => (entityArr, preferredEntityIdsMap, isFollowMode) => {
  if (!isFollowMode) return entityArr;
  if (!isUserFollowingAnyEntity(preferredEntityIdsMap)) return entityArr;
  if (entityName === 'series') return getSeriesSortedByFollow({ series: entityArr, preferredEntityIdsMap });
  if (entityName === 'featured-tournaments')
    return getFeaturedTournamentsSortedByFollow({ tournaments: entityArr, preferredEntityIdsMap });

  return entityArr;
};

export const getRelatedStateEntities = createCachedSelector(
  state => state,
  (_, { entityId }) => entityId,
  (_, { relatedEntityName }) => relatedEntityName,
  (state, entityId, relatedEntityName) => {
    switch (relatedEntityName) {
      case 'tournaments': {
        return getSortedTournamentsByCompetitionId(state, entityId);
      }
      default: {
        return [];
      }
    }
  }
)({ keySelector: (_, props) => props.entityId, selectorCreator: createSelector });
