import { call, put, select, takeEvery } from 'redux-saga/effects';

import firebase, { db, messaging } from './firebase';
import { SET_SERIES_NOTIFICATION, TOGGLE_SERIES_NOTIFICATION } from '../actions/notification';
import { trackEvent } from '../actions/uiStates';
import { REFRESH_FCM_TOKEN, updateFCMToken } from '../actions/user';
import { isSignedIn } from '../selectors/user';
import tryCatchWrapper from '../../utils/tryCatchWrapper';
import getNotificationPermission from '../../utils/getNotificationPermission';

// TODO: remove string casting after IDs are converted to strings in firestore.
const getSeriesId = seriesId => `${seriesId}`;

const getFCMToken = async () => {
  if (!messaging) {
    console.error('[getFCMToken] error: this browser does not support firebase messaging');
    return null;
  }

  // Check for permission and prompt if not yet given.
  if (getNotificationPermission() !== 'granted') {
    // Will throw with `e.code === 'messaging/permission-blocked'` when permission denied.
    // This behavior is desired, as it will end execution in calling functions.
    await messaging.requestPermission();
  }
  return await messaging.getToken();
};

const setSeriesNotification = async ({ seriesId, uid }) =>
  db
    .collection('userNotifications')
    .doc(uid)
    .set({ seriesIds: firebase.firestore.FieldValue.arrayUnion(seriesId) }, { merge: true });

const unsetSeriesNotification = async ({ seriesId, uid }) =>
  db
    .collection('userNotifications')
    .doc(uid)
    .set({ seriesIds: firebase.firestore.FieldValue.arrayRemove(seriesId) }, { merge: true });

/**
 * Method to set an FCM token on the user document. Retrieves one if not provided.
 * @param {string} token (optional) replaces user.fcmToken with provided token or gets a new one.
 */
function* setUserFCMToken(token) {
  // Prevent FCM errors if no user.
  if (!(yield select(isSignedIn))) {
    return null;
  }

  // Prevent error if messaging is unsupported in browser.
  if (!messaging) {
    return null;
  }

  // TODO [#840]: When necessary to support multiple devices, we should update to use
  // FCM device-groups: https://firebase.google.com/docs/cloud-messaging/js/device-group
  const { user } = yield select();
  const fcmToken = token || (yield call(getFCMToken));

  if (fcmToken && fcmToken !== user.fcmToken) {
    // NOTE: Firestore sync to redux is asynchronous, so update fcmToken here
    // in case of a quick succession of `handleSetSeriesNotification` calls.
    yield put(updateFCMToken(fcmToken));
    yield db
      .collection('users')
      .doc(user.uid)
      .set({ fcmToken }, { merge: true });
  }
  return fcmToken;
}

function* handleUpdateUserFCMToken({ fcmToken }) {
  yield call(setUserFCMToken, fcmToken);
}

function* handleSetSeriesNotification(action) {
  // Prevent FCM errors if no user.
  if (!(yield select(isSignedIn))) {
    console.error('[handleToggleSeriesNotification] error: no user signed in.');
    return null;
  }

  // Ensure that series is in the future.
  const seriesId = getSeriesId(action.series.id);
  if (action.series.start.toDate() < new Date()) {
    console.error('[handleToggleSeriesNotification] error: cannot set notification for started series');
    return null;
  }

  const {
    user: { uid },
  } = yield select();

  // If user has no token, ask permission, retrieve and set one.
  // Ensure FCM token is up-to-date and set the notification preference.
  yield call(setUserFCMToken);
  yield call(setSeriesNotification, { seriesId, uid });
  yield put(trackEvent('Notification Set', { type: 'series', id: seriesId }, { contextSeriesId: seriesId }));
}

function* handleUnsetSeriesNotification(action) {
  // Prevent FCM errors if no user.
  if (!(yield select(isSignedIn))) {
    console.error('[handleToggleSeriesNotification] error: no user signed in.');
    return null;
  }

  const seriesId = getSeriesId(action.series.id);
  const {
    user: { uid },
  } = yield select();
  yield call(unsetSeriesNotification, { seriesId, uid });
  yield put(trackEvent('Notification Unset', { type: 'series', id: seriesId }, { contextSeriesId: seriesId }));
}

function* handleToggleSeriesNotification(action) {
  const seriesId = getSeriesId(action.series.id);
  const {
    notification: { seriesIds },
  } = yield select();

  if (seriesIds.includes(seriesId)) {
    yield call(handleUnsetSeriesNotification, action);
  } else {
    yield call(handleSetSeriesNotification, action);
  }
}

export default function* notificationsSaga() {
  yield takeEvery(SET_SERIES_NOTIFICATION, tryCatchWrapper(handleSetSeriesNotification));
  yield takeEvery(TOGGLE_SERIES_NOTIFICATION, tryCatchWrapper(handleToggleSeriesNotification));
  yield takeEvery(REFRESH_FCM_TOKEN, tryCatchWrapper(handleUpdateUserFCMToken));
}
