import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { format, isSameDay } from 'date-fns';

const useStyles = makeStyles(theme => ({
  root: {
    boxSizing: 'border-box',
  },
  text: {
    fontSize: 9,
    fontWeight: 'bold',
    letterSpacing: 1,
    lineHeight: '12px',
  },
  live: {
    '&:before': {
      backgroundColor: 'red',
      borderRadius: '50%',
      content: '" "',
      display: 'inline-block',
      height: 6,
      verticalAlign: 'middle',
      width: 6,
      marginRight: 4,
    },
  },
}));

const getDateString = seriesStart => {
  const seriesStartDate = seriesStart.toDate();

  return format(seriesStartDate, `${!isSameDay(seriesStartDate, new Date()) ? 'MMM d, ' : ''}h:mm a`);
};

const Header = ({ aSeries, live }) => {
  const classes = useStyles();

  if (live) {
    // Passing the live property because series have grace period logic which is better to consume instead of repeat.
    return <div className={`${classes.live} ${classes.text}`}>LIVE</div>;
  }

  const nowSeconds = Math.floor(new Date().getTime() / 1000);
  const isFinished = aSeries.end && aSeries.end.seconds < nowSeconds;

  return <div className={classes.text}>{isFinished ? 'FINAL' : getDateString(aSeries.start)}</div>;
};

export default Header;
