import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { H1 } from '../../common';
import CollapsibleSection from '../CollapsibleSection';
import EmailNotifications from './EmailNotifications';
import EntityFollowingNotifications from './EntityFollowingNotifications';
import { FOLLOW_FIELDS } from '../../../store/actions/user';

const useStyles = makeStyles(theme => ({
  notificationsView: {
    width: 600,
    display: 'flex',
    flexDirection: 'column',
  },
  h1: {
    fontWeight: 'normal',
    margin: '0 0 24px 0',
  },
  allNotifications: {
    padding: '12px 0',
    borderBottom: `1px solid ${theme.palette.border.septenary}`,
  },

  [theme.breakpoints.down('sm')]: {
    notificationsView: {
      width: '100%',
    },
    h1: {
      marginTop: 32,
    },
  },
}));

const NotificationsView = () => {
  const classes = useStyles();

  return (
    <div className={classes.notificationsView}>
      <H1 className={classes.h1}>Your Notifications</H1>
      <EmailNotifications />

      <CollapsibleSection
        subtitle="Notifications based on your followed teams, players, and events"
        title="By Following"
      >
        <EntityFollowingNotifications entityField={FOLLOW_FIELDS.TOURNAMENT_IDS} />
        <EntityFollowingNotifications entityField={FOLLOW_FIELDS.TEAM_IDS} />
      </CollapsibleSection>
    </div>
  );
};

export default NotificationsView;
