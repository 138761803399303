import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { DropdownSelect, DropdownItem } from '../common';

import { isSignedIn } from '../../store/selectors/user';
import { toggleShowTopEventsOnVideoSwatch } from '../../store/actions/uiStates';

const useStyles = makeStyles(theme => ({
  dropdowns: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: 600, // To beat the player iFrame.
  },
  topEventsSelect: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    '& .root': {
      width: 144,
      height: 32,
      color: theme.suvaGray,
      border: `1px solid ${theme.palette.border.soloDenary}`,
      background: theme.palette.background.primary,
      '& span': {
        marginLeft: 12,
      },
    },
    '& .dropIcon': {
      marginRight: 12,
    },
    '& .popper': {
      top: 32,
      width: 144,
      borderRadius: 4,
      '& .dropdown': {
        maxWidth: '144px',
        maxHeight: 300,
      },
    },
  },
  menuItem: {
    textAlign: 'center',
  },
}));
export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isOpenSelected, setIsOpenSelected] = useState(false);
  const showTopEventsOnVideoSwatch = useSelector(state => state.uiStates.showTopEventsOnVideoSwatch);

  const getTracking = (event, target = null) => ({
    event,
    source: 'home-page',
    eventProperties: { is_signed_in: isSignedIn },
    target,
  });

  return (
    <div className={classes.dropdowns}>
      <div className={classes.topEventsSelect}>
        <DropdownSelect
          withArrowIcon
          className={classes.wrapper}
          open={isOpenSelected}
          active={showTopEventsOnVideoSwatch}
          placeholder={showTopEventsOnVideoSwatch ? 'Top Events' : 'All Events'}
          handleOpen={() => setIsOpenSelected(!isOpenSelected)}
          handleClose={() => setIsOpenSelected(false)}
          track={getTracking('Home Page Top Events Toggle Opened')}
        >
          <DropdownItem
            className={classes.menuItem}
            active={!showTopEventsOnVideoSwatch}
            onClick={() => {
              setIsOpenSelected(false);
              dispatch(toggleShowTopEventsOnVideoSwatch(false));
            }}
            track={getTracking('Top Events Toggle Filter Clicked', 'All Events')}
          >
            <span>All Events</span>
          </DropdownItem>
          <DropdownItem
            className={classes.menuItem}
            active={showTopEventsOnVideoSwatch}
            onClick={() => {
              setIsOpenSelected(false);
              dispatch(toggleShowTopEventsOnVideoSwatch(true));
            }}
            track={getTracking('Top Events Toggle Filter Clicked', 'Top Events')}
          >
            <span>Top Events</span>
          </DropdownItem>
        </DropdownSelect>
      </div>
    </div>
  );
};
