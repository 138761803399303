import { createSelector } from 'reselect';

export const getAuthenticated = state => {
  return state.twitter.authenticated;
};

export const getTweetIds = createSelector(
  state => state.twitter.tweets.tweetIds,
  tweetIds => [...new Set(tweetIds)]
);
