import React from 'react';
import styled from 'styled-components';

import { SpanText } from '../core';

const LINKS = [
  ['News', '/news'],
  ['Calendar', '/calendar'],
  ['Multi-Viewer', '/multiviewer'],
  ['VoDs', '/vods'],
  ['Games', '/games'],
  ['Teams', '/t/'],
];

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props): string => props.theme.palette.background.tertiary};
  border-bottom: solid 1px ${(props): string => props.theme.palette.border.secondary};
  height: 48px;
  width: 100%;
`;

const ChildContainer = styled.div`
  align-items: center;
  display: flex;
`;

const Logo = styled.div`
  align-items: center;
  background-color: ${(props): string => props.theme.palette.secondary.active};
  display: flex;
  flex: 1;
  margin-right: 16px;
  height: calc(48px - 16px);
  padding: 8px;
`;

const LogoImg = styled.img`
  height: 100%;
`;

const TempLink = styled.div`
  padding: 0 16px;
`;

const NavBar = (): JSX.Element => (
  // TODO: Add SideNavBar for mobile
  <Container>
    <ChildContainer>
      <Logo>
        <LogoImg src="/images/WebLogo.png" alt="Juked" />
      </Logo>
      {LINKS.map(([title]) => (
        <TempLink key={`${title}`}>
          <SpanText variant="bold">{title}</SpanText>
        </TempLink> // TODO: Tracking links
      ))}
    </ChildContainer>
  </Container>
);

export default NavBar;
