import { useState, useEffect } from 'react';

import { db } from '../../store/sagas/firebase';

export default (collectionName, docId) => {
  const [initialized, setInitialized] = useState(false);
  const [result, setResult] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const snapshot = await db
        .collection(collectionName)
        .doc(docId)
        .get();
      setResult(snapshot.exists ? snapshot.data() : null);
      setInitialized(true);
    };
    collectionName && docId ? fetch() : setInitialized(true);
  }, [collectionName, docId]); // query should unsub & resub if anything pertaining to the query changes.

  return [result, !initialized /* Fetching State */];
};
