import classnames from 'classnames';
import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  carouselContainer: {
    maxWidth: 1280,
    width: '100%',
    textAlign: 'right',
    margin: 'auto',
    marginBottom: 40,
    '&.notLoaded': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
});

const CarouselContainer = ({ classes, className, children, notLoaded }) => (
  <div
    className={classnames({
      [className]: true,
      [classes.carouselContainer]: true,
      notLoaded,
    })}
  >
    {children}
  </div>
);

export default withStyles(styles)(CarouselContainer);
