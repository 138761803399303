import React from 'react';
import { useSelector } from 'react-redux';
import { getGameById } from '../../store/selectors/gameData';

import WindowTitle from '../WindowTitle';

const MatchPageTitle = ({ series }) => {
  const game = useSelector(state => getGameById(state, series.game));
  const teamNames = series.participants.map(p => p.shortName || p.name).join(' vs. ');

  const { title, description, keywords, robots } = series.tournament?.metaTags || {};

  return (
    <WindowTitle
      title={
        title || `${series.tournament.title} ${teamNames.includes('TBD') ? '|' : `| ${teamNames} |`} ${series.title}`
      }
      description={
        description ||
        `Watch ${series.tournament.title} live streams and find schedules, standings, brackets, scores, and more at Juked | ${game?.name}`
      }
      keywords={keywords}
      robots={robots}
    />
  );
};

export default MatchPageTitle;
