import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import TopUpcomingEvent from '../TopUpcomingEvent';
import { CarouselContainer, Carousel } from '../../common';
import { useGetTrackClick } from '../../Wrappers/withTrackClick';
import SingleViewVideoContent from './SingleViewVideoContent';
import SingleViewAlphaSignupPrompt from './SingleViewAlphaSignupPrompt';
import LoadingState from './LoadingState';
import { setSinglePlayerSeriesId } from '../../../store/actions/uiStates';

const SOURCE = 'single-video-swatch';
export const ALPHA_SIGNUP_ID = 'alpha-signup';

const separatorClasses = {
  top: -12,
  bottom: 0,
  position: 'absolute',
  width: 'calc((100vw - 1232px) * 0.5)',
  zIndex: 3, // To beat carousel.
};

const useStyles = makeStyles(theme => ({
  carouselContainer: {
    position: 'relative',
    marginBottom: 0,
    maxWidth: 1280,
  },
  separatorLeft: {
    ...separatorClasses,
    background: theme.palette.background.primary,
    left: 'calc((100vw - 1280px) * -0.5)',
  },
  separatorRight: {
    ...separatorClasses,
    background: theme.palette.background.primary,
    right: 'calc((100vw - 1280px) * -0.5)',
  },
  carousel: {
    paddingBottom: 24,
    '& .slick-dots': {
      bottom: -16,
      zIndex: 1,
    },
    '& .slick-list': {
      overflow: 'visible',
      zIndex: 2,
    },
  },
}));

const SingleViewCarousel = React.forwardRef(({ series, isLoaded }, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const singlePlayerSeriesId = useSelector(state => state.uiStates.singlePlayerSeriesId);

  // Add a fake series placeholder for alpha signup at the beginning of carousel.
  const displaySeries = [{ id: ALPHA_SIGNUP_ID }, ...series];

  const page = useMemo(
    () =>
      singlePlayerSeriesId
        ? Math.max(
            0, // If findIndex returns -1 (not found) default to 0.
            displaySeries.findIndex(aSeries => aSeries.id === singlePlayerSeriesId)
          )
        : 0, // If no existing singlePlayerSeriesId, default to 0.
    [displaySeries, singlePlayerSeriesId]
  );

  const setPage = useCallback(
    pageIndex => displaySeries[pageIndex] && dispatch(setSinglePlayerSeriesId(displaySeries[pageIndex].id)),
    [displaySeries, dispatch]
  );

  const getTrackClick = useGetTrackClick();

  const seriesCount = displaySeries.length;

  if (!isLoaded) {
    return <LoadingState />;
  }

  if (isLoaded && !seriesCount) {
    return <TopUpcomingEvent />;
  }
  return (
    <CarouselContainer className={classes.carouselContainer}>
      <div className={classes.separatorLeft} />
      <Carousel
        className={classnames({ [classes.carousel]: seriesCount > 1 })}
        settings={{
          dots: true,
          initialSlide: page,
          slides: 1,
          afterChange: pageIndex => {
            getTrackClick({
              track: {
                event: 'Single View Video Swatch Page Changed',
                eventProperties: { type: 'click' },
                source: SOURCE,
                target: pageIndex > page ? 'next-page' : 'previous-page',
              },
            })();
            setPage(pageIndex);
          },
        }}
        ref={ref}
        track={{ source: 'single-view-swatch', event: 'Single View Carousel Clicked' }}
      >
        {displaySeries.map((aSeries, index) => {
          if (aSeries.id === ALPHA_SIGNUP_ID) {
            return <SingleViewAlphaSignupPrompt key={aSeries.id} />;
          } else
            return (
              <SingleViewVideoContent
                key={aSeries.id}
                isActive={index === page}
                series={aSeries}
                isLoaded={isLoaded}
                seriesCount={seriesCount}
              />
            );
        })}
      </Carousel>
      <div className={classes.separatorRight} />
    </CarouselContainer>
  );
});

export default SingleViewCarousel;
