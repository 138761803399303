import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';

import LoginView from '../components/User/Dialogs/LoginViews/LoginView';
import { LOGIN_SOURCES } from '../store/reducers/user';
import AuthScreenStyles from './AuthScreenStyles';

const styles = theme => AuthScreenStyles(theme);

class LoginScreen extends Component {
  componentWillMount() {
    // Grabs the "r" query from the URL then wipe it off the url.
    // `redirPath` doesn't need to be a state because it's never gonna change after init.
    const urlParams = new URLSearchParams(window.location.search);
    this.redirPath = urlParams.get('r') || '/';
    // Remove the query param from the URL bar once we retrieved it.
    window.history.replaceState({}, document.title, '/login');
  }

  handleRenderOnSuccess = () => <Redirect to={this.redirPath} />;

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.loginStatus}>
          <LoginView renderOnSuccess={this.handleRenderOnSuccess} source={LOGIN_SOURCES.SIGNUP_PAGE} />
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    null,
    {}
  )
)(LoginScreen);
