import React from 'react';
import { withStyles } from '@material-ui/styles';

import TeamPageLink from '../common/TeamPageLink';

const styles = {
  logo: {
    width: 32,
    verticalAlign: 'middle',
    marginLeft: 10,
    marginRight: 7,
    filter: 'drop-shadow(0 0 1px white)',
  },
  standings: {
    textAlign: 'center',
  },
  participantName: {
    color: 'white',
  },
};

const StandingsTableRow = ({ standing: { participant }, standing, columnsToRender, classes }) => (
  <tr>
    <td>
      {standing.rank}.&nbsp;
      {participant.image && <img className={classes.logo} src={participant.image} alt="" />}
      <TeamPageLink
        className={classes.participantName}
        slug={participant.slug}
        participantType={participant?.participantType}
        track={'standing-dialog'}
      >
        {participant.name}
      </TeamPageLink>
    </td>
    {columnsToRender.map(name => (
      <td key={name} className={classes.standings}>
        {standing[name]}
      </td>
    ))}
  </tr>
);

export default withStyles(styles)(StandingsTableRow);
