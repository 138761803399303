import React from 'react';

import { Breadcrumb, BreadcrumbSpacer } from '../Breadcrumbs';
import { getCompetitionUrl, getTournamentUrl, getGameUrl } from '../../../utils/urls';

const getTracking = (target, type, event = null) => ({
  event: 'Breadcrumb Link Clicked',
  eventProperties: {
    event_name: event && event.title,
    event_slug: event && event.slug,
    event_id: event && event.id,
    type,
  },
  source: 'breadcrumb',
  target,
});

const EventBreadcrumb = ({ className, competition, tournament, game }) => {
  return (
    <div className={className}>
      <Breadcrumb url={`/`} track={getTracking('home-page', 'root-link')}>
        Juked
      </Breadcrumb>
      {Boolean(game) && (
        <>
          <BreadcrumbSpacer />
          <Breadcrumb url={getGameUrl(game)} track={getTracking('event-page', 'game-link', game)}>
            {game?.name}
          </Breadcrumb>
        </>
      )}
      {!!(competition || tournament) && <BreadcrumbSpacer />}
      {!!competition && (
        <Breadcrumb
          url={getCompetitionUrl(competition)}
          track={getTracking('event-page', 'competition-link', competition)}
        >
          {competition.title}
        </Breadcrumb>
      )}
      {!!competition && !!tournament && <BreadcrumbSpacer />}
      {!!tournament && (
        <Breadcrumb url={getTournamentUrl(tournament)} track={getTracking('event-page', 'tournament-link', tournament)}>
          {tournament.title}
        </Breadcrumb>
      )}
    </div>
  );
};

export default EventBreadcrumb;
