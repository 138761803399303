import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import TopNav from './TopNav/TopNav';
import SideNav from './SideNav/SideNav';

export default () => {
  const matchesDesktop = useMediaQuery('(min-width:960px)'); // SM Breakpoint
  return matchesDesktop ? <TopNav /> : <SideNav />;
};
