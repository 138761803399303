import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import classnames from 'classnames';

import FilterDropdowns from './FilterDropdowns';
import Underline from './Underline';
import { TrackSpan } from '../../common';
import { withCalendarUrlState } from '../wrappers';
import { mergeQueryToLocation, qsParse } from '../../common/QueryLink';
import { changeCalendarView } from '../../../store/actions/calendar';
import { CALENDAR_LINK_FILTERS, CALENDAR_FILTERS } from '../../../store/reducers/uiStates';
import { getPreferredGameIds } from '../../../store/selectors/user';
import toggleInArray from '../../../utils/toggleInArray';
import { toggleCalendarGameFilter } from '../../../store/actions/uiStates';

const useStyles = makeStyles(theme => ({
  containerBackground: {
    background: theme.palette.background.secondary,
    '&:not(.showWeek)': {
      marginBottom: 30,
      padding: '0 24px',
    },
    [theme.breakpoints.down('sm')]: {
      '.showWeek &': {
        marginBottom: 16,
      },
    },
  },
  container: {
    color: theme.darkGrey,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: 1232,
    minWidth: 960,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'initial',
      justifyContent: 'initial',
      margin: '0 20px',
      flexDirection: 'column',
    },
  },
  periodView: {
    display: 'flex',
    alignItems: 'flex-end',
    position: 'relative',
    height: 64,
    pointerEvents: 'none',
    [theme.breakpoints.down('sm')]: {
      height: 44,
      width: ' 100%',
      justifyContent: 'space-between',
    },
    '.showWeek &': {
      pointerEvents: 'inherit',
    },
    '.showDay &': {
      pointerEvents: 'inherit',
    },
    '.showAll &': {
      pointerEvents: 'inherit',
    },
    ' & > span:not(:first-child)': {
      marginLeft: 40,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 30,
      },
    },
  },
  calendarLink: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '24px',
    marginBottom: 10,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    transition: 'all 200ms ease-in-out',
    '&.selected': {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down('sm')]: {
      letterSpacing: -0.5,
      fontWeight: 600,
    },
  },
  // iPhone SE classes
  '@media (max-width: 350px)': {
    container: {
      margin: '0 12px',
    },
    calendarLink: {
      fontSize: 16,
      lineHeight: '20px',
      marginBottom: 6,
    },
    periodView: {
      height: 50,
    },
  },
}));

const Filters = ({
  calendarView,
  changeCalendarView,
  onSearchInputChange,
  searchInput,
  onGameChange,
  onSortChange,
  onTabChange,
}) => {
  const [leftOffset, setLeftOffset] = useState(null);
  const matchesMobile = useMediaQuery('(max-width:960px)');

  useEffect(() => {
    // This will keep the teal underline under the selected filter in mobile.
    // This is needed because the container has a variable width on mobile.
    if (matchesMobile) {
      const selectedFilter = document.getElementById(calendarView);
      setLeftOffset(selectedFilter && selectedFilter.offsetLeft);
    }
  }, [matchesMobile, calendarView]);

  const classes = useStyles();

  const getTracking = target => ({
    event: 'Calendar Date Link Clicked',
    source: 'calendar-page',
    target,
  });
  return (
    <div className={classes.containerBackground}>
      <div className={classes.container}>
        <div className={classes.periodView}>
          <TrackSpan
            id={CALENDAR_LINK_FILTERS.DAY}
            className={classnames({
              [classes.calendarLink]: true,
              selected: calendarView === CALENDAR_LINK_FILTERS.DAY && !searchInput,
            })}
            onClick={() => {
              onTabChange(CALENDAR_LINK_FILTERS.DAY);
              changeCalendarView(CALENDAR_LINK_FILTERS.DAY);
            }}
            track={getTracking('today')}
          >
            Today
          </TrackSpan>
          <TrackSpan
            id={CALENDAR_LINK_FILTERS.WEEK}
            className={classnames({
              [classes.calendarLink]: true,
              selected: calendarView === CALENDAR_LINK_FILTERS.WEEK && !searchInput,
            })}
            onClick={() => {
              onTabChange(CALENDAR_LINK_FILTERS.WEEK);
              changeCalendarView(CALENDAR_LINK_FILTERS.WEEK);
            }}
            track={getTracking('week')}
          >
            Week
          </TrackSpan>
          <TrackSpan
            id={CALENDAR_LINK_FILTERS.ALL}
            className={classnames({
              [classes.calendarLink]: true,
              selected: calendarView === CALENDAR_LINK_FILTERS.ALL && !searchInput,
            })}
            onClick={() => {
              onTabChange(CALENDAR_LINK_FILTERS.ALL);
              changeCalendarView(CALENDAR_LINK_FILTERS.ALL);
            }}
            track={getTracking('all')}
          >
            All
          </TrackSpan>
          <Underline calendarView={calendarView} left={leftOffset} />
        </div>
        <FilterDropdowns
          onSearchInputChange={onSearchInputChange}
          searchInput={searchInput}
          onGameChange={onGameChange}
          onSortChange={onSortChange}
        />
      </div>
    </div>
  );
};

export const withCalendarUrlActions = WrappedComponent => props => {
  const { history, location, toggleCalendarGameFilter, isFollowMode } = props;

  const setURLGames = (sort, previousSort, preferredGameIds) => {
    if (isFollowMode) {
      if (sort === CALENDAR_FILTERS.FEATURED) {
        return { sort: CALENDAR_FILTERS.FEATURED, games: preferredGameIds };
      }
      return { sort: CALENDAR_FILTERS.ALL, games: preferredGameIds };
    }
    return { games: null };
  };

  const handleSearchInputChange = searchInput => {
    const newLoc = mergeQueryToLocation(location, { search: searchInput });
    history.push(newLoc);
  };

  //Get URL params values
  const queryParams = qsParse(location.search);

  const { games } = queryParams;
  //Push game ids to the URL.
  const handleGamesChange = gameId => {
    toggleCalendarGameFilter(gameId);
    history.push(
      mergeQueryToLocation(location, {
        games: !!gameId ? toggleInArray(games || [], gameId) : null,
      })
    );
  };

  //Push selected sort filter to the URL.
  const handleSortChange = sort => {
    const { sort: previousSort } = queryParams;
    const { preferredGameIds, calendarSelectedGames } = props;

    history.push(
      mergeQueryToLocation(location, {
        sort, // Clear favorite games from the URL when changing to another sort option.
        ...setURLGames(sort, previousSort, preferredGameIds, calendarSelectedGames),
      })
    );
  };

  //Push the selected tab option to the URL.
  const handleTabChange = tab => {
    history.push(
      mergeQueryToLocation(location, {
        //This will prevent the events the user has in Remind Me from being changed if they open a shared URL
        tab,
      })
    );
  };

  return (
    <WrappedComponent
      onSearchInputChange={handleSearchInputChange}
      onGameChange={handleGamesChange}
      onSortChange={handleSortChange}
      onTabChange={handleTabChange}
      {...props}
    />
  );
};

export default compose(
  withRouter,
  withCalendarUrlState,
  connect(
    state => ({
      calendarView: state.calendar.calendarView,
      calendarFilter: state.uiStates.calendarFilter,
      preferredGameIds: getPreferredGameIds(state),
      calendarSelectedGames: state.uiStates.calendarSelectedGames,
      isFollowMode: state.uiStates.isFollowMode,
    }),
    { changeCalendarView, toggleCalendarGameFilter }
  ),
  withCalendarUrlActions // After connect, to get actions.
)(Filters);
