/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'react-router-dom';

import { withTrackClick } from '../wrappers';

export type { Track } from '../../store/actions/uiStates';

export const TrackA = withTrackClick(({ children, ...props }) => <a {...props}>{children}</a>);

export const TrackDiv = withTrackClick(props => <div {...props} />);

export const TrackLink = withTrackClick(Link);

export const TrackSpan = withTrackClick(props => <span {...props} />);
