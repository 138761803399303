import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

import { GameBadge } from '../common';
import LoadingStateTicker from './LoadingStateTicker';
import TickerMatch from './TickerMatch';
import { isDataLoaded } from '../../store/selectors/gameData';
import { getPrioritizedGameSeries } from '../../store/selectors/upcomingSeries';
import ScrollingArrow, { ARROW_DIRECTIONS } from './ScrollingArrow';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.duoDenary,
    backgroundColor: theme.palette.background.quartary,
    borderBottom: `1px solid ${theme.mediumCharcoalGrey}`,
    borderTop: `1px solid ${theme.mediumCharcoalGrey}`,
    whiteSpace: 'nowrap',
    position: 'relative',
    height: 60, // needs to be same height as <TickerPart>
    '&.isTheaterMode': {
      display: 'none',
    },
  },
  placeholderContainer: {
    overflow: 'hidden',
  },
  tickerContainer: {
    height: '100%',
    overflow: 'hidden',
  },
  gameContainer: {
    display: 'inline-flex',
    height: '100%',
  },
  game: {
    zIndex: 1,
    height: '100%',
    width: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: `1px solid ${theme.mediumCharcoalGrey}`,
    '& a': {
      width: 20,
      height: 20,
    },
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      display: 'none',
    },
  },
}));

const TopTicker = () => {
  const classes = useStyles();
  const gameSeriesCollections = useSelector(getPrioritizedGameSeries);
  const isPageLoaded = useSelector(isDataLoaded);
  const isTheaterMode = useSelector(state => state.video.isTheaterMode);

  if (isPageLoaded && !gameSeriesCollections?.length) {
    return null;
  }

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.placeholderContainer]: isPageLoaded,
        isTheaterMode,
      })}
    >
      {!isPageLoaded ? (
        <LoadingStateTicker />
      ) : (
        <>
          <ScrollingArrow containerSelector="#ticker" direction={ARROW_DIRECTIONS.LEFT} />
          <div id="ticker" className={classes.tickerContainer}>
            {gameSeriesCollections.map(({ game, liveSeries, upcomingSeries }) => (
              <div key={game.id} className={classes.gameContainer}>
                <div className={classes.game}>
                  <GameBadge animated game={game} imgSrc={game.images?.circle} />
                </div>
                {[...liveSeries, ...upcomingSeries].slice(0, 5).map((aSeries, index) => (
                  <TickerMatch key={aSeries.id} aSeries={aSeries} live={index < liveSeries.length} />
                ))}
              </div>
            ))}
          </div>
          <ScrollingArrow containerSelector="#ticker" direction={ARROW_DIRECTIONS.RIGHT} />
        </>
      )}
    </div>
  );
};

export default TopTicker;
