import { TrackEvent } from './types';
import { User } from '../types';

export const SAVE_PREFERENCES_ACCOUNT = 'SAVE_PREFERENCES_ACCOUNT';
export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_IN_COMPLETE = 'SIGN_IN_COMPLETE';
export const SIGN_UP_COMPLETE = 'SIGN_UP_COMPLETE';
export const SET_PREFERENCES_ACCOUNT_STATUS = 'SET_PREFERENCES_ACCOUNT_STATUS';
export const SET_PREFERENCES_COMPLETED_AT = 'SET_PREFERENCES_COMPLETED_AT';
export const SET_PREFERENCES_ERROR = 'SET_PREFERENCES_ERROR';
export const CHANGE_USER_ARRAY_OPT_IN = 'CHANGE_USER_ARRAY_OPT_IN';
export const SAVE_PREFERRED_ENTITIES = 'SAVE_PREFERRED_ENTITIES';
export const SAVE_PREFERRED_ENTITY = 'SAVE_PREFERRED_ENTITY';
export const SAVE_PREFERRED_ENTITIES_NOTIFICATIONS = 'SAVE_PREFERRED_ENTITIES_NOTIFICATIONS';
export const SAVE_PREFERRED_ENTITY_NOTIFICATIONS = 'SAVE_PREFERRED_ENTITY_NOTIFICATIONS';
export const SAVE_USER_TIME_ZONE = 'SAVE_USER_TIME_ZONE';
export const REFRESH_FCM_TOKEN = 'REFRESH_FCM_TOKEN';
export const REMOVE_PREFERRED_ENTITY = 'REMOVE_PREFERRED_ENTITY';
export const REMOVE_PREFERRED_ENTITY_NOTIFICATIONS = 'REMOVE_PREFERRED_ENTITY_NOTIFICATIONS';
export const RESET_PREFERENCES = 'RESET_PREFERENCES';
export const UPDATE_FCM_TOKEN = 'UPDATE_FCM_TOKEN';
export const UPDATE_PREFERENCES = 'UPDATE_PREFERENCES';
export const SET_SPORT_BUFF_TOKEN = 'SET_SPORT_BUFF_TOKEN';

type FollowFieldsType = {
  GAME_IDS: 'gameIds';
  TEAM_IDS: 'teamIds';
  TOURNAMENT_IDS: 'tournamentIds';
};

export const FOLLOW_FIELDS: FollowFieldsType = {
  GAME_IDS: 'gameIds',
  TEAM_IDS: 'teamIds',
  TOURNAMENT_IDS: 'tournamentIds',
};
export const FOLLOW_FIELD_VALUES = Object.values(FOLLOW_FIELDS);

type EntityTypesType = {
  COMPETITION: 'competition';
  GAME: 'game';
  ORGANIZATION: 'organization';
  TEAM: 'team';
  TOURNAMENT: 'tournament';
};

// This includes some entities which are not followable (ex: Organization)
export const ENTITY_TYPES: EntityTypesType = {
  COMPETITION: 'competition',
  GAME: 'game',
  ORGANIZATION: 'organization',
  TEAM: 'team',
  TOURNAMENT: 'tournament',
};

type SharedEntityFields = 'teamIds' | 'tournamentIds' | 'competitionIds';
export type PreferredEntityField = SharedEntityFields | 'gameIds';
export type NotificationEntityField = SharedEntityFields;
export type PreferencesErrors = { [attr: string]: string };

type PreferencesAccountData = {
  currentPassword: string;
  displayName?: string;
  email?: string;
  newPassword?: string;
  isSSOProvider?: boolean;
};

interface SavePreferencesAccountAction {
  type: typeof SAVE_PREFERENCES_ACCOUNT;
  data: PreferencesAccountData;
}

export const savePreferencesAccount = (data: PreferencesAccountData): UserActionTypes => ({
  type: SAVE_PREFERENCES_ACCOUNT,
  data,
});

interface SetUserAction {
  type: typeof SET_USER;
  user: User;
}

export const setUser = (user: User): UserActionTypes => ({
  type: SET_USER,
  user,
});

interface UpdateUserAction {
  type: typeof UPDATE_USER;
  payload: User;
}

export const updateUser = (payload: User & { fetched?: boolean }): UserActionTypes => ({
  type: UPDATE_USER,
  payload,
});

interface SignOutAction {
  type: typeof SIGN_OUT;
  trackEvent: TrackEvent;
}

export const signOut = (): UserActionTypes => ({
  type: SIGN_OUT,
  trackEvent: { event: 'Sign Out' },
});

interface SignUpCompleteAction {
  type: typeof SIGN_UP_COMPLETE;
  trackEvent: TrackEvent;
}

export const signUpComplete = ({ source, providerId }: { source: string; providerId: string }): UserActionTypes => ({
  type: SIGN_UP_COMPLETE,
  trackEvent: {
    event: 'Sign Up Complete',
    properties: { source, providerId },
  },
});

interface SignInCompleteAction {
  type: typeof SIGN_IN_COMPLETE;
  trackEvent: TrackEvent;
}

export const signInComplete = ({ source, providerId }: { source: string; providerId: string }): UserActionTypes => ({
  type: SIGN_IN_COMPLETE,
  trackEvent: {
    event: 'Sign In',
    properties: { source, providerId },
  },
});

interface SetPreferencesAccountStatusAction {
  type: typeof SET_PREFERENCES_ACCOUNT_STATUS;
  status: string;
}

export const setPreferencesAccountStatus = (status: string): UserActionTypes => ({
  type: SET_PREFERENCES_ACCOUNT_STATUS,
  status,
});

interface SetPreferencesErrorAction {
  type: typeof SET_PREFERENCES_ERROR;
  errors: PreferencesErrors;
}

export const setPreferencesError = (errors: PreferencesErrors): UserActionTypes => ({
  type: SET_PREFERENCES_ERROR,
  errors,
});

interface SetPreferencesCompletedAtAction {
  type: typeof SET_PREFERENCES_COMPLETED_AT;
  preferencesType: string;
}

export const setPreferencesCompletedAt = (preferencesType: string): UserActionTypes => ({
  type: SET_PREFERENCES_COMPLETED_AT,
  preferencesType,
});

interface ChangeUserArrayOptInAction {
  type: typeof CHANGE_USER_ARRAY_OPT_IN;
  optionName: string;
  isOptIn: boolean;
  value: string;
  trackEvent: TrackEvent;
}

export const changeUserArrayOptIn = (optionName: string, value: string, isOptIn: boolean): UserActionTypes => ({
  type: CHANGE_USER_ARRAY_OPT_IN,
  optionName,
  isOptIn,
  value,
  trackEvent: {
    event: `Preference ${isOptIn ? 'Set' : 'Unset'}`,
    properties: { type: `${optionName}:${value}` },
  },
});

interface PreferredEntityAction {
  trackEvent: TrackEvent;
  entityField: PreferredEntityField | NotificationEntityField;
}

interface RemovePreferredEntityAction extends PreferredEntityAction {
  type: typeof REMOVE_PREFERRED_ENTITY;
  selectedId: string;
}

export const removePreferredEntity = (entityField: PreferredEntityField, selectedId: string): UserActionTypes => ({
  type: REMOVE_PREFERRED_ENTITY,
  trackEvent: {
    event: 'Favorite Removed',
    properties: { type: entityField },
  },
  entityField,
  selectedId,
});

interface SavePreferredEntitiesAction extends PreferredEntityAction {
  type: typeof SAVE_PREFERRED_ENTITIES;
  trackEvent: TrackEvent;
  selectedIds: string[];
}

export const savePreferredEntities = (entityField: PreferredEntityField, selectedIds: string[]): UserActionTypes => ({
  type: SAVE_PREFERRED_ENTITIES,
  trackEvent: {
    event: 'Favorites Set',
    properties: { type: entityField },
  },
  entityField,
  selectedIds,
});

interface SavePreferredEntityAction extends PreferredEntityAction {
  type: typeof SAVE_PREFERRED_ENTITY;
  selectedId: string;
}

export const savePreferredEntity = (entityField: PreferredEntityField, selectedId: string): UserActionTypes => ({
  type: SAVE_PREFERRED_ENTITY,
  trackEvent: {
    event: 'Favorite Added',
    properties: { type: entityField },
  },
  entityField,
  selectedId,
});

interface RemovePreferredEntityNotificationsAction extends PreferredEntityAction {
  type: typeof REMOVE_PREFERRED_ENTITY_NOTIFICATIONS;
  selectedId: string;
}

export const removePreferredEntityNotifications = (
  entityField: NotificationEntityField,
  selectedId: string
): UserActionTypes => ({
  type: REMOVE_PREFERRED_ENTITY_NOTIFICATIONS,
  trackEvent: {
    event: 'Favorite Notifications Removed',
    properties: { type: entityField },
  },
  entityField,
  selectedId,
});

interface SavePreferredEntitiesNotificationsAction extends PreferredEntityAction {
  type: typeof SAVE_PREFERRED_ENTITIES_NOTIFICATIONS;
  selectedIds: string[];
}

export const savePreferredEntitiesNotifications = (
  entityField: NotificationEntityField,
  selectedIds: string[]
): UserActionTypes => ({
  type: SAVE_PREFERRED_ENTITIES_NOTIFICATIONS,
  trackEvent: {
    event: 'Favorites Notifications Set',
    properties: { type: entityField },
  },
  entityField,
  selectedIds,
});

interface SavePreferredEntityNotificationsAction extends PreferredEntityAction {
  type: typeof SAVE_PREFERRED_ENTITY_NOTIFICATIONS;
  selectedId: string;
}

export const savePreferredEntityNotification = (
  entityField: NotificationEntityField,
  selectedId: string
): UserActionTypes => ({
  type: SAVE_PREFERRED_ENTITY_NOTIFICATIONS,
  trackEvent: {
    event: 'Favorite Notifications Added',
    properties: { type: entityField },
  },
  entityField,
  selectedId,
});

interface SaveUserTimeZoneAction {
  type: typeof SAVE_USER_TIME_ZONE;
  selectedTimeZone: string;
}

export const saveUserTimeZone = (selectedTimeZone: string): UserActionTypes => ({
  type: SAVE_USER_TIME_ZONE,
  selectedTimeZone,
});

interface SetSportBuffTokenAction {
  type: typeof SET_SPORT_BUFF_TOKEN;
  token: string;
}

export const setSportBuffToken = (token: string): UserActionTypes => ({
  type: SET_SPORT_BUFF_TOKEN,
  token,
});

interface UpdateFCMTokenAction {
  type: typeof UPDATE_FCM_TOKEN;
  fcmToken: string;
}

// Updates fcmToken on user in local state tree.
export const updateFCMToken = (fcmToken: string): UserActionTypes => ({
  type: UPDATE_FCM_TOKEN,
  fcmToken,
});

interface RefreshFCMTokenAction {
  type: typeof REFRESH_FCM_TOKEN;
  fcmToken: string;
}

// Used to handle FCM messaging.onTokenRefresh or force token refresh.
export const refreshFCMToken = (fcmToken: string): UserActionTypes => ({
  type: REFRESH_FCM_TOKEN,
  fcmToken,
});

export type UserActionTypes =
  | ChangeUserArrayOptInAction
  | RefreshFCMTokenAction
  | RemovePreferredEntityAction
  | RemovePreferredEntityNotificationsAction
  | SavePreferencesAccountAction
  | SavePreferredEntityAction
  | SavePreferredEntitiesAction
  | SavePreferredEntityNotificationsAction
  | SavePreferredEntitiesNotificationsAction
  | SaveUserTimeZoneAction
  | SetPreferencesAccountStatusAction
  | SetPreferencesCompletedAtAction
  | SetPreferencesErrorAction
  | SetSportBuffTokenAction
  | SetUserAction
  | SignInCompleteAction
  | SignOutAction
  | SignUpCompleteAction
  | UpdateFCMTokenAction
  | UpdateUserAction;
