import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  icon: {
    fontSize: 32,
    color: theme.palette.inputIcon.primary,
  },
  iconButton: {}, // Hides error msg when used in PasswordOutlinedInput :(
  errorMsg: {
    color: theme.palette.error.main,
    fontSize: 13,
    marginTop: 6,
    marginLeft: 4,
  },
});

const IconOutlinedInput = ({
  classes,
  className,
  errorMsg,
  icon,
  label,
  labelWidth,
  onBlur,
  onChange,
  required,
  type,
  value,
  disabled,
}) => (
  <FormControl className={className} error={!!errorMsg} required={required} variant="outlined">
    <InputLabel>{label}</InputLabel>
    <OutlinedInput
      className={classes.input}
      endAdornment={
        <InputAdornment className={classes.icon} position="end">
          {icon}
        </InputAdornment>
      }
      labelWidth={labelWidth}
      onBlur={onBlur}
      onChange={onChange}
      type={type || 'text'}
      value={value}
      disabled={disabled}
    />
    {errorMsg && <div className={classes.errorMsg}>{errorMsg}</div>}
  </FormControl>
);

export default withStyles(styles)(IconOutlinedInput);
