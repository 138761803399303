export const getTournamentBaseUrl = tournament => `/e/${tournament?.slug}`;

export const getCompetitionBaseUrl = competition => `/c/${competition?.slug}`;

export const getOrganizationBaseUrl = organization => `/o/${organization?.slug}`;

export const getTeamBaseUrl = team => `/t/${team?.slug}`;

export const getVodBaseUrl = series => `/vods/watch/${series.id}`;

export const getTournamentUrl = tournament => `${getTournamentBaseUrl(tournament)}#overview`;

export const getCompetitionUrl = competition => `${getCompetitionBaseUrl(competition)}#overview`;

export const getOrganizationUrl = organization => `${getOrganizationBaseUrl(organization)}#overview`;

export const getTeamUrl = team => `${getTeamBaseUrl(team)}#overview`;

export const getSeriesUrl = series => `${getTournamentBaseUrl(series.tournament)}/${series.id}`;

export const getGameUrl = game => `/${game && game.abbr.toLowerCase().replace(/\s/g, '')}`;

export const getTournamentUrlFromSlug = slug => `/e/${slug}#overview`;
