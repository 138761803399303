import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Redirect } from 'react-router-dom';

const styles = theme => ({
  root: {
    color: 'white',
    background: 'url(/images/Juked_Background.jpg) no-repeat center center',
    backgroundSize: 'cover',
    justifyContent: 'center',
    zIndex: -40,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100vh',
    width: '100%',
    '& h1': {
      textAlign: 'center',
    },
  },
  logo: {
    maxWidth: 650,
    minWidth: 200,
    marginBottom: 30,
  },
});

class MaintenanceScreen extends Component {
  render() {
    const { classes, showMaintenancePage } = this.props;
    if (!showMaintenancePage) {
      return <Redirect to={'/'} />;
    }
    return (
      <div className={classes.root}>
        <img src="/images/White_Logo.png" className={classes.logo} alt="logo" />
        <h1>The site is under maintenance</h1>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    state => ({
      showMaintenancePage: state.maintenance.showMaintenancePage,
    }),
    {}
  )
)(MaintenanceScreen);
