import React from 'react';
import { makeStyles } from '@material-ui/styles';

import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
  tooltipContainer: {
    visibility: 'hidden',
    background: theme.swampBlack,
    color: 'white',
    textAlign: 'center',
    borderRadius: 4,
    padding: 5,
    position: 'absolute',
    zIndex: 99999,
    top: '130%',
    opacity: 0,
    transition: 'opacity 0.3s',
    boxSizing: 'border-box',
    '$tooltip:hover &': {
      visibility: 'visible',
      opacity: 1,
    },
    '&.left': {
      top: '50%',
      transform: 'translateY(-50%)',
      width: '100%',
      left: '-120%',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '100%',
      left: '50%',
      marginLeft: -5,
      borderWidth: 5,
      borderStyle: 'solid',
      borderColor: `transparent transparent ${theme.swampBlack} transparent`,
    },
    '&.left:after': {
      right: -10,
      transform: 'rotate(90deg)',
      top: '45%',
      left: 'unset',
    },
  },
}));

const Tooltip = ({ className, text, left }) => {
  const classes = useStyles();

  return (
    <span
      className={classnames({
        [className]: true,
        [classes.tooltipContainer]: true,
        left,
        tooltip: true,
      })}
    >
      {text}
    </span>
  );
};

export default Tooltip;
