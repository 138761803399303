import React, { Component } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/styles';

import IconOutlinedInput from './IconOutlinedInput';

const styles = theme => ({
  iconButton: {
    fontSize: 24,
    padding: 4,
  },
});

class PasswordOutlinedInput extends Component {
  state = { visible: false };

  handleClickShowPassword = () => {
    this.setState(({ visible }) => ({ visible: !visible }));
  };

  handleMouseDownPassword = e => {
    e.preventDefault();
  };

  render() {
    const { classes } = this.props;
    const { visible } = this.state;

    return (
      <IconOutlinedInput
        {...this.props}
        icon={
          <IconButton
            className={classes.iconButton}
            onClick={this.handleClickShowPassword}
            onMouseDown={this.handleMouseDownPassword}
          >
            {visible ? <MdVisibility /> : <MdVisibilityOff />}
          </IconButton>
        }
        type={visible ? 'text' : 'password'}
      />
    );
  }
}

export default withStyles(styles)(PasswordOutlinedInput);
