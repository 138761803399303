import { Box } from '@rebass/grid';

const Column = Box;

Column.defaultProps = {
  flex: '1 1 auto',
  px: 3,
};

export default Column;
