import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { qsStringify, qsParse } from '../common/QueryLink';

export const isString = obj => Object.prototype.toString.call(obj) === '[object String]';

// TODO: Do shallow comparisons for lists
const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

/**
 * Takes a map of prop names to actions that should be sync'd between URL and redux.
 * Handles the following cases:
 * 1. Syncs from URL to redux on mount.
 * 2. Syncs from redux to URL on any prop change.
 * 3. Syncs from URL to redux on any url change (back/forward/router) unless triggered by prop change.
 * @param {object} propsMap is a mapping of prop names to actions, for state that should be sync'd.
 */
const withUrlStateSync = propsMap => WrappedComponent => props => {
  // Keep track of initialization state.
  const [initialized, setInitialized] = useState(false);
  const [skipReduxSync, setSkipReduxSync] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // NOTE: Be careful not to mutate propsMap, or syncProps order may break.
  const syncProps = Object.keys(propsMap);
  const searchState = qsParse(location.search);

  const syncToRedux = () => {
    for (const prop of syncProps) {
      const { action, filter } = propsMap[prop];
      if (!filter) console.error(`filter is required in withUrlStateSync for prop: ${prop}`);

      const urlValue = searchState[prop];
      const isValid = prop in searchState && (filter ? filter(urlValue) : false);

      // NOTE: duplicate syncToRedux can be triggered from a prop change
      // propagating to URL, so only dispatch when values are not equal and
      // skip if reduxSync just happened -- because we are doing 2-way sync.
      if (isValid && !isEqual(urlValue, props[prop])) {
        dispatch(action(urlValue));
      }
    }
  };

  const syncToUrl = () => {
    const { pathname, hash } = location;
    const searchFromProps = syncProps.reduce(
      (accu, prop) => (props[prop] ? { ...accu, [prop]: props[prop] } : accu),
      {}
    );

    // Only sync if there are any differences in URL state to sync.
    const searchFromPropsJson = JSON.stringify(syncProps.map(prop => searchFromProps[prop]));
    const searchStateJson = JSON.stringify(syncProps.map(prop => searchState[prop]));
    if (searchFromPropsJson !== searchStateJson) {
      setSkipReduxSync(true); // history.replace will trigger an additional syncToRedux, so we should skip it.
      history.replace(`${pathname}?${qsStringify(searchFromProps)}${hash}`);
    }
  };

  // If component initialized, sync to URL on props changes.
  useEffect(() => {
    initialized && syncToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...syncProps.map(prop => JSON.stringify(props[prop]))]);

  // If component initialized, sync to redux on URL state changes (i.e. forward/backward)
  useEffect(() => {
    initialized && !skipReduxSync && syncToRedux();
    skipReduxSync && setSkipReduxSync(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...syncProps.map(prop => JSON.stringify(searchState[prop]))]);

  // On page load, we must sync state from URL to redux.
  useEffect(() => {
    syncToRedux();
    setInitialized(true); // Prevent duplicate sync until after initialized.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <WrappedComponent {...props} />;
};

export default withUrlStateSync;
