export const GAME_MESSAGES = {
  //This object keys point to a specific game
  2: {
    title: 'League of Legends Esports - LCS, LEC, LCK, LPL + more',
    description:
      'Follow the latest in League of Legends esports news, leagues, tournaments, scores, brackets, teams, and players - LCS, LEC, LCK, LPL, LJL, and more.',
  },
  11: {
    title: 'Call of Duty Esports - Call of Duty + more | Juked',
    description:
      'Follow the latest in Call of Duty League esports on Juked - CDL news, tournaments, scores, brackets, teams, players, and more.',
  },
  12: {
    title: 'Overwatch Esports - Overwatch League + more | Juked',
    description:
      'Follow the latest in Overwatch esports - news, leagues, tournaments, scores, brackets, teams, and players in OWL (Overwatch League) and Overwatch Contenders.',
  },
};
