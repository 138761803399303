import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  noFavorites: {
    fontStyle: 'oblique',
    textAlign: 'center',
    marginTop: 45,
    maxWidth: 800,
    fontSize: 35,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
      fontSize: 30,
    },
  },
  noSearch: {
    fontSize: 45,
    fontStyle: 'oblique',
    fontWeight: 980,
    color: theme.palette.text.primary,
  },
}));

const NoContentMessage = ({ isFavoritesSelected, favoritesGames }) => {
  const classes = useStyles();
  return (
    <>
      {isFavoritesSelected && favoritesGames && !favoritesGames.length ? (
        <p className={classes.noFavorites}>
          You didn't set any followed games in your Profile yet, nerd! Head over to your{' '}
          <Link to="/profile?page=following">Profile</Link> to set them up.
        </p>
      ) : (
        <h2 className={classes.noSearch}>NO SEARCH RESULTS.</h2>
      )}
    </>
  );
};

export default NoContentMessage;
