import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { TrackDiv } from '../common';
import ColorCheckbox from '../Filters/ColorCheckbox';

const styles = theme => ({
  menuItem: {
    width: '100%',
    boxSizing: 'border-box',
    padding: 8,
    cursor: 'pointer',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
    '&.active': {
      color: theme.palette.text.primary,
      fontWeight: 700,
    },
    '&:hover': {
      background: theme.palette.background.tertiary,
    },
    '& .MuiCheckbox-root': {
      padding: 0,
      borderRadius: 4,
      marginRight: 8,
      '& .MuiSvgIcon-root': {
        width: 20,
        height: 20,
      },
    },
    '&.disabled': {
      pointerEvents: 'none',
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  },
});

const DropdownItem = ({ classes, className, active, disabled, children, hasCheckbox, onClick, track }) => (
  // NOTE: Use TrackDiv because we need to wrap onClick.  Otherwise, would use withTrackClick HOC.
  <TrackDiv
    className={classnames({
      [classes.menuItem]: true,
      [className]: true,
      disabled,
      active,
    })}
    onClick={e => {
      e.stopPropagation();
      e.preventDefault(); // Stop from redirecting if parent is a link.
      // If the onClick prop is provided, execute it.
      onClick && onClick();
    }}
    track={track}
  >
    {hasCheckbox && <ColorCheckbox checked={active} />}
    {children}
  </TrackDiv>
);

DropdownItem.propTypes = {
  children: PropTypes.any.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
  onClick: PropTypes.func,
  track: PropTypes.object,
};

export default withStyles(styles)(DropdownItem);
