import React from 'react';

const MultiViewIcon = ({ fill }) => {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Icon - Multi Viewer</title>
      <g id="Follow-System" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Homepage---Nav-Bar-Update---Signed-In" transform="translate(-1128.000000, -116.000000)">
          <g id="Top-Bar" transform="translate(80.000000, 115.000000)">
            <g id="Viewer-Buttons" transform="translate(1048.000000, 0.000000)">
              <g id="Icon---Multi-Viewer" transform="translate(0.000000, 1.000000)">
                <rect id="Rectangle-Copy-7" x="0" y="0" width="32" height="32" rx="4"></rect>
                <g id="Multi-Viewer" transform="translate(3.333333, 9.333333)" fill={fill}>
                  <path
                    d="M24.3333333,7.53623188 C24.8856181,7.53623188 25.3333333,7.98394713 25.3333333,8.53623188 L25.3333333,12.6231884 C25.3333333,13.1754732 24.8856181,13.6231884 24.3333333,13.6231884 L15.969697,13.6231884 C15.4174122,13.6231884 14.969697,13.1754732 14.969697,12.6231884 L14.969697,8.53623188 C14.969697,7.98394713 15.4174122,7.53623188 15.969697,7.53623188 L24.3333333,7.53623188 Z M5.33333333,9.27536232 C5.88561808,9.27536232 6.33333333,9.72307757 6.33333333,10.2753623 L6.33333333,12.3333333 C6.33333333,12.8856181 5.88561808,13.3333333 5.33333333,13.3333333 L1,13.3333333 C0.44771525,13.3333333 5.11724585e-16,12.8856181 0,12.3333333 L0,10.2753623 C-6.76353751e-17,9.72307757 0.44771525,9.27536232 1,9.27536232 L5.33333333,9.27536232 Z M12.8181818,9.27536232 C13.3704666,9.27536232 13.8181818,9.72307757 13.8181818,10.2753623 L13.8181818,12.3333333 C13.8181818,12.8856181 13.3704666,13.3333333 12.8181818,13.3333333 L8.48484848,13.3333333 C7.93256374,13.3333333 7.48484848,12.8856181 7.48484848,12.3333333 L7.48484848,10.2753623 C7.48484848,9.72307757 7.93256374,9.27536232 8.48484848,9.27536232 L12.8181818,9.27536232 Z M12.8181818,0 C13.3704666,-1.08250786e-15 13.8181818,0.44771525 13.8181818,1 L13.8181818,7.11594203 C13.8181818,7.66822678 13.3704666,8.11594203 12.8181818,8.11594203 L1,8.11594203 C0.44771525,8.11594203 6.76353751e-17,7.66822678 0,7.11594203 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L12.8181818,0 Z M24.3333333,0 C24.8856181,-2.12475365e-16 25.3333333,0.44771525 25.3333333,1 L25.3333333,5.08695652 C25.3333333,5.63924127 24.8856181,6.08695652 24.3333333,6.08695652 L15.969697,6.08695652 C15.4174122,6.08695652 14.969697,5.63924127 14.969697,5.08695652 L14.969697,1 C14.969697,0.44771525 15.4174122,1.01453063e-16 15.969697,0 L24.3333333,0 Z"
                    id="Rectangle-Copy-11"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MultiViewIcon;
