import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { withStyles } from '@material-ui/styles';
import { compose } from 'recompose';
import { format, getDayOfYear } from 'date-fns';

import { getGameById } from '../../../../store/selectors/gameData';
import { getTournamentUrl } from '../../../../utils/urls';
import { GameTag, TrackLink } from '../../../common';
import ItemActionDropdown from '../ItemActionDropdown';
import ItemStyles from '../../ItemStyles';
import TournamentImage from '../../../Images/TournamentImage';
import ItemPrizepool from '../../ItemPrizepool';

// Extracted to be shared between desktop and mobile.
export const renderDateRange = series => {
  let dateRange;
  const tournamentStartDate = series.tournament.start.toDate();
  const tournamentEndDate = series.tournament.end.toDate();

  dateRange = format(tournamentStartDate, 'M/d');

  // If we have an end date for the tournament is because it finishes at least a day after.
  if (getDayOfYear(tournamentStartDate) !== getDayOfYear(tournamentEndDate)) {
    dateRange += ` - ${format(tournamentEndDate, 'M/d')}`;
  }
  // Else use the start date only.
  return dateRange;
};

const styles = theme => ({
  ...ItemStyles,
  root: {
    ...ItemStyles.root,
    textDecoration: 'none',
    color: theme.palette.text.primary,
    overflow: 'visible',
    background: theme.palette.background.secondary,
  },
  dateRange: {
    width: '13%',
    fontSize: 20,
    order: -1,
    fontWeight: 600,
  },
  seriesInfo: {
    ...ItemStyles.seriesInfo,
    width: '50%',
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  prizepool: {
    '& .trophyIcon': {
      fontSize: 16,
    },
  },
  notificationDropdown: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    '.dropdownOpen &': {
      // NOTE: this is needed to prevent rendering the dropdown under the next item notification buttonon.
      zIndex: 1002, // To beat footer when dropdown open.
    },
  },
});

class AllViewUpcomingItem extends Component {
  state = { dropdownOpen: false };

  handleClose = () => {
    this.setState({ dropdownOpen: false });
  };

  handleOpen = () => this.setState(state => ({ dropdownOpen: !state.dropdownOpen }));

  render() {
    const {
      classes,
      series: { tournament },
      series,
      game,
    } = this.props;
    const { dropdownOpen } = this.state;

    return (
      <div
        className={classnames({
          [classes.root]: true,
          dropdownOpen,
        })}
      >
        <div className={classes.dateRange}>{renderDateRange(series)}</div>
        <GameTag className={classes.gameName} game={game} seriesId={series.id} />
        <div className={classes.seriesInfo}>
          <TournamentImage tournament={tournament} />
          <div className={classes.seriesInfoText}>
            <div className={classes.tournamentName}>{tournament.title}</div>
            <ItemPrizepool className={classes.prizepool} prizepool={tournament.prizepool} withTrophyIcon />
          </div>
        </div>
        <div className={classes.notificationDropdown}>
          <ItemActionDropdown
            open={dropdownOpen}
            handleClose={this.handleClose}
            handleOpen={this.handleOpen}
            series={series}
          />
        </div>
        <TrackLink
          to={getTournamentUrl(tournament)}
          className={classes.hoverText}
          track={{
            event: 'Schedule Item Clicked',
            target: 'event-page',
            eventProperties: { type: 'all-view-upcoming-item' },
            eventOptions: { contextSeriesId: series.id },
          }}
        >
          Click Here to <span>View Event</span>
        </TrackLink>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect((state, ownProps) => ({
    game: getGameById(state, ownProps.series.game),
  }))
)(AllViewUpcomingItem);
