import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { BodyText } from '../common';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  heroImg: {
    borderRadius: 4,
    width: '100%',
  },
  heroCaptionRow: {
    borderBottom: `2px solid ${theme.palette.border.primary}`,
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    margin: '8px 0 0 0',
    padding: '0 0 14px 0',
    width: '100%',
  },
  caption: {
    fontStyle: 'italic',
  },
}));

export default ({ article, className }) => {
  const classes = useStyles();
  const { attributes } = article;
  const { hero_media_url: heroMediaUrl, hero_media_caption: heroMediaCaption } = attributes || {};

  return !heroMediaUrl ? null : (
    <div className={`${classes.root} ${className}`}>
      <img alt={heroMediaCaption || 'hero image'} className={classes.heroImg} src={heroMediaUrl} />
      <div className={classes.heroCaptionRow}>
        {heroMediaCaption && (
          <BodyText className={classes.caption} variant="light">
            {heroMediaCaption}
          </BodyText>
        )}
      </div>
    </div>
  );
};
