import { trackEvent } from '../actions/uiStates';

const trackEventMiddleware = store => next => action => {
  if (action.trackEvent) {
    const { event, properties } = action.trackEvent;
    store.dispatch(trackEvent(event, properties));
  }
  return next(action);
};

export default trackEventMiddleware;
