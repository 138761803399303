import React, { useEffect } from 'react';

import { useSubscribeArticles } from '../../hooks/articles';
import ArticleSection from '../ArticlesSection/index';

const EntityArticleSection = ({ title, className, onFetchArticles, queryProps }) => {
  const [articles, featuredArticles] = useSubscribeArticles(queryProps);

  useEffect(() => {
    onFetchArticles && onFetchArticles(articles);
  }, [articles, onFetchArticles]);

  return <ArticleSection title={title} articles={articles} featuredArticles={featuredArticles} className={className} />;
};

export default EntityArticleSection;
