import React from 'react';
import { withStyles } from '@material-ui/styles';

import CalendarTable from './Table';

const styles = theme => ({
  scheduleSection: {
    background: theme.palette.background.primary,
    color: theme.palette.text.primary,
    paddingTop: 40,
    paddingBottom: 40,
    width: '100%',
  },
  scheduleContainer: {
    maxWidth: 'calc(100vw - 48px)',
    width: '100%',
    paddingTop: 5,
    margin: 'auto',
    '&:empty': {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1232,
    },
  },
  calendarHeaderContainer: {
    position: 'relative',
    marginBottom: 55,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& > h1': {
      margin: 0,
    },
    '&:before': {
      content: '" "',
      position: 'absolute',
      background: theme.lightTeal,
      width: 80,
      height: 3,
      bottom: -15,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 976,
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1232,
    },
  },
  //  TODO: re-visit design for series with more than 2 participants.
  [theme.breakpoints.down('sm')]: {
    scheduleContainer: {
      margin: 0,
      width: 'unset',
      minWidth: 'unset',
      maxWidth: 'unset',
    },
  },
});

// Specially wrapped calendar for one specific tournament's upcoming matches
const EventCalendar = ({ classes, liveSeries, upcomingSeries, title, context }) => (
  <div className={classes.scheduleSection}>
    <div className={classes.scheduleContainer}>
      <div className={classes.calendarHeaderContainer}>
        <h1>{title || 'What to Watch'}</h1>
      </div>
      {!upcomingSeries || !upcomingSeries.length ? (
        <h3>No more upcoming matches for this {context || 'Event'}.</h3>
      ) : (
        <CalendarTable seriesRange={upcomingSeries} liveSeries={liveSeries} isMatchView={true} />
      )}
    </div>
  </div>
);

export default withStyles(styles)(EventCalendar);
