// NOTE: This file is shared between Frontend and Admin. Please copy from Frontend to Admin when making changes.
import React from 'react';

import getEmbedUrl from '../../utils/getEmbedUrl';

const CardThumbnail = ({ article }) => {
  const mediaUrl = article.attributes.preview_media_url || article.attributes.hero_media_url;
  return (
    <>
      {mediaUrl.includes('youtube') || mediaUrl.includes('twitch') ? (
        <iframe
          title={article.attributes.title}
          src={getEmbedUrl(mediaUrl)}
          width="100%"
          height="100%"
          frameBorder="0"
        ></iframe>
      ) : (
        <img src={mediaUrl} alt="Article" />
      )}
    </>
  );
};

export default CardThumbnail;
